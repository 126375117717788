import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import SettingRouter from './SettingRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import HRISRouter from './HRISRouter';

import ChatRouter from './ChatRouter';
import Layout from '../Layouts';
import ManagementRouter from './ManagementRouter';
import CustomerRelationRouter from './CustomerRelationRouter';
import ProductRouter from './ProductRouter';
import OrdersRouter from './OrdersRouter';
import IntegrationRouter from './IntegrationRouter';
import ShippingRouter from './ShippingRouter';
import ViewRouter from './ViewRouter'
import DashboardRouter from './DashboardRouter';
import WebsitesRouter from './WebsitesRouter';
import PrintLabelPage from '../Pages/Shipping/PrintLabelPage';
import ChatPublicRouter from './ChatPublicRouter';
import WarehouseRouter from './WarehouseRouter';

function MainRouter() {



  const allRouter = [
    ...AuthenticationMainRouter,
    ...ManagementRouter,
    ...HRISRouter,
    ...SettingRouter,
    ...HomeRouter,
    ...ChatRouter,
    ...SettingRouter,
    ...CustomerRelationRouter,
    ...ProductRouter,
    ...OrdersRouter,
    ...IntegrationRouter,
    ...ShippingRouter,
    ...ViewRouter,
    ...DashboardRouter,
    ...WarehouseRouter,
    ...WebsitesRouter,
    ...ChatPublicRouter,
  ];




  return (
    <Routes>
      <Route path='/to-print' element= {<PrintLabelPage />} />
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={<Layout>{item.element}</Layout>} />;
      })}
    </Routes>
  );
}

export default MainRouter;
