import React from 'react';
// import WarehouseAddressPage from '../Pages/Warehouses/WarehouseAddressPage';
import WareHouseHomePage from '../Pages/Warehouse/WareHouseHomePage';
import WarehouseDetailPage from '../Pages/Warehouse/WarehouseDetailPage';
import WarehousePage from '../Pages/Warehouse/WarehousePage';

const WarehouseRouter = [
  {
    path: '/warehouses',
    element: <WareHouseHomePage />,
  },

  {
    path: '/warehouse',
    element: <WarehousePage />,
  },

  {
    path: '/warehouse/:id',
    element: <WarehouseDetailPage />,
  },

  // {
  //   path: '/warehouse/address',
  //   element: <WarehouseAddressPage />,
  // },
];

export default WarehouseRouter;
