/* eslint-disable react/prop-types */
import {
  Badge,
  Box,
  HStack,
  Spacer,
  Text,
  Stack,
  Tooltip,
  Avatar,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import SingleCalendarComponent from './SingleCalendarComponent';
import 'react-quill/dist/quill.snow.css';
import { auth } from '../../Config/firebase';
import {
  addDocumentFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice } from '../../Utils/Helper';
import {
  BsCalendarPlus,
  BsChatDots,
  BsCheckSquare,
  BsFileText,
  BsTag,
  BsTelephone,
} from 'react-icons/bs';
import { getUserByUid } from '../../Utils/getUser';

function LineTaskDefaultCardComponent({ task, setData, columnsData, handleModalOpen }) {
  const [index, setIndex] = useState(0);


  const globalState = useUserStore();

  // const res = getUserByUid(globalState?.users, task?.assignee[0]);
  const res = getUserByUid(globalState?.users);



  const handleMoveCard = (id, column) => {
    setData([...columnsData.slice(0, index), ...columnsData.slice(index + 1)]);
    // console.log(setData([...columnsData.slice(0, index), ...columnsData.slice(index + 1)]))
    setDocumentFirebase(
      'customer',
      id,
      {
        column: column,
        lastUpdated: new Date(),
        companyId: globalState.currentCompany,
      },
      globalState.currentCompany
    );

    addDocumentFirebase(
      `customer/${id}/pipeline-history`,
      {
        comments: `${
          auth.currentUser.email
        } has moved card to ${column} on ${new Date()}`,
        lastUpdated: new Date(),
      },
      globalState.currentCompany
    );
  };

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'column',
      item: task?.id,
      end(item, monitor) {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          const isDropAllowed = dropResult.allowedDropEffect === 'move';
          if (isDropAllowed) {
            handleMoveCard(task?.id, dropResult.name);
          }
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [task]
  );
  
  useEffect(() => {
    setIndex(index);
    // handleMoveCard()
  }, []);

  return (
    <>
      <Stack
        width="full"
        shadow="base"
        borderBottomWidth={2}
        borderColor={'green.300'}
        borderRadius={'md'}
        p="2"
        spacing={1}
        ref={drag}
        style={{ opacity }}
        cursor="pointer"
        onClick={() => {
          handleModalOpen(task);
          // console.log(`${task?.id}`, { state: { type: 'existing' } })
          // console.log(`${task?.id}`, { state: { type: 'existing' } })
          // navigate(`${task?.id}`, { state: { type: 'existing' } })
          // window.open(`${param.title}/${task?.id}`)
          // setSomeFunction(setData)
          // setTaskData(props)
          // console.log(props)
        }}
      >
        <HStack>
          <HStack spacing={1} alignItems="center">
            <Text
              fontWeight={'bold'}
              fontSize="sm"
              textTransform={'capitalize'}
            >
              {task?.name}
            </Text>
            {task.status ? (
              <Badge
                colorScheme={
                  task.status === 'help'
                    ? 'yellow'
                    : task.status === 'won'
                      ? 'green'
                      : task.status === 'lost'
                        ? 'red'
                        : 'gray'
                }
                size="sm"
                borderRadius={'sm'}
              >
                <Text>{task.status}</Text>
              </Badge>
            ) : (
              <></>
            )}
          </HStack>
          <Spacer />
          <Box
            borderRadius={'full'}
            bgColor="gray.100"
            cursor={'pointer'}
            onClick={() => console.log('delete from leads')}
          >
            {task?.createdBy ? (
              <Tooltip
                label={res?.name || res?.email}
                aria-label="Name"
              >
                <Avatar
                  m="0"
                  size="xs"
                  name={res?.name || res?.email}
                  src={res?.image || res?.email}
                />
              </Tooltip>
            ) : (
              <></>
            )}

          </Box>
        </HStack>

        <Text fontSize="xs" color={'gray.600'}>
          {task?.source ? task.source : ''}
        </Text>

        <Text fontSize="xs" fontWeight={500}>
          Rp.{formatFrice(task?.last_checkout_value ? task.last_checkout_value : 0)}
        </Text>

        <HStack>
          <SingleCalendarComponent
            data={task.lastUpdated}
            date={moment.unix(task.lastUpdated).format('DD')}
            month={moment.unix(task.lastUpdated).format('MMM')}
          />

          <Spacer />
          <Stack spacing={2}>
            <HStack spacing={3}>
              <Stack>
                <BsTelephone size={14} />
              </Stack>
              <Stack> 
                <BsChatDots size={14} />
              </Stack>
              <Stack>
                <BsTag size={14} />
              </Stack>
              <Stack>
                <BsFileText size={14} />
              </Stack>
              <Stack>
                <BsCheckSquare size={14} />
              </Stack>
              <Stack>
                <BsCalendarPlus size={14} />
              </Stack>
            </HStack>
            <Stack spacing={0} textAlign="end">
              {task?.lastUpdated?.seconds ? (
                <Text fontSize="2xs">
                  Updated: {moment.unix(task?.lastUpdated?.seconds)?.fromNow()}
                </Text>
              ) : (
                <></>
              )} 
              <Text fontSize="3xs">ID: {task.id}</Text>
            </Stack>
          </Stack>
        </HStack>
      </Stack>
    </>
  );
}

export default LineTaskDefaultCardComponent