import React,{ useEffect,useState } from 'react'
import { MdNavigateBefore,MdNavigateNext } from 'react-icons/md';
import PropTypes from 'prop-types'

const HeroSlider = ({data,width}) => {

  const dataDummy = [
    {
      image: 'https://klamby.id/cdn/shop/files/website_estella-2_1944x.jpg?v=1705984402',
      link: 'https://facebook.com'
    },
    {
      image: 'https://klamby.id/cdn/shop/files/website_biyya-4_1944x.jpg?v=1705634701',
      link: 'https://instagram.com'
    },
    {
      image: 'https://klamby.id/cdn/shop/files/BANNER-110124-LASTCALL_1944x.png?v=1704961356',
      link: 'https://klamby.id'
    },
  ]

  const dataSlider = data || dataDummy


  const [currentIndex,setCurrentIndex] = useState(0)

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? dataSlider?.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === dataSlider?.length - 1

    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    },5000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval);
  },[currentIndex]);


  return (
    <div className={width === '400px' ? 'w-350px m-auto h-[300px] relative group border' : 'w-full m-auto h-[1000px] relative group border'}>
      <a className='w-full' href={dataSlider[currentIndex]?.link} target='_blank' rel='noreferrer'>
        <div style={{ backgroundImage: `url(${dataSlider[currentIndex]?.image})` }} className='w-full h-full bg-center bg-cover duration-500'>
        </div>
      </a>

      <div className='text-white absolute hidden group-hover:block bg-slate-400 bg-opacity-50 rounded-full mx-5 top-[50%] cursor-pointer' onClick={() => prevSlide()}>
        <MdNavigateBefore size={50} />
      </div>
      <div className='text-white absolute hidden group-hover:block bg-slate-400 bg-opacity-50  rounded-full right-0 mx-5 top-[50%] cursor-pointer' onClick={() => nextSlide()}>
        <MdNavigateNext size={50} />
      </div>
    </div>
  )
}

export default HeroSlider

HeroSlider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.string,
}