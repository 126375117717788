import React from 'react';
import PropTypes from 'prop-types';

export const Product5 = ({ header,data }) => {
  // const [hoveredIndex, setHoveredIndex] = useState();
  // const [filteredData, setFilteredData] = useState(data);

  // const handleFilterChange = (event, selectedFilter) => {
  //   const { checked } = event.target;

  //   if (checked) {
  //     const newData = data.filter((product) => product.tags && product.tags.includes(selectedFilter));
  //     setFilteredData(newData);
  //   } else {
  //     setFilteredData(data);
  //   }
  // };

  return (
    <div className='bg-white'>
      {data?.length > 0 ? (
        <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
          {/* Header */}
          <h2 className='text-2xl font-bold tracking-tight text-gray-900 mb-5'>{header}</h2>
          <div className='grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8'>
            {/* {filteredData.map((product, index) => (
              <a
                key={index}
                href='#'
                className='group'>
                <div className='aspect-w-1 aspect-h-1 w-full'>
                  <img
                    src={product.image1}
                    alt=''
                    className='object-cover w-full h-[300px] mt-8 rounded-t-xl'
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <h3 className='mt-4 text-sm text-gray-700'>{product.name}</h3>
                <p className='mt-1 text-lg font-medium text-gray-900'>Rp. {product.price}</p>
              </a>
            ))} */}
          </div>
        </div>
      ) : (
        <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
          <h2 className='text-2xl font-bold tracking-tight text-gray-900 mb-5'>Products</h2>

          <div className='grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8'>
            <a
              href='#'
              className='group'>
              <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7'>
                <img
                  src='https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-01.jpg'
                  alt='Tall slender porcelain bottle with natural clay textured body and cork stopper.'
                  className='h-full w-full object-cover object-center group-hover:opacity-75'
                />
              </div>
              <h3 className='mt-4 text-sm text-gray-700'>Earthen Bottle</h3>
              <p className='mt-1 text-lg font-medium text-gray-900'>$48</p>
            </a>
            <a
              href='#'
              className='group'>
              <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7'>
                <img
                  src='https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg'
                  alt='Olive drab green insulated bottle with flared screw lid and flat top.'
                  className='h-full w-full object-cover object-center group-hover:opacity-75'
                />
              </div>
              <h3 className='mt-4 text-sm text-gray-700'>Nomad Tumbler</h3>
              <p className='mt-1 text-lg font-medium text-gray-900'>$35</p>
            </a>
            <a
              href='#'
              className='group'>
              <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7'>
                <img
                  src='https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-03.jpg'
                  alt='Person using a pen to cross a task off a productivity paper card.'
                  className='h-full w-full object-cover object-center group-hover:opacity-75'
                />
              </div>
              <h3 className='mt-4 text-sm text-gray-700'>Focus Paper Refill</h3>
              <p className='mt-1 text-lg font-medium text-gray-900'>$89</p>
            </a>
            <a
              href='#'
              className='group'>
              <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7'>
                <img
                  src='https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-04.jpg'
                  alt='Hand holding black machined steel mechanical pencil with brass tip and top.'
                  className='h-full w-full object-cover object-center group-hover:opacity-75'
                />
              </div>
              <h3 className='mt-4 text-sm text-gray-700'>Machined Mechanical Pencil</h3>
              <p className='mt-1 text-lg font-medium text-gray-900'>$35</p>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

Product5.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
  data: PropTypes.array.isRequired,
  filter: PropTypes.any,
};
