import {
  FcConferenceCall,
  FcSettings,
  FcBriefcase,
  FcCollaboration,
  FcBusinessContact,
  // FcReading,
  FcCircuit,
  // FcFlashOn,
  FcMoneyTransfer,
  // FcReadingEbook,
  FcContacts,
  FcBusinessman,
  FcInternal,
  FcSpeaker,
  FcAssistant,
  FcHome,
  FcShipped,
  FcScatterPlot,
  FcComboChart,
  // FcPodiumWithoutSpeaker,
  FcFilledFilter,
  FcTemplate,
  FcServices,
  FcMultipleCameras,
  // FcCurrencyExchange,
  // FcDebt,
  FcDonate,
  FcGlobe,
  FcLockPortrait,
  FcCurrencyExchange,
  // FcAdvance,
} from 'react-icons/fc';

export const data = [
  {
    name: 'Dashboard',
    icon: FcComboChart,
    link: '/dashboard',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Contacts',
        icon: FcContacts,
        link: '/relations/contacts',
      },
      {
        name: 'Sales Pipeline',
        icon: FcInternal,
        link: '/relations/sales-pipeline',
        submenu: [
          {
            name: 'Acquisition (sales)',
            icon: FcBusinessman,
            link: '/relations/acquisition',
          },
          {
            name: 'Retention (After Sales)',
            icon: FcAssistant,
            link: '/relations/retention',
          },
        ],
      },
    ],
  },

  {
    name: 'Product',
    icon: FcCircuit,
    link: '/product',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Products-Variants',
        icon: FcMultipleCameras,
        link: '/products',
      },

      {
        name: 'Master Category Product',
        icon: FcScatterPlot,
        link: '/category',
      },

      {
        name: 'Discount',
        icon: FcCurrencyExchange,
        link: '/discount',
      },

      // {
      //   name: 'Shipping',
      //   icon: FcShipped,
      //   link: '/shipping',

      // },
      // {
      //   name: 'Warehouse',
      //   icon: FcHome,
      //   link: '/warehouse',
      // },
    ],
  },
  {
    name: 'Warehouse',
    icon: FcHome,
    link: '/warehouses',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      // {
      //   name: 'Products-Variants',
      //   icon: FcMultipleCameras,
      //   link: '/products',
      // },

      // {
      //   name: 'Master Category Product',
      //   icon: FcScatterPlot,
      //   link: '/category',
      // },

      // {
      //   name: 'Shipping',
      //   icon: FcShipped,
      //   link: '/shipping',

      // },
      {
        name: 'Warehouse',
        icon: FcHome,
        link: '/warehouse',
      },
    ],
  },

  {
    name: 'Orders',
    icon: FcMoneyTransfer,
    link: '/order',
    submenu: [
      {
        name: 'Orders',
        icon: FcMoneyTransfer,
        link: '/orders',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      // {
      //   name: 'Abandoned Cart',
      //   icon: FcPodiumWithoutSpeaker,
      //   link: '/abandonedCart',
      // },
      // {
      //   name: 'One Time Billing',
      //   icon: FcAdvance,
      //   link: '/billing/one-time',
      //   description:
      //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      // },
      {
        name: 'Shipping',
        icon: FcShipped,
        link: '/shipping',
      },
      {
        name: 'Payment Confirmations',
        icon: FcLockPortrait,
        link: '/payment-confirmations',
      },
      // {
      //   name: 'Per Transaction Billing',
      //   icon: FcInternal,
      //   link: '/billing/transaction',
      //   description:
      //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      // },
    ],
  },

  {
    name: 'Chat',
    icon: FcCollaboration,
    link: '/chats',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Costumer Service',
        icon: FcSpeaker,
        link: '/chat',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      // {
      //   name: 'User Chat',
      //   icon: FcConferenceCall,
      //   link: '/chat-user',
      //   description:
      //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      // },
      {
        name: 'Configuration Chat',
        icon: FcSettings,
        link: '/chat-config',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },
  {
    name: 'Customer',
    icon: FcBusinessContact,
    link: '/customer',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Contacts',
        icon: FcContacts,
        link: '/relations/contacts',
      },
      {
        name: 'Sales Pipeline',
        icon: FcInternal,
        link: '/relations/sales-pipeline',
        submenu: [
          {
            name: 'Acquisition (sales)',
            icon: FcBusinessman,
            link: '/relations/acquisition',
          },
          {
            name: 'Retention (After Sales)',
            icon: FcAssistant,
            link: '/relations/retention',
          },
        ],
      },
      {
        name: 'Voucher',
        icon: FcDonate,
        link: '/relations/voucher',
      },
    ],
  },

  // {
  //   name: 'Integration',
  //   icon: FcFlashOn,
  //   link: '/integration',
  //   description:
  //     'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
  //   submenu: [
  //     {
  //       name: 'Basic Knowledge',
  //       icon: FcReading,
  //       link: '/ai/knowledge',
  //     },
  //     {
  //       name: 'Product Knowledge',
  //       icon: FcReadingEbook,
  //       link: '/ai/knowledge/product',
  //     },
  //     {
  //       name: 'Knowledge Categories',
  //       icon: FcBriefcase,
  //       link: '/ai/knowledges/categories',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },

  //     {
  //       name: 'Integration Whatsapp',
  //       icon: FcBriefcase,
  //       link: '/integration/whatsapp',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },

  //     {
  //       name: 'Integration Web-Chat',
  //       icon: FcBriefcase,
  //       link: '/integration/web-chat',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //   ],
  // },

  {
    name: 'Sites',
    icon: FcFilledFilter,
    link: '/websites',
    submenu: [
      {
        name: 'Website Builder',
        icon: FcTemplate,
        link: '/websites/website-builder',
      },
      {
        name: 'Website Configuration',
        icon: FcServices,
        link: '/websites/website-configuration',
      },
      {
        name: 'Domains',
        icon: FcGlobe,
        link: '/websites/website-domains',
      },
    ],
  },
  {
    name: 'Team',
    icon: FcCollaboration,
    link: '/management/configuration',
    description:
      'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
    submenu: [
      {
        name: 'Settings',
        icon: FcSettings,
        link: 'https://recruitment-deoapp.firebaseapp.com/',
        submenu: [
          {
            name: 'Project',
            icon: FcBriefcase,
            link: '/configuration/project',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },

          {
            name: 'Users',
            icon: FcConferenceCall,
            link: '/configuration/users',
            description:
              'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
        ],
      },
    ],
  },

];
