import React from 'react';
import HrisUsers from '../Pages/HRIS/HrisUsers';
import ProjectsPage from '../Pages/Configurations/ProjectPage';
import ProjectNewPage from '../Pages/Configurations/ProjectNewPage';

const HRISRouter = [

  {
    path: '/configuration/users',
    element: <HrisUsers />,
  },

  {
    path: '/configuration/project',
    element: <ProjectsPage />,
  },
  {
    path: '/configuration/project/:id',
    element: <ProjectNewPage />,
  }
];

export default HRISRouter;
