import { Button, Divider, HStack, Image, Input, Stack, Text, useNumberInput, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { addDocumentFirebase, getCollectionFirebase, setDocumentFirebase } from '../../Api/firebaseApi'
import BackButtons from '../../Components/Buttons/BackButtons'
import useUserStore from '../../Hooks/Zustand/Store';
import { arrayUnion } from 'firebase/firestore'

function ViewPageDetail() {

  

  const location = useLocation()
  const param = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const globalState = useUserStore();

  const dataState = location.state

  const [dataDetail, setDataDetail] = useState(dataState)
  const [activeVariant, setActiveVariant] = useState({})
  const [variantList, setVariantList] = useState([])



  const getDataDetail = () => {
    try {
      setDataDetail(dataState)
    } catch (error) {
      toast({
        title: 'deoapp',
        description: error.message,
        status: 'error',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    }
  }

  const getDataVariant = async () => {

    const conditions = [
      {
        field: 'productId',
        operator: '==',
        value: dataDetail.id
      },
      {
        field: 'type',
        operator: '==',
        value: dataState?.type,
      }
    ];



    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 9999;


    try {
      const res = await getCollectionFirebase(
        'product_variants',
        conditions,
        sortBy,
        limitValue,
      );
      setVariantList(res)
    } catch (error) {
      toast({
        title: 'deoapp',
        description: error.message,
        status: 'error',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    }
  }

  useEffect(() => {
    getDataDetail()
    getDataVariant()

    return () => {
    }
  }, [param.id])


  const handleAddToCart = async () => {

    if (!activeVariant.id) {
      return toast({
        title: 'Deoapp',
        description: 'Silahkan pilih variant produk',
        status: 'warning',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    }

    if (input?.value === '' || input?.value === 0 || input?.value === '0') {
      return toast({
        title: 'Deoapp',
        description: 'Silahkan isi jumlah orderan',
        status: 'warning',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    }

    if (!activeVariant?.id) {
      return toast({
        title: 'Deoapp',
        description: 'Silahkan pilih produk orderan',
        status: 'warning',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    }


    if (!globalState.uid) {
      return toast({
        title: 'deoapp',
        description: 'Anda harus login terlebih dahulu',
        status: 'warning',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    }


    const dataCart = {
      productId: dataDetail?.id,
      productData: dataDetail,
      variantId: activeVariant?.id,
      variantData: activeVariant,
      quantity: input?.value,
    }


    const collectionName = 'product_carts';
    const docName = `${globalState?.uid}-${globalState?.currentProject}`
    const values = {
      userId: globalState.uid,
      carts: arrayUnion(dataCart)
    }
    const collectionNames = 'pipelines';
    const datas = {
      companyId: globalState.currentCompany,
      createdAt: new Date(),
      createdBy: globalState.uid,
      email: globalState.email,
      filesId: 'PYeisfboIZLjxp9djKbq',
      column: 'add to cart',
      name: globalState.name,
      opportunity_value: dataCart.productData.price_int * dataCart.quantity,
      projectId: globalState.currentProject,
      status: 'open',
      type: 'acquisition',
    };
    // return console.log(datas,'val')
    try {
      await addDocumentFirebase(collectionNames, datas, globalState.currentCompany);
      await setDocumentFirebase(collectionName, docName, values, globalState?.currentCompany);
      toast({
        title: 'deoapp',
        description: 'berhasil menambahkan ke cart',
        status: 'success',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });
    } catch (error) {
      toast({
        title: 'deoapp',
        description: error.message,
        status: 'error',
        isClosable: true,
        duration: 9000,
        position: 'top'
      });
    }
  }

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
        useNumberInput({
          min: 0,
          max: dataDetail?.stocks || 0,
        })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()


  const handleOrder = () => {

    const dataCart = {
      productId: dataDetail?.id,
      productData: dataDetail,
      variantId: activeVariant?.id,
      variantData: activeVariant,
      variantList: variantList,
      quantity: input?.value || '1',
    }

    
    navigate('/checkout', {state: dataCart })
  }

  return (
    <Stack spacing={[1, 1, 5]} gap={5}>
      <HStack spacing={5}>
        <BackButtons />
        <Text>Product Detail</Text>
      </HStack>
      <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
        <Stack>
          <Image src={dataState?.images[0] || ''} w={'200px'} alt='image' />

        </Stack>
        <Stack>
          <Text>{param.id}</Text>
          <Text>{param.slug}</Text>
          <Text>{dataDetail.stocks}</Text>
          <Stack spacing={3}>

            {variantList?.length > 0 && (
              <Stack>
                <HStack columns={[1, 1, 3]} gap={3} overflowX='scroll' width={['350px', '400px', '500px']}>
                  {variantList?.map((x, index) => {
                    return (
                      <Stack p={2} maxW='200px' borderRadius='md' cursor={'pointer'} bgColor={activeVariant.id === x.id ? 'gray.700' : 'gray.500'} key={index} onClick={() => setActiveVariant(x)}>
                        <Stack alignItems={'center'} justifyContent='center'>
                          <Image src={x?.images || 'https://picsum.photos/150/150'} w="150px" h={'150px'} objectFit="contain" fallbackSrc={'https://untirta.ac.id/wp-content/uploads/2023/08/placeholder-44.png'} />
                        </Stack>
                        <Divider />
                        <Stack spacing={0} w='170px'>
                          <Text fontSize={'sm'} color='white' fontWeight={500} textTransform='capitalize' noOfLines={1}>{x?.name}</Text>
                          <Text fontSize={'xs'} color='white' fontWeight={500} noOfLines={1}>{x?.description}</Text>
                          <Text fontSize={'xs'} color='white' fontWeight={500}>Rp.{(parseFloat(x?.price || 0))}/Pcs</Text>
                          <Text fontSize={'xs'} color='white' fontWeight={500}>Stok Produk: {x?.stocks || 0}</Text>
                          <Text fontSize={'xs'} color='white' fontWeight={500}>Berat Produk: {x?.weight || 0}g</Text>
                        </Stack>
                      </Stack>
                    )
                  })}


                </HStack>

                <HStack spacing={3}>
                  <Button {...dec} colorScheme="red">-</Button>
                  <Input min={0} defaultValue={0} max={activeVariant?.stocks || 0}  {...input} />
                  <Button {...inc} colorScheme="red">+</Button>
                </HStack>
              </Stack>
            )}
          </Stack>
          <HStack>
            <Button onClick={() => handleAddToCart(dataState)}>Add to Cart</Button>
            <Button onClick={() => handleOrder(activeVariant)}>Orders now</Button>
          </HStack>
        </Stack>


      </HStack>
    </Stack>
  )
}

export default ViewPageDetail