import { Button, HStack, Heading, Select, SimpleGrid, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import ChartsLineOrderV3 from '../../Components/Charts/ChartsLineOrderV3'
import RevanueAnalytics from './Analytics/RevanueAnalytics'
import OrdersAnalytics from './Analytics/OrdersAnalytics'
import useUserStore from '../../Hooks/Zustand/Store'
import { countDocumentsFirebase } from '../../Api/firebaseApi'
import moment from 'moment'

function DashboardPage() {
  const [time, setTime] = useState('14');
  const [labelX, setLabelX] = useState([]);
  const [date, setDate] = useState([]);
  const [paid, setPaid] = useState([]);
  const [order, setOrder] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const globalState = useUserStore();

  const selectTime = (data) => {
    setTime(data);
  };
  
  const getData = async () => {
    try {
      const currentDate = moment();
      let startDate = moment();
      if (time === '1') {
        startDate = moment().subtract(1, 'day');
      } else if (time === '7') {
        startDate = moment().subtract(7, 'days');
      } else if (time === '30') {
        startDate = moment().subtract(30, 'days');
      } else if (time === '14') {
        startDate = moment().subtract(14, 'days');
      } else if (time === '90') {
        startDate = moment().subtract(90, 'days');
      } else {
        startDate = moment().subtract(1, 'day');
      }

      const dates = [];
      const label = [];
      for (let date = moment(startDate); date <= currentDate; date.add(1, 'day')) {
        dates.push(moment(date));
        label.push(moment(date).format('DD-MM-YYYY'));
      }
      setDate(dates)
      setLabelX(label)
      // dates.reverse();
      // const length = await Promise.all(dates.map(async (daily) => {
      //   const nextDate = moment(daily).add(1, 'day');
      //   const conditions = [
      //     { field: 'companyId', operator: '==', value: globalState.currentCompany },
      //     { field: 'projectId', operator: '==', value: globalState.currentProject },
      //     { field: 'lastUpdated', operator: '>=', value: daily.toDate() },
      //     { field: 'lastUpdated', operator: '<=', value: nextDate.toDate() }
      //   ];
      //   const conditions1 = [
      //     { field: 'companyId', operator: '==', value: globalState.currentCompany },
      //     { field: 'projectId', operator: '==', value: globalState.currentProject },
      //     { field: 'status', operator: '==', value: 'ACTIVE' },
      //     { field: 'lastUpdated', operator: '>=', value: daily.toDate() },
      //     { field: 'lastUpdated', operator: '<=', value: nextDate.toDate() }
      //   ];
      //   const res = await countDocumentsFirebase('orders', conditions);
      //   const res1 = await countDocumentsFirebase('payments', conditions1);
      //   return {
      //     date: daily.toDate(),
      //     count: res, // jumlah order
      //     pay: res1   // jumlah order terbayar / paid
      //   };
      // }
      // ));
      // const countValues = length.map((item) => item.count);
      // const payValues = length.map((item) => item.pay);
      // const labelValues = length.map((item) => moment(item.date).format('DD-MM-YYYY'));

      // setOrder(countValues);
      // setPaid(payValues);
      // setLabel(labelValues);

      // const newDatasets = [
      //   {
      //     label: 'Paid',
      //     data: payValues,
      //     borderColor: 'rgba(75, 192, 192, 1)',
      //     pointBackgroundColor: 'rgba(75, 192, 192, 1)',
      //   },
      //   {
      //     label: 'Orders',
      //     data: countValues,
      //     borderColor: 'rgba(192, 75, 192, 1)',
      //     pointBackgroundColor: 'rgba(192, 75, 192, 1)',
      //   },
      // ];
      // setDatasets(newDatasets);
    } catch (error) {
      console.log(error, 'ini error');
      throw error;
    }
  };


  useEffect(() => {
    getData();
  }, [time, globalState.currentCompany, globalState.currentProject]);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>Dashboard</Heading>
      </HStack>
      <Stack spacing={3}>
        <SimpleGrid columns={[1, 1, 2]} gap={3}>
          <Stack>
            {/* <Stack width={'30%'}>
              <Select size={'sm'} borderRadius="md" placeholder="Select option" onChange={(e) => setTime(e.target.value)} value={time}>
                <option value="1">1 Day</option>
                <option value="7">1 Week</option>
                <option value="14">2 Week</option>
                <option value="30">1 Month</option>
                <option value="90">3 Month</option>
              </Select>
            </Stack> */}
            <ChartsLineOrderV3
              collectionNames={['orders', 'payments']}
              conditionsArray={date?.map((x) => ([
                [
                  { field: 'companyId', operator: '==', value: globalState.currentCompany },
                  { field: 'projectId', operator: '==', value: globalState.currentProject },
                  { field: 'lastUpdated', operator: '>=', value: x.toDate() },
                  { field: 'lastUpdated', operator: '<=', value: moment(x).add(1, 'day').toDate() }
                ],
                [
                  { field: 'companyId', operator: '==', value: globalState.currentCompany },
                  { field: 'projectId', operator: '==', value: globalState.currentProject },
                  { field: 'status', operator: '==', value: 'ACTIVE' },
                  { field: 'lastUpdated', operator: '>=', value: x.toDate() },
                  { field: 'lastUpdated', operator: '<=', value: moment(x).add(1, 'day').toDate() }
                ]
              ]))}
              labels={['Orders', 'Paid Orders']}
              // borderColor={['uhu', 'oohok']}
              borderColor={['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)']}
              isSelect={true}
              selectTime={selectTime}
              chartType="line"
              chartLabel={labelX}
              title="Transactions"
            />
          </Stack>
          <Stack>
            <OrdersAnalytics />
          </Stack>
        </SimpleGrid>
      </Stack>
      <Stack spacing={3}>
        <Heading size={'md'}>Revenue</Heading>
        <Stack>
          <RevanueAnalytics />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default DashboardPage;
