import { Avatar, Box, Button, Divider, FormControl, FormLabel, HStack, IconButton, Image, Input, Radio, RadioGroup, Select, SimpleGrid, Spacer, Stack, Switch, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import { DeleteIcon } from '@chakra-ui/icons';
import { clientTypessense } from '../../Api/Typesense';
import useUserStore from '../../Hooks/Zustand/Store';
import {  getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import { useNavigate, useParams } from 'react-router-dom';

const BulkDiscountPage = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState('varian');
  const toast = useToast();
  const [discount, setDiscount] = useState({
    status: false,
    amount: '',
    description: '',
    type: '',
    name: '' });
  const param = useParams();
  const globalState = useUserStore();
  const navigate = useNavigate();
  const [showSearchResults, setShowSearchResults] = useState();

  const getData = async () => {
    try {

      const get = await getSingleDocumentFirebase('discounts', param.id);
      setDiscount({
        name: get.name || '',
        description: get.description || '',
        type: get.type || '',
        discountDuration: get.discountDuration || '',
        // flatPrice: get.flatPrice || '',
        amount: get.amount || '',
        status: get.status || false
      })
      if(get.variantId) {
        const array = [];        
        await Promise.all(
          get.variantId.map(async (x) => {
            const variant = await getSingleDocumentFirebase('product_variants', x);
            array.push(variant);
          })
        );   
        setData(array);           
      }
      // console.log(array, 'datam');
    } catch (error) {
      console.log('error', error);
    }
  };


  const handleDelete = (id) => {
    console.log(id)
    setData(data.filter((item) => item.id !== id));
  }

  const handleTypesenseSearch = (q) => {
    if (value === 'varian') {
      const searchParameters = {
        q: q,
        query_by: 'name',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc'
      };
      clientTypessense
        .collections('product_variants')
        .documents()
        .search(searchParameters)
        .then((x) => {
          const newData = x.hits.map((y) => { return { ...y.document } })
          setDiscount({ ...discount, variant: newData });
        });
    } else if (value === 'produk') {
      const searchParameters = {
        q: q,
        query_by: 'name',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc'
      };
      clientTypessense
        .collections('product')
        .documents()
        .search(searchParameters)
        .then(async(x) => {
          const newData = x.hits.map((y) => { return { ...y.document } })
          setDiscount({ ...discount, variant: newData });
          // console.log(newData, 'newd')
          // const conditions = [
          //   { field: 'companyId', operator: '==', value: globalState.currentCompany },
          //   { field: 'productId', operator: '==', value: newData[0].id },
          //   { field: 'projectId', operator: '==', value: globalState.currentProject },
          // ]
          // console.log(conditions,'connn')
          // const get = await getCollectionFirebase('product_variants', conditions)
          // setDiscount({ ...discount, variant: get });
          // console.log(get,'get')
        });
    } else if (value === 'category') {
      const searchParameters = {
        q: q,
        query_by: 'title',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc'
      };
      clientTypessense
        .collections('product_categories')
        .documents()
        .search(searchParameters)
        .then(async(x) => {
          const newData = x.hits.map((y) => { return { ...y.document } })
          setDiscount({ ...discount, variant: newData });
          // console.log(newData, 'newd')
          // const conditions = [
          //   { field: 'companyId', operator: '==', value: globalState.currentCompany },
          //   { field: 'productId', operator: '==', value: newData[0].id },
          //   { field: 'projectId', operator: '==', value: globalState.currentProject },
          // ]
          // console.log(conditions,'connn')
          // const get = await getCollectionFirebase('product_variants', conditions)
          // setDiscount({ ...discount, variant: get });
          // console.log(get,'get')
        });
    }

  }
  const handleSubmit = async () => {
    try {
      const datas = {
        name: discount.name || '',
        type: discount.type || '',
        amount: discount.amount || '',
        description: discount.description || '',
        status: discount.status || '',
        startDate: discount.startDate || '',
        endDate: discount.endDate || '',
        // flatPrice: discount.flatPrice,
        variantId: data.map((x) => x.id) || ''
      }
      // console.log(datas)
      const add = await setDocumentFirebase('discounts', param.id, datas)
      const disc = datas.variantId.map(async(x) => {
        await setDocumentFirebase(
          'product_variants',
          x,
          {
            is_promo : discount?.status,
            discount_amount : discount?.amount,
            discount_type : discount?.type,
            discount_start_date: discount?.startDate,
            discount_end_date: discount?.endDate,
            // discount_id: param.id,
          }
        )
      })
      toast({
        title: 'Success',
        description: 'Success Saving Discount!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/discount')
    } catch (error) {
      console.log('error', error)
    }
  }
  // console.log(data,'tabel data')
  // console.log(discount,'disss')
  const handleAdd = async (x) => {
    if(value === 'varian'){
      const newData = {
        images: x.images[0],
        id: x.id,
        name: x.name
      };
      setData([...data, newData]);      
    } else if(value === 'produk') {
      console.log(x,'xxx')
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'productId', operator: '==', value: x.id },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
      ];
      // console.log(conditions, 'connn');
  
      const get = await getCollectionFirebase('product_variants', conditions);
      // console.log(get,'getget')
      if (get && get.length > 0) {
        const newData = get.map((variant) => ({
          images: variant.images[0],
          id: variant.id,
          name: variant.name
        }));
        setData([...data, ...newData]);
      } else {
        toast({
          title: 'Data Not Found',
          description: `Data Category ${x.name} Has Not Found`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } else if(value === 'category') {
      // console.log(x,'xxx')
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'categoryId', operator: 'array-contains', value: x.id },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
      ];
      // if(x.categoriesId){
      //   conditions.push({ field: 'categoryId', operator: 'array-contains', value: x.categoriesId })
      // } else {
      //   conditions.push({ field: 'categoryId', operator: 'array-contains', value: x.id })
      // }
      // console.log(conditions, 'connn');
  
      const get = await getCollectionFirebase('product', conditions);
      // console.log(get,'uhuy')
      if (get && get.length > 0) {
        const getData = get.map(async (y) => {
          const stats = [
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'productId', operator: '==', value: y.id },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
          ];
          const variant = await getCollectionFirebase('product_variants', stats);
          console.log(variant, 'ahay');
          if (variant) {
            const newData = variant.map((variant) => ({
              images: variant.images[0],
              id: variant.id,
              name: variant.name,
            }));
            setData([...data, ...newData]);
          }
        });
      } else {
        toast({
          title: 'Data Not Found',
          description: `Data Category ${x.title} Has Not Found`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }

    }

  }
  // console.log(data,'dtadatata')
  // console.log(value, 'varrr')
  useEffect(() => {
    getData();
  }, [globalState?.currentProject])
  // console.log(discount?.variant)
  return (
    <Box>
      <Stack py={2}>
        <HStack gap={5}>
          <BackButtons />
          <Text fontSize={'xl'} fontWeight={500}>
            Discount Information
          </Text>
          <Spacer />
        </HStack>
        <Divider />
      </Stack>
      <Stack bg={useColorModeValue('white', '')} p={5}>
        <SimpleGrid columns={[1, 1, 2]} spacing={4} width={'100%'}>
          <Stack w={'90%'}>
            <FormControl>
              <FormLabel>Nama Diskon</FormLabel>
              <Input defaultValue={discount?.name} onChange={(e) => setDiscount({ ...discount, name: e.target.value })} />
            </FormControl>   
            <FormControl>
              <Text>Type</Text>
              <Select
                placeholder='Select Discont Type'
                value={discount?.type}
                onChange={(e) => setDiscount({ ...discount, type: e.target.value })}
              >
                <option value='percentage'>Percentage</option>
                <option value='value'>Value</option>
              </Select>

            </FormControl>
            <FormControl>
              <Text>Amount</Text>
              <Input defaultValue={discount?.amount} onChange={(e) => setDiscount({ ...discount, amount: e.target.value })} type='number' />
            </FormControl> 
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type='date' defaultValue={discount?.startDate} onChange={(e) => setDiscount({ ...discount, startDate: e.target.value })} />
            </FormControl>    
            {value === 'varian' ? (
              <FormControl>
                <Stack>
                  <Text>Daftar Variant</Text>

                  <Input
                    type='text'
                    placeholder='Search Variant'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults(e.target.value);
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults && discount?.variant?.length > 0 ? (
                  discount?.variant?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.name}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.name}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAdd(x)}
                      >
                      +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults && discount?.variant?.length == 0 ? (
                    <HStack
                    // key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Variant Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
              </FormControl>
            ) : value === 'produk' ? (     
              <FormControl>
                <Stack>
                  <Text>Daftar Produk</Text>
                  <Input
                    type='text'
                    placeholder='Search Variant'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults(e.target.value);
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults && discount?.variant?.length > 0 ? (
                  discount?.variant?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.name}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.name}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAdd(x)}
                      >
                      +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults && discount?.variant?.length == 0 ? (
                    <HStack
                    // key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Produk Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
              </FormControl>
            ) :     value === 'category' ? (        
              <FormControl>
                <Stack>
                  <Text>Daftar Kategori</Text>
                  <Input
                    type='text'
                    placeholder='Search Category'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults(e.target.value);
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults && discount?.variant?.length > 0 ? (
                  discount?.variant?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.title}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.title}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAdd(x)}
                      >
                      +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults && discount?.variant?.length == 0 ? (
                    <HStack
                    // key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Kategori Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
              </FormControl>
            ): null}
          </Stack>
          <Stack w={'90%'}>
            {/* <FormControl>
              <FormLabel>Flat Price</FormLabel>
              <InputGroup>
                <InputLeftAddon>
      Rp.
                </InputLeftAddon>
                <Input defaultValue={discount?.flatPrice} onChange={(e) => setDiscount({ ...discount, flatPrice: e.target.value })} type='number'/>
              </InputGroup>
            </FormControl>   */}
            <FormControl>
              <Text>Description</Text>
              <Textarea height={'200px'} defaultValue={discount?.description} onChange={(e) => setDiscount({ ...discount, description: e.target.value })} />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <Input type='date' defaultValue={discount?.endDate} onChange={(e) => setDiscount({ ...discount, endDate: e.target.value })} />
            </FormControl> 
            <SimpleGrid columns={[1, 1, 2]}>
              <FormControl alignItems="center" w={'90%'}>
                <FormLabel>Status </FormLabel>
                <HStack>
                  <Text>Tidak Aktif</Text>
                  <Switch
                    isChecked={discount?.status ?? false}
                    onChange={(e) => setDiscount({ ...discount, status: e.target.checked })}
                  />
                  <Text>Aktif</Text>
                </HStack>
              </FormControl>
              <FormControl alignItems="center" w={'90%'}>
                <FormLabel>Bulk Diskon</FormLabel>
                <RadioGroup onChange={setValue} value={value}>
                  <Stack direction={['column', 'column', 'row']}>
                    <Radio value='varian'>Varian</Radio>
                    <Radio value='produk'>Produk</Radio>
                    <Radio value='category'>Category</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>               
            </SimpleGrid>
          </Stack>
        </SimpleGrid>

        <TableContainer p={4}>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Images</Th>
                <Th>Variant Id</Th>
                <Th>Variant Name</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    <Image src={item.images} maxW={'100px'} />
                  </Td>
                  <Td>{item.id}</Td>
                  <Td>{item.name}</Td>
                  <Td>
                    <IconButton
                      colorScheme="red"
                      aria-label="Delete"
                      size="sm"
                      icon={<DeleteIcon />}
                      onClick={() => handleDelete(item.id)}
                    />
                  </Td>
                </Tr>
              ))}

            </Tbody>
          </Table>
        </TableContainer>


        <Button onClick={handleSubmit} mt={'5%'} colorScheme='green'>Submit</Button>
      </Stack>
    </Box>

  )
}

export default BulkDiscountPage