// /* eslint-disable react/prop-types */
// import * as FileSaver from 'file-saver';
// import XLSX from 'sheetjs-style';
// import React from 'react';
// import { Button, Tooltip } from '@chakra-ui/react';

// const ExportExcel = ({
//   excelData,
//   fileName,
//   size,
//   variant,
//   colorScheme,
//   title,
// }) => {
//   const fileType =
//     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//   const fileExtension = '.xlsx';

//   const dataCheck =
//     Array.isArray(excelData) &&
//     excelData.every(
//       (innerArr) =>
//         Array.isArray(innerArr) &&
//         innerArr.every((obj) => typeof obj === 'object')
//     );

//   const exportToExcel = async () => {
//     const sheets = {};
//     let combinedData = [];


//     if (dataCheck) {
//       if (dataCheck === true) {
//         excelData?.forEach((arr) => {
//           combinedData = combinedData.concat(arr); // Combine arrays
//         });

//         const ws = XLSX.utils.json_to_sheet(combinedData);
//         sheets['CombinedData'] = ws;
//       } else {
//         const ws = XLSX.utils.json_to_sheet(excelData);
//         sheets['data'] = ws;
//       }

//       const wb = { Sheets: sheets, SheetNames: ['CombinedData'] };
//       const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//       const data = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(data, fileName + fileExtension);
//     } else {
//       const ws = XLSX.utils.json_to_sheet(excelData);
//       const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//       const data = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(data, fileName + fileExtension);
//     }
//   };

//   return (
//     <>
//       <Tooltip title="Excel Export">
//         <Button
//           size={size}
//           my={2}
//           variant={variant}
//           colorScheme={colorScheme}
//           onClick={() => exportToExcel(fileName)}
//           cursor="pointer"
//         >
//           {title || 'Export to Excel'}
//         </Button>
//       </Tooltip>
//     </>
//   );
// };

// export default ExportExcel;

import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'; // Menggunakan '* as' untuk mengimpor semua dari 'xlsx'
import { Tooltip, useToast } from '@chakra-ui/react';
import { FaFileAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import DynamicButton from '../Components/Buttons/DynamicButton';

const ComponentExportExcel = ({ fileName, excelData }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const toast = useToast()
  
  const exportToExcel = async (excelData) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);

    return toast({
      title: 'Admin Deoapp',
      description: 'The data has been successfully Export.',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
  };

  // const getAllOwnersWithCompanyName = (companies) => {
  //     const allOwnersWithCompanyName = [];

  //     companies.forEach((company) => {
  //         const owners = company.users || [];
  //         const companyName = company.name || ""; // Ambil nama perusahaan

  //         owners.forEach((owner) => {
  //             allOwnersWithCompanyName.push({ ownerId: owner, companyName });
  //         });
  //     });

  //     // Menghilangkan duplikat dengan menggunakan Set
  //     const uniqueOwnersWithCompanyName = allOwnersWithCompanyName.reduce(
  //         (acc, owner) =>
  //             acc.some((item) => item.ownerId === owner.ownerId)
  //                 ? acc
  //                 : [...acc, owner],
  //         []
  //     );

  //     return uniqueOwnersWithCompanyName;
  // };

  // const getAllCompany = async () => {
  //     try {
  //         globalState.setIsLoading(true);
  //         const sortBy = { field: 'name', direction: 'asc' };
  //         const getCompany = await getCollectionFirebase("companies", [], sortBy);
  //         const allOwnersWithCompanyName = await getAllOwnersWithCompanyName(getCompany);
  //         const allOwnerIds = allOwnersWithCompanyName.map((owner) => owner.ownerId);
  //         const usersData = await getUsersByIds(allOwnerIds);
  //         const usersDataWithCompanyName = usersData.map((user) => {
  //             const ownerInfo = allOwnersWithCompanyName.find((owner) => owner.ownerId === user.id);
  //             return { ...user, companyName: ownerInfo ? ownerInfo.companyName : "" };
  //         });
  //         const simplifiedUserData = usersDataWithCompanyName.map((user) => {
  //             return {
  //                 companyName: user.companyName,
  //                 email: user.email,
  //                 name: user.name,
  //                 phone: user.phone || user.phoneNumber || "", // Gunakan phone atau phoneNumber (terserah versi API)
  //             };
  //         });
  //         console.log(simplifiedUserData, "gggg");
  //         globalState.setIsLoading(false);
  //         exportToExcel(simplifiedUserData);
  //     } catch (error) {
  //         console.error(Error in getAllCompany: ${error.message});
  //         // Handle the error as needed, e.g., show an error message to the user
  //         globalState.setIsLoading(false);
  //     }
  // };

  return (
    <>
      <Tooltip title="Excel Export">
        <DynamicButton
          size={'md'}
          action="custom"
          color={'blue'}
          title={'Export'}
          icon={FaFileAlt}
          onClick={() => exportToExcel(excelData)}
        />
      </Tooltip>
    </>
  );
};

export default ComponentExportExcel;

ComponentExportExcel.propTypes = {
  fileName: PropTypes.string.isRequired, 
  excelData: PropTypes.array.isRequired, 
};
