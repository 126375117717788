import { AspectRatio, Box, Button, Container, Heading, HStack, Input } from '@chakra-ui/react'
import React from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'

function IntegrationWhatsappPage() {
  return (
    <Box>
      <HStack>
        <BackButtons/>
        <Heading>Whatsapp</Heading>
      </HStack>
      <Container p='2' shadow='base'>
        <AspectRatio maxW='full' ratio={16 / 9}>
          <iframe
            title='naruto'
            src='https://www.youtube.com/embed/QhBnZ6NPOY0'
            allowFullScreen
          />
        </AspectRatio>

        <Heading mt='2' fontSize='lg'>1. Twilio account SID</Heading>
        <Input type='text' placeholder='Twilio account SID'/>
          
        <Heading mt='2' fontSize='lg'>2. Twilio account Token</Heading>
        <Input type='text' placeholder='Twilio account Token'/>

        <Button width='full' colorScheme='green'>Save</Button>

      </Container>
    </Box>
  )
}

export default IntegrationWhatsappPage