import React from 'react';
import ProductHomePage from '../Pages/Products/ProductHomePage';
import ProductDetailPage from '../Pages/Products/ProductDetailPage';
import CategoryPage from '../Pages/Products/CategoryPage';
import ProductPageV2 from '../Pages/Products/ProductPageV2';
import VariantDetailPage from '../Pages/Products/VariantDetailPage';
import DiscountPage from '../Pages/Products/DiscountPage';
import BulkDiscountPage from '../Pages/Products/BulkDiscountPage';
import BulkProduct from '../Pages/Products/BulkProductPage';
import BulkVariant from '../Pages/Products/BulkVariantPage';
import BulkAddDiscount from '../Pages/Products/BulkAddDiscount';
import BulkCategoryPage from '../Pages/Products/BulkCategoryPage';

const ProductRouter = [
  {
    path: '/product',
    element: <ProductHomePage />,
  },

  {
    path: '/products',
    element: <ProductPageV2 />,
  },

  {
    path: '/products/add/bulk-product',
    element: <BulkProduct />,
  },

  {
    path: '/products/:id',
    element: <ProductDetailPage />,
  },

  {
    path: '/products/:id/bulk-variant',
    element: <BulkVariant />,
  },

  {
    path: '/category',
    element: <CategoryPage />,
  },

  {
    path: '/category/bulk',
    element: <BulkCategoryPage />,
  },

  {
    path: '/products/:id/variant/:variantId',
    element: <VariantDetailPage />,
  },

  {
    path: '/discount',
    element: <DiscountPage />,
  },

  {
    path: '/discount/bulk',
    element: <BulkAddDiscount />,
  },

  {
    path: '/discount/:id',
    element: <BulkDiscountPage />,
  },

];

export default ProductRouter;
