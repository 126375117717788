/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import { Box, Button, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import logo from '../../../assets/image/1.png';

import { AiOutlineAlert } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';


function ChatNoAccess({ phoneWA }) {

  const navigate = useNavigate()

  const handleWhatsapp = () => {
    const message = encodeURIComponent('Hi Admin,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊');
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=${phoneWA}&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  }



  const height = window.innerHeight;

  return (
    <Stack>
      <Stack
        spacing={10}
        pt={20}
        minH={height}
        bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={'center'}
        justifyContent="center"
      >
        <Stack>
          <Stack alignItems={'center'} justifyContent="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bgColor="blackAlpha.500"
              p={10}
              borderRadius="xl"
            >
              <Box>
                <Image
                  w="200px"
                  borderRadius={20}
                  src={logo}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />
              <Stack align='center' spacing={3}>
                <AiOutlineAlert color="gray" size={100} />
                <Text textAlign="center" color={'gray.500'} fontSize="2xl" fontWeight={'bold'}>Warning!</Text>
                <Text fontWeight={500} fontSize="lg" textAlign="center" color={'gray.500'}>Im sorry, but based on your role access, you cannot access this page.</Text>
                <Spacer />

                <HStack space={1}>
                  <Text color={'gray.400'} fontSize={['xs', null, 'sm']}>
                  Register your account or contact admin
                  </Text>
                  <Text
                    color={'gray.400'}
                    fontSize={['xs', null, 'sm']}
                    cursor="pointer"
                    fontWeight="bold"
                    onClick={() => handleWhatsapp()}
                  >
                  Call us !
                  </Text>
                </HStack>
                <Button onClick={() => navigate(-1)}>Back</Button>
              </Stack>


             
            </Stack>
          </Stack>
          <Spacer />

          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ChatNoAccess