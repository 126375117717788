/* eslint-disable react/prop-types */
import { Button, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Stack, Text, useToast } from '@chakra-ui/react'

import { SlOptions } from 'react-icons/sl';
import { LuCheck, LuPin } from 'react-icons/lu';

import React from 'react'
import { updateDocumentFirebase } from '../../../Api/firebaseApi';

function SettingCardCustomer({ data }) {

  const toast = useToast()

  const handleSettingCustomer = async (type) => {

    const collectionName = 'chat_customer';
    const docName = data.id;

    let dataUpdate = {}
    if (type === 'pin') {
      dataUpdate = {
        isPin: data?.isPin === undefined || data?.isPin === false ? true : false,
      };
    }else{
      dataUpdate = {
        isResolved: data?.isResolved === undefined || data?.isResolved === false ? true : false,
      };
    }


    try {
      await updateDocumentFirebase(collectionName, docName, dataUpdate);
      toast({
        status: 'success',
        duration: 9000,
        title: `Success add ${type} this customer ${type === 'pin' ? dataUpdate.isPin : dataUpdate.isResolved}.`,
        isClosable: true,
      });

    } catch (error) {
      toast({
        status: 'error',
        duration: 9000,
        title: error.message,
        isClosable: true,
      });
    }
  }

  return (
    <Stack>
      <Popover size={'sm'}>
        <PopoverTrigger>
          <Button size={'xs'} variant={'ghost'}>
            <SlOptions />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Setting</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Stack>
                <Button w={'full'} size={'sm'} colorScheme='blue' onClick={() => handleSettingCustomer('pin')}>
                  <HStack>
                    <LuPin />
                    <Text>Pin Message</Text>
                  </HStack>

                </Button>
                <Button w={'full'} size={'sm'} colorScheme='green' onClick={() => handleSettingCustomer('resolve')}>
                  <HStack>
                    <LuCheck />
                    <Text>Resolved</Text>
                  </HStack>
                </Button>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Stack >
  )
}

export default SettingCardCustomer