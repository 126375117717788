import { Avatar, Box, Button, Checkbox, Flex, FormControl, FormLabel, HStack, Heading, IconButton, Image, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, SimpleGrid, Spacer, Stack, Switch, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import useUserStore from '../../Hooks/Zustand/Store'
import { addDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi'
import { useParams } from 'react-router-dom'
import { DeleteIcon, Search2Icon } from '@chakra-ui/icons'
import { clientTypessense } from '../../Api/Typesense'

const VoucherSetting = () => {
  const globalState = useUserStore();
  const [customer, setCustomer] = useState()
  const [modal, setModal] = useState();
  const [oneUse, setOneUse] = useState(false)
  const [showSearchResults, setShowSearchResults] = useState({
    customer: '',
    variant: ''
  });
  const [value, setValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const param = useParams();
  const [data, setData] = useState();
  const [dataTabel, setDataTabel] = useState([]);
  const [dataTabelCustomer, setDataTabelCustomer] = useState([]);
  const toast = useToast();

  const getData = async () => {
    try {
      // const get = await getCollectionFirebase('vouchers', conditions)
      const get = await getSingleDocumentFirebase('vouchers', param.id)
      // console.log(get, 'getttt')
      setData({
        name: get.name || '',
        key: get.key || '',
        type: get.type || '',
        endDate: get.endDate || '',
        startDate: get.startDate || '',
        minimumQuantity: get.minimumQuantity || 0,
        value: get.value || 0,
        maxValue: get.maxValue || 0,
        reusable: get.reusable || false,
        minimumPurchase: get.minimumPurchase || '',
        status: get.customer || false,
        stock: get.stock || 0,
        description: get.description || '',
        module: get.module || '',
      })
      if(get.variantId) {
        const array = [];        
        await Promise.all(
          get.variantId.map(async (x) => {
            const variant = await getSingleDocumentFirebase('product_variants', x);
            array.push(variant);
          })
        );   
        setDataTabel(array);           
      }
      if(get.customerId) {
        const array = [];        
        await Promise.all(
          get.customerId.map(async (x) => {
            const variant = await getSingleDocumentFirebase('customer', x);
            // console.log(variant,'car')
            array.push(variant);
          })
        );   
        // console.log(array,'arrats')
        setDataTabelCustomer(array);           
      }
      // setDataTabel(get.productId || '')
      // setDataTabelCustomer(get.customerId || '')
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleDelete = (id) => {
    // console.log(id)
    setDataTabel(dataTabel.filter((item) => item.id !== id));
  }

  const handleDeleteCustomer = (id) => {
    // console.log(id)
    setDataTabelCustomer(dataTabelCustomer.filter((item) => item.id !== id));
  }

  const handleTypesenseSearch = (q) => {
    if (value === 'varian') {
      const searchParameters = {
        q: q,
        query_by: 'name',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc'
      };
      clientTypessense
        .collections('product_variants')
        .documents()
        .search(searchParameters)
        .then((x) => {
          const newData = x.hits.map((y) => { return { ...y.document } })
          setData({ ...data, variant: newData });
        });
    } else if (value === 'produk') {
      const searchParameters = {
        q: q,
        query_by: 'name',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc'
      };
      clientTypessense
        .collections('product')
        .documents()
        .search(searchParameters)
        .then(async(x) => {
          const newData = x.hits.map((y) => { return { ...y.document } })
          setData({ ...data, variant: newData });
          // console.log(newData, 'newd')
          // const conditions = [
          //   { field: 'companyId', operator: '==', value: globalState.currentCompany },
          //   { field: 'productId', operator: '==', value: newData[0].id },
          //   { field: 'projectId', operator: '==', value: globalState.currentProject },
          // ]
          // console.log(conditions,'connn')
          // const get = await getCollectionFirebase('product_variants', conditions)
          // setData({ ...data, variant: get });
          // console.log(get,'get')
        });
    } else if (value === 'category') {
      const searchParameters = {
        q: q,
        query_by: 'title',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc'
      };
      clientTypessense
        .collections('product_categories')
        .documents()
        .search(searchParameters)
        .then(async(x) => {
          const newData = x.hits.map((y) => { return { ...y.document } })
          setData({ ...data, variant: newData });
          // console.log(newData, 'newd')
          // const conditions = [
          //   { field: 'companyId', operator: '==', value: globalState.currentCompany },
          //   { field: 'productId', operator: '==', value: newData[0].id },
          //   { field: 'projectId', operator: '==', value: globalState.currentProject },
          // ]
          // console.log(conditions,'connn')
          // const get = await getCollectionFirebase('product_variants', conditions)
          // setData({ ...data, variant: get });
          // console.log(get,'get')
        });
    }
  }

  const handleTypesenseCustomerSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'email',
      filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
      sort_by: '_text_match:desc'
    };
    clientTypessense
      .collections('customer')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        console.log(newData,'newData')
        setData({ ...data, customer: newData });
      });
  }

  const handleAdd = async (x) => {
    if(value === 'varian'){
      const newData = {
        images: x.images[0],
        id: x.id,
        name: x.name
      };
      setDataTabel([...dataTabel, newData]); 
      toast({
        title: 'Variant Added',
        description: `Data Variant ${x.name} Added`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });     
    } else if(value === 'produk') {
      // console.log(x,'xxx')
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'productId', operator: '==', value: x.id },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
      ];
      // console.log(conditions, 'connn');
  
      const get = await getCollectionFirebase('product_variants', conditions);
      // console.log(get,'getget')
      if (get && get.length > 0) {
        const newData = get.map((variant) => ({
          images: variant.images[0],
          id: variant.id,
          name: variant.name
        }));
        setDataTabel([...dataTabel, ...newData]);
        toast({
          title: 'Variant Added',
          description: `Data Variant ${x.name} Added`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Data Not Found',
          description: `Data Category ${x.name} Has Not Found`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } else if(value === 'category') {
      // console.log(x,'xxx')
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'categoryId', operator: 'array-contains', value: x.id },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
      ];
      // if(x.categoriesId){
      //   conditions.push({ field: 'categoryId', operator: 'array-contains', value: x.categoriesId })
      // } else {
      //   conditions.push({ field: 'categoryId', operator: 'array-contains', value: x.id })
      // }
      // console.log(conditions, 'connn');
  
      const get = await getCollectionFirebase('product', conditions);
      // console.log(get,'uhuy')
      if (get && get.length > 0) {
        const getData = get.map(async (y) => {
          const stats = [
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'productId', operator: '==', value: y.id },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
          ];
          const variant = await getCollectionFirebase('product_variants', stats);
          // console.log(variant, 'ahay');
          if (variant) {
            const newData = variant.map((variant) => ({
              images: variant.images[0],
              id: variant.id,
              name: variant.name,
            }));
            setDataTabel([...dataTabel, ...newData]);
            toast({
              title: 'Variant Added',
              description: `Data Variant ${x.title} Added`,
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          }
        });
      } else {
        toast({
          title: 'Data Not Found',
          description: `Data Category ${x.title} Has Not Found`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }

    }

  }

  const handleAddCustomer = (x) => {
    // return console.log(x, 'xxx')
    const newData = {
      id: x.id,
      name: x.name,
      phoneNumber: x.phone_number,
      email: x.email
    };
    setDataTabelCustomer([...dataTabelCustomer,  newData]);  
    toast({
      title: 'Customer Added',
      description: `Data Customer ${x.name} Added`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  }
  // console.log(data?.status,'status')
  const handleSubmit = async () => {
    try {
      const value = {
        name: data.name || '',
        key: data.key || '',
        type: data.type || '',
        startDate: data.startDate || '',
        endDate: data.endDate || '',
        value: parseInt(data.value) || 0,
        maxValue: parseInt(data.maxValue) || 0,
        projectId: globalState.currentProject,
        stock: parseInt(data.stock) || 0,
        description: data.description || '', 
        module: data.module || '',
        reusable: data.reusable || false,
        minimumQuantity: parseInt(data.minimumQuantity) || '', 
        minimumPurchase: parseInt(data.minimumPurchase) || '', 
        customer: data.status || false,
        customerId: data.status && dataTabelCustomer ? dataTabelCustomer.map((x) => x.id) : [],
        variantId: dataTabel?.map((x) => x.id) || '',
        category: 'point'
      }
      // console.log(value,'val')
      const submit = await setDocumentFirebase('vouchers', param.id, value)
      toast({
        title: 'Success',
        description: 'Voucher Data Succes Updated!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (error) {
      console.log(error, 'error')
    }
  }
  // console.log(data?.variant, 'dataaaa')
  useEffect(() => {
    getData();
  }, [globalState.currentCompany, globalState.currentProject])

  return (
    <Stack p={3}>
      <HStack gap={5}>
        <BackButtons />
        <Heading
          size={'md'}
          textTransform='capitalize'>
                    Voucher Setting
        </Heading>
        <Spacer />
      </HStack>
      <Stack p={3} mt={'3%'}>
        <SimpleGrid columns={[1, 1, 2]} w={'100%'}>
          <Stack w={'95%'}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input defaultValue={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })}/>
            </FormControl>
            <FormControl>
              <FormLabel>key</FormLabel>
              <Input defaultValue={data?.key} onChange={(e) => setData({ ...data, key: e.target.value })}/>
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select value={data?.type} placeholder='Select option' onChange={(e) => setData({ ...data, type: e.target.value })}>
                <option value='percentage'>Percentage</option>
                <option value='amount'>Amount</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Value</FormLabel>
              <Input type='number' defaultValue={data?.value} onChange={(e) => setData({ ...data, value: e.target.value })}/>
            </FormControl>
            <FormControl>
              <FormLabel>Minimum Purchase</FormLabel>
              <InputGroup>
                <InputLeftAddon>
              Rp. 
                </InputLeftAddon>
                <Input type='number' defaultValue={data?.minimumPurchase} onChange={(e) => setData({ ...data, minimumPurchase: e.target.value })} />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type='date' defaultValue={data?.startDate} onChange={(e) => setData({ ...data, startDate: e.target.value })} />
            </FormControl>
            {/* {data?.status ? (
              <FormControl>
                <Stack>
                  <Text>Daftar Customer</Text>
                  <Input
                    type='text'
                    placeholder='Search Customer'
                    onChange={(e) => {
                      setShowSearchResults({...showSearchResults, customer: e.target.value});
                      handleTypesenseCustomerSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults.customer && data?.customer?.length > 0 ? (
                  data?.customer?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.name}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.name}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAddCustomer(x)}
                      >
            +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults.customer && (!data || !data.customer || data.customer.length === 0) ? (
                    <HStack
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Customer Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
                <Button
                  colorScheme='green'
                  onClick={() => {
                    setShowModal(true); 
                    setModal('customer')
                  }}
                >
    +
                </Button>
              </FormControl>
            ) : null } */}
            {value ? 
              <FormControl>
                <Button
                  mx={'35%'}
                  colorScheme='green'
                  onClick={() => {
                    setShowModal(true); 
                    setModal('variant');
                  }}
                  leftIcon={<Search2Icon />}
                >
  Search Variant
                </Button>
              </FormControl>  
              : <></>          
            }

          </Stack>
          <Stack w={'95%'}>
            <FormControl>
              <FormLabel>Stock</FormLabel>
              <Input type='number' defaultValue={data?.stock} onChange={(e) => setData({ ...data, stock: e.target.value })} />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea height='120px' defaultValue={data?.description} onChange={(e) => setData({ ...data, description: e.target.value })}/>
            </FormControl>
            <FormControl>
              <FormLabel>Maximum Value</FormLabel>
              <InputGroup>
                <InputLeftAddon>
              Rp. 
                </InputLeftAddon>
                <Input type='number' defaultValue={data?.maxValue} onChange={(e) => setData({ ...data, maxValue: e.target.value })} isDisabled={data?.type === 'amount'}  />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Minimum Quantity</FormLabel>
              <Input type='number' defaultValue={data?.minimumQuantity} onChange={(e) => setData({ ...data, minimumQuantity: e.target.value })} />
            </FormControl>
            <FormControl>
              <FormLabel>End Date</FormLabel>
              <Input type='date' defaultValue={data?.endDate} onChange={(e) => setData({ ...data, endDate: e.target.value })} />
            </FormControl>

            {/* {value === 'varian' ? (
              <FormControl>
                <Stack>
                  <Text>Daftar Variant</Text>

                  <Input
                    type='text'
                    placeholder='Search Variant'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults({...showSearchResults, variant: e.target.value});
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults.variant && data?.variant?.length > 0 ? (
                  data?.variant?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.name}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.name}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAdd(x)}
                      >
                      +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults.variant && data?.variant?.length == 0 ? (
                    <HStack
                    // key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Variant Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
              </FormControl>
            ) : value === 'produk' ? (     
              <FormControl>
                <Stack>
                  <Text>Daftar Produk</Text>
                  <Input
                    type='text'
                    placeholder='Search Variant'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults({...showSearchResults, variant: e.target.value});
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults.variant && data?.variant?.length > 0 ? (
                  data?.variant?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.name}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.name}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAdd(x)}
                      >
                      +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults.variant && data?.variant?.length == 0 ? (
                    <HStack
                    // key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Produk Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
              </FormControl>
            ) :     value === 'category' ? (        
              <FormControl>
                <Stack>
                  <Text>Daftar Kategori</Text>
                  <Input
                    type='text'
                    placeholder='Search Category'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults({...showSearchResults, variant: e.target.value});
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults.variant && data?.variant?.length > 0 ? (
                  data?.variant?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.title}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.title}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAdd(x)}
                      >
                      +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  showSearchResults.variant && data?.variant?.length == 0 ? (
                    <HStack
                    // key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Box>
                        <Text>Kategori Tidak Ditemukan</Text>
                      </Box>
                    </HStack>
                  ) : (
                    <></>
                  )
                )}
                <Button
                  colorScheme='green'
                  onClick={() => {
                    setShowModal(true); 
                    setModal('variant')
                  }}
                >
    +
                </Button>
              </FormControl>
            ): null} */}

            {data?.status ? 
              <FormControl >
                <Button
                  mx={'35%'}
                  colorScheme='green'
                  onClick={() => {
                    setShowModal(true); 
                    setModal('customer')
                  }}
                  leftIcon={<Search2Icon />}
                >
                 Search Customer
                </Button>
              </FormControl> 
              : <></>           
            }

          </Stack>
        </SimpleGrid>
        <Flex justifyContent={'space-between'} width={'100%'}>
          {/* <Stack>
            <FormControl>
              <FormLabel>Modules</FormLabel>
              <HStack>
                <Checkbox 
                  value={'ecommerce'} 
                  isChecked={data?.module?.includes('ecommerce')} 
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newValue = 'ecommerce';
                    const updatedModules = isChecked ? [...(data?.module || []), newValue] : data?.module?.filter((module) => module !== newValue);
                    setData({ ...data, module: updatedModules });
                  }}
                >
  E-commerce
                </Checkbox>
                <Checkbox 
                  value={'crm'} 
                  isChecked={data?.module?.includes('crm')} 
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newValue = 'crm';
                    const updatedModules = isChecked ? [...(data?.module || []), newValue] : data?.module?.filter((module) => module !== newValue);
                    setData({ ...data, module: updatedModules });
                  }}
                >
  CRM
                </Checkbox>
              </HStack>
            </FormControl>          
          </Stack> */}
          <Stack>
            <FormControl alignItems="center">
              <FormLabel>Bulk Diskon</FormLabel>
              <RadioGroup onChange={setValue} value={value}>
                <Stack direction={['column', 'column', 'row']}>
                  <Radio value='varian'>Varian</Radio>
                  <Radio value='produk'>Produk</Radio>
                  <Radio value='category'>Category</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>                     
          </Stack>
          <Stack>
            <FormControl>
              <FormLabel>Customer Select</FormLabel>
              <HStack>
                <Text>Off</Text>
                <Switch
                  isChecked={data?.status ?? false}
                  onChange={(e) => setData({ ...data, status: e.target.checked })}
                />
                <Text>On</Text>
              </HStack>
            </FormControl> 
          </Stack>
          <Stack>
            <FormControl>
              <FormLabel>Multiple Choice</FormLabel>
              <HStack>
                <Text>On</Text>
                <Switch
                  isChecked={data?.reusable ?? false}
                  onChange={(e) => setData({ ...data, reusable: e.target.checked })}
                />
                <Text>Off</Text>
              </HStack>
            </FormControl>            
          </Stack>
        </Flex>
        <SimpleGrid columns={[1, 1, 2]} spacing={8}>
          <Box>
            <TableContainer p={4} boxShadow="md" borderRadius="md">
              <Table size='sm' variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Customer Id</Th>
                    <Th>Customer Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Email</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.status && dataTabelCustomer?.map((item, index) => (
                    <Tr key={index} _hover={{ bg: 'gray.100' }}>
                      <Td>{item.id}</Td>
                      <Td>{item.name}</Td>
                      <Td>{item.phoneNumber}</Td>
                      <Td>{item.email}</Td>
                      <Td>
                        <IconButton
                          colorScheme="red"
                          aria-label="Delete"
                          size="sm"
                          icon={<DeleteIcon />}
                          onClick={() => handleDeleteCustomer(item.id)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box>
            <TableContainer p={4} boxShadow="md" borderRadius="md">
              <Table size='sm' variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Images</Th>
                    <Th>Variant Id</Th>
                    <Th>Variant Name</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataTabel?.map((item, index) => (
                    <Tr key={index} _hover={{ bg: 'gray.100' }}>
                      <Td>
                        <Image src={item.images} maxW={'100px'} />
                      </Td>
                      <Td>{item.id}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        <IconButton
                          colorScheme="red"
                          aria-label="Delete"
                          size="sm"
                          icon={<DeleteIcon />}
                          onClick={() => handleDelete(item.id)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </SimpleGrid>
        <Button onClick={handleSubmit} colorScheme='green' mt={'3%'}>Submit</Button>
      </Stack>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modal === 'customer' ? 'Search Customer' : 'Search Variant'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modal === 'customer' && data?.status ? (
              <FormControl>
                <Stack>
                  <Text>Daftar Customer</Text>
                  <Input
                    type='text'
                    placeholder='Search Customer'
                    onChange={(e) => {
                      setShowSearchResults({...showSearchResults, customer: e.target.value});
                      handleTypesenseCustomerSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults.customer && data?.customer?.length > 0 ? (
                  data?.customer?.map((x, index) => (
                    <HStack
                      key={index}
                      p='2'
                      zIndex={99}
                      width={'auto'}
                      borderBottom='1px'
                    >
                      <Avatar
                        name={x.name}
                        src={x.images ? x.images[0] : ''}
                      />
                      <Box>
                        <Text>{x.name}</Text>
                        <Text>{x.id}</Text>
                      </Box>
                      <Spacer />
                      <Button
                        colorScheme='green'
                        onClick={() => handleAddCustomer(x)}
                      >
                  +
                      </Button>
                    </HStack>
                  ))
                ) : (
                  <HStack
                    p='2'
                    zIndex={99}
                    width={'auto'}
                    borderBottom='1px'
                  >
                    <Box>
                      <Text>Customer Tidak Ditemukan</Text>
                    </Box>
                  </HStack>
                )}
              </FormControl>
            ) : null }

            {modal !== 'customer' && (value === 'varian' || value === 'produk' || value === 'category') ? (
              <FormControl>
                <Stack>
                  <Text>Daftar {value === 'varian' ? 'Variant' : value === 'produk' ? 'Produk' : 'Kategori'}</Text>
                  <Input
                    type='text'
                    placeholder={`Search ${value === 'varian' ? 'Variant' : value === 'produk' ? 'Produk' : 'Kategori'}`}
                    onChange={(e) => {
                      setShowSearchResults({...showSearchResults, variant: e.target.value});
                      handleTypesenseSearch(e.target.value);
                    }}
                  />
                </Stack>
                {showSearchResults.variant && data?.variant?.length > 0 ? (
                  <Stack>
                    {value === 'varian' || value === 'produk' ? (
                      data?.variant?.map((x, index) => (
                        <HStack
                          key={index}
                          p='2'
                          zIndex={99}
                          width={'auto'}
                          borderBottom='1px'
                        >
                          <Avatar
                            name={x.name}
                            src={x.images ? x.images[0] : ''}
                          />
                          <Box>
                            <Text>{x.name}</Text>
                            <Text>{x.id}</Text>
                          </Box>
                          <Spacer />
                          <Button
                            colorScheme='green'
                            onClick={() => handleAdd(x)}
                          >
                      +
                          </Button>
                        </HStack>
                      ))
                    ) : (
                      data?.variant?.map((x, index) => (
                        <HStack
                          key={index}
                          p='2'
                          zIndex={99}
                          width={'auto'}
                          borderBottom='1px'
                        >
                          <Avatar
                            name={x.title}
                            src={x.images ? x.images[0] : ''}
                          />
                          <Box>
                            <Text>{x.title}</Text>
                            <Text>{x.id}</Text>
                          </Box>
                          <Spacer />
                          <Button
                            colorScheme='green'
                            onClick={() => handleAdd(x)}
                          >
                      +
                          </Button>
                        </HStack>
                      ))
                    )}
                  </Stack>
                ) : (
                  <HStack
                    p='2'
                    zIndex={99}
                    width={'auto'}
                    borderBottom='1px'
                  >
                    <Box>
                      <Text>{`${value === 'varian' ? 'Variant' : value === 'produk' ? 'Produk' : 'Kategori'} Tidak Ditemukan`}</Text>
                    </Box>
                  </HStack>
                )}
              </FormControl>
            ) : null}
          </ModalBody>
          <ModalFooter>
            {/* <Button mr={'40%'} onClick={() => setOneUse(true)} colorScheme={oneUse ? 'green' : 'gray'}>
  Set For One Use
            </Button> */}
            <Button colorScheme="blue" mr={3} onClick={() => setShowModal(false)}>
        Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>

  )
}

export default VoucherSetting