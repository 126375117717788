import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';

import ChatComponentAdmin from './ChatComponentAdmin';
import { db } from '../../Config/firebase';
import { doc, onSnapshot } from '@firebase/firestore';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';

function ChatConversationAdmin() {
  const [customer, setCustomer] = useState();
  const [chatConfig, setChatConfig] = useState();
  const param = useParams();
  const toast = useToast()

  const globalState = useUserStore();

  const userId = globalState?.uid;
  const companyId = globalState?.currentCompany;
  const projectId = globalState?.currentProject;
  const accessTokenDb = globalState?.accessToken;

  const getDataConfig = async () => {
    if(!param?.id) return
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 1;
    try {
      const res = await getCollectionFirebase(
        'chat_config',
        conditions,
        sortBy,
        limitValue
      );
      setChatConfig(res[0]);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getDataConfig();

    return () => {};
  }, [globalState.currentProject]);

  useEffect(() => {
    if(!param?.id) return

    const unsub = onSnapshot(doc(db, 'chat_customer', param.id), (doc) => {
      const data = doc.data();
      setCustomer({ ...data, id: doc.id });
    });

    return () => {
      unsub();
    };
  }, [param.id]);

  return (
    <Flex height="100vh" >
      <Flex direction="column" flex="1" position={'relative'}>
        {customer ? (
          <ChatComponentAdmin
            data={customer}
            chatConfig={chatConfig}
            companyId={companyId}
            projectId={projectId}
            userId={userId}
            accessTokenDb={accessTokenDb}
            globalState={globalState}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
  );
}

export default ChatConversationAdmin;
