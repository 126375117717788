import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Image } from '@chakra-ui/image';
import { AspectRatio, Box, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import React, { useState } from 'react';
import { Input } from '@chakra-ui/input';
import { Textarea } from '@chakra-ui/textarea';
import { Select, useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import AsyncSelect from 'react-select/async';

const ModalCreateHomeSection = ({ modal, data, handleSave, selectedTemplate, setSelectedTemplate, setNewSectionInput, newSectionInput, handleChangeSectionDetail, addNewData, handleChangeSectionImage, globalState }) => {
  const [selectedType, setSelectedType] = useState(data && data.length > 0 ? data[0] : null);
  const [showAsset, setShowAsset] = useState(false);
  const toast = useToast();

  const handleChooseProduct = () => {
    setShowAsset(true);
  };

  const handleChooseManual = () => {
    addNewData(selectedType?.title);
    setShowAsset(false);
  };

  const handleTypesenseSearch = async (inputValue) => {
    const searchParameters = {
      q: inputValue,
      query_by: 'name',
      filter_by: `companyId: ${globalState?.currentCompany}`,
      per_page: 25,
    };

    try {
      const searchData = await clientTypessense.collections('product').documents().search(searchParameters);

      const hits = searchData.hits;

      const newData = hits.map((hit) => ({
        value: hit.document.name,
        label: hit.document.name,
        id: hit.document.id,
      }));

      return newData;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleAddProduct = async (selectedOption) => {
    try {
      const res = await getSingleDocumentFirebase('product', selectedOption?.id);
      console.log(res,'ini data res push')

      const newProductAdd = {
        image: res?.images[0] || '',
        image1: res?.images[0] || '',
        image2: res?.images[1] || '',
        link: 'https://example.com/new-link/',
        name: res?.name || '',
        price: res?.price_int || 0,
        discount: res?.discount_amount || '0',
        launching: res?.lastUpdated ,
      };

      setNewSectionInput((prevData) => {
        return {
          ...prevData,
          data: [...prevData.data, newProductAdd],
        };
      });
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <Modal
      onClose={modal.onClose}
      isOpen={modal.isOpen}
      size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Home Section</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Section Type</FormLabel>
            <SimpleGrid
              columns={3}
              spacing={2}>
              {data?.map((x, i) => (
                <Box
                  key={i}
                  onClick={() => setSelectedType(x)}
                  cursor={'pointer'}
                  align={'center'}
                  p={2}
                  borderRadius={'md'}
                  borderColor={selectedType === x ? 'blue.400' : 'gray.50'}
                  borderWidth={selectedType === x ? '2px' : '1px'}
                  color={selectedType === x ? 'white' : 'black'}
                  backgroundColor={selectedType === x ? 'blue.400' : 'white'}
                  textAlign={'center'}
                  justifyContent={'center'}
                  _hover={{ bg: 'blue.100' }}>
                  {x?.title}
                </Box>
              ))}
            </SimpleGrid>
          </FormControl>

          {selectedType?.title === 'Banner Slider' ? (
            <>
              <SimpleGrid
                columns={2}
                py={2}
                spacing={2}>
                {selectedType?.templates?.map((x, i) => (
                  <Box
                    key={i}
                    align={'center'}
                    onClick={() => setSelectedTemplate(x)}
                    cursor={'pointer'}
                    border={selectedTemplate?.id === x?.id ? '1px' : null}
                    borderColor={selectedTemplate?.id === x?.id ? 'blue.300' : null}
                    borderRadius={'md'}>
                    <AspectRatio maxW={200}>
                      <Image src={x?.image} />
                    </AspectRatio>
                    <Text
                      fontWeight={500}
                      fontSize={14}>
                      {x?.title}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
            </>
          ) : selectedType?.title === 'Hero' ? (
            <>
              <SimpleGrid
                columns={2}
                py={2}
                spacing={2}>
                {selectedType?.templates?.map((x, i) => (
                  <Box
                    key={i}
                    align={'center'}
                    onClick={() => setSelectedTemplate(x)}
                    cursor={'pointer'}
                    border={selectedTemplate?.id === x?.id ? '1px' : null}
                    borderColor={selectedTemplate?.id === x?.id ? 'blue.300' : null}
                    borderRadius={'md'}>
                    <AspectRatio maxW={200}>
                      <Image src={x?.image} />
                    </AspectRatio>
                    <Text
                      fontWeight={500}
                      fontSize={14}>
                      {x?.title}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
              <Box my={4}>
                <Stack>
                  <Text>Header</Text>
                  <Input
                    placeholder='Enter your header'
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, header: e.target.value })}
                  />
                </Stack>
                <Stack my={2}>
                  <Text>Description Content</Text>
                  <Textarea
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, content: e.target.value })}
                    placeholder='Enter your description content'
                  />
                </Stack>
              </Box>
            </>
          ) : selectedType?.title === 'Product Highlights' ? (
            <>
              <SimpleGrid
                columns={2}
                py={2}
                spacing={2}>
                {selectedType?.templates?.map((x, i) => (
                  <Box
                    key={i}
                    align={'center'}
                    onClick={() => setSelectedTemplate(x)}
                    cursor={'pointer'}
                    border={selectedTemplate?.id === x?.id ? '1px' : null}
                    borderColor={selectedTemplate?.id === x?.id ? 'blue.300' : null}
                    borderRadius={'md'}>
                    <AspectRatio maxW={200}>
                      <Image src={x?.image} />
                    </AspectRatio>
                    <Text
                      fontWeight={500}
                      fontSize={14}>
                      {x?.title}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
              <Box my={4}>
                <Stack>
                  <Text>Header</Text>
                  <Input
                    placeholder='Enter your header'
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, header: e.target.value })}
                  />
                </Stack>
                <Stack my={2}>
                  <Text>Description Content</Text>
                  <Textarea
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, content: e.target.value })}
                    placeholder='Enter your description content'
                  />
                </Stack>
              </Box>
            </>
          ) : selectedType?.title === 'Full Image' ? (
            <>
              <SimpleGrid
                columns={2}
                py={2}
                spacing={2}>
                {selectedType?.templates?.map((x, i) => (
                  <Box
                    key={i}
                    align={'center'}
                    onClick={() => setSelectedTemplate(x)}
                    cursor={'pointer'}
                    border={selectedTemplate?.id === x?.id ? '1px' : null}
                    borderColor={selectedTemplate?.id === x?.id ? 'blue.300' : null}
                    borderRadius={'md'}>
                    <AspectRatio maxW={200}>
                      <Image src={x?.image} />
                    </AspectRatio>
                    <Text
                      fontWeight={500}
                      fontSize={14}>
                      {x?.title}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
              <Box my={4}>
                <Stack>
                  <Text>Header</Text>
                  <Input
                    placeholder='Enter your header'
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, header: e.target.value })}
                  />
                </Stack>
                <Stack my={2}>
                  <Text>Description Content</Text>
                  <Textarea
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, content: e.target.value })}
                    placeholder='Enter your description content'
                  />
                </Stack>
              </Box>
            </>
          ) : selectedType?.title === 'Sponsor and Logo Highlights' ? (
            <>
              <SimpleGrid
                columns={2}
                py={2}
                spacing={2}>
                {selectedType?.templates?.map((x, i) => (
                  <Box
                    key={i}
                    align={'center'}
                    onClick={() => setSelectedTemplate(x)}
                    cursor={'pointer'}
                    border={selectedTemplate?.id === x?.id ? '1px' : null}
                    borderColor={selectedTemplate?.id === x?.id ? 'blue.300' : null}
                    borderRadius={'md'}>
                    <AspectRatio maxW={200}>
                      <Image src={x?.image} />
                    </AspectRatio>
                    <Text
                      fontWeight={500}
                      fontSize={14}>
                      {x?.title}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
              <Box my={4}>
                <Stack>
                  <Text>Header</Text>
                  <Input
                    placeholder='Enter your header'
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, header: e.target.value })}
                  />
                </Stack>
                <Stack my={2}>
                  <Text>Description Content</Text>
                  <Textarea
                    onChange={(e) => setNewSectionInput({ ...newSectionInput, content: e.target.value })}
                    placeholder='Enter your description content'
                  />
                </Stack>
              </Box>
            </>
          ) : null}

          <Stack
            align={'center'}
            // bg='red'
            my={4}>
            {/* {showAsset  ( */}
            <>
              <Text fontWeight={500}>Asset</Text>
              {newSectionInput?.data?.map((x, i) => (
                <>
                  {selectedTemplate?.title?.startsWith('Full Image') || selectedTemplate?.title?.startsWith('Banner Slider') ? (
                    <Stack
                      gap={2}
                      p={3}>
                      <HStack>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Image
                        </Text>
                        <Input
                          w={'70%'}
                          type='file'
                          size={'xs'}
                          onChange={(e) => handleChangeSectionImage(e, 'new', i, 'image')}
                        />
                      </HStack>
                      <HStack>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Link
                        </Text>
                        <Input
                          w={'70%'}
                          size={'xs'}
                          onChange={(e) => handleChangeSectionDetail(e.target.value, 'link', i, 'new')}
                        />
                      </HStack>
                      <Box align={'center'}>
                        <Image
                          w={200}
                          src={x?.image}
                          alt={x?.name}
                        />
                      </Box>
                    </Stack>
                  ) : (
                    <Stack
                      gap={2}
                      p={3}>
                      <FormControl>
                        <FormLabel size={'sm'}>Product Name</FormLabel>
                        <Input
                          defaultValue={x?.name}
                          size='xs'
                          onChange={(e) => handleChangeSectionDetail(e.target.value, 'name', i, 'new')}
                        />
                      </FormControl>
                      {x?.image1 ? (
                        <HStack justify={'center'}>
                          <Image
                            w={200}
                            src={x?.image1}
                            alt={x?.name}
                          />
                          <Image
                            w={200}
                            src={x?.image2}
                            alt={x?.name}
                          />
                        </HStack>
                      ) : (
                        <Image
                          src={x?.image || 'gibbresh.png'}
                          fallbackSrc='https://via.placeholder.com/150'
                          alt={x?.name}
                        />
                      )}
                      <HStack w={'100%'}>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Price
                        </Text>
                        <Input
                          w={'70%'}
                          size={'xs'}
                          defaultValue={x?.price}
                          onChange={(e) => handleChangeSectionDetail(e.target.value, 'price', i, 'new')}
                        />
                      </HStack>
                      <HStack w={'100%'}>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Discount
                        </Text>
                        <Input
                          w={'70%'}
                          size={'xs'}
                          defaultValue={x?.discount}
                          onChange={(e) => handleChangeSectionDetail(e.target.value, 'discount', i, 'new')}
                        />
                      </HStack>
                      <HStack w={'100%'}>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Status Product
                        </Text>
                        <Select
                          w={'70%'}
                          size={'xs'}
                          onChange={(e) => handleChangeSectionDetail(e.target.value, 'status', i, 'new')}>
                          <option value='ready'>Ready</option>
                          <option value='soldOut'>Sold Out</option>
                        </Select>
                      </HStack>
                      <HStack w={'100%'}>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Link product
                        </Text>
                        <Input
                          w={'70%'}
                          size={'xs'}
                          defaultValue={x?.link}
                          onChange={(e) => handleChangeSectionDetail(e.target.value, 'link', i, 'new')}
                        />
                      </HStack>

                      <HStack>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Image 1
                        </Text>
                        <Input
                          w={'70%'}
                          type='file'
                          size={'xs'}
                          onChange={(e) => handleChangeSectionImage(e, 'new', i, 'image1')}
                        />
                      </HStack>
                      <HStack>
                        <Text
                          fontSize={10}
                          w={'30%'}>
                          Image 2
                        </Text>
                        <Input
                          w={'70%'}
                          type='file'
                          size={'xs'}
                          onChange={(e) => handleChangeSectionImage(e, 'new', i, 'image2')}
                        />
                      </HStack>
                    </Stack>
                  )}
                </>
              ))}
            </>

            <Box align={'center'}>
              <Button
                size={'md'}
                width={40}
                colorScheme='blue'
                onClick={() => handleChooseManual()}
                marginRight={4}>
                Add Product Manual
              </Button>

              <Button
                size={'md'}
                width={40}
                colorScheme='blue'
                onClick={() => handleChooseProduct()}>
                Choose Product
              </Button>
            </Box>
            {showAsset && (
              <Box
                width='200px'
                bg='gray'>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleTypesenseSearch}
                  onChange={(selectedOption) => handleAddProduct(selectedOption)}
                  value={null}
                  placeholder='Choose Product'
                />
              </Box>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              size={'sm'}
              colorScheme='red'
              onClick={modal.onClose}>
              Cancel
            </Button>
            <Button
              size={'sm'}
              colorScheme='green'
              onClick={handleSave}>
              Create Section
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCreateHomeSection;

ModalCreateHomeSection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func,
  modal: PropTypes.func,
  handleSave: PropTypes.func,
  setNewSectionInput: PropTypes.func,
  newSectionInput: PropTypes.func,
  handleChangeSectionDetail: PropTypes.func,
  addNewData: PropTypes.func,
  deleteData: PropTypes.func,
  handleGetProduct: PropTypes.func,
  handleChangeSectionImage: PropTypes.func,
  globalState: PropTypes.object,
};
