import React from 'react';
import WebsitesPage from '../Pages/Websites/WebsitesPage';
import WebsiteConfigurationPage from '../Pages/Websites/WebsiteConfigurationPage';
import WebsiteBuilderPage from '../Pages/Websites/WebsiteBuilderPage';
import WebsiteBuilderCreatePage from '../Pages/Websites/WebsiteBuilderCreatePage';
import WebsiteDomainsPage from '../Pages/Websites/WebsiteDomainsPage';


const WebsitesRouter = [
  {
    path: '/websites',
    element: <WebsitesPage />,
  },
  {
    path: '/websites/website-builder',
    element: <WebsiteBuilderPage />,
  },

  {
    path: '/websites/website-configuration',
    element: <WebsiteConfigurationPage />,
  },
  {
    path: '/websites/website-builder/:id/edit',
    element: <WebsiteBuilderCreatePage />,
  },
  {
    path: '/websites/website-domains',
    element: <WebsiteDomainsPage />,
  },
];

export default WebsitesRouter;
