/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Image,
  Stack,
  Text,
  Select,
  useColorModeValue as mode,
} from '@chakra-ui/react';

export const CheckoutProductMeta = (props) => {
  const { image, name, description, variantList } = props;

  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        rounded="lg"
        width="120px"
        height="120px"
        fit="contain"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
      />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="medium">{name}</Text>
          <Select maxW="fit-content" size={'sm'} placeholder="Select variant">
            {variantList.map((variant, index) => (
              <option key={index} value={variant}>
                {variant.name}
              </option>
            ))}
          </Select>
        </Stack>
        {/* {isGiftWrapping && (
          <HStack spacing="1" mt="3" color={mode('gray.600', 'gray.400')}>
            <Icon as={FiGift} boxSize="4" />
            <Link fontSize="sm" textDecoration="underline">
              Add gift wrapping
            </Link>
          </HStack>
        )} */}
      </Box>
    </Stack>
  );
};
