/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Stack, Image, Text, Tooltip, Checkbox } from '@chakra-ui/react';
import DynamicButton from '../Buttons/DynamicButton';

const DynamicTable = ({ header, data, onEdit, onDelete, onCreate, onRead, onCustom, clickableColumns, onColumnClick, isCheckbox, handleCheckboxChange, ...props }) => {
  const hasData = data && data.length > 0;
  return (
    <Box
      // bgColor="white"
      spacing={1}
      borderRadius="xl"
      p={3}
      m={[1, 1, 5]}
      shadow="md"
      overflowX={'auto'}
    >
      <Table variant="striped" colorScheme="gray" size={props?.size || 'md'}>
        <Thead>
          <Tr>
            {isCheckbox ? (
              <>
                <Th />
                {header?.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
                <Th>Action</Th>
              </>
            ) : (
              <>
                {header?.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
                <Th>Action</Th>
              </>
            )}
          </Tr>
        </Thead>
        {hasData ? (
          <Tbody fontSize="sm" >
            {isCheckbox ?
              <>
                {data?.map((row, rowIndex) => (
                  <Tr key={rowIndex}>
                    <Td >
                      <Checkbox onChange={(e) => handleCheckboxChange(e, row)} />
                    </Td>
                    {header?.map((column, columnIndex) => (
                      <><Td
                        key={columnIndex}
                        onClick={() => {
                          if (clickableColumns?.includes(column) && onColumnClick) {
                            onColumnClick(column, row);
                          }
                        }}
                        // onClick={() => console.log(row)}
                        sx={{
                          cursor: clickableColumns?.includes(column) ? 'pointer' : 'default',
                          color: clickableColumns?.includes(column) ? 'blue.500' : 'inherit'
                        }}
                      >
                        {column === 'image' ? (
                          <Image src={row[column]} alt="Image" boxSize="50px" objectFit="cover" />
                        ) : (
                          <Text fontSize={props?.textSize || 'md'}>{row[column]}</Text>
                        )}
                      </Td></>
                    ))}
                    <Td>
                      <Stack flexDirection={'row'} spacing={2}>
                        {onCustom && (
                          <DynamicButton variant={'solid'} color={props.action_color} icon={props.action_icon} action={'custom'} size="sm" onClick={() => onCustom(row)} tooltip={props?.customTooltip} />
                        )}
                        {onEdit && (
                          <DynamicButton variant={'solid'} action={'update'} size="sm" onClick={() => onEdit(row)} tooltip={props?.editTooltip} />
                        )}
                        {onDelete && (
                          <DynamicButton variant={'solid'} action={'delete'} size="sm" onClick={() => onDelete(row)} tooltip={props?.deleteTooltip} />
                        )}
                        {onCreate && (
                          <DynamicButton variant={'solid'} action={'create'} size="sm" onClick={() => onCreate(row)} tooltip={props?.createTooltip} />
                        )}
                        {onRead && (
                          <DynamicButton variant={'solid'} action={'read'} size="sm" onClick={() => onRead(row)} tooltip={props?.readTooltip} />
                        )}
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </>
              :
              data?.map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  {header?.map((column, columnIndex) => (
                    <Td
                      key={columnIndex}
                      onClick={() => {
                        if (clickableColumns?.includes(column) && onColumnClick) {
                          onColumnClick(column, row);
                        }
                      }}
                      // onClick={() => console.log(row)}
                      sx={{
                        cursor: clickableColumns?.includes(column) ? 'pointer' : 'default',
                        color: clickableColumns?.includes(column) ? 'blue.500' : 'inherit'
                      }}
                    >
                      {column === 'image' ? (
                        <Image src={row[column]} alt="Image" boxSize="50px" objectFit="cover" />
                      ) : (
                        <Text fontSize={props?.textSize || 'md'}>{row[column]}</Text>
                      )}
                    </Td>
                  ))}
                  <Td>
                    <Stack flexDirection={'row'} spacing={2}>
                      {onCustom && (
                        <DynamicButton variant={'solid'} color={props.action_color} icon={props.action_icon} action={'custom'} size="sm" onClick={() => onCustom(row)} tooltip={props?.customTooltip} />
                      )}
                      {onEdit && (
                        <DynamicButton variant={'solid'} action={'update'} size="sm" onClick={() => onEdit(row)} tooltip={props?.editTooltip} />
                      )}
                      {onDelete && (
                        <DynamicButton variant={'solid'} action={'delete'} size="sm" onClick={() => onDelete(row)} tooltip={props?.deleteTooltip} />
                      )}
                      {onCreate && (
                        <DynamicButton variant={'solid'} action={'create'} size="sm" onClick={() => onCreate(row)} tooltip={props?.createTooltip} />
                      )}
                      {onRead && (
                        <DynamicButton variant={'solid'} action={'read'} size="sm" onClick={() => onRead(row)} tooltip={props?.readTooltip} />
                      )}
                    </Stack>
                  </Td>
                </Tr>
              ))
            }

            {/* {data?.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {header?.map((column, columnIndex) => (
                  <Td
                    key={columnIndex}
                    onClick={() => {
                      if (clickableColumns?.includes(column) && onColumnClick) {
                        onColumnClick(column, row);
                      }
                    }}
                    // onClick={() => console.log(row)}
                    sx={{
                      cursor: clickableColumns?.includes(column) ? 'pointer' : 'default',
                      color: clickableColumns?.includes(column) ? 'blue.500' : 'inherit'
                    }}
                  >
                    {column === 'image' ? (
                      <Image src={row[column]} alt="Image" boxSize="50px" objectFit="cover" />
                    ) : (
                      <Text fontSize={props?.textSize || 'md'}>{row[column]}</Text>
                    )}
                  </Td>
                ))}
                <Td>
                  <Stack flexDirection={'row'} spacing={2}>

                    {onCustom && (
                      <DynamicButton variant={'solid'} color={props.action_color} icon={props.action_icon} action={'custom'} size="sm" onClick={() => onCustom(row)} tooltip={props?.customTooltip} />
                    )}
                    {onEdit && (
                      <DynamicButton variant={'solid'} action={'update'} size="sm" onClick={() => onEdit(row)} tooltip={props?.editTooltip} />
                    )}
                    {onDelete && (
                      <DynamicButton variant={'solid'} action={'delete'} size="sm" onClick={() => onDelete(row)} tooltip={props?.deleteTooltip} />
                    )}
                    {onCreate && (
                      <DynamicButton variant={'solid'} action={'create'} size="sm" onClick={() => onCreate(row)} tooltip={props?.createTooltip} />
                    )}
                    {onRead && (
                      <DynamicButton variant={'solid'} action={'read'} size="sm" onClick={() => onRead(row)} tooltip={props?.readTooltip} />
                    )}
                  </Stack>
                </Td>
              </Tr>
            ))} */}
          </Tbody>
        ) : (
          <Tbody>
            <Stack minH={'50vh'} alignItems="center" justifyContent={'center'}>
              <Text fontWeight={500} color="gray.600">No data found</Text>
            </Stack>
          </Tbody>
        )}
      </Table>
    </Box>
  );
};

export default DynamicTable;
