import React, { useEffect, useState } from 'react';
import { Avatar, AvatarGroup, Box, HStack, Spacer, Stack, Text, VStack, useColorMode } from '@chakra-ui/react';
import { FcApproval, FcFactory } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import { countDocumentsFirebase, sumDocumentsFieldFirebase } from '../../Api/firebaseApi';
import moment from 'moment';

const AcquisitionDefaultPipeline = () => {
  const globalState = useUserStore();
  const findProject = globalState?.projects?.find(
    (x) => x?.id === globalState?.currentProject
  );
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [counts, setCounts] = useState({});
  const [value, setValue] = useState({})

  const handleNavigate = (transaction) => {
    navigate(`view/${transaction}`, { state: transaction });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = moment().startOf('day').toDate();
        const now = moment().toDate();
        // const dates = [
        //   new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)),
        //   new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000)),
        //   new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000)),
        //   new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000))
        // ];
        const todayMoment = moment()
        const dates = [
          moment(todayMoment).subtract(7, 'days').toDate(),
          moment(todayMoment).subtract(14, 'days').toDate(),
          moment(todayMoment).subtract(30, 'days').toDate(),
          moment(todayMoment).subtract(90, 'days').toDate()
        ];
        const todayConditions = [
          { field: 'companyId', operator: '==', value: globalState.currentCompany },
          { field: 'projectId', operator: '==', value: globalState.currentProject },
          { field: 'lastUpdated', operator: '>=', value: today },
          { field: 'total_paid_transaction', operator: '==', value: 0 }
        ];
        const conditions = dates.map((date) => ([
          { field: 'companyId', operator: '==', value: globalState.currentCompany },
          { field: 'projectId', operator: '==', value: globalState.currentProject },
          { field: 'lastUpdated', operator: '<=', value: now },
          { field: 'total_paid_transaction', operator: '==', value: 0 },
          { field: 'lastUpdated', operator: '>=', value: date }
        ]));

        const countsPromises = conditions.map(async (condition,i) => {
          if (i==2) console.log(condition, 'condition')
          const count = await countDocumentsFirebase('customer', condition);
          return count;
        });
        const counts = await Promise.all(countsPromises);

        const valuesPromises = conditions.map(async (condition) => {
          const point = await sumDocumentsFieldFirebase('customer', 'last_checkout_value', condition);
          return point;
        });
        const values = await Promise.all(valuesPromises);

        const todayCountPromise = countDocumentsFirebase('customer', todayConditions);
        const todayValuePromise = sumDocumentsFieldFirebase('customer', 'last_checkout_value', todayConditions);

        const [todayCount, todayValue] = await Promise.all([todayCountPromise, todayValuePromise]);

        setCounts([
          todayCount, ...counts
        ]);

        setValue([
          todayValue, ...values
        ]);
        // console.log(counts, value, 'nilai2')
      } catch (error) {
        console.log('error', error)
      }
    };

    fetchData();
  }, [globalState.currentCompany, globalState.currentProject]);

  const title = [
    { name: 'Today Transactions', url: 'today-transaction' },
    { name: 'Last 7 Days Transactions', url: 'last-7-days-transaction' },
    { name: 'Last 14 Days Transactions', url: 'last-14-days-transaction' },
    { name: 'Last 30 Days Transactions', url: 'last-30-days-transaction' },
    { name: 'Last 90 Days Transactions', url: 'last-90-days-transaction' }];

  return (
    <>
      <Stack>
        <Box overflowX={'auto'} sx={{
          '&::-webkit-scrollbar': {
            w: '2',
            h: '3',
          },
          '&::-webkit-scrollbar-track': {
            w: '6',
            h: '5',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10',
            bg: 'gray.200',
          },
        }}>
          <HStack w={'100%'} spacing={5} p={4}>
            {title?.map((title, index) => (
              <Stack key={index} shadow="base" minWidth="350px">
                <VStack borderRadius={'md'} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} align="flex-start" w="full" h="full" justify="space-between">
                  <HStack spacing={3} px={4} pt={4} w="100%">
                    <Stack cursor="pointer" onClick={() => handleNavigate(title.url)}>
                      <FcFactory size={50} />
                    </Stack>
                    <Stack>
                      <Text
                        key={index}
                        textTransform="capitalize"
                        cursor="pointer"
                        noOfLines={1}
                        fontSize="sm"
                        fontWeight="medium"
                        onClick={() => handleNavigate(title.url)}
                      >
                        {title.name}
                      </Text>

                      <Text spacing={3} fontSize="2xs">
                        Amount: Rp. {value[`${index}`]?.toLocaleString('id-ID')}
                      </Text>
                      <Text spacing={3} fontSize="2xs">
                        Count: {counts[`${index}`]}
                      </Text>
                    </Stack>
                  </HStack>

                  <HStack
                    alignItems="center"
                    justifyContent="space-around"
                    w="full"
                    p={3}
                    mt={3}
                  >
                    <Stack spacing={0}>
                      <Text spacing={3} fontSize="2xs">
                        Project: {findProject?.name}
                      </Text>
                    </Stack>
                    <Spacer />
                    {/* Tombol aksi tambahan jika diperlukan */}
                  </HStack>
                </VStack>
              </Stack>
            ))}
          </HStack>
        </Box>
      </Stack>

    </>
  );
};

export default AcquisitionDefaultPipeline;
