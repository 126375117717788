import {
  Box, Button, Heading, HStack, Spacer, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, Skeleton, Image, InputGroup, Input, InputRightElement,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx'
import axios from 'axios';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import CardFile from '../../Components/Accounting/CardFile';
import DynamicButton from '../../Components/Buttons/DynamicButton';

function KpiImport() {
  const globalState = useUserStore();
  const [file, setFile] = useState(null)
  const [transaction, setTransaction] = useState([])
  const [data, setData] = useState([]);
  const toast = useToast()

  const onSelectFile = (arg) => {
    setFile(arg)
  }
  console.log(file.target.files,'filessss')
  const onImport = () => {
    if (!file) {
      return toast({
        title: 'Error',
        description: 'Not Have Data',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (file?.target?.files?.[0]?.name?.split('.').pop() !== 'xlsx') {
      return toast({
        title: 'Error',
        description: 'Invalid file type. Please select an XLSX file.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      setTransaction([...jsonData])
    }

    reader.readAsArrayBuffer(file.target.files[0]);
  }

  const saveDocument = async () => {
    try {
      if (!transaction || transaction.length === 0) {
        return toast({
          title: 'Error',
          description: 'Not Have Data, plese input file  first.',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      globalState.setIsLoading(true);
      // await addArrayDocumentFirebase(
      //   'kpi',
      //   transaction,
      // );
      globalState.setIsLoading(false);
      window.location.href = '/sopkpi/kpi-template';
      toast({
        title: 'Data saved to Firebase',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
      toast({
        title: 'Error saving data to Firebase',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const downloadTemplate = async () => {
    try {
      const spreadsheetUrl = 'https://docs.google.com/spreadsheets/d/1vw8ohFEPsD1WP3y1uwXNwaK65oyM7TQZfgPmD079iK0/export?format=xlsx';
      const response = await axios.get(spreadsheetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading template:', error);
      // Handle error
    }
  };
  console.log(transaction)
  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>KPI Import</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5} textAlign="center">
          <CardFile onChange={onSelectFile} />

          <HStack mb={5} mt={5} justifyContent="center">
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Load Document'}
              onClick={() => onImport()}
            />
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Download Template'}
              onClick={downloadTemplate}
            />
          </HStack>

          <Stack alignItems={'end'} justifyContent="end" my={3}>
            <DynamicButton
              size={'sm'}
              action="create"
              title={'Save Document'}
              onClick={() => saveDocument()}
            />
          </Stack>

          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Title </Th>
                  <Th>Divisi </Th>
                  <Th>Achivement</Th>
                  <Th>Period</Th>
                  <Th>Format</Th>
                  <Th>Is Average</Th>
                  <Th>Target</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transaction?.map((x, i) => (
                  <Tr key={i}>
                    <Td> {x?.title} </Td>
                    <Td> {x?.divisi} </Td>
                    <Td> {x?.achivement} </Td>
                    <Td> {x?.period} </Td>
                    <Td> {x?.format} </Td>
                    <Td> {x?.isAvarage ? 'Yes' : 'No'} </Td>
                    <Td> {x?.target} </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

        </Box>
      </Box>
    </>
  );
}

export default KpiImport;