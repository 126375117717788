import React from 'react';
import LoginEmail from '../Pages/Auth/LoginEmail';
import SignUpPage from '../Pages/Auth/SignUpPage';
// import RedirectElement from '../Pages/Auth/RedirectElement';
import ForgotPassword from '../Pages/Auth/ForgotPassword';
import EmailVerification from '../Pages/Auth/EmailVerification';
import HomeMainPage from '../Pages/Home/HomeMainPage';

const AuthenticationRouter = [
  // {
  //   path: '/',
  //   element: <RedirectElement />,
  // },
  {
    path: '/',
    element: <HomeMainPage />,
  },  
  {
    path: '/home',
    element: <HomeMainPage />,
  },
  {
    path: '/login',
    element: <LoginEmail />,
  },
  {
    path: '/signup',
    element: <SignUpPage />,
  },
  {

    path: '/verificationEmail',
    element: <EmailVerification />,
  },
  {
    path: '/reset-password',
    element: <ForgotPassword />,
  },
];

export default AuthenticationRouter;
