import React from 'react';
import ManagementConfigurationPage from '../Pages/Management/ManagementConfigurationPage';

const ManagementRouter = [
  {
    path: '/management/configuration',
    element: <ManagementConfigurationPage />,
  },

];

export default ManagementRouter;
