import { Box, Button, Center, Container, HStack, Heading, Image, Stack, Text, VStack, useToast } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { formatFrice } from '../../Utils/Helper';
import { Link } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';



const banks = [
  { name: 'bni', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png' },
  { name: 'bca', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png' },
  { name: 'mandiri', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png' },
  { name: 'permata', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png' },
  { name: 'bri', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png' },
  { name: 'cimb', uri: 'https://career.amikom.ac.id/images/company/cover/1637497490.jpeg' },
];




const CheckoutFrontPayment = () => {
  const { state: { data } } = useLocation();
  const toast = useToast();
  const handleStringCopy = (string) => {
    navigator.clipboard.writeText(string);
    toast({
      description: 'Wallet Address Copied!',
      status: 'success',
      duration: 2000,
      position: 'top'
    })
  };
  const handleCheckPayment = async () => {
    try {
      const orderData = await getSingleDocumentFirebase('orders', data?.orderId);
      if (orderData?.paymentStatus !== 'PAID') {
        toast({
          title: `Status pembayaran anda : ${orderData?.paymentStatus}`,
          description: 'Segera lakukan pembayaran ke nomor di atas',
          status: 'error',
          isClosable: true
        })
      } else if (orderData?.paymentStatus === 'PAID') {
        toast({
          title: 'Orderan anda sedang kami proses. Simpan ORDER ID : ' + orderData?.id,
          status: 'success',
          isClosable: true
        })
        // navigate('https://lp-deoapp.web.app')
      }
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  }

  return (
    <>
      <Container justifyContent={'center'}>
        <Heading align={'center'} size='md'>Terima Kasih sudah melakukan order</Heading>
        <Text align={'center'}>Untuk menyelesaikan pesanan, silakan melakukan pembayaran di bawah ini : </Text>

        <Center>
          <Heading color='green' size='lg'>IDR {formatFrice(data?.amount)}</Heading>
        </Center>



        {data?.payment_information?.gopay_link || data?.payment_information?.qr_link || data?.payment_information?.actions?.length > 0 ?
          <>
            <>
              <Box display='flex' flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
                <HStack w={300} justify={'space-between'} alignItems={'center'} justifyContent={'center'}>
                  <Image
                    w={150}
                    src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/QRIS_logo.svg/384px-QRIS_logo.svg.png'}
                    alt={'qris logo'}
                  />
                  <Image
                    w={10}
                    src={'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Gerbang_Pembayaran_Nasional_logo.svg/1200px-Gerbang_Pembayaran_Nasional_logo.svg.png'}
                    alt={'gpn'}
                  />
                </HStack>
                <Image
                  w={300}
                  src={data?.payment_information?.actions[0]?.url}
                  alt={'qris payment'}
                />
                <Image src='https://antinomi.org/wp-content/uploads/2022/03/logo-gopay-vector.png' width={'10em'} alt='gopay' />
                <Text align={'center'}>Silakan scan QRIS di atas atau buka link GOPAY di bawah ini pada smartphone kamu:</Text>

                <Text
                  color={'blue'}
                  align={'center'}
                  fontWeight={'bold'}
                >
                  <Link
                    to={data?.payment_information?.actions[1]?.url}
                  >
                    {data?.payment_information?.actions[1]?.url}
                  </Link>
                </Text>

              </Box>
            </>
          </> :
          data?.payment_information?.payment_type === 'bank_transfer' ||
            data?.payment_information?.payment_type === 'permata' ?
            <>
              <VStack p={5}>
                <Image
                  w={100}
                  src={
                    findBankImage(
                      banks,
                      data?.payment_information?.va_numbers[0]?.bank
                    )
                  }
                />
                <HStack>
                  <Text>{data?.payment_information?.va_numbers[0]?.bank?.toUpperCase()} VA :<strong>{data?.payment_information?.va_numbers[0]?.va_number}</strong> </Text>
                  <Box
                    _hover={{
                      transform: 'scale(1.1)'
                    }}
                    cursor='pointer'
                  >
                    <FaRegCopy
                      onClick={() => handleStringCopy(
                        data?.payment_information?.va_numbers[0]?.va_number
                      )}
                    />
                  </Box>
                </HStack>
              </VStack>
            </> :
            data?.payment_information?.qr_link ?
              <>
                <Stack>
                  <Stack>
                    <HStack w={300} justify={'space-between'}>
                      <Image
                        w={150}
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/QRIS_logo.svg/384px-QRIS_logo.svg.png'}
                        alt={'qris logo'}
                      />
                      <Image
                        w={10}
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Gerbang_Pembayaran_Nasional_logo.svg/1200px-Gerbang_Pembayaran_Nasional_logo.svg.png'}
                        alt={'gpn'}
                      />
                    </HStack>
                  </Stack>
                  <Image
                    w={300}
                    src={data?.payment_information?.actions[0]?.url}
                    alt={'qris payment'}
                  />
                </Stack>
              </>
              :
              data?.payment_information?.bill_key ?
                <>
                  <VStack p={5}>
                    <Image
                      w={100}
                      src={'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png'}
                    />
                    <HStack>
                      <Text>MANDIRI VA :<strong>{data?.payment_information?.bill_key}</strong> </Text>
                      <Box
                        _hover={{
                          transform: 'scale(1.1)'
                        }}
                        cursor='pointer'
                      >
                        <FaRegCopy
                          onClick={() => handleStringCopy(
                            data?.payment_information?.va_numbers[0]?.va_number
                          )}
                        />
                      </Box>
                    </HStack>
                  </VStack>
                </>
                : <>No data</>
        }
        <Center>
          <Button
            my={10}
            bg='black'
            color='white'
            onClick={handleCheckPayment}>
            Saya sudah melakukan pembayaran
          </Button>
        </Center>

        <Text mt={20} align={'center'}>Jangan lupa untuk menympan screenshot bukti pembayaran dan order id</Text>
      </Container>
    </>
  )
}

export default CheckoutFrontPayment



function findBankImage(banks, bankName) {
  const selected = banks?.find((x) => x?.name?.toLowerCase() == bankName);
  const img = selected?.uri;
  return img;
}