/* eslint-disable no-undef */
import axios from 'axios';


const baseUrl = 'https://new-apiv2.importir.com/';

export const AwsUploadFile = async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization' : process.env.REACT_APP_PAYMENT_KEY
      },
    }
    const response = await axios.post(`${baseUrl}api/upload-aws/image`,
      data, config
    );

    return response.data;
  } catch (error) {
    return error.message;
  }
}