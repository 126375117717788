import React from 'react'
import PropTypes from 'prop-types'


export const Hero4 = ({ textColor, bgColor, header, content, modeBG, otherColor }) => {

  return (
    <section className={modeBG ? 'text-white body-font' : 'text-gray-400 body-font'} style={{ color: textColor }}>
      <div className='container mx-auto flex px-5 py-24 items-center justify-center flex-col'>
        <img className='lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded' alt='hero' src='https://dummyimage.com/720x600' />
        <div className='text-center lg:w-2/3 w-full'>
          <h1 className='title-font sm:text-4xl text-3xl mb-4 font-medium '>{header || 'Microdosing synth tattooed vexillologist'}</h1>
          <p className='leading-relaxed mb-8' >{content || 'Meggings kinfolk echo park stumptown DIY, kale chips beard jianbing tousled. Chambray dreamcatcher trust fund, kitsch vice godard disrupt ramps hexagon mustache umami snackwave tilde chillwave ugh. Pour-over meditation PBR&B pickled ennui celiac mlkshk freegan photo booth af fingerstache pitchfork.'}</p>
          <div className='flex justify-center'>
            <button className='inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg' style={{ backgroundColor: otherColor || '#6366f1' }}>Button</button>
            <button className='ml-4 inline-flex border-0 py-2 px-6 focus:outline-none  rounded text-lg'>Button</button>
          </div>
        </div>
      </div>


      <div id='animation-carousel' className='relative w-full' data-carousel='static'>
        {/* <!-- Carousel wrapper --> */}
        <div className='relative h-56 overflow-hidden rounded-lg md:h-96'>
          {/* <!-- Item 1 --> */}
          <div className='hidden duration-200 ease-linear' data-carousel-item>
            <img src='https://flowbite.com/docs/images/carousel/carousel-1.svg' className='absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' alt='...' />
          </div>
          {/* <!-- Item 2 --> */}
          <div className='hidden duration-200 ease-linear' data-carousel-item>
            <img src='https://flowbite.com/docs/images/carousel/carousel-2.svg' className='absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' alt='...' />
          </div>
          {/* <!-- Item 3 --> */}
          <div className='hidden duration-200 ease-linear' data-carousel-item='active'>
            <img src='https://flowbite.com/docs/images/carousel/carousel-3.svg' className='absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' alt='...' />
          </div>
          {/* <!-- Item 4 --> */}
          <div className='hidden duration-200 ease-linear' data-carousel-item>
            <img src='https://flowbite.com/docs/images/carousel/carousel-4.svg' className='absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' alt='...' />
          </div>
          {/* <!-- Item 5 --> */}
          <div className='hidden duration-200 ease-linear' data-carousel-item>
            <img src='https://flowbite.com/docs/images/carousel/carousel-5.svg' className='absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' alt='...' />
          </div>
        </div>
        {/* <!-- Slider controls --> */}
        <button type='button' className='absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none' data-carousel-prev>
          <span className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none'>
            <svg className='w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 10'>
              <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5 1 1 5l4 4' />
            </svg>
            <span className='sr-only'>Previous</span>
          </span>
        </button>
        <button type='button' className='absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none' data-carousel-next>
          <span className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none'>
            <svg className='w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 10'>
              <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 9 4-4-4-4' />
            </svg>
            <span className='sr-only'>Next</span>
          </span>
        </button>
      </div>

    </section>
  )
}


Hero4.propTypes = {
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  modeBG: PropTypes.string,
  otherColor: PropTypes.string,
}