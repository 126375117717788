import React, { useEffect, useState } from 'react';
import {
  HStack,
  Stack,
  Text,
  Grid,
  GridItem,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import { CartItem } from '../../Components/Cart/CartItem';
import { CartOrderSummary } from '../../Components/Cart/CartOrderSummary';
function ViewCartPage() {
  const globalState = useUserStore();

  const [originData, setOriginData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const calculateTotalValue = () => {
    return cartData.reduce(
      (total, item) =>
        total + parseInt(item.productData.price) * parseInt(item.quantity),
      0
    );
  };


  useEffect(() => {
    const getDataCart = async () => {
      try {
        const docName = `${globalState?.uid}-${globalState?.currentProject}`;
        const cartData = await getSingleDocumentFirebase(
          'product_carts',
          docName
          //   'PPtFYNpK1ddAi6DAc990Q3Z5zqS2-8NCG4Qw0xVbNR6JCcJw1'
        );
        setOriginData(cartData);
        setCartData(cartData?.carts);
      } catch (error) {
        console.log('Error:', error.message);
      }
    };

    getDataCart();
  }, []);

  console.log('cartData', cartData);

  const onChangeQuantity = async (cartItem, newQuantity) => {
    try {
      const updatedCartItem = { ...cartItem, quantity: String(newQuantity) };
      const updatedCartData = cartData.map((item) =>
        item.productId === cartItem.productId &&
        item.variantId === cartItem.variantId
          ? updatedCartItem
          : item
      );
      setCartData(updatedCartData);
      setTotalValue(calculateTotalValue(updatedCartData));

      await updateDocumentFirebase('product_carts', originData.id, {
        carts: updatedCartData,
      });
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  const onClickDelete = async (cartItem) => {
    try {
      const updatedCartData = cartData.filter(
        (item) =>
          item.productId !== cartItem.productId ||
          item.variantId !== cartItem.variantId
      );
      setCartData(updatedCartData);
      setTotalValue(calculateTotalValue(updatedCartData));

      await updateDocumentFirebase('product_carts', originData.id, {
        carts: updatedCartData,
      });
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  useEffect(() => {
    setTotalValue(calculateTotalValue());
  }, [cartData]);

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack spacing={5}>
        <BackButtons />
        <Spacer />
        <Text fontWeight={'bold'} fontSize={'lg'}>
          Product Cart
        </Text>
      </HStack>
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={2}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <GridItem
          colSpan={{ base: 3, md: 2 }}
          bg={'white'}
          p={4}
          borderRadius={'lg'}
        >
          {cartData.length > 0 ? (
            cartData.map((item) => (
              <CartItem
                key={item.productId}
                cartItem={item}
                onChangeQuantity={onChangeQuantity}
                onClickDelete={onClickDelete}
              />
            ))
          ) : (
            <Heading fontSize={'lg'} textAlign={'center'}>
              Cart is empty
            </Heading>
          )}
        </GridItem>

        <GridItem
          colSpan={{ base: 3, md: 1 }}
          bg={'white'}
          p={4}
          borderRadius={'lg'}
        >
          <CartOrderSummary value={totalValue} />
        </GridItem>
      </Grid>
    </Stack>
  );
}

export default ViewCartPage;
