// /* eslint-disable react/prop-types */
// import React, { useEffect, useState } from 'react';
// import {
//   Stack,
//   VStack,
//   HStack,
//   Text,
//   AvatarGroup,
//   Avatar,
//   Spacer,
//   useColorMode,
// } from '@chakra-ui/react';
// import moment from 'moment';
// import DynamicButton from '../Buttons/DynamicButton';
// import { countDocumentsFirebase, getCollectionFirebase, sumDocumentsFieldFirebase } from '../../Api/firebaseApi';

// function CardAvatar({
//   Icon,
//   data,
//   globalState,
//   onNavigate,
//   userArray,
//   title,
//   timeCreated,
//   modalTeam,
//   owner,
//   onDelete,
//   onEdit,
// }) {
//   const findProject = globalState?.projects?.find(
//     (x) => x?.id === data?.projectId
//   );

//   const { colorMode } = useColorMode();
//   const [counts, setCounts] = useState();
//   const [value, setValue] = useState();

//   useEffect(() => {
//     console.log('first')
//     const fetchData = async () => {
//       const conditionsProduct = [
//         { field: 'companyId', operator: '==', value: globalState.currentCompany },
//         { field: 'projectId', operator: '==', value: globalState.currentProject },
//         { field: 'tags', operator: 'array-contains', value: title },
//       ];

//       const products = await getCollectionFirebase('product', conditionsProduct);
//       const productIds = products.map((product) => product.id);
//       console.log(productIds)
//       const orderPromises = productIds.map(async (productId) => {
//         const orders = await getCollectionFirebase('orders', [{ field: 'productIds', operator: 'array-contains', value: productId }]);
//         const userIds = orders.map((order) => order.userId);
//         return [...new Set(userIds)].filter((userId) => userId !== '');
//       });

//       const uniqueUserIdsArray = await Promise.all(orderPromises);
//       console.log(uniqueUserIdsArray, 'op')
//       const core = [];
//       await Promise.all(uniqueUserIdsArray.flat().map(async (userId) => {
//         const customerData = await getCollectionFirebase('customer', [
//           { field: 'companyId', operator: '==', value: globalState.currentCompany },
//           { field: 'projectId', operator: '==', value: globalState.currentProject },
//           { field: 'uid', operator: '==', value: userId },
//           // { field: 'column', operator: '==', value: column }
//         ]);
//         core.push(...customerData);
//       }));
//       // setColumnsData(core)
//       console.log(core, 'reza');
//       const conditions = core.map((x) => ([
//         // { field: 'projectId', operator: '==', value: globalState?.currentProject },
//         // { field: 'companyId', operator: '==', value: globalState?.currentCompany },
//         { field: 'id', operator: '==', value: x.id }
//       ]));
//       console.log(conditions)

//       const countsPromises = conditions.map(async (item) => {
//         const count = await countDocumentsFirebase('customer', item);
//         return count;
//       });
//       const counts = await Promise.all(countsPromises);
//       const totalCount = counts.reduce(function (a, b) {
//         return a + b;
//       }, 0);

//       const valuesPromises = conditions.map(async (x) => {
//         const point = await sumDocumentsFieldFirebase('customer', 'last_checkout_value', x);
//         return point;
//       });

//       const values = await Promise.all(valuesPromises)
//         .then(function (result) {
//           console.log(result)
//           const total = result.reduce(function (a, b) {
//             return a + b;
//           }, 0);
//           console.log(total)
//           // setValue(total)
//           return total
//         });
//       console.log(totalCount)
//       console.log(values)
//       setCounts(totalCount)
//       setValue(values)
//     };
//     fetchData()
//   }, [globalState.currentCompany, globalState.currentProject])


//   return (
//     <Stack shadow="base" >
//       <VStack borderRadius={'md'} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} align="flex-start" w="full" h="full" justify="space-between">
//         <HStack spacing={3} px={4} pt={4} w="100%">
//           <Stack cursor="pointer" onClick={onNavigate}>
//             {Icon && <Icon size={50} />}
//           </Stack>
//           <Stack>
//             <Text
//               textTransform="capitalize"
//               cursor="pointer"
//               noOfLines={1}
//               fontSize="sm"
//               fontWeight="medium"
//               onClick={onNavigate}
//             >
//               {title}
//             </Text>
//             {userArray?.length > 0 && (
//               <AvatarGroup
//                 onClick={modalTeam}
//                 cursor="pointer"
//                 size="sm"
//                 max={5}
//               >
//                 {userArray?.map((user, i) => (
//                   <Avatar key={i} name={user.name} />
//                 ))}
//               </AvatarGroup>
//             )}
//           </Stack>
//           <Stack spacing={0} ml={['10%', '10%', '30%']}>
//             <Text spacing={3} fontSize="2xs">
//               Project: {findProject?.name}
//             </Text>
//             <Text spacing={3} fontSize="2xs">
//               Last updated {`${moment.unix(timeCreated).fromNow()}`}
//             </Text>
//           </Stack>
//         </HStack>

//         <HStack
//           alignItems="center"
//           justifyContent="space-around"
//           w="full"
//           p={3}
//         // mt={3}
//         >
//           <HStack w={'80%'}>
//             <Text spacing={3} fontSize="2xs">
//               Amount: Rp {value?.toLocaleString('id-ID')}
//             </Text>
//             <Text spacing={3} fontSize="2xs" ml={'10%'}>
//               Count: {counts}
//             </Text>
//           </HStack>
//           {/* <Stack spacing={0}>
//             <Text spacing={3} fontSize="2xs">
//               Project: {findProject?.name}
//             </Text>
//             <Text spacing={3} fontSize="2xs">
//               Last updated {`${moment.unix(timeCreated).fromNow()}`}
//             </Text>
//           </Stack> */}
//           <Spacer />
//           {owner?.includes(globalState?.uid) && (
//             <HStack spacing={2}>
//               {onEdit && (
//                 <DynamicButton action={'update'} size="sm" onClick={onEdit} />
//               )}

//               {onDelete && (
//                 <DynamicButton action={'delete'} size="sm" onClick={onDelete} />
//               )}
//             </HStack>
//           )}
//         </HStack>
//       </VStack>
//     </Stack>
//   );
// }

// export default CardAvatar;

/* eslint-disable react/prop-types */
import React from 'react';
import {
  Stack,
  VStack,
  HStack,
  Text,
  AvatarGroup,
  Avatar,
  Spacer,
  useColorMode,
} from '@chakra-ui/react';
import moment from 'moment';
import DynamicButton from '../Buttons/DynamicButton';

function CardAvatar({
  Icon,
  data,
  globalState,
  onNavigate,
  userArray,
  title,
  timeCreated,
  modalTeam,
  owner,
  onDelete,
  onEdit,
}) {
  const findProject = globalState?.projects?.find(
    (x) => x?.id === data?.projectId
  );

  const { colorMode } = useColorMode();

  

  return (
    <Stack shadow="base" >
      <VStack borderRadius={'md'} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} align="flex-start" w="full" h="full" justify="space-between">
        <HStack spacing={3} px={4} pt={4} w="100%">
          <Stack cursor="pointer" onClick={onNavigate}>
            {Icon && <Icon size={50} />}
          </Stack>
          <Stack>
            <Text
              textTransform="capitalize"
              cursor="pointer"
              noOfLines={1}
              fontSize="sm"
              fontWeight="medium"
              onClick={onNavigate}
            >
              {title}
            </Text>
            {userArray?.length > 0 && (
              <AvatarGroup
                onClick={modalTeam}
                cursor="pointer"
                size="sm"
                max={5}
              >
                {userArray?.map((user, i) => (
                  <Avatar key={i} name={user.name} />
                ))}
              </AvatarGroup>
            )}
          </Stack>
        </HStack>

        <HStack
          alignItems="center"
          justifyContent="space-around"
          w="full"
          p={3}
          mt={3}
        >
          <Stack spacing={0}>
            <Text spacing={3} fontSize="2xs">
              Project: {findProject?.name}
            </Text>
            <Text spacing={3} fontSize="2xs">
              Last updated {`${moment.unix(timeCreated).fromNow()}`}
            </Text>
          </Stack>
          <Spacer />
          {owner?.includes(globalState?.uid) && (
            <HStack spacing={2}>
              {onEdit && (
                <DynamicButton action={'update'} size="sm" onClick={onEdit} />
              )}

              {onDelete && (
                <DynamicButton action={'delete'} size="sm" onClick={onDelete} />
              )}
            </HStack>
          )}
        </HStack>
      </VStack>
    </Stack>
  );
}

export default CardAvatar;