// import { Button, SimpleGrid } from '@chakra-ui/react';
// import React, { useRef, useCallback } from 'react'
// import { useLocation } from 'react-router-dom'
// import LabelTemplate from '../../Components/Label/LabelTemplate';
// import ReactToPrint, { useReactToPrint } from 'react-to-print';
// import { IoIosPrint } from 'react-icons/io';



// const PrintLabelPage = () => {
//   const { state } = useLocation();
//   const data = JSON.parse(localStorage.getItem('printData'));


//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });
//   const componentRef = useRef(null);

//   // const handlePrint = () => window.print();

//   return (
//     <>
//       <div id='label-template'>
//         <SimpleGrid columns={2} w='auto' ref={componentRef}>
//           <LabelTemplate data={data} />
//         </SimpleGrid>
//         <Button
//           leftIcon={<IoIosPrint boxSize={1} />}
//           colorScheme='blue'
//           onClick={handlePrint}
//           width={'10%'}
//         >
//          PRINT
//         </Button>
//       </div>
//     </>
//   )
// }

// export default PrintLabelPage


import React from 'react';
import { SimpleGrid, Button } from '@chakra-ui/react';
import LabelTemplate from '../../Components/Label/LabelTemplate';
import { IoIosPrint } from 'react-icons/io';
// import './label-template.css'

const PrintLabelPage = () => {
  const data = JSON.parse(localStorage.getItem('printData'));


  return (
    <>
      <div id='label-template'>
        <SimpleGrid columns={2} w='auto'>
          <LabelTemplate data={data} />
        </SimpleGrid>
      </div>

      <Button
        leftIcon={<IoIosPrint boxSize={1} />}
        colorScheme='blue'
        // onClick={() => {
        //   handlePrint(null, () => contentToPrint.current);
        // }}
        onClick={() => window.print('label-template')}
        width={'10%'}
      >
        PRINT
      </Button>
    </>
  );
};

export default PrintLabelPage