/* eslint-disable react/prop-types */
import { Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import RegionSelect from '../Select/RegionSelect';
import CardAddress from './CardAddress';
import Region from '../../Config/Json/Regencies.json'
import Provinsi from '../../Config/Json/Provinsi.json'
import ProvinceSelect from '../Select/ProvinceSelect';
import { updateDocumentFirebase } from '../../Api/firebaseApi';
import DynamicButton from '../Buttons/DynamicButton';

function AddressIndex({warehouseData, refreshData}) {



  const [modalAddress, setModalAddress] = useState(false)


  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: ''
    }
  });

  const handleSubmit = async () => {

    setLoading(true);
    // You can submit your form data or perform any other action here.

    try {

      const data = {
        ...formData
      }

      await updateDocumentFirebase('warehouses', warehouseData?.id, data);
      //   console.log(result); // Pesan toast yang berhasil

      toast({
        title: 'Deoapp',
        description: 'Success save address',
        status: 'success',
        isClosable: true,
        position:'top'

      });


    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position:'top'

      });
    } finally {
      setLoading(false);
      setModalAddress(false)
      setFormData({
        address: {
          street: '',
          city: '',
          state: '',
          postal_code: '',
          country: ''
        }
      })
      refreshData()
    }
  }

  const handleOpenModalAddress = () => {
    setModalAddress(true)
  }

  const handleCloseModalAddress = () => {
    setModalAddress(false)
    setFormData({
      address: {
        street: '',
        city: '',
        state: '',
        postal_code: '',
        country: ''
      }
    })
  }


  const handleDeleteAddress = async () => {

    const data = {
      address : null
    }

    try {
      await updateDocumentFirebase('warehouses', warehouseData?.id, data);

      toast({
        title: 'Deoapp',
        description: 'Success delete address',
        status: 'success',
        isClosable: true,
        position:'top'

      });
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position:'top'

      });
    } finally {
      refreshData()
    }
  }

  const handleEditAddress = (data) => {
    handleOpenModalAddress()
    setFormData({
      address: {
        street: data?.street,
        city: data?.city,
        state: data?.state,
        postal_code: data?.postal_code,
        country: data?.country
      }
    });
  }


  return (
    <Stack spacing={4}>
      <HStack>
        <Text fontSize={'md'} fontWeight={500}>Address</Text>
        <Spacer />
        <DynamicButton 
          title={'Address'}
          action='create'
          size={'sm'}
          variant='solid'
          onClick={handleOpenModalAddress}
        />
      </HStack>

      {warehouseData?.address === null || warehouseData?.address === undefined && (
        <Text color={'red.500'} fontWeight={500} fontSize="sm">
                    You dont have any address in your warehouse. Please add an address.
        </Text>
      )}


      <Stack overflowY={'scroll'} h="150px">

        <Stack  shadow="md" borderWidth={1} borderRadius="md" >
          <CardAddress data={warehouseData?.address} handleDeleteAddress={() => handleDeleteAddress()} handleEditAddress={() => handleEditAddress(warehouseData.address)} />
        </Stack>
      </Stack>

      <Modal isOpen={modalAddress} onClose={handleCloseModalAddress} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Setting Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <SimpleGrid columns={[1, 2, 2]} gap={3}>
             



                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>City Address</Text>
                  <RegionSelect formData={formData} setFormData={setFormData} Provinsi={Region} />

                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>State Address</Text>
                  <ProvinceSelect formData={formData} setFormData={setFormData} Provinsi={Provinsi} />

                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>Country</Text>
                  <Input
                    placeholder="Ex: Indonesia"
                    value={formData.address.country}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, country: e.target.value } })}
                  />
                </Stack>



                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>Postal Code</Text>
                  <Input
                    placeholder="Postal Code"
                    value={formData.address.postal_code}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, postal_code: e.target.value } })}
                  />
                </Stack>


                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>Street Address</Text>
                  <Textarea
                    placeholder="ex: Jl. Gardu"
                    value={formData.address.street}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, street: e.target.value } })}
                  />
                </Stack>





              </SimpleGrid>

            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button colorScheme={'red'} onClick={handleCloseModalAddress}>Cancel</Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={loading}
                loadingText="Submitting"
              >
                                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack >
  )
}

export default AddressIndex