import {
  Stack,
  Text,
  HStack,
  Spacer,
  Grid,
  GridItem,
  Box,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import AddressCustomerIndex from '../../Components/Address/AddressCustomerIndex';
import { CartOrderSummary } from '../../Components/Cart/CartOrderSummary';
import useUserStore from '../../Hooks/Zustand/Store';
import { CheckoutItem } from '../../Components/Checkout/CheckoutItem';

function CheckoutViewPage() {
  const globalState = useUserStore();
  const location = useLocation();
  const dataOrder = location.state;
  const [totalValue, setTotalValue] = useState(0);
  const [data, setData] = useState(dataOrder);
  const calculateTotalValue = () => {
    const price = data?.productData?.price;
    const quantity = data?.quantity;
    return parseInt(price) * parseInt(quantity);
  };

  const onChangeQuantity = async (newQuantity) => {
    try {
      const updatedData = { ...data, quantity: newQuantity };
      setData(updatedData);
      setTotalValue(calculateTotalValue(updatedData));
    } catch (error) {
      console.log('Error:', error.message);
    }
  };

  useEffect(() => {
    setTotalValue(calculateTotalValue());
  }, [data]);

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack spacing={5}>
        <BackButtons />
        <Spacer />
        <Text fontWeight={'bold'} fontSize={'lg'}>
          Checkout
        </Text>
      </HStack>

      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={2}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <GridItem
          colSpan={{ base: 3, md: 2 }}
          bg={'white'}
          p={4}
          borderRadius={'lg'}
        >
          <Box
            borderRadius={'lg'}
            border={'1px solid'}
            borderColor={'gray.200'}
            my={2}
          >
            <AddressCustomerIndex
              userData={globalState}
              refreshData={() => console.log('test')}
            />
          </Box>
          <CheckoutItem
            key={data?.productId}
            item={data}
            variantList={data?.variantList}
            onChangeQuantity={onChangeQuantity}
          />
        </GridItem>

        <GridItem
          colSpan={{ base: 3, md: 1 }}
          bg={'white'}
          p={4}
          borderRadius={'lg'}
        >
          <CartOrderSummary value={totalValue} userData={globalState} dataOrder={data}/>
        </GridItem>
      </Grid>
    </Stack>
  );
}

export default CheckoutViewPage;
