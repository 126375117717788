import React from 'react';
import PropTypes from 'prop-types';

export const Product6 = ({ header, content, data }) => {
  return (
    <div>
      {data?.length > 0 ? (
        <section className='bg-[#ffffff]'>
          <div className='px-4 py-8 space-y-6 text-center'>
            <h3 className='text-2xl font-bold tracking-tight text-gray-900'>{header}</h3>
            <p className='text-2xl font-bold tracking-tight text-gray-900'>{content}</p>
            <button className='text-2xl font-bold tracking-tight text-gray-900 border-black md:px-24 rounded-xl bg-[#828282] hover:bg-yellow-400'>All product</button>
          </div>
          <div className='flex flex-wrap justify-center gap-4 px-2 py-12 md:px-8 xl:px-24'>
            {data.map((product, index) => (
              <div
                className='w-full p-4 bg-white border border-gray-900 rounded-xl md:w-1/4'
                key={index}>
                <h1 className='text-2xl font-bold tracking-tight text-gray-900'>{product?.name}</h1>
                <img
                  className='object-cover w-full h-[300px] mt-8 rounded-t-xl'
                  src={product?.image1}
                  alt=''
                />
                <div className='flex flex-col mt-8 space-y-4 text-center'>
                  <button className='px-4 py-3 text-gray-100 uppercase bg-black border border-black rounded-lg hover:bg-yellow-400 hover:text-black'>add to cart - {product?.price}</button>
                  <a
                    href=''
                    className='text-sm underline uppercase hover:no-underline'>
                    see product
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <section className='bg-[#ffffff]'>
          <div className='px-4 py-8 space-y-6 text-center'>
            <h3 className='text-2xl font-bold tracking-tight text-gray-900'>GET YOUR BUZZ ON</h3>
            <p className='text-2xl font-bold tracking-tight text-gray-900'>Hot, cold, sweet or straight up, we’ve got the brew for you.</p>
            <button className='text-2xl font-bold tracking-tight text-gray-900 border-black md:px-24 rounded-xl hover:bg-yellow-400'>All product</button>
          </div>
          <div className='flex flex-wrap justify-center gap-4 px-2 py-12 md:px-8 xl:px-24'>
            <div className='w-full p-4 bg-white border border-gray-900 rounded-xl md:w-1/3'>
              <h1 className='mt-4 text-sm text-gray-700'>CINNAMON SGR</h1>
              <img
                className='object-cover w-full mt-8 rounded-t-xl'
                src='https://cdn.sanity.io/images/769hkezm/production/1d9ae5ba38e69d3ebb9b869f5c88acccccb994bc-654x590.png?fm=webp'
                alt=''
              />
              <div className='flex flex-col mt-8 space-y-4 text-center'>
                <button className='px-4 py-3 text-gray-100 uppercase bg-black border border-black rounded-lg hover:bg-yellow-400 hover:text-black'>add to cart - $18.00</button>
                <a
                  href=''
                  className='text-sm underline uppercase hover:no-underline'>
                  see product
                </a>
              </div>
            </div>
            <div className='w-full p-4 bg-white border border-gray-900 rounded-xl md:w-1/3'>
              <h1 className='mt-4 text-sm text-gray-700'>CHOCOLATE</h1>
              <img
                className='object-cover w-full mt-8 rounded-t-xl'
                src='https://cdn.sanity.io/images/769hkezm/production/bdf6c6ff1d5f0710152878e504b5960a88de8b71-654x590.png?fm=webp'
                alt=''
              />
              <div className='flex flex-col mt-8 space-y-4 text-center'>
                <button className='px-4 py-3 text-gray-100 uppercase bg-black border border-black rounded-lg hover:bg-yellow-400 hover:text-black'>add to cart - $18.00</button>
                <a
                  href=''
                  className='text-sm underline uppercase hover:no-underline'>
                  see product
                </a>
              </div>
            </div>
            <div className='w-full p-4 bg-white border border-gray-900 rounded-xl md:w-1/3'>
              <h1 className='mt-4 text-sm text-gray-700'>ORIGINAL</h1>
              <img
                className='object-cover w-full mt-8 rounded-t-xl'
                src='https://cdn.sanity.io/images/769hkezm/production/45e41cdecdf395e33d77139aa7331139524ebe19-654x590.png?fm=webp'
                alt=''
              />
              <div className='flex flex-col mt-8 space-y-4 text-center'>
                <button className='px-4 py-3 text-gray-100 uppercase bg-black border border-black rounded-lg hover:bg-yellow-400 hover:text-black'>add to cart - $18.00</button>
                <a
                  href=''
                  className='text-sm underline uppercase hover:no-underline'>
                  see product
                </a>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

Product6.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
  data: PropTypes.array.isRequired,
};
