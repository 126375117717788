import React, { useEffect, useRef } from 'react';
import { HStack, Heading, Select, Spacer, Stack } from '@chakra-ui/react';
import { Chart } from 'chart.js/auto';
import PropTypes from 'prop-types';
import { countDocumentsFirebase } from '../../Api/firebaseApi';
import moment from 'moment';

const ChartsLineOrderV3 = ({
  collectionNames,
  conditionsArray,
  chartType,
  chartLabel,
  title,
  borderColor,
  selectTime,
  isSelect}) => {
  // const [time, setTime] = useState('');
  let time 
  // const [dates, setDates] = useState([]);
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    selectTime(selectedValue);
    // setTime(selectedValue);
    const currentDate = moment();
    let startDate = moment();
    if (time === '1') {
      startDate = moment().subtract(1, 'day');
    } else if (time === '7') {
      startDate = moment().subtract(7, 'days');
    } else if (time === '30') {
      startDate = moment().subtract(30, 'days');
    } else if (time === '14') {
      startDate = moment().subtract(14, 'days');
    } else if (time === '90') {
      startDate = moment().subtract(90, 'days');
    } else {
      startDate = moment().subtract(1, 'day');
    }

    const dates = [];
    const label = [];
    for (let date = moment(startDate); date <= currentDate; date.add(1, 'day')) {
      dates.push(moment(date));
      label.push(moment(date).format('DD-MM-YYYY'));
    }
    // setDates(dates);
  };

  const getData = async () => {
    try {
      if (!conditionsArray) {
        return;
      }

      const promises = collectionNames.map(async (collectionName, index) => {
        return Promise.all(conditionsArray.map(async (x) => {
          const res = await countDocumentsFirebase(collectionName, x[index]);
          return {
            label: collectionName,
            order: res,
          };
        }));
      });

      const results = await Promise.all(promises);

      const transformedResults = results.map((array) => {
        return array.reduce((acc, obj) => {
          const index = acc.findIndex((item) => item.label === obj.label);
          if (index === -1) {
            acc.push({ label: obj.label, order: [obj.order] });
          } else {
            acc[index].order.push(obj.order);
          }
          return acc;
        }, []);
      }).flatMap((item) => item);

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartContainer.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: chartType,
        data: {
          labels: chartLabel,
          datasets: transformedResults?.map((dataset, index) => ({
            label: dataset.label,
            data: dataset.order,
            borderColor: borderColor[index],
            borderWidth: 2,
            pointBackgroundColor: borderColor[index],
            pointRadius: 4,
            pointHoverRadius: 6,
            fill: false,
          })),
        },
        options: {
          responsive: true,
        },
      });


      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    } catch (error) {
      console.log(error, 'ini error');
    }
  }


  useEffect(() => {
    getData();
  }, [handleSelectChange])

  return (
    <Stack className="line-chart" spacing={2}>
      <HStack>
        <Heading size={'md'}>{title}</Heading>
        <Spacer />
        {isSelect ?
          <Stack width={'30%'}>
            <Select size={'sm'} borderRadius="md" placeholder="Select Time Rage" onChange={handleSelectChange} defaultValue={'14'}>
              <option value="1">1 Day</option>
              <option value="7">1 Week</option>
              <option value="14">2 Week</option>
              <option value="30">1 Month</option>
              <option value="90">3 Month</option>
            </Select>
          </Stack>
          :
          <></>
        }
      </HStack>
      <canvas ref={chartContainer} width="auto" height="auto"></canvas>
    </Stack>
  );
}

ChartsLineOrderV3.propTypes = {
  collectionNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  conditionsArray: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  chartType: PropTypes.string.isRequired,
  chartLabel: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  borderColor: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectTime: PropTypes.func.isRequired,
  isSelect: PropTypes.bool.isRequired,
  labels: PropTypes.string,
};

export default ChartsLineOrderV3;
