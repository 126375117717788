/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { FiUploadCloud } from 'react-icons/fi'
import { Text, useColorModeValue } from '@chakra-ui/react';
export default function CardFile({ onChange, IsIcon, fileName }) {
  return (
    <>
      <div
        className="card-file"
        style={{
          backgroundColor: useColorModeValue('white', '')
        }}
      >
        <input type="file" className="file-input" onChange={onChange} />
        {!IsIcon && (
          <FiUploadCloud size="50" />
        )}
        <Text fontSize={'sm'}>{fileName}</Text>
      </div>
    </>
  )
}

CardFile.defaultProps = {
  onChange: () => 'oke'
}