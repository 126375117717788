import { create } from 'zustand';

const useWebsiteStore = create((set,get) => ({
  //initial state
  color: [],
  colorDark: [],
  pageHome: [],
  domain: '',
  facebookPixel: '',
  favicon: '',
  gtm: '',
  logo: '',
  menu: '',
  modelBG: false,
  name: '',
  navigation: '',
  socialMedia: [],
  tiktokPixel: '',
  title: '',
  pageProduct: {},
  pageProductDetail: {},
  pageCategory: {},
  pageBlog: {},
  pageCheckout: {},
  pageCourse: {},
  pageBlogDetail: {},
  pageViewCourse: {},
  pageCart: {},


  //function dispatch reducer

  setColor: (data) => {
    set({
      color: data
    })
  },
  setColorDark: (data) => {
    set({
      colorDark: data
    })
  },
  setDomain: (data) => {
    set({
      domain: data
    })
  },
  setFacebookPixel: (data) => {
    set({
      facebookPixel: data
    })
  },
  setFavicon: (data) => {
    set({
      favicon: data
    })
  },
  setGtm: (data) => {
    set({
      gtm: data
    })
  },
  setLogo: (data) => {
    set({
      logo: data
    })
  },
  setMenu: (data) => {
    set({
      menu: data
    })
  },
  setName: (data) => {
    set({
      name: data
    })
  },
  setNavigation: (data) => {
    set({
      navigation: data
    })
  },
  setSocialMedia: (data) => {
    set({
      socialMedia: data
    })
  },
  setTiktokPixel: (data) => {
    set({
      tiktokPixel: data
    })
  },
  setTitle: (data) => {
    set({
      title: data
    })
  },
  setPageHome: (data) => {
    set({
      pageHome: data
    })
  },
  setPageProduct: (data) => {
    set({
      pageProduct: data
    })
  },
  setPageProductDetail: (data) => {
    set({
      pageProductDetail: data
    })
  },
  setPageCategory: (data) => {
    set({
      pageCategory: data
    })
  },
  setPageBlog: (data) => {
    set({
      pageBlog: data
    })
  },
  setPageBlogDetail: (data) => {
    set({
      pageBlogDetail: data
    })
  },
  setPageCourse: (data) => {
    set({
      pageCourse: data
    })
  },
  setPageViewCourse: (data) => {
    set({
      pageViewCourse: data
    })
  },
  setPageCart: (data) => {
    set({
      pageCart: data
    })
  },
  setPageCheckout: (data) => {
    set({
      pageCheckout: data
    })
  },

}));

export default useWebsiteStore;
