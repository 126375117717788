import React from 'react';
import PropTypes from 'prop-types';

const LogoFeatures1 = ({ header, data, width }) => {
  const dataDummy = [
    {
      logo: 'https://klamby.id/cdn/shop/files/highend_180x.png?v=1662113057',
    },
    {
      logo: 'https://klamby.id/cdn/shop/files/tatler_180x.png?v=1662112074',
    },
    {
      logo: 'https://klamby.id/cdn/shop/files/fimela_logo_180x.png?v=1660015909',
    },
    {
      logo: 'https://klamby.id/cdn/shop/files/fortune_logo_180x.jpg?v=1660015984',
    },
    {
      logo: 'https://klamby.id/cdn/shop/files/Bazaar_180x.jpg?v=1653285952',
    },
    {
      logo: 'https://klamby.id/cdn/shop/files/Cosmopolitan_180x.jpg?v=1653286136',
    },
    {
      logo: 'https://klamby.id/cdn/shop/files/IDN_Media_180x.jpg?v=1653286180',
    },
  ];
  return (
    <div className='text-center text-lg p-10 mb-20'>
      <p className='mb-10'>{header || 'As seen on'}</p>
      <div className={width === 'full' ? 'flex flex-wrap gap-8 justify-center items-center w-[50vw] m-auto' : 'flex  '}>
        {data?.map((x, i) => (
          <div
            className='w-28'
            key={i}>
            <img src={x?.image1} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoFeatures1;

LogoFeatures1.propTypes = {
  header: PropTypes.string,
  width: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};
