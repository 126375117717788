import { Box, Button, Center, Heading, HStack, IconButton, Image, Select, SimpleGrid, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcIphone, FcLandscape, FcMoneyTransfer, FcMultipleDevices, FcParallelTasks, FcSettings, FcTabletAndroid, FcTemplate } from 'react-icons/fc';
import ModalCreateHomeSection from './ModalCreateHomeSection';
import DesignComponent from './Components/DesignComponent';
import NavigationComponent from './Components/NavigationComponent';
import SettingComponent from './Components/SettingComponent';
import { useParams } from 'react-router-dom';
import ModalEditHomeSection from './ModalEditHomeSection';
import { getCollectionFirebase, getSingleDocumentFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';
import { decryptToken } from '../../Utils/encrypToken';
import useWebsiteStore from '../../Hooks/Zustand/WebsiteStore';
import Footer1 from './Ecommerce/templates/Footer/Footer1';
import { Navbar1 } from './Ecommerce/templates/Navbar/Navbar1';
import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';
import { data, dataSecond, dataSection } from './Ecommerce/templates/_InitialTemplate';
import '../../index.css';
import SavedTemplateDisplay from './Components/SavedTemplateDisplay';

const WebsiteBuilderCreatePage = () => {
  const modalAddSectionProductDetail = useDisclosure();
  const modalAddSectionProduct = useDisclosure();
  const modalAddSectionHome = useDisclosure();
  const modalEditHero = useDisclosure();

  const toast = useToast();
  const param = useParams();

  const globalState = useUserStore();

  const [selectedTemplate, setSelectedTemplate] = useState();
  const [navigation, setNavigation] = useState(<Navbar1 />);
  const [focusPage, setFocusPage] = useState('Home Page');
  const [selectedHero, setSelectedHero] = useState({});
  const [selectedColor, setSelectedColor] = useState();
  const [viewWidth, setViewWidth] = useState('full');
  // const [webConfig,setWebConfig] = useState();
  const [focus, setFocus] = useState('design');
  const [progress, setProgress] = useState();
  const [content, setContent] = useState();
  const websiteStore = useWebsiteStore();
  const [input, setInput] = useState();

  const [newSectionInput, setNewSectionInput] = useState({
    image: '',
    data: [],
    heading: '',
    content: '',
  });

  const handleAddTemplate = (value) => {
    let dataToSave = { title: value?.title, id: value?.id, image: value?.image };
    const component = React.cloneElement(value?.file);
    setContent(component);

    if (focusPage !== 'Home Page' && focusPage !== 'Product Page') {
      dataToSave = {
        title: value?.title,
        id: value?.id,
        image: value?.image,
        content: '',
        header: '',
        data: [],
      };
    }

    switch (focusPage) {
    case 'Home Page': {
      const newCode = { ...value };
      const currentHomePage = websiteStore?.pageHome;
      if (currentHomePage?.length > 0) {
        currentHomePage?.push(newCode);
        websiteStore?.setPageHome(currentHomePage);
        setContent(websiteStore?.pageHome);
      } else {
        websiteStore?.setPageHome([newCode]);
        setContent(websiteStore?.pageHome);
      }
      break;
    }
    case 'Product Page': {
      const productPageData = {
        title: value?.title,
        id: value?.id,
        image: value?.image,
        content: websiteStore?.pageProduct.content || '',
        header: websiteStore?.pageProduct.header || '',
        data: websiteStore?.pageProduct.data || [],
      };
      websiteStore?.setPageProduct(productPageData);
      break;
    }

    case 'Product Detail':
      websiteStore?.setPageProductDetail(dataToSave);
      break;

    case 'Category Page':
      websiteStore?.setPageCategory(dataToSave);
      break;

    case 'Blog Page':
      websiteStore?.setPageBlog(dataToSave);
      break;

    case 'Blog Detail Page':
      websiteStore?.setPageBlogDetail(dataToSave);
      break;

    case 'Checkout Page':
      websiteStore?.setPageCheckout(dataToSave);
      break;

    case 'Course Page':
      websiteStore?.setPageCourse(dataToSave);
      break;

    case 'Cart Page':
      websiteStore?.setPageCart(dataToSave);
      break;

    case 'Course Detail Page':
      websiteStore?.setPageViewCourse(dataToSave);
      break;

    default:
      // Default case if none of the conditions match
      break;
    }
  };

  const TemplateComponent = () => {
    if (!focusPage) return null;

    const templateData = data.find((x) => x.title === focusPage);
    return templateData?.templates?.map((x, i) => (
      <Box
        key={i}
        my='3'
        shadow='base'
        onClick={() => handleAddTemplate(x)}
        cursor='pointer'>
        <Text
          textAlign='center'
          fontSize='sm'>
          {x.title}
        </Text>
        <Box width='full'>
          <Image src={x?.image} />
        </Box>
      </Box>
    ));
  };

  const handleChangeInput = (value, type) => {
    if (type === 'title') {
      // setWebConfig({ ...webConfig,title: value });
      websiteStore?.setTitle(value);
    } else {
      // setWebConfig({ ...webConfig,name: value });
      websiteStore?.setName(value);
    }
  };

  const handleChangeColor = (value) => {
    const colorIndexToUpdate = selectedColor?.indexColor;

    const updatedInput = { ...input };

    if (updatedInput.color && Array.isArray(updatedInput.color) && updatedInput.color[colorIndexToUpdate]) {
      updatedInput.color[colorIndexToUpdate] = { ...updatedInput.color[colorIndexToUpdate], colorCode: value };
    }

    setInput(updatedInput);
    setSelectedColor({ ...selectedColor, colorCode: value });

    // setWebConfig({ ...webConfig,color: updatedInput?.color });
    websiteStore?.setColor(updatedInput?.color);
  };

  const getTemplate = async () => {
    let conditions = [{ field: 'id', operator: '==', value: 'zHrpnBlo8UBsNpOfvQUt' }];

    if (globalState?.currentCompany !== '8NCG4Qw0xVbNR6JCcJw1') {
      conditions = [
        { field: 'companyId', operator: '==', value: globalState?.currentCompany },
        { field: 'projectId', operator: '==', value: globalState?.currentProject },
        { field: 'type', operator: '==', value: 'websiteBuilder' },
      ];
    }

    try {
      const res = await getCollectionFirebase('websites', conditions);
      websiteStore?.setPageHome(res[0]?.pageHome);

      websiteStore?.setColor(res[0]?.color);
      websiteStore?.setTitle(res[0]?.title);
      websiteStore?.setName(res[0]?.name);
      websiteStore?.setGtm(res[0]?.gtm);
      websiteStore?.setName(res[0]?.name);
      websiteStore?.setFavicon(res[0]?.favicon);
      websiteStore?.setLogo(res[0]?.logo);
      websiteStore?.setFacebookPixel(res[0]?.facebookPixel);
      websiteStore?.setTiktokPixel(res[0]?.tiktokPixel);
      websiteStore?.setSocialMedia(res[0]?.socialMedia);
      websiteStore?.setDomain(res[0]?.domain);
      websiteStore?.setMenu(res[0]?.menu);
      websiteStore?.setNavigation(res[0]?.navigation);
      websiteStore?.setPageProduct(res[0]?.pageProduct);
      websiteStore?.setPageProductDetail(res[0]?.pageProductDetail);
      websiteStore?.setPageCategory(res[0]?.pageCategory);
      websiteStore?.setPageBlog(res[0]?.pageBlog);
      websiteStore?.setPageCheckout(res[0]?.pageCheckout);
      websiteStore?.setPageCourse(res[0]?.pageCourse);
      websiteStore?.setPageBlogDetail(res[0]?.pageBlogDetail);
      websiteStore?.setPageViewCourse(res[0]?.pageViewCourse);
      websiteStore?.setPageCart(res[0]?.pageCart);

      setInput({
        color: res[0]?.color,
        logo: res[0]?.logo,
        name: res[0]?.name,
        title: res[0]?.title,
        favicon: res[0]?.favicon,
      });
    } catch (error) {
      throw Error(error);
    }
  };

  const handleChangeSectionImage = async (e, type, index, imageOrder) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/website-assets`;

    const currentPageHome = websiteStore?.pageHome;

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file, parentPath, decryptResult, setProgress, progress);

      if (type === 'new') {
        if (imageOrder) {
          const newData = [...newSectionInput.data];

          newData[index][imageOrder] = res?.link;

          setNewSectionInput({ ...newSectionInput, data: newData });
        } else {
          const newData = [...newSectionInput.data];

          newData[index].image = res?.link;

          setNewSectionInput({ ...newSectionInput, data: newData });
        }
      } else {
        currentPageHome[selectedHero?.index].data[index][imageOrder] = res?.link;

        websiteStore?.setPageHome(currentPageHome);
      }
    } catch (error) {
      throw Error(error);
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleSaveModalHomePage = () => {
    try {
      // const newData = { ...selectedTemplate, data: newSectionInput.data, header: newSectionInput.header, content: newSectionInput.content };
      const currentHomePage = websiteStore?.pageHome;
      let dataSaved = {
        title: selectedTemplate.title,
        image: selectedTemplate.image,
        id: selectedTemplate.id,
        header: newSectionInput.header || '',
        content: newSectionInput.content || '',
        data: newSectionInput.data,
      };

      if (websiteStore.pageHome?.length > 0) {
        currentHomePage?.push(dataSaved);
        websiteStore?.setPageHome(currentHomePage);
      } else {
        websiteStore?.setPageHome([dataSaved]);
      }

      dataSaved = {
        title: '',
        image: '',
        id: '',
        header: '',
        content: '',
        data: [],
      };

      setNewSectionInput({
        image: '',
        data: [],
        header: '',
        content: '',
      });
      modalAddSectionHome.onClose();
    } catch (error) {
      toast({
        title: 'data in the form is uncompleted',
        description: 'complete all forms.',
        duration: 3000,
        status: 'error',
      });
    }
  };

  // console.log(dataSection)

  const addNewData = (type) => {
    let newDataObject = {};

    if (type !== 'Full Image' || type !== 'Banner Slider') {
      newDataObject = {
        name: 'New Product',
        image: 'https://example.com/new-image.jpg',
        price: 0,
        discount: '0',
        link: 'https://example.com/new-link/',
      };
    } else {
      newDataObject = {
        image: 'https://example.com/new-image.jpg',
        link: 'example.com',
      };
    }

    setNewSectionInput((prevData) => {
      return {
        ...prevData,
        data: [...prevData.data, newDataObject],
      };
    });
  };

  const handleSave = async () => {
    globalState?.setIsLoading(true);

    const dataUpdate = {
      color: websiteStore?.color || [],
      title: websiteStore?.title || '',
      name: websiteStore?.name || '',
      gtm: websiteStore?.gtm || '',
      favicon: websiteStore?.favicon || '',
      logo: websiteStore?.logo || '',
      facebookPixel: websiteStore?.facebookPixel || '',
      tiktokPixel: websiteStore?.tiktokPixel || '',
      socialMedia: websiteStore?.socialMedia || [],
      domain: websiteStore?.domain || '',
      menu: websiteStore?.menu || [],
      navigation: websiteStore?.navigation || '',
      pageHome: websiteStore?.pageHome || [],
      pageProduct: websiteStore?.pageProduct || [],
      pageProductDetail: websiteStore?.pageProductDetail || [],
      pageCategory: websiteStore?.pageCategory || [],
      pageBlog: websiteStore?.pageBlog || [],
      pageCheckout: websiteStore?.pageCheckout || [],
      pageCourse: websiteStore?.pageCourse || [],
      pageBlogDetail: websiteStore?.pageBlogDetail || [],
      pageViewCourse: websiteStore?.pageViewCourse || [],
    };

    console.log(dataUpdate, 'dataUpdate');
    try {
      await updateDocumentFirebase('websites', param?.id, dataUpdate);
      toast({
        title: 'Web Config Updated',
        description: 'Success updated',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleChangeSectionDetail = (value, field, index, type) => {
    if (type === 'edit') {
      // const newData = [...sectionData.data];
      const currentPageHome = websiteStore?.pageHome;
      const indexPage = selectedHero?.index;

      currentPageHome[indexPage].data[index][field] = value;

      websiteStore?.setPageHome(currentPageHome);

      // websiteStore?.set({ ...sectionData,data: newData });
    } else {
      const newData = [...newSectionInput.data];

      newData[index][field] = value;

      setNewSectionInput({ ...newSectionInput, data: newData });
      // websiteStore?.setPageHome()
    }
  };

  const handleRemoveSection = (data) => {
    const modifHomePage = websiteStore?.pageHome;
    const index = modifHomePage?.findIndex((obj) => obj.title === data.title);

    if (index !== -1) {
      modifHomePage?.splice(index, 1);
      // setSelectedContent([...content]); // Ensure state update after mutation
      websiteStore?.setPageHome(modifHomePage);
    }
  };

  const handleOpenModal = () => {
    if (focusPage === 'Home Page') {
      modalAddSectionHome.onOpen();
    } else if (focusPage === 'Product Page') {
      modalAddSectionProduct.onOpen();
    } else if (focusPage === 'Product Detail') {
      modalAddSectionProductDetail.onOpen();
    }
  };

  const handleChangeAssets = async (e, type) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/website-assets`;

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file, parentPath, decryptResult, setProgress, progress);

      if (type === 'favicon') {
        websiteStore?.setFavicon(res?.link);
      } else {
        websiteStore?.setLogo(res?.link);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleOpenEditHero = (value, index) => {
    setSelectedHero({ ...value, index: index });
    modalEditHero.onOpen();
  };

  const handleChangeHeroContent = (value, type) => {
    const currentHomePage = [...websiteStore.pageHome];
    const currentWebConfigHomePage = [...websiteStore.pageHome];

    const findIndexHome = currentHomePage.findIndex((x) => x.title === selectedHero.title);

    if (type === 'header') {
      currentHomePage[findIndexHome].header = value;
      currentWebConfigHomePage[findIndexHome].header = value;
    } else {
      currentHomePage[findIndexHome].content = value;
      currentWebConfigHomePage[findIndexHome].content = value;
    }

    websiteStore.setPageHome(currentHomePage);
  };

  useEffect(() => {
    getTemplate();

    return () => {};
  }, []);

  return (
    <Box overflowY='auto'>
      <SimpleGrid
        height='8vh'
        p={'1'}
        borderBottom='1px'
        columns='3'
        borderColor={'gray.300'}>
        <HStack>
          <BackButtons />
          <Heading>Page Editor</Heading>
        </HStack>
        <Spacer />

        <Select
          onChange={(e) => {
            setFocusPage(e.target.value);
            setContent();
          }}
          alignSelf='center'>
          {data.map((x, i) => (
            <option
              key={i}
              value={x.title}>
              {x.title}
            </option>
          ))}
        </Select>
        <HStack>{/* <Button colorScheme='green'>Save</Button> */}</HStack>
      </SimpleGrid>
      <HStack
        height='92vh'
        alignItems='flex-start'>
        <Box
          height='full'
          p='1'
          borderRight='1px'
          borderColor={'gray.300'}>
          <Box m='1'>
            <IconButton
              // colorScheme='blue'
              aria-label='Navigation'
              icon={<FcParallelTasks />}
              onClick={() => setFocus('navigation')}
              title='Navbar Build'
              fontSize="24px" // Menetapkan ukuran ikon
              _hover={{ fontSize: '28px' }} // Memperbesar ikon saat dihover
              tooltip='Navbar Build' // Menetapkan judul tooltip
            />
          </Box>

          <Box m='1'>
            <IconButton
              // colorScheme='blue'
              aria-label='Design'
              icon={<FcLandscape />}
              onClick={() => setFocus('design')}
              title='Design'
              fontSize="24px"
              _hover={{ fontSize: '28px' }}
              tooltip='Design'
            />
          </Box>

          <Box m='1'>
            <IconButton
              // colorScheme='blue'
              aria-label='Template'
              icon={<FcTemplate />}
              onClick={() => setFocus('template')}
              title='Template'
              fontSize="24px"
              _hover={{ fontSize: '28px' }}
              tooltip='Template'
            />
          </Box>

          <Box m='1'>
            <IconButton
              // colorScheme='blue'
              aria-label='Payment'
              icon={<FcMoneyTransfer />}
              onClick={() => setFocus('payment')}
              title='Payment'
              fontSize="24px"
              _hover={{ fontSize: '28px' }}
              tooltip='Payment'
            />
          </Box>

          <Box m='1'>
            <IconButton
              // colorScheme='blue'
              aria-label='Settings'
              icon={<FcSettings />}
              onClick={() => setFocus('settings')}
              title='Settings'
              fontSize="24px"
              _hover={{ fontSize: '28px' }}
              tooltip='Settings'
            />
          </Box>
        </Box>


        <Box
          p={2}
          w='300px'
          h='full'
          // bg='blue'
          overflowY='auto'
          borderRight='1px'
          borderColor={'gray.300'}
          sx={{
            '&::-webkit-scrollbar': {
              w: '2',
              h: '3',
            },
            '&::-webkit-scrollbar-track': {
              w: '6',
              h: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.200',
            },
          }}>
          <Heading
            fontSize='lg'
            pb={2}>
            {focus.toUpperCase()}
          </Heading>

          {focus === 'navigation' ? (
            <NavigationComponent
              // webConfig={webConfig}
              websiteStore={websiteStore}
              dataSecond={dataSecond}
              setNavigation={setNavigation}
            />
          ) : focus === 'template' ? (
            <TemplateComponent />
          ) : focus === 'settings' ? (
            <SettingComponent websiteStore={websiteStore} />
          ) : focus === 'design' ? (
            <DesignComponent
              websiteStore={websiteStore}
              handleChangeColor={handleChangeColor}
              handleChangeAssets={handleChangeAssets}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              handleChangeInput={handleChangeInput}
            />
          ) : (
            <></>
          )}
        </Box>

        <Box
          w='full'
          p='1'
          h='full'
          // bg='blue'
          overflowY='auto'
          maxH='92vh'>
          <SimpleGrid
            columns='3'
            w='full'
            p='1'>
            <Heading>{focusPage}</Heading>
            <HStack>
              <Spacer />
              <IconButton
                aria-label='Desktop'
                icon={<FcMultipleDevices />}
                onClick={() => setViewWidth('full')}
              />

              <IconButton
                aria-label='Smart Phone'
                icon={<FcIphone />}
                onClick={() => setViewWidth('600px')}
              />

              <IconButton
                aria-label='Tablet'
                icon={<FcTabletAndroid />}
                onClick={() => setViewWidth('400px')}
              />
              <Spacer />
            </HStack>
            <HStack>
              <Spacer />
              <Button
                colorScheme='green'
                onClick={() => handleSave()}>
                Save
              </Button>
            </HStack>
          </SimpleGrid>
          <Box
            alignSelf='center'
            width={viewWidth}
            border='1px'
            borderRadius='md'
            overflow={'hidden'}
            my={2}
            // bg='blue'
            borderColor={'gray.50'}>
            {React.cloneElement(navigation, { navmenu: websiteStore?.menu, name: websiteStore?.name, bgColor: websiteStore?.color[1]?.colorCode, textColor: websiteStore?.color[2]?.colorCode, logo: websiteStore?.logo, width: viewWidth })}

            <Box>
              <>
                <SavedTemplateDisplay
                  focusPage={focusPage}
                  websiteStore={websiteStore}
                  width={viewWidth}
                  handleRemoveSection={handleRemoveSection}
                  handleOpenEditHero={handleOpenEditHero}
                />
              </>
            </Box>

            {focusPage === 'Home Page' && (
              <Box
                border='1px'
                m='2'
                borderColor='gray.100'
                p='5'
                borderRadius={'md'}>
                <Center>
                  <Button
                    size='xl'
                    colorScheme='green'
                    p='5'
                    onClick={handleOpenModal}>
                    + Add Section
                  </Button>
                </Center>
              </Box>
            )}

            {/* {focusPage !== 'Home Page' && (websiteStore.pageProduct !== null || websiteStore.pageProductDetail !== null || websiteStore.pageCategory !== null || websiteStore.pageBlog !== null || websiteStore.pageCheckout !== null) ? (
              <Box
                border='1px'
                m='2'
                borderColor='gray.100'
                p='5'
                borderRadius={'md'}>
                <Center>
                  <Button
                    size='xl'
                    colorScheme='green'
                    p='5'
                    onClick={handleOpenModal}>
                    + Add Section
                  </Button>
                </Center>
              </Box>
            ) : null} */}
            <Footer1 socialMedia={websiteStore?.socialMedia} />
          </Box>
        </Box>
      </HStack>

      <ModalEditHomeSection
        modal={modalEditHero}
        selectedHero={selectedHero}
        handleChangeHeroContent={handleChangeHeroContent}
        handleChangeSectionDetail={handleChangeSectionDetail}
        handleChangeSectionImage={handleChangeSectionImage}
      />

      <ModalCreateHomeSection
        modal={modalAddSectionHome}
        data={dataSection}
        setContent={setContent}
        content={content}
        handleSave={handleSaveModalHomePage}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        newSectionInput={newSectionInput}
        setNewSectionInput={setNewSectionInput}
        handleChangeSectionDetail={handleChangeSectionDetail}
        handleChangeSectionImage={handleChangeSectionImage}
        addNewData={addNewData}
        globalState={globalState}
      />
    </Box>
  );
};

export default WebsiteBuilderCreatePage;
