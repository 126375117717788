/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  Badge,
  Text,
  Center,
  Stack,
  HStack,
  Heading,
  Spacer,
  Button,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  Select,
  ModalCloseButton,
  useToast,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { countDocumentsFirebase, getCollectionFirebase, sumDocumentsFieldFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice } from '../../Utils/numberUtil';
import moment from 'moment';
import DatePicker from '../../Components/DatePicker/DatePicker';
import { FaRegCalendar } from 'react-icons/fa';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import ModalDetailOrder from '../../Components/Modals/ModalDetailOrder';
import DynamicTable from '../../Components/Table/DynamicTable';
import OrdersDetailAnalytics from './OrdersDetail/OrdersDetailAnalytics';
import PropTypes from 'prop-types';
import { clientTypessense } from '../../Api/Typesense';

TablePage.propTypes = {
  sendDataToParent: PropTypes.func.isRequired,
};

const OrdersPage = () => {
  const [dataFromChild, setDataFromChild] = useState('');

  // Fungsi untuk menerima data dari komponen anak
  const receiveDataFromChild = (data) => {
    setDataFromChild(data);
  };
  return (
    <>
      <Stack my={4} spacing={5} p={[1, 1, 5]}>

        <HStack mb="5">
          <BackButtons />
          <Heading size={'md'} fontWeight="bold">
            Data Orders
          </Heading>
        </HStack>
        <Analytics data={dataFromChild} />

        <TablePage sendDataToParent={receiveDataFromChild} />
      </Stack>

    </>
  )

};

export default OrdersPage;



function Analytics(data) {
  return (
    <>
      <Stack>
        <OrdersDetailAnalytics orderModule={data} />
      </Stack>
    </>
  )
}


function TablePage(props) {
  const [dataOrders, setDataOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [option, setOption] = useState([]);
  const [detailOrder, setDetailOrder] = useState({});
  const [product, setProduct] = useState(null);
  const [status, setStatus] = useState(null);
  const [sort, setSort] = useState('desc');
  const [income, setIncome] = useState();
  const [module, setModule] = useState(null)
  const [store, setStore] = useState([])
  const [storeId, setStoreId] = useState(null)
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [filteredData, setFilteredData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');

  const [originData, setOriginData] = useState([]);

  const modalFilterDate = useDisclosure();

  const orderDetail = useDisclosure();

  const globalState = useUserStore();

  const toast = useToast();

  const itemsPerPage = 10;

  props?.sendDataToParent();
  const getDataOrders = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      // { field: 'module', operator: '==', value: 'commerce' },
    ];
    

    const conditionsOptions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];
    // if (status) conditions.push({ field: 'paymentStatus', operator: '==', value: status });
    // if (product) conditions.push({ field: 'productIds', operator: '==', value: [product] });
    const sortBy = { field: 'createdAt', direction: sort };
    const limitValue = startIndex + itemsPerPage;

    //for paid user
    const conditionsPaid = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      // { field: 'module', operator: '==', value: orderModule },
      { field: 'paymentStatus', operator: '==', value: 'PAID' },
    ];
    try {
      if(module === 'pos') {
        const data = await getCollectionFirebase('store', conditionsOptions)
        setStore(data);
        // console.log(data)
      }
      const orders = await getCollectionFirebase(
        'orders',
        conditions,
        sortBy,
        limitValue
      );
      const options = await getCollectionFirebase(
        'product',
        conditionsOptions
      );
      setDataOrders(orders);
      setOption(options)
      setOriginData(orders);
      const totalOrders = await getCollectionFirebase('orders', conditions);
      setTotalPages(Math.ceil(totalOrders / itemsPerPage));
      // const paidOrders = await getCollectionFirebase('orders', conditionsPaid);

      // const totalIncome = paidOrders.reduce((a, b) => a + b.amount, 0);



    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    try {
      const totalIncome = await sumDocumentsFieldFirebase('orders', 'amount', conditionsPaid);
      setIncome(totalIncome)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

  };

  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: '',
      filter_by: 'productIds:= ehH9XGUoNJro8J8tEyFl',
      sort_by: '_text_match:desc '
    };
    clientTypessense
      .collections('orders')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setDataOrders(newData)
      });
  }

  const handleOpenModalDetail = (detailOrder) => {
    setDetailOrder(detailOrder);
    orderDetail.onOpen();
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const searchFilterFunction = (text) => {
    if (text) {
      const newData = originData?.filter((item) => {
        const orderName = item.contact_information?.name
          ? item.contact_information?.name.toUpperCase()
          : ''.toUpperCase();
        const orderPhoneNumber = item.contact_information?.phone_number
          ? item.contact_information?.phone_number.toUpperCase()
          : ''.toUpperCase();
        const orderId = item.id ? item.id.toUpperCase() : ''.toUpperCase();

        const textData = text.toUpperCase();

        return (
          orderName.indexOf(textData) > -1 ||
          orderPhoneNumber.indexOf(textData) > -1 ||
          orderId.indexOf(textData) > -1
        );
      });
      setDataOrders(newData);
    } else {
      setDataOrders(originData);
    }
  };

  const filteredCategory = (value) => {
    if (value) {
      const newData = originData?.filter((item) => {
        return item.productType === value;
      });
      setDataOrders(newData);
    } else {
      setDataOrders(originData);
    }
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    filterDataByDateRange(dateRange);
  };

  const filterDataByDateRange = (dateRange) => {
    if (!dateRange) {
      setDataOrders(originData);
    } else {
      const newData = originData?.filter((item) => {
        const orderDate = moment(item.createdAt.seconds * 1000);

        return (
          orderDate.isSameOrAfter(dateRange.startDate) &&
          orderDate.isSameOrBefore(dateRange.endDate)
        );
      });
      setDataOrders(newData);
    }
  };

  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  useEffect(() => {
    if (!selectedDateRange) {
      getDataOrders();
    } else {
      filterDataByDateRange(selectedDateRange);
    }
  }, [globalState.currentProject, currentPage, selectedDateRange, sort, status, product]);

  const tableHeader = [
    'no',
    'Created Date',
    'Order',
    'Products',
    // 'Product Type',
    // 'Category',
    // 'Shipping Status',
    'Payment Status',
    'Payment Method',
    'Order Value',
    'UTM'
  ];

  let tableData = [];
  tableData = dataOrders?.map((data, index) => {
    const createdDate = (
      <Stack direction="column" spacing={0}>
        <Text fontSize="sm">
          {moment(data?.createdAt?.seconds * 1000).format('MMMM Do, YYYY')}
        </Text>
        <Text fontSize="sm">
          {moment(data?.createdAt?.seconds * 1000).format('LT')}
        </Text>
      </Stack>
    );

    const name = data?.contact_information?.name || '';
    const order = data?.id;
    const productType = data?.productType || '';
    const category = data?.productCategory || '';
    const shippingStatus = data?.shippingStatus || '';
    const paymentStatus = data?.paymentStatus || '';
    const paymentService = data?.paymentService || data?.payment_information?.method || data?.payment_information?.payment_type || '';
    const orderValue = data?.payment_information?.amount || data?.amount || data?.opportunity_value || '';

    return {
      ...data,
      'no': index + 1,
      'Created Date': createdDate,
      Order: (
        <Stack spacing={0}>
          <Text textTransform={'capitalize'}>{name}</Text>
          <Text color="gray.500" fontSize={'xs'}>
            Order Id: {order}
          </Text>
        </Stack>
      ),
      'Products': <Flex>
        {data?.items?.map((item, i) => (
          <Tooltip label={item?.name} key={i}>
            <Stack mx={'2px'} maxW={50} _hover={{ transform: 'scale(1.1)' }}>
              {item?.images?.length > 0 && <Image maxW={50} src={item?.images[0]} alt={'product'} />}
              <Text isTruncated>{item?.name}</Text>
            </Stack>
          </Tooltip>
        ))}
      </Flex>,
      'Product Type': <Text textTransform={'capitalize'}>{productType}</Text>,
      Category: category,
      'Shipping Status': (
        <Badge colorScheme={shippingStatus === 'SHIPPED' ? 'green' : 'orange'}>
          {shippingStatus ? shippingStatus : 'No Data'}
        </Badge>
      ),
      'Payment Status': (
        <Badge colorScheme={paymentStatus === 'PENDING' ? 'red' : 'green'}>
          {paymentStatus === 'PENDING' ? 'UNPAID' : paymentStatus}
        </Badge>
      ),
      'Payment Method': (
        <Text textTransform={'uppercase'}>
          {paymentService ? paymentService : 'No Data'}
        </Text>
      ),
      'Order Value': <Text>Rp. {formatFrice(orderValue)}</Text>,
      'UTM': <Stack justifyContent={'center'}>
        <Text>{data?.utm_content}</Text>
        <Text>{data?.utm_medium}</Text>
        <Text>{data?.utm_campaign}</Text>
      </Stack>,
    };
  });

  return (
    <>
      <HStack spacing={3} justifyContent="space-between" alignItems={'center'}>
        <Spacer />
        <HStack>
          <Select placeholder='Select Product' maxW={'200px'} onChange={(e) => { 
            setProduct(e.target.value);
            handleTypesenseSearch(e.target.value);
          }}>
            {option.map((x) => (
              <option key={x?.id} value={x?.id}>{x?.name}</option>
            ))}
          </Select>

          <Select placeholder='Status Payment' maxW={'200px'} onChange={(e) => { 
            setStatus(e.target.value); 
            handleTypesenseSearch(e.target.value); 
          }}>
            <option value='PAID'>Paid</option>
            <option value='PENDING'>Unpaid</option>
          </Select>

          <Select placeholder='Sort Data' maxW={'200px'} onChange={(e) => setSort(e.target.value)}>
            <option value='desc'>Paling Baru</option>
            <option value='asc'>Paling Lama</option>
          </Select>

          <Select placeholder='Select Module' maxW={'200px'} onChange={(e) => { 
            setModule(e.target.value); 
            handleTypesenseSearch(e.target.value); 
          }}>
            <option value='commerce'>Commerce</option>
            <option value='pos'>POS</option>
          </Select>

          {module === 'pos' ? (
            <Select placeholder='Select Store' maxW={'200px'} onChange={(e) => { 
              setStoreId(e.target.value); 
              handleTypesenseSearch(e.target.value); 
            }}>
              {store.map((x) => (
                <option key={x?.id} value={x?.id}>{x?.name}</option>
              ))}
            </Select>
          ) : null}

          <Input
            type="text"
            placeholder="Search Order Id"
            color="black"
            shadow="base"
            flex={1}
            sx={inputStyles}
            fontSize="sm"
            onChange={(e) => searchFilterFunction(e.target.value)}
          />

          <Select
            placeholder="Select Type"
            shadow={'base'}
            flex={1}
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              filteredCategory(e.target.value);
            }}
          >
            <option value="physical">Physical</option>
            <option value="digital">Digital</option>
          </Select>

          <DynamicButton
            onClick={modalFilterDate.onOpen}
            action={'custom'}
            icon={FaRegCalendar}
            color={'blue'}
            title={'Filter Date'}
          />
        </HStack>
      </HStack>


      <Stack py={3} spacing={1} justifyContent={'center'}>
        <Text>Total Income Paid Orders In Project</Text>
        <Heading>Rp {formatFrice(income)},-</Heading>
      </Stack>

      <DynamicTable
        size={'sm'}
        textSize={13}
        header={tableHeader}
        data={tableData}
        onEdit={handleOpenModalDetail}
      />
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Box px={5}>
          {(!selectedDateRange ||
            Object.keys(selectedDateRange).length === 0) &&
            (dataOrders?.length > 0 ? (
              <Button
                colorScheme={'blue'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            ) : null)}

          {selectedDateRange &&
            Object.keys(selectedDateRange).length !== 0 &&
            (filteredData?.length > 0 ? (
              <Button
                colorScheme={'blue'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            ) : null)}
        </Box>

      </Stack>

      <ModalDetailOrder
        isOpen={orderDetail.isOpen}
        onClose={orderDetail.onClose}
        detailOrder={detailOrder}
        getDataOrders={getDataOrders}
      />

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format('LLL')}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format('LLL')}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={'xs'}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>



  );
}
