import { Box,Button,HStack,Heading,Spacer,Stack,Text, useToast } from '@chakra-ui/react';
import React,{ useEffect,useState } from 'react';
import { FcPlus } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import '../../index.css';

const MarketingWebsiteBuilderCreatePage = () => {
  const [result,setResult] = useState([])
  const [websiteConfig,setWebsiteConfig] = useState([])
  // const [id , setId] = useState(1);
  // const [isMobile,setIsMobile] = useState(false)
  const globalState = useUserStore()

  const toast = useToast()

  const navigate = useNavigate()

  // const getTemplate = async () => {
  //   const conditions = [{ field: 'type',operator: '==',value: 'tailblocks' }];

  //   try {
  //     const res = await getCollectionFirebase('templates',conditions)
  //     setDataTemplate(res)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }


  const getWebsiteConfig = async () => {
    // let conditions = [
    //   { field: 'id',operator: '==',value: 'zHrpnBlo8UBsNpOfvQUt' },
    // ]

    // if (globalState?.currentCompany !== '8NCG4Qw0xVbNR6JCcJw1') {
    const  conditions = [
      { field: 'companyId',operator: '==',value: globalState?.currentCompany },
      { field: 'projectId',operator: '==',value: globalState?.currentProject },
      { field: 'type',operator: '==',value: 'websiteBuilder' },
    ]
    // }


    try {
      const res = await getCollectionFirebase('websites',conditions)

      setWebsiteConfig(res[0])
      setResult(res)

    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  }

  useEffect(() => {
    // getTemplate();
    getWebsiteConfig()

    return () => {
      setWebsiteConfig([])
      // setDataTemplate([])
    };
  },[globalState?.currentCompany,globalState?.currentProject]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading>Website Builder</Heading>
        <Spacer />

        {result?.length > 0 && (
          <Button
            colorScheme="green"
            variant="outline"
          >
            <HStack spacing={2} onClick={() => navigate(`${websiteConfig?.id}/edit`)}>
              <FcPlus />
              <Text textTransform="uppercase" fontSize="sm" fontWeight={500}>
                Edit
              </Text>
            </HStack>
          </Button>
        )}
      </HStack>

      {result?.length > 0 ? (
        <Stack
          gap={2}
          dir='column'
          my={5}>
          <Stack >
            <HStack
              bg={'white'}
              p={3}>
              {/* <HStack
                spacing={0}
                marginEnd={20}
                borderRadius={'md'}
                overflow={'hidden'}
                //   bg={'blue.300'}
                color={'white'}>
                <Box
                  onClick={() => setIsMobile(false)}
                  bg={!isMobile ? 'blue.400' : 'gray'}
                  p={2}
                  width={20}
                  cursor={'pointer'}
                  fontWeight={500}>
                  Desktop
                </Box>
                <Box
                  onClick={() => setIsMobile(true)}
                  bg={!isMobile ? 'gray ' : 'blue.400'}
                  p={2}
                  width={20}
                  cursor={'pointer'}
                  fontWeight={500}>
                  Mobile
                </Box>
              </HStack> 
              <Input
                width='50%'
                bg='gray.100'
                placeholder='Your Website'
                isDisabled
              /> */}

              <Button
                colorScheme='blue'
                onClick={() => {
                  // console.log(websiteConfig);
                  if (websiteConfig?.domain) {
                    window.open(`https://${websiteConfig?.domain}`)
                  } else {
                    toast({
                      title : 'Deoapp',
                      description : 'Domain not found',
                      status : 'error',
                      isClosable : true
                    })
                  }
                  // setIsMobile(false)
                }}
              >
                Go To Website
              </Button>
            </HStack>
            <Box border={'1px'} w={'full'} h={'100vh'}>
              <iframe
                src={`https://${websiteConfig?.domain}` || 'https://deoapp-page-view-qwik.vercel.app/'}
                width={'100%'}
                height={'100%'}
              />
            </Box>
          </Stack>
        </Stack>
      ) : (
        // <MarketingWebsiteBuilderHome getData={getWebsiteConfig} setId={setId} />
        <></>
      )}
    </Box>
  );
};

export default MarketingWebsiteBuilderCreatePage;
