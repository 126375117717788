/* eslint-disable react/prop-types */
import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FaRegAngry, FaRegSmile } from 'react-icons/fa'
import { FcFile } from 'react-icons/fc'
import { getUserByUid } from '../../../Utils/getUser'
import { FaRegFaceMeh } from 'react-icons/fa6'

function ChatCardReplyDetail({ x, globalState }) {
  return (
    <HStack gap="5" 
      w={'full'}
      spacing="1"
      bgColor={'#293136'}
      borderRadius="lg"
      py={2}
      px={3}
      textColor="white"
    >
      <Stack

      >
        {x.media?.type === 'image' && (
          <a href={x.media?.link} target="_blank" rel="noreferrer">
            <img
              src={x.media?.link}
              alt="Image"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </a>
        )}
        {x.media?.type === 'audio' && (
          <a href={x.media?.link} target="_blank" rel="noreferrer">
            <audio style={{ maxWidth: '100%' }} controls>
              <source src={x.media?.link} type="audio/mpeg" />
                            Your browser does not support the audio element.
            </audio>
          </a>
        )}
        {x.media?.type === 'video' && (
          <a href={x.media?.link} target="_blank" rel="noreferrer">
            <video
              style={{ maxWidth: '100%', maxHeight: '300px' }}
              controls
            >
              <source src={x.media?.link} type="video/mp4" />
                            Your browser does not support the video tag.
            </video>
          </a>
        )}
        {x.media?.type === 'file' && (
          <a href={x.media.link} target="_blank" rel="noreferrer">
            <FcFile style={{ fontSize: '50px' }} />
          </a>
        )}
        {x.media && x.media?.link && !x.media?.type && (
          <a href={x.media} target="_blank" rel="noreferrer">
            <FcFile style={{ fontSize: '50px' }} />
          </a>
        )}
        <HStack spacing="2" alignItems={'flex-start'}>

          {x?.sentiment &&
                        <Stack
                          alignItems={'flex-start'}
                          justifyContent="flex-start"
                          p={1}
                        >
                          {(x.sentiment === 'positive' ? (
                            <FaRegSmile color="green" size={20} />
                          ) : x.sentiment === 'negative' ? (
                            <FaRegAngry color="red" size={20} />
                          ) : (
                            <FaRegFaceMeh color="white" size={20} />
                          ))}
                        </Stack>

          }
          <Box color="fg.muted" fontSize={'sm'} lineHeight="tall">
            <HStack>


              <Text
                fontSize="sm"
                fontWeight={'bold'}
                textTransform={'capitalize'}
                color={x.user === globalState?.uid ? 'orange.600' : 'blue.500'}
              >
                {' '}
                {x?.user === 'AI'
                  ? 'AI'
                  : getUserByUid(globalState?.users, x?.user)?.name ||
                                    'Customer'}
              </Text>
              <Spacer />




            </HStack>
            <Stack py={1}>
              <div>

                <span
                  dangerouslySetInnerHTML={{
                    __html: x.message
                      .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                      .replace(/(?:\r\n|\r|\n)/g, '<br>')
                      .replace(
                        /\[(.*?)\]\((.*?)\)/g,
                        '<a href="$2" target="_blank" style="text-decoration: underline; color: blue;">$1</a>'
                      ),
                  }}
                />
              </div>
            </Stack>
          </Box>
        </HStack>
      </Stack>


    </HStack>
  )
}

export default ChatCardReplyDetail