/* eslint-disable react/prop-types */
import { Badge, Box, Button, Fade, Heading, Stack } from '@chakra-ui/react'
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd';
import { useParams } from 'react-router-dom';
import { countDocumentsFirebase, getCollectionFirebase, getCollectionFirebaseV2 } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
// import { addDocumentFirebase, getCollectionFirebase } from '../../Apis/firebaseApi';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import LineTaskCardComponent from '../Card/LineTaskCardComponent';
import LineTaskDefaultCardComponent from '../Card/LineTaskDefaultCardComponent';
import { add } from 'lodash';
import moment from 'moment';


function RetentionColumnsDefaultComponent({ allowedDropEffect, column, filterData, index, handleModalOpen }) {
  const [columnsData, setColumnsData] = useState([])
  const [columnsData2, setColumnsData2] = useState([])
  const param = useParams()
  const globalState = useUserStore();
  const [value, setValue] = useState();

  const handleLoad = () => {
    const today = moment().startOf('day').toDate();
    let time = moment().startOf('day').toDate();
    if (param.id === 'today-transaction') {
      time = moment().startOf('day').toDate();
    } else if (param.id === 'last-7-days-transaction' || param.id === 'inactive-period-7-day') {
      time = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
    } else if (param.id === 'last-14-days-transaction' || param.id === 'inactive-period-14-day') {
      time = new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000));
    } else if (param.id === 'last-30-days-transaction' || param.id === 'inactive-period-30-day') {
      time = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
    } else if (param.id === 'last-90-days-transaction' || param.id === 'inactive-period-90-day') {
      time = new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000));
    }


    const conditions = [
      { field: 'column', operator: '==', value: column },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = ''

    if (columnsData2.length > 0) {
      // console.log('kedua kali dan seterusnya')
      startAfter = columnsData2[columnsData2.length - 1].lastUpdated
      if (filterData?.status) conditions.push({ field: 'status', operator: '==', value: filterData?.status })

    } else {

      startAfter = columnsData[columnsData.length - 1].lastUpdated
      if (filterData?.category)
        conditions.push({ field: 'status', operator: '==', value: filterData?.status })
    }

    if (param.id === 'today-transaction' || param.id === 'last-7-days-transaction' || param.id === 'last-14-days-transaction' || param.id === 'last-30-days-transaction' || param.id === 'last-90-days-transaction') {
      conditions.push({ field: 'last_transaction', operator: '<=', value: today })
      conditions.push({ field: 'last_transaction', operator: '>=', value: time })
    } else {
      conditions.push({ field: 'lastUpdated', operator: '<=', value: today })
      conditions.push({ field: 'lastUpdated', operator: '>=', value: time })
    }

    getCollectionFirebaseV2('customer', { conditions }, { sortBy }, { limitValue }, { startAfterData: startAfter })
      .then((x) => {
        const data = x.filter((doc) => doc.total_transaction >= 1);
        const updateData = [...columnsData2, ...data]
        setColumnsData2(updateData)
      })

  }

  //   console.log(columnsData2,'colomn')


  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'name',
      filter_by: `column:${column} `,
      sort_by: '_text_match:desc'
    };
    clientTypessense
      .collections('customer')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setColumnsData(newData)
      });
  }

  useEffect(() => {
    let unsubscribe = () => { };

    const fetchData = async () => {
      const today = moment().startOf('day').toDate();
      let time = moment().startOf('day').toDate();
      if (param.id === 'today-transaction') {
        time = moment().startOf('day').toDate();
      } else if (param.id === 'last-7-days-transaction' || param.id === 'inactive-period-7-day') {
        time = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
      } else if (param.id === 'last-14-days-transaction' || param.id === 'inactive-period-14-day') {
        time = new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000));
      } else if (param.id === 'last-30-days-transaction' || param.id === 'inactive-period-30-day') {
        time = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
      } else if (param.id === 'last-90-days-transaction' || param.id === 'inactive-period-90-day') {
        time = new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000));
      }

      if (filterData?.search) {
        setTimeout(function () {
          handleTypesenseSearch(filterData.search);
        }, 300);
      }

      const conditions = [];

      if (filterData?.status) {
        conditions.push({ field: 'status', operator: '==', value: filterData.status });
      }

      const sortBy = { field: 'last_transaction', direction: 'desc' };
      const limitValue = 5;

      let collectionRef;

      if ( param.id === 'last-7-days-transaction' || param.id === 'last-14-days-transaction' || param.id === 'last-30-days-transaction' || param.id === 'last-90-days-transaction') {
        collectionRef = query(
          collection(db, 'customer'),
          where('column', '==', column),
          where('projectId', '==', globalState?.currentProject),
          where('companyId', '==', globalState?.currentCompany),
          where('last_transaction', '<=', today),
          where('last_transaction', '>=', time),
          where('total_paid_transaction', '>=', 1),
          orderBy('last_transaction', 'desc'),
          limit(limitValue)
        );
      } else if(param.id === 'today-transaction') {
        collectionRef = query(
          collection(db, 'customer'),
          where('column', '==', column),
          where('projectId', '==', globalState?.currentProject),
          where('companyId', '==', globalState?.currentCompany),
          where('last_transaction', '>=', time),
          where('total_paid_transaction', '>=', 1),
          orderBy('last_transaction', 'desc'),
          limit(limitValue)
        );
      } else {
        collectionRef = query(
          collection(db, 'customer'),
          where('column', '==', column),
          where('projectId', '==', globalState?.currentProject),
          where('companyId', '==', globalState?.currentCompany),
          where('lastUpdated', '<=', today),
          where('lastUpdated', '>=', time),
          // orderBy('last_transaction', 'desc'),
          limit(limitValue)
        );
      }

      // console.log(today, time, 'wakry')

      unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        const addTask = [];
        querySnapshot.forEach((doc) => {
          addTask.push({ id: doc.id, ...doc.data() });
        });
        // const data = addTask.filter((doc) => doc.total_transaction >= 1);
        setColumnsData(addTask);
        // console.log(addTask, 'addTask');
      });
    };

    fetchData();

    return () => {
      unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [filterData, globalState.currentProject]);


  // console.log(columnsData, 'datak')

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'column',
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect],
  )

  const ColumnTasks = (datas, type, handleModalOpen) => datas?.map((x, index) => {
    // console.log(x, 'ini datanya di columntask')
    if (x.id)
      return (
        <Fade in={true} initialscale={0.9} key={index}>
          <LineTaskDefaultCardComponent
            key={index}
            task={x}
            indexNumber={index}
            setData={type === 'snapshot' ? setColumnsData : setColumnsData2}
            columnsData={datas}
            handleModalOpen={handleModalOpen}
          />
        </Fade>
      )
  });

  return (
    <Box w='xs' m='1' >
      <Heading fontSize="md" mb={1} letterSpacing="wide" textAlign='center'>
        <Badge
          w='full'
          fontSize='xl'
          px={2}
          py={1}
          rounded="lg"
          bgColor={`green.${index ? index * 100 : 50}`}
          color='black'
        >
          {column}
        </Badge>
      </Heading>

      <Stack
        ref={drop}
        direction='column'
        h={{ base: '30vh', md: '80vh' }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver ? 'red' : 'none'}
        rounded="lg"
        boxShadow="md"
        overflow="auto"
        opacity={isOver ? 0.85 : 1}
      >
        {ColumnTasks(columnsData, 'snapshot', handleModalOpen)}
        {columnsData2?.length ? ColumnTasks(columnsData2, 'manual', handleModalOpen) : <></>}
        {columnsData?.length > 4 && columnsData2?.length === 0 ?
          <Button onClick={() => handleLoad()}> Load more</Button>
          :
          columnsData2?.length > 4 ?
            <Button onClick={() => handleLoad()}> Load more</Button>
            :
            <></>
        }
      </Stack>
    </Box >
  )
}

export default RetentionColumnsDefaultComponent