import { HStack, Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import React from 'react'
import useUserStore from '../../Hooks/Zustand/Store'
import BackButtons from '../../Components/Buttons/BackButtons'
import CardAvatar from '../../Components/Card/CardAvatar'
import { FcShipped } from 'react-icons/fc'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const ShippingPage = () => {

  const globalState = useUserStore();
  const navigate = useNavigate()

  const dataShipping = {
    name: 'OExpress',
    link: 'oExpress'
  }


  return (
    <Stack p={[1, 1, 5]} gap={5}>
      <HStack spacing={3}>
        <BackButtons />
        <Heading size={'md'}>Shipping page</Heading>
      </HStack>
      <Stack>
        <SimpleGrid columns={[1, 2, 4]} gap="3">
          {dataShipping?.map((x, i) => (
            <Stack key={i}>
              <CardAvatar
                data={x}
                title={x.name}
                globalState={globalState}
                onNavigate={() => navigate(`${(x.link)}`)}
                timeCreated={moment(new Date()).valueOf() / 1000}
                Icon={FcShipped}
              />
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}

export default ShippingPage