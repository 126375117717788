import { Button, Grid, HStack, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { clientTypessense } from '../../Api/Typesense';
import { collection, documentId, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../Config/firebase';
import BackButtons from '../../Components/Buttons/BackButtons';
import CategoryViewPage from './CategoryViewPage';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import { sendEmail } from '../../Api/sendEmailApi';


function ViewPage() {

  const [page, setPage] = useState(1)
  const [productList, setProductList] = useState([])
  const navigate = useNavigate()
  const globalState = useUserStore();


  const getDataFirestore = (idArr, page) => {
    try {
      console.log(idArr, 'idArr')
      const q = query(
        collection(db, 'product'),
        where(documentId(), 'in', idArr)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const arrProducts = [];

        // Loop through querySnapshot in the order of searchResultIds
        idArr.forEach((id) => {
          const doc = querySnapshot.docs.find((doc) => doc.id === id);
          if (doc) {
            const objData = doc.data();
            objData.id = doc.id;
            arrProducts.push(objData);
          }
        });

        if (arrProducts.length > 0) {
          arrProducts.sort((a, b) => a.price - b.price);
        }

        if (page > 1) {
          setProductList((prevProductList) => [...prevProductList, ...arrProducts]);
        } else {
          setProductList(arrProducts);
        }


      });
    } catch (error) {
      console.log(error, 'ini error')
    }
  }


  const getData = async () => {
    try {
      const searchParameters = {
        q: '',
        query_by: 'name',
        filter_by: `projectId: ${globalState.currentProject} && companyId: ${globalState.currentCompany}`,
        page: page,
        per_page: 20,
        sort_by: '_text_match:desc',
      };

      const searchResult = await clientTypessense
        .collections('product')
        .documents()
        .search(searchParameters);

      const totalPages = Math.ceil(searchResult.found / searchParameters.per_page);


      const searchResultData = searchResult.hits.map((hit) => hit.document.id);
      getDataFirestore(searchResultData, totalPages)

    } catch (error) {
      console.log(error, 'ini error')
    }


  }

  const handleTes = async () => {
    try {
      const response = await sendEmail();
      console.log(response, 'ress')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()

    return () => {
    }
  }, [globalState.currentCompany])

  const handleNavigate = (data) => {
    console.log(data, 'ini data')
    navigate(`/view/${data?.id}/${data?.slug}`, {state: data})
  }


  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack spacing={5}>
        <BackButtons />
        <Text fontWeight={'bold'} fontSize={'lg'}>Product</Text>
        <Button onClick={handleTes}>tes</Button>
      </HStack>
      <Grid  templateColumns={{ base: '1fr', md: '1fr 4fr' }}>
        <Stack>
          <CategoryViewPage/>
        </Stack>
        <Stack>
          {productList?.length > 0 && (
            <SimpleGrid columns={[2, 4, 6]} gap={5}>
              {productList?.map((x, index) => {
                return (
                  <Stack key={index}>
                    <Image src={x?.images[0]} />
                    <Text>{x?.name}</Text>
                    <Text>{x?.price}</Text>
                    <Button onClick={() => handleNavigate(x)}>Buy</Button>
                  </Stack>
                )
              })}
            </SimpleGrid>
          )}
        </Stack>

      </Grid>

    </Stack>
  )
}

export default ViewPage