/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import idrDecimalHelper from '../../../../../Utils/idrDecimalHelper'

export const Product1 = ({ header, content, data, filter }) => {
  const [hoveredIndex, setHoveredIndex] = useState()
  const [filteredData, setFilteredData] = useState(data);

  const handleFilterChange = (event, selectedFilter) => {
    const { checked } = event.target;

    
    if (checked) {
      const newData = data.filter((product) =>
        product.tags && product.tags.includes(selectedFilter)
      );
      setFilteredData(newData);
    } else {
      setFilteredData(data);
    }
  };

  

  // console.log(filter)
  // console.log(data,'ini data')
  // const dataDummy = [
  //   {
  //     name: 'Focus Paper Refill',
  //     price: '13',
  //     description: '3 sizes available',
  //     image1: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     image2: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     link: '#'
  //   },
  //   {
  //     name: 'Focus Paper Refill',
  //     price: '13',
  //     description: '3 sizes available',
  //     image1: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     image2: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     link: '#'
  //   },
  //   {
  //     name: 'Focus Paper Refill',
  //     price: '13',
  //     description: '3 sizes available',
  //     image1: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     image2: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     link: '#'
  //   },
  //   {
  //     name: 'Focus Paper Refill',
  //     price: '13',
  //     description: '3 sizes available',
  //     image1: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     image2: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     link: '#'
  //   },
  //   {
  //     name: 'Focus Paper Refill',
  //     price: '13',
  //     description: '3 sizes available',
  //     image1: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     image2: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     link: '#'
  //   },
  //   {
  //     name: 'Focus Paper Refill',
  //     price: '13',
  //     description: '3 sizes available',
  //     image1: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     image2: 'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg',
  //     link: '#'
  //   },
  // ]

  // const dataDisplay = data || dataDummy

  return (
    <div className='bg-white'>
      <div>
        {/* <!--
      Mobile filter dialog

      Off-canvas menu for mobile, show/hide based on off-canvas menu state.
    --> */}
        <div className='relative z-40 lg:hidden' role='dialog' aria-modal='true'>
          {/* <!--
        Off-canvas menu backdrop, show/hide based on off-canvas menu state.

        Entering: 'transition-opacity ease-linear duration-300'
          From: 'opacity-0'
          To: 'opacity-100'
        Leaving: 'transition-opacity ease-linear duration-300'
          From: 'opacity-100'
          To: 'opacity-0'
      --> */}
          <div className='fixed inset-0 bg-black bg-opacity-25'></div>

          <div className='fixed inset-0 z-40 flex'>
            {/* <!--
          Off-canvas menu, show/hide based on off-canvas menu state.

          Entering: 'transition ease-in-out duration-300 transform'
            From: 'translate-x-full'
            To: 'translate-x-0'
          Leaving: 'transition ease-in-out duration-300 transform'
            From: 'translate-x-0'
            To: 'translate-x-full'
        --> */}
            <div className='relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl'>
              <div className='flex items-center justify-between px-4'>
                <h2 className='text-lg font-medium text-gray-900'>Filters</h2>
                <button type='button' className='-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500'>
                  <span className='sr-only'>Close menu</span>
                  <svg className='h-6 w-6' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' aria-hidden='true'>
                    <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                  </svg>
                </button>
              </div>

              {/* <!-- Filters --> */}
              {filter?.length > 0 || filter !== null ? (
                <form className='mt-4'>
                  {filter?.map((x, i) => (
                    <div key={i} className='border-t border-gray-200 pb-4 pt-4'>
                      <fieldset>
                        <legend className='w-full px-2'>
                          {/* <!-- Expand/collapse section button --> */}
                          <button type='button' className='flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500' aria-controls='filter-section-0' aria-expanded='false'>
                            <span className='text-sm font-medium text-gray-900'>{x?.name}</span>
                            <span className='ml-6 flex h-7 items-center'>
                              {/* <!--
                          Expand/collapse icon, toggle classNamees based on section open state.

                          Open: '-rotate-180', Closed: 'rotate-0'
                        --> */}
                              <svg className='rotate-0 h-5 w-5 transform' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z' clip-rule='evenodd' />
                              </svg>
                            </span>
                          </button>
                        </legend>

                        <div className='px-4 pb-2 pt-4' id='filter-section-0'>
                          {x?.filterData?.length > 0 ? (
                            <div className='space-y-6'>
                              {x?.filterData?.map((y, j) => (
                                <div key={j} className='flex items-center'>
                                  <input id='color-0-mobile' name='color[]' value='white' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' onChange={(event) => handleFilterChange(event, y)} />
                                  <label htmlFor='color-0-mobile' className='ml-3 text-sm text-gray-500 capitalize'>{y}</label>
                                </div>
                              ))}
                            </div>
                          ):(
                            <></>
                          )}
                        </div>
                      </fieldset>
                    </div>
                  ))}
                </form>
              ):(
                <></>
              )}
            </div>
          </div>
        </div>

        <main className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
          <div className='border-b border-gray-200 pb-10'>
            <h1 className='text-4xl font-bold tracking-tight text-gray-900'>{header || 'New Arrivals'}</h1>
            <p className='mt-4 text-base text-gray-500'>{content || 'Checkout out the latest release of Basic Tees, new and improved with four openings!'}</p>
          </div>

          <div className='pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4'>
            <aside>
              <h2 className='sr-only'>Filters</h2>

              {/* <!-- Mobile filter dialog toggle, controls the 'mobileFilterDialogOpen' state. --> */}
              <button type='button' className='inline-flex items-center lg:hidden'>
                <span className='text-sm font-medium text-gray-700'>Filters</span>
                <svg className='ml-1 h-5 w-5 flex-shrink-0 text-gray-400' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                  <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                </svg>
              </button>

              <div className='hidden lg:block'>
                {filter?.length > 0 || filter !== null ? (
                  <form className='space-y-10 divide-y divide-gray-200'>
                    {filter?.map((x, i) => (
                      <div key={i}>
                        <fieldset>
                          <legend className='block text-sm font-medium text-gray-900'>{x?.name}</legend>

                          {x?.filterData?.length > 0 ? (
                            <div className='space-y-3 pt-6'>
                              {x?.filterData?.map((y, j) => (
                                <div key={j} className='flex items-center'>
                                  <input id='color-0' name='color[]' value='white' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'onChange={(event) => handleFilterChange(event, y)} />
                                  <label htmlFor='color-0' className='ml-3 text-sm text-gray-600'>{y}</label>
                                </div>
                              ))}
                            </div>
                          ):(
                            <></>
                          )}
                        </fieldset>
                      </div>
                    ))}
                  </form>
                ):(
                  <></>
                )}
              </div>
            </aside>

            {/* <!-- Product grid --> */}
            <div className='mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3'>
              {/* <!-- Your content --> */}
              <div className='bg-white'>
                <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
                  <h2 id='products-heading' className='sr-only'>Products</h2>

                  {/* {data?.length > 0 ? ( */}
                  {filteredData?.length > 0 ? (
                    <div className='grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8'>
                      {/* {dataDisplay?.map((product, index) => ( */}
                      {filteredData?.map((product, index) => (
                        <a href={product?.link} className='group' key={index}>
                          <div className='relative bg-cover bg-center w-56 h-72 transition-opacity' style={{
                            backgroundImage: `url(${hoveredIndex === index ? product.image2 : product.image1})`,
                            transition: 'background-image 0.3s ease',
                          }}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}>
                                  
                          </div>
                          <div className='mt-4 flex flex-col items-center justify-between text-base font-medium text-gray-900'>
                            <h3>{product?.name}</h3>
                            {product?.discount === '0' || product?.discount === '' ? (
                              <p>{idrDecimalHelper(product?.price)}</p>
                            ):(
                              <div className='text-center'>
                                <p className='line-through text-sm'>{idrDecimalHelper(product?.price)}</p>
                                <p className='text-red-600'>{idrDecimalHelper(product?.price - product?.price*(product?.discount/100))}</p>
                              </div>
                            )}
                          </div>
                          <p className='mt-1 text-sm italic text-gray-500'>{product?.description}</p>
                        </a>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <div className='grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8'>
                        <div>
                          <p>No Product Data</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </main>
      </div>
    </div>
  )
}


Product1.propTypes = {
  header: PropTypes.any, content: PropTypes.any, data: PropTypes.any, filter: PropTypes.any
}
