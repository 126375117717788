/* eslint-disable no-undef */
import _axios from './AxiosBarrier';

export const sendReplyWhatsapp = async (message, to, maxchat_key) => {

  try {
    const response = await _axios.post(
      '/whatsapp-sendMessageTextFront',
      {
        channel: 'whatsapp',
        to: to,
        msgType: 'text',
        text: message,
        maxchat_key
      }
    );

    return response;
  } catch (error) {
    return error.message;
  }
};

export const sendPushWhatsappFirst = async (templateId, to, maxchat_key) => {

  try {
    const response = await _axios.post(
      '/whatsapp-notificationText',
      {
        templateId: templateId,
        to: to,
        msgType: 'text',
        maxchat_key
      }
    );

    return response;
  } catch (error) {
    return error.message;
  }
};
