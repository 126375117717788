/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@chakra-ui/icons'
import { Box, HStack, Input, Stack, Text, useColorMode, useToast } from '@chakra-ui/react'
import { BsCake, BsInstagram, BsLinkedin, BsMailbox, BsTags, BsTiktok, BsTwitter, BsWhatsapp, BsFacebook, BsPerson } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import { updateDocumentFirebase } from '../../Api/firebaseApi'
import AccountDetail from './Components/AccountDetail'
import _axios from '../../Api/AxiosBarrier'
import { db } from '../../Config/firebase'
import { doc, onSnapshot } from '@firebase/firestore'



function ChatSidebarComponent() {
  
  const [loadingContact, setLoadingContact] = useState(false)
  const param = useParams()
  const toast = useToast()
  const { colorMode } = useColorMode();


  const customerRef = useRef({})
  const uidUserRef = useRef('')



  const getData = () => {
    customerRef.current = {}
    customerRef.current = ''
    try {
      onSnapshot(doc(db, 'chat_customer', param.id), async(doc) => {

        const data = doc.data()
        customerRef.current = { ...data, id: doc.id }

        if (data) {
          let resUser = await _axios.post('/user-getByPhone', { phoneNumber: `+${data?.phoneNumber}` });

          if (!resUser.status) {
            resUser = await _axios.post('/user-getByEmail', { email: data?.email });
          }
          uidUserRef.current = resUser?.uid || ''
        }

      });
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error.message,
        status: 'error',
        isClosable: true,
        position: 'top'
      });
    }
  }


  useEffect(() => {

    if(!param?.id) return

    const unsub = getData()
    return () => {
      unsub
    }
  }, [param.id])

  const handleInputChange = (e) => {
    customerRef.current = { ...customerRef, [e.target.name]: e.target.value }
  };

  const handleSave = async () => {
    const collectionName = 'chat_customer'
    const docName = param.id
    setLoadingContact(true)
    try {
      await updateDocumentFirebase(collectionName, docName, customerRef?.current);
      toast({
        title: 'Deoapp',
        description: 'Success save contact',
        status: 'success',
        isClosable: true,
        position: 'top'
      });

    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error.message,
        status: 'error',
        isClosable: true,
        position: 'top'
      });
    } finally {
      setLoadingContact(false)
      getData()
    }
  };


  return (
    <Box spacing="4"
      shadow={'revert'}
      boxShadow={'revert'}
    >
      <HStack h='100px' alignItems={'center'} justifyContent={'center'}
        bgColor={colorMode === 'light' ? 'blue.500' : ''}
        color={colorMode === 'light' ? 'white' : ''}
        borderTopRightRadius={'md'}
        boxShadow={'revert'}
      >
        <Text fontSize="md" fontWeight="bold">
              Contacts
        </Text>
      </HStack>

      <Stack shadow={'md'} bgColor={colorMode === 'light' ? 'white' : ''} color={colorMode === 'light' ? 'black' : ''} p={5} >

        <AccountDetail data={customerRef?.current} uidUser={uidUserRef?.current}/>
      </Stack>


      <Stack shadow={'md'}  h={'430px'} overflowY={'auto'} p="5" bgColor={colorMode === 'light' ? 'white' : ''} color={colorMode === 'light' ? 'black' : ''} >
        <Stack spacing={2}>
          <Text fontWeight={500} fontSize={'sm'} as="span" flex='1' textAlign='left'>
            Contacts Detail
          </Text>
        </Stack>
        <Stack>
          {/* <HStack >
            <Icon as={BsTags} />
            <Input type='text' size='sm' defaultValue={customerRef?.current?.user} disabled placeholder='ID number' />
          </HStack> */}
          <HStack >
            <Icon as={BsPerson} />
            <Input name={'name'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.name} placeholder='Full name' />
          </HStack>
          <HStack >
            <Icon as={BsMailbox} />
            <Input name={'email'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.email} placeholder='Email address' />
          </HStack>
          <HStack >
            <Icon as={BsWhatsapp} />
            <Input name={'phoneNumber'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.phoneNumber} placeholder='Whatsapp Number' />
          </HStack>
          <HStack >
            <Icon as={BsInstagram} />
            <Input name={'instagram'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.instagram} placeholder='Instagram handle @deoappcom' />
          </HStack>
          <HStack >
            <Icon as={BsFacebook} />
            <Input name={'facebook'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.facebook} placeholder='Facebook handle @deoappcom' />
          </HStack>
          <HStack >
            <Icon as={BsTwitter} />
            <Input name={'twitter'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.twitter} placeholder='Twitter / X handle @deoappcom' />
          </HStack >
          <HStack >
            <Icon as={BsTiktok} />
            <Input name={'tiktok'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.tiktok} placeholder='Tiktok handle @deoappcom' />
          </HStack>
          <HStack >
            <Icon as={BsLinkedin} />
            <Input name={'linkedin'} onChange={handleInputChange} type='text' size='sm' defaultValue={customerRef?.current?.linkedin} placeholder='Linkedin handle' />
          </HStack>
          <HStack >
            <Icon as={BsCake} />
            <Input name={'dateofbirt'} onChange={handleInputChange} type='date' size='sm' defaultValue={customerRef?.current?.dateofbirth} placeholder='Date of birth' />
          </HStack>
          <HStack spacing={3} alignItems={'center'} pt={2} justifyContent={'center'}>
            <DynamicButton isLoading={loadingContact} title={'Save Contact'} action={'create'} onClick={handleSave} size={'sm'} variant={'solid'} />
          </HStack>
        </Stack>



      </Stack>

    </Box>
  )
}

export default ChatSidebarComponent


