/* eslint-disable react/prop-types */
import { HStack, Icon, Stack, Input, Avatar, useToast, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import { BsMailbox, BsPerson, BsPhone, BsTags } from 'react-icons/bs'
import _axios from '../../../Api/AxiosBarrier'
import { getUserByUid } from '../../../Utils/getUser'
import useUserStore from '../../../Hooks/Zustand/Store'
import { IoIosContact } from 'react-icons/io'

function AccountDetail({ data, uidUser }) {

  const globalState = useUserStore();

  const [loading, setLoading] = useState(false)


  const [inputForm, setInputForm] = useState({
    name: data?.name || '',
    email: data?.email || '',
    phoneNumber: data?.phoneNumber || '',
  })


  const toast = useToast()




  const createAccount = async () => {

    const dataCreateUser = {
      email: inputForm?.email,
      name: inputForm?.name,
      country: 'indonesia',
      phoneNumber: inputForm?.phoneNumber
    };

    setLoading(true)

    try {
      let resUser = await _axios.post('/user-getByPhone', { phoneNumber: `+62${inputForm.phoneNumber}` });

      if (!resUser.status) {
        resUser = await _axios.post('/user-getByEmail', { email: inputForm.email });
        if (!resUser.status) {
          resUser = await _axios.post('user-create', dataCreateUser);
        }if(!resUser.status){
          toast({
            title: 'Deoapp.com',
            description: resUser.message,
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });  
        }
      }
      uidUser = resUser?.uid || ''
      toast({
        title: 'Deoapp.com',
        description: 'Success',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false)
    }
  }



  return (
    <Stack>
      <Stack spacing={2}>
        <HStack alignItems={'center'} justifyContent={'center'}>
          {data?.email ? (
            <Avatar bgColor={'blue.500'} size={'md'} src={getUserByUid(globalState.users, uidUser)?.image || ''} name={data?.email || ''}/>
          ) : (
            <IoIosContact size={50} color='gray' />
          )}
        </HStack>

        {/* <HStack >
          <Icon as={BsTags} />
          <Input type='text' size='sm' disabled placeholder={'UID'} defaultValue={uidUser} />
        </HStack> */}

        <HStack >
          <Icon as={BsMailbox} />
          <Input type='text' size='sm' placeholder={data?.email || 'Email'} onChange={(e) => setInputForm({ ...inputForm, email: e.target.value })} />
        </HStack>

        <HStack >
          <Icon as={BsPerson} />
          <Input type='text' size='sm' placeholder={data?.name || 'Display Name'} onChange={(e) => setInputForm({ ...inputForm, name: e.target.value })} />
        </HStack>


        <HStack >
          <Icon as={BsPhone} />
          <Input type='text' size='sm' placeholder={data?.phoneNumber || 'Phone Number'} onChange={(e) => setInputForm({ ...inputForm, phoneNumber: e.target.value })} />
        </HStack>
      </Stack>

      <HStack spacing={3} alignItems={'center'} pt={2} justifyContent={'center'}>
        {uidUser === '' && (
          <DynamicButton isLoading={loading} title={'Add Account'} action={'create'} onClick={createAccount} size={'sm'} variant={'solid'} />
        )}
      </HStack>
    </Stack>
  )
}

export default AccountDetail