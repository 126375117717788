/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Text,
  HStack,
  Divider,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spacer,
  useToast,
  useDisclosure,
  Input,
  Button,
} from '@chakra-ui/react';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../Config/firebase';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';

import 'react-date-range/dist/styles';
import 'react-date-range/dist/theme/default.css';

import { useNavigate, useParams } from 'react-router-dom';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DynamicTable from '../../Components/Table/DynamicTable';
import ExportExcel from '../../Utils/ExportExcel';
import ComponentExportExcel from '../../Utils/ExportExcel';

const ProductVariants = ({ productId, totalStocks }) => {
  const navigate = useNavigate();
  const globalState = useUserStore();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState('')
  const [variantData, setVariantData] = useState([])
  const [productData, setProductData] = useState({});
  const toast = useToast()


  const itemsPerPage = 8;


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getProductData = () => {
    const res = localStorage.getItem('product_data_on_edit');
    const parsed = typeof res === 'object' && JSON.parse(res);
    setProductData(parsed);
  };

  const createNewProduct = () => {
    const withUnix = name + ' ' + Date.now();

    const data = {
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      name: name,
      slug: withUnix.split(' ').join('-'),
      productId: productId,
      type: productData?.type || ''
    };
    addDocumentFirebase('product_variants', data, globalState.currentCompany).then((x) => {
      navigate(`/products/${productId}/variant/${x}`);
    });
  };


  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },

      {
        field: 'productId',
        operator: '==',
        value: productId,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase(
        'product_variants',
        conditions,
        sortBy,
        limitValue,
      );
      setVariantData(res);

      const dataStocks =  res.map((x) => {
        return x?.stocks_int
      })
      if (dataStocks) {
        const total = dataStocks.reduce((a, b) =>  a + b, 0)
        return totalStocks(total)
      }

      console.log(dataStocks, 'dataStocks')

      const collectionRef = collection(db, 'product_variants');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      const countQuery = query(collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject),
        where('productId', '==', productId),
      );


      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleBulk = () => {
    navigate(`/products/${productId}/bulk-variant`)
  }

  useEffect(() => {
    getData();
    getProductData();

  }, [globalState?.currentProject, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };


  const tableHeader = ['image', 'name', 'price', 'type', 'stocks', 'sold'];


  let tableData = '';
  tableData = variantData?.map((data) => {
    const image = data?.images?.length > 0 ? data?.images[0] : '';
    const name = data?.name || '';
    const price = data?.price || '';
    const stocks = data?.stocks || '';
    const sold = data?.sold || '';
    const type = data?.type || '';

    return {
      data,
      image: image,
      name: (
        <Stack spacing={1}>
          <Text fontWeight={500} textTransform={'capitalize'}>
            {name}
          </Text>
          <Text fontSize={9}>{data?.id}</Text>
          <Text fontSize={9}>SKU : {data?.sku}</Text>
        </Stack>
      ),
      sold: sold,
      price: price,
      stocks: stocks,
      type: type
    };
  });

  const handleDetail = (x) => {
    navigate(`/products/${productId}/variant/${x.data.id}`)
  }

  const arrayToString = (arr) => {
    if (!Array.isArray(arr)) {
      return '';
    }
    return arr.join(', ');
  };
  const excelData = variantData.map((x) => {
    const string = {
      id: x.id || '',
      companyId: x?.companyId || '',
      createdAt: x?.createdAt || '',
      createdBy: x?.createdBy || '',
      currency: x?.currency || '',
      description: x?.description || '',
      duration: x?.duration || '',
      externalVariantSKUId: x?.externalVariantSKUId || '',
      external_productId: x?.external_productId || '',
      external_variantId: x?.external_variantId || '',
      external_variant_skuId: x?.external_variant_skuId || '',
      images: arrayToString(x?.images) || '',
      name: x?.name || '',
      price: x?.price || '',
      price_high: x?.price_high || '',
      price_high_int: x?.price_high_int || '',
      price_int: x?.price_int || '',
      price_low: x?.price_low || '',
      price_low_int: x?.price_low_int || '',
      product_skuId: x?.product_skuId || '',
      projectId: x?.projectId || '',
      productId: x?.productId || '',
      shipping: x?.shipping || '',
      slug: x?.slug || '',
      sold: x?.sold || '',
      sold_int: x?.sold_int || '',
      stocks: x?.stocks || '',
      stocks_int: x?.stocks_int || '',
      type: x?.type || '',
      variant_skuId: x?.variant_skuId || '',
      warehouseId: x?.warehouseId || '',
    }
    return string
  })


  return (
    <Box>
      <Stack py={2}>
        <HStack gap={5}>
          <Text fontSize={'xl'} fontWeight={500}>
            Product Variant
          </Text>
          <Spacer />
          <DynamicButton action={'create'} onClick={onOpen} title={'Add Variant'} />
          <DynamicButton action={'create'} onClick={handleBulk} title={'Bulk Add Variant'} />
          {/* <ExportExcel excelData={variantData} fileName={'Data Produk'} size={'md'} variant={'outline'} colorScheme={'blue'} title={'Print'} /> */}
          <ComponentExportExcel excelData={excelData} fileName={'Data Variant'} />
        </HStack>

        <Divider />
      </Stack>

      <Box>
        <DynamicTable
          header={tableHeader}
          data={tableData}
          //   onDelete={openModalDelete}
          //   onRead={openModalDeleteRole}
          onEdit={handleDetail}
        />

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Box>





      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new variant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" placeholder='Product name' onChange={(e) => setName(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewProduct()}
              title="Save"
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Box>
  );
};

export default ProductVariants;
