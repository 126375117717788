
import React from 'react'

export const Product2 = () => {

  return (
    <div className='bg-white'>
      <section aria-labelledby='features-heading' className='mx-auto max-w-7xl py-32 sm:px-2 lg:px-8'>
        <div className='mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0'>
          <div className='max-w-3xl'>
            <h2 id='features-heading' className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>Technical Specifications</h2>
            <p className='mt-4 text-gray-500'>The Organize modular system offers endless options for arranging your favorite and most used items. Keep everything at reach and in its place, while dressing up your workspace.</p>
          </div>

          <div className='mt-4'>
            <div className='-mx-4 flex overflow-x-auto sm:mx-0'>
              <div className='flex-auto border-b border-gray-200 px-4 sm:px-0'>
                <div className='-mb-px flex space-x-10' aria-orientation='horizontal' role='tablist'>
                  {/* <!-- Current: 'border-indigo-500 text-indigo-600', Default: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' --> */}
                  <button id='features-tab-1' className='border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-6 text-sm font-medium' aria-controls='features-panel-1' role='tab' type='button'>Design</button>
                  <button id='features-tab-2' className='border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-6 text-sm font-medium' aria-controls='features-panel-2' role='tab' type='button'>Material</button>
                  <button id='features-tab-3' className='border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-6 text-sm font-medium' aria-controls='features-panel-3' role='tab' type='button'>Considerations</button>
                  <button id='features-tab-4' className='border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-6 text-sm font-medium' aria-controls='features-panel-4' role='tab' type='button'>Included</button>
                </div>
              </div>
            </div>

            <div id='features-panel-1' className='space-y-16 pt-10 lg:pt-16' aria-labelledby='features-tab-1' role='tabpanel' tabIndex='0'>
              <div className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8'>
                <div className='mt-6 lg:col-span-5 lg:mt-0'>
                  <h3 className='text-lg font-medium text-gray-900'>Adaptive and modular</h3>
                  <p className='mt-2 text-sm text-gray-500'>The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.</p>
                </div>
                <div className='lg:col-span-7'>
                  <div className='aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5'>
                    <img src='https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-01.jpg' alt='Maple organizer base with slots, supporting white polycarbonate trays of various sizes.' className='object-cover object-center' />
                  </div>
                </div>
              </div>
            </div>
            <div id='features-panel-2' className='space-y-16 pt-10 lg:pt-16' aria-labelledby='features-tab-2' role='tabpanel' tabIndex='0'>
              <div className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8'>
                <div className='mt-6 lg:col-span-5 lg:mt-0'>
                  <h3 className='text-lg font-medium text-gray-900'>Natural wood options</h3>
                  <p className='mt-2 text-sm text-gray-500'>Organize has options for rich walnut and bright maple base materials. Accent your desk with a contrasting material, or match similar woods for a calm and cohesive look. Every base is hand sanded and finished.</p>
                </div>
                <div className='lg:col-span-7'>
                  <div className='aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5'>
                    <img src='https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-02.jpg' alt='Walnut organizer base with pen, sticky note, phone, and bin trays, next to modular drink coaster attachment.' className='object-cover object-center' />
                  </div>
                </div>
              </div>
            </div>
            <div id='features-panel-3' className='space-y-16 pt-10 lg:pt-16' aria-labelledby='features-tab-3' role='tabpanel' tabIndex='0'>
              <div className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8'>
                <div className='mt-6 lg:col-span-5 lg:mt-0'>
                  <h3 className='text-lg font-medium text-gray-900'>Helpful around the home</h3>
                  <p className='mt-2 text-sm text-gray-500'>Our customers use Organize throughout the house to bring efficiency to many daily routines. Enjoy Organize in your workspace, kitchen, living room, entry way, garage, and more. We can&#039;t wait to see how you&#039;ll use it!</p>
                </div>
                <div className='lg:col-span-7'>
                  <div className='aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5'>
                    <img src='https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-03.jpg' alt='Walnut organizer base with white polycarbonate trays in the kitchen with various kitchen utensils.' className='object-cover object-center' />
                  </div>
                </div>
              </div>
            </div>
            <div id='features-panel-4' className='space-y-16 pt-10 lg:pt-16' aria-labelledby='features-tab-4' role='tabpanel' tabIndex='0'>
              <div className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8'>
                <div className='mt-6 lg:col-span-5 lg:mt-0'>
                  <h3 className='text-lg font-medium text-gray-900'>Everything you&#039;ll need</h3>
                  <p className='mt-2 text-sm text-gray-500'>The Organize base set includes the pen, phone, small, and large trays to help you group all your essential items. Expand your set with the drink coaster and headphone stand add-ons.</p>
                </div>
                <div className='lg:col-span-7'>
                  <div className='aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5'>
                    <img src='https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-04.jpg' alt='Walnut organizer system on black leather desk mat on top of white desk.' className='object-cover object-center' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}