import { Avatar, Box, Button, Checkbox, HStack, Heading, Input, SimpleGrid, Spacer, Stack, Text, Textarea, useToast, useColorModeValue, IconButton, Switch, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import useUserStore from '../../../Hooks/Zustand/Store'
import { countDocumentsFirebase, getCollectionFirebase, updateDocumentFirebase } from '../../../Api/firebaseApi'
import { CheckIcon, CloseIcon, EditIcon, QuestionIcon } from '@chakra-ui/icons'
import { AwsUploadFile } from '../../../Utils/AwsUploadFile'
import CardFile from '../../../Components/Accounting/CardFile'
import { FaBuilding, FaClock, FaRobot, FaUser } from 'react-icons/fa'
import { MdConfirmationNumber, MdOutlineKey, MdOutlinePhoneIphone, MdOutlineSentimentSatisfied, MdOutlineTask, MdOutlineWhatsapp } from 'react-icons/md'
import { GiFlatPlatform } from 'react-icons/gi';

function ChatConfigDetailPage() {
  const globalState = useUserStore();
  const [usage, setUsage] = useState();
  const [bot, setBot] = useState(false);
  const [sentiment, setSentiment] = useState(false);
  const [isCardFileOpen, setIsCardFileOpen] = useState(false);
  const [fileName, setFileName] = useState('File Name')
  const [isEditing, setIsEditing] = useState(false);
  const [nameText, setNameText] = useState('Bot Name');
  const [newName, setNewName] = useState(nameText);
  const [point,setPoint] = useState(0)
  const [dataInput, setDataInput] = useState();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const toast = useToast();

  const handleCheckboxChange = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms(selectedPlatforms.filter((item) => item !== platform));
    } else {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    }
  };

  const fetchData = async () => {
    const conditions = [
      { field: 'projectId', operator: '==', value: globalState?.currentProject },
      { field: 'companyId', operator: '==', value: globalState?.currentCompany }
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 1;
    try {
      const res = await getCollectionFirebase(
        'chat_config',
        conditions,
        sortBy,
        limitValue,
      );

      const sum = await countDocumentsFirebase('chat_config', conditions)
      setUsage(sum)
      setDataInput(res)
      const selected = res?.[0]?.platform || [];
      setSelectedPlatforms(selected)
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  }
  const chatConfig = async () => {
    try {
      // const data = {
      //   // image_bot: dataInput.image || '',
      //   format_chat: dataInput[0].format_chat,
      //   name_bot: newName,
      //   platform: selectedPlatforms,
      //   projectId: globalState.currentProject,
      //   status: 'Requested',
      //   usage: usage,
      //   chatBot: bot,
      //   sentiment: sentiment
      // };

      let result;

      // if (dataInput[0].id) {
      //   result = await setDocumentFirebase('chat_config', dataInput[0].id, data, globalState.currentCompany);
      // } else {
      //   result = await addDocumentFirebase('chat_config', data, globalState.currentCompany);
      // }
      if (result) {
        toast({
          title: 'Deoapp.com',
          description: 'Chat Config Successfully Added',
          status: 'success',
          position: 'top-right',
        });

        fetchData();
      }
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };
  const handleAvatarEditClick = () => {
    setIsCardFileOpen((prevStatus) => !prevStatus);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setNewName(nameText);
  };

  const handleInputChange = (event) => {
    setNewName(event.target.value);
  };

  const handleBotToggle = () => {
    setBot((prevBot) => !prevBot);
  };

  const handleSentimentToggle = () => {
    setSentiment((prevSentiment) => !prevSentiment);
  };

  const handleSave = () => {
    setNameText(newName);
    setIsEditing(false);
    setPoint(point+1)
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)
    const uploadData = {
      file,
      folder: 'folder/v1/xx'
    }
    try {
      const result = await AwsUploadFile(uploadData);

      if (result.status === true) {
        const collectionName = 'chat_config';
        const docName = dataInput?.[0]?.id;
        const values = [result.data];
        const data = {
          image_bot: values,
        };

        await updateDocumentFirebase(collectionName, docName, data,);

        toast({
          status: 'success',
          duration: 9000,
          title: 'Upload your image product success.',
          isClosable: true,
        });
        fetchData()
      } else {
        toast({
          status: 'error',
          duration: 9000,
          title: 'Upload image failed',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      },);
    }
  }

  const convert = (time) => {
    const date = new Date(time * 1000);

    const year = date.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    const hours = String(date?.getHours()).padStart(2, '0');
    const minutes = String(date?.getMinutes()).padStart(2, '0');
    const seconds = String(date?.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate
  }

  useEffect(() => {
    fetchData();
  }, [globalState.currentProject])

  return (
    <Stack>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>Chat Configuration</Heading>
        <Spacer />
      </HStack>
      <Stack py={6}>
        <Box
          maxW={'full'}
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          // boxShadow={'2xl'}
          rounded={'md'}
          overflow={'hidden'}>
          <SimpleGrid columns={[1, 1, 2]} spacing={3}>
            <SimpleGrid columns={[1, 1, 2]} templateColumns={['100%', '100%', '20% 80%']} justify={'center'} mt={'1.5%'} spacing={10} direction={'row'} border="1px solid"
              borderColor="gray.200"
              borderRadius="lg"
              p={4}
              boxShadow="md" >
              <Stack direction={'column'}>
                <Avatar
                  size={'2xl'}
                  mx={['10%']}
                  src={dataInput?.[0]?.image_bot ? dataInput?.[0]?.image_bot :
                    'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ'
                  }
                  css={{
                    border: '2px solid white',
                  }}
                />
                <Button onClick={handleAvatarEditClick} size={'sm'} width={'100%'} colorScheme={'blue'}> <EditIcon /> Edit Icon</Button>
                {isCardFileOpen && (
                  <CardFile
                    onChange={onSelectFile}
                    fileName={fileName}
                    setFileName={setFileName}
                  />
                )}
              </Stack>
              <Stack w={'80%'} mt={'-1.5%'}>
                <Stack spacing={0} p={1} >
                  <Stack direction="row" alignItems="center">
                    {isEditing ? (
                      <Input
                        value={newName}
                        w={'35%'}
                        onChange={handleInputChange}
                        size="md"
                        autoFocus
                      />
                    ) : (
                      <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                        {point == 0 ?  dataInput?.[0]?.name_bot : newName }
                      </Heading>
                    )}
                    {isEditing ? (
                      <>
                        <IconButton
                          aria-label="Save"
                          icon={<CheckIcon />}
                          onClick={handleSave}
                          variant="ghost"
                          size="md"
                        />
                        <IconButton
                          aria-label="Cancel"
                          icon={<CloseIcon />}
                          onClick={handleCancel}
                          variant="ghost"
                          size="md"
                        />
                      </>
                    ) : (
                      <IconButton
                        aria-label="Edit"
                        icon={<EditIcon />}
                        onClick={handleEditClick}
                        variant="ghost"
                        size="md"
                      />
                    )}
                  </Stack>
                  <Stack w={'100%'}>
                    <Stack spacing={4} w={'100%'} mt={'5%'}>
                      <SimpleGrid columns={[3, 3, 3]} w={'100%'} spacingY={5} templateColumns={['15% 45% 40%', '15% 45% 40%', '10% 20% 70%']}>
                        <FaBuilding size={20} color="blue.500" />
                        <Text>Status:</Text>
                        <Input isDisabled defaultValue={dataInput?.[0]?.status} />
                        <FaClock size={20} color="blue.500" />
                        <Text>Approve At:</Text>
                        <Input isDisabled defaultValue={dataInput?.[0]?.lastUpdated ? convert(dataInput?.[0]?.lastUpdated?.seconds) : ''} />
                        <FaUser size={20} color="blue.500" />
                        <Text>Approve By:</Text>
                        <Input isDisabled />
                      </SimpleGrid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>


            </SimpleGrid>

            <SimpleGrid columns={[1, 1, 2]} templateColumns={['100%', '100%', '80% 20%']} justify={'center'} mt={'1.5%'} spacing={8} direction={'row'} border="1px solid"
              borderColor="gray.200"
              borderRadius="lg"
              p={4}
              boxShadow="md" >
              <Stack direction={'column'} width={['100%', '100%', '80%']}>
                <SimpleGrid columns={[3, 3, 3]} w={'100%'} spacingY={5} templateColumns={['15% 55% 30%', '15% 55% 30%', '10% 20% 70%']} mt={'2%'}>
                  <FaBuilding size={20} color="blue.500" />
                  <Text>Company Id:</Text>
                  <Input isDisabled defaultValue={dataInput?.[0]?.companyId} />
                  <MdOutlineTask size={20} color="blue.500" />
                  <Text>Project Id:</Text>
                  <Input isDisabled defaultValue={dataInput?.[0]?.projectId} />
                  <FaUser size={20} color="blue.500" />
                  <Text>Created By:</Text>
                  <Input isDisabled defaultValue={dataInput?.[0]?.createdBy} />
                  <FaClock size={20} color="blue.500" />
                  <Text>Created At:</Text>
                  <Input isDisabled defaultValue={convert(dataInput?.[0]?.createdAt?.seconds)} />
                </SimpleGrid>
              </Stack>
              <Stack width={['100%', '100%', '100%']} ml={[0, 0, -10]}>
                <Box border="1px solid" borderColor="gray.200" borderRadius="lg" p={4} boxShadow="md">
                  <Text fontSize="lg" fontWeight="bold" mb={2}>
                    Number Chat
                  </Text>
                  <Text>{dataInput?.[0]?.usage}</Text>
                </Box>
                <Box border="1px solid" borderColor="gray.200" borderRadius="lg" p={4} boxShadow="md">
                  <Stack direction="row" alignItems="center">
                    <FaRobot />
                    <Switch isChecked={bot} onChange={handleBotToggle} />
                    <Tooltip label="Set Your Bot Status"  >
                      <QuestionIcon />
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" alignItems="center" mt={'10%'}>
                    <MdOutlineSentimentSatisfied />
                    <Switch isChecked={sentiment} onChange={handleSentimentToggle} />
                    <Tooltip label='Set Your Sentiment Status'>
                      <QuestionIcon />
                    </Tooltip>
                  </Stack>
                </Box>
              </Stack>
            </SimpleGrid>
          </SimpleGrid>

          <Stack spacing={4} mt={'1%'}>
            <SimpleGrid columns={[1, 1, 2]} gap={4}>
              <Stack border="1px solid"
                borderColor="gray.200"
                borderRadius="lg" w={'100%'} boxShadow={'md'} p={5} minH="200px">
                <SimpleGrid columns={[3, 3, 3]} w={'100%'} spacingY={5} templateColumns={['15% 55% 30%', '15% 55% 30%', '10% 20% 70%']} >
                  <MdConfirmationNumber size={20} color="blue.500" />
                  <Text>Flowise Id:</Text>
                  <Input isDisabled defaultValue={dataInput?.[0]?.flowiseId} />
                  <MdOutlineKey size={20} color="blue.500" />
                  <Text>Maxchat Key:</Text>
                  <Input isDisabled defaultValue={dataInput?.[0]?.maxchat_key} />
                  <MdOutlinePhoneIphone size={20} color="blue.500" />
                  <Text>WhatsApp Number:</Text>
                  <Input isDisabled defaultValue={dataInput?.[0]?.phoneNumber} />
                </SimpleGrid>
              </Stack>
              <Stack spacing={4}>
                <Stack border="1px solid"
                  borderColor="gray.200"
                  borderRadius="lg" w={'100%'} boxShadow={'md'} p={5} minH="200px">
                  <SimpleGrid columns={[3, 3, 3]} w={'100%'} spacingY={5} templateColumns={['15% 55% 30%', '15% 55% 30%', '10% 20% 70%']} >
                    <MdOutlineWhatsapp size={20} color="blue.500" />
                    <Text>WhatsApp Name:</Text>
                    <Input isDisabled defaultValue={dataInput?.[0]?.name_whatsapp} />
                    <GiFlatPlatform size={20} color="blue.500" />
                    <Text>Platform Active:</Text>
                    <HStack spacing={3}>
                      <Checkbox isChecked={selectedPlatforms.includes('Website')} onChange={() => handleCheckboxChange('Website')}>Website</Checkbox>
                      <Checkbox isChecked={selectedPlatforms.includes('Maxchat')} onChange={() => handleCheckboxChange('Maxchat')}>Maxchat</Checkbox>
                    </HStack>
                  </SimpleGrid>
                  <Text>Format Chat</Text>
                  <Textarea
                    defaultValue={dataInput?.[0]?.format_chat || ''}
                    onChange={(e) => setDataInput((prevData) => ({ ...prevData, [0]: { ...prevData[0], format_chat: e.target.value } }))}
                    size='3xl'
                  />
                </Stack>
              </Stack>
            </SimpleGrid>
            <DynamicButton
              title={'Save Config'}
              action={'create'}
              variant={'solid'}
              onClick={chatConfig}
            // size={{ wi }}
            />
          </Stack>
        </Box >
      </Stack >


    </Stack >
  )
}

export default ChatConfigDetailPage