import React from 'react';
import PropTypes from 'prop-types';

export const Product7 = ({ data }) => {
  // const [hoveredIndex, setHoveredIndex] = useState();
  // const [filteredData, setFilteredData] = useState(data);

  // const handleFilterChange = (event, selectedFilter) => {
  //   const { checked } = event.target;

  //   if (checked) {
  //     const newData = data.filter((product) => product.tags && product.tags.includes(selectedFilter));
  //     setFilteredData(newData);
  //   } else {
  //     setFilteredData(data);
  //   }
  // };

  return (
    <div>
      {data?.length > 0 ? (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 w-full mt-12 dark:bg-gray-800'>
          {data?.map((product, index) => (
            <div
              className='relative overflow-hidden rounded-lg shadow-lg cursor-pointer m-4 dark:bg-gray-600 duration-300 ease-in-out transition-transform transform hover:-translate-y-2'
              key={index}>
              <img
                className='object-cover w-full h-80'
                src={product?.image1}
                alt='Flower and sky'
              />
              <span className='absolute top-0 left-0 flex flex-col items-center mt-3 ml-3 px-2 py-2 rounded-full z-10 bg-yellow-500 text-sm font-medium text-white select-none dark:bg-yellow-600 dark:text-gray-100'>
                <p className='text-sm text-gray-200'>{product?.price}</p>
              </span>

              <div className='items-center text-center m-3'>
                <p className='text-md font-semibold text-gray-700 dark:text-gray-200'>{product?.name}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 w-full mt-12 dark:bg-gray-800'>
          <div className='relative overflow-hidden rounded-lg shadow-lg cursor-pointer m-4 dark:bg-gray-600 duration-300 ease-in-out transition-transform transform hover:-translate-y-2'>
            <img
              className='object-cover w-full h-80'
              src='https://images.unsplash.com/photo-1556401615-c909c3d67480?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA3fHxsYW1wfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=80'
              alt='Flower and sky'
            />
            <span className='absolute top-0 left-0 flex flex-col items-center mt-3 ml-3 px-2 py-2 rounded-full z-10 bg-yellow-500 text-sm font-medium text-white select-none dark:bg-yellow-600 dark:text-gray-100'>
              <p className='text-sm text-gray-200'>Rp.500.000</p>
            </span>

            <div className='items-center text-center m-3'>
              <p className='text-md font-semibold text-gray-700 dark:text-gray-200'>White bulb</p>
            </div>
          </div>

          <div className='relative overflow-hidden rounded-lg shadow-lg cursor-pointer m-4 dark:bg-gray-600 duration-300 ease-in-out transition-transform transform hover:-translate-y-2'>
            <img
              className=' object-cover w-full h-80'
              src='https://images.unsplash.com/photo-1554104707-a76b270e4bbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2hhaXJ8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=80'
              alt='Flower and sky'
            />
            <span className='absolute top-0 left-0 flex flex-col items-center mt-3 ml-3 px-2 py-2 rounded-full z-10 bg-yellow-500 text-sm font-medium text-white select-none dark:bg-yellow-600 dark:text-gray-100'>
              <p className='text-sm text-gray-200'>Rp.300.000</p>
            </span>

            <div className='items-center text-center m-3'>
              <p className='text-md font-semibold text-gray-700 dark:text-gray-200'>White metal chair</p>
            </div>
          </div>

          <div className='relative overflow-hidden rounded-lg shadow-lg cursor-pointer m-4 dark:bg-gray-600 duration-300 ease-in-out transition-transform transform hover:-translate-y-2'>
            <img
              className=' object-cover w-full h-80'
              src='https://images.unsplash.com/photo-1539688831660-2ca435ee5a1a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjV8fGFwcGxlJTIwbGFwdG9wfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=80'
              alt='Flower and sky'
            />
            <span className='absolute top-0 left-0 flex flex-col items-center mt-3 ml-3 px-2 py-2 rounded-full z-10 bg-yellow-500 text-sm font-medium text-white select-none dark:bg-yellow-600 dark:text-gray-100'>
              <p className='text-sm text-gray-200'>Rp.150.000</p>
            </span>

            <div className='items-center text-center m-3'>
              <p className='text-md font-semibold text-gray-700 dark:text-gray-200'>Apple laptop</p>
            </div>
          </div>

          <div className='relative overflow-hidden rounded-lg shadow-lg cursor-pointer m-4 dark:bg-gray-600 duration-300 ease-in-out transition-transform transform hover:-translate-y-2'>
            <img
              className=' object-cover w-full h-80'
              src='https://images.unsplash.com/photo-1490135900376-2e86d918a23b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fG9mZmljZSUyMHRhYmxlfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=80'
              alt='Flower and sky'
            />
            <span className='absolute top-0 left-0 flex flex-col items-center mt-3 ml-3 px-2 py-2 rounded-full z-10 bg-yellow-500 text-sm font-medium text-white select-none dark:bg-yellow-600 dark:text-gray-100'>
              <p className='text-sm text-gray-200'>Rp.250.000</p>
            </span>

            <div className='items-center text-center m-3'>
              <p className='text-md font-semibold text-gray-700 dark:text-gray-200'>Office table</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Product7.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
  data: PropTypes.array.isRequired,
  filter: PropTypes.any,
};
