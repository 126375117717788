/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import L, { marker } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';
import pin from '../../../src/assets/icons/pin-location-gps-icon-png.webp';
import { Button, HStack, Text } from '@chakra-ui/react';
import { FcCollect } from 'react-icons/fc';

const MapComponent = ({ onLongitude, onLatitude, defaultLatitude, defaultLongitude }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [userMarker, setUserMarker] = useState(null);

  const mapRef = useRef(null);

  const showUserLocation = () => {
    const myIcon = L.icon({
      iconUrl: pin,
      iconSize: [50, 40],
    });
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const userLocation = [latitude, longitude];

        setLatitude(userLocation[0]);
        setLongitude(userLocation[1]);

        const marker = L.marker(userLocation, { icon: myIcon }).addTo(
          mapRef.current
        );
        setUserMarker(marker);

        mapRef.current.setView(userLocation, 15);
        onLongitude(userLocation[1]);
        onLatitude(userLocation[0]);
      });
    }
  };

  useEffect(() => {
    const map = L.map('map').setView([defaultLatitude||0,  defaultLongitude||0], 16);
    mapRef.current = map;

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    // L.marker([defaultLongitude||0, defaultLongitude||0]).addTo(map) //not working

    map.on('click', handleMapClick);
    showUserLocation();
    return () => {
      map.off('click', handleMapClick);
      map.remove();
    };
  }, [defaultLatitude, defaultLongitude]);

  const clearUserMarker = () => {
    if (userMarker) {
      marker.removeFrom(mapRef.current);
    }
  };

  const handleMapClick = (e) => {
    clearUserMarker();
    const { lat, lng } = e.latlng;
    setLatitude(lat);
    setLongitude(lng);
    const myIcon = L.icon({
      iconUrl: pin,
      iconSize: [50, 40],
    });
    const marker = L.marker([lat, lng], { icon: myIcon }).addTo(mapRef.current);
    setUserMarker(marker);
    onLongitude(lng);
    onLatitude(lat);
  };

  return (
    <div>
      <div id="map" style={{ height: '500px' }}></div>
      <HStack my={3} align={'end'}>
        <Button
          size={'sm'}
          shadow={'md'}
          w={'fit-content'}
          onClick={showUserLocation}
          title="Show My Location"
        >
          <FcCollect />
        </Button>
      </HStack>
      {latitude !== null && longitude !== null && (
        <HStack
          gap={2}
          w={'fit-content'}
          shadow={'md'}
          p={1}
          borderRadius={'md'}
          px={2}
        >
          <Text fontSize={'xs'}>Latitude: {latitude}</Text>
          <Text fontSize={'xs'}>Longitude: {longitude}</Text>
        </HStack>
      )}
    </div>
  );
};

export default MapComponent;
