import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { navbarData } from './defaultData';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { IoSparklesOutline } from 'react-icons/io5';
import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';
import useUserStore from '../../Hooks/Zustand/Store';
import { decryptToken } from '../../Utils/encrypToken';

const WebsiteConfigurationPage = () => {
  // const [data, setData] = useState()
  // const modalAddFeatures = useDisclosure()
  const modalMenu = useDisclosure();
  const modalBanner = useDisclosure();
  // const modalAddNavbarMenu = useDisclosure()
  const globalState = useUserStore();
  const [bannerLink, setBannerLink] = useState();
  // const [bannerList, setBannerList] = useState()
  // const [bannerInput, setBannerInput] = useState()
  const [selectedColor, setSelectedColor] = useState();
  const [progress, setProgress] = useState();
  const toast = useToast();
  const [input, setInput] = useState({
    name: '',
    title: '',
    favicon: '',
    menu: [],
    banner: '',
    color: '',
    section: [],
    logo: '',
  });
  const [newSectionInput, setNewSectionInput] = useState({
    name: '',
    data: [],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [navmenu, setNavmenu] = useState([])
  const [dataConfig, setDataConfig] = useState();
  const [sectionData, setSectionData] = useState([]);
  const modalSectionDetail = useDisclosure();
  const modalSection = useDisclosure();
  const [menuDatas, setMenuDatas] = useState({
    title: '',
    link: '',
  });
  // const [color, setColor] = useState()
  const [selectedNavMenu, setSelectedNavMenu] = useState([]);
  const [domains, setDomains] = useState([]);


  const dataNavmenu = navbarData;

  const handleGetConfig = async () => {
    const conditions = [{ field: 'projectId', operator: '==', value: globalState?.currentProject || '' }];

    try {
      const res = await getCollectionFirebase('websites', conditions);
      setDataConfig(res[0]);

      setInput({
        ...input,
        name: res[0]?.name || '',
        title: res[0]?.title || '',
        favicon: res[0]?.favicon || '',
        menu: res[0]?.menu || [],
        banner: res[0]?.banner || [],
        color: res[0]?.color || [],
        section: res[0]?.section || [],
        logo: res[0]?.logo || '',
      });
    } catch (error) {
      toast({
        title: 'error',
        description: error,
        duration: 3000,
        status: 'error',
      });
      throw Error(error);
    }
    try {
      //     { field: "nama_field_1", operator: "==", value: "nilai_1" }
      const res = await getCollectionFirebase(
        'domain_landing_page',
        [
          {
            field: 'companyId',
            operator: '==',
            value: globalState.currentCompany,
          },
          {
            field: 'projectId',
            operator: '==',
            value: globalState.currentProject,
          },
        ]
      );
      setDomains(res);
      console.log({res});
    } catch (error) {
      console.log(error.message);
    }
  };

  // const handleRemove

  const handleCheckedMenu = (selected) => {
    const menuObject = navbarData?.find((x) => x.name === selected);

    if (selectedNavMenu.includes(selected)) {
      setSelectedNavMenu((prevSelectedNavMenu) => prevSelectedNavMenu.filter((item) => item !== selected));

      if (input.menu) {
        const menuIndex = input.menu.findIndex((x) => x?.name === selected);
        if (menuIndex > -1) {
          setInput((prevInput) => ({
            ...prevInput,
            menu: [...input.menu.slice(0, menuIndex), ...input.menu.slice(menuIndex + 1)],
          }));
        }
      }
    } else {
      const findData = input?.menu?.some((x) => x?.name === selected);

      if (!findData && menuObject) {
        const updatedMenu = [...(input.menu || []), menuObject];
        setInput((prevInput) => ({
          ...prevInput,
          menu: updatedMenu,
        }));
      }
      setSelectedNavMenu([...selectedNavMenu, selected]);
    }
  };

  const handleAddFeature = () => {
    try {
      dataNavmenu.push(menuDatas);
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    } finally {
      setMenuDatas({ title: '', link: '' });
    }
  };

  const handleOpenModalColor = (value, index) => {
    setSelectedColor({ ...value, indexColor: index });
    onOpen();
  };

  const handleChangeColor = (value) => {
    const colorIndexToUpdate = selectedColor?.indexColor;

    const updatedInput = { ...input };

    if (updatedInput.color && Array.isArray(updatedInput.color) && updatedInput.color[colorIndexToUpdate]) {
      updatedInput.color[colorIndexToUpdate] = { ...updatedInput.color[colorIndexToUpdate], colorCode: value };
    }

    setInput(updatedInput);
    setSelectedColor({ ...selectedColor, colorCode: value });
  };

  const handleChangeSectionDetail = (value, field, index, type) => {
    if (type === 'edit') {
      const newData = [...sectionData.data];

      newData[index][field] = value;

      setSectionData({ ...sectionData, data: newData });
    } else {
      const newData = [...newSectionInput.data];

      newData[index][field] = value;

      setNewSectionInput({ ...newSectionInput, data: newData });
    }
  };

  const handleChangeAssets = async (e, type, index) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/website-assets`;

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file, parentPath, decryptResult, setProgress, progress);
      console.log(res, 'res dropbox');
      if (type === 'favicon') {
        setInput({ ...input, favicon: res?.link });
      } else if (type === 'logo') {
        setInput({ ...input, logo: res?.link });
      } else if (type === 'banner') {
        const dataBanner = { image: res?.link, link: bannerLink || '' };

        input.banner.push(dataBanner);

        modalBanner.onClose();
      } else {
        const newData = [...sectionData.data];

        newData[index].image = res?.link;

        setSectionData({ ...sectionData, data: newData });
      }
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleChangeSectionImage = async (e, type, index) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/website-assets`;

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file, parentPath, decryptResult, setProgress, progress);


      if (type === 'new') {
        const newData = [...newSectionInput.data];

        newData[index].image = res?.link;

        setNewSectionInput({ ...newSectionInput, data: newData });
      } else {
        const newData = [...sectionData.data];

        newData[index].image = res?.link;

        setSectionData({ ...sectionData, data: newData });
      }
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });    } finally {
      globalState?.setIsLoading(false);
    }
  };

  // Function to add a new data object to the 'data' array of each section
  const addNewData = (type) => {
    const newDataObject = {
      name: 'New Product',
      image: 'https://example.com/new-image.jpg',
      price: 0,
      discount: '0',
      link: 'https://example.com/new-link/',
    };

    if (type === 'edit') {
      setSectionData((prevData) => {
        return {
          ...prevData,
          data: [...prevData.data, newDataObject],
        };
      });
    } else {
      setNewSectionInput((prevData) => {
        return {
          ...prevData,
          data: [...prevData.data, newDataObject],
        };
      });
    }

  };

  const openSectionModal = (value) => {
    setSectionData(value);
    modalSectionDetail.onOpen();
  };

  const handleSaveSection = (type) => {
    if (type === 'edit') {
      console.log(input);
      // setInput((prevInput) => ({
      //   ...prevInput,
      //   section: prevInput?.section?.map((section) => {
      //     if (section?.name === sectionData?.name) {
      //       return sectionData;
      //     }  
      //     return section;
      //   }),
      // }));
      // modalSectionDetail.onClose();
    } else {
      const updatedSection = [...input?.section || [], newSectionInput];

      setInput((prevInput) => ({
        ...prevInput,
        section: updatedSection,
      }));

      setNewSectionInput({
        name: '',
        data: [],
      });
      modalSection.onClose();
    }
  };

  const handleSaveConfig = async () => {
    console.log(dataConfig,'dataConfig');
    console.log(input, 'input');
    // try {
    //   await updateDocumentFirebase('websites', dataConfig?.id, input);

    //   toast({
    //     status: 'success',
    //     title: 'Success saved changes',
    //     duration: 3000,
    //   });
    // } catch (error) {
    //   toast({
    //     title: 'Deoapp',
    //     description: error?.message,
    //     status: 'error',
    //     isClosable: true,
    //     position: 'top',
    //   });
    // }
  };

  const handleDeleteBanner = (value) => {
    const bannerIndex = input.banner?.findIndex((x) => x?.name === value?.name);

    if (bannerIndex > -1) {
      input?.banner?.splice(bannerIndex, 1);

      setInput((prevInput) => ({
        ...prevInput,
        banner: input.banner,
      }));
    }
  };

  useEffect(() => {
    handleGetConfig();

    return () => {};
  }, []);

  return (
    <Box>
      <Stack
        my={3}
        shadow='md'
        bg='white'
        padding={3}>
        <Text>Information for the head section</Text>
        <SimpleGrid
          columns={2}
          spacing={3}
          py={3}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Text
              fontSize={12}
              pb={3}>
              For the information about the site name
            </Text>
            <Input
              defaultValue={dataConfig?.name ? dataConfig?.name : ''}
              onChange={(e) => setInput({ ...input, name: e.target.value })}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Text
              fontSize={12}
              pb={3}>
              Title will be place above the browser, as the name of the tab along with the favicon
            </Text>
            <Input
              defaultValue={dataConfig?.title ? dataConfig?.title : ''}
              onChange={(e) => setInput({ ...input, title: e.target.value })}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Domain</FormLabel>
            <Text
              fontSize={12}
              pb={3}>
              Select the domain to access your storefront
            </Text>
            <Select onChange={(e) => setInput({ ...input, domain: e.target.value })}>
              {domains?.length > 0 && domains?.map((domain, i) => (
                <option key={i} value={domain?.domain}>{domain?.domain}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Favicon</FormLabel>
            <Text
              fontSize={12}
              pb={3}>
              Icon that will show up beside a title in tab browser
            </Text>
            <Box
              align={'center'}
              p={2}>
              {dataConfig?.favicon ? (
                <Box>
                  <Image
                    maxW={200}
                    py={3}
                    src={input?.favicon}
                  />
                  {/* <Text fontSize={12} color={'blue.300'} cursor={'pointer'} onClick={() => console.log('click favicon')}>Change Favicon</Text> */}
                  {/* <Spacer /> */}
                  <Input
                    type={'file'}
                    size={'sm'}
                    onChange={(e) => handleChangeAssets(e, 'favicon')}
                  />
                </Box>
              ) : (
                <Input
                  type='file'
                  size={'sm'}
                  onChange={(e) => handleChangeAssets(e, 'favicon')}
                />
              )}
            </Box>
          </FormControl>
          <FormControl>
            <FormLabel>Logo</FormLabel>
            <Text
              fontSize={12}
              pb={3}>
              For logo that will be placed in navigation bar or footer
            </Text>
            <Box
              align={'center'}
              p={2}>
              {dataConfig?.favicon ? (
                <Box>
                  <Image
                    maxW={200}
                    py={3}
                    src={input?.logo}
                  />
                  {/* <Text fontSize={12} color={'blue.300'} cursor={'pointer'} onClick={() => console.log('click favicon')}>Change Favicon</Text> */}
                  <Spacer />
                  <Input
                    type={'file'}
                    size={'sm'}
                    onChange={(e) => handleChangeAssets(e, 'logo')}
                  />
                </Box>
              ) : (
                <Input
                  type='file'
                  size={'sm'}
                  onChange={(e) => handleChangeAssets(e, 'logo')}
                />
              )}
            </Box>
          </FormControl>
        </SimpleGrid>
      </Stack>

      <Box
        my={3}
        shadow='md'
        bg='white'
        padding={3}
        align={'center'}>
        <Text fontWeight='bold'>Navbar Menu</Text>
        <HStack
          py={2}
          justify={'center'}>
          {dataNavmenu?.map((x, i) => {
            return (
              <Checkbox
                key={i}
                onChange={(e) => handleCheckedMenu(e.target.value)}
                value={x?.name}>
                {x?.name}
              </Checkbox>
            );
          })}
        </HStack>
      </Box>

      {/* <HStack spacing={3} w={'100%'}> */}
      <Box
        shadow='md'
        bg='white'
        padding={3}
        mb={3}>
        <Box align={'center'}>
          <Text fontWeight='bold'>Banner</Text>
          <Text fontSize={14}>Setup banner for your page, Adding more than 1 banner would make it a slider banner instead of static banner. Maximum image per banner section is 5, more than that can make user exhaustion</Text>
        </Box>

        {dataConfig?.banner?.length > 0 ? (
          <SimpleGrid
            columns={5}
            spacing={2}
            my={2}>
            {input?.banner?.map((x, i) => (
              <Stack
                p={3}
                key={i}>
                <Image src={x?.image} />
                <Box align={'center'}>
                  <Input
                    size={'sm'}
                    defaultValue={x?.link}
                    placeholder='Enter image url here'
                  />
                  <Text>or</Text>
                  <HStack justify={'center'}>
                    {/* <Button size={'xs'} colorScheme='blue' onClick={() => console.log('click change banner button')}>Change</Button> */}
                    <Button
                      size={'xs'}
                      colorScheme='blue'
                      onClick={() => handleDeleteBanner(x)}>
                      Delete
                    </Button>
                  </HStack>
                </Box>
              </Stack>
            ))}

            {input?.banner?.length < 6 && (
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                shadow='base'
                p='2'
                m='2'
                borderRadius='md'
                cursor='pointer'
                border={'1px'}
                borderColor={'gray.100'}>
                <Heading
                  color={'gray.500'}
                  onClick={modalBanner?.onOpen}
                  fontSize='xxx-large'
                  textAlign='center'
                  justifySelf='center'>
                  +
                </Heading>
              </Flex>
            )}
          </SimpleGrid>
        ) : (
          <></>
        )}
      </Box>

      <Stack
        my={3}
        border={'1px'}
        borderRadius={'sm'}
        borderColor={'gray.200'}
        shadow={'md'}
        bg={'white'}
        p={4}>
        <Text
          align={'center'}
          fontWeight={500}>
          Color View
        </Text>
        <HStack justify={'center'}>
          {input?.color?.length > 0 ? (
            <>
              {input?.color?.map((x, i) => (
                <Stack
                  key={i}
                  align={'center'}
                  shadow='md'
                  bg='white'
                  padding={2}>
                  <Text
                    align={'center'}
                    fontWeight={500}
                    textTransform={'capitalize'}>
                    {x?.name}
                  </Text>
                  <Box
                    borderWidth={1}
                    aspectRatio={1}
                    w={10}
                    h={10}
                    bg={x?.colorCode ? x?.colorCode : ''}></Box>

                  <Text align={'center'}>{x?.colorCode ? x?.colorCode : ''}</Text>
                  <Button
                    size='xs'
                    id={1}
                    onClick={() => handleOpenModalColor(x, i)}>
                    Change
                  </Button>
                </Stack>
              ))}
            </>
          ) : (
            <Text>No Color Set</Text>
          )}
        </HStack>
      </Stack>

      <Stack
        border={'1px'}
        borderRadius={'sm'}
        borderColor={'gray.200'}
        shadow={'md'}
        bg={'white'}
        p={3}
        align={'center'}>
        <Heading size='sm'>Section</Heading>
        <Text size='sm'>Setup sections for your pages, such as new products, favorites and more</Text>
        <Box>
          <SimpleGrid
            columns={5}
            spacing={2}
            align={'center'}>
            {input?.section?.map((x, i) => (
              <Flex
                key={i}
                onClick={() => openSectionModal(x)}
                alignItems={'center'}
                justifyContent={'center'}
                shadow='base'
                p='2'
                m='2'
                borderRadius='md'
                cursor='pointer'
                border={'1px'}
                borderColor={'gray.100'}
                flexDir={'column'}
                gap={3}>
                <Heading size={'md'}>Section {i + 1}</Heading>
                <Text fontSize={14}>{x?.name}</Text>
              </Flex>
            ))}

            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              shadow='base'
              p='2'
              m='2'
              borderRadius='md'
              cursor='pointer'
              border={'1px'}
              borderColor={'gray.100'}
              onClick={modalSection.onOpen}>
              <Stack
                color='gray'
                align={'center'}
                p={3}>
                <IoSparklesOutline size={25} />
                <Text
                  fontWeight={500}
                  fontSize={14}>
                  Add Section
                </Text>
              </Stack>
            </Flex>
          </SimpleGrid>
        </Box>
      </Stack>

      <Box align={'center'}>
        <Button
          onClick={() => handleSaveConfig()}
          colorScheme={'green'}
          my={3}>
          Save Configuration
        </Button>
      </Box>

      <Modal
        isOpen={modalMenu.isOpen}
        onClose={modalMenu.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Custom Menu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder={'Enter one new features ...'}
              //   onChange={handleNewFeature}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='green'
              onClick={handleAddFeature}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'sm'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedColor?.name} Color</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack align={'center'}>
              <Box>
                <HexColorPicker
                  // placeholder={color}
                  color={selectedColor?.colorCode}
                  onChange={handleChangeColor}
                />

                {/* <HexColorInput
                                    // placeholder={color}
                                    color={selectedColor?.colorCode}
                                    onChange={handleChangeColor}
                                /> */}

                <HexColorInput
                  style={{ background: '#ececec', width: '100px', padding: '6px', alignItems: 'center', justifyContent: 'center' }}
                  color={selectedColor?.colorCode}
                  onChange={handleChangeColor}
                />

                {/* </Box> */}
              </Box>
              <Box>
                <Box
                  aspectRatio={1}
                  w='10'
                  bg={selectedColor?.colorCode}></Box>
                <Text>{selectedColor?.colorCode}</Text>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='green'
              onClick={onClose}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalSectionDetail.isOpen}
        onClose={modalSectionDetail.onClose}
        size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Section Edit</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Section Title</FormLabel>
              <Input defaultValue={sectionData?.name} />
            </FormControl>
            <SimpleGrid columns={3}>
              {sectionData?.data?.map((x, i) => (
                <Stack
                  gap={2}
                  p={3}
                  key={i}>
                  <FormControl>
                    <FormLabel size={'sm'}>Product Name</FormLabel>
                    <Input
                      defaultValue={x?.name}
                      size='xs'
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'name', i, 'edit')}
                    />
                  </FormControl>
                  <Image
                    src={x?.image}
                    alt={x?.name}
                  />
                  <HStack w={'100%'}>
                    <Text
                      fontSize={10}
                      w={'30%'}>
                      Price
                    </Text>
                    <Input
                      w={'70%'}
                      size={'xs'}
                      defaultValue={x?.price}
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'price', i, 'edit')}
                    />
                  </HStack>
                  <HStack w={'100%'}>
                    <Text
                      fontSize={10}
                      w={'30%'}>
                      Discount
                    </Text>
                    <Input
                      w={'70%'}
                      size={'xs'}
                      defaultValue={x?.discount}
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'discount', i, 'edit')}
                    />
                  </HStack>
                  <HStack w={'100%'}>
                    <Text
                      fontSize={10}
                      w={'30%'}>
                      Link product
                    </Text>
                    <Input
                      w={'70%'}
                      size={'xs'}
                      defaultValue={x?.link}
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'link', i, 'edit')}
                    />
                  </HStack>
                  <Box align={'center'}>
                    {/* <Button size={'xs'} colorScheme='blue'>Change Image</Button> */}
                    <Input
                      type='file'
                      onChange={(e) => handleChangeSectionImage(e, 'edit', i)}
                    />
                  </Box>
                </Stack>
              ))}

              {sectionData?.data?.length < 7 && (
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  shadow='base'
                  p='2'
                  m='2'
                  borderRadius='md'
                  cursor='pointer'
                  border={'1px'}
                  borderColor={'gray.100'}>
                  <Heading
                    color={'gray.500'}
                    onClick={() => addNewData('edit')}
                    fontSize='xxx-large'
                    textAlign='center'
                    justifySelf='center'>
                    +
                  </Heading>
                </Flex>
              )}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => handleSaveSection('edit')}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalBanner?.isOpen}
        onClose={modalBanner?.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Banner</ModalHeader>
          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel>Banner Link</FormLabel>
                <Input onChange={(e) => setBannerLink(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Image</FormLabel>
                <Input
                  type='file'
                  onChange={(e) => handleChangeAssets(e, 'banner')}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>{/* <Button onClick={() => handleSaveBanner()}>Save</Button> */}</ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalSection?.isOpen}
        onClose={modalSection?.onClose}
        size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Section</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Section Title</FormLabel>
              <Input
                placeholder={'Enter your section name'}
                onChange={(e) => setNewSectionInput({ ...newSectionInput, name: e.target.value })}
              />
            </FormControl>
            <SimpleGrid columns={3}>
              {newSectionInput?.data?.map((x, i) => (
                <Stack
                  gap={2}
                  p={3}
                  key={i}>
                  <FormControl>
                    <FormLabel size={'sm'}>Product Name</FormLabel>
                    <Input
                      defaultValue={x?.name}
                      size='xs'
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'name', i, 'new')}
                    />
                  </FormControl>
                  <Image
                    src={x?.image}
                    alt={x?.name}
                  />
                  <HStack w={'100%'}>
                    <Text
                      fontSize={10}
                      w={'30%'}>
                      Price
                    </Text>
                    <Input
                      w={'70%'}
                      size={'xs'}
                      defaultValue={x?.price}
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'price', i, 'new')}
                    />
                  </HStack>
                  <HStack w={'100%'}>
                    <Text
                      fontSize={10}
                      w={'30%'}>
                      Discount
                    </Text>
                    <Input
                      w={'70%'}
                      size={'xs'}
                      defaultValue={x?.discount}
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'discount', i, 'new')}
                    />
                  </HStack>
                  <HStack w={'100%'}>
                    <Text
                      fontSize={10}
                      w={'30%'}>
                      Link product
                    </Text>
                    <Input
                      w={'70%'}
                      size={'xs'}
                      defaultValue={x?.link}
                      onChange={(e) => handleChangeSectionDetail(e.target.value, 'link', i, 'new')}
                    />
                  </HStack>
                  <Box align={'center'}>
                    {/* <Button size={'xs'} colorScheme='blue'>Change Image</Button> */}
                    <Input
                      type='file'
                      onChange={(e) => handleChangeSectionImage(e, 'new', i)}
                    />
                  </Box>
                </Stack>
              ))}

              {newSectionInput?.data?.length < 7 && (
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  shadow='base'
                  p='2'
                  m='2'
                  borderRadius='md'
                  cursor='pointer'
                  border={'1px'}
                  borderColor={'gray.100'}>
                  <Heading
                    color={'gray.500'}
                    onClick={() => addNewData('new')}
                    fontSize='xxx-large'
                    textAlign='center'
                    justifySelf='center'>
                    +
                  </Heading>
                </Flex>
              )}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => handleSaveSection('new')}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WebsiteConfigurationPage;
