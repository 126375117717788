import { Box, FormLabel, Heading, Input, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import PropTypes from 'prop-types'

const SettingComponent = ({ websiteStore }) => {
  const handleChangeSettingInput = (value,type) => {
    if (type === 'facebook') {
      websiteStore?.setFacebookPixel(value)
    } else if (type === 'tiktok') {
      websiteStore?.setTiktokPixel(value)
    } else {
      websiteStore?.setGtm(value)
    }
  }

  return (
    <Box p='1' gap='1' mr='2'>
      <Text fontWeight='semibold' align={'center'}>Marketing Tools</Text>
      <Box my={2}>
        <Stack spacing={0}>
          <FormLabel>Facebook Pixel</FormLabel>
          <Input defaultValue={websiteStore?.facebookPixel} size='sm' type='text' placeholder='Enter Facebook Pixel' onChange={(e) => handleChangeSettingInput(e.target.value,'facebook')} />
        </Stack>
        <Stack spacing={0} py={2}>
          <FormLabel>Tiktok Pixel</FormLabel>
          <Input defaultValue={websiteStore?.tiktokPixel} size='sm' type='text' placeholder='Enter Tiktok Pixel' onChange={(e) => handleChangeSettingInput(e.target.value,'tiktok')} />
        </Stack>
        <Stack spacing={0}>
          <FormLabel>Google Tag Manager</FormLabel>
          <Input defaultValue={websiteStore?.gtm} size='sm' type='text' placeholder=' Enter Google Tag Manager' onChange={(e) => handleChangeSettingInput(e.target.value,'gtm')} />
        </Stack>
      </Box>
    </Box>
  )
}

export default SettingComponent


SettingComponent.propTypes = {
  websiteStore: PropTypes.object
}