import React from 'react';
import LayoutChatAdmin from '../Layouts/LayoutChatAdmin';
import ChatHomePage from '../Pages/Messanger/ChatHomePage';
import ChatConversationAdmin from '../Pages/Chat/ChatCoversationAdmin';
import ChatConfigPage from '../Pages/Chat/Components/ChatConfigPage';
import ChatConfigViewPage from '../Pages/Chat/ChatConfigViewPage';

const ChatRouter = [
  {
    path: '/chats',
    element: <ChatHomePage />,
  },

  {
    path: '/chat',
    element: <LayoutChatAdmin />,
  },

  {
    path: '/chat/:id',
    element: ( 
      <LayoutChatAdmin>
        <ChatConversationAdmin />
      </LayoutChatAdmin>
    ),
  },

  {
    path: '/chat-config',
    element: <ChatConfigPage />,
  },

  {
    path: '/chat-config/:id',
    element: <ChatConfigViewPage />,
  },


];

export default ChatRouter;
