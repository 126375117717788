import { Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase } from '../../Api/firebaseApi';

function CategoryViewPage() {

  const [categoryList, setCategoryList] = useState([])

  const getData = async () => {

    const conditions = [
      {
        field: 'categoriesId',
        operator: '==',
        value: 'M03D0fyRASvoxe4k9AQY',
      },
      {
        field: 'type',
        operator: '==',
        value: 'physical',
      },
      {
        field: 'projectId',
        operator: '==',
        value: '8NCG4Qw0xVbNR6JCcJw1',
      },
      {
        field: 'module',
        operator: 'array-contains',
        value: 'commerce',
      },

    ];

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 9999;


    try {
      const res = await getCollectionFirebase(
        'product_categories',
        conditions,
        sortBy,
        limitValue,
      );
      setCategoryList(res)
    } catch (error) {
      console.log(error, 'ini error')
    }
  }

  useEffect(() => {
    getData()

    return () => {
    }
  }, [])


  return (
    <Stack spacing={5}>
      {categoryList?.length > 0 && (
        categoryList?.map((x, index) => {
          return(<Text key={index} fontWeight={500}>{x.title}</Text>)
        })
      )}
    </Stack>
  )
}

export default CategoryViewPage