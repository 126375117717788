/* eslint-disable react/react-in-jsx-scope */
import CheckoutViewPage from '../Pages/View/CheckoutViewPage'
import ViewCartPage from '../Pages/View/ViewCartPage'
import ViewPage from '../Pages/View/ViewPage'
import ViewPageDetail from '../Pages/View/ViewPageDetail'

const ViewRouter = [
  {
    path: '/view',
    element: <ViewPage />,
  },
  {
    path: '/view/cart',
    element: <ViewCartPage />,
  },
  {
    path: '/view/:id/:slug',
    element: <ViewPageDetail />,
  },
  {
    path: '/checkout',
    element: <CheckoutViewPage />,
  },
  
]

export default ViewRouter