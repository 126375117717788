import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Header1 = ({ bgColor, navmenu, textColor, width }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentParent, setCurrentParent] = useState(null);
  const handleSubCategoryClick = (e) => {
    e.preventDefault();

  };

  return (
    <header
      className='text-gray-400 bg-gray-900 body-font'
      style={{ backgroundColor: bgColor || '#111827' }}>
      <div className='container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center'>
        {/* <a className='flex title-font font-medium items-center text-white mb-4 md:mb-0'> */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          className='w-10 h-10 text-white p-2 bg-indigo-500 rounded-full'
          viewBox='0 0 24 24'>
          <path d='M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5'></path>
        </svg>
        <span className='ml-3 text-xl'>Tailblocks</span>
        {/* </a> */}

        <nav className={width === '400px' ? 'hidden' : 'md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center'}>
          {navmenu && navmenu?.length > 0 ? (
            <>
              {navmenu.map((parentItem, parentIndex) => (
                <div
                  key={parentIndex}
                  onMouseEnter={() => {
                    setCurrentParent(parentIndex);
                    setShowDropdown(true);
                  }}
                  onMouseLeave={() => setShowDropdown(false)}>
                  <a
                    href={parentItem.link}
                    className='text-sm font-semibold leading-6 text-gray-900'
                    style={{ color: textColor || '#111827' }}>
                    {parentItem.name}
                  </a>
                  {showDropdown && currentParent === parentIndex && parentItem.subCategories && (
                    <div className='absolute bg-white shadow-lg rounded-lg w-48 z-10'>
                      {parentItem.subCategories.map((subItem, subIndex) => (
                        <a
                          key={subIndex}
                          onClick={(e) => handleSubCategoryClick(e, subItem.name)}
                          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                          {subItem.name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              <a
                href='/'
                className='mr-5 hover:text-white'>
                Home
              </a>
              <a
                href='/product'
                className='mr-5 hover:text-white'>
                Products
              </a>
              <a
                href='/product'
                className='mr-5 hover:text-white'>
                Third Link
              </a>
              <a
                href='/product'
                className='mr-5 hover:text-white'>
                Contact
              </a>
            </>
          )}
        </nav>
        <button
          className='inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0'
          style={{ backgroundColor: bgColor || '#111827' }}>
          Button
          <svg
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            className='w-4 h-4 ml-1'
            viewBox='0 0 24 24'>
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header1;

Header1.propTypes = {
  bgColor: PropTypes.string,
  navmenu: PropTypes.array,
  textColor: PropTypes.string,
  width: PropTypes.string,
};
