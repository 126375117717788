import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Category2 = ({ modeBG, data, header, content }) => {
  // const [data, setData] = useState()

  // const getData = async () => {
  //   try {
  //     const citiesRef = collection(db, 'categories');
  //     const q = query(citiesRef,
  //       where('companyId', '==', '8NCG4Qw0xVbNR6JCcJw1'),
  //       // where('isParent', '==', true),
  //     );
  //     const querySnapshot = await getDocs(q);

  //     const newData = []

  //     querySnapshot.forEach((doc) => {
  //       const editData = { id: doc.id, ...doc.data() }
  //       editData.createdAt = editData.createdAt.seconds
  //       editData.lastUpdated = editData.lastUpdated.seconds
  //       newData.push(editData)
  //     });

  //     setData(newData)
  //     // console.log(newData)

  //   } catch (error) {
  //     // console.log(error.message)
  //   }
  // };

  useEffect(() => {
    // getData()

    return () => {

    }
  }, [])

  return (
    <div className={
      modeBG
        ? 'mx-auto max-w-xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'
        : 'mx-auto max-w-xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 text-gray-900'}
    >
      <h2 className='text-2xl font-bold tracking-tight '>{header || 'Shop by Collection'}</h2>
      <p className='mt-4 text-base text-gray-500' >{content ||'Each season, we collaborate with world-className designers to create a collection inspired by the natural world.'}</p>

      {data && data?.length > 0 ? (
        <div className='mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0'>
          {data?.map((x, i) =>
            <a key={i} href='#' className='group block'>
              <div aria-hidden='true' className='aspect-h-2 aspect-w-3 overflow-hidden rounded-lg lg:aspect-h-6 lg:aspect-w-5 group-hover:opacity-75'>
                <img src={x?.image} alt='Brown leather key ring with brass metal loops and rivets on wood table.' className='h-full w-full object-cover object-center' />
              </div>
              <h3 className='mt-4 text-base font-semibold text-gray-900 text-center'>{x.name}</h3>
              {/* <p className='mt-2 text-sm text-gray-500'>Keep your phone, keys, and wallet together, so you can lose everything at once.</p> */}
            </a>
          )}
        </div>
      ):(
        <div className='flex justify-center p-10'>
          No Category Data
        </div>
      )}
    </div>
  )
}

export default Category2

Category2.propTypes = {
  modeBG: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object)
}
