/* eslint-disable no-undef */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { decryptToken } from '../../../Utils/encrypToken';
import _axios from '../../../Api/AxiosBarrier';
import ChatPublicComponent from './ChatPublicComponent';
import ChatAuth from './ChatAuth';
import ChatNoAccess from './ChatNoAccess';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';

function ChatPublic() {
  const toast = useToast();
  const [idMessage, setIdMessage] = useState('');

  const params = useParams();

  const userId = params?.userId;
  const projectId = params?.projectId;
  const companyId = params?.companyId;
  const chatConfigId = params?.chatConfigId;

  const [accessTokenDb, setAccessTokenDb] = useState('');
  const [chatConfig, setChatConfig] = useState('');


  const getAccessToken = async () => {
    try {
      const result = await _axios.get('/dropbox-getTokenFirebase');
      const { access_token } = result?.data?.token;
      if (access_token) {
        const resultData = decryptToken(access_token);
        setAccessTokenDb(resultData);
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getDataUser = async() => {
    try {
      const res = await getSingleDocumentFirebase('users', userId)
      return res
    } catch (error) {
      return null
    }
  }

  const handleNewChat = async () => {
    if(userId === null || userId === undefined) return
    const dataUser = await getDataUser()
    if(dataUser === null) return 

    const dataSend = {
      collection: 'chat_customer',
      doc: `${userId}-${projectId}`,
      data: {
        companyId: companyId,
        projectId: projectId,
        karyawanAi: '',
        createdAt: moment(new Date()).unix(),
        user: userId,
        platform: 'website',
        name: dataUser?.name || 'anonymous',
        email: dataUser?.email,
        phoneNumber: dataUser?.phoneNumber ? `62${dataUser?.phoneNumber}` : '',
        country: 'indonesia'
      },
    };

    try {
      const res = await _axios.post('chat-setDocument', dataSend);
      if (res?.status === true) {
        setIdMessage(dataSend?.doc);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getDataChatConfig = async () => {
    try {
      const resConfig = await _axios.post('/chat-getDocument', {
        collection: 'chat_config',
        doc: chatConfigId,
      });
      if (resConfig.status) {
        setChatConfig(resConfig.data);
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getAccessToken();
    return () => { };
  }, []);

  useEffect(() => {
    getDataChatConfig();
    handleNewChat();

    return () => { };
  }, [userId, projectId, companyId]);

  if (companyId === undefined || companyId === '' || companyId === 'undefined' || projectId === undefined || projectId === '' || projectId === 'undefined' || chatConfigId === undefined || chatConfigId === '' || chatConfigId === 'undefined'  ) {
    return (
      <ChatNoAccess phoneWA={chatConfig?.phoneNumber || '6287887147471'} />
    )
  }


  if (userId === undefined || userId === '') {
    return (
      <ChatAuth phoneWA={chatConfig?.phoneNumber || ''} />
    )
  }

 

  return (
    <>
      <ChatPublicComponent
        chatConfig={chatConfig}
        companyId={companyId}
        projectId={projectId}
        userId={userId || ''}
        idMessage={idMessage}
        accessTokenDb={accessTokenDb}
        flowiseHost={chatConfig?.flowiseId}
        flowiseKey={process.env.REACT_APP_FLOWISE_KEY}
        openAiKey={process.env.REACT_APP_OPENAI_KEY}
        secretKey={process.env.REACT_APP_SECRET_KEY}
      />
    </>
  );
}

export default ChatPublic;
