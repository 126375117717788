/* eslint-disable react/prop-types */
import {
  Icon,
  HStack,
  IconButton,
  Input,
  Stack,
  Spacer,
  Button,
  Center,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  useToast,
  Text,
  Skeleton,
  Tooltip,
  useColorMode} from '@chakra-ui/react';
import {
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import {
  BsFilter,
  BsSnapchat,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { clientTypessense } from '../Api/Typesense';
import BackButtons from '../Components/Buttons/BackButtons';
import ChatCard from '../Components/Card/ChatCard';
import { db } from '../Config/firebase';
import useUserStore from '../Hooks/Zustand/Store';
import ChatSidebarComponent from '../Pages/Chat/ChatSidebarComponent';
import { countDocumentsFirebase, getCollectionFirebaseV4 } from '../Api/firebaseApi';
import { LuPin } from 'react-icons/lu';
import { MdOutlineChat, MdOutlineMarkChatRead, MdOutlineMarkChatUnread } from 'react-icons/md';

function LayoutChatAdmin({ children }) {
  const globalState = useUserStore();
  const [loading, setLoading] = useState(false);
  const [customerChat, setCustomerChat] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchData, setSearchData] = useState([]);

  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const toast = useToast();

  const { colorMode } = useColorMode();

  const [allChatCount, setAllChatCount] = useState(0);
  const [unreadChatCount, setUnreadChatCount] = useState(0);
  const [negativeChatCount, setNegativeChatCount] = useState(0);
  const [pinChatCount, setPinChatCount] = useState(0);
  const [resolvedChatCount, setResolvedChatCount] = useState(0);


  const navigate = useNavigate();


  const [tabBar, setTabBar] = useState('all');

  useEffect(() => {
    setLoading(true);

    let q;
    let countQuery;

    if (tabBar === 'all') {
      // Query for all chats
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage)
      );
      countQuery = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject)
      );
    } else if (tabBar === 'unread') {
      // Query for unread chats
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('status', '==', 'unread'),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage)
      );
      countQuery = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('status', '==', 'unread')
      );
    } else if (tabBar === 'negative') {
      // Query for negative sentiment chats
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('sentiment', '==', 'negative'),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage)
      );
      countQuery = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('sentiment', '==', 'negative')
      );
    } else if (tabBar === 'pin') {
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('isPin', '==', true),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage)
      );
      countQuery = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('isPin', '==', true)
      );
    } else if (tabBar === 'resolved') {
      q = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('isResolved', '==', true),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage)
      );
      countQuery = query(
        collection(db, 'chat_customer'),
        where('projectId', '==', globalState.currentProject),
        where('isResolved', '==', true)
      );
    }


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chats = [];
      querySnapshot.forEach((doc) => {
        let newData = { id: doc.id, ...doc.data() };
        newData = { ...newData };
        chats.push(newData);
      });
      setCustomerChat(chats);

      // Ambil jumlah total dokumen untuk pagination
      getCountFromServer(countQuery)
        .then((snapshot) => {
          const assessmentLength = snapshot.data().count;
          setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
        })
        .catch((error) => {
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        });

      setLoading(false);
    });

    setCurrentPage(1);

    return () => {
      unsubscribe();
      setCustomerChat([]);
    };
  }, [globalState?.currentProject, tabBar]);

  const handleLoadMore = () => {
    const conditions = [];

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = itemsPerPage;
    let startAfter = null;

    const collectionName = 'chat_customer';

    if (customerChat.length > 0) {
      // If data exist, set startAfter to the last data
      startAfter = customerChat[customerChat.length - 1].createdAt;
    }

    if (tabBar === 'all') {
      // Query for all chats
      conditions.push({
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      });
    } else if (tabBar === 'unread') {
      // Query for unread chats
      conditions.push({
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      });
      conditions.push({ field: 'status', operator: '==', value: 'unread' });
    } else if (tabBar === 'negative') {
      // Query for negative sentiment chats
      conditions.push({
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      });
      conditions.push({
        field: 'sentiment',
        operator: '==',
        value: 'negative',
      });
    } else if (tabBar === 'pin') {
      // Query for negative sentiment chats
      conditions.push({
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      });
      conditions.push({
        field: 'isPin',
        operator: '==',
        value: true,
      });
    }

    else if (tabBar === 'resolved') {
      // Query for negative sentiment chats
      conditions.push({
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      });
      conditions.push({
        field: 'isResolved',
        operator: '==',
        value: true,
      });
    }

    getCollectionFirebaseV4(
      collectionName,
      { conditions },
      { sortBy },
      { limitValue },
      { startAfterData: startAfter }
    )
      .then((x) => {
        // If any data is retrieved, delete the last data that is equal to the first data in x
        if (
          customerChat.length > 0 &&
          x.length > 0 &&
          customerChat[customerChat.length - 1].id === x[0].id
        ) {
          x.shift();
        }
        const updateData = [...customerChat, ...x];
        setCustomerChat(updateData);
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
    setCurrentPage((prev) => prev + 1);
  };



  const getDataChatCount = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];

    try {
      // Mengambil jumlah chat untuk masing-masing kondisi
      const allCount = await countDocumentsFirebase('chat_customer', conditions);
      setAllChatCount(allCount);

      const unreadConditions = [...conditions, { field: 'status', operator: '==', value: 'unread' }];
      const unreadCount = await countDocumentsFirebase('chat_customer', unreadConditions);
      setUnreadChatCount(unreadCount);

      const negativeConditions = [...conditions, { field: 'sentiment', operator: '==', value: 'negative' }];
      const negativeCount = await countDocumentsFirebase('chat_customer', negativeConditions);
      setNegativeChatCount(negativeCount);

      const pinConditions = [...conditions, { field: 'isPin', operator: '==', value: true }];
      const pinCount = await countDocumentsFirebase('chat_customer', pinConditions);
      setPinChatCount(pinCount);

      const resolvedConditions = [...conditions, { field: 'isResolved', operator: '==', value: true }];
      const resolvedCount = await countDocumentsFirebase('chat_customer', resolvedConditions);
      setResolvedChatCount(resolvedCount);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    getDataChatCount()

    return () => {
    }
  }, [globalState?.currentProject])

  const handleSearch = (q) => {
    setSearchInput(q);

    const searchParameters = {
      q: q,
      query_by: ['message', 'name', 'email'],
      filter_by: `projectId:${globalState?.currentProject}`,
      sort_by: '_text_match:desc',
    };
    clientTypessense
      .collections('chat_customer')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const hits = x.hits.map((x) => x.document);
        setSearchData(hits);
      })
      .catch((err) => {
        toast({
          title: 'Deoapp.com',
          duration: 3000,
          description: err.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };



  const handleNavigate = (data) => {
    navigate(`/chat/${data.id}`);
  };

  return (
    <Grid templateColumns={{ base: '1fr', md: '1fr 2fr 1fr' }}>
      <Stack
        spacing="4"
        height="100vh"
        overflow="hidden"
        shadow={'md'}
        pt="5"
        bgColor={colorMode === 'light' ? 'blue.500' : ''}
        borderTopLeftRadius={'md'}
      >
        <Stack >
          <HStack px="5" color={colorMode === 'light' ? 'white' : ''}>
            <BackButtons />
            <Spacer />
            <Text fontSize="md" fontWeight="bold">
              Conversation
            </Text>
          </HStack>
          <HStack px={5}>
            <Input
              onChange={(e) => handleSearch(e.target.value)}
              type="text"
              size="sm"
              borderRadius={'md'}
              bgColor={'whitesmoke'}
              placeholder="Search User / Message"
            />
        
          </HStack>
        </Stack>

        <Stack bgColor={colorMode === 'light' ? 'white' : ''} flex="1" pt={5} overflowY="auto">
          <Stack >
            <HStack
              px={2}
              color="fg.muted"
              cursor={'pointer'}
              alignItems="center"
              spacing={10}
              justifyContent={'center'}
            >
              <HStack
                borderBottomWidth={2}
                py={2}
                borderBottomColor={tabBar === 'all' && 'blue.300'}
                color={tabBar === 'all' && 'blue.300'}
                onClick={() => setTabBar('all')}
              >
                <Tooltip label='Convertion All'>
                  <Stack position={'relative'}>
                    <Icon as={MdOutlineChat} fontSize={20} />
                    <Stack position={'absolute'} bottom={5} left={3} bgColor={'blue.500'} borderRadius={'full'} p={1} minW={19} h={19} alignItems={'center'} justifyContent={'center'}>
                      <Text fontSize={'xs'} fontWeight={'bold'} color={'white'}>{allChatCount || 0}</Text>
                    </Stack>
                  </Stack>
                </Tooltip>

              </HStack>

              <HStack
                spacing={1}
                borderBottomWidth={2}
                py={2}
                borderBottomColor={tabBar === 'unread' && 'blue.300'}
                color={tabBar === 'unread' && 'blue.300'}
                cursor={'pointer'}
                onClick={() => setTabBar('unread')}
              >
                <Tooltip label='Unread'>
                  <Stack position={'relative'}>
                    <Icon as={MdOutlineMarkChatUnread} fontSize={20} />
                    <Stack position={'absolute'} bottom={5} left={3} bgColor={'blue.500'} borderRadius={'full'} p={1} minW={19} h={19} alignItems={'center'} justifyContent={'center'}>
                      <Text fontSize={'xs'} fontWeight={'bold'} color={'white'}>{unreadChatCount || 0}</Text>
                    </Stack>
                  </Stack>
                </Tooltip>

              </HStack>

              <HStack
                borderBottomWidth={2}
                py={2}
                borderBottomColor={tabBar === 'negative' && 'blue.300'}
                color={tabBar === 'negative' && 'blue.300'}
                spacing={1}
                cursor={'pointer'}
                onClick={() => setTabBar('negative')}
              >
                <Tooltip label='Convertion Negative'>
                  <Stack position={'relative'}>
                    <Icon as={BsSnapchat} fontSize={20} />
                    <Stack position={'absolute'} bottom={5} left={3} bgColor={'blue.500'} borderRadius={'full'} p={1} minW={19} h={19} alignItems={'center'} justifyContent={'center'}>
                      <Text fontSize={'xs'} fontWeight={'bold'} color={'white'}>{negativeChatCount || 0}</Text>
                    </Stack>
                  </Stack>
                </Tooltip>
              </HStack>

              <HStack
                borderBottomWidth={2}
                py={2}
                borderBottomColor={tabBar === 'pin' && 'blue.300'}
                color={tabBar === 'pin' && 'blue.300'}
                spacing={1}
                cursor={'pointer'}
                onClick={() => setTabBar('pin')}
              >
                <Tooltip label='Convertion Pinned'>
                  <Stack position={'relative'}>
                    <Icon as={LuPin} fontSize={20} />
                    <Stack position={'absolute'} bottom={5} left={3} bgColor={'blue.500'} borderRadius={'full'} p={1} minW={19} h={19} alignItems={'center'} justifyContent={'center'}>
                      <Text fontSize={'xs'} fontWeight={'bold'} color={'white'}>{pinChatCount || 0}</Text>
                    </Stack>
                  </Stack>
                </Tooltip>
              </HStack>

              <HStack
                borderBottomWidth={2}
                py={2}
                borderBottomColor={tabBar === 'resolved' && 'blue.300'}
                color={tabBar === 'resolved' && 'blue.300'}
                spacing={1}
                cursor={'pointer'}
                onClick={() => setTabBar('resolved')}
              >
                <Tooltip label='Convertion Resolved'>
                  <Stack position={'relative'}>
                    <Icon as={MdOutlineMarkChatRead} fontSize={20} />
                    <Stack position={'absolute'} bottom={5} left={3} bgColor={'blue.500'}  borderRadius={'full'} p={1} minW={19} h={19} alignItems={'center'} justifyContent={'center'}>
                      <Text fontSize={'xs'} fontWeight={'bold'} color={'white'}>{resolvedChatCount || 0}</Text>
                    </Stack>
                  </Stack>
                </Tooltip>
              </HStack>
            </HStack>
          </Stack>

          <Stack spacing="4" px="3">
            <Stack>
              {loading ? <Skeleton height="50px" width="full" /> : <></>}

              {searchInput === ''
                ? customerChat?.length > 0 &&
                customerChat?.map((x, i) => (
                  <Stack onClick={() => handleNavigate(x)} key={i}>
                    <ChatCard
                      data={x}
                      key={i}
                    />
                  </Stack>
                ))
                : searchData?.length > 0 &&
                searchData?.map((x, i) => (
                  <Stack
                    onClick={() => handleNavigate(x)}
                    key={i}
                  >
                    <ChatCard
                      data={x}
                      key={i}
                      searchQuery={searchInput}
                    />
                  </Stack>
                ))}

              {currentPage < totalPages ? (
                <Center>
                  <Button
                    colorScheme="green"
                    mt="2"
                    size="sm"
                    onClick={handleLoadMore}
                  >
                    Load more
                  </Button>
                </Center>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack overflow="hidden">{children}</Stack>
      <Stack overflow="hidden" flex="1">
        <ChatSidebarComponent  />
      </Stack>
    </Grid >
  );
}

export default LayoutChatAdmin;
