import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, Textarea } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import useWebsiteStore from '../../../../Hooks/Zustand/WebsiteStore';

const ModalAddCart = ({ modal }) => {
  const websiteStore = useWebsiteStore();
  const currentCart = websiteStore?.pageCart;

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Checkout Configuration</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box my={3}>
            <Stack>
              <Text>Header</Text>
              <Input
                size={'sm'}
                defaultValue={currentCart?.header}
                placeholder='Enter your page header'
                onChange={(e) => websiteStore?.setPageCart({ ...currentCart, header: e.target.value })}
              />
            </Stack>
            <Stack my={2}>
              <Text>Description Content</Text>
              <Textarea
                size={'sm'}
                defaultValue={currentCart?.content}
                onChange={(e) => websiteStore?.setPageCart({ ...currentCart, content: e.target.value })}
                placeholder='Enter your cart page content'
              />
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              size={'sm'}
              colorScheme='red'
              onClick={() => modal.onClose()}>
              Cancel
            </Button>
            <Button
              size={'sm'}
              colorScheme='green'
              onClick={() => modal.onClose()}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddCart;

ModalAddCart.propTypes = {
  modal: PropTypes.func,
};
