import React from 'react';
import HomeComingSoonPage from '../Pages/Home/HomeComingSoonPage';
import DashboardPage from '../Pages/Dashboard/DashboardPage';

const HomeRouter = [
  {
    path: '/',
    element: <DashboardPage />,
  },
  {
    path: '/homePage',
    element: <HomeComingSoonPage />,
  },


];

export default HomeRouter;
