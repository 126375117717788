/* eslint-disable react/prop-types */
import { Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Stack } from '@chakra-ui/react'
import React from 'react'
import { RiArrowDropDownLine } from 'react-icons/ri'

function SettingMessage({ data, setChatReply }) {

  const handleSetReply = () => {
    setChatReply(data)
  }
  return (
    <Stack>
      <Popover size={'sm'}>
        <PopoverTrigger>
          <Button size={'xs'} variant={'ghost'}>
            <RiArrowDropDownLine color='white' fontSize={21} />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Setting</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Button w={'full'} size={'sm'} colorScheme='blue' onClick={() => handleSetReply()}>Reply Message</Button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Stack>
  )
}

export default SettingMessage