/* eslint-disable react/prop-types */
import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { ImAngry, ImHappy } from 'react-icons/im';

import { FcFile } from 'react-icons/fc'
import { getUserByUid } from '../../../Utils/getUser'
import SettingMessage from './SettingMessage'
import { FaRegFaceMeh } from 'react-icons/fa6'
import moment from 'moment'
import ChatCardReplyDetail from './ChatCardReplyDetail'

function ChatCard({ x, globalState, setChatReply, chatReply, highlightedMessages, i }) {
  return (
    <HStack gap="5">
      {x.user === globalState?.uid ? <Spacer /> : <></>}
      <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
        {x?.sentiment &&
          <Stack
            alignItems={'flex-start'}
            justifyContent="flex-start"
            p={1}
          >
            {(x.sentiment === 'positive' ? (
              <ImHappy color="green" size={20}/>
            // <FaRegSmile  />
            ) : x.sentiment === 'negative' ? (
              <ImAngry color="red" size={20}/>
            ) : (
              <FaRegFaceMeh size={20} />
            ))}
          </Stack>

        }
        <Stack
          spacing="1"
          bgColor={'#121a1f'}
          minW="md"
          borderRadius="lg"
          py={2}
          px={3}
          textColor="white"
          maxW={200}
          maxH={'full'}
        >
          {x.media?.type === 'image' && (
            <a href={x.media?.link} target="_blank" rel="noreferrer">
              <img
                src={x.media?.link}
                alt="Image"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
            </a>
          )}
          {x.media?.type === 'audio' && (
            <a href={x.media?.link} target="_blank" rel="noreferrer">
              <audio style={{ maxWidth: '100%' }} controls>
                <source src={x.media?.link} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </a>
          )}
          {x.media?.type === 'video' && (
            <a href={x.media?.link} target="_blank" rel="noreferrer">
              <video
                style={{ maxWidth: '100%', maxHeight: '300px' }}
                controls
              >
                <source src={x.media?.link} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </a>
          )}
          {x.media?.type === 'file' && (
            <a href={x.media.link} target="_blank" rel="noreferrer">
              <FcFile style={{ fontSize: '50px' }} />
            </a>
          )}
          {x?.media && x?.media?.link && !x?.media?.type && (
            <a href={x?.media} target="_blank" rel="noreferrer">
              <FcFile style={{ fontSize: '50px' }} />
            </a>
          )}
          <HStack spacing="2" alignItems={'flex-start'} w={'full'} position={'relative'}>


            <Box color="fg.muted" fontSize={'sm'} lineHeight="tall" w={'full'}>
              {x.reply !== '' && x.reply !== undefined && (
                <ChatCardReplyDetail x={x.reply} globalState={globalState} />
              )}

              <HStack w={'full'}>


                <Text
                  fontSize="sm"
                  fontWeight={'bold'}
                  textTransform={'capitalize'}
                  color={x.user === globalState?.uid ? 'orange.600' : 'blue.500'}
                >
                  {' '}
                  {x?.user === 'AI'
                    ? 'AI'
                    : getUserByUid(globalState?.users, x?.user)?.name ||
                    'Customer'}
                </Text>
                <Spacer />
                <Stack >
                  <SettingMessage data={x} setChatReply={setChatReply} chatReply={chatReply} />
                </Stack>



              </HStack>
              <Stack py={1}>
                <div>
                  {highlightedMessages[i]?.highlightedText ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightedMessages[i]?.highlightedText
                          .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                          .replace(/(?:\r\n|\r|\n)/g, '<br>')
                          .replace(
                            /\[(.*?)\]\((.*?)\)/g,
                            '<a href="$2" target="_blank" style="text-decoration: underline; color: blue;">$1</a>'
                          ),
                      }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: x.message
                          .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                          .replace(/(?:\r\n|\r|\n)/g, '<br>')
                          .replace(
                            /\[(.*?)\]\((.*?)\)/g,
                            '<a href="$2" target="_blank" style="text-decoration: underline; color: blue;">$1</a>'
                          ),
                      }}
                    />
                  )}
                </div>
              </Stack>
              <HStack alignItems={'flex-end'} justifyContent={'flex-end'} w={'full'}>
                <Text
                  fontSize="xx-small"
                  textAlign={
                    x.user === globalState?.uid ? 'flex-end' : 'flex-start'
                  }
                >
                  {moment.unix(x?.createdAt).format('MM/DD HH:mm')}
                </Text>
              </HStack>
            </Box>
          </HStack>
        </Stack>
      </HStack>

      {x?.user !== globalState?.uid ? <Spacer /> : <></>}
    </HStack>
  )
}

export default ChatCard