/* eslint-disable react/prop-types */
import React from 'react'

const LabelTemplate = (data) => {
  (x) => {
    // const resi = x.resi;
    // const digits = resi.split('').map(Number);
    // const bars = digits.map((digit) => {
    //   const bar = digit.toString(2).padStart(7, '0').split('').map((d) => (d === '1' ? '█' : '░')).join('');
    //   return `<div style="display: inline-block; border: 1px solid black; width: 10px; height: 50px;">${bar}</div>`;
    // });
    // return bars.join('');
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <link href='https://fonts.googleapis.com/css?family=Libre Barcode 39' rel='stylesheet'>
          <style>
            body {
                font-family: 'Libre Barcode 39';font-size: 22px;
            }
          </style>
        </head>
        <body>
          <h1>${x.resi}</h1>
        </body>
      </html>
    `;
  };

  return (
    <>
      {data?.data?.map((item, index) => (
        <div
          key={index}
          style={{ width: '1000', padding: 5, backgroundColor: 'white' }}
        >
          {/* <p>{JSON.stringify(data)}</p> */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', borderWidth: '1px' }}>
            <div style={{ display: 'flex' }}>
              <img
                src='https://oexpress.id/wp-content/uploads/2022/09/cropped-Oexpress-LOGO-1-300x50-1.png'
                alt='jne'
                width={150}
                style={{
                  objectFit: 'contain'
                }}
              ></img>
            </div>
            <img
              src='https://business.deoapp.com/static/media/kotakputih.cb3515c0d29bb858cafa.png'
              alt='jne' width='50'
              style={{
                objectFit: 'contain'
              }}
            ></img>
          </div>
          <div style={{ fontSize: 10, display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ width: '50%', borderWidth: '1px' }}>
              <p>penerima:</p>
              <p style={{ fontWeight: 'bold' }}>{item.name} {item.phoneNumber}</p>
              <p>{item.address}</p>
              <h1 style={{ fontWeight: 'bold', fontSize: 20 }}>Kode Area : {item.areaCode}</h1>
            </div>
            <div style={{ width: '50%', borderWidth: '1px' }}>
              <p>pengirim</p>
              <p style={{ fontWeight: 'bold' }}>{item.senderName}</p>
              <p>{item.senderAddress}</p>
            </div>
          </div>
          <div style={{ fontSize: 10, display: 'flex', flexDirection: 'row', width: '100%', borderWidth: '1px' }}>
            <div style={{ width: '50%', borderWidth: '1px' }}>
              <p style={{ fontWeight: 'bold' }}>Detail goods :</p>
              {item.product.map((x, index) => (
                <p key={index} style={{ fontWeight: 'bold' }}>- {x}</p>
              ))}
              <p>Quantity : {item.quantity}</p>
              <p>With Insurance : NO</p>
              <p>Estimasi Ongkir : {item.shippingPrice.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</p>
              <p style={{ fontWeight: 'bold' }}>ORDER ID : {item.orderId}</p>
              <p>Print date : {item.printDate}</p>
            </div>
            <div style={{ display: 'block', width: '50%', borderWidth: '1px' }}>
              {/* <img src={'https://barcode.orcascan.com/?type=code128&data=12310387012980381093'}
                style={{ height: 100 }}
                alt='resi'></img> */}
              {/* <div id='barcode' dangerouslySetInnerHTML={{ __html: generateBarcodeHTML(item) }} /> */}
              <img style={{ padding: '3%' }} src={`https://barcode.tec-it.com/barcode.ashx?data=${item.resi}&code=Code128`} alt="Barcode" />
              {/* <p>RESI : <strong>{item.resi}</strong></p> */}
            </div>
          </div>
        </div>
      ))}
    </>
  )
};

export default LabelTemplate;