/* eslint-disable react/prop-types */
import { Badge, Box, Button, Fade, Heading, Stack } from '@chakra-ui/react'
// import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd';
import { useParams } from 'react-router-dom';
import { countDocumentsFirebase, getCollectionFirebase, getCollectionFirebaseV2 } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
// import { addDocumentFirebase, getCollectionFirebase } from '../../Apis/firebaseApi';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import LineTaskCardComponent from '../Card/LineTaskCardComponent';
import LineTaskDefaultCardComponent from '../Card/LineTaskDefaultCardComponent';
import moment from 'moment';


function AcquisitionColumnsDefaultComponent({ allowedDropEffect, column, filterData, index, handleModalOpen }) {
  const [columnsData, setColumnsData] = useState([])
  const [columnsData2, setColumnsData2] = useState([])
  const param = useParams()
  const globalState = useUserStore();

  const handleLoad = () => {
    const today = new Date();
    let time = new Date();

    if (param.id === 'today-transaction') {
      time = new Date();
    } else if (param.id === 'last-7-days-transaction') {
      time.setDate(today.getDate() - 7);
    } else if (param.id === 'last-14-days-transaction') {
      time.setDate(today.getDate() - 14);
    } else if (param.id === 'last-30-days-transaction') {
      time.setDate(today.getDate() - 30);
    } else if (param.id === 'last-90-days-transaction') {
      time.setDate(today.getDate() - 90);
    }
    const conditions = [
      { field: 'column', operator: '==', value: column },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'last_transaction', operator: '<=', value: today },
      { field: 'last_transaction', operator: '>=', value: time },
      { field: 'total_paid_transaction', operator: '==', value: 0 },
      // { field: 'type', operator: '==', value: 'acquisition' },
    ];
    console.log(conditions, 'comditions')
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = ''

    if (columnsData2.length > 0) {
      // console.log('kedua kali dan seterusnya')
      startAfter = columnsData2[columnsData2.length - 1].lastUpdated
      if (filterData?.status) conditions.push({ field: 'status', operator: '==', value: filterData?.status })

    } else {

      startAfter = columnsData[columnsData.length - 1].lastUpdated
      if (filterData?.category)
        conditions.push({ field: 'status', operator: '==', value: filterData?.status })
    }

    getCollectionFirebaseV2('customer', { conditions }, { sortBy }, { limitValue }, { startAfterData: startAfter })
      .then((x) => {
        const updateData = [...columnsData2, ...x]
        setColumnsData2(updateData)
      })

  }

  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'name',
      filter_by: `column:${column} `,
      sort_by: '_text_match:desc'
    };
    clientTypessense
      .collections('customer')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setColumnsData(newData)
      });
  }
  // console.log(columnsData)
  useEffect(() => {
    let unsubscribe = () => { };

    const fetchData = async () => {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
        { field: 'column', operator: '==', value: column },
        // { field: 'type', operator: '==', value: 'acquisition' },
      ];

      const today = new Date();
      let time = new Date();

      if (param.id === 'today-transaction') {
        // time = new Date();
        time = moment(`${moment().format('YYYY-MM-DD')}) 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toDate();
      } else if (param.id === 'last-7-days-transaction') {
        time.setDate(today.getDate() - 7);
      } else if (param.id === 'last-14-days-transaction') {
        time.setDate(today.getDate() - 14);
      } else if (param.id === 'last-30-days-transaction') {
        time.setDate(today.getDate() - 30);
      } else if (param.id === 'last-90-days-transaction') {
        time.setDate(today.getDate() - 90);
      }

      if (filterData?.search) {
        setTimeout(function () {
          handleTypesenseSearch(filterData.search);
        }, 300);
      }

      if (filterData?.status) {
        conditions.push({ field: 'status', operator: '==', value: filterData.status });
      }

      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = 5;

      const collectionRef = query(
        collection(db, 'customer'),
        where('column', '==', column),
        where('projectId', '==', globalState?.currentProject),
        where('companyId', '==', globalState?.currentCompany),
        where('lastUpdated', '<=', today),
        where('lastUpdated', '>=', time),
        where('total_paid_transaction', '==', 0),
        orderBy('lastUpdated', 'desc'),
        limit(limitValue)
      );
      console.log(time, 'time')
      unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setColumnsData(arr);
        console.log(arr, 'arr');
      });
    };

    fetchData();

    return () => {
      unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [filterData, globalState.currentProject]);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'column',
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect],
  )

  const ColumnTasks = (datas, type, handleModalOpen) => datas?.map((x, index) => {
    if (x.id)
      return (
        <Fade in={true} initialscale={0.9} key={index}>
          <LineTaskDefaultCardComponent
            key={index}
            task={x}
            indexNumber={index}
            setData={type === 'snapshot' ? setColumnsData : setColumnsData2}
            columnsData={datas}
            handleModalOpen={handleModalOpen}
          />
        </Fade>
      )
  });
  return (
    <Box w='xs' m='1' >
      <Heading fontSize="md" mb={1} letterSpacing="wide" textAlign='center'>
        <Badge
          w='full'
          fontSize='xl'
          px={2}
          py={1}
          rounded="lg"
          bgColor={`green.${index ? index * 100 : 50}`}
          color='black'
        >
          {column}
        </Badge>
      </Heading>

      <Stack
        ref={drop}
        direction='column'
        h={{ base: '30vh', md: '80vh' }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver ? 'red' : 'none'}
        rounded="lg"
        boxShadow="md"
        overflow="auto"
        opacity={isOver ? 0.85 : 1}
      >
        {ColumnTasks(columnsData, 'snapshot', handleModalOpen)}
        {columnsData2?.length ? ColumnTasks(columnsData2, 'manual', handleModalOpen) : <></>}
        {columnsData?.length > 4 && columnsData2?.length === 0 ?
          <Button onClick={() => handleLoad()}> Load more</Button>
          :
          columnsData2?.length > 4 ?
            <Button onClick={() => handleLoad()}> Load more</Button>
            :
            <></>
        }
      </Stack>
    </Box >
  )
}

export default AcquisitionColumnsDefaultComponent