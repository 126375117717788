/* eslint-disable react/no-unknown-property */
import React from 'react'

// eslint-disable-next-line react/prop-types
const Course1 = ({ modeBG }) => {
  return (
    <div className={modeBG === 'dark' ? 'py-8 text-white' : 'py-8'}>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center pb-5 lg:pb-20">
          <h2 className="text-3xl font-bold tracking-tight  sm:text-4xl">
            We’re always looking for awesome people to join us
          </h2>
          <p className="mt-6 lg:text-xl leading-8 ">
            Diam nunc lacus lacus aliquam turpis enim. Eget hac velit est
            euismod lacus. Est non placerat nam arcu. Cras purus nibh cursus

          </p>
        </div>
        <div className="mx-auto flex flex-col items-start justify-between lg:mx-0 lg:max-w-none lg:flex-row">
          <div className="w-full lg:max-w-lg lg:flex-auto pb-4">
            <img
              width="1920"
              height="1357"
              src="https://cdn.filestackcontent.com/8MbtJ4hTAaOk3KPcptqZ"
              alt=""
              className="aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
            />
          </div>
          <div className="w-full h-[34.5rem] overflow-y-auto lg:max-w-xl lg:flex-auto ">
            <ul className="divide-y divide-gray-100">
              {/* {dataCourse?.map((x,i) => ( */}
              <div className="flex w-full h-auto justify-center">
                <div className="w-full">
                  <div>
                    <h3>
                      <div className="group flex w-full items-center justify-between rounded-t-sm pt-4 pb-2 text-left">
                        <li className="my-4 lg:py-0">
                          <dl className="relative flex flex-wrap gap-x-3">
                            <dd className="w-full flex-none text-lg font-semibold tracking-tight ">
                              <span>{'tittle'}</span>
                            </dd>
                            <dd className="mt-2 w-full flex-none text-base leading-7 ">
                              {'description'}
                            </dd>
                            <dd className="mt-4 text-base font-semibold leading-7 ">
                              {'price'}
                            </dd>
                            <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 ">
                              <svg
                                viewBox="0 0 2 2"
                                className="h-0.5 w-0.5 flex-none fill-gray-300"
                                aria-hidden="true"
                              >
                                <circle cx="1" cy="1" r="1" />
                              </svg>
                              {'country'}
                            </dd>
                          </dl>
                        </li>
                      </div>
                    </h3>
                    <div>
                      {/* {x.couse?.map((x,i) => ( */}
                      <a href={'#'} className="cursor-pointer">
                        <div className="flex space-x-2  items-center px-3 py-1 mt-1">
                          <div className="flex space-x-3 items-center">
                            <div>
                              <svg
                                className="-mr-0.5 h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule='evenodd'
                                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                                  clip-rule='evenodd'
                                />
                              </svg>
                            </div>
                            <div>
                              <div>{1}- {'name'} ({'nameTime'})</div>
                            </div>
                          </div>
                        </div>
                      </a>
                      {/* ))} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* ))} */}
            </ul>
            <div className="mt-8 flex border-t border-gray-100 pt-8">
              <a
                href="/course/id"
                className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                View all openings <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Course1