import React from 'react';
import PropTypes from 'prop-types';

const ProductFeature2 = ({ header, data, width }) => {
  // const dataDummy = [
  //   {
  //     image: 'https://klamby.id/cdn/shop/files/Cover_cf5d459e-994e-44a5-9fca-11d00e31c22e_540x.jpg?v=1706237194',
  //   },
  //   {
  //     image: 'https://klamby.id/cdn/shop/files/Cover_c50db63d-c9c5-42d5-b22e-10f5e6507f89_540x.jpg?v=1705895337',
  //   },
  //   {
  //     image: 'https://klamby.id/cdn/shop/files/Cover_fe48d96e-d726-40ce-a876-06cde90c71aa_360x.jpg?v=1704352951',
  //   },
  //   {
  //     image: 'https://klamby.id/cdn/shop/files/Cover_195087dd-83c9-4858-ac15-fc9fb616f2f2_360x.jpg?v=1704693279',
  //   },
  //   {
  //     image: 'https://klamby.id/cdn/shop/files/Cover_5be4c91f-8a85-4198-8320-c7b4aaed0752_360x.jpg?v=1704178975',
  //   },
  // ];
  return (
    <div className='p-20'>
      <p className='text-center text-lg mb-10'>{header || 'Our Latest Lookbook'}</p>
      <div className={width === '400px' ? 'm-auto flex justify-center gap-2 flex-wrap w-[7vw]' : 'm-auto flex justify-center gap-5 flex-wrap w-[60vw]'}>
        {data?.map((item, index) => (
          <div key={index}>
            <div className={`product ${index >= 2 ? 'w-[15.5rem]' : 'w-96'}`}>
              <img
                src={item.image}
                alt={`Product ${index}`}
              />
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default ProductFeature2;

ProductFeature2.propTypes = {
  header: PropTypes.string,
  width: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};
