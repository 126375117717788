/* eslint-disable no-undef */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

// import song from '../assets/sound/CoinDrop-Notification.mp3';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATA_BASE_URL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  token_option: process.env.REACT_APP_TOKEN_OPTION, // your vapid key
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);
const configMessage = getMessaging(app);
auth.languageCode = 'id';

export { app, analytics, auth, db, storage, database };
export const fetchToken = async () => {
  try {
    const token = await getToken(configMessage, {
      vapidKey: firebaseConfig.token_option,
    });
    if (token) {
      return token;
    } else {
      return 'no push notif token for now';
    }
  } catch (error) {
    return error;
  }
};

export const onMessageListener = (toast) => {
  onMessage(configMessage, (payload) => {
    // const notif = new Audio(song);
    notif.play();
    const { data } = payload;
    const { title, description } = data;
    toast({
      title: title,
      description: description,
      position: 'top-right',
      isClosable: true,
    });
  });
};
