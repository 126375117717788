export const navbarData = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'Products',
    link: '/products'
  },
  {
    name: 'About',
    link: '/about'
  },
  {
    name: 'Location',
    link: '/location'
  },
]

export const mainBannerSliderContent = [
  {
    name: 'Banner name',
    link: 'www.image-here.com'
  },
  {
    name: 'Banner name',
    link: 'www.image-here.com'
  },
  {
    name: 'Banner name',
    link: 'www.image-here.com'
  }
]

export const secondBannerSliderContent = [
  {
    name: 'Banner name',
    link: 'www.image-here.com'
  },
  {
    name: 'Banner name',
    link: 'www.image-here.com'
  },
  {
    name: 'Banner name',
    link: 'www.image-here.com'
  }
]

export const newProductContent = [
  {
    name: 'Shirt 1',
    price: 200000,
    link: 'www.testing.com'
  },
  {
    name: 'Shirt 1',
    price: 200000,
    link: 'www.testing.com'
  },
  {
    name: 'Shirt 1',
    price: 200000,
    link: 'www.testing.com'
  },
  {
    name: 'Shirt 1',
    price: 200000,
    link: 'www.testing.com'
  },
]

export const highlightSeries = [
  {
    name: 'Baju',
    price: 300000,
    link: 'www.testing.com'
  },
  {
    name: 'Baju',
    price: 300000,
    link: 'www.testing.com'
  },
  {
    name: 'Baju',
    price: 300000,
    link: 'www.testing.com'
  },
]

export const favoriteItemsContent = [
  {
    title: 'Baju',
    imageLink: 'www.testing.com'
  },
  {
    title: 'Baju',
    imageLink: 'www.testing.com'
  },
  {
    title: 'Baju',
    imageLink: 'www.testing.com'
  },
]

export const footerContent = ['contact us', 'careers', 'terms & conditions', 'offline store']

export const customerSupport = ['klamby family', 'about points', 'FAQ']

export const socialMedia = ['Tokopedia', 'Instagram', 'Facebook', 'YouTube', 'Tiktok']

export const sections = [{
  sectionName: 'What\'s New',
  data: [
    {
      productName: 'Maia Dress Sahara',
      image: 'https://klamby.id/cdn/shop/files/MaiaDress-Sahara_1of4_539a5b0b-2d9f-4651-ad4a-18dd531e9212_360x.jpg?v=1704817290',
      price: 618000,
      discount: '50%',
      link: 'https://lpage.id/testing-sayur/'
    },
    {
      productName: 'Maia Dress Sahara',
      image: 'https://klamby.id/cdn/shop/files/MaiaDress-Sahara_1of4_539a5b0b-2d9f-4651-ad4a-18dd531e9212_360x.jpg?v=1704817290',
      price: 618000,
      discount: '50%',
      link: 'https://lpage.id/testing-sayur/'
    },
    {
      productName: 'Maia Dress Sahara',
      image: 'https://klamby.id/cdn/shop/files/MaiaDress-Sahara_1of4_539a5b0b-2d9f-4651-ad4a-18dd531e9212_360x.jpg?v=1704817290',
      price: 618000,
      discount: '50%',
      link: 'https://lpage.id/testing-sayur/'
    },
  ]
}]