/* eslint-disable react/prop-types */
import { Badge, Box, Button, Fade, Heading, Stack } from '@chakra-ui/react'
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd';
import { getCollectionFirebase, getCollectionFirebaseV2 } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
// import { addDocumentFirebase, getCollectionFirebase } from '../../Apis/firebaseApi';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import LineTaskCardComponent from '../Card/LineTaskCardComponent';


function AcquisitionColumnsComponent({ allowedDropEffect, column,  filterData, index, filesId, handleModalOpen }) {
  const [columnsData, setColumnsData] = useState([])
  const [columnsData2, setColumnsData2] = useState([])
  const globalState = useUserStore();

  const handleLoad = () => {
    const conditions = [
      { field: 'filesId', operator: '==', value: filesId },
      { field: 'column', operator: '==', value: column },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = ''

    if (columnsData2.length > 0) {
      // console.log('kedua kali dan seterusnya')
      startAfter = columnsData2[columnsData2.length - 1].lastUpdated
      if (filterData?.status) conditions.push({ field: 'status', operator: '==', value: filterData?.status })

    } else {

      startAfter = columnsData[columnsData.length - 1].lastUpdated
      if (filterData?.category)
        conditions.push({ field: 'status', operator: '==', value: filterData?.status })
    }


    getCollectionFirebaseV2('customer', { conditions }, { sortBy }, { limitValue }, { startAfterData: startAfter })
      .then((x) => {
        const updateData = [...columnsData2, ...x]
        setColumnsData2(updateData)
      })

  }

  console.log(columnsData2,'colomn')


  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'name',
      filter_by: `filesId: ${filesId} && column:${column} `,
      sort_by: '_text_match:desc'
    };
    clientTypessense
      .collections('pipelines')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setColumnsData(newData)
      });
  }



  useEffect(() => {
    let unsubscribe = () => { };

    if (!filterData?.search) {
      const fetchData = async () => {
        const conditionsProduct = [
          { field: 'companyId', operator: '==', value: globalState.currentCompany },
          { field: 'projectId', operator: '==', value: globalState.currentProject },
          { field: 'tags', operator: 'array-contains', value: name },
        ];

        const products = await getCollectionFirebase('product', conditionsProduct);
        const productIds = products?.map((product) => product.id);

        const orderPromises = productIds?.map(async (productId) => {
          const orders = await getCollectionFirebase('orders', [{ field: 'productIds', operator: 'array-contains', value: productId }]);
          const userIds = orders?.map((order) => order.userId);
          return [...new Set(userIds)]?.filter((userId) => userId !== '');
        });

        const uniqueUserIdsArray = await Promise.all(orderPromises);
        console.log(uniqueUserIdsArray, 'op');
        if (uniqueUserIdsArray && uniqueUserIdsArray.length > 1 && uniqueUserIdsArray[1].length > 0) {
          const collectionRef = query(collection(db, 'customer'), 
            where('column', '==', column), 
            where('companyId', '==', globalState.currentCompany), 
            where('projectId', '==', globalState.currentProject), 
            where('uid', 'in', uniqueUserIdsArray[1])
          );
          unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
            const addTask = [];
            querySnapshot?.forEach((doc) => {
              addTask?.push({ id: doc.id, ...doc.data() });
            });
            setColumnsData(addTask);
          });
        } else {
          return;
        }



        // const customerData = await getCollectionFirebase('customer', [
        //   { field: 'companyId', operator: '==', value: globalState.currentCompany },
        //   { field: 'projectId', operator: '==', value: globalState.currentProject },
        //   { field: 'uid', operator: 'in', value: uniqueUserIdsArray[1] },
        //   { field: 'column', operator: '==', value: column }
        // ]);
        // console.log(customerData)
        // setColumnsData(customerData)
      };
      fetchData();
    }

    return () => {
      // unsubscribe();
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [filterData, globalState.currentProject]);


  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'column',
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect],
  )

  const ColumnTasks = (datas, type, handleModalOpen) => datas?.map((x, index) => {
    // console.log(x, 'ini datanya di columntask')
    if (x.id)
      return (
        <Fade in={true} initialscale={0.9} key={index}>
          <LineTaskCardComponent
            key={index}
            task={x}
            indexNumber={index}
            setData={type === 'snapshot' ? setColumnsData : setColumnsData2}
            columnsData={datas}
            handleModalOpen={handleModalOpen}
          />
        </Fade>
      )
  });

  return (
    <Box w='xs' m='1' >
      <Heading fontSize="md" mb={1} letterSpacing="wide" textAlign='center'>
        <Badge
          w='full'
          fontSize='xl'
          px={2}
          py={1}
          rounded="lg"
          bgColor={`green.${index ? index * 100 : 50}`}
          color='black'
        >
          {column}
        </Badge>
      </Heading>

      <Stack
        ref={drop}
        direction='column'
        h={{ base: '30vh', md: '80vh' }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver ? 'red' : 'none'}
        rounded="lg"
        boxShadow="md"
        overflow="auto"
        opacity={isOver ? 0.85 : 1}
      >
        {ColumnTasks(columnsData, 'snapshot', handleModalOpen)}
        {columnsData2?.length ? ColumnTasks(columnsData2, 'manual', handleModalOpen) : <></>}
        {columnsData?.length > 4 && columnsData2?.length === 0 ?
          <Button onClick={() => handleLoad()}> Load more</Button>
          :
          columnsData2?.length > 4 ?
            <Button onClick={() => handleLoad()}> Load more</Button>
            :
            <></>
        }
      </Stack>
    </Box >
  )
}

export default AcquisitionColumnsComponent