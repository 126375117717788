import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const Navbar1 = ({ name, bgColor, textColor, navmenu, logo, width }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentParent, setCurrentParent] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleSubCategoryClick = (e) => {
    e.preventDefault();
  };

  return (
    <header
      className='bg-white'
      style={{ backgroundColor: bgColor || '#fff' }}>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
        aria-label='Global'>
        <div className='flex flex-1'>
          <div className={width === '400px' ? 'hidden' : 'lg:flex lg:gap-x-12'}>
            {navmenu?.length > 0 &&
              navmenu.map((parentItem, parentIndex) => (
                <div
                  key={parentIndex}
                  onMouseEnter={() => {
                    setCurrentParent(parentIndex);
                    setShowDropdown(true);
                  }}
                  onMouseLeave={() => setShowDropdown(false)}>
                  <a
                    href={parentItem.link}
                    className='text-sm font-semibold leading-6 text-gray-900'
                    style={{ color: textColor || '#111827' }}>
                    {parentItem.name}
                  </a>
                  {showDropdown && currentParent === parentIndex && parentItem.subCategories && (
                    <div className='absolute bg-white shadow-lg rounded-lg w-48 z-10'>
                      {parentItem.subCategories.map((subItem, subIndex) => (
                        <a
                          key={subIndex}
                          onClick={(e) => handleSubCategoryClick(e, subItem.name)}
                          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                          {subItem.name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className={width === '400px' ? 'flex' : 'hidden'}>
            <button
              type='button'
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)} // Toggle mobile menu
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'>
              <span className='sr-only'>Open main menu</span>
              <svg
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                aria-hidden='true'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                />
              </svg>
            </button>
          </div>
        </div>
        <a
          href='#'
          className='-m-1.5 p-1.5'>
          <span className='sr-only'>{name || 'Your Company'}</span>
          <img
            className='h-8 w-auto'
            src={logo || 'https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'}
            alt=''
          />
        </a>
        <div className={`${width === '400px' && mobileMenuOpen ? 'flex' : 'hidden'} lg:hidden`}>
          <button
            onClick={closeMobileMenu}
            className='text-sm font-semibold leading-6 text-gray-900'
            style={{ color: textColor || '#111827' }}>
            Close <span aria-hidden='true'>&rarr;</span>
          </button>
        </div>
        <div className='hidden lg:flex lg:flex-1 justify-end'>
          <a
            href='#'
            className='text-sm font-semibold leading-6 text-gray-900'
            style={{ color: textColor || '#111827' }}>
            Log in <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </nav>
    </header>
  );
};

Navbar1.propTypes = {
  name: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  navmenu: PropTypes.array,
  logo: PropTypes.string,
  width: PropTypes.string,
};
