/* eslint-disable react/no-unknown-property */
import { Box, HStack, Icon, Text, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Hero1 } from '../Ecommerce/templates/Hero/Hero1';
import { Hero2 } from '../Ecommerce/templates/Hero/Hero2';
import { Hero3 } from '../Ecommerce/templates/Hero/Hero3';
import { Hero4 } from '../Ecommerce/templates/Hero/Hero4';
import Hero5 from '../Ecommerce/templates/Hero/Hero5';
import Hero6 from '../Ecommerce/templates/Hero/Hero6';
import Hero7 from '../Ecommerce/templates/Hero/Hero7';
import LogoFeatures1 from '../Ecommerce/templates/Features/LogoFeatures1';
import ProductHighlight1 from '../Ecommerce/templates/Product/ProductHighlight1';
import HeroSlider from '../Ecommerce/templates/Hero/HeroSlider';
import Product3 from '../Ecommerce/templates/Product/Product3';
import { Product1 } from '../Ecommerce/templates/Product/Product1';
import { Product2 } from '../Ecommerce/templates/Product/Product2';
import { ProductView2 } from '../Ecommerce/templates/Product/ProductView2';
import { ProductView1 } from '../Ecommerce/templates/Product/ProductView1';
import Category2 from '../Ecommerce/templates/Product/Category2';
import { Blog1 } from '../Ecommerce/templates/Blog/Blog1';
import { BlogDetail1 } from '../Ecommerce/templates/Blog/BlogDetail1';
import Course1 from '../Ecommerce/templates/Course/Course1';
import { CourseDetail1 } from '../Ecommerce/templates/Course/CourseDetail1';
import { Checkout1 } from '../Ecommerce/templates/Product/Checkout1';
import { TiDelete, TiPencil } from 'react-icons/ti';
import PropTypes from 'prop-types';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase } from '../../../Api/firebaseApi';
import { decryptToken } from '../../../Utils/encrypToken';
import UploadFileToDropbox from '../../../Components/DropBox/UploadFileToDropbox';
import ModalAddContentProduct from '../Ecommerce/Modals/ModalAddContentProduct';
import ModalAddCategory from '../Ecommerce/Modals/ModalAddCategory';
import ModalAddCheckout from '../Ecommerce/Modals/ModalAddCheckout';
import Cart1 from '../Ecommerce/templates/Cart/Cart1';
import ModalAddCart from '../Ecommerce/Modals/ModalAddCart';
import ProductFeature2 from '../Ecommerce/templates/Product/ProductFeature2';
import { Product4 } from '../Ecommerce/templates/Product/Product4';
import { Product5 } from '../Ecommerce/templates/Product/Product5';
import { Product6 } from '../Ecommerce/templates/Product/Product6';
import { Product7 } from '../Ecommerce/templates/Product/Product7';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ProductCountdown } from '../Ecommerce/templates/Product/ProductCountdown1';

const SavedTemplateDisplay = ({ focusPage, websiteStore, handleOpenEditHero, handleRemoveSection, width }) => {
  const modalProduct = useDisclosure();
  const modalCategory = useDisclosure();
  const modalCheckout = useDisclosure();
  const modalCart = useDisclosure();
  const globalState = useUserStore();
  const [progress, setProgress] = useState();

  const handleChangeSectionImage = async (e, section, index, field) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/website-assets`;

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token', 'dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file, parentPath, decryptResult, setProgress, progress);

      switch (section) {
      case 'product':
        {
          const currentData = websiteStore?.pageProduct;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageProduct(currentData);
          }
        }
        break;

      case 'Product Detail':
        {
          const currentData = websiteStore?.pageProduct;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageProductDetail(currentData);
          }
        }
        break;

      case 'category':
        {
          const currentData = websiteStore?.pageCategory;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageCategory(currentData);
          }
        }
        break;

      case 'Blog Page':
        {
          const currentData = websiteStore?.pageBlog;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageBlog(currentData);
          }
        }
        break;

      case 'Blog Detail Page':
        {
          const currentData = websiteStore?.pageProduct;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageBlogDetail(currentData);
          }
        }
        break;

      case 'Checkout Page':
        {
          const currentData = websiteStore?.pageCheckout;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageCheckout(currentData);
          }
        }
        break;

      case 'Cart Page':
        {
          const currentData = websiteStore?.pageCart;
          if (currentData) {
            currentData.data[index][field] = res?.link;
            websiteStore?.setPageCart(currentData);
          }
        }
        break;

      default:
        // Default case if none of the conditions match
        break;
      }
    } catch (error) {
      throw Error(error);
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  // console.log(websiteStore?.pageHome,'ini page home')

  // const onDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const currentPageHome = websiteStore?.pageHome

  //   const source = result?.source.index
  //   const destination = result?.destination.index
  //   const itemToMove = currentPageHome?.splice(source,1)

  //   currentPageHome?.splice(destination,0,itemToMove[0])

  //   console.log(currentPageHome)

  //   websiteStore?.setPageHome(currentPageHome)
  // }

  const onDragEnd = (results) => {
    const { source, destination, type } = results;
    const currentPageHome = websiteStore?.pageHome;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    if (type === 'group') {
      const reorderedStores = [...currentPageHome];

      const storeSourceIndex = source.index;
      const storeDestinatonIndex = destination.index;

      const [removedStore] = reorderedStores.splice(storeSourceIndex, 1);
      reorderedStores.splice(storeDestinatonIndex, 0, removedStore);

      return websiteStore?.setPageHome(reorderedStores);
    }
    const itemSourceIndex = source.index;
    const itemDestinationIndex = destination.index;

    const storeSourceIndex = currentPageHome.findIndex((store) => store.id === source.droppableId);
    const storeDestinationIndex = currentPageHome.findIndex((store) => store.id === destination.droppableId);

    const newSourceItems = [...currentPageHome[storeSourceIndex].items];
    const newDestinationItems = source.droppableId !== destination.droppableId ? [...currentPageHome[storeDestinationIndex].items] : newSourceItems;

    const [deletedItem] = newSourceItems.splice(itemSourceIndex, 1);
    newDestinationItems.splice(itemDestinationIndex, 0, deletedItem);

    const newStores = [...currentPageHome];

    newStores[storeSourceIndex] = {
      ...currentPageHome[storeSourceIndex],
      items: newSourceItems,
    };
    newStores[storeDestinationIndex] = {
      ...currentPageHome[storeDestinationIndex],
      items: newDestinationItems,
    };

    websiteStore?.setPageHome(newStores);
  };
  // console.log(websiteStore.pageProduct.data, 'data page product data');

  return (
    <Box>
      {focusPage === 'Home Page' ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId='home-1'
            type='group'>
            {(provided) => {
              return (
                <div
                  className='border p-4'
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                  {websiteStore?.pageHome?.map((x, i) => (
                    <div key={i}>
                      <Draggable
                        draggableId={x?.id}
                        index={i}
                        key={x?.id}>
                        {(provided) => {
                          return (
                            <div
                              className='p-4 border bg-white'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              {x?.id === 'Hero1' ? (
                                <Hero1
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  width={width}
                                />
                              ) : x?.id === 'Hero2' ? (
                                <Hero2
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'Hero3' ? (
                                <Hero3
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'Hero4' ? (
                                <Hero4
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'Hero5' ? (
                                <Hero5
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'Hero6' ? (
                                <Hero6
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'ProductCountdown' ? (
                                <ProductCountdown
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'Hero7' ? (
                                <Hero7
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'LogoFeatures1' ? (
                                <LogoFeatures1
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'ProductHighlights2' ? (
                                <ProductHighlight1
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'FullImage2' ? (
                                <Hero6
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'BannerSlider1' ? (
                                <HeroSlider
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : x?.id === 'FullImage3' ? (
                                <ProductFeature2
                                  header={x?.header}
                                  content={x?.content}
                                  data={x?.data}
                                  image={x?.image}
                                  width={width}
                                />
                              ) : null}
                              <HStack>
                                <HStack
                                  cursor={'pointer'}
                                  onClick={() => handleOpenEditHero(x, i)}
                                  mb={4}>
                                  <Icon
                                    as={TiPencil}
                                    cursor={'pointer'}
                                  />
                                  <Text fontSize={'sm'}>Edit</Text>
                                </HStack>
                                <HStack
                                  cursor={'pointer'}
                                  onClick={() => handleRemoveSection(x, i)}
                                  mb={4}>
                                  <Icon
                                    as={TiDelete}
                                    cursor={'pointer'}
                                  />
                                  <Text fontSize={'sm'}>Delete</Text>
                                </HStack>
                              </HStack>
                            </div>
                          );
                        }}
                      </Draggable>
                    </div>
                  ))}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : focusPage === 'Product Page' ? (
        <>
          {websiteStore?.pageProduct?.id === 'ProductsFilterComponent' ? (
            <Product3
              modeBG={websiteStore?.color[1]?.colorCode}
              textColor={websiteStore?.color[2]?.colorCode}
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
              filter={websiteStore?.pageProduct?.filterConfiguration || null}
            />
          ) : websiteStore?.pageProduct?.id === 'Product4' ? (
            <Product4
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
              filter={websiteStore?.pageProduct?.filterConfiguration || null}
            />
          ) : websiteStore?.pageProduct?.id === 'Product5' ? (
            <Product5
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
              filter={websiteStore?.pageProduct?.filterConfiguration || null}
            />
          ) : websiteStore?.pageProduct?.id === 'Product6' ? (
            <Product6
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
              filter={websiteStore?.pageProduct?.filterConfiguration || null}
            />
          ) : websiteStore?.pageProduct?.id === 'Product7' ? (
            <Product7
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
              filter={websiteStore?.pageProduct?.filterConfiguration || null}
            />
          ) : websiteStore?.pageProduct?.id === 'Product1' ? (
            <Product1
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
              filter={websiteStore?.pageProduct?.filterConfiguration || null}
            />
          ) : websiteStore?.pageProduct?.id === 'Product2' ? (
            <Product2
              header={websiteStore?.pageProduct?.header}
              content={websiteStore?.pageProduct?.content}
              data={websiteStore?.pageProduct?.data}
            />
          ) : null}
          {websiteStore?.pageProduct?.id && (
            <HStack
              cursor={'pointer'}
              onClick={modalProduct.onOpen}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Product Detail' ? (
        <>
          {websiteStore?.pageProductDetail?.id === 'ProductViewComponent' ? (
            <ProductView2 otherColor={websiteStore?.color[2]?.colorCode} />
          ) : websiteStore?.pageProductDetail?.id === 'ProductView1' ? (
            <ProductView1 otherColor={websiteStore?.color[2]?.colorCode} />
          ) : null}
          {websiteStore?.pageProductDetail?.id && (
            <HStack
              cursor={'pointer'}
              // onClick={() => handleOpenEditPage()}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Category Page' ? (
        <>
          {websiteStore?.pageCategory?.id === 'CategoryThreeColumn' ? (
            <Category2
              modeBG={websiteStore?.color[1]?.colorCode}
              data={websiteStore?.pageCategory?.data}
              header={websiteStore?.pageCategory?.header}
              content={websiteStore?.pageCategory?.content}
            />
          ) : null}
          {websiteStore?.pageCategory?.id && (
            <HStack
              cursor={'pointer'}
              onClick={modalCategory?.onOpen}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Blog Page' ? (
        <>
          {websiteStore?.pageBlog?.id === 'BlogComponent' ? <Blog1 /> : null}
          {websiteStore?.pageBlog?.id && (
            <HStack
              cursor={'pointer'}
              // onClick={() => handleOpenEditPage()}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Blog Detail Page' ? (
        <>
          {websiteStore?.pageBlogDetail?.id === 'BlogComponent' ? <BlogDetail1 /> : null}
          {websiteStore?.pageBlogDetail?.id && (
            <HStack
              cursor={'pointer'}
              // onClick={() => handleOpenEditPage()}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Course Page' ? (
        <>
          {websiteStore?.pageCourse?.id === 'CourseMainPage' ? <Course1 modeBG={websiteStore?.color[1]?.colorCode} /> : null}
          {websiteStore?.pageCourse?.id && (
            <HStack
              cursor={'pointer'}
              // onClick={() => handleOpenEditPage()}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Course Detail Page' ? (
        <>
          {websiteStore?.pageViewCourse?.id === 'CourseVideoPage' ? <CourseDetail1 modeBG={websiteStore?.color[1]?.colorCode} /> : null}
          {websiteStore?.pageViewCourse?.id && (
            <HStack
              cursor={'pointer'}
              // onClick={() => handleOpenEditPage()}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Checkout Page' ? (
        <>
          {websiteStore?.pageCheckout?.id === 'Checkout1' ? (
            <Checkout1
              textColor={websiteStore?.color[2]?.colorCode}
              otherColor={websiteStore?.color[3]?.colorCode}
              accentColor={websiteStore?.color[2]?.colorCode}
              data={websiteStore?.pageCheckout?.formInput}
            />
          ) : null}

          {websiteStore?.pageCheckout?.id && (
            <HStack
              cursor={'pointer'}
              onClick={modalCheckout.onOpen}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : focusPage === 'Cart Page' ? (
        <>
          {websiteStore?.pageCart?.id === 'Cart1' ? (
            <Cart1
              header={websiteStore?.pageCart?.header}
              content={websiteStore?.pageCart?.content}
              textColor={websiteStore?.color[2]?.colorCode}
              otherColor={websiteStore?.color[3]?.colorCode}
              accentColor={websiteStore?.color[2]?.colorCode}
            />
          ) : null}

          {websiteStore?.pageCart?.id && (
            <HStack
              cursor={'pointer'}
              onClick={modalCart.onOpen}
              my={4}>
              <Icon
                as={TiPencil}
                cursor={'pointer'}
              />
              <Text fontSize={'sm'}>Edit</Text>
            </HStack>
          )}
        </>
      ) : null}

      <ModalAddContentProduct
        modal={modalProduct}
        handleChangeSectionImage={handleChangeSectionImage}
      />

      <ModalAddCategory
        modal={modalCategory}
        handleChangeSectionImage={handleChangeSectionImage}
      />

      <ModalAddCheckout modal={modalCheckout} />

      <ModalAddCart modal={modalCart} />
    </Box>
  );
};

export default SavedTemplateDisplay;

SavedTemplateDisplay.propTypes = {
  focusPage: PropTypes.string,
  width: PropTypes.string,
  websiteStore: PropTypes.func,
  handleOpenEditHero: PropTypes.func,
  handleRemoveSection: PropTypes.func,
};
