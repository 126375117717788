/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Text,
  HStack,
  Divider,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spacer,
  useToast,
  useDisclosure,
  Input,
  Button,
} from '@chakra-ui/react';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../Config/firebase';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';

import 'react-date-range/dist/styles';
import 'react-date-range/dist/theme/default.css';

import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DynamicTable from '../../Components/Table/DynamicTable';
import { PiShareBold } from 'react-icons/pi';
import ExportExcel from '../../Utils/ExportExcel';
import ComponentExportExcel from '../../Utils/ExportExcel';


const ProductPageV2 = () => {
  const navigate = useNavigate();
  const globalState = useUserStore();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState('')
  const [dataProduct, setDataProduct] = useState([])
  const toast = useToast()


  const itemsPerPage = 8;


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // console.log(globalState.currentProject, 'project')
  const createNewProduct = () => {
    const unix = name.toLowerCase() + ' ' + Date.now();
    const slug = unix.split(' ').join('-')

    const data = {
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      name: name,
      slug: slug
    };
    addDocumentFirebase('product', data, globalState.currentCompany).then((docId) => {
      navigate(docId);
    });
  };

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      }
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase(
        'product',
        conditions,
        sortBy,
        limitValue,
      );
      setDataProduct(res);

      const collectionRef = collection(db, 'product');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      const countQuery = query(collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject),
      );


      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };


  useEffect(() => {
    getData();


  }, [globalState?.currentProject, currentPage]);
  // console.log(dataProduct, 'yupi')
  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleGenerateLink = (arg) => {
    console.log(arg);
    try {
      // navigator.clipboard.writeText(`${window.location.host}/checkout?slug=${arg.data.slug}`);
      navigator.clipboard.writeText(`https://deoapp-page-view-qwik.vercel.app/buy/?slug=${arg.data?.slug}`);
      toast({
        description: 'Checkout page link copied!',
        status: 'success',
        duration: 2000,
        position: 'top'
      })
    } catch (error) {
      toast({
        title: 'error',
        description: `${error.message}`,
        status: 'error',
      })
    }
  };

  const tableHeader = ['image', 'name', 'price', 'type', 'stocks', 'sold'];

  let tableData = '';
  tableData = dataProduct?.map((data) => {
    const image = data?.images?.length > 0 ? data?.images[0] : '';
    const name = data?.name || '';
    const price = data?.price || '';
    const stocks = data?.stocks || '';
    const sold = data?.sold || '';
    const type = data?.type || '';
    // const published = !data?.is_active ? 'draft' : data.is_active;
    // const on_discount = !data?.is_promo ? '-' : data.is_promo;

    return {
      data,
      image,
      name: (
        <Stack spacing={1}>
          <Text fontWeight={500} textTransform={'capitalize'}>
            {name}
          </Text>
          <Text fontSize={9}>{data?.id}</Text>
          <Text fontSize={9}>SKU : {data?.sku}</Text>
        </Stack>
      ),
      sold,
      price,
      stocks,
      type,
      // published,
      // on_discount
    };
  });

  const handleDetail = (x) => {
    navigate(`/products/${x?.data?.id}`)
  }

  const handleBulk = () => {
    navigate('/products/add/bulk-product')
  }

  const deleteData = async (x) => {
    const confirmDelete = window.confirm('If you delete this data you can\'t Undo');
    if (confirmDelete)

      try {
        await deleteDocumentFirebase('product', x.data.id)
        getData()
        toast({
          title: 'Success',
          description: `Delete ${x.data.name} success`,
          status: 'success',
        })
      } catch (error) {
        toast({
          title: 'error',
          description: `${error.message}`,
          status: 'error',
        })
      }
  }

  const arrayToString = (arr) => {
    if (!Array.isArray(arr)) {
      return '';
    }
    return arr.join(', ');
  };

  const age = (time) => {
    const launchingDate = new Date(time.seconds * 1000 + 628);
    const currentTime = new Date();
    const yearDiff = currentTime.getFullYear() - launchingDate.getFullYear();
    const monthDiff = yearDiff * 12 + currentTime.getMonth() - launchingDate.getMonth();

    return monthDiff
  }
  console.log(dataProduct, '90')
  const excelData = dataProduct.map((x) => {
    const string = {
      id: x.id || '',
      categoryId: arrayToString(x?.categoryId) || '',
      companyId: x?.companyId || '',
      createdAt: x?.createdAt || '',
      createdBy: x?.createdBy || '',
      currency: x?.currency || '',
      description: x?.description || '',
      discount_amount: x?.discount_amount || '',
      discount_type: x?.discount_type || '',
      // duration: x?.duration || '',
      external_productId: x?.external_productId || '',
      external_product_skuId: x?.external_product_skuId || '',
      Age: x.launchingDate ? age(x.launchingDate) : '',
      images: arrayToString(x?.images) || '',
      is_active: x?.is_active || '',
      is_promo: x?.is_promo || '',
      label: x?.label || '',
      modules: arrayToString(x?.modules) || '',
      name: x?.name || '',
      pipelineId: x?.pipelineId || '',
      pixelId: x?.pixelId || '',
      price: x?.price || '',
      price_high: x?.price_high || '',
      price_high_int: x?.price_high_int || '',
      price_int: x?.price_int || '',
      price_low: x?.price_low || '',
      price_low_int: x?.price_low_int || '',
      product_skuId: x?.product_skuId || '',
      projectId: x?.projectId || '',
      shipping: x?.shipping || '',
      slug: x?.slug || '',
      sold: x?.sold || '',
      sold_int: x?.sold_int || '',
      stocks: x?.stocks || '',
      stocks_int: x?.stocks_int || '',
      tags: arrayToString(x?.tags) || '',
      type: x?.type || '',
      warehouseId: x?.warehouseId || '',
    }
    return string
  })

  return (
    <Box>
      <Stack py={2}>
        <HStack gap={5}>
          <BackButtons />
          <Text fontSize={'xl'} fontWeight={500}>
            Product
          </Text>
          <Spacer />
          <DynamicButton action={'create'} onClick={onOpen} title={'Add Product'} />
          <DynamicButton action={'create'} onClick={handleBulk} title={'Bulk Add Product'} />
          {/* <ExportExcel excelData={dataProduct} fileName={'Data Produk'} size={'md'} constiant={'outline'} colorScheme={'blue'} title={'Print'} /> */}
          <ComponentExportExcel fileName={'Data Produk'} excelData={excelData} />
        </HStack>

        <Divider />
      </Stack>

      <Box>
        <DynamicTable
          header={tableHeader}
          data={tableData}
          onDelete={deleteData}
          // onRead={openModalDeleteRole}
          onEdit={handleDetail}
          onCustom={handleGenerateLink}
          action_color='green'
          action_icon={PiShareBold}
          editTooltip={'Edit product-constiant details'}
          customTooltip={'Generate checkout link'}
          deleteTooltip={'Delete this product'}
        />

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Box>





      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" placeholder='Product name' onChange={(e) => setName(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={createNewProduct}
              title="Save"
              constiant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Box>
  );
};

export default ProductPageV2;
