/* eslint-disable react/prop-types */
import { Badge, Box, Button, Fade, Heading, Stack } from '@chakra-ui/react'
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd';
import { getCollectionFirebase, getCollectionFirebaseV2, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import LineTaskCardComponent from '../Card/LineTaskCardComponent';

// const ColumnColorScheme = {
//   TODOS: 'blue',
//   BACKLOG: 'red',
//   PROGRESS: 'green',
//   REVIEW: 'yellow',
//   DONE: 'gray',
// };

function KanbanColumnsComponent({
  allowedDropEffect,
  column,
  filterData,
  index,
  handleModalOpen,
  typesense,
  query: queryArray,
}) {
  const [columnsData, setColumnsData] = useState([])
  const [columnsData2, setColumnsData2] = useState([])
  // const [queryTypesense, setQueryTypesense] = useState('');
  // const [uid, setUid] = useState([]);
  // const [pipelineDetails, setPipelineDetails] = useState({})
  const pipelineDetails = [];
  // const param = useParams()
  // console.log(uid, 'pppp')
  // const navigate = useNavigate()

  const globalState = useUserStore();


  const handleLoad = () => {
    const conditions = [
      { field: 'column', operator: '==', value: column },
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = 5;
    let startAfter = ''

    if (columnsData2.length > 0) {
      startAfter = columnsData2[columnsData2.length - 1].lastUpdated
      if (filterData?.status) conditions.push({ field: 'status', operator: '==', value: filterData?.status })

    } else {

      startAfter = columnsData[columnsData.length - 1].lastUpdated
      if (filterData?.category)
        conditions.push({ field: 'status', operator: '==', value: filterData?.status })
    }


    getCollectionFirebaseV2('customer', { conditions }, { sortBy }, { limitValue }, { startAfterData: startAfter })
      .then((x) => {
        const updateData = [...columnsData2, ...x]
        setColumnsData2(updateData)
      })

  }

  const handleTypesenseSearch = async (q) => {
    let queryTypesense = queryArray?.length > 0 ?  queryArray?.map((x) => `${x.field}:${x?.operator} ${x.value} `).join(' && '): '';
    queryTypesense += `&& projectId:=${globalState.currentProject}`;
    console.log(queryTypesense, 'queryTypesense changed');
    try {
      const searchParameters = {
        q: q ? q : '*',
        query_by: 'name',
        filter_by: queryTypesense,
        sort_by: '_text_match:desc',
        // per_page: q ? 8 : itemsPerPage,
        // page: q ? 1 : currentPage,
      };

      const x = await clientTypessense 
        .collections('customer')
        .documents() 
        .search(searchParameters);

      const newData = x?.hits?.map((y) => {
        return { ...y.document };
      });
      if(typeof queryTypesense === 'string' && queryTypesense?.length > 0) {
        setColumnsData(newData)
      }
    } catch (error) {
      console.log(error.message,);
    }
  };

  useEffect(() => {
    let unsubscribe = () => { };
    if (typesense) {
      handleTypesenseSearch('');
      return () => {
        setColumnsData([]);
        setColumnsData2([]);
      };
    }

    if (!filterData?.search) {
      const fetchData = async () => {
        const conditionsProduct = [
          { field: 'companyId', operator: '==', value: globalState.currentCompany },
          { field: 'projectId', operator: '==', value: globalState.currentProject },
        ];

        // get the products that has the desired tag string
        const products = await getCollectionFirebase('product', conditionsProduct);
        const productIds = products.map((product) => product.id);

        // get orders which contains the product id's
        const orderPromises = productIds?.map(async (productId) => {
          const orders = await getCollectionFirebase('orders', [{ field: 'productIds', operator: 'array-contains', value: productId }]);
          const userIds = orders?.map((order) => order.userId);
          return [...new Set(userIds)]?.filter((userId) => userId !== '');
        });

        const uniqueUserIdsArray = await Promise.all(orderPromises);
        let collectionRef = query(
          collection(db, 'customer'), 
          where('column', '==', column), 
          where('companyId', '==', globalState.currentCompany), 
          where('projectId', '==', globalState.currentProject), 
        );

        if (uniqueUserIdsArray[1] && uniqueUserIdsArray[1]?.length > 0) {
          collectionRef = query(collectionRef, where('uid', 'in', uniqueUserIdsArray[1]));
        } 

        unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
          const addTask = [];
          querySnapshot.forEach((doc) => {
            addTask.push({ id: doc.id, ...doc.data() });
          });
          setColumnsData(addTask);
        });
      };
      fetchData();
    }

    return () => {
      setColumnsData([]);
      setColumnsData2([]);
    };
  }, [filterData, globalState.currentProject, queryArray]);

  // useEffect(() => {
  //   if(queryArray) {
  //     const x = queryArray?.length > 0 ?  queryArray?.map((x) => `${x.field}:${x?.operator} ${x.value} `).join(' && '): '';
  //     setQueryTypesense(x);
  //   }
  // },[queryArray])
  const arrayToString = (arr) => {
    if (!Array.isArray(arr)) {
      return '';
    }
    return arr.join(', ');
  };
  const secondToDate = (time) => {
    const date = new Date(time?.seconds * 1000);
    console.log(date,'dates')
    const formattedDate = date?.toISOString()?.split('T')[0]; 
    return formattedDate
  }
  const excelData = columnsData?.map((x) => {
    const string = {
      id: x.id || '',
      name: x?.name || '',
      phone_number: x?.phone_number || '',
      uid: x?.phone_number || '',
      address: `${x?.address?.street || ''}, ${x?.address?.postal_code || ''}, ${x?.address?.city || ''}, ${x?.address?.state || ''}, ${x?.address?.country || ''}`,
      companyId: x?.companyId || '',
      // createdAt: secondToDate(x?.createdAt) || '',
      createdBy: x?.createdBy || '',
      average_order_value: x?.average_order_value || '',
      column: x?.column || '',
      email: x?.email || '',
      last_checkout_value: x?.last_checkout_value || '',
      last_transaction_amount: x?.last_transaction_amount || '',
      // lastUpdated: secondToDate(x?.lastUpdated) || '',
      // last_checkout: secondToDate(x?.last_checkout) || '',
      // last_transaction: secondToDate(x?.last_transaction) || '',
      total_transaction: x?.total_transaction || '',
      orderIds: arrayToString(x?.modules) || '',
      projectId: x?.projectId || '',
      
    }
    return string
  })

  // useEffect(() => {
  //   sendDataToParent(excelData);
  // }, []);



  useEffect(() => {
    const myStage = pipelineDetails?.stages?.find((x) => x?.stageName === column);
    const fetchColumn = async () => {
      if (myStage?.customers){
        const dataCustomers = await Promise.all(
          myStage?.customers.map(async (customerId) => await getSingleDocumentFirebase('customer', customerId))
        );
        setColumnsData(dataCustomers);
      }
    }
    fetchColumn();
  },[])

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'column',
      drop: () => ({
        name: column,
        allowedDropEffect,
      }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect],
  )

  const ColumnTasks = (datas, type, handleModalOpen) => datas?.map((x, index) => {
    // console.log(x, 'ini datanya di columntask')
    if (x.id)
      return (
        <Fade in={true} initialscale={0.9} key={index}>
          <LineTaskCardComponent
            key={index}
            task={x}
            indexNumber={index}
            setData={type === 'snapshot' ? setColumnsData : setColumnsData2}
            columnsData={datas}
            handleModalOpen={handleModalOpen}
          />
        </Fade>
      )
  });

  return (
    <Box w='xs' m='1' >
      <Heading fontSize="md" mb={1} letterSpacing="wide" textAlign='center'>
        <Badge
          w='full'
          fontSize='xl'
          px={2}
          py={1}
          rounded="lg"
          bgColor={`green.${index ? index * 100 : 50}`}
          color='black'
        >
          {column}
        </Badge>
      </Heading>

      <Stack
        ref={drop}
        direction='column'
        h={{ base: '30vh', md: '80vh' }}
        p={4}
        mt={2}
        spacing={4}
        bgColor={isOver ? 'red' : 'none'}
        rounded="lg"
        boxShadow="md"
        overflow="auto"
        opacity={isOver ? 0.85 : 1}
      >
        {ColumnTasks(columnsData, 'snapshot', handleModalOpen)}
        {columnsData2?.length ? ColumnTasks(columnsData2, 'manual', handleModalOpen) : <></>}
        {columnsData?.length > 4 && columnsData2?.length === 0 ?
          <Button onClick={() => handleLoad()}> Load more</Button>
          :
          columnsData2?.length > 4 ?
            <Button onClick={() => handleLoad()}> Load more</Button>
            :
            <></>
        }
      </Stack>
    </Box >
  )
}

export default KanbanColumnsComponent