import { create } from 'zustand';

const useKanbanStore = create((set, get) => ({
  //initial state
  userDisplay: [],
  userObject: {},
  taskData: {},
  newCardId: '',
  filterData: { asignee: '', category: '', label: '' },
  functionAddData: function () {},

  //function dispatch reducer
  setUserDisplay: (data) => {
    set({
      userDisplay: data,
    });
  },
  setUserObject: (data) => {
    set({
      userObject: {
        ...get().userObject,
        ...data,
      },
    });
  },
  setFunctionAddData: (func) => {
    set({
      setData: func,
    });
  },
  setTaskData: (data) => {
    set({
      taskData: {
        ...get().taskData,
        ...data,
      },
    });
  },
  setNewCardId: (str) => {
    set({
      newCardId: str,
    });
  },
  resetTaskData: () => {
    set({
      taskData: {},
    });
  },
  setFilterData: (data) => {
    set({
      filterData: {
        ...get().filterData,
        ...data,
      },
    });
  },
  resetFilterData: () => {
    set({
      filterData: {
        asignee: '',
        label: '',
        category: '',
      },
    });
  },
}));

export default useKanbanStore;
