/* eslint-disable react/no-unknown-property */
import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'

const Product3 = ({ modeBG, textColor }) => {
  const [openModal, setOpenModal] = useState(false)
  const [openSort, setOpenSort] = useState(false)
  return (
    <div className={
      modeBG
        ? 'text-white'
        : ' text-gray-900'
    }
    style={{ color: textColor }}
    >
      <div>
        {openModal.value ?
          <div className='relative z-40 lg:hidden' role='dialog' aria-modal='true'>
            <div className='fixed inset-0 z-40 flex '>
              <div className={
                modeBG
                  ? ' relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto  py-4 pb-12 shadow-xl'
                  : ' bg-white relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto  py-4 pb-12 shadow-xl'
              }>
                <div className='flex items-center justify-between px-4'>
                  <h2 className='text-lg font-medium '>Filters</h2>
                  <button onClick$={(prev) => setOpenModal(!prev)} type='button' className='-mr-2 flex h-10 w-10 items-center justify-center rounded-md  p-2 '>
                    <span className='sr-only'>Close menu</span>
                    <svg className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' aria-hidden='true'>
                      <path stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' />
                    </svg>
                  </button>
                </div>

                <form className='mt-4 border-t border-gray-200'>
                  <h3 className='sr-only'>Categories</h3>
                  <ul role='list' className='px-2 py-3 font-medium '>
                    <li>
                      <a href='#' className='block px-2 py-3'>Totes</a>
                    </li>
                    <li>
                      <a href='#' className='block px-2 py-3'>Backpacks</a>
                    </li>
                    <li>
                      <a href='#' className='block px-2 py-3'>Travel Bags</a>
                    </li>
                    <li>
                      <a href='#' className='block px-2 py-3'>Hip Bags</a>
                    </li>
                    <li>
                      <a href='#' className='block px-2 py-3'>Laptop Sleeves</a>
                    </li>
                  </ul>

                  <div className='border-t border-gray-200 px-4 py-6'>
                    <h3 className='-mx-2 -my-3 flow-root'>

                      <button type='button' className='flex w-full items-center justify-between  px-2 py-3  hover:text-gray-500' aria-controls='filter-section-mobile-0' aria-expanded='false'>
                        <span className='font-medium '>Color</span>
                        <span className='ml-6 flex items-center'>

                          <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                          </svg>

                          <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path fill-rule='evenodd' d='M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z' clip-rule='evenodd' />
                          </svg>
                        </span>
                      </button>
                    </h3>

                    <div className='pt-6' id='filter-section-mobile-0'>
                      <div className='space-y-6'>
                        <div className='flex items-center'>
                          <input id='filter-mobile-color-0' name='color[]' value='white' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-color-0' className='ml-3 min-w-0 flex-1'>White</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-color-1' name='color[]' value='beige' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-color-1' className='ml-3 min-w-0 flex-1'>Beige</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-color-2' name='color[]' value='blue' type='checkbox' checked className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-color-2' className='ml-3 min-w-0 flex-1'>Blue</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-color-3' name='color[]' value='brown' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-color-3' className='ml-3 min-w-0 flex-1'>Brown</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-color-4' name='color[]' value='green' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-color-4' className='ml-3 min-w-0 flex-1'>Green</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-color-5' name='color[]' value='purple' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-color-5' className='ml-3 min-w-0 flex-1'>Purple</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='border-t border-gray-200 px-4 py-6'>
                    <h3 className='-mx-2 -my-3 flow-root'>
                      <button type='button' className='flex w-full items-center justify-between  px-2 py-3  hover:text-gray-500' aria-controls='filter-section-mobile-1' aria-expanded='false'>
                        <span className='font-medium '>Category</span>
                        <span className='ml-6 flex items-center'>
                          <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                          </svg>
                          <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path fill-rule='evenodd' d='M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z' clip-rule='evenodd' />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div className='pt-1' id='filter-section-mobile-1'>
                      <div className='space-y-6'>
                        <div className='flex items-center'>
                          <input id='filter-mobile-category-0' name='category[]' value='new-arrivals' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-category-0' className='ml-3 min-w-0 flex-1'>New Arrivals</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-category-1' name='category[]' value='sale' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-category-1' className='ml-3 min-w-0 flex-1'>Sale</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-category-2' name='category[]' value='travel' type='checkbox' checked className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-category-2' className='ml-3 min-w-0 flex-1'>Travel</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-category-3' name='category[]' value='organization' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-category-3' className='ml-3 min-w-0 flex-1'>Organization</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-category-4' name='category[]' value='accessories' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-category-4' className='ml-3 min-w-0 flex-1'>Accessories</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='border-t border-gray-200 px-4 py-6'>
                    <h3 className='-mx-2 -my-3 flow-root'>
                      <button type='button' className='flex w-full items-center justify-between  px-2 py-3  hover:text-gray-500' aria-controls='filter-section-mobile-2' aria-expanded='false'>
                        <span className='font-medium '>Size</span>
                        <span className='ml-6 flex items-center'>
                          <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                          </svg>
                          <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path fill-rule='evenodd' d='M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z' clip-rule='evenodd' />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div className='pt-1' id='filter-section-mobile-2'>
                      <div className='space-y-6'>
                        <div className='flex items-center'>
                          <input id='filter-mobile-size-0' name='size[]' value='2l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-size-0' className='ml-3 min-w-0 flex-1 '>2L</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-size-1' name='size[]' value='6l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-size-1' className='ml-3 min-w-0 flex-1 '>6L</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-size-2' name='size[]' value='12l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-size-2' className='ml-3 min-w-0 flex-1 '>12L</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-size-3' name='size[]' value='18l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-size-3' className='ml-3 min-w-0 flex-1 '>18L</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-size-4' name='size[]' value='20l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-size-4' className='ml-3 min-w-0 flex-1 '>20L</label>
                        </div>
                        <div className='flex items-center'>
                          <input id='filter-mobile-size-5' name='size[]' value='40l' type='checkbox' checked className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                          <label htmlFor='filter-mobile-size-5' className='ml-3 min-w-0 flex-1 '>40L</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          : <></>}

        <main className={modeBG ? 'mx-auto max-w-7xl px-4 sm text-gray-500 capitalize:px-6 lg:px-8 text-white' : 'mx-auto max-w-7xl px-4 sm text-gray-500 capitalize:px-6 lg:px-8 '}>
          <div className='flex items-baseline justify-between border-b border-gray-200 pb-6 pt-2'>
            <h1 className='text-4xl font-bold tracking-tight '>New Arrivals</h1>

            <div className='flex items-center'>
              <div className='relative inline-block text-left'>
                <div>
                  <button onClick$={(prev) => setOpenSort(!prev)} type='button' className='group inline-flex justify-center text-sm text-gray-500 capitalize font-medium h' id='menu-button' aria-expanded='false' aria-haspopup='true'>
                    Sort
                    <svg className='-mr-1 ml-1 h-5 w-5 flex-shrink-0 ' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                      <path fill-rule='evenodd' d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z' clip-rule='evenodd' />
                    </svg>
                  </button>
                </div>
                {openSort ?
                  <div className='absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md  shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-300' role='menu' aria-orientation='vertical' aria-labelledby='menu-button' tabIndex='-1'>
                    <div className='py-1' role='none'>
                      <a href='#' className='font-medium  block px-4 py-2 text-sm text-gray-500 capitalize' role='menuitem' tabIndex='-1' id='menu-item-0'>Most Popular</a>
                      <a href='#' className=' block px-4 py-2 text-sm text-gray-500 capitalize' role='menuitem' tabIndex='-1' id='menu-item-1'>Best Rating</a>
                      <a href='#' className=' block px-4 py-2 text-sm text-gray-500 capitalize' role='menuitem' tabIndex='-1' id='menu-item-2'>Newest</a>
                      <a href='#' className=' block px-4 py-2 text-sm text-gray-500 capitalize' role='menuitem' tabIndex='-1' id='menu-item-3'>Price: Low to High</a>
                      <a href='#' className=' block px-4 py-2 text-sm text-gray-500 capitalize' role='menuitem' tabIndex='-1' id='menu-item-4'>Price: High to Low</a>
                    </div>
                  </div> : <></>}
              </div>

              <button type='button' className='-m-2 ml-5 p-2  sm text-gray-500 capitalize:ml-7'>
                <span className='sr-only'>View grid</span>
                <svg className='h-5 w-5' aria-hidden='true' viewBox='0 0 20 20' fill='currentColor'>
                  <path fill-rule='evenodd' d='M4.25 2A2.25 2.25 0 002 4.25v2.5A2.25 2.25 0 004.25 9h2.5A2.25 2.25 0 009 6.75v-2.5A2.25 2.25 0 006.75 2h-2.5zm0 9A2.25 2.25 0 002 13.25v2.5A2.25 2.25 0 004.25 18h2.5A2.25 2.25 0 009 15.75v-2.5A2.25 2.25 0 006.75 11h-2.5zm9-9A2.25 2.25 0 0011 4.25v2.5A2.25 2.25 0 0013.25 9h2.5A2.25 2.25 0 0018 6.75v-2.5A2.25 2.25 0 0015.75 2h-2.5zm0 9A2.25 2.25 0 0011 13.25v2.5A2.25 2.25 0 0013.25 18h2.5A2.25 2.25 0 0018 15.75v-2.5A2.25 2.25 0 0015.75 11h-2.5z' clip-rule='evenodd' />
                </svg>
              </button>

              <button onClick$={() => openModal.value = !openModal.value} type='button' className='-m-2 ml-4 p-2 sm text-gray-500 capitalize:ml-6 lg:hidden'>
                <span className='sr-only'>Filters</span>
                <svg className='h-5 w-5' aria-hidden='true' viewBox='0 0 20 20' fill='currentColor'>
                  <path fill-rule='evenodd' d='M2.628 1.601C5.028 1.206 7.49 1 10 1s4.973.206 7.372.601a.75.75 0 01.628.74v2.288a2.25 2.25 0 01-.659 1.59l-4.682 4.683a2.25 2.25 0 00-.659 1.59v3.037c0 .684-.31 1.33-.844 1.757l-1.937 1.55A.75.75 0 018 18.25v-5.757a2.25 2.25 0 00-.659-1.591L2.659 6.22A2.25 2.25 0 012 4.629V2.34a.75.75 0 01.628-.74z' clip-rule='evenodd' />
                </svg>
              </button>
            </div>
          </div>

          <section aria-labelledby='products-heading' className='pb-24 pt-6'>
            <h2 id='products-heading' className='sr-only'>Products</h2>

            <div className='grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4'>
              <form className='hidden lg:block'>
                <h3 className='sr-only'>Categories</h3>
                <ul role='list' className='space-y-4 border-b border-gray-200 pb-6 text-sm text-gray-500 capitalize font-medium '>
                  <li>
                    <a href='#'>Totes</a>
                  </li>
                  <li>
                    <a href='#'>Backpacks</a>
                  </li>
                  <li>
                    <a href='#'>Travel Bags</a>
                  </li>
                  <li>
                    <a href='#'>Hip Bags</a>
                  </li>
                  <li>
                    <a href='#'>Laptop Sleeves</a>
                  </li>
                </ul>

                <div className='border-b border-gray-200 py-6'>
                  <h3 className='-my-3 flow-root'>
                    <button type='button' className='flex w-full items-center justify-between  py-3 text-sm text-gray-500 capitalize hover:text-gray-500' aria-controls='filter-section-0' aria-expanded='false'>
                      <span className='font-medium'>Color</span>
                      <span className='ml-6 flex items-center'>
                        <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                          <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                        </svg>
                        <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                          <path fill-rule='evenodd' d='M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z' clip-rule='evenodd' />
                        </svg>
                      </span>
                    </button>
                  </h3>
                  <div className='pt-6' id='filter-section-0'>
                    <div className='space-y-4'>
                      <div className='flex items-center'>
                        <input id='filter-color-0' name='color[]' value='white' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-color-0' className='ml-3 text-sm text-gray-500 capitalize '>White</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-color-1' name='color[]' value='beige' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-color-1' className='ml-3 text-sm text-gray-500 capitalize'>Beige</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-color-2' name='color[]' value='blue' type='checkbox' checked className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-color-2' className='ml-3 text-sm text-gray-500 capitalize '>Blue</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-color-3' name='color[]' value='brown' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-color-3' className='ml-3 text-sm text-gray-500 capitalize '>Brown</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-color-4' name='color[]' value='green' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-color-4' className='ml-3 text-sm text-gray-500 capitalize '>Green</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-color-5' name='color[]' value='purple' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-color-5' className='ml-3 text-sm text-gray-500 capitalize '>Purple</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='border-b border-gray-200 py-6'>
                  <h3 className='-my-3 flow-root'>
                    <button type='button' className='flex w-full items-center justify-between  py-3 text-sm text-gray-500 capitalize  hover:text-gray-500' aria-controls='filter-section-1' aria-expanded='false'>
                      <span className='font-medium '>Category</span>
                      <span className='ml-6 flex items-center'>
                        <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                          <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                        </svg>
                        <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                          <path fill-rule='evenodd' d='M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z' clip-rule='evenodd' />
                        </svg>
                      </span>
                    </button>
                  </h3>
                  <div className='pt-6' id='filter-section-1'>
                    <div className='space-y-4'>
                      <div className='flex items-center'>
                        <input id='filter-category-0' name='category[]' value='new-arrivals' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-category-0' className='ml-3 text-sm text-gray-500 capitalize '>New Arrivals</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-category-1' name='category[]' value='sale' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-category-1' className='ml-3 text-sm text-gray-500 capitalize '>Sale</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-category-2' name='category[]' value='travel' type='checkbox' checked className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-category-2' className='ml-3 text-sm text-gray-500 capitalize '>Travel</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-category-3' name='category[]' value='organization' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-category-3' className='ml-3 text-sm text-gray-500 capitalize '>Organization</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-category-4' name='category[]' value='accessories' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-category-4' className='ml-3 text-sm text-gray-500 capitalize '>Accessories</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='border-b border-gray-200 py-6'>
                  <h3 className='-my-3 flow-root'>
                    <button type='button' className='flex w-full items-center justify-between py-3 text-sm text-gray-500 capitalize  hover:text-gray-500' aria-controls='filter-section-2' aria-expanded='false'>
                      <span className='font-medium '>Size</span>
                      <span className='ml-6 flex items-center'>
                        <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                          <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                        </svg>
                        <svg className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                          <path fill-rule='evenodd' d='M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z' clip-rule='evenodd' />
                        </svg>
                      </span>
                    </button>
                  </h3>
                  <div className='pt-6' id='filter-section-2'>
                    <div className='space-y-4'>
                      <div className='flex items-center'>
                        <input id='filter-size-0' name='size[]' value='2l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-size-0' className='ml-3 text-sm text-gray-500 capitalize'>2L</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-size-1' name='size[]' value='6l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-size-1' className='ml-3 text-sm text-gray-500 capitalize'>6L</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-size-2' name='size[]' value='12l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-size-2' className='ml-3 text-sm text-gray-500 capitalize'>12L</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-size-3' name='size[]' value='18l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-size-3' className='ml-3 text-sm text-gray-500 capitalize'>18L</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-size-4' name='size[]' value='20l' type='checkbox' className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-size-4' className='ml-3 text-sm text-gray-500 capitalize'>20L</label>
                      </div>
                      <div className='flex items-center'>
                        <input id='filter-size-5' name='size[]' value='40l' type='checkbox' checked className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500' />
                        <label htmlFor='filter-size-5' className='ml-3 text-sm text-gray-500 capitalize'>40L</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className='sm text-gray-500 capitalize:col-span-2 lg:col-span-3'>
                <div className=''>
                  <div className='mx-auto max-w-7xl overflow-hidden sm text-gray-500 capitalize:px-6 lg:px-8'>
                    <h2 className='sr-only'>Products</h2>
                    <div className='-mx-px grid grid-cols-2 border-l border-gray-200 sm text-gray-500 capitalize:mx-0 md:grid-cols-3 lg:grid-cols-4'>


                      <div className='group relative border-b border-r border-gray-200 p-4 sm text-gray-500 capitalize:p-6'>
                        <div className='aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75'>
                          <img src={'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg'} alt='TODO' className='h-full w-full object-cover object-center' />
                        </div>
                        <div className='pb-4 pt-10 text-center'>
                          <h3 className='text-sm text-gray-500 capitalize font-medium '>
                            <a href={'#'}>
                              <span aria-hidden='true' className='absolute inset-0'></span>
                              Dummy Product
                            </a>
                          </h3>
                          <div className='mt-3 flex flex-col items-center'>
                            <p className='sr-only'>5 out of 5 stars</p>
                            <div className='flex items-center'>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                            </div>
                            <p className='mt-1 text-sm text-gray-500 capitalize text-gray-500'>99 reviews</p>
                          </div>
                          {/* <p className='mt-4 text-base font-medium'>{idrDecimalHelper(x.price) }</p> */}
                        </div>
                      </div>

                      <div className='group relative border-b border-r border-gray-200 p-4 sm text-gray-500 capitalize:p-6'>
                        <div className='aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75'>
                          <img src={'https://tailwindui.com/img/ecommerce-images/category-page-01-image-card-01.jpg'} alt='TODO' className='h-full w-full object-cover object-center' />
                        </div>
                        <div className='pb-4 pt-10 text-center'>
                          <h3 className='text-sm text-gray-500 capitalize font-medium '>
                            <a href={'#'}>
                              <span aria-hidden='true' className='absolute inset-0'></span>
                              Dummy Product
                            </a>
                          </h3>
                          <div className='mt-3 flex flex-col items-center'>
                            <p className='sr-only'>5 out of 5 stars</p>
                            <div className='flex items-center'>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                              <svg className='text-yellow-400 h-5 w-5 flex-shrink-0' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                                <path fill-rule='evenodd' d='M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z' clip-rule='evenodd' />
                              </svg>
                            </div>
                            <p className='mt-1 text-sm text-gray-500 capitalize text-gray-500'>99 reviews</p>
                          </div>
                          {/* <p className='mt-4 text-base font-medium'>{idrDecimalHelper(x.price) }</p> */}
                        </div>
                      </div>

                      {/* <!-- More products... --> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  )
}

export default Product3


Product3.propTypes = {
  modeBG: PropTypes.string, textColor: PropTypes.string,
}