import { HStack, Heading, SimpleGrid, Stack, Text, useColorMode } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { PiMoney } from 'react-icons/pi';
import { TbLocationDollar } from 'react-icons/tb';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { NumberAcronym } from '../../../Utils/NumberUtils';
import useUserStore from '../../../Hooks/Zustand/Store';
import { sumDocumentsFieldFirebase } from '../../../Api/firebaseApi';



function RevanueAnalytics() {

  const { colorMode } = useColorMode();
  const globalState = useUserStore();
  const [grossRevenue, setGrossRevenue] = useState([])
  const [shippingCost, setShippingCost] = useState([])
  const [netRevenue, setNetRevenue] = useState([])

  const getData = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'paymentStatus', operator: '==', value: 'PAID' },
    ];
    const conditionsShipping = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'resi', operator: '!=', value: ''},
    ];
    try {
      // const res = await getCollectionFirebase('orders', conditions);
      // const ship = await getCollectionFirebase('shipping', conditionsShipping)

      // let grossSum = 0;

      // for( let i = 0; i< res.length; i++){
      //   grossSum += res[i].amount
      // }

      // let shippingSum = 0;

      // for (let i = 0; i < ship.length; i++) {
      //   if (typeof ship[i].shipping_price === 'string' && ship[i].shipping_price.trim() !== '') {
      //     shippingSum += parseInt(ship[i].shipping_price);
      //   } else if (typeof ship[i].shipping_price === 'number') {
      //     shippingSum += ship[i].shipping_price;
      //   }
      // }

      const grossSum = await sumDocumentsFieldFirebase('orders', 'amount', conditions);
      const shippingSum = await sumDocumentsFieldFirebase('shipping', 'shipping_price', conditionsShipping);
      const net = grossSum - shippingSum;
      setGrossRevenue(grossSum)
      setShippingCost(shippingSum)
      setNetRevenue(net)
    } catch (error) {
      console.log('error :', error)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 2000)
  }, [globalState.currentCompany, globalState.currentProject])

  return (
    <Stack>
      <SimpleGrid columns={[1, 3, 3]} gap={5}>
        <Stack>
          <Stack bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} shadow={3} p={5} spacing={5} justifyContent='center' borderRadius='xl'>
            <HStack>
              <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                <PiMoney size={'20px'} color='white' />
              </Stack>
              <Text color={'gray.500'}>Gross Revenue</Text>
            </HStack>
            <Stack>
              <Heading>{NumberAcronym(grossRevenue)}</Heading>
            </Stack>

            <Stack alignItems={'flex-end'} justifyContent='flex-end'>
              {/* <Text fontSize={'sm'} color='blue.400'>See Section</Text> */}
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Stack bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} shadow={3} p={5} spacing={5} justifyContent='center' borderRadius='xl'>
            <HStack>
              <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                <LiaShippingFastSolid size={'20px'} color='white' />
              </Stack>
              <Text color={'gray.500'}>Shipping Cost</Text>
            </HStack>
            <Stack>
              <Heading>{NumberAcronym(shippingCost)}</Heading>
            </Stack>

            <Stack alignItems={'flex-end'} justifyContent='flex-end'>
              {/* <Text fontSize={'sm'} color='blue.400'>See Section</Text> */}
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Stack shadow={3} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} p={5} spacing={5} justifyContent='center' borderRadius='xl'>
            <HStack>
              <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                <TbLocationDollar size={'20px'} color='white' />
              </Stack>
              <Text color={'gray.500'}>Net Revenue</Text>
            </HStack>
            <Stack>
              <Heading>{NumberAcronym(netRevenue)}</Heading>
            </Stack>

            <Stack alignItems={'flex-end'} justifyContent='flex-end'>
              {/* <Text fontSize={'sm'} color='blue.400'>See Section</Text> */}
            </Stack>
          </Stack>
        </Stack>

        {/* <Stack>
          <Stack shadow={3} p={5} spacing={5} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} justifyContent='center' borderRadius='xl'>
            <HStack>
              <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                <IoWalletOutline size={'20px'} color='white' />
              </Stack>
              <Text color={'gray.500'}>Gross Profit</Text>
            </HStack>
            <Stack>
              <Heading>{NumberAcronym(3000000)}</Heading>
            </Stack>

            <Stack alignItems={'flex-end'} justifyContent='flex-end'>
              <Text fontSize={'sm'} color='blue.400'>See Section</Text>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Stack shadow={3} p={5} spacing={5} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} justifyContent='center' borderRadius='xl'>
            <HStack>
              <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                <AiOutlineDollar size={'20px'} color='white' />
              </Stack>
              <Text color={'gray.500'}>Net Profit</Text>
            </HStack>
            <Stack>
              <Heading>{NumberAcronym(20000000)}</Heading>
            </Stack>

            <Stack alignItems={'flex-end'} justifyContent='flex-end'>
              <Text fontSize={'sm'} color='blue.400'>See Section</Text>
            </Stack>
          </Stack>
        </Stack> */}
      </SimpleGrid>
    </Stack>
  )
}

export default RevanueAnalytics