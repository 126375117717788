import { Blog1 } from './Blog/Blog1';
import { Checkout1 } from './Product/Checkout1';
import { Hero1 } from './Hero/Hero1';
import { Hero2 } from './Hero/Hero2';

import { Product1 } from './Product/Product1';
import { Product2 } from './Product/Product2';
import { ProductView1 } from './Product/ProductView1';
import { Navbar1 } from './Navbar/Navbar1';
import { Navbar2 } from './Navbar/Navbar2';
import { Navbar3 } from './Navbar/Navbar3';
import { Hero3 } from './Hero/Hero3';
import Header1 from './Navbar/Header1';
import Header2 from './Navbar/Header2';
import Product3 from './Product/Product3';
import { BlogDetail1 } from './Blog/BlogDetail1';
import Course1 from './Course/Course1';
import Category2 from './Product/Category2';
import { ProductView2 } from './Product/ProductView2';
import { CourseDetail1 } from './Course/CourseDetail1';
import React from 'react';
import HeroSlider from './Hero/HeroSlider';
import ProductHighlight1 from './Product/ProductHighlight1';
import LogoFeatures1 from './Features/LogoFeatures1';
import Hero6 from './Hero/Hero6';
import ProductFeature2 from './Product/ProductFeature2';
import Cart1 from './Cart/Cart1';
import Hero7 from './Hero/Hero7';
import { Product4 } from './Product/Product4';
import { Product5 } from './Product/Product5';
import { Product6 } from './Product/Product6';
import { Product7 } from './Product/Product7';
import { ProductCountdown } from './Product/ProductCountdown1';

export const data = [
  {
    title: 'Home Page',
    isCustomize: true,
    templates: [
      {
        title: 'Home 1',
        file: <Hero1 />,
        id: 'Hero1',
        image: 'https://raw.githubusercontent.com/marcosorive/react-responsive-navbar-overlay/HEAD/screenshots/expanded.PNG',
      },
      {
        title: 'Home 2',
        file: <Hero2 />,
        id: 'Hero2',
        image: 'https://raw.githubusercontent.com/marcosorive/react-responsive-navbar-overlay/HEAD/screenshots/expanded.PNG',
      },
    ],
  },
  {
    title: 'Product Page',
    isCustomize: false,
    templates: [
      {
        title: 'Product 1',
        file: <Product1 />,
        id: 'Product1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product1.png',
      },
      {
        title: 'Product 2',
        file: <Product2 />,
        id: 'Product2',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product2.png',
      },
      {
        title: 'Product 3',
        file: <Product3 />,
        id: 'ProductsFilterComponent',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product3.png',
      },
      {
        title: 'Product 4',
        file: <Product4 />,
        id: 'Product4',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product3.png',
      },
      {
        title: 'Product 5',
        file: <Product5 />,
        id: 'Product5',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product3.png',
      },
      {
        title: 'Product 6',
        file: <Product6 />,
        id: 'Product6',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product3.png',
      },
      {
        title: 'Product 7',
        file: <Product7 />,
        id: 'Product7',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Product3.png',
      },
    ],
  },
  {
    title: 'Product Detail',
    isCustomize: false,
    templates: [
      {
        title: 'Product Detail 1',
        file: <ProductView1 />,
        id: 'ProductView1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/ProductDetail1.png',
      },
      {
        title: 'Product Detail 2',
        file: <ProductView2 />,
        id: 'ProductViewComponent',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/ProductDetail2.png',
      },
    ],
  },
  {
    title: 'Category Page',
    isCustomize: false,
    templates: [
      // {
      //     title: 'Category 1',
      //     file: <Category1 />,
      //     id: 'Category1',
      //     image: 'https://raw.githubusercontent.com/marcosorive/react-responsive-navbar-overlay/HEAD/screenshots/expanded.PNG'
      // },
      {
        title: 'Category 1',
        file: <Category2 />,
        id: 'CategoryThreeColumn',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Category1.png',
      },
    ],
  },
  {
    title: 'Blog Page',
    isCustomize: false,
    templates: [
      {
        title: 'Basic Blog',
        file: <Blog1 />,
        id: 'BlogComponent',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Blog1.png',
      },
    ],
  },
  {
    title: 'Blog Detail Page',
    isCustomize: false,
    templates: [
      {
        title: 'Basic Blog',
        file: <BlogDetail1 />,
        id: 'BlogComponent',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/BlogDetail1.png',
      },
    ],
  },
  {
    title: 'Course Page',
    isCustomize: false,
    templates: [
      {
        title: 'Basic Course',
        file: <Course1 />,
        id: 'CourseMainPage',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Course1.png',
      },
    ],
  },
  {
    title: 'Course Detail Page',
    isCustomize: false,
    templates: [
      {
        title: 'With Video',
        file: <CourseDetail1 />,
        id: 'CourseVideoPage',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/CourseDetail1.png',
      },
    ],
  },
  {
    title: 'Checkout Page',
    isCustomize: false,
    templates: [
      {
        title: 'Checkout',
        file: <Checkout1 />,
        id: 'Checkout1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Checkout1.png',
      },
    ],
  },
  {
    title: 'Cart Page',
    isCustomize: false,
    templates: [
      {
        title: 'Cart',
        file: <Cart1 />,
        id: 'Cart1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/Screenshot 2024-03-04 110426.png',
      },
    ],
  },
];

export const dataSecond = [
  {
    title: 'Navbar',
    templates: [
      {
        id: 'Navbar1',
        title: 'Middle',
        file: <Navbar1 />,
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Navbar1.png',
      },
      {
        id: 'Navbar2',
        title: 'Right',
        file: <Navbar2 />,
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Navbar2.png',
      },
      {
        id: 'Navbar3',
        title: 'Left',
        file: <Navbar3 />,
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Navbar3.png',
      },
      {
        id: 'HeaderComponent1',
        title: 'Header 1',
        file: <Header1 />,
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Navbar4.png',
      },
      {
        id: 'HeaderComponent2',
        title: 'Header 2',
        file: <Header2 />,
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Navbar5.png',
      },
    ],
  },
  // {title:'Footer',templates:[{title:'Middle',image:'https://raw.githubusercontent.com/marcosorive/react-responsive-navbar-overlay/HEAD/screenshots/expanded.PNG'},{title:'Left',image:'https://raw.githubusercontent.com/marcosorive/react-responsive-navbar-overlay/HEAD/screenshots/expanded.PNG'},{title:'Right',image:'https://raw.githubusercontent.com/marcosorive/react-responsive-navbar-overlay/HEAD/screenshots/expanded.PNG'}]},
];

export const dataSection = [
  {
    title: 'Hero',
    templates: [
      {
        title: 'Hero 1',
        file: (
          <Hero1
            heading={'test'}
            content={'test'}
          />
        ),
        id: 'Hero1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/hero-simpleCenteredWithBackgroundImage.png',
      },
      {
        title: 'Hero 2',
        file: (
          <Hero2
            heading={'test'}
            content={'test'}
          />
        ),
        id: 'Hero2',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/landingpage/asset/hero-simpleCentered.png',
      },
      {
        title: 'Hero 3',
        file: (
          <Hero3
            heading={'test'}
            content={'test'}
            image={'https://images.unsplash.com/photo-1567532900872-f4e906cbf06a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80'}
          />
        ),
        id: 'Hero3',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Hero3.png',
      },
      {
        title: 'Hero 7',
        file: (
          <Hero7
            heading={'test'}
            content={'test'}
          />
        ),
        id: 'Hero7',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/fullimage3.png',
      },
      // {
      //     title: 'Hero 4',
      //     file: <Hero7 heading={'test'} content={'test'} />,
      //     id: 'Hero7',
      //     image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets/Hero3.png'
      // },
    ],
  },
  {
    title: 'Banner Slider',
    templates: [
      {
        title: 'Banner Slider 1',
        file: <HeroSlider />,
        id: 'BannerSlider1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/slider1.png',
      },
    ],
  },
  {
    title: 'Product Highlights',
    templates: [
      {
        title: 'Product Highlights 2',
        file: (
          <ProductHighlight1
            heading={'What\'s New'}
            content={'test'}
          />
        ),
        id: 'ProductHighlights2',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/producthighlight4grid.png',
      },
      // {
      //   title: 'Product Highlights 3',
      //   file: (
      //     <ProductHighlight2
      //       heading={'What\'s New'}
      //       content={'test'}
      //     />
      //   ),
      //   id: 'ProductHighlights3',
      //   image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/producthighlight8grid.png',
      // },
    ],
  },
  {
    title: 'Sponsor and Logo Highlights',
    templates: [
      {
        title: 'Logo Higlights',
        file: (
          <LogoFeatures1
            heading={'test'}
            content={'test'}
          />
        ),
        id: 'LogoFeatures1',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/Screenshot 2024-02-23 095113.png',
      },
    ],
  },
  {
    title: 'Full Image',
    templates: [
      {
        title: 'Full Image 2',
        file: <Hero6 />,//ini tadinya hero 6
        id: 'FullImage2',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/fullimage2.png',
      },
      {
        title: 'Product Countdown',
        file: <ProductCountdown />,//ini tadinya hero 6
        id: 'ProductCountdown',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/fullimage2.png',
      },
      {
        title: 'Full Image 3',
        file: <ProductFeature2 />,
        id: 'FullImage3',
        image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/template/assets1/fullimage3.png',
      },
    ],
  },
];