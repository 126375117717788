const colors = {
  primary: '#fc5c65',
  secondary: '#FFFFFF',
  black: '#1a202c',
  white: '#fff',
  medium: '#6e6969',
  light: '#d8d8d8',
  dark: '#2B2B2B',
  darklight: '#413F42',
  danger: '#ff5252',
  theme: '#FFD600',
  text: '#00B5D8',
};

const labelsColorChat = [
  {
    name: 'New Customer',
    value: 'newCustomer',
    color: 'blue.400',
  },
  {
    name: 'Repeat Order',
    value: 'repeatOrder',
    color: 'green.400',
  },
  {
    name: 'Pending Payment',
    value: 'pendingPayment',
    color: 'red.400',
  },
  {
    name: 'Paid',
    value: 'paid',
    color: 'yellow.400',
  },
  {
    name: 'Order Complete',
    value: 'orderComplete',
    color: 'purple.400',
  },
  {
    name: 'Important',
    value: 'important',
    color: 'pink.400',
  },
  {
    name: 'Follow Up',
    value: 'followUp',
    color: 'gray.400',
  },
  {
    name: 'Lead',
    value: 'lead',
    color: 'teal.400',
  },
];

export {
  colors,
  labelsColorChat,
};
