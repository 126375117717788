import {
  HStack,
  Heading,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  VStack,
  Tooltip,
  useToast,

} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import { getCollectionFirebase, updateDocumentFirebase } from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import DynamicTable from '../../../Components/Table/DynamicTable';
import moment from 'moment/moment';

const PaymentConfirmations = () => {
  const [payments, setPayments] = useState([]);
  // const [startIndex, setStartIndex] = useState(0);
  const startIndex = 0
  const [detail, setDetail] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alertAction = useDisclosure();
  const cancelRef = React.useRef();
  
  const itemsPerPage = 8;
  const globalState = useUserStore();
  const dataHeader = ['product', 'order id', 'account name', 'transfer to', 'transfer amount', 'transfer date', 'transfer receipt'];
  const toast = useToast();
  const getData = async () => {
    try {
      const conditions = [
        {field : 'companyId', operator : '==', value : globalState?.currentCompany},
        {field : 'paymentService', operator : '==', value : 'manual_transfer'},
      ];
      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;
      const res = await getCollectionFirebase(
        'payments',
        conditions,
        sortBy,
        limitValue
      );
      setPayments(res);
    } catch (error) {
      console.log(error.message, 'err');
    }
  };

  // const handleLoadMore = () => {
  //   setStartIndex((prev) => prev + itemsPerPage);
  // };

  const handleOpenModal = (data) => {
    setDetail(data);
    console.log(data, 'dataa');
    onOpen()
  };
  
  const handleUpdateOrder = async () => {
    try {
      await updateDocumentFirebase('orders', detail?.data?.orderId, {paymentStatus : 'PAID', transfer_receipt : detail?.data?.transfer_receipt });
      toast({
        status :'success',
        description : 'Pembayaran terkonfirmasi. Order telah PAID',
        title : 'Success',
        isClosable : true, 
        position : 'top-right',
        duration : 5000
      });
      onClose();
      alertAction.onClose();
    } catch (error) {
      toast({
        status :'error',
        description : error.message,
        title : 'Ups!',
        isClosable : true, 
        position : 'top-right',
        duration : 5000
      })
    }
  };

  useEffect(() => {
    getData();
  },[globalState?.currentProject])

  const fallbackSrc = 'https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png';

  let tableData = '';
  tableData = payments?.map((data) => {
    const name = data?.contact_information?.name || '';
    return {
      data,
      'account name': name,
      'transfer to' :<Text>{data?.payment_information?.bank} {data?.payment_information?.account_holder} {data?.payment_information?.account_number}</Text>,
      'transfer amount' : data?.amount || 0,
      'transfer date' : moment.unix(data?.lastUpdated.seconds).format('ddd, DD MMM YYYY HH:mm'),
      'order id' : data?.id,
      product : 
      <HStack>
        {data?.items?.map((item, i) => (
          <Tooltip key={i} label={item?.name}>
            <Image
              cursor={'pointer'}
              src={item?.images[0]}
              w={8}
              fallbackSrc={fallbackSrc}
            />
          </Tooltip>
        ))}
      </HStack>,
      'transfer receipt' : 
      <Image
        cursor={'pointer'}
        src={data?.transfer_receipt}
        w={8}
        fallbackSrc={fallbackSrc}
      />
    };
  });



  return (
    <>
      <Stack>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Payment Confirmations</Heading>
        </HStack>
        <Stack>
          <DynamicTable
            header={dataHeader}
            data={tableData}
            onEdit={handleOpenModal}
            onColumnClick={onOpen}
          />
        </Stack>

        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Order Id #{detail?.data?.orderId}</ModalHeader>
            <VStack>
              <Image 
                src={detail?.data?.transfer_receipt} 
                alt={`bukti bayar ${detail?.data?.orderId}`}
                fallbackSrc={fallbackSrc}
                width={'50%'}
                objectFit={'contain'}
                onClick={() => window.open(detail?.data?.transfer_receipt)}
                cursor={'pointer'}
              />
              <Text><strong>Order Id : </strong>#{detail?.data?.orderId}</Text>
              <Text><strong>Upload Date : </strong>{detail['transfer date'] || ''}</Text>
              <Text><strong>Amount : </strong>Rp {detail?.data?.amount}</Text>
            </VStack>
            <ModalCloseButton />
            <ModalBody>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='green' mr={3} onClick={alertAction.onOpen}>
              Mark as Paid
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={alertAction.isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Mark Paid Order #{detail?.data?.orderId} ?
              </AlertDialogHeader>

              <AlertDialogBody>
              Silakan cek bukti bayar customer terlebih dahulu
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button colorScheme='red' onClick={handleUpdateOrder} ml={3}>
                Mark Paid
                </Button>
                <Button ref={cancelRef} onClick={() => { onClose(); alertAction.onClose()}}>
                Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Stack>
    </>
  )
}

export default PaymentConfirmations