import { Button, FormControl, FormHelperText, FormLabel, HStack, Input, Text, Select } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
// import Select from 'react-select';
import PropTypes from 'prop-types';

const ShippingOExpress = ({
  cityCode,
  setCityCode
}) => {
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState({});
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);


  const getCitiesOExpress = async (text) => {
    setLoading(true);
    try {
      const config = {
        method: 'get',
        url: `https://sandbox.api.oexpress.co.id/open/1.0/shipment/subdistrict?keyword=${keyword}`,
        headers: {
          'x-api-key': process.env.REACT_APP_OEXPRESS_API_KEY
        },
        // data : {keyword : inputRef.current.value}
      };
      const res = await axios(config);
      console.log(res.data.data);
      setData(res.data.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    let timeoutId
    if (keyword.length >= 3) {
      timeoutId = setTimeout(() => getCitiesOExpress(), 1000);
    }

    return () => clearTimeout(timeoutId)
  }, [keyword])

  return (
    <>
      <FormControl>
        <FormLabel>Cari nama kota anda di sini </FormLabel>
        <HStack gap={2} my={2}>
          <Input placeholder="Ketik Nama Kota / Kabupaten" onChange={(e) => setKeyword(e.target.value)} />
          <Button isLoading={loading} colorScheme='green' onClick={getCitiesOExpress}>Cari</Button>
        </HStack>
        {/* <Select
          options={data?.map((x) => ({ ...x, label: x?.subdistrict_name, value: x?.subdistrict_id }))}
          placeholder="Ketik Nama Kota / Kabupaten"
          onChange={(selected) => {
            setDisplay({ oExpress: selected })
            setCityCode({
              ...cityCode,
              oExpress: selected?.subdistrict_id
            })
          }}
          onInputChange={(text) => setKeyword(text)}
          // isSearchable
          isLoading={loading}
        /> */}
        <Select
          placeholder='Pilih Area'
          // onChange={(e) => setCityCode(e.target.value)}
          onChange={(e) => {
            setDisplay({ oExpress: e.target.value })
            setCityCode({
              ...cityCode,
              oExpress: e.target.value
            })
          }}
        >
          {data?.map((x, i) => (
            <option key={i} value={x?.subdistrict_id}>
              {/* {x.subdistrict_name} */}
              {x?.city}-{x?.zip}
            </option>
          ))}
        </Select>
        <FormHelperText>Ketik keyword dan tekan cari untuk mencari kelurahan</FormHelperText>
      </FormControl>
      {display?.oExpress &&
        <Text>
          Kode Kelurahan : {display?.oExpress}
        </Text>}
    </>
  )
}

export default ShippingOExpress



ShippingOExpress.propTypes = {
  cityCode: PropTypes.object,
  setCityCode: PropTypes.func
}