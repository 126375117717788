/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  PopoverArrow,
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  HStack,
  Circle,
  Text,
  Icon,
  useDisclosure,
  useToast,
  Divider,
} from '@chakra-ui/react';
import { MdLabel } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import { labelsColorChat } from '../../Utils/colors';
import { updateDocumentFirebase } from '../../Api/firebaseApi';

const LabelButtonChat = ({ docId }) => {
  const toast = useToast();
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [label, setLabel] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (label) {
      const index = labelsColorChat.findIndex((item) => item.value === label);
      if (index !== -1) {
        setSelectedLabel(index);
      }
    }
  }, [label]);

  const handleLabelClick = (index) => {
    const newSelectedLabel = index === selectedLabel ? null : index;
    setSelectedLabel(newSelectedLabel);
    if (newSelectedLabel !== null) {
      const selected = labelsColorChat[newSelectedLabel].value;
      setLabel(selected);
    } else {
      setLabel('');
    }
  };

  const handleSave = async () => {
    const selected = labelsColorChat[selectedLabel]?.value || ''
    try {
      await updateDocumentFirebase('chat_customer', docId, {
        label: selected || '',
      });
      toast({
        description: `Label successfully changed to ${labelsColorChat[selectedLabel]?.name || ''}`,
        status: 'success',
        position: 'bottom',
        isClosable: true,
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Popover
      placement="bottom"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <IconButton
          aria-label="label"
          icon={<MdLabel />}
          variant={'outline'}
          size={'sm'}
          bgColor={
            selectedLabel !== null ? labelsColorChat[selectedLabel].color : ''
          }
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Label Items
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack>
            {labelsColorChat.map((label, index) => (
              <Stack   key={index}>
                <HStack
                
                  onClick={() => handleLabelClick(index)}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  cursor={'pointer'}
                >
                  <Circle
                    size="30px"
                    bg={label.color}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {selectedLabel === index && (
                      <Icon as={FaCheckCircle} color="green.900" />
                    )}
                  </Circle>
                  <Text fontSize={'sm'}>{label.name}</Text>
                </HStack>
                <Divider />
              </Stack>
            ))}
          </Stack>
        </PopoverBody>
        <PopoverFooter
          border="0"
          display="flex"
          justifyContent={'flex-end'}
          pb={4}
        >
          <ButtonGroup size="sm">
            <Button
              colorScheme="green"
              variant={'outline'}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button colorScheme="green" variant={'solid'} onClick={handleSave}>
              Save
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default LabelButtonChat;