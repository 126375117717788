import { Box, Button, Flex, FormLabel, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Stack, Text, Textarea } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import useWebsiteStore from '../../../../Hooks/Zustand/WebsiteStore';
import { CloseIcon } from '@chakra-ui/icons';
import { FaTrash } from 'react-icons/fa';

const ModalAddCheckout = ({ modal }) => {
  const websiteStore = useWebsiteStore();

  const currentCheckout = websiteStore?.pageCheckout;
  // console.log(currentCheckout, 'ini page checkout');

  const handleAddNewData = () => {
    const defaultProductData = {
      formInput: [],
      address: [],
    };

    const currentCheckoutData = currentCheckout?.data;
    currentCheckoutData?.push(defaultProductData);
    websiteStore?.setPageCheckout({ ...currentCheckout, data: currentCheckoutData });
  };

  const handleCustomForm = () => {
    const defaultForm = {
      name: '',
    };

    if (currentCheckout && currentCheckout.formInput) {
      currentCheckout.formInput.push(defaultForm);
      websiteStore && websiteStore.setPageCheckout(currentCheckout);
    } else {
      const defaultFormInput = [{ name: 'Email' }, { name: 'Address' }, { name: 'Card Number' }];
      websiteStore && websiteStore.setPageCheckout({ ...currentCheckout, formInput: [...defaultFormInput, defaultForm] });
    }
  };

  const handleDeleteForm = (indexFilter) => {
    const updatedFormInputs = currentCheckout.formInput.filter((_, index) => index !== indexFilter);
    websiteStore.setPageCheckout({ ...currentCheckout, formInput: updatedFormInputs });
  };

  const handleChangeFormName = (value, indexFilter) => {
    currentCheckout.formInput[indexFilter].name = value;
    websiteStore?.setPageCheckout(currentCheckout);
  };

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Checkout Configuration</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box my={3}>
            <Stack>
              <Text>Header</Text>
              <Input
                size={'sm'}
                defaultValue={currentCheckout?.header}
                placeholder='Enter your page header'
                onChange={(e) => websiteStore?.setPageCart({ ...currentCheckout, header: e.target.value })}
              />
            </Stack>
            <Stack my={2}>
              <Text>Description Content</Text>
              <Textarea
                size={'sm'}
                defaultValue={currentCheckout?.content}
                onChange={(e) => websiteStore?.setPageCart({ ...currentCheckout, content: e.target.value })}
                placeholder='Enter your cart page content'
              />
            </Stack>
          </Box>
          <Box
            border={'1px'}
            borderRadius={'md'}
            borderColor={'gray.200'}
            p={4}>
            {currentCheckout && currentCheckout.formInput ? (
              <SimpleGrid
                columns={2}
                spacing={3}>
                {currentCheckout.formInput.map((x, i) => (
                  <>
                    <Flex
                      key={i}
                      flexWrap={'wrap'}
                      gap={2}
                      py={2}>
                      <Box flex='1'>
                        <FormLabel>Form Name</FormLabel>
                        <Flex align='center'>
                          {' '}
                          {/* Membungkus Box dan ikon delete dalam Flex */}
                          <Input
                            onChange={(e) => handleChangeFormName(e.target.value, i)}
                            size={'md'}
                            defaultValue={x?.name}
                          />
                          <Box
                            color='gray.400'
                            ml={2} // Memberikan jarak antara Input dan ikon delete
                            onClick={() => handleDeleteForm(i)}>
                            <FaTrash />
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </>
                ))}
              </SimpleGrid>
            ) : (
              <Box
                align={'center'}
                border={'1px'}
                py={8}
                my={4}>
                <Text>Form is empty</Text>
              </Box>
            )}
            <Box
              align={'center'}
              py={3}>
              <Button
                size={'sm'}
                rounded={'sm'}
                colorScheme='blue'
                onClick={() => handleCustomForm()}>
      Add Your Custom Form
              </Button>
            </Box>
          </Box>

        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              size={'sm'}
              colorScheme='red'
              onClick={() => modal.onClose()}>
              Cancel
            </Button>
            <Button
              size={'sm'}
              colorScheme='green'
              onClick={() => modal.onClose()}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddCheckout;

ModalAddCheckout.propTypes = {
  modal: PropTypes.func,
};
