/* eslint-disable react/prop-types */
import { Icon, IconButton, Stack, useToast } from '@chakra-ui/react'
import { addDoc, collection } from '@firebase/firestore';
import moment from 'moment';
import React from 'react'

import { BsBroadcast } from 'react-icons/bs';
import { db } from '../../../Config/firebase';
import { updateDocumentFirebase } from '../../../Api/firebaseApi';
import { sendPushWhatsappFirst } from '../../../Api/sendWhatsappApi';


function ChatSendBroadcast({ globalState, props }) {

  const toast = useToast()

  const updateChatCustomer = async (data) => {
    const message = {
      message: data?.message,
      characters: data?.characters,
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      status: 'read',
    };
    try {
      await updateDocumentFirebase('chat_customer', props?.data?.id, message);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };


  const handleBroadcast = async () => {

    try {
      // insert to firestore human
      const insertData = {
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
        message: props?.chatConfig?.templateFirstText ? props?.chatConfig?.templateFirstText : '',
        characters: props?.chatConfig?.templateFirstText ? props.chatConfig?.templateFirstText?.length : 0,
        type: 'human',
        chatId: props?.data?.id,
        user: globalState?.uid,
        status: 'read',
        medium: 'web',
        createdAt: moment().unix(),
        lastUpdated: new Date(),
      };
      const userDoc = await addDoc(collection(db, 'chat'), insertData);

      if (userDoc) {
        await updateChatCustomer(insertData);
      }

      if (props?.data?.platform === 'maxchat') {
        if (props?.data?.phoneNumber === undefined) {
          toast({
            title: 'Deoapp.com',
            description: 'this account dont have any number phone',
            status: 'warning',
            position: 'top-right',
            isClosable: true,
          });
          return;
        }
        await sendPushWhatsappFirst(
          props?.chatConfig?.templateFirst,
          props?.data?.phoneNumber,
          props?.chatConfig?.maxchat_key
        );

        toast({
          title: 'Deoapp.com',
          description: 'Success send brodcast',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });

      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  return (
    <Stack>
      <IconButton
        size={'sm'}
        variant={'ghost'}
        colorScheme="blue"
        icon={<Icon as={BsBroadcast}  fontSize="md" />}
        onClick={handleBroadcast}
      />
    </Stack>
  )
}

export default ChatSendBroadcast