/* eslint-disable react/prop-types */
import {
  Avatar,
  Tooltip,
  AvatarBadge,
  Box,
  Divider,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { BsGlobe, BsRobot, BsWhatsapp } from 'react-icons/bs';
import { FaCheck, FaRegAngry, FaRegSmile } from 'react-icons/fa';
import { readMore } from '../../Utils/Helper';
import { MdLabel } from 'react-icons/md';
import { labelsColorChat } from '../../Utils/colors';
import SettingCardCustomer from '../../Pages/Chat/Public/SettingCardCustomer';
import { LuPin } from 'react-icons/lu';


function ChatCard({ data, searchQuery }) {

  const highlightMatches = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query || ''})`, 'gi');
    return text?.replace(
      regex,
      `<span style="background-color:#D69E2E;">${query}</span>`
    ) || '';
  };

  return (
    <Stack
      bgColor={data?.status === 'unread' ? 'red.100' : 'none'}
      color={data?.status === 'unread' ? 'black' : 'none'}
      borderRadius={'md'}
      shadow={'sm'}
      p={2}
    >
      <HStack
        cursor="pointer"
        align="flex-start"
        gap="3"
        _hover={{ bg: 'bg.muted' }}
      >
        <Box pt="1">
          <Avatar
            size="sm"
            src={data?.image}
            name={data?.name ? data.name : data.user}
          >
            {data?.sentiment === 'negative' ? (
              <AvatarBadge boxSize="1.5em" bg="red.500" color={'white'}>
                <FaRegAngry size={20} />
              </AvatarBadge>
            ) : (
              <AvatarBadge boxSize="1.5em" bg="green.500" color={'white'}>
                <FaRegSmile size={20} />
              </AvatarBadge>
            )}
          </Avatar>
        </Box>
        <Stack spacing={1} fontSize="sm" flex="1" isTruncated>
          <HStack spacing={3}>
          
            <Text noofline={1} fontWeight="bold" flex="1" textTransform="capitalize">

              <span
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(
                    readMore(data?.name ? data?.name : data?.user, 15),
                    searchQuery
                  )
                    .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                    .replace(/(?:\r\n|\r|\n)/g, '<br>')
                    .replace(
                      /\[(.*?)\]\((.*?)\)/g,
                      '<a href="$2" target="_blank" style="text-decoration: underline; color: blue;">$1</a>'
                    ),
                }}
              />

              {/* {highlightMatches(
                readMore(data?.name ? data?.name : data?.user, 20),
                searchQuery
              )} */}
            </Text>
           
            <HStack>

              {data?.isPin && (
                <Tooltip label={'Pin'}>
                  <Stack>
                    <Icon
                      as={LuPin}
                      color={'blue.400'}
                      boxSize={4}
                    />
                  </Stack>
                </Tooltip>
              )}


              {data?.isResolved && (
                <Tooltip label={'Resolved'}>
                  <Stack
                    borderRadius={'full'}
                    alignItems="center"
                    justifyContent={'center'}
                    w="20px"
                    h={'20px'}
                    bgColor="green"
                  >
                    <FaCheck color="white" size={10} />
                  </Stack>
                </Tooltip>
              )}
              <Stack>
                <SettingCardCustomer data={data} />
              </Stack>

            </HStack>
          </HStack>

          <Box color="fg.subtle" isTruncated noOfLines={1}>
            <span
              dangerouslySetInnerHTML={{
                __html: highlightMatches(data?.message || '...', searchQuery || '')
                  .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                  .replace(/(?:\r\n|\r|\n)/g, '<br>')
                  .replace(
                    /\[(.*?)\]\((.*?)\)/g,
                    '<a href="$2" target="_blank" style="text-decoration: underline; color: blue;">$1</a>'
                  ),
              }}
            />
          </Box>
          <HStack>
            
            <Text color="fg.subtle" fontSize="xx-small">
              {moment.unix(data?.createdAt).format('MM/DD hh:mm')}
            </Text>

            {data?.label && (
              <Tooltip label={data?.label}>
                <Stack>
                  <Icon
                    as={MdLabel}
                    color={
                      labelsColorChat.find((label) => label.value === data?.label)
                        ?.color || ''
                    }
                    boxSize={5}
                  />
                </Stack>
              </Tooltip>
            )}


          
            <Spacer />

            {data?.platform === 'maxchat' ? (
              <Icon as={BsWhatsapp} color="green" />
            ) : (
              <Icon as={BsGlobe} color="gray" />
            )}

            {data?.type === 'AI' && <Icon as={BsRobot} color="gray" />}

            {data?.lastUpdatedBy?.email && (
              <Tooltip label={data?.lastUpdatedBy?.email} aria-label="Name">
                <Avatar
                  size={'xs'}
                  name={data?.lastUpdatedBy?.email}
                  image={data?.lastUpdatedBy?.uid}
                />
              </Tooltip>
            )}
          </HStack>
        </Stack>
      </HStack>
      <Divider />
    </Stack>
  );
}

export default ChatCard;
