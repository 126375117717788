/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { updateDocumentFirebase } from './firebaseApi';

export const handleRequest = async ({
  setLoading,
  shippingDetails,
  allItem,
  dataWarehouse,
  globalState,
  orderData,
  setShippingResponse,
  onAlertOpen,
  toast
}) => {
  setLoading(true);
  const now = moment().format('YYYY-MM-DD HH:mm');
  const lastPickupTime = moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
  const isAfterLastPickupDate = moment(now).isAfter(lastPickupTime);
  const body = {
    'shipping_id': shippingDetails?.id,
    'is_production': true,
    'shipping_service': shippingDetails?.service,
    'is_cod': false,
    'is_fragile': false,
    'cod_amount': 0,
    'goods_value': 30000,
    'applied_insurance': false,
    'origin_id': parseInt(shippingDetails?.from),
    'destination_id': parseInt(shippingDetails?.to),
    'dimensions': {
      'length': 1,
      'width': 1,
      'height': 1
    },
    'items': allItem,
    'pickup': {
      'date': isAfterLastPickupDate ? moment().add(1, 'day').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      // 'date': '2024-03-19',
      'timeslot': {
        'start_time': dataWarehouse?.start_pickup || '10:00',
        'end_time': dataWarehouse?.end_pickup || '17:00',
        'timezone': 'Asia/Jakarta'
      },
      'name': globalState?.projects?.find((x) => x?.id === globalState.currentProject).name,
      'address': dataWarehouse?.address?.street || '',
      'phone': dataWarehouse?.phone || '087806848932',
      'zip_code': dataWarehouse.address?.postal_code || '15147',
    },
    'receiver': {
      'name': orderData.displayName || 'reinhart',
      'address': orderData?.contact_information?.address?.street + ' ' + orderData?.contact_information?.address?.city || '',
      'phone': orderData?.phoneNumber || '087806848932',
      'zip_code': orderData?.address?.postal_code || '15147'
    }, // penerima
    'shipper': { //pengirim.
      'name': globalState?.projects?.find((x) => x?.id === globalState.currentProject).name,
      'address': dataWarehouse?.address?.street,
      'phone': dataWarehouse?.phone || '087806848932',
    }
  }


  const config = {
    url: `${process.env.REACT_APP_FUNCTIONS_HOST}/shipping-oExpressCreateOrder`,
    // url: 'http://127.0.0.1:8081/deoapp-indonesia/asia-southeast2/shipping-oExpressCreateOrder',
    method: 'post',
    data: body,
    // headers: { 'x-api-key': process.env.REACT_APP_OEXPRESS_API_KEY }
  }
  try {
    const requestShipping = await axios(config);
    // console.log(requestShipping.data, '::requestshipping.data');
    const resiNumber = requestShipping?.data?.data?.data?.tracking_number;
    if (resiNumber) {
      try {
        await updateDocumentFirebase('shipping', shippingDetails.id, { status: 'requested', resi: resiNumber });
        try {
          const obj = orderData?.fulfillment?.find((x) => x?.shippingId == shippingDetails.id);
          const x = [...orderData.fulfillment];
          const updatedArray = x.filter((x) => x.shippingId != shippingDetails.id);
          updatedArray.push({ ...obj, status: 'requested', resi: resiNumber });

          await updateDocumentFirebase('orders', orderData?.id, {fulfillment : updatedArray });
        } catch (error) {
          console.log(error.message, 'error update fulfillment');
        }
      } catch (error) {
        toast({
          title: 'Failed',
          description: error.message,
          isClosable: true,
          status: 'error',
          duration: 9000
        })
      }
    }
    onAlertOpen(true);
    setShippingResponse(requestShipping.data);
  } catch (error) {
    console.log('error request shipping ::::::', error.message);
  } finally {
    setLoading(false);
  }
}