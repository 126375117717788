import { SimpleGrid, Stack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { MdSupervisorAccount } from 'react-icons/md';
import { TbPresentationAnalytics } from 'react-icons/tb';
import AnalyticsCard from '../../../Components/Card/AnalyticsCard';
import useUserStore from '../../../Hooks/Zustand/Store';
import PropTypes from 'prop-types';
import { countDocumentsFirebase } from '../../../Api/firebaseApi';

OrdersDetailAnalytics.propTypes = {
  status: PropTypes.string.isRequired,
};

function OrdersDetailAnalytics() {

  const [loading, setLoading] = useState([])
  const [totalOrder, setTotalOrder] = useState(0)
  const [totalPaid, setTotalPaid] = useState(0)
  const globalState = useUserStore();
  const toast = useToast()
  


  const getDataTotal = async () => {
    setLoading(true)

    const collectionName='orders'
    const conditions=[
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ]
    try {
      const count = await countDocumentsFirebase(collectionName, conditions);
      setTotalOrder(count);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  const getDataPaid = async () => {
    setLoading(true)
    const collectionName='orders'
    const conditions=[
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'paymentStatus', operator: '==', value: 'PAID' },
    ]
    try {
      const count = await countDocumentsFirebase(collectionName, conditions);
      // console.log(count)
      setTotalPaid(count);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataTotal()
    getDataPaid()
    return () => {
      setTotalOrder(0)
      setTotalPaid(0)
    }
  }, [globalState.currentProject])
  


  return (
    <Stack>
      <SimpleGrid columns={[1, 2, 4]} gap={5}>
        <Stack>
          <AnalyticsCard
            number={totalOrder}
            loading={loading}
            unit={''}
            title={'Total Orders'}
            icon={<MdSupervisorAccount size={'20px'} color='white' />}
          />
        </Stack>

        <Stack>
          <AnalyticsCard
            number={totalPaid}
            loading={loading}
            unit={''}
            title={'Total Paid'}
            icon={<TbPresentationAnalytics size={'20px'} color='white' />}
          />
        </Stack>

        <Stack>
          <AnalyticsCard
            number={(((totalPaid || 0) / (totalOrder || 0)) * 100).toFixed(1)}
            loading={loading}
            icon={<HiOutlineSquares2X2 size={'20px'} color='white' />}
            title={'Paid Ratio (%)'}
            unit={'%'}
          />
        </Stack>

        <Stack>
          <AnalyticsCard
            number={(((totalOrder - totalPaid) / totalOrder) * 100).toFixed(1)}
            loading={loading}
            icon={<HiOutlineSquares2X2 size={'20px'} color='white' />}
            title={'Unpaid Ratio (%)'}
            unit={'%'}
          />
        </Stack>
      </SimpleGrid>
    </Stack>
  )
}

export default OrdersDetailAnalytics