/* eslint-disable no-undef */
import axios from 'axios';

export async function sendEmail(data) {
  const url = 'https://new-third-party.importir.com/api/email/send-message';
  const deoapp = 'https://cdn.orderonline.id/uploads/2582751705286171607.png';

  const body = {
    platform_name: 'Deoapp',
    sender_email: 'info@deoapp.com',
    recipient_email: `${data.contact_information.email}`,
    recipient_name: `${data.contact_information.name}`,
    cc: [],
    subject: 'Product Order',
    title: 'Order',
    message: `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <style>
          table { border-spacing: 0; border-collapse: collapse; width: 100%; }
          td { font-family: Arial, sans-serif; padding: 8px; vertical-align: top;  }
          td:last-child 
          .price { text-align: right; white-space: nowrap; } 
          .price p { margin: 0; }
          .image-cell { width: 80px; }
          .details-cell { width: calc(100% - 200px); }
      </style>
      
      </head>
      <body>
          <table>
              <tr>
                  <td>
                      <center>
                          <tr>
                              <td><img src="${deoapp}" width="50%"></td>
                              <td margin-top="30px">ORDER#<span>${
  data.orderId
}</span></td>
                          </tr>
                      </center>
                  </td>
              </tr>
          </table>
          <table>
              <tr>
                  <td>
                      <center>
                          <table>
                              <tr>
                                  <td>
                                      <h2>Thank you for your order!</h2>
                                      <p>Hi ${
  data.contact_information.name
}, we’ve just received your order. To process your order, you need to finish your payment.
                                          Please finish your payment to ensure your order is processed thoroughly.</p>
                                      <p>Please ignore if you have made your payment. Kindly please wait, while we are processing your order and we will notify you when it has been successfully sent to you.</p>
                                      <p>Thank you!</p>
                                  </td>
                              </tr>
                          </table>
                      </center>
                  </td>
              </tr>
          </table>
          <table class="order-summary-table">
          <tr>
              <td>
                  <center>
                      <table>
                          <tr>
                              <td>
                                  <h3>Order summary</h3>
                                  <table>
                                      ${data.items
    .map(
      (item) => `
                                      <tr>
                                          <td><img src="${
  item.images[0]
}" alt="${
  item.productName
}" width="400px" ></td>
                                          <td style="max-width: 200px;">
                                          <span style="font-weight: bold;">${
  item.name
}</span><br>
                                          <span style="margin-top: 25px;">${
  item.description
}</span>
                                      </td>
                                          <td>x${item?.quantity}</td>
                                          <td class="price"><p>Rp ${(
    item.itemPrice * item.quantity
  ).toLocaleString()}</p></td>
                                      </tr>
                                      `
    )
    .join('')}
                                  </table>
                                  <table>
                                      <tr>
                                          <td>Total Price</td>
                                          <td class="price"><p>Rp ${data.items
    .reduce(
      (acc, item) =>
        acc +
                                                item.itemPrice * item.quantity,
      0
    )
    .toLocaleString()}</p></td>
                                      </tr>
                                      <tr>
                                          <td>Payment Method</td>
                                          <td class="price">
                                              <canvas id="qr-code"></canvas>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                      </table>
                  </center>
              </td>
          </tr>
      </table>
      
          <script src="https://cdn.jsdelivr.net/npm/qrcode@1.4.4/qrcode.min.js"></script>
          <script>
          const qrString = '${data.payment_information?.qr_string}';
          const qrConfig = {
              text: qrString,
              width: 120,
              height: 120,
          };
          const canvas = document.createElement('canvas');
          QRCode.toCanvas(canvas, qrConfig, function (error) {
              if (error) console.error(error);
              console.log('QR code berhasil dibuat');
          });
          const qrCodeCell = document.getElementById('qr-code');
          qrCodeCell.appendChild(canvas);          
          </script>
      </body>
      </html>`,
  };

  try {
    await axios.post(url, body);
  } catch (error) {
    return new Error(error.message)
  }
}

export async function sendEmailPaid(data) {
  const url = 'https://new-third-party.importir.com/api/email/send-message';
  const deoapp = 'https://cdn.orderonline.id/uploads/2582751705286171607.png';

  const body = {
    platform_name: 'Deoapp',
    sender_email: 'info@deoapp.com',
    recipient_email: `${data.contact_information.email}`,
    recipient_name: `${data.contact_information.name}`,
    cc: [],
    subject: 'Product Order',
    title: 'Order',
    message: `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <style>
          table { border-spacing: 0; border-collapse: collapse; width: 100%; }
          td { font-family: Arial, sans-serif; padding: 8px; vertical-align: top;  }
          td:last-child 
          .price { text-align: right; white-space: nowrap; } 
          .price p { margin: 0; }
          .image-cell { width: 80px; }
          .details-cell { width: calc(100% - 200px); }
      </style>
      
      </head>
      <body>
          <table>
              <tr>
                  <td>
                      <center>
                          <tr>
                              <td><img src="${deoapp}" width="50%"></td>
                              <td margin-top="30px">ORDER#<span>${
  data.orderId
}</span></td>
                          </tr>
                      </center>
                  </td>
              </tr>
          </table>
          <table>
              <tr>
                  <td>
                      <center>
                          <table>
                              <tr>
                                  <td>
                                      <h2>Payment Succes!</h2>
                                      <p>Hi ${
  data.contact_information.name
}, we’ve just received your order. Your payment has been successfully processed.
    We are now processing your order and will notify you as soon as it has been shipped to you.</p>
                                      <p>Thank you for shopping with us!</p>
                                  </td>
                              </tr>
                          </table>
                      </center>
                  </td>
              </tr>
          </table>
          <table class="order-summary-table">
          <tr>
              <td>
                  <center>
                      <table>
                          <tr>
                              <td>
                                  <h3>Order summary</h3>
                                  <table>
                                      ${data.items
    .map(
      (item) => `
                                      <tr>
                                          <td><img src="${
  item.images[0]
}" alt="${
  item.productName
}" width="400px" ></td>
                                          <td style="max-width: 200px;">
                                          <span style="font-weight: bold;">${
  item.name
}</span><br>
                                          <span style="margin-top: 25px;">${
  item.description
}</span>
                                      </td>
                                          <td>x${item?.quantity}</td>
                                          <td class="price"><p>Rp ${(
    item.itemPrice * item.quantity
  ).toLocaleString()}</p></td>
                                      </tr>
                                      `
    )
    .join('')}
                                  </table>
                                  <table>
                                      <tr>
                                          <td>Total Price</td>
                                          <td class="price"><p>Rp ${data.items
    .reduce(
      (acc, item) =>
        acc +
                                                item.itemPrice * item.quantity,
      0
    )
    .toLocaleString()}</p></td>
                                      </tr>
                                      <tr>
                                          <td>Payment Method</td>
                                          <td class="price">
                                              <canvas id="qr-code"></canvas>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                      </table>
                  </center>
              </td>
          </tr>
      </table>
      
          <script src="https://cdn.jsdelivr.net/npm/qrcode@1.4.4/qrcode.min.js"></script>
          <script>
          const qrString = '${data.payment_information?.qr_string}';
          const qrConfig = {
              text: qrString,
              width: 120,
              height: 120,
          };
          const canvas = document.createElement('canvas');
          QRCode.toCanvas(canvas, qrConfig, function (error) {
              if (error) console.error(error);
              console.log('QR code berhasil dibuat');
          });
          const qrCodeCell = document.getElementById('qr-code');
          qrCodeCell.appendChild(canvas);          
          </script>
      </body>
      </html>`,
  };

  try {
    const response = await axios.post(url, body);
    return response
  } catch (error) {
    return error.message
  }
}
