import React from 'react';
import IntegrationHomePage from '../Pages/Integration/IntegrationHomePage';
import IntegrationWebChatPage from '../Pages/Integration/IntegrationWebChatPage';
import IntegrationWhatsappPage from '../Pages/Integration/IntegrationWhatsappPage';

const IntegrationRouter = [
  {
    path: '/integration',
    element: <IntegrationHomePage />,
  },
  {
    path: '/integration/whatsapp',
    element: <IntegrationWhatsappPage />,
  },
  {
    path: '/integration/web-chat',
    element: <IntegrationWebChatPage />,
  },
];

export default IntegrationRouter;
