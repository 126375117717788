import { Box, Button, FormLabel, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import PropTypes from 'prop-types';

const DesignComponent = ({ websiteStore, handleChangeColor, selectedColor, setSelectedColor, handleChangeAssets, handleChangeInput }) => {
  const [input,setInput] = useState([])
  // const [selectedColor,setSelectedColor] = useState()
  const { onOpen,onClose,isOpen } = useDisclosure()

  const handleOpenModalColor = (value,index) => {
    setSelectedColor({ ...value,indexColor: index })
    onOpen()
  }

  // const handleChangeColor = (value) => {
  //     const colorIndexToUpdate = selectedColor?.indexColor;

  //     const updatedInput = { ...input };

  //     if (
  //         updatedInput.color &&
  //         Array.isArray(updatedInput.color) &&
  //         updatedInput.color[colorIndexToUpdate]
  //     ) {
  //         updatedInput.color[colorIndexToUpdate] = { ...updatedInput.color[colorIndexToUpdate],colorCode: value };
  //     }

  //     setInput(updatedInput);
  //     setSelectedColor({ ...selectedColor,colorCode: value });

  //     setWebConfig({ ...webConfig,color: updatedInput })
  // };



  return (
    <Box p='2'>

      <Stack
        my={3}
        border={'1px'}
        borderRadius={'sm'}
        borderColor={'gray.200'}
        shadow={'md'}
        bg={'white'}
        p={4}
      >
        <Text align={'center'} fontWeight={500}>
                    Text Content
        </Text>
        <Stack>
          <Stack spacing={1}>
            <FormLabel>Title</FormLabel>
            <Input defaultValue={websiteStore?.title} placeholder='Enter Title' onChange={(e) => handleChangeInput(e.target.value,'title')} />
          </Stack>
          <Stack spacing={1}>
            <FormLabel>Name</FormLabel>
            <Input defaultValue={websiteStore?.name} placeholder='Enter Name' onChange={(e) => handleChangeInput(e.target.value,'name')} />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        my={3}
        border={'1px'}
        borderRadius={'sm'}
        borderColor={'gray.200'}
        shadow={'md'}
        bg={'white'}
        p={4}
      >

        <FormLabel>Favicon</FormLabel>
        <Text fontSize={12} p={2} align={'center'}>Icon that will show up beside a title in tab browser</Text>
        <Box align={'center'} p={2} >
          {websiteStore?.favicon ? (
            <Box>
              <Image maxW={100} py={3} src={websiteStore?.favicon} />
              {/* <Text fontSize={12} color={'blue.300'} cursor={'pointer'} onClick={() => console.log('click favicon')}>Change Favicon</Text> */}
              {/* <Spacer /> */}
              <Input type={'file'} size={'sm'} onChange={(e) => handleChangeAssets(e,'favicon')} />
            </Box>
          ) : (
            <Input type='file' size={'sm'} onChange={(e) => handleChangeAssets(e,'favicon')} />
          )}
        </Box>

      </Stack>

      <Stack
        my={3}
        border={'1px'}
        borderRadius={'sm'}
        borderColor={'gray.200'}
        shadow={'md'}
        bg={'white'}
        p={4}
      >
        <FormLabel>Logo</FormLabel>
        <Text fontSize={12} p={1} align={'center'}>For logo that will be placed in navigation bar or footer</Text>
        <Box align={'center'} p={2} >
          {websiteStore?.logo ? (
            <Box>
              <Image maxW={100} py={3} src={websiteStore?.logo} />
              {/* <Text fontSize={12} color={'blue.300'} cursor={'pointer'} onClick={() => console.log('click favicon')}>Change Favicon</Text> */}
              <Spacer />
              <Input type={'file'} size={'sm'} onChange={(e) => handleChangeAssets(e,'logo')} />
            </Box>
          ) : (
            <Input type='file' size={'sm'} onChange={(e) => handleChangeAssets(e,'logo')} />
          )}
        </Box>
      </Stack>

      <Stack
        my={3}
        border={'1px'}
        borderRadius={'sm'}
        borderColor={'gray.200'}
        shadow={'md'}
        bg={'white'}
        p={4}
      >
        <Text align={'center'} fontWeight={500}>
                    Color View
        </Text>
        <SimpleGrid columns={2} justify={'center'} spacing={3}>
          {websiteStore?.color?.length > 0 ? (
            <>
              {websiteStore?.color?.map((x,i) => (
                <Stack key={i} align={'center'} shadow="md" bg="white" padding={2}>
                  <Text align={'center'} fontWeight={500} textTransform={'capitalize'}>
                    {x?.name}
                  </Text>
                  <Box
                    borderWidth={1}
                    aspectRatio={1}
                    w={10}
                    h={10}
                    bg={x?.colorCode ? x?.colorCode : ''}
                  ></Box>

                  <Text align={'center'}>
                    {x?.colorCode ? x?.colorCode : ''}
                  </Text>
                  <Button size="xs" id={1}
                    onClick={() => handleOpenModalColor(x,i)}
                  >
                                        Change
                  </Button>
                </Stack>

              ))}
            </>
          ) : (
            <Text>No Color Set</Text>
          )}
        </SimpleGrid>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size={'sm'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedColor?.name} Color

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack align={'center'}>
              <Box>
                <HexColorPicker
                  // placeholder={color}
                  color={selectedColor?.colorCode}
                  onChange={handleChangeColor}
                />

                {/* <HexColorInput
                                    // placeholder={color}
                                    color={selectedColor?.colorCode}
                                    onChange={handleChangeColor}
                                /> */}

                <HexColorInput style={{ background: '#ececec',width: '100px',padding: '6px',alignItems: 'center',justifyContent: 'center' }} color={selectedColor?.colorCode}
                  onChange={handleChangeColor} />

                {/* </Box> */}
              </Box>
              <Box>
                <Box aspectRatio={1} w="10" bg={selectedColor?.colorCode}></Box>
                <Text>{selectedColor?.colorCode}</Text>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              onClick={onClose}
            >
                            Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default DesignComponent


DesignComponent.propTypes = {
  websiteStore: PropTypes.object,
  handleChangeColor: PropTypes.func,
  selectedColor: PropTypes.string,
  setSelectedColor: PropTypes.func,
  handleChangeAssets: PropTypes.func,
  handleChangeInput: PropTypes.func,
}