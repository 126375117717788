/* eslint-disable no-unsafe-optional-chaining */
import { Box, Grid, HStack, Heading, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import { deleteDocumentFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi'
import { useNavigate, useParams } from 'react-router-dom'
import DropboxUploader from '../../Components/DropBox/DropboxUploader';
import useUserStore from '../../Hooks/Zustand/Store';
import RichTextEditor from '../../Components/Quill/RichTextEditor';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';

function ChatConfigViewPage() {

  const [data, setData] = useState({
    title: '',
    data: '',
  })
  const param = useParams()
  const globalState = useUserStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const navigate = useNavigate()

  const [alertDelete, setAlertDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)

  function openModal() {
    setModalOpen(true);
  }

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setData({ ...data, dataHtml: data.dataHtml + `${htmlContent}` })
    }
  };

  const toast = useToast()

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('chat_data', param.id)
      setData(res)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }


  function closeModal() {
    setModalOpen(false);
  }

  useEffect(() => {
    getData()

    return () => {
      setData({})
    }
  }, [param.id])



  const htmltoText = (html) => {
    let text = html;
    text = text.replace(/\n/gi, '');
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, '');
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, '');
    // eslint-disable-next-line no-useless-escape
    text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
    text = text.replace(/<\/div>/gi, '\\n\\n');
    text = text.replace(/<\/li>/gi, '\\n');
    text = text.replace(/<li.*?>/gi, '  *  ');
    text = text.replace(/<\/ul>/gi, '\\n\\n');
    text = text.replace(/<\/p>/gi, '\\n\\n');
    // eslint-disable-next-line no-useless-escape
    text = text.replace(/<br\s*[\/]?>/gi, '\\n');
    text = text.replace(/<[^>]+>/gi, '');
    text = text.replace(/^\s*/gim, '');
    text = text.replace(/ ,/gi, ',');
    text = text.replace(/ +/gi, ' ');
    text = text.replace(/\n+/gi, '\\n\\n');
    return text;
  };

  const handleSave = async () => {

    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: 'Alert!',
          description: 'You dont have access to edit this data.',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }
   

    if (!data?.title || !data?.status || !data?.dataHtml) {
      return toast({
        title: 'Error!',
        description: 'Please fill in the data',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setLoadingAdd(true)


    const newData = data
    newData.data = String(htmltoText(data.dataHtml))

    delete newData.embedding
    delete newData.id

    try {
      await setDocumentFirebase('chat_data', param.id, newData)
      toast({
        title: 'Success',
        description: 'Data updated',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }finally{
      setLoadingAdd(false)
    }
  }

  const handleDeleteFile = async () => {
    setLoading(true);

    if(data.createdBy !== globalState.uid){
      if (
        globalState.roleCompany !== 'owner' &&
        globalState.roleProject === 'user'
      ) {
        return toast({
          title: 'Alert!',
          description: 'You dont have access to edit this data.',
          status: 'warning',
          duration: 9000,     
          isClosable: true,
        });
      }
    }
    try {
      await deleteDocumentFirebase('chat_data', param?.id);

      toast({
        title: 'Success',
        description: 'Chat file deleted',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete Chat file' + error,
        duration: 3000,
        status: 'error',
      });
    } finally {
      setLoading(false);
      setAlertDelete(false)
      navigate(-1)
    }
  }

  const handleAlertDelete = () => {
    setAlertDelete(true)
  }

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack>
        <Stack>
          <BackButtons />
        </Stack>
        <Stack>
          <Heading size={'md'}>Chat Config View</Heading>
        </Stack>
      </HStack>

      <Stack spacing={1} pt={5}>

        <Heading
          as="h1"
          size="lg"
          p={1}
          contentEditable
          placeholder="Masukkan judul dokumen"
          onBlur={(e) => setData({ ...data, title: e.target.textContent })}
        >
          {data?.title || 'Untitled'}
        </Heading>


        <Grid templateColumns={{ base: '1fr', md: '3fr 1fr' }} gap={3}>
          <Box width='full' m='2'>
            <RichTextEditor
              value={data?.dataHtml || data?.data}
              onChange={(e) => setData({ ...data, dataHtml: e })} m='1' />
          </Box>

          <Stack w='full' m='2' shadow={'md'} p='3' borderRadius='md' alignSelf='flex-start'>


            <Text fontSize='md' fontWeight={500} >Publish</Text>
            <RadioGroup onChange={(e) => setData({ ...data, status: e })} value={data?.status}>
              <Stack direction='column'>
                <Radio value='draft'>Draft</Radio>
                <Radio value='publish'>Publish</Radio>
              </Stack>
            </RadioGroup>

            <Stack w={'full'}>

              <DynamicButton
                action={'read'}
                title={'Add File'}
                onClick={openModal}
                variant={'solid'}
              />

              <DynamicButton
                action={'create'}
                title={'Save'}
                onClick={handleSave}
                variant={'solid'}
                isLoading={loadingAdd}
              />

              <DynamicButton
                action={'delete'}
                title={'Delete'}
                onClick={handleAlertDelete}
                variant={'solid'}
              />
            </Stack>

          </Stack>
        </Grid>
      </Stack>

      <DropboxUploader
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${globalState.currentCompany}/sop`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />

      <AlertDialogV2
        isLoading={loading}
        isOpen={alertDelete}
        onClose={() => setAlertDelete(false)}
        title={`Delete ${data?.title}`}
        onAction={handleDeleteFile}
      />

    </Stack>
  )
}

export default ChatConfigViewPage
