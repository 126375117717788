/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  SimpleGrid,
  Radio,
  Image,
  RadioGroup,
  useToast,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { formatFrice } from '../../Utils/Helper';
import axios from 'axios';
import { addDocumentFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import _axios from '../../Api/AxiosBarrier';
import useUserStore from '../../Hooks/Zustand/Store';
import { sendEmail } from '../../Api/sendEmailApi';
import { doc, setDoc } from '@firebase/firestore';
import { db } from '../../Config/firebase';


const OrderSummaryItem = (props) => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">IDR {value}</Text> : children}
    </Flex>
  );
};

export const CartOrderSummary = ({ value, userData, dataOrder }) => {
  const toast = useToast();
  const [bank, setBank] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentService, setPaymentService] = useState();
  const [dataUser, setDataUser] = useState({});
  const globalState = useUserStore();
  const [dataAddress, setDataAddress] = useState({});
  // console.log(dataUser, 'ini data user')
  const getAddress = async () => {
    try {
      await getSingleDocumentFirebase('users', userData?.uid).then((res) => {
        setDataUser(res);
        setDataAddress(res?.address);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePay = async () => {
    setIsLoading(true);
    const paramPay = {
      userId: userData?.uid,
      userType: 'customer',
      companyId: userData?.currentCompany,
      projectId: userData?.currentProject,
      paymentGateway: 'xendit',
      paymentService: paymentService,
      bank: bank,
      module: 'commerce',
      items: [
        {
          variantId: dataOrder?.variantId,
          productId: dataOrder?.productId,
          quantity: dataOrder?.quantity,
          message: '-',
          shipping_provider: {
            provider: '',
            service: '',
            description: '',
            note: '',
            from: '',
            to: '',
          },
        },
      ],
      contact_information: {
        email: userData?.email,
        name: userData?.name,
        phone_number: dataUser?.phone_number || '081231231231',
        address: {
          street: dataAddress?.street,
          city: dataAddress?.city,
          state: dataAddress?.state,
          postal_code: dataAddress?.postal_code,
          country: dataAddress?.country,
        },
      },
    };
    console.log(paramPay, 'ini param');
    console.log(process.env.REACT_APP_PAYMENT_KEY)
    try {
      const url =
        'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/order-createOrder';
      const response = await _axios.post(url, paramPay,
        //   {
        //   headers: {
        //     'Authorization' : process.env.REACT_APP_PAYMENT_KEY
        //   }
        // }
      );
      const results = await getSingleDocumentFirebase('customer', globalState.uid - globalState.currentProject);
      if (!results) {
        const customDocId = globalState.uid - globalState.currentProject; 
        const data = {
          companyId: globalState.currentCompany,
          email: globalState.email,
          lastUpdate: new Date(),
          name: globalState.name
        };

        const customDocRef = doc(db, 'customer', customDocId);

        await setDoc(customDocRef, data);
      }
      console.log(result, 'ini resut')
      const collectionName = 'pipelines';
      const data = {
        companyId: globalState.currentCompany,
        createdAt: new Date(),
        createdBy: globalState.uid,
        email: globalState.email,
        filesId: 'PYeisfboIZLjxp9djKbq',
        column: 'order',
        name: globalState.name,
        opportunity_value: response.data.payment_information.amount,
        projectId: globalState.currentProject,
        status: 'open',
        type: 'acquisition',
      };
      const result = await addDocumentFirebase(collectionName, data, globalState.currentCompany);
      console.log(response.data, 'ini datanya')
      console.log(result, 'jika')
      const mail = await sendEmail(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message, 'error handlePay')
      return toast({
        status: 'warning',
        duration: 9000,
        title: error.message,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    getAddress();
  }, [userData]);
  const banks = [
    {
      name: 'BNI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png',
    },
    {
      name: 'MANDIRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png',
    },
    {
      name: 'PERMATA',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png',
    },
    {
      name: 'BRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png',
    },
  ];
  return (
    <Stack
      spacing="8"
      borderWidth="1px"
      rounded="lg"
      padding="8"
      width="full"
      my={2}
    >
      <Heading size="md">Order Summary</Heading>

      <Stack spacing="6">
        <OrderSummaryItem label="Subtotal" value={formatFrice(value)} />
        <OrderSummaryItem label="Shipping + Tax">
          <Text fontWeight="medium">IDR {formatFrice(0)}</Text>
        </OrderSummaryItem>
        {/* <OrderSummaryItem label="Coupon Code">
          <Link href="#" textDecor="underline">
            Add coupon code
          </Link>
        </OrderSummaryItem> */}
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
              Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
              IDR {formatFrice(value)}
          </Text>
        </Flex>
      </Stack>
      <Stack>
        <Text>Payment Service</Text>
        <RadioGroup onChange={setPaymentService}>
          <Stack direction="row">
            <Radio value="qr">QRIS</Radio>
            <Radio value="va">VA</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
      {paymentService === 'va' ? (
        <RadioGroup onChange={setBank}>
          <SimpleGrid columns={[2, null, 4]} align="start" gap="2">
            {banks?.map((x, index) => (
              <Radio key={index} value={x.name}>
                <Image src={x?.uri} w="80px" />
              </Radio>
            ))}
          </SimpleGrid>
        </RadioGroup>
      ) : (
        <></>
      )}

      <Button
        colorScheme="blue"
        size="lg"
        fontSize="md"
        rightIcon={<FaArrowRight />}
        onClick={handlePay}
        isLoading={isLoading}
        loadingText='Loading'
        spinnerPlacement='start'
      >
          Checkout
      </Button>
    </Stack>
  );
};
