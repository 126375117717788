import { Box, Flex, Text, Center, Image } from '@chakra-ui/react'
import React, {  } from 'react'

function HomeComingSoonPage() {
  const linearColor ='linear(to-r, #FFA500, #ffd600)'

  return (
    <Box bgColor='151719'>
      <Center>
        <Image
          alt="logo"
          src="https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/2.png"
   
        />
      </Center>


      {/* <Container py={{ base: '16', md: '24' }}> */}
      {/* <Heading size={{ base: 'md', md: 'xl' }}>Create your app remarkable fast</Heading> */}
      <Flex
        direction={{ base: 'column', md: 'column' }}
        justifyContent={'center'}
        alignItems={'center'}
        fontSize={{ base: '1.5em',md: '2em', lg: '3em' }}
        fontWeight="bold"
        color={'white'}
        // gap={'0.25em'}
      >
        <Text>100+ Karyawan AI dengan skillset expert</Text>
        <Text bgGradient={linearColor} bgClip="text">
						Tanpa pusing gaji bulanan
        </Text>
      </Flex>
      <Image
        alt="AI"
        src="https://replicate.delivery/pbxt/tXF6mjWC6uZnKVIBryeogfS7nyWEL3m5PQbYcZAIfRzSBKSkA/out-0.png"
        //   objectFit="cover"
        //   objectPosition="center -140px"
        //   maxH={{ base: 'sm', md: 'lg' }}
        width="full"
      />
      {/* <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={16} rowGap={4}>
        <Stack spacing={{ base: '6', md: '8' }} justifyContent="center">
          <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
            Choose from over 180+ beautiful and responsive components and build your app twice as
            fast.
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="3">
            <Button size={{ base: 'lg', md: 'xl' }}>Buy now</Button>
            <Button variant="secondary" size={{ base: 'lg', md: 'xl' }}>
              Learn more
            </Button>
          </Stack>
        </Stack>
      </SimpleGrid> */}
      {/* </Container> */}
    </Box>

  )
}

export default HomeComingSoonPage