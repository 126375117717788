/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import { Box, Button, HStack, Image, Input, InputGroup, InputLeftAddon, Spacer, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import logo from '../../../assets/image/1.png';

import { MdAccountCircle, MdEmail, MdOutlinePhoneIphone } from 'react-icons/md'
import _axios from '../../../Api/AxiosBarrier';
import { useNavigate } from 'react-router-dom';

function ChatAuth({ phoneWA }) {

  const [loading, setLoading] = useState(false)
  const [inputForm, setInputForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  })

  const toast = useToast()

  const navigate = useNavigate()

  const handleLogin = async () => {

    const phoneNumberRegex = /^(62|0)\d{9,}$/;
    if (!phoneNumberRegex.test(inputForm?.phoneNumber)) {
      toast({
        title: 'Deoapp',
        description: 'Format nomor telepon harus dimulai dengan 62 atau 0 dan terdiri dari 10-13 digit angka.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }


    const dataCreateUser = {
      email: inputForm?.email,
      name: inputForm?.name,
      country: 'indonesia',
      phoneNumber: inputForm?.phoneNumber,
      module: 'chat'
    };

    setLoading(true)

    try {
      let resUser = await _axios.post('/user-getByPhone', { phoneNumber: `+${inputForm.phoneNumber}` });

      if (!resUser.status) {
        resUser = await _axios.post('/user-getByEmail', { email: inputForm.email });
        if (!resUser.status) {
          resUser = await _axios.post('user-create', dataCreateUser);
          navigate(resUser.uid)
          toast({
            title: 'Deoapp.com',
            description: 'Success create account',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        }else{
          navigate(resUser.uid)
          toast({
            title: 'Deoapp.com',
            description: 'Success',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          });
        }
      } else {
        navigate(resUser.uid)
        toast({
          title: 'Deoapp.com',
          description: 'Success',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }


    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false)
    }
  };

  const handleWhatsapp = () => {
    const message = encodeURIComponent('Hi Admin,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊');
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=${phoneWA}&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  }



  const height = window.innerHeight;

  return (
    <Stack>
      <Stack
        spacing={10}
        pt={20}
        minH={height}
        bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={'center'}
        justifyContent="center"
      >
        <Stack>
          <Stack alignItems={'center'} justifyContent="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bgColor="blackAlpha.500"
              p={10}
              borderRadius="xl"
            >
              <Box>
                <Image
                  w="200px"
                  borderRadius={20}
                  src={logo}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '95%',
                    md: '100%',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    children={<MdAccountCircle size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Full Name"
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) => setInputForm({ ...inputForm, name: e.target.value })}
                  />
                </InputGroup>

              </Stack>
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '95%',
                    md: '100%',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    children={<MdEmail name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Email"
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) => setInputForm({ ...inputForm, email: e.target.value })}
                  />
                </InputGroup>

              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '95%',
                    md: '100%',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    children={<MdOutlinePhoneIphone size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Phone Ex: 6287887123456"
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) => setInputForm({ ...inputForm, phoneNumber: e.target.value })}
                  />
                </InputGroup>

              </Stack>



              <Stack>
                {loading ? (
                  <Spinner size={'md'} color="white" />
                ) : (
                  <HStack>
                    <Stack>
                      <Button
                        alignItems={'center'}
                        justifyContent="center"
                        size={'sm'}
                        bgColor={'white'}
                        m={2}
                        onClick={() => handleLogin()}
                      >
                        <Text color={'black'} fontSize="xs" fontWeight="bold">
                          CONTINUE
                        </Text>
                      </Button>
                    </Stack>
                  </HStack>
                )}
              </Stack>


              <Spacer />

              <HStack space={1}>
                <Text color={'gray.400'} fontSize={['xs', null, 'sm']}>
                  Register your account or contact admin
                </Text>
                <Text
                  color={'gray.400'}
                  fontSize={['xs', null, 'sm']}
                  cursor="pointer"
                  fontWeight="bold"
                  onClick={() => handleWhatsapp()}
                >
                  Call us !
                </Text>
              </HStack>
            </Stack>
          </Stack>
          <Spacer />

          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ChatAuth