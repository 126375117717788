import {
  Box,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';


const ShippingRajaOngkir = ({
  cityCode,
  setCityCode
}) => {
  const [destinationResults, setDestinationResults] = useState([]);
  const [cityDestination, setCityDestination] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const toast = useToast()
  const [subDistricts, setSubdistricts] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({});

  const getProvinces = async () => {
    try {
      const result = await axios.get(
        'https://us-central1-intrapreneuer.cloudfunctions.net/listprovince'
      );
      console.log(result.data);
      if (
        result.data?.rajaongkir?.results !== undefined &&
        result.data?.rajaongkir?.results?.length > 0
      ) {
        setDestinationResults(result.data?.rajaongkir?.results);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      // setFetchingDestinations(false);
    }
  };

  const getDataCities = async () => {
    const requestData = { province_id: selectedProvince.province_id }; // Use 'province_id' here
    try {
      const result = await axios.get(
        `https://us-central1-intrapreneuer.cloudfunctions.net/listcities?province=${requestData.province_id}`,
        requestData
      );
      setCityDestination(result.data?.rajaongkir?.results);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      // setFetchingDestinations(false);
    }
  };

  const getDataSubcities = async () => {
    const requestData = { city_id: selectedCity?.city_id }; // Use 'province_id' here

    try {
      const result = await axios.get(
        `https://us-central1-intrapreneuer.cloudfunctions.net/listsubdistrict?city=${requestData.city_id}`,
        requestData
      );
      setSubdistricts(result.data?.rajaongkir?.results);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      // setFetchingDestinations(false);
    }
  };


  useEffect(() => {
    getProvinces();
    return () => { };
  }, []);

  useEffect(() => {
    getDataSubcities();
    return () => { };
  }, [selectedCity]);


  useEffect(() => {
    getDataCities();
  }, [selectedProvince]);


  return (
    <Box w={'full'}>
      <Select
        options={destinationResults?.map((x) => ({ ...x, label: x.province, value: x?.province_id }))}
        placeholder="Pilih Provinsi"
        onChange={(selected) => {
          setSelectedProvince(selected);
          console.log(selected)
        }}
        isSearchable
      />


      <Stack my={2}>
        <Select
          options={cityDestination?.map((x) => ({ ...x, label: x.city_name, value: x?.city_id }))}
          placeholder="Pilih Kota"
          onChange={(selected) => {
            setSelectedCity(selected);
          }}
          isSearchable
        />
      </Stack>
      <Stack my={2}>
        <Select
          options={subDistricts?.map((x) => ({ ...x, label: x.subdistrict_name, value: x?.subdistrict_id }))}
          placeholder="Pilih Kecamatan"
          onChange={(selected) => {
            setCityCode({
              ...cityCode,
              rajaOngkir: selected.value
            });
            console.log({
              ...cityCode,
              rajaOngkir: selected.value
            })
          }}
          isSearchable
        />
      </Stack>
    </Box>
  );
};

export default ShippingRajaOngkir;


ShippingRajaOngkir.propTypes = {
  cityCode: PropTypes.object,
  setCityCode: PropTypes.func,
}
