/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Header2 = ({ bgColor, name, logo, navmenu, modeBG, textColor, width }) => {
  const [openMainMenu] = useState(false);
  const [searchData] = useState();
  const [openProduct] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentParent, setCurrentParent] = useState(null);
  const [treeMenu, setTreeMenu] = useState();
  const [open, setOpen] = useState();
  const handleSubCategoryClick = (e) => {
    e.preventDefault();

  };

  useEffect(() => {
    function buildTree(data, parentId = null) {
      const tree = [];

      for (const item of data) {
        if (item.link === '' && item.subCategories) {
          const children = buildTree(item.subCategories, item.name);

          if (children.length) {
            tree.push({ label: item.name, children });
          }
        } else if (item.subCategories) {
          const parentLabels = item.subCategories.map((subCat) => subCat.parent.map((parent) => parent.label)).flat();
          if (parentId && parentLabels.includes(parentId)) {
            const children = buildTree(item.subCategories, item.name);
            if (children.length) {
              tree.push({ label: item.name, children });
            }
          }
        } else if (!item.subCategories && parentId === null) {
          tree.push({ label: item.name, value: item.categoriesId });
        }
      }

      return tree;
    }

    if (navmenu) {
      const tree = buildTree(navmenu);

      setTreeMenu(tree);
    }

    return () => {
      setTreeMenu([]);
      setOpen([]);
    };
  }, [navmenu]);

  return (
    <header
      className={'text-white'}
      style={{ color: textColor || '#111827' }}
      //   className={
      //     theme.value.modeBG === 'dark'
      //       ? `bg-[${theme.value.modeDark}] text-white`
      //       : `bg-[${theme.value.modeLight}] text-gray-900`
      //   }
    >
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
        aria-label='Global'>
        <div className='flex lg:flex-1'>
          <a
            href='#'
            className='-m-1.5 p-1.5'>
            <span className='sr-only'>{name}</span>
            <img
              className='h-8 w-auto'
              src={logo}
              alt=''
            />
          </a>
          {/* <button onClick$={()=>console.log(searchData.value)}>check console</button> */}
        </div>
        <div className={width === '400px' ? 'hidden' :' flex lg:hidden'}>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5'
            // onClick$={() => (openMainMenu.value = true)}
          >
            <span className='sr-only'>Open main menu</span>
            <svg
              className='h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          </button>
        </div>
        {/* <ThemeSwitch/> */}
        <div className={width === '400px' ? 'hidden' : 'lg:flex lg:gap-x-12'}>
          {navmenu?.length > 0 ? (
            <>
              {navmenu.map((parentItem, parentIndex) => (
                <div
                  key={parentIndex}
                  onMouseEnter={() => {
                    setCurrentParent(parentIndex);
                    setShowDropdown(true);
                  }}
                  onMouseLeave={() => setShowDropdown(false)}>
                  <a
                    href={parentItem.link}
                    className='text-sm font-semibold leading-6 text-gray-900'
                    style={{ color: textColor || '#111827' }}>
                    {parentItem.name}
                  </a>
                  {showDropdown && currentParent === parentIndex && parentItem.subCategories && (
                    <div className='absolute bg-white shadow-lg rounded-lg w-48 z-10'>
                      {parentItem.subCategories.map((subItem, subIndex) => (
                        <a
                          key={subIndex}
                          onClick={(e) => handleSubCategoryClick(e, subItem.name)}
                          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                          {subItem.name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              <a
                href='/'
                className='text-sm font-semibold leading-6 '>
                Home
              </a>
              <a
                href='/product'
                className='text-sm font-semibold leading-6'>
                Products
              </a>
              <a
                href='/categories'
                className='text-sm font-semibold leading-6'>
                Categories
              </a>
              <a
                href='/course'
                className='text-sm font-semibold leading-6'>
                Course
              </a>
              <a
                href='/blog'
                className='text-sm font-semibold leading-6'>
                Blog
              </a>
              <a
                href='#'
                className='text-sm font-semibold leading-6'>
                Company
              </a>
            </>
          )}
        </div>
        <div className='hidden lg:flex lg:flex-1 lg:justify-end gap-2'>
          {/* <button href='/cart' className='text-sm font-semibold leading-6 text-gray-900 m-1' onClick$={()=>openCart.value=true}>Cart </button> */}

          <svg
            className='h-6 w-6'
            cursor='pointer'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            aria-hidden='true'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0'
            />
          </svg>

          <svg
            cursor='pointer'
            // onClick$={() => (openSearch.value = !openSearch.value)}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
            />
          </svg>

          <svg
            cursor='pointer'
            // onClick$={() => (openCart.value = true)}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z'
            />
          </svg>

          {/* <svg cursor='pointer' onClick$={()=>openCart.value=true} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-6 h-6'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z' />
            </svg> */}

          <a href='/account'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
              />
            </svg>
          </a>
        </div>
      </nav>

      {/* {openSearch.value ? (
        <div className='m-2  '>
          <input
            type='text'
            className='rounded-full w-full h-10'
            placeholder='Search'
            onInput$={async (_, e) => {
              try {
                const res = await onGetClientTypesenseFront(
                  e.value,
                  'users',
                  'name',
                  5
                );
                console.log(res);
                if (e.value === ') searchData.value = ';
                else searchData.value = res;
              } catch (error) {
              }
            }}
          />
        </div>
      ) : (
        <></>
      )} */}

      {searchData ? (
        <div className='relative  after:ease-out first:ease-in	after:duration-500'>
          {/* <!--
                Flyout menu, show/hide based on flyout menu state.

                Entering: 'transition ease-out duration-200'
                  From: 'opacity-0 translate-y-1'
                  To: 'opacity-100 translate-y-0'
                Leaving: 'transition ease-in duration-150'
                  From: 'opacity-100 translate-y-0'
                  To: 'opacity-0 translate-y-1'
              --> */}

          <div className='absolute left-1/2 z-10 mt-2 flex w-screen max-w-max -translate-x-1/2 px-4'>
            <div className='w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5'>
              {/* <div className='p-4'>
                {searchData.value?.map((x, i) => (
                  <div
                    key={i}
                    className='group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50'
                  >
                    <div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white'>
                      <svg
                        className='h-6 w-6 text-gray-600 group-hover:text-indigo-600'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z'
                        />
                      </svg>
                    </div>
                    <div>
                      <a href='#' className='font-semibold text-gray-900'>
                        {x.document.name}
                        <span className='absolute inset-0'></span>
                      </a>
                      <p className='mt-1 text-gray-600'>{x.document.email}</p>
                    </div>
                  </div>
                ))}
              </div> */}
              {/* <div className='grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50'>
                    <Link href='/product' className='flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100'>
                      <svg className='h-5 w-5 flex-none text-gray-400' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                        <path fill-rule='evenodd' d='M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z' clip-rule='evenodd' />
                      </svg>
                    Product
                    </Link>
                    <Link href='/categories' 
                    onClick$={()=>searchData.value=''}
                    className='flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100'>
                      <svg className='h-5 w-5 flex-none text-gray-400' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                        <path fill-rule='evenodd' d='M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z' clip-rule='evenodd' />
                      </svg>
                      Category
                    </Link>
                  </div> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* {openCart.value?<CartComponent openCart={openCart}/>:<></>} */}

      {/* <!-- Mobile menu, show/hide based on menu open state. --> */}
      {openMainMenu ? (
        <div
          className='lg:hidden'
          role='dialog'>
          {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
          <div
            className={'bg-white text-gray-900 fixed inset-y-0 right-0 z-10 w-full px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'}
            style={{ backgroundColor: bgColor || '#111827' }}>
            <div className='flex items-center justify-between'>
              <a
                href='#'
                className='-m-1.5 p-1.5'>
                <span className='sr-only'>Your Company</span>
                <img
                  className='h-8 w-auto'
                  src={logo}
                  alt=''
                />
              </a>
              <button
                type='button'
                className='-m-2.5 rounded-md p-2.5 text-gray-700'
                // onClick$={() => (openMainMenu.value = false)}
              >
                <span className='sr-only'>Close menu</span>
                <svg
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  aria-hidden='true'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            <div className='mt-6 flowRoot'>
              <div className='-my-6 divide-y divide-gray-500/10'>
                <div className='space-y-2 py-6'>
                  <a
                    href='/'
                    className={!modeBG ? '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7' : '-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'}
                    // style={{  }}
                  >
                    Home
                  </a>
                  <div className='-mx-3'>
                    <button
                      type='button'
                      //   onClick$={() => (openProduct.value = !openProduct.value)}
                      className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50'
                      aria-controls='disclosure-1'
                      aria-expanded='false'>
                      Product
                      <svg
                        className='h-5 w-5 flex-none'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        aria-hidden='true'>
                        <path
                          fill-rule='evenodd'
                          d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                          clip-rule='evenodd'
                        />
                      </svg>
                    </button>
                    {openProduct ? (
                      <div className='mt-2 space-y-2'>
                        <a
                          href='/product/physical'
                          className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:bg-gray-50'>
                          Physical
                        </a>
                        <a
                          href='/product/digital'
                          className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:bg-gray-50'>
                          Digital
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {navmenu && navmenu?.length > 0 ? (
                    <>
                      {navmenu?.map((x, i) => (
                        <a
                          key={i}
                          href={x?.link}
                          className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'>
                          {x?.name}
                        </a>
                      ))}
                    </>
                  ) : (
                    <>
                      <a
                        href='/categories'
                        className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'>
                        Categories
                      </a>
                      <a
                        href='/course'
                        className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'>
                        Course
                      </a>
                      <a
                        href='/blog'
                        className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'>
                        Blog
                      </a>
                      <a
                        href='#'
                        className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-50'>
                        Company
                      </a>
                    </>
                  )}
                </div>
                <div className='py-6'>
                  <a
                    href='#'
                    className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:bg-gray-50'>
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header2;

Header2.propTypes = {
  bgColor: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  navmenu: PropTypes.string,
  modeBG: PropTypes.string,
  textColor: PropTypes.string,
  width: PropTypes.string,
};
