/* eslint-disable react/prop-types */
import { Divider, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import DynamicButton from '../Buttons/DynamicButton';

function CardAddress({ data, handleDeleteAddress, handleEditAddress }) {
  return (
    <Stack spacing={0}>
      {data && (
        <>
          <Stack spacing={0}>
            <Text fontSize={'sm'} noOfLines={1} textTransform={'capitalize'}>
              {data?.street}
            </Text>
            <Text fontSize={'sm'} textTransform={'uppercase'}>
              {data?.city}, {data?.state}, {data?.country}, {data?.postal_code}
            </Text>
          </Stack>
          <Divider my={2} />
          <HStack justify={'flex-end'}>
            <DynamicButton
              action={'update'}
              title={'Edit'}
              size={'sm'}
              onClick={handleEditAddress}
            />
            <DynamicButton
              action={'delete'}
              title={'Delete'}
              size={'sm'}
              onClick={handleDeleteAddress}
            />
          </HStack>
        </>
      )}
    </Stack>
  );
}

export default CardAddress;
