/* eslint-disable react/prop-types */
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, useToast, Card, CardBody, Image, Link } from '@chakra-ui/react'
import React, { useState } from 'react'
import { updateDocumentFirebase } from '../../Api/firebaseApi';
import DynamicButton from '../Buttons/DynamicButton';
import JneForm from '../../Pages/Products/JneForm';
import ShippingRajaOngkir from '../Shipment/ShippingRajaOngkir';
import ShippingOExpress from '../Shipment/ShippingOExpress';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const shippingServices = [
  {
    name: 'OExpress',
    title: 'oExpress',
    image: 'https://oexpress.id/wp-content/uploads/2023/09/oex.jpg',
    description: 'SULTAN (Service Unggulan Instan), SATSET (Sampai Tujuan Setengah Hari), KISS (Kiriman Sehari Sampai), Serbu (Servis Reguler Banyak Untung), HORE (Harga Ongkir Ekonomis), GOCAP (Cargo Cepat),  KECE (Kiriman Emas Cepat)',
    link: 'https://oexpress.id/'
  },
  {
    name: 'Raja Ongkir',
    title: 'rajaOngkir',
    image: 'https://image.opencart.com/cache/5829cb1ac975e-resize-710x380.jpg',
    description: 'RajaOngkir memiliki data ongkos kirim yang terpadu. Sehingga Anda cukup sekali saja menginputkan tujuan pengiriman, maka ongkir dari seluruh kurir akan muncul.',
    link: 'https://rajaongkir.com/'
  },
  {
    name: 'JNE',
    title: 'jne',
    image: 'https://jnewsonline.com/wp-content/uploads/2021/11/Foto-2-Naskah-Mengenal-Sosok-Kreator-Logo-%E2%80%98Biru-Tua-Merah-JNE.jpg',
    description: 'REG, YES, OKE, Super Speed, Diplomat, International Express, JTR',
    link: 'https://www.jne.co.id/'
  },
];
const CardShipping = ({ data, selectedShipping, setSelectedShipping, height }) => {
  return (
    <Card
      height={height ? height : '10rem'}
      direction={{ base: 'column', sm: 'row' }}
      // overflow='hidden'
      variant='outline'
      borderWidth={selectedShipping === data?.name ? 5 : 1}
      borderColor={selectedShipping === data?.name && 'green.300'}
    >
      <Image
        objectFit='contain'
        maxW={{ base: '100%', sm: '200px' }}
        src={data?.image}
        alt='Caffe Latte'
      />

      <Stack position={'relative'}>
        <CardBody>

          <Link>
            <Link href={data?.link} isExternal>
              {data?.name} <ExternalLinkIcon mx='2px' />
            </Link>
          </Link>
          <Text py='2' color={'gray'} fontSize={12}>
            {data?.description}
          </Text>
          <Button
            position={'absolute'}
            bottom={3}
            right={5}
            size='xs' variant='solid'
            colorScheme='blue'
            onClick={() => setSelectedShipping(data?.name)}
          >
            Configure
          </Button>
        </CardBody>

        {/* <CardFooter>
          <Button variant='solid' colorScheme='blue' onClick={() => setSelectedShipping(data.name)}>
            Configure
          </Button>
        </CardFooter> */}
      </Stack>
    </Card>
  )
}

function WarehouseIndex({ warehouseData, refreshData }) {
  const [modalAddress, setModalAddress] = useState(false)
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [selectedShipping, setSelectedShipping] = useState('OExpress');
  const [cityCode, setCityCode] = useState({
    jne: '',
    oExpress: '',
    rajaOngkir: ''
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        ...cityCode
      }
      await updateDocumentFirebase('warehouses', warehouseData?.id, data);
      toast({
        title: 'Deoapp',
        description: 'Success save address',
        status: 'success',
        isClosable: true,
        position: 'top'
      });
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top'
      });
    } finally {
      setLoading(false);
      setModalAddress(false)
      refreshData()
    }
  }

  const handleOpenModalAddress = () => {
    setModalAddress(true)
  }

  const handleCloseModalAddress = () => {
    setModalAddress(false)
  }


  return (
    <Stack spacing={4}>
      <HStack>
        <Text fontSize={'md'} fontWeight={500}>Shipping</Text>
        <Spacer />
        <DynamicButton
          title={'Shipping'}
          action='create'
          size={'sm'}
          variant='solid'
          onClick={handleOpenModalAddress}
        />
      </HStack>

      {warehouseData?.oExpress || warehouseData?.jne || warehouseData?.rajaOngkir ?
        <Stack shadow="md" borderWidth={1} borderRadius="md">
          {shippingServices.map((data, i) => (
            <HStack key={i} height={'2rem'}>

              <Text>{data?.name} : {warehouseData[data?.title]}</Text>
            </HStack>
          ))}
        </Stack> :

        <Text color={'red.500'} fontWeight={500} fontSize="sm">
          You haven&apos;t set shipping address for couriers
        </Text>
      }

      <Modal isOpen={modalAddress} onClose={handleCloseModalAddress} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Setting Shipping Integration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              {shippingServices.map((item, i) => (
                <CardShipping
                  key={i}
                  data={item}
                  selectedShipping={selectedShipping}
                  setSelectedShipping={setSelectedShipping}
                />
              ))}

              {selectedShipping === 'JNE' ?
                <JneForm
                  cityCode={cityCode}
                  setCityCode={setCityCode}
                /> :
                selectedShipping === 'Raja Ongkir' ?
                  <ShippingRajaOngkir
                    cityCode={cityCode}
                    setCityCode={setCityCode}
                  /> :
                  selectedShipping === 'OExpress' ?
                    <ShippingOExpress
                      cityCode={cityCode}
                      setCityCode={setCityCode}
                    /> :
                    <></>
              }

              {/* <SimpleGrid columns={[1, 2, 2]} gap={3}>
                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>State Address</Text>
                  <ProvinceSelect formData={formData} setFormData={setFormData} Provinsi={Provinsi} />

                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>Country</Text>
                  <Input
                    placeholder="Ex: Indonesia"
                    value={formData.address.country}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, country: e.target.value } })}
                  />
                </Stack>


                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>Postal Code</Text>
                  <Input
                    placeholder="Postal Code"
                    value={formData.address.postal_code}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, postal_code: e.target.value } })}
                  />
                </Stack>


                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>Street Address</Text>
                  <Textarea
                    placeholder="ex: Jl. Gardu"
                    value={formData.address.street}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, street: e.target.value } })}
                  />
                </Stack>


              </SimpleGrid> */}

            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button colorScheme={'red'} onClick={handleCloseModalAddress}>Cancel</Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack >
  )
}

export default WarehouseIndex