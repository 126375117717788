import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  SimpleGrid,
  Spacer,
  InputGroup,
  InputLeftElement,
  Box,
  useToast,
  Heading,
  useDisclosure,
  Select,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { addDocumentFirebase, countDocumentsFirebase, deleteDocumentFirebase, getCollectionFirebase, sumDocumentsFieldFirebase } from '../../Api/firebaseApi';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import CardAvatar from '../../Components/Card/CardAvatar';
import { FcCurrencyExchange } from 'react-icons/fc';
import { IoMdPrint } from 'react-icons/io';
import RetentionDefaultPipeline from '../../Components/Pipeline/RetentionDefaultPipeline';

function RetentionPage() {
  const [pipelineModal, setPipelineModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [dataPipeline, setDataPipeline] = useState({
    name: '',
    stages: [],
    type: 'retantion',
  });

  const [dataSearchPipeline, setDataSearchPipeline] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [dataDelete, setDataDelete] = useState('');
  // const [counts, setCounts] = useState();
  // const [value, setValue] = useState();
  const [query, setQuery] = useState([{field : 'lifetime_value', operator : '=', value : '1000000'}]);

  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const navigate = useNavigate();

  const deleteModal = useDisclosure();

  const [listPipeline, setListPipeline] = useState([]);

  const globalState = useUserStore();

  const handleSubmitRetentionPipeline = async () => {

    const dataUpdate = dataPipeline;
    dataUpdate.projectId = globalState.currentProject;

    const marketing_customers = 'files';
    const data = dataPipeline;
    
    const stage = [
      // {
      //   filter: true,
      //   stageName: 'leads'
      // },
      {
        filter: true,
        stageName: 'fu 1'
      },
      {
        filter: true,
        stageName: 'fu 2'
      },
      {
        filter: true,
        stageName: 'done'
      },
    ];
    data.stages = stage
    // console.log({ ...data, type: 'retention', query })
    try {
      await addDocumentFirebase(marketing_customers, { ...data, type: 'retention', query }, globalState?.currentCompany);

      fetchData();
      handleCloseModal();

      toast({
        title: 'Success',
        description: 'Success add new pipeline!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }
  };

  const handleExportRetentionPipeline = async () => {
    const conditions = [
      ...query,
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];

    try {
      console.log(conditions)
      const get = await getCollectionFirebase('customer', conditions);
      console.log(get,'get');
      const ws = XLSX.utils.json_to_sheet(get);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

      FileSaver.saveAs(data, 'Data Pipeline' + '.xlsx');

      return toast({
        title: 'Admin Deoapp',
        description: 'The data has been successfully Export.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    }
  };


  const resetForm = () => {
    setDataPipeline({
      name: '',
      stages: [],
    });
  };

  const handleCloseModal = () => {
    resetForm();
    setPipelineModal(false);
    setExportModal(false);
  };

  const getData = async () => {
    const productsArray = [];
    const products = listPipeline.map( async(x) => { 
      const data = await getCollectionFirebase('product', [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
        { field: 'tags', operator: 'array-contains', value: x.name },
      ]);
      productsArray.push(data)
      return {data}})
    const productIds = productsArray.map((product) => product.id);

    const orderPromises = productIds.map(async (productId) => {
      const orders = await getCollectionFirebase('orders', [{ field: 'productIds', operator: 'array-contains', value: productId }]);
      const userIds = orders.map((order) => order.userId);
      return [...new Set(userIds)].filter((userId) => userId !== '');
    });

    const uniqueUserIdsArray = await Promise.all(orderPromises);

    const core = [];
    await Promise.all(uniqueUserIdsArray.flat().map(async (userId) => {
      const customerData = await getCollectionFirebase('customer', [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
        { field: 'uid', operator: '==', value: userId },
        // { field: 'column', operator: '==', value: column }
      ]);
      core.push(...customerData);
    }));
    // setColumnsData(core)
    const conditions = core.map((x) => ([
      // { field: 'projectId', operator: '==', value: globalState?.currentProject },
      // { field: 'companyId', operator: '==', value: globalState?.currentCompany },
      { field: 'id', operator: '==', value: x.id }
    ]));

    const countsPromises = conditions.map(async (item) => {
      const count = await countDocumentsFirebase('customer', item);
      return count;
    });
    const counts = await Promise.all(countsPromises);
    const totalCount = counts.reduce(function (a, b) {
      return a + b;
    }, 0);

    const valuesPromises = conditions.map(async (x) => {
      const point = await sumDocumentsFieldFirebase('customer', 'last_checkout_value', x);
      return point;
    });

    const values = await Promise.all(valuesPromises)
      .then(function (result) {
        const total = result.reduce(function (a, b) {
          return a + b;
        }, 0);
        // setValue(total)
        return total
      });
    // setCounts(totalCount)
    // setValue(values)
  };

  const fetchData = async () => {
    const conditionsPipelines = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'type', operator: '==', value: 'retention' },
    ];
    // const conditionsForms = [
    //   { field: 'companyId', operator: '==', value: globalState.currentCompany },
    //   { field: 'projectId', operator: '==', value: globalState.currentProject },
    // ];
    // const sortBy = { field: 'createdAt', direction: 'desc' };
    // const limitValue = 10;
    try {
      // const res = await getCollectionFirebase('files', conditionsPipelines, sortBy, limitValue);
      const res = await getCollectionFirebase('files', conditionsPipelines);
      // const resForm = await getCollectionFirebase('forms', conditionsForms, sortBy);
      // const formFilter = resForm.filter((x) => !x.pipelineId || x.pipelineId === '');

      setListPipeline(res);
      // setFormList(resForm);
      // setFormList(formFilter); 
    } catch (error) {
      console.log(error, 'ini error');
    }
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = listPipeline.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearchPipeline(newData);
      setInputSearch(text);
    } else {
      setDataSearchPipeline(listPipeline);
      setInputSearch(text);
    }
  };

  const handleDeleteModal = (value) => {
    deleteModal.onOpen();
    setDataDelete(value);
  };

  const handleDeletePipeline = async () => {
    setIsLoading(true);
    try {
      const result = await deleteDocumentFirebase('files', dataDelete.id);


      // if (result === 'Dokumen berhasil dihapus.') {
      //   await updateDocumentFirebase('forms', decryptToken(dataDelete.formId[0]), { pipelineId: '' });

      // }

      toast({
        status: 'success',
        title: 'Deoapp CRM',
        description: 'Pipeline deleted',
        duration: 3000,
      });

      fetchData();

      deleteModal.onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleFormToggle = (formId) => {
  //   setSelectedFormId((prev) => (prev === formId ? null : formId));
  // };

  const userOptions = [
    {name : 'Lifetime Value', value : 'lifetime_value'},
    {name : 'Average Order Value', value : 'average_order_value'},
    {name : 'Date of Birth', value : 'birth'},
    {name : 'Last Paid', value : 'lastTransaction'},
    {name : 'Tags', value : 'lastTransaction'},
    {name : 'Phone', value : 'lastTransaction'},
    {name : 'City', value : 'address.city'},
    {name : 'Province', value : 'address.province'},
    {name : 'Last Purchase', value : 'lastTransaction'},
    {name : 'First Purchase', value : 'lastTransaction'},
    {name : 'Active', value : 'lastTransaction'},
    {name : 'Last Login', value : 'lastTransaction'},
  ];

  const exportOption = [
    {name : 'Lifetime Value', value : 'lifetime_value'},
    {name : 'AOV', value : 'average_order_value'},
    // {name : 'BOD', value : 'birth'},
    {name : 'Last Transaction', value : 'lastTransaction'},
    {name : 'Last Active', value : 'lastUpdated'},
  ];

  const handleTriggerChange = (e, i, type) => {
    const newQuery = [...query];
    newQuery[i][type] = e.target.value;
    setQuery(newQuery);
  };

  const handleTriggerChangeTime = (e, i, type) => {
    const newQuery = [...query];
    newQuery[i][type] = (new Date(e.target.value)).getTime();
    setQuery(newQuery);
  };
  useEffect(() => {
    fetchData();
    // getData();
    return () => { };
  }, [globalState.currentProject]);
  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={5}>
        <HStack gap={5} flexDirection={['column', 'row', 'row']}>
          <BackButtons />
          <Heading
            size={'md'}
            textTransform='capitalize'>
            Retention Pipeline
          </Heading>
          <Spacer />
          <HStack>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon
                  color='gray.300'
                  mb={2}
                />
              </InputLeftElement>
              <Input
                borderRadius={'md'}
                placeholder='Search'
                // size={"sm"}
                onChange={(e) => searchFilterFunction(e.target.value)}
              />
            </InputGroup>
            <Box>
              <DynamicButton
                title={'Add Pipeline'}
                onClick={() => setPipelineModal(true)}
                action={'create'}
              />
            </Box>
            <Box>
              <DynamicButton
                title={'Export Pipeline'}
                onClick={() => setExportModal(true)}
                action={'custom'}
                color={'blue'}
                icon={IoMdPrint}
              />
            </Box>
          </HStack>
        </HStack>

        <Box
          borderRadius={'md'}
          minHeight='700px'
          my={2}
          p={[1, 1, 5]}
          boxShadow={'sm'}>
          {inputSearch === '' ? (
            <><RetentionDefaultPipeline />
              <SimpleGrid
                columns={[1, 1, 3]}
                spacing={3}>
                {listPipeline?.map((x, i) => {
                  return (
                    <Stack
                      key={i}
                      borderWidth='1px'
                      p={3}
                      shadow={'md'}
                      rounded={5}
                      cursor='pointer'
                      _hover={{
                        bg: 'gray.100',
                        transform: 'scale(1.02)',
                        transition: '0.3s',
                        cursor: 'pointer',
                      }}>
                      {/* <Box
                  mb={3}
                  onClick={() => navigate(`/relations/retention/pipeline/view/${x.id}`, { state: x })}>
                  <Heading
                    textTransform={'capitalize'}
                    size='sm'>
                    {x.name}
                  </Heading>
                  <Text color={'gray.700'}>{x.project}</Text>
                </Box>
                <Spacer /> */}

                      <HStack>
                        <Text
                          fontSize={'xs'}
                          color={'gray.500'}>
                          {moment(x.createdAt.seconds * 1000).fromNow()}
                        </Text>
                        <Spacer />
                        <DeleteIcon
                          onClick={() => handleDeleteModal(x)}
                          boxSize={3} />
                      </HStack>
                      <CardAvatar
                        data={x}
                        title={x.name}
                        globalState={globalState}
                        onNavigate={() => navigate(`/relations/retention/pipeline/view/${x.id}`, { state: x })}
                        userArray={x?.usersDisplay}
                        timeCreated={x?.lastUpdated?.seconds}
                        // modalTeam={() => modalTeam(x)}
                        owner={x?.users}
                        // onDelete={() => console.log(x)}
                        Icon={FcCurrencyExchange} />
                    </Stack>

                  );
                })}
              </SimpleGrid></>
          ) : (
            <SimpleGrid
              columns={[2, null, 4]}
              spacing={3}>
              {dataSearchPipeline?.map((x, i) => {
                return (
                  <Stack
                    key={i}
                    borderWidth='1px'
                    p={3}
                    shadow={'md'}
                    rounded={5}
                    cursor='pointer'
                    _hover={{
                      bg: 'gray.100',
                      transform: 'scale(1.02)',
                      transition: '0.3s',
                      cursor: 'pointer',
                    }}>
                    <Box
                      mb={3}
                      onClick={() => navigate(`/pipeline/view/${x.id}`, { state: x })}>
                      <Heading
                        textTransform={'capitalize'}
                        size='sm'>
                        {x.name}
                      </Heading>
                      <Text color={'gray.700'}>{x.project}</Text>
                      <Spacer />
                    </Box>

                    <HStack>
                      <Text
                        fontSize={'xs'}
                        color={'gray.500'}>
                        {moment(x.createdAt.seconds * 1000).fromNow()}
                      </Text>
                      <Spacer />
                      <DeleteIcon
                        onClick={() => handleDeleteModal(x)}
                        boxSize={3}
                      />
                    </HStack>
                  </Stack>
                );
              })}
            </SimpleGrid>
          )}
        </Box>
      </Stack>

      <Modal
        isOpen={pipelineModal}
        onClose={handleCloseModal}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack>
              <Text fontSize={'md'}>Create New Retention Pipeline</Text>
              <Text fontSize={'sm'} fontWeight={'400'}></Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Stack>
                <Text>Pipeline Name</Text>
                <Input
                  size={'sm'}
                  placeholder='name'
                  onChange={(e) => setDataPipeline({ ...dataPipeline, name: e.target.value })}
                />
              </Stack>
              <Stack>
                {query?.map((trigger, i) => (
                  <HStack key={i}>
                    <Select 
                      onChange={(e) => {handleTriggerChange(e, i, 'field')}}
                      defaultValue={'lifetime_value'}
                    >
                      {userOptions.map((x,i) => <option value={x?.value} key={i}>{x?.name}</option>)}
                    </Select>
                    <Select
                      onChange={(e) => {handleTriggerChange(e, i, 'operator')}}
                      defaultValue={'='}
                    >
                      <option>=</option>
                      <option>{'<'}</option>
                      <option>{'>'}</option>
                      <option>{'>='}</option>
                      <option>{'<='}</option>
                    </Select>
                    <Input 
                      type='text'
                      onChange={(e) => {handleTriggerChange(e, i, 'value')}}
                      value={trigger[i]?.value}
                    />
                    <DeleteIcon color={'gray'} cursor={'pointer'} onClick={() => setQuery((prev) => prev.filter((_, idx) => idx !== i))} />
                  </HStack>
                ))}
                <Button onClick={() => setQuery((prev) => [...prev, {}])}>+</Button>
                {/* <Heading size='sm'>Lifetime Value</Heading>
                <InputGroup>
                  <InputLeftAddon>{'>='}</InputLeftAddon>
                  <Input type='number' placeholder='Rp 1,000,000' />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon>{'>'}</InputLeftAddon>
                  <Input type='number' placeholder='Rp 1,000,000' />
                </InputGroup> */}

              </Stack>
              {/* <FormControl>
                <Text>Available Tags</Text>
                <Select placeholder='Select option'>
                  <option value='option1'>Option 1</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </Select>
              </FormControl> */}

              {/* <Stack spacing={2}>
                <Text>Form Active</Text>
                {formList?.length > 0 ? (
                  <>
                    {formList?.map((x, index) => (
                      <HStack
                        key={index}
                        p={2}
                        borderRadius='md'
                        borderWidth={1}
                        borderColor='blackAlpha.200'>
                        <Text>{x.title}</Text>
                        <Spacer />
                        <IconButton
                          size='sm'
                          icon={<FiCheck size={20} />}
                          // bgColor={x.token === selectedFormId ? 'green.300' : 'gray.200'}
                          onClick={() => handleFormToggle(x.token)}
                        />
                      </HStack>
                    ))}
                  </>
                ) : (
                  <Center>
                    <Box
                      align={'center'}
                      fontSize={12}>
                      <Text>No Form Data. All Forms are used</Text>
                      <Text>You can create your first form</Text>
                      <Button
                        my={2}
                        colorScheme='blue'
                        size={'xs'}
                        onClick={() => navigate('/form-builder')}>
                        Here
                      </Button>
                    </Box>
                  </Center>
                )}
              </Stack>  */}

              {/* {dataPipeline?.stages?.map((stage, index) => (
                <Grid
                  gap={4}
                  templateColumns={{ base: '2fr 1fr', md: '2fr 1fr' }}
                  key={index}>
                  <Stack spacing={2}>
                    <Text>Stage Name</Text>
                    <Input
                      size={'sm'}
                      w='100%'
                      placeholder='name'
                      value={stage.stageName}
                      onChange={(e) => handleStageNameChange(index, e.target.value.toLowerCase())}
                    />
                  </Stack>

                  <Stack>
                    <Text>Actions</Text>

                    <HStack>
                      <IconButton
                        size='sm'
                        icon={<FiFilter />}
                        color={stage.filter ? 'blue' : 'gray'}
                        onClick={() => handleFilterToggle(index)}
                      />
                      <Button
                        colorScheme={'red'}
                        size='sm'
                        onClick={() => handleDeleteStage(index)}>
                        Delete
                      </Button>
                    </HStack>
                  </Stack>
                </Grid>
              ))} */}
              {/* <Button
                colorScheme={'blue'}
                variant='outline'
                size={'sm'}
                onClick={handleAddNewStage}>
                Add Stage
              </Button> */}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme='blackAlpha'
              mr={3}
              onClick={() => setPipelineModal(false)}>
              Close
            </Button>
            <Button
              size={'sm'}
              colorScheme='twitter'
              mr={3}
              onClick={handleSubmitRetentionPipeline}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={exportModal}
        onClose={handleCloseModal}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack>
              <Text fontSize={'md'}>Export Pipeline</Text>
              <Text fontSize={'sm'} fontWeight={'400'}></Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Stack>
                {query?.map((trigger, i) => (
                  <HStack key={i}>
                    <Select 
                      onChange={(e) => {handleTriggerChange(e, i, 'field')}}
                      placeholder={'Select Option'}
                    >
                      {exportOption.map((x,i) => <option value={x?.value} key={i}>{x?.name}</option>)}
                    </Select>
                    <Select
                      onChange={(e) => {handleTriggerChange(e, i, 'operator')}}
                      placeholder={'Select Option'}
                    >
                      <option>==</option>
                      <option>{'<'}</option>
                      <option>{'>'}</option>
                      <option>{'>='}</option>
                      <option>{'<='}</option>
                    </Select>
                    {trigger.field === 'lastTransaction' || trigger.field === 'lastUpdated' ? (
                      <Input 
                        type='date'
                        onChange={(e) => {handleTriggerChangeTime(e, i, 'value')}}
                        value={trigger[i]?.value}
                      />  
                    ) : (
                      <Input 
                        type='text'
                        onChange={(e) => {handleTriggerChange(e, i, 'value')}}
                        value={trigger[i]?.value}
                      />                      
                    )}

                    <DeleteIcon color={'gray'} cursor={'pointer'} onClick={() => setQuery((prev) => prev.filter((_, idx) => idx !== i))} />
                  </HStack>
                ))}
                <Button onClick={() => setQuery((prev) => [...prev, {}])}>+</Button>
                {/* <Heading size='sm'>Lifetime Value</Heading>
                <InputGroup>
                  <InputLeftAddon>{'>='}</InputLeftAddon>
                  <Input type='number' placeholder='Rp 1,000,000' />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon>{'>'}</InputLeftAddon>
                  <Input type='number' placeholder='Rp 1,000,000' />
                </InputGroup> */}

              </Stack>
              {/* <FormControl>
                <Text>Available Tags</Text>
                <Select placeholder='Select option'>
                  <option value='option1'>Option 1</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </Select>
              </FormControl> */}

              {/* <Stack spacing={2}>
                <Text>Form Active</Text>
                {formList?.length > 0 ? (
                  <>
                    {formList?.map((x, index) => (
                      <HStack
                        key={index}
                        p={2}
                        borderRadius='md'
                        borderWidth={1}
                        borderColor='blackAlpha.200'>
                        <Text>{x.title}</Text>
                        <Spacer />
                        <IconButton
                          size='sm'
                          icon={<FiCheck size={20} />}
                          // bgColor={x.token === selectedFormId ? 'green.300' : 'gray.200'}
                          onClick={() => handleFormToggle(x.token)}
                        />
                      </HStack>
                    ))}
                  </>
                ) : (
                  <Center>
                    <Box
                      align={'center'}
                      fontSize={12}>
                      <Text>No Form Data. All Forms are used</Text>
                      <Text>You can create your first form</Text>
                      <Button
                        my={2}
                        colorScheme='blue'
                        size={'xs'}
                        onClick={() => navigate('/form-builder')}>
                        Here
                      </Button>
                    </Box>
                  </Center>
                )}
              </Stack>  */}

              {/* {dataPipeline?.stages?.map((stage, index) => (
                <Grid
                  gap={4}
                  templateColumns={{ base: '2fr 1fr', md: '2fr 1fr' }}
                  key={index}>
                  <Stack spacing={2}>
                    <Text>Stage Name</Text>
                    <Input
                      size={'sm'}
                      w='100%'
                      placeholder='name'
                      value={stage.stageName}
                      onChange={(e) => handleStageNameChange(index, e.target.value.toLowerCase())}
                    />
                  </Stack>

                  <Stack>
                    <Text>Actions</Text>

                    <HStack>
                      <IconButton
                        size='sm'
                        icon={<FiFilter />}
                        color={stage.filter ? 'blue' : 'gray'}
                        onClick={() => handleFilterToggle(index)}
                      />
                      <Button
                        colorScheme={'red'}
                        size='sm'
                        onClick={() => handleDeleteStage(index)}>
                        Delete
                      </Button>
                    </HStack>
                  </Stack>
                </Grid>
              ))} */}
              {/* <Button
                colorScheme={'blue'}
                variant='outline'
                size={'sm'}
                onClick={handleAddNewStage}>
                Add Stage
              </Button> */}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme='blackAlpha'
              mr={3}
              onClick={() => setExportModal(false)}>
              Close
            </Button>
            <Button
              size={'sm'}
              colorScheme='twitter'
              mr={3}
              onClick={handleExportRetentionPipeline}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Text fontSize={'md'}>Delete Pipeline</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure to delete {dataDelete.name} Pipeline?</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme='blackAlpha'
              mr={3}
              onClick={deleteModal.onClose}>
              No
            </Button>
            <Button
              isLoading={isLoading}
              size={'sm'}
              colorScheme='twitter'
              mr={3}
              onClick={handleDeletePipeline}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default RetentionPage;
