/* eslint-disable quotes */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const ProductCountdown = ({ header, content, data }) => {
  console.log(data, 'ini dataa didalam komponen');
  const timestamps = data[0]?.launching?.seconds;
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!timestamps) {
      console.error('No timestamp provided for countdown');
      return;
    }

    const eventTime = timestamps;
    const updateCountdown = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeDiff = eventTime - currentTime;

      if (timeDiff > 0) {
        const duration = moment.duration(timeDiff, 'seconds');
        setTimeRemaining({
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      } else {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [timestamps]);

  return (
    <div
      className='flex justify-center items-center text-center text-white h-[40vw] bg-no-repeat bg-cover bg-fixed'
      style={{ backgroundImage: `url(${data[0]?.image1})` }}>
      <div>
        <p className='text-5xl'>{data[0]?.name || "Klamby's Journal"}</p>
        <p className='text-3xl'>{header || "Klamby's Journal"}</p>
        <p className='text-sm my-5'>{content || 'The Beginning of Your Day Reading'}</p>

        <div className='flex items-start justify-center w-full gap-8 count-down-main'>
          <div className='timer w-20'>
            {' '}
            {/* Perbesar lebar elemen */}
            <div className='bg-indigo-600 py-6 px-3 rounded-lg overflow-hidden'>
              {' '}
              {/* Tambahkan padding */}
              <h3 className='countdown-element days font-Cormorant font-bold text-3xl text-white text-center'>
                {' '}
                {/* Perbesar ukuran font */}
                {timeRemaining.days}
              </h3>
            </div>
            <p className='text-xl font-Cormorant font-medium text-gray-900 mt-2 text-center'>
              {' '}
              {/* Perbesar ukuran font deskripsi */}
              days
            </p>
          </div>
          <h3 className='font-manrope font-bold text-3xl text-gray-900'>:</h3> {/* Perbesar ukuran tanda ':' */}
          <div className='timer w-20'>
            {' '}
            {/* Perbesar lebar elemen */}
            <div className='bg-indigo-600 py-6 px-3 rounded-lg overflow-hidden'>
              {' '}
              {/* Tambahkan padding */}
              <h3 className='countdown-element hours font-Cormorant font-bold text-3xl text-white text-center'>
                {' '}
                {/* Perbesar ukuran font */}
                {timeRemaining.hours}
              </h3>
            </div>
            <p className='text-xl font-Cormorant font-medium text-gray-900 mt-2 text-center'>
              {' '}
              {/* Perbesar ukuran font deskripsi */}
              hours
            </p>
          </div>
          <h3 className='font-manrope font-bold text-3xl text-gray-900'>:</h3> {/* Perbesar ukuran tanda ':' */}
          <div className='timer w-20'>
            {' '}
            {/* Perbesar lebar elemen */}
            <div className='bg-indigo-600 py-6 px-3 rounded-lg overflow-hidden'>
              {' '}
              {/* Tambahkan padding */}
              <h3 className='countdown-element hours font-Cormorant font-bold text-3xl text-white text-center'>
                {' '}
                {/* Perbesar ukuran font */}
                {timeRemaining.minutes}
              </h3>
            </div>
            <p className='text-xl font-Cormorant font-medium text-gray-900 mt-2 text-center'>
              {' '}
              {/* Perbesar ukuran font deskripsi */}
              minutes
            </p>
          </div>
          <h3 className='font-manrope font-bold text-3xl text-gray-900'>:</h3> {/* Perbesar ukuran tanda ':' */}
          <div className='timer w-20'>
            {' '}
            {/* Perbesar lebar elemen */}
            <div className='bg-indigo-600 py-6 px-3 rounded-lg overflow-hidden'>
              {' '}
              {/* Tambahkan padding */}
              <h3 className='countdown-element seconds font-Cormorant font-bold text-3xl text-white text-center'>
                {' '}
                {/* Perbesar ukuran font */}
                {timeRemaining.seconds}
              </h3>
            </div>
            <p className='text-xl font-Cormorant font-medium text-gray-900 mt-2 text-center'>
              {' '}
              {/* Perbesar ukuran font deskripsi */}
              seconds
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductCountdown.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  data: PropTypes.array.isRequired,
};
