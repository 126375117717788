import {
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';

import BackButtons from '../../Components/Buttons/BackButtons';
import DetailPipelineCard from '../../Components/Card/DetailPipelineCard';
import useUserStore from '../../Hooks/Zustand/Store';
import { clientTypessense } from '../../Api/Typesense';
import DetailPipelineAddCard from '../../Components/Card/DetailPipelineAddCard';
import LineColumnsComponent from '../../Components/Columns/LineColumnsComponent';
import RetentionColumnsDefaultComponent from '../../Components/Columns/RetentionColumnsDefaultComponent';

function RetentionPipelineViewPage() {
  const param = useParams();
  const globalState = useUserStore();
  const location = useLocation();
  const datas = location.state;
  const [pipelineList, setPipelineList] = useState('');

  const [searchResult, setSearchResult] = useState('');
  const navigate = useNavigate();
  const [modalDetail, setModalDetail] = useState(false);
  const [detailActive, setDetailActive] = useState('');
  const toast = useToast()

  const [modalAdd, setModalAdd] = useState(false);

  const [filterData, setFilterData] = useState({ search: '' });

  const fetchData = async () => {
    try {
      const result = await getSingleDocumentFirebase('files', param.id);
      setPipelineList(result);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleModalOpen = (x) => {
    setModalDetail(true);
    setDetailActive(x);
  };

  const handleModalClose = () => {
    setModalDetail(false);
    setDetailActive('');
  };

  const handleModalAddClose = () => {
    setModalAdd(false);
  };


  const handleSearchUsers = async (q) => {
    const companyUsers = globalState.companies.find((x) => x.id === globalState.currentCompany);
    const userChunks = chunkArray(companyUsers?.users, 100);
    // console.log(companyUsers);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense.collections('users').documents().search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp',
          description: error?.message,
          status: 'error',
          isClosable: true,
          position: 'top',
        });
      });
  };

  const pipeDefault = {
    title: param.id,
    type: 'retantion',
    stages: [
      {
        filter: true,
        stageName: 'leads'
      },
      {
        filter: true,
        stageName: 'fu 1'
      },
      {
        filter: true,
        stageName: 'fu 2'
      },
      {
        filter: true,
        stageName: 'done'
      },]
  }
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };
  useEffect(() => { 
    fetchData();

    return () => { };
  }, [globalState.currentProject]);
  return (
    <Stack
      p={[1, 1, 5]}
      spacing={5}>
      <HStack gap='5'>
        <BackButtons />
        <Heading size={'md'}>Retention Pipeline {param.id === 'today-transaction' || param.id === 'last-7-days-transaction' || param.id === 'last-14-days-transaction' || param.id === 'last-30-days-transaction' || param.id === 'last-90-days-transaction' || param.id === 'inactive-period-7-day' || param.id === 'inactive-period-14-day' || param.id === 'inactive-period-30-day' || param.id === 'inactive-period-90-day' ? pipeDefault.title : pipelineList?.name}</Heading>
        <Spacer />
        <HStack>
          <Input
            type='text'
            w='80%'
            placeholder='Search Leads'
            onChange={(e) => setFilterData({ search: e.target.value })}
          />
          {/* <Select
            w='fit-content'
            placeholder='Sort by'
            onChange={(e) => setFilterData({ status: e.target.value })}>
            <option value={'won'}>Won</option>
            <option value={'lost'}>Lost</option>
            <option value={'open'}>Open</option>
          </Select> */}
          {/* <DynamicButton
            action={'create'}
            onClick={handleModalAddOpen}
            title={'Add Contact'}
          /> */}
        </HStack>
      </HStack>

      <Stack>{param.id === 'today-transaction' || param.id === 'last-7-days-transaction' || param.id === 'last-14-days-transaction' || param.id === 'last-30-days-transaction' || param.id === 'last-90-days-transaction' || param.id === 'inactive-period-7-day' || param.id === 'inactive-period-14-day' || param.id === 'inactive-period-30-day' || param.id === 'inactive-period-90-day' ? pipeDefault?.length > 0 : pipelineList?.length > 0}</Stack>
      <Flex overflowX='auto'>
        <DndProvider backend={HTML5Backend}>
          <HStack>
            {param.id === 'today-transaction' || param.id === 'last-7-days-transaction' || param.id === 'last-14-days-transaction' || param.id === 'last-30-days-transaction' || param.id === 'last-90-days-transaction' || param.id === 'inactive-period-7-day' || param.id === 'inactive-period-14-day' || param.id === 'inactive-period-30-day' || param.id === 'inactive-period-90-day' ?
              (pipeDefault?.stages?.map((x, i) => {
                return (
                  <RetentionColumnsDefaultComponent
                    handleModalOpen={handleModalOpen}
                    key={i}
                    index={i}
                    kanbanData={{ name: x?.stageName }}
                    allowedDropEffect='move'
                    // filterData={filterData}
                    column={x?.stageName}
                  />
                );
              })) :

              <>
                <LineColumnsComponent
                  name={pipelineList.name}
                  // data={pipe}
                  handleModalOpen={handleModalOpen}
                  index={0}
                  kanbanData={{ name: 'testttt' }}
                  // formId={pipelineList?.formId[0]}
                  allowedDropEffect='move'
                  filterData={filterData}
                  column={'testttt'}
                  typesense
                  query={pipelineList?.query}
                  pipelineList={pipelineList}
                />
                {(pipelineList?.stages?.map((x, i) => {
                  return (
                    <LineColumnsComponent
                      name={pipelineList?.name}
                      // data={pipe}
                      // handleModalOpen={handleModalOpen}
                      key={i}
                      index={i}
                      kanbanData={{ name: x?.stageName }}
                      // formId={pipelineList?.formId[0]}
                      allowedDropEffect='move'
                      filterData={filterData}
                      column={x?.stageName}
                    />
                  );
                }))}
              </>
            }
          </HStack>
        </DndProvider>
      </Flex>

      <Modal
        isOpen={modalDetail}
        onClose={handleModalClose}
        isCentered
        size={'4xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack>
              <Text textTransform={'capitalize'}>Detail {detailActive.name}</Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={'sm'}>Add and edit opportunity details, tasks, notes and appointments.</Text>
            <Divider py={1} />
            <Stack>
              {detailActive && (
                <DetailPipelineCard
                  fetchData={fetchData}
                  pipeline={datas}
                  data={detailActive}
                  stages={pipelineList?.stages}
                  navigate={navigate}
                  handleModalClose={handleModalClose}
                />
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalAdd}
        onClose={handleModalAddClose}
        isCentered
        size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack>
              <Text textTransform={'capitalize'}>Add Contact</Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={'sm'}>Add and edit opportunity details, tasks, notes and appointments. </Text>
            <Divider py={1} />
            <Stack>
              {pipelineList && (
                <DetailPipelineAddCard
                  fetchData={fetchData}
                  handleSearchUsers={handleSearchUsers}
                  searchResult={searchResult}
                  formId={pipelineList?.formId?.[0]}
                  stages={pipelineList?.stages}
                  navigate={navigate}
                  handleModalAddClose={handleModalAddClose}
                />
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default RetentionPipelineViewPage;
