import React, { useState } from 'react';
import { Button,
  FormControl,
  FormLabel,
  Image,
  Select,
  Textarea,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  SimpleGrid,
  Stack,
  Text, 
  Box,
  useToast} from '@chakra-ui/react';
import PropTypes from 'prop-types'
import useWebsiteStore from '../../Hooks/Zustand/WebsiteStore';
import useUserStore from '../../Hooks/Zustand/Store';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';


const ModalEditHomeSection = ({modal,selectedHero,handleChangeHeroContent,handleChangeSectionDetail,handleChangeSectionImage}) => {
  const websiteStore = useWebsiteStore()
  const globalState = useUserStore()
  const [progress, setProgress] = useState()
  const toast = useToast()

  const addNewData = (title) => {
    let newDataObject = {};
    const currentPageHome = websiteStore?.pageHome
    const indexPage = selectedHero?.index

    if (title?.startsWith('Full Image') || title?.startsWith('Banner Slider')) {
      newDataObject = {
        image: 'https://example.com/new-image.jpg',
        link: 'example.com'
      };
    } else {
      newDataObject = {
        name: 'New Product',
        image: 'https://example.com/new-image.jpg',
        price: 0,
        discount: '0',
        link: 'https://example.com/new-link/',
      };
    } 

    currentPageHome[indexPage].data?.push(newDataObject);
    websiteStore?.setPageHome(currentPageHome)
  };

  const handleDelete = (selectedData, indexValue) => {
    const currentHomePage = websiteStore?.pageHome
    const currentSection = websiteStore?.pageHome[selectedHero?.index].data

    currentSection?.splice(indexValue,1);

    currentHomePage[selectedHero?.index].data = currentSection
    websiteStore?.setPageHome(currentHomePage)
  }

  const handleChangeImage = async (e,field,index, type) => {
    const file = e.target.files[0];

    globalState?.setIsLoading(true);
    const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/website-assets`;

    const currentPageHome = websiteStore?.pageHome

    globalState?.setIsLoading(true);
    try {
      const token = await getSingleDocumentFirebase('token','dropbox');
      const decryptResult = decryptToken(`${token?.access_token}`);

      const res = await UploadFileToDropbox(file,parentPath,decryptResult,setProgress,progress);

      if(type === 'imageData'){
        currentPageHome[selectedHero?.index].image = res?.link;
      } else {
        currentPageHome[selectedHero?.index].data[index][field] = res?.link
      }


      websiteStore?.setPageHome(currentPageHome)
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });    } finally {
      globalState?.setIsLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Content</ModalHeader>
        <ModalBody>
          <Stack>
            <Stack>
              <FormLabel>Header</FormLabel>
              <Input
                defaultValue={selectedHero?.header || ''}
                onChange={(e) => handleChangeHeroContent(e.target.value,'header')}
              />
            </Stack>
            <Stack>
              <FormLabel>Content</FormLabel>
              <Textarea
                defaultValue={selectedHero?.content || ''}
                onChange={(e) => handleChangeHeroContent(e.target.value,'content')}
              />
            </Stack>
            <Stack>
              <FormLabel>Image</FormLabel>
              <Input
                type='file'
                onChange={(e) => handleChangeImage(e,'image', '', 'imageData')}
              />
            </Stack>
            <Text align={'center'}>Asset Data</Text>

            <SimpleGrid  columns={2} spacing={3}>
              {selectedHero?.data?.map((x,i) => (
                <>
                  {selectedHero?.title?.startsWith('Full Image') || selectedHero?.title?.startsWith('Banner Slider') ? (
                    <Stack>
                      <Image src={x?.image} />
                      <Input size={'xs'} type='file' onChange={(e) => handleChangeImage(e,'image',i)} />
                      <Input size={'xs'} defaultValue={x?.link} onChange={(e) => handleChangeSectionDetail(e.target.value,'link',i,'edit')} />
                      <Button size={'xs'} colorScheme='red' onClick={() => handleDelete(x, i)}>Delete</Button>
                    </Stack>
                  ) : (
                    <Stack gap={2} p={3}>
                      <FormControl>
                        <FormLabel size={'sm'}>Product Name</FormLabel>
                        <Input size='xs' defaultValue={x?.name} onChange={(e) => handleChangeSectionDetail(e.target.value,'name',i,'edit')} />
                      </FormControl>
                      {x?.image1 ? (
                        <HStack justify={'center'}>
                          <Image maxW={100} src={x?.image1} alt={x?.name} />
                          <Image maxW={100} src={x?.image2} alt={x?.name} />
                        </HStack>
                      ) : (
                        <Image src={x?.image} alt={x?.name} />
                      )}
                      <HStack w={'100%'}>
                        <Text fontSize={10} w={'30%'}>Price</Text>
                        <Input w={'70%'} defaultValue={x?.price} size={'xs'} onChange={(e) => handleChangeSectionDetail(e.target.value,'price',i,'edit')} />
                      </HStack>
                      <HStack w={'100%'}>
                        <Text fontSize={10} w={'30%'}>Discount</Text>
                        <Input w={'70%'} defaultValue={x?.discount} size={'xs'} onChange={(e) => handleChangeSectionDetail(e.target.value,'discount',i,'edit')} />
                      </HStack>
                      <HStack w={'100%'}>
                        <Text fontSize={10} w={'30%'}>Status Product</Text>
                        <Select defaultValue={x?.status} w={'70%'} size={'xs'} onChange={(e) => handleChangeSectionDetail(e.target.value,'status',i,'edit')}>
                          <option value='ready'>Ready</option>
                          <option value='soldOut'>Sold Out</option>
                        </Select>
                      </HStack>
                      <HStack w={'100%'}>
                        <Text fontSize={10} w={'30%'}>Link product</Text>
                        <Input w={'70%'} defaultValue={x?.link} size={'xs'} onChange={(e) => handleChangeSectionDetail(e.target.value,'link',i,'edit')} />
                      </HStack>

                      <HStack>
                        <Text fontSize={10} w={'30%'}>Image 1</Text>
                        <Input w={'70%'} type='file' size={'xs'} onChange={(e) => handleChangeSectionImage(e,'edit',i,'image1')} />
                      </HStack>
                      <HStack>
                        <Text fontSize={10} w={'30%'}>Image 2</Text>
                        <Input w={'70%'} type='file' size={'xs'} onChange={(e) => handleChangeSectionImage(e,'edit',i,'image2')} />
                      </HStack>

                      <Button size={'xs'} colorScheme='red' onClick={() => handleDelete(x, i)}>Delete</Button>
                    </Stack>
                  )}
                </>
              ))}
            </SimpleGrid>

            <Box align={'center'}>
              <Button size={'xs'} colorScheme='blue' onClick={() => addNewData(selectedHero?.title, 'edit')}>Add More</Button>
            </Box>
            {/* </Stack> */}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              onClick={modal.onClose}
              size={'sm'}
              colorScheme='red'>
              Cancel
            </Button>
            <Button
              size={'sm'}
              colorScheme='green'
              onClick={modal.onClose}>
              Save Change
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditHomeSection;

ModalEditHomeSection.propTypes = {
  selectedHero: PropTypes.object,
  handleChangeHeroContent: PropTypes.func,
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func,
  modal: PropTypes.func,
  handleSave: PropTypes.func,
  setNewSectionInput: PropTypes.func,
  newSectionInput: PropTypes.func,
  handleChangeSectionDetail: PropTypes.func,
  addNewData: PropTypes.func,
  handleChangeSectionImage: PropTypes.func,
}