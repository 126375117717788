/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  HStack,
  Icon,
  Input,
  Spinner,
  Stack,
  Text,
  Flex,
  useToast,
} from '@chakra-ui/react';
import {
  FcFile,
} from 'react-icons/fc';
import parse from 'html-react-parser';
import { BsPaperclip } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import { IoSend } from 'react-icons/io5';
import DropboxUploader from '../../../Components/DropBox/DropboxUploader';
import _axios from '../../../Api/AxiosBarrier';
import {
  collection,
  getCountFromServer,
  limit,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from '@firebase/firestore';
import { db } from '../../../Config/firebase';
import { getSentiment } from '../../../Api/OpenAi';

const ChatPublicComponent = ({
  companyId,
  userId,
  projectId,
  idMessage,
  accessTokenDb,
  flowiseHost,
  flowiseKey,
  openAiKey,
  chatConfig,
}) => {
  const toast = useToast();
  const apiKey = `Bearer ${flowiseKey}`;

  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [value, setValue] = useState('');

  const [inputValue, setInputValue] = useState();
  const [chat, setChat] = useState([]);
  const [dataCustomer, setDataCustomer] = useState('');
  const [detailCustomer, setDetailCustomer] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [messageLoading, setMessageLoading] = useState(false);
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const itemsPerPage = 10;

  const handleWhatsapp = () => {
    const message = encodeURIComponent(
      'Hi Admin,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊'
    );
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=${chatConfig?.phoneNumber}&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  };

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><audio controls src="${link}"></audio></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><video controls src="${link}"></video></p>`;
      } else if (type === 'file') {
        htmlContent = `<p><iframe src="${link}"></iframe></p>`;
      } else {
        htmlContent = `<p>File: <a href="${link}" rel="noopener noreferrer" target="_blank">${link}</a></p>`;
      }


      setValue((prevContent) => prevContent + ` ${htmlContent}`);
      closeModal()
    }
  };

  useEffect(() => {
    if (!idMessage) return;
    const unsub = onSnapshot(doc(db, 'chat_customer', idMessage), (doc) => {
      const data = doc.data();
      setDataCustomer({ ...data, id: doc.id });
    });

    return () => {
      unsub();
    };
  }, [idMessage]);

  const getDataCustomer = async () => {
    if (!idMessage) return;
    const dataSend = {
      collection: 'users',
      doc: userId,
    };

    try {
      const res = await _axios.post('chat-getDocument', dataSend);
      setDetailCustomer(res?.data);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    if (!idMessage) return;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const limitValue = startIndex + itemsPerPage;
    const q = query(
      collection(db, 'chat'),
      where('projectId', '==', projectId),
      where('chatId', '==', idMessage),
      orderBy('createdAt', 'desc'),
      limit(limitValue)
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push(doc.data());
        });
        setChat(chats); // Set the chat data once outside the loop

        const collectionRef = collection(db, 'chat');
        const countQuery = query(
          collectionRef,
          where('projectId', '==', projectId),
          where('chatId', '==', idMessage)
        );

        getCountFromServer(countQuery)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            resolve(); // Resolve the promise when both data and count are fetched
          })
          .catch((error) => {
            reject(error); // Reject the promise in case of an error
          });
      });

      // Return the unsubscribe function to clean up the listener when needed
      return unsubscribe;
    });
  };

  const addChatCustomer = async (dataProps) => {
    const dataSend = {
      collection: 'chat',
      data: dataProps,
    };
    try {
      const res = await _axios.post('chat-addDocument', dataSend);
      return res;
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleMessage = async () => {
    setMessageLoading(true);
    setInputValue('');

    const sentimentData = await getSentiment(inputValue, openAiKey);

    const insertData = {
      companyId: companyId,
      projectId: projectId,
      message: inputValue ? inputValue : '',
      media: shareLink,
      characters: inputValue ? inputValue.length : 0,
      type: 'human',
      chatId: idMessage,
      user: userId,
      status: 'unread',
      platform: 'website',
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      sentiment: sentimentData.sentiment,
      token: sentimentData.totalTokens,
    };

    try {
      const userDoc = await addChatCustomer(insertData);

      if (userDoc?.docId) await updateChatCustomer(insertData);
      if (dataCustomer.type === 'AI') {
        // querying AI
        const query = {
          question: inputValue,
          overrideConfig: {
            sessionId: `${userId}-${projectId}`,
          },
        };
        const response = await fetch(flowiseHost, {
          headers: {
            Authorization: apiKey,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(query),
        });
        const result = await response.json();

        const characterOri = result?.text?.length;

        // insert to firestore AI
        const aiReply = {
          companyId: companyId,
          projectId: projectId,
          message: result.text,
          // characters: result.text.length,
          characters: characterOri,
          chatId: idMessage,
          type: 'AI',
          user: 'AI',
          createdAt: moment().unix(),
          lastUpdated: new Date(),
          status: 'read',
        };
        const aiDoc = await addChatCustomer(aiReply);

        if (aiDoc) await updateChatCustomer(aiReply);
      }

      setMessageLoading(false);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setInputValue('');
      setMessageLoading(false);
    } finally {
      setValue('');
      setInputValue('');
      setShareLink('');
      setMessageLoading(false);
    }
  };

  const updateChatCustomer = async (data) => {
    const dataSend = {
      collection: 'chat_customer',
      doc: `${userId}-${projectId}`,
      data: {
        message: data?.message,
        characters: data?.characters,
        createdAt: moment().unix(),
        lastUpdated: new Date(),
        status: 'unread',
        platform: 'website',
        sentiment: data?.sentiment,
        name: detailCustomer?.name || '',
        phoneNumber: `62${detailCustomer?.phoneNumber}` || '',
      },
    };
    try {
      await _axios.post('chat-setDocument', dataSend);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const separateByDate = (chat) => {
    const separatedChat = [];
    let currentDate = null;
    let currentChats = [];

    chat?.forEach((message) => {
      const messageDate = moment.unix(message.createdAt).format('YYYY-MM-DD');

      if (messageDate !== currentDate) {
        currentChats = [];
        separatedChat.push({ date: messageDate, chats: currentChats });
        currentDate = messageDate;
      }

      currentChats.push(message);
    });

    return separatedChat;
  };

  useEffect(() => {
    getData();
    getDataCustomer();
    inputRef?.current?.focus();

    return () => {
      setChat([]);
      setMessageLoading(false);
    };
  }, [currentPage, idMessage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
    scrollToBottom();
  };

  const handleChatKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleMessage();
    }
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <Stack>
      <Stack spacing={0}>
        <HStack
          bg={'blue.500'}
          p={5}
          borderTopRadius={10}
          justifyContent={'space-between'}
        >
          <HStack spacing={3}>
            <Avatar name="Admin">
              <AvatarBadge boxSize="20px" bg="green.400" border={'1px'} />
            </Avatar>
            <Stack spacing={0}>
              <Text fontWeight={'bold'} color={'white'}>
                Admin
              </Text>
              <Text color={'white'}>Support is online</Text>
            </Stack>
          </HStack>
          <HStack>
            <Button
              h={'50px'}
              w={'50px'}
              variant="none"
              bg="blue.600"
              borderRadius="full"
              _hover={{ bg: 'blue.800' }}
              onClick={handleWhatsapp}
            >
              <FaWhatsapp color="white" size={30} />
            </Button>
          </HStack>
        </HStack>
        <Stack spacing={0} bg={'white'} borderBottomRadius={10}>
          {value !== '' ? (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              h={'calc(100vh - 180px)'}
              overflowY={'auto'}
              bg={'blackAlpha.300'}
              p={10}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  bg: 'gray.400',
                  borderRadius: 'full',
                },
                '&::-webkit-scrollbar-track': {
                  bg: 'transparent',
                },
              }}
            >
              <Stack
                fontSize="2xs"
                alignItems="center"
                justifyContent={'center'}
              >
                {parse(value)}
                <HStack>
                  <DynamicButton
                    action={'delete'}
                    variant={'solid'}
                    color={'red'}
                    onClick={() => setValue('')}
                    size={'sm'}
                  />
                </HStack>
              </Stack>
            </Flex>
          ) : (
            <Stack
              overflowY="auto"
              direction={'column-reverse'}
              h={'calc(100vh - 180px)'}
              p={5}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  bg: 'gray.400',
                  borderRadius: 'full',
                },
                '&::-webkit-scrollbar-track': {
                  bg: 'transparent',
                },
              }}
            >
              <div ref={messagesEndRef} />

              {separateByDate(chat).map((dateChat, index) => (
                <Stack key={index} direction={'column-reverse'}>
                  {dateChat.chats.map((chatItem, chatIndex) => (
                    <HStack
                      key={chatIndex}
                      justifyContent={
                        chatItem.user === userId ? 'flex-end' : 'flex-start'
                      }
                      alignItems={'flex-start'}
                      my={1}
                    >
                      <Box
                        p={3}
                        bg={
                          chatItem.user === userId
                            ? 'blackAlpha.200'
                            : 'blue.500'
                        }
                        borderRadius={'lg'}
                        maxW={'70%'}
                        shadow={chatItem.user === userId ? 'none' : 'md'}
                      >
                        {chatItem.media?.type === 'image' && (
                          <a
                            href={chatItem?.media?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={chatItem?.media?.link}
                              alt="Image"
                              style={{ maxWidth: '100%', maxHeight: '300px' }}
                            />
                          </a>
                        )}
                        {chatItem?.media?.type === 'audio' && (
                          <a
                            href={chatItem?.media?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <audio style={{ maxWidth: '100%' }} controls>
                              <source src={chatItem?.media?.link} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </a>
                        )}
                        {chatItem.media?.type === 'video' && (
                          <a
                            href={chatItem.media?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <video
                              style={{ maxWidth: '100%', maxHeight: '300px' }}
                              controls
                            >
                              <source src={chatItem.media?.link} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </a>
                        )}
                        {chatItem.media?.type === 'file' && (
                          <a href={chatItem.media} target="_blank" rel="noreferrer">
                            <FcFile style={{ fontSize: '50px' }} />
                          </a>
                        )}
                        {chatItem.media && chatItem.media?.link && !chatItem.media?.type && (
                          <a href={chatItem.media} target="_blank" rel="noreferrer">
                            <FcFile style={{ fontSize: '50px' }} />
                          </a>
                        )}

                        <Text
                          fontWeight={500}
                          fontSize={'sm'}
                          textColor={
                            chatItem.user === userId ? 'black' : 'white'
                          }
                        >
                          {parse(
                            chatItem.message
                              ? chatItem.message
                                  .replace(/(\*\*)(.*?)\1/g, '<b>$2</b>')
                                  .replace(/(?:\r\n|\r|\n)/g, '<br>')
                                  .replace(
                                    /\[(.*?)\]\((.*?)\)/g,
                                    '<a href="$2" target="_blank" style="text-decoration: underline; color: white; font-weight: 800;">$1</a>'
                                  )
                              : ''
                          )}
                        </Text>
                        <HStack>
                          <Text
                            fontSize="xx-small"
                            color={
                              chatItem?.user === userId ? 'gray.500' : 'white'
                            }
                          >
                            {moment.unix(chatItem?.createdAt).format('MM/DD HH:mm')}
                          </Text>
                          <Text
                            fontSize="xx-small"
                            textTransform={'lowercase'}
                            color={
                              chatItem?.user === userId ? 'gray.500' : 'white'
                            }
                          >
                            by{' '}
                            {chatItem?.type === 'AI'
                              ? 'AI'
                              : chatItem.user !== userId
                              ? 'Admin'
                              : 'You'}
                          </Text>
                        </HStack>
                      </Box>
                    </HStack>
                  ))}
                  <Text
                    key={index}
                    textAlign="center"
                    size={'sm'}
                    my={2}
                    color={'gray.500'}
                  >
                      {moment(dateChat?.date?.seconds).format('LL')}
                  </Text>
                </Stack>
              ))}
              {currentPage < totalPages ? (
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <Button
                    onClick={handleLoadMore}
                    variant={'none'}
                    size={'sm'}
                    color={'black'}
                  >
                    Load More
                  </Button>
                </Flex>
              ) : (
                <></>
              )}
            </Stack>
          )}

          <HStack alignItems={'center'} justifyContent={'space-evenly'} p={5}>
            <Button
              variant={'none'}
              bg={'blue.500'}
              h={'50px'}
              onClick={openModal}
              isDisabled={value ? true : false}
            >
              <Icon as={BsPaperclip} color={'white'} />
            </Button>
            <Input
              ref={inputRef}
              h={'50px'}
              bg={'blackAlpha.200'}
              border={'1px solid'}
              borderColor={'gray.200'}
              color={'black'}
              placeholder={value ? 'Add caption...' : 'Type a message...'}
              value={inputValue}
              isDisabled={messageLoading}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleChatKeyDown}
            />

            <Button
              variant={'none'}
              bg={'blue.500'}
              h={'50px'}
              onClick={handleMessage}
            >
              {messageLoading ? (
                <Spinner color="white" />
              ) : (
                <Icon as={IoSend} color={'white'} />
              )}
            </Button>
          </HStack>
        </Stack>
      </Stack>
      <DropboxUploader
        accessTokenDb={accessTokenDb}
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${companyId || 'edrus'}/chat`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Stack>
  );
};

export default ChatPublicComponent;
