import React from 'react';
import ChatPublic from '../Pages/Chat/Public/ChatPublic';
// import ChatPublicIframe from '../Pages/Chat/Public/ChatPublicIframe';

const ChatPublicRouter = [
  {
    path: '/chat-public/:companyId',
    element: <ChatPublic />,
  },
  {
    path: '/chat-public/:companyId/:projectId',
    element: <ChatPublic />,
  },
  {
    path: '/chat-public/:companyId/:projectId/:chatConfigId',
    element: <ChatPublic />,
  },

  {
    path: '/chat-public/:companyId/:projectId/:chatConfigId/:userId',
    element: <ChatPublic />,
  },
];

export default ChatPublicRouter;
