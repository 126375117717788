import {
  Box, Button, Heading, HStack, Spacer, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, Skeleton, Image, InputGroup, Input, InputRightElement,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx'
import axios from 'axios';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import CardFile from '../../Components/Accounting/CardFile';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { addDocumentFirebase } from '../../Api/firebaseApi';

function BulkVoucher() {
  const globalState = useUserStore();
  const [file, setFile] = useState(null)
  const [transaction, setTransaction] = useState([])
  const [data, setData] = useState([]);
  const toast = useToast()

  const onSelectFile = (arg) => {
    setFile(arg)
  }

  // const fixedData = [];
  // const fixedArray = async (data) => {
  //   await Promise.all(data.map(async (x) => {
  //     if (x['NO.'] !== 0) {
  //       const dataOrder = {
  //         message: x['ID Pesanan'],
  //         amount: x['Total'],
  //         contact_information: {
  //           name: x['Nama Penerima'] || '',
  //           email: x['Email Penerima'] || '',
  //           address: x['Alamat Penerima'],
  //           phone_number: x['No.HP Penerima'] || ''
  //         },
  //         items: [
  //           {
  //             quantity: x['Jumlah'],
  //             shipping_status: '',
  //             shipping_provider: {
  //               description: '',
  //               from: '',
  //               note: '',
  //               provider: '',
  //               service: '',
  //               to: '',
  //               value: ''
  //             },
  //             images: [],
  //             name: x['Nama Produk'],
  //             delivery: {},
  //             price: x['Harga Awal Produk'],
  //             locations: x['Nama Gudang'],
  //             variant_skuId: x['SKU'] || '',
  //             variant: x['Variant Produk']
  //           }
  //         ],
  //         paymentStatus: x['Status'],
  //         paymentService: x['Pembayaran'],
  //         paymentGateway:'',
  //         userType:'costumer'
  //       };
  //       fixedData.push(dataOrder);
  //     } else {
  //       console.log('ini yang gaada no.');
  //       if (fixedData.length > 0) {
  //         const index = fixedData.length - 1;
  //         const newItem = {
  //           quantity: x['Jumlah'],
  //           shipping_status: '',
  //           shipping_provider: {
  //             description: '',
  //             from: '',
  //             note: '',
  //             provider: '',
  //             service: '',
  //             to: '',
  //             value: ''
  //           },
  //           images: x.images || [],
  //           name: x['Nama Produk'],
  //           delivery: x.delivery || {},
  //           price: x['Harga Awal Produk'],
  //           locations: x['Nama Gudang'],
  //           variant_skuId: x['SKU'] || '',
  //           variant: x['Variant Produk'],
  //         };
  //         fixedData[index]?.items.push(newItem);
  //       }
  //     }
  //   }));
  //   setTransaction(fixedData)
  // }

  const fixedArray = (data) => {
    const dats = []
    const varDatas = []
    data.map((x,index) => {
      const array = x.customerId?.split(',')
      const array2 = x.module?.split(',')
      const array3 = x.variantId?.split(',')
      const tabsData = {
        name : x.name || '',
        customer: x.customer || '',
        customerId: array || '',
        description: x.description || '',
        startDate: x.startDate || '',
        endDate: x.endDate || '',
        type: x.type || '',
        key: x.key || '',
        maxValue: x.maxValue || '',
        minimumPurchase: x.minimumPurchase || '',
        minimumQuantity: x.minimumQuantity || '',
        reusable: x.reusable || '',
        stock: x.stock || '',
        value: x.value || '',
        variantId: array3 || '',
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
      }
      dats.push(tabsData)
    })
    console.log(dats)
    setTransaction(dats)
  }
  const onImport = () => {
    if (!file) {
      return toast({
        title: 'Error',
        description: 'Not Have Data',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (file?.target?.files?.[0]?.name?.split('.').pop() !== 'xlsx') {
      return toast({
        title: 'Error',
        description: 'Invalid file type. Please select an XLSX file.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      console.log(worksheet,'works')
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log(jsonData,'json')
      // setTransaction([...jsonData])
      fixedArray(jsonData)
    }

    reader.readAsArrayBuffer(file.target.files[0]);
  }

  const saveDocument = async () => {
    try {
      if (!transaction || transaction.length === 0) {
        return toast({
          title: 'Error',
          description: 'Not Have Data, plese input file  first.',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      const push = transaction.map(async(x) => {
        const idProduct = await addDocumentFirebase('vouchers', x, globalState.currentCompany) 
      })
      // globalState.setIsLoading(true);
      // //   await addArrayDocumentFirebase(
      // //     'kpi',
      // //     transaction,
      // //   );
      // globalState.setIsLoading(false);
      // window.location.href = '/sopkpi/kpi-template';
      toast({
        title: 'Data saved to Firebase',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
      toast({
        title: 'Error saving data to Firebase',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const downloadTemplate = async () => {
    try {
      const spreadsheetUrl = 'https://docs.google.com/spreadsheets/d/11uJz62lqkEt99y5D_6_lI6N00Jxjd6YATAz3cEJL520/export?format=xlsx';
      const response = await axios.get(spreadsheetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template voucher.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading template:', error);
      // Handle error
    }
  };
  // console.log(transaction)
  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Bulk Variant</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5} textAlign="center">
          <CardFile onChange={onSelectFile} />

          <HStack mb={5} mt={5} justifyContent="center">
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Load Document'}
              onClick={() => onImport()}
            />
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Download Template'}
              onClick={downloadTemplate}
            />
          </HStack>

          <Stack alignItems={'end'} justifyContent="end" my={3}>
            <DynamicButton
              size={'sm'}
              action="create"
              title={'Save Document'}
              onClick={() => saveDocument()}
            />
          </Stack>

          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name </Th>
                  <Th>Customer</Th>
                  <Th>Customer ID</Th>
                  <Th>Type</Th>
                  <Th>Description</Th>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Key </Th>
                  <Th>Max Value</Th>
                  <Th>Stock</Th>
                  <Th>Minimum Purchase</Th>
                  <Th>Minimun Quantity</Th>
                  <Th>Reusable</Th>
                  <Th>Variant ID </Th>
                  <Th>Value </Th>
                </Tr>
              </Thead>
              <Tbody>
                {transaction?.map((x, i) => (
                  <Tr key={i}>
                    <Td> {x?.name} </Td>
                    <Td> {x?.customer} </Td>
                    <Td> 
                      <UnorderedList>
                        {x?.customerId?.map((y) => (
                          <ListItem key={y}>{y}</ListItem>
                        ))}
                      </UnorderedList>
                    </Td>                    
                    <Td> {x?.type} </Td>
                    <Td> {x?.description} </Td>
                    <Td> {x?.startDate} </Td>
                    <Td> {x?.endDate} </Td>
                    <Td> {x?.key} </Td>
                    <Td> {x?.maxValue} </Td>
                    <Td> {x?.stock} </Td>
                    <Td> {x?.minimumPurchase} </Td>
                    <Td> {x?.minimumQuantity} </Td> 
                    <Td> {x?.reusable} </Td>
                    <Td> 
                      <UnorderedList>
                        {x?.variantId?.map((y) => (
                          <ListItem key={y}>{y}</ListItem>
                        ))}
                      </UnorderedList>
                    </Td>   
                    <Td> {x?.value} </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

        </Box>
      </Box>
    </>
  );
}

export default BulkVoucher;