/* eslint-disable react/prop-types */
import {
  Button,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import RegionSelect from '../Select/RegionSelect';
import CardAddress from './CardAddress';
import Region from '../../Config/Json/Regencies.json';
import Provinsi from '../../Config/Json/Provinsi.json';
import ProvinceSelect from '../Select/ProvinceSelect';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import ShippingRajaOngkir from '../Shipment/ShippingRajaOngkir';
import JneForm from '../../Pages/Products/JneForm';
import DynamicButton from '../Buttons/DynamicButton';

function AddressCustomerIndex({ userData, refreshData }) {
  const toast = useToast();
  const update = useDisclosure();

  const [dataAddress, setDataAddress] = useState({});
  const [dataUser, setDataUser] = useState({});
  const [modalAddress, setModalAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: userData?.email,
    name: '',
    label: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
  });
  const [cityCode, setCityCode] = useState({
    jne: '',
    rajaOngkir: '',
    oExpress: '',
  });
  const [updateAddress, setUpdateAddress] = useState({
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
  });

  const getAddress = async () => {
    try {
      await getSingleDocumentFirebase('users', userData?.uid).then((res) => {
        setDataUser(res);
        setDataAddress(res?.address);
      });
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const handleDeleteAddress = async () => {
    try {
      await updateDocumentFirebase('users', userData?.uid, {
        address: null,
      });
      toast({
        title: 'Success delete address',
        status: 'success',
        isClosable: true,
        duration: 5000,
        position: 'top',
      });
      await getAddress();
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        ...formData, ...updateAddress
      };
      await updateDocumentFirebase('users', userData?.uid, data);
      toast({
        title: 'Deoapp',
        description: 'Success save address',
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      await getAddress();
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
      setModalAddress(false);
      setFormData({
        address: {
          street: '',
          city: '',
          state: '',
          postal_code: '',
          country: '',
        },
      });
      refreshData();
      update.onClose()
    }
  };

  const handleOpenModalAddress = () => {
    setModalAddress(true);
  };

  const handleCloseModalAddress = () => {
    setModalAddress(false);
    setFormData({
      email: userData.email,
      name: '',
      label: '',
      phone_number: '',
      address: {
        street: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
      },
    });
  };

  useEffect(() => {
    getAddress();
  }, [userData]);

  const handleEditAddress = (data) => {
    update.onOpen();
    setFormData({
      address: {
        street: data?.address?.street,
        city: data?.address?.city,
        state: data?.address?.state,
        postal_code: data?.address?.postal_code,
        country: data?.address?.country,
      },
    });
  };


  return (
    <Stack p={[2, 2, 5]} spacing={4}>
      <HStack>
        <Text fontSize={'lg'} fontWeight="bold">
          Address
        </Text>
        <Spacer />
        <DynamicButton
          action={'create'}
          onClick={handleOpenModalAddress}
          size={'sm'}
          variant={'solid'}
          title={'New Address'}
        />
      </HStack>

      <Stack spacing={0}>
        <Text fontWeight={'semibold'}>
          {dataUser?.name} ({dataUser?.phone_number})
        </Text>
        {dataAddress ? (
          <CardAddress
            data={dataAddress}
            handleDeleteAddress={() => handleDeleteAddress()}
            handleEditAddress={() => handleEditAddress(dataAddress)}
          />
        ) : (
          <Text>No Data Address</Text>
        )}
      </Stack>

      <Modal
        isOpen={modalAddress}
        onClose={handleCloseModalAddress}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Setting Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <SimpleGrid columns={[1, 2, 2]} gap={3}>
                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    Receiver{' '}
                  </Text>
                  <Input
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    Label Address{' '}
                  </Text>
                  <Input
                    placeholder="ex: home / apartement"
                    value={formData.label}
                    onChange={(e) =>
                      setFormData({ ...formData, label: e.target.value })
                    }
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    Phone Number Receiver{' '}
                  </Text>
                  <Input
                    placeholder="ex: 085256123456"
                    value={formData.phone_number}
                    onChange={(e) =>
                      setFormData({ ...formData, phone_number: e.target.value })
                    }
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    City Address
                  </Text>
                  <RegionSelect
                    formData={formData}
                    setFormData={setFormData}
                    Provinsi={Region}
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    State Address
                  </Text>
                  <ProvinceSelect
                    formData={formData}
                    setFormData={setFormData}
                    Provinsi={Provinsi}
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    Country
                  </Text>
                  <Input
                    placeholder="Ex: Indonesia"
                    value={formData.address.country}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: {
                          ...formData.address,
                          country: e.target.value,
                        },
                      })
                    }
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    Street Address
                  </Text>
                  <Textarea
                    placeholder="ex: Jl. Gardu"
                    value={formData.address.street}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: {
                          ...formData.address,
                          street: e.target.value,
                        },
                      })
                    }
                  />
                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                    Postal Code
                  </Text>
                  <Input
                    placeholder="Postal Code"
                    value={formData.address.postal_code}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        address: {
                          ...formData.address,
                          postal_code: e.target.value,
                        },
                      })
                    }
                  />
                </Stack>
              </SimpleGrid>
              <Heading size="md">Raja Ongkir :</Heading>
              <ShippingRajaOngkir
                cityCode={cityCode}
                setCityCode={setCityCode}
              />

              <Heading size="md">Jne:</Heading>
              <JneForm cityCode={cityCode} setCityCode={setCityCode} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button colorScheme={'red'} onClick={handleCloseModalAddress}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={update.isOpen} onClose={update.onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={[1, 2, 2]} gap={3}>
              <Stack>
                <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                  City Address
                </Text>
                <RegionSelect
                  formData={updateAddress}
                  setFormData={setUpdateAddress}
                  Provinsi={Region}
                />
              </Stack>

              <Stack>
                <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                  State Address
                </Text>
                <ProvinceSelect
                  formData={updateAddress}
                  setFormData={setUpdateAddress}
                  Provinsi={Provinsi}
                />
              </Stack>

              <Stack>
                <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                  Country
                </Text>
                <Input
                  placeholder="Ex: Indonesia"
                  value={updateAddress.address.country}
                  onChange={(e) =>
                    setUpdateAddress({
                      address: {
                        ...updateAddress.address,
                        country: e.target.value,
                      },
                    })
                  }
                />
              </Stack>

              <Stack>
                <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                  Postal Code
                </Text>
                <Input
                  placeholder="Postal Code"
                  value={updateAddress.address.postal_code}
                  onChange={(e) =>
                    setUpdateAddress({
                      address: {
                        ...updateAddress.address,
                        postal_code: e.target.value,
                      },
                    })
                  }
                />
              </Stack>
            </SimpleGrid>
            <Stack>
              <Text fontWeight={500} fontSize="sm" color={'gray.700'}>
                Street Address
              </Text>
              <Textarea
                placeholder="ex: Jl. Gardu"
                value={updateAddress.address.street}
                onChange={(e) =>
                  setUpdateAddress({
                    address: {
                      ...updateAddress.address,
                      street: e.target.value,
                    },
                  })
                }
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={update.onClose}>
              Close
            </Button>
            <DynamicButton
              isLoading={loading}
              onClick={handleSubmit}
              action={'update'}
              title={'update'}
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default AddressCustomerIndex;
