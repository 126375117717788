/* eslint-disable react/prop-types */
import React from 'react';
import { Text, Flex, Select, useColorModeValue, Box } from '@chakra-ui/react';
import { formatFrice } from '../../Utils/Helper';
import { CheckoutProductMeta } from './CheckoutProductMeta';
const QuantitySelect = ({ quantity, onChange, ...props }) => {
  return (
    <Select
      maxW="70px"
      size={'sm'}
      aria-label="Select quantity"
      focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
      value={quantity}
      onChange={onChange}
      {...props}
      placeholder='Qty'
    >
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
    </Select>
  );
};

export const CheckoutItem = ({ item, onChangeQuantity, variantList }) => {
  const {
    productData: { name, images, price, currency },
    quantity,
    variantData: { name: variantName },
  } = item;

  return (
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
      justify="space-between"
      align="center"
      border={'1px solid'}
      p={'4'}
      borderRadius={'lg'}
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      my={2}
    >
      <CheckoutProductMeta
        name={name}
        description={variantName}
        variantList={variantList}
        image={images[0]}
      />

      {/* Desktop */}
      <Flex
        width="full"
        justify="space-between"
        align={'center'}
        display={{
          base: 'none',
          md: 'flex',
        }}
      >
        <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(e.currentTarget.value);
          }}
        />

        <Text fontWeight={'bold'}>
          {currency ? currency : 'IDR'} {formatFrice(price)}
        </Text>
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(+e.currentTarget.value);
          }}
        />
        <Text fontWeight={'bold'}>Rp. {formatFrice(price)}</Text>
      </Flex>
    </Flex>
  );
};
