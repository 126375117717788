/* eslint-disable react/prop-types */
import { AddIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Divider,
  Grid,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, {  useRef, useState } from 'react';
import {
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice } from '../../Utils/Helper';

function DetailPipelineCard({
  data,
  stages,
  handleModalClose,
  fetchData,
}) {
  const toast = useToast();

  const globalState = useUserStore();
  const nameRef = useRef(data?.name);
  const emailRef = useRef(data?.email);
  const phoneRef = useRef(data?.phone);
  const columnRef = useRef(data?.column);
  // const detailsRef = useRef(data?.details || []);

  const [isLoading, setIsLoading] = useState(false);

  // const [, forceUpdate] = useReducer((x) => x + 1, 0);


  const handleSaveData = async () => {
    const updatedData = {
      name: nameRef.current?.value,
      email: emailRef.current?.value,
      phone: phoneRef.current?.value,
      column: columnRef.current?.value,
      // status: statusRef.current?.value,
      // source: sourceRef.current?.value,
      // opportunity_value: opportunityValueRef.current?.value,
      // details: detailsRef.current
    };

    const collectionName = 'customer';
    const docName = data.id;
    const value = updatedData;

    setIsLoading(true);
    try {
      const result = await setDocumentFirebase(
        collectionName,
        docName,
        value,
        globalState.currentCompany
      );
      handleModalClose();

      toast({
        title: 'Deoapp.com',
        description: 'success update customer',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      if (result) {
        fetchData();
      }

    } catch (error) {

      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleDetailChange = (index, key, value, timeFrom, timeTo) => {
  //   const updatedDetails = [...detailsRef.current];
  //   updatedDetails[index] = {
  //     key: key || '',
  //     value: value || '',
  //     timeFrom: timeFrom || '',
  //     timeTo: timeTo || '',
  //   };
  //   detailsRef.current = updatedDetails;
  //   forceUpdate()
  // };

  // const navigateToContact = async () => {
  //   console.log(data, 'data');
  //   try {
  //     const result = await getSingleDocumentFirebase(
  //       'contacts',
  //       `${data?.phone || data?.phone_number}-${globalState.currentProject}`
  //     );
  //     console.log(result, 'result')
  //     // if (result) {
  //     //   navigate(
  //     //     `/contacts/detail/${data?.phone}-${globalState.currentProject}`,
  //     //     {
  //     //       state: {
  //     //         result: result,
  //     //         pipeline: pipeline,
  //     //         price: opportunityValueRef?.current?.value,
  //     //       },
  //     //     }
  //     //   );
  //     // } else {
  //     //   toast({
  //     //     title: 'Deoapp.com',
  //     //     description: 'You dont have any contact',
  //     //     status: 'error',
  //     //     position: 'top-right',
  //     //     isClosable: true,
  //     //   });
  //     // }
  //   } catch (error) {
  //     toast({
  //       title: 'Deoapp.com',
  //       description: error.message,
  //       status: 'error',
  //       position: 'top-right',
  //       isClosable: true,
  //     });
  //   }
  // };

  // const handleAddDetail = () => {
  //   detailsRef.current = [...detailsRef.current, { key: '', value: '', timeFrom: '', timeTo: '' }];
  //   forceUpdate()
  // };

  // const handleRemoveDetail = (index) => {
  //   const updatedDetails = [...detailsRef.current];
  //   updatedDetails.splice(index, 1);
  //   detailsRef.current = updatedDetails;
  //   forceUpdate()
  // };

  const convert = (time) => {
    const timestamp = time;
    const date = new Date(timestamp * 1000);

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayName = days[date.getDay()];
    const dateOfMonth = date.getDate();
    const monthName = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const result = `${dayName}, ${dateOfMonth} ${monthName} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return result
  }
  console.log(data)

  return (
    <Stack>
      <Stack>
        <Grid templateColumns={{ base: '1fr', md: '1fr' }}>
          {/* <Stack py={2}>
                        <Text fontWeight={500}>Opportunity Details</Text>
                    </Stack> */}
          <Stack h={'550px'} overflowY="scroll">
            <Stack py={2}>
              <Text fontWeight={500}>Contact Details</Text>
            </Stack>

            <Divider />
            <SimpleGrid columns={[2]} gap={3} fontSize="sm">
              <Stack>
                <Text>Contact Name</Text>
                <Input defaultValue={data?.name} ref={nameRef} />
              </Stack>

              <Stack>
                <Text>Email</Text>
                <Input defaultValue={data?.email} ref={emailRef} />
              </Stack>

              <Stack>
                <Text>Phone</Text>
                <Input defaultValue={data?.phone || data?.phone_number} ref={phoneRef} />
              </Stack>

              <Stack>
                <Text>Uid</Text>
                <Text>{data?.uid}</Text>
              </Stack>
            </SimpleGrid>

            <Divider pt={3} />

            <Stack>
              <Text fontWeight={500}>Opportunity Details</Text>
            </Stack>

            <Divider />

            <SimpleGrid columns={[2]} gap={3} fontSize="sm">
              <Stack>
                <Text>Name</Text>
                <Input defaultValue={data?.name} ref={nameRef} />
              </Stack>

              <Stack>
                <Text>Stage</Text>
                <Select
                  defaultValue={data?.column}
                  variant="outline"
                  fontWeight="normal"
                  placeholder='select columns'
                  ref={columnRef}
                >
                  {stages?.length > 0 &&
                    stages.map((x, index) => {
                      return (
                        <option key={index} value={x.stageName}>
                          {x.stageName}
                        </option>
                      );
                    })}
                </Select>
              </Stack>

              {/*  <Stack>
                <Text>Status</Text>
                <Select
                  defaultValue={data?.status}
                  variant="outline"
                  fontWeight="normal"
                  placeholder='select status'
                  ref={statusRef}
                >
                  <option value={'open'}>Open</option>
                  <option value={'won'}>Won</option>
                  <option value={'lost'}>Lost</option>
                </Select>
              </Stack> */}
              {/* <Stack>
                <Text>Source</Text>
                <Input defaultValue={data?.source} ref={sourceRef} />
              </Stack> */}

              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">LTV</Text>
                <Text color="gray">IDR {formatFrice(data?.lifetime_value)}</Text>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">AOV</Text>
                <Text color="gray">IDR {formatFrice(data?.average_order_value)}</Text>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Last Transaction</Text>
                <Text color="gray">{convert(data?.last_transaction?.seconds)}</Text>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Last Checkout Value</Text>
                <Text color="gray">IDR {formatFrice(data?.last_checkout_value)}</Text>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Total Initiated Transaction</Text>
                <HStack>
                  <Badge colorScheme="red">{data?.total_transaction || 0}</Badge>
                </HStack>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Total Paid Transaction</Text>
                <HStack>
                  <Badge colorScheme="green">{data?.total_paid_transaction || 0}</Badge>
                </HStack>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Last Added Cart</Text>
                <Text color="gray">{data?.last_added_cart || '-'}</Text>
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Order Ids</Text>
                <Text color="gray">{data?.orderIds?.join(', ')}</Text>
                {/* <Text>{JSON.stringify(data)}</Text> */}
              </Stack>
              <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Last Transaction Amount</Text>
                <Text color="gray">IDR{formatFrice(data?.last_transaction_amount)}</Text>
                {/* <Text>{JSON.stringify(data)}</Text> */}
              </Stack>
              {/* <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Order Ids</Text>
                <Text color="gray">{JSON.stringify(data)}</Text>
              </Stack> */}
            </SimpleGrid>
          </Stack>
        </Grid>
        {/* <Grid gap={3} templateColumns={{ base: '1fr', md: '1fr' }}>
          <Stack w={'100%'}>
            <Text fontWeight={'bold'}>Details Card :</Text>
          </Stack>
          <Stack>
            {detailsRef?.current?.length > 0 && (
              detailsRef?.current?.map((detail, index) => (
                <HStack key={index} align={'center'} justify={'center'}>
                  <FormControl id={`detail-key-${index}`}>
                    <FormLabel>Point:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Title detail, ex: location"
                      value={detail?.key}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          e.target.value,
                          detail?.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Time From:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeFrom}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          e.target.value,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Time To:</FormLabel>
                    <Input
                      size={'sm'}
                      defaultValue={detail?.timeTo}
                      type="date"
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          detail?.value,
                          detail?.timeFrom,
                          e.target.value)
                      }
                    />
                  </FormControl>

                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Value:</FormLabel>
                    <Input
                      size={'sm'}
                      type="text"
                      placeholder="Value detail, ex: location"
                      value={detail?.value}
                      onChange={(e) =>
                        handleDetailChange(
                          index,
                          detail?.key,
                          e.target.value,
                          detail?.timeFrom,
                          detail?.timeTo
                        )
                      }
                    />
                  </FormControl>
                  <Box pt="7">
                    <DynamicButton variant={'solid'} action='delete' onClick={() => handleRemoveDetail(index)} size="sm" />
                  </Box>
                </HStack>
              ))
            )}

          </Stack>
          <Stack>
            <DynamicButton size={'sm'} variant={'solid'} title='Point' action='create' onClick={handleAddDetail} />

          </Stack>
        </Grid> */}
        <HStack
          gap={5}
          py={3}
          alignItems="flex-end"
          justifyContent={'flex-end'}
        >
          <Button
            colorScheme="green"
            onClick={() =>
              window.open(
                `https://wa.me/62${data?.phone_number.slice(1)}`,
                '_blank'
              )
            }
          >
            Chat via Whatsapp
          </Button>
          {/* <Button colorScheme="green" onClick={navigateToContact}>
            Go to contact
          </Button> */}
          <Button
            isLoading={isLoading}
            leftIcon={<AddIcon boxSize={3} />}
            colorScheme="green"
            onClick={handleSaveData}
          >
            Save
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default DetailPipelineCard;
