import { Box, Center, Stack, Text, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types';
import Select from 'react-select'


const JneForm = (
  { 
    cityCode,
    setCityCode,
  }
) => {
  const [cityResults, setCityResults] = useState([]);
  const [fetchingDestinations, setFetchingDestinations] = useState(false);

  const toast = useToast()


  const getDestination = async () => {
    setFetchingDestinations(true);
    const url = 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/shipping-getOriginJne'
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      data: {
        is_production: true
      }
    };
    try {
      const result = await axios(config);
      if (result?.data?.data !== undefined && result?.data?.data?.length > 0) {
        setCityResults(result?.data?.data);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setFetchingDestinations(false);
    }
  };

  useEffect(() => {
    getDestination();
  }, []);


  const handleChange = (selectedOption) => {
    setCityCode({
      ...cityCode,
      jne: selectedOption.value
    });
  }



  return (
    <>
      <Center my={3}>
        <Box w='md'>
          <Stack gap={3}>
            <Text fontWeight='bold' mt={10}> Kota:</Text>
            {cityCode?.jne ? <Text>{cityCode?.jne}</Text> : ''}
            <Select
              options={cityResults?.map((x) => ({ ...x, label: x.City_Name, value: x?.City_Code }))}
              placeholder="Tulis untuk mencari kota"
              onChange={handleChange}
              value={cityResults.find((option) => option.value === cityCode?.jne)}
              isSearchable
            />
           
          </Stack>
        </Box>
      </Center>
    </>
  )
}

export default JneForm



JneForm.propTypes = {
  selectedDestination: PropTypes.string,
  setSelectedDestination: PropTypes.string,
  selectedCourier: PropTypes.string,
  setSelectedCourier: PropTypes.string,
  setFullAddress: PropTypes.string,
  setFormValues: PropTypes.string,
  cityCode: PropTypes.string,
  setCityCode: PropTypes.func
}