/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { Icon, InfoIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import {
  addDoc,
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { BsPaperclip, BsRobot, BsWhatsapp } from 'react-icons/bs';
import { updateDocumentFirebase } from '../../Api/firebaseApi';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { readMore } from '../../Utils/Helper';
import { sendReplyWhatsapp } from '../../Api/sendWhatsappApi';
import LabelButtonChat from '../../Components/Buttons/LabelButtonChat';
import parse from 'html-react-parser';
import DropboxUploader from '../../Components/DropBox/DropboxUploader';
import { IoSend } from 'react-icons/io5';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import ChatSendBroadcast from './Components/ChatSendBroadcast';
import ChatCard from './Components/ChatCard';
import ChatCardReply from './Components/ChatCardReply';


function ChatComponentAdmin(props) {
  if (!props) return;

  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [value, setValue] = useState('');


  const [chat, setChat] = useState([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [inputValue, setInputValue] = useState();
  const globalState = useUserStore();

  const [searchInput, setSearchInput] = useState('');

  const messagesEndRef = useRef(null);

  const isActiveRef = useRef(props?.data?.type === 'AI' ? true : false);
  const isActiveWaRef = useRef(
    props?.data?.platform === 'maxchat' ? true : false
  );


  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const currentLockRef = useRef()

  const [chatReply, setChatReply] = useState('')

  const { colorMode } = useColorMode();


  const toast = useToast();

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><audio controls src="${link}"></audio></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><video controls src="${link}"></video></p>`;
      } else if (type === 'file') {
        htmlContent = `<p><iframe src="${link}"></iframe></p>`;
      } else {
        htmlContent = `<p>File: <a href="${link}" rel="noopener noreferrer" target="_blank">${link}</a></p>`;
      }

      setValue((prevContent) => prevContent + ` ${htmlContent}`);
      closeModal()
    }
  };

  const updateChatCustomer = async (data) => {
    const message = {
      message: data?.message,
      characters: data?.characters,
      createdAt: moment().unix(),
      lastUpdated: new Date(),
      status: 'read',
    };
    try {
      await updateDocumentFirebase('chat_customer', props?.data?.id, message);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleMessage = async () => {
    setMessageLoading(true);
    setInputValue('');

    try {
      // insert to firestore human
      const insertData = {
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
        message: inputValue ? inputValue : '',
        media: shareLink,
        characters: inputValue ? inputValue.length : 0,
        type: 'human',
        chatId: props?.data?.id,
        user: globalState?.uid,
        status: 'read',
        medium: 'web',
        createdAt: moment().unix(),
        lastUpdated: new Date(),
      };

      if (chatReply !== '') {
        insertData.reply = chatReply || ''
        insertData.message = `Message : ${chatReply.message} \n \n Answer : ${inputValue}`
      }
      const userDoc = await addDoc(collection(db, 'chat'), insertData);

      if (userDoc) {
        await updateChatCustomer(insertData);
      }

      if (props?.data?.platform === 'maxchat') {
        if (props?.data?.phoneNumber === undefined) {
          toast({
            title: 'Deoapp.com',
            description: 'this account dont have any number phone',
            status: 'warning',
            position: 'top-right',
            isClosable: true,
          });
          return;
        }
        await sendReplyWhatsapp(
          insertData?.message,
          props?.data?.phoneNumber,
          props?.chatConfig?.maxchat_key
        );
      }

      setMessageLoading(false);
      setValue('');
      setInputValue('');
      setShareLink('');
      setChatReply('')

      return result;
    } catch (error) {
      setMessageLoading(false);
    }
  };


  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const getData = async () => {
    if (!props?.data?.id) return;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const limitValue = startIndex + itemsPerPage;
    const q = query(
      collection(db, 'chat'),
      where('projectId', '==', globalState.currentProject),
      where('chatId', '==', props.data.id),
      orderBy('createdAt', 'desc'),
      limit(limitValue)
    );

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push({ ...doc.data(), id: doc.id });
        });

        const chatsWa = chats.filter((x) => x.platform === 'maxchat')


        if (
          props?.data?.platform === 'maxchat' && 
          chatsWa.length > 0 &&
          chatsWa[0]?.user !== globalState.uid &&
          chatsWa[0]?.platform === 'maxchat' &&
          (Date.now() - chatsWa[0]?.createdAt * 1000) > 24 * 60 * 60 * 1000
        ) {
          currentLockRef.current = true
        } else {
          currentLockRef.current = false
        }
        setChat(chats); // Set the chat data once outside the loop


        const collectionRef = collection(db, 'chat');
        const countQuery = query(
          collectionRef,
          where('projectId', '==', globalState.currentProject),
          where('chatId', '==', props.data.id)
        );

        getCountFromServer(countQuery)
          .then((snapshot) => {
            const assessmentLength = snapshot.data().count;
            setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
            resolve(); // Resolve the promise when both data and count are fetched
          })
          .catch((error) => {
            reject(error); // Reject the promise in case of an error
          });
      });

      // Return the unsubscribe function to clean up the listener when needed
      return unsubscribe;
    });
  };

  useEffect(() => {
    getData();

    return () => {
      setChat([]);
      setMessageLoading(false);
    };
  }, [props?.data?.id, currentPage]);



  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
    scrollToBottom();
  };

  const handleAiActive = async (type) => {
    let data;

    if (type === 'ai') {
      isActiveRef.current = !isActiveRef.current;
      data = {
        type: isActiveRef.current === true ? 'AI' : 'human',
      };
    }
    if (type === 'wa') {
      isActiveWaRef.current = !isActiveWaRef.current;
      data = {
        platform: isActiveWaRef.current === true ? 'maxchat' : 'website',
      };
    }

    try {
      await updateDocumentFirebase('chat_customer', props?.data?.id, data);
      toast({
        title: 'Deoapp.com',
        description: `${type === 'ai' ? data.type : data.platform} mode has active`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const [highlightedMessages, setHighlightedMessages] = useState([]);

  const handleSearch = (q) => {
    setSearchInput(q);
  };

  useEffect(() => {
    if (searchInput.trim() === '') {
      setHighlightedMessages([]);
      return;
    }

    const lowercaseSearchInput = searchInput.toLowerCase();
    const highlighted = chat.map((message) => {
      const regex = new RegExp(lowercaseSearchInput, 'gi');
      const highlightedText = message.message.replace(
        regex,
        (match) => `<span style="background-color: #D69E2E">${match}</span>`
      );
      return {
        ...message,
        highlightedText: highlightedText,
      };
    });

    setHighlightedMessages(highlighted);
  }, [searchInput, chat]);

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <Stack shadow={'md'} h={'full'}>
      <Flex  
        bgColor={colorMode === 'light' ? 'blue.500' : ''}
        p={6}
        color={colorMode === 'light' ? 'white' : ''}
        shadow={'md'}
      >
        <HStack spacing="4" flex="1"
        >
          <Avatar name={props?.data?.name} src={props?.data?.name} />
          <Stack spacing={1}>
            <Text fontWeight={'bold'} textTransform={'Capitalize'}>
              {readMore(props?.data?.name, 10)}
            </Text>
            {props?.data?.phoneNumber && (
              <Text fontSize="sm">+{props?.data?.phoneNumber}</Text>
            )}
          </Stack>

          <Spacer />

          <HStack  color={colorMode === 'light' ? 'black' : 'white'}>
            <Input
              size={'sm'}
              bgColor={'whitesmoke'}
              borderRadius={'md'}
              type="text"
              color={'black'}
              placeholder={`Search Message from ${props?.data?.name}`}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <LabelButtonChat
              docId={props?.data?.id}
            />
          </HStack>
          <Stack>
            <HStack>
              <BsRobot />
              <Switch
                isChecked={props?.data?.type === 'AI' ? true : false}
                onChange={() => handleAiActive('ai')}
                size={'sm'}
              />
              <Tooltip
                label="Run AI for this conversation (only for positive sentiment users and chat bot)"
                fontSize="sm"
              >
                <InfoIcon />
              </Tooltip>
            </HStack>

            <HStack>
              <BsWhatsapp />
              <Switch
                isChecked={props?.data?.platform === 'maxchat' ? true : false}
                onChange={() => handleAiActive('wa')}
                size={'sm'}
              />
              <Tooltip
                label="Run WA for this conversation integration with your whatsapp"
                fontSize="sm"
              >
                <InfoIcon />
              </Tooltip>
            </HStack>
          </Stack>
        </HStack>
      </Flex>

      {value !== '' ? (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          h={'calc(100vh - 180px)'}
          overflowY={'auto'}
          bg={'blackAlpha.300'}
          p={10}
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: 'gray.400',
              borderRadius: 'full',
            },
            '&::-webkit-scrollbar-track': {
              bg: 'transparent',
            },
          }}
        >
          <Stack fontSize="2xs" alignItems="center" justifyContent={'center'}>
            {parse(value)}
            <HStack>
              <DynamicButton
                action={'delete'}
                variant={'solid'}
                color={'red'}
                onClick={() => setValue('')}
                size={'sm'}
              />
            </HStack>
          </Stack>
        </Flex>
      ) : (
        <Stack
          flex="1"
          overflow="auto"
          px="5"
          py="4"
          direction={'column-reverse'}
        >
          {
            chat.length > 0 ? (
              chat?.map((x, i) => (
                <Stack key={i} m="1">
                  <ChatCard
                    x={x}
                    globalState={globalState}
                    setChatReply={setChatReply}
                    chatReply={chatReply}
                    highlightedMessages={highlightedMessages}
                    i={i}
                  />
                </Stack>
              ))
            ) : (
              <Stack  m="1" flex={1} alignItems={'center'} justifyContent={'center'}>
                <Heading size={'lg'} fontWeight={'bold'} letterSpacing={0.5} textTransform={'uppercase'} color={'gray.500'}>New Convertion Leads</Heading>
                <Text fontSize={'sm'}  textAlign={'uppercase'}>Untuk memulai percakapan, silahkan kirimkan pesan .</Text>
              </Stack> 
            )
          }
          {currentPage < totalPages ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Button variant={'none'} size={'sm'} onClick={handleLoadMore}>
                Load more
              </Button>
            </Flex>
          ) : (
            <></>
          )}
          <div ref={messagesEndRef} />
        </Stack>
      )}


      <Box bg="bg.subtle" py="4" px="5" borderTopWidth="1px">
        {chatReply !== '' && (
          <Stack m="1">
            <ChatCardReply
              x={chatReply}
              globalState={globalState}
              setChatReply={setChatReply}
              chatReply={chatReply}
            />
          </Stack>
        )}
        <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
          <Stack>
            <IconButton
              variant={'ghost'}
              size={'sm'}
              aria-label="Attach file"
              icon={<Icon as={BsPaperclip} fontSize="md" />}
              onClick={openModal}
            />
          </Stack>

          {currentLockRef.current === true ? (
            <InputGroup>
              <InputLeftElement>
              </InputLeftElement>
              <Textarea
                placeholder={'You cannot send message and you must broadcast message'}
                fontSize="sm"
                isDisabled={true}
              />
              <InputRightElement>
                <HStack>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    isDisabled={true}
                    aria-label="Send message"
                    icon={<Icon as={IoSend} fontSize="md" />}
                  />
                </HStack>
              </InputRightElement>
            </InputGroup>
          ) : (
            <InputGroup>
              <InputLeftElement>


              </InputLeftElement>
              <Textarea
                placeholder={value ? 'Add caption...' : 'Type a message...'}
                fontSize="sm"
                value={inputValue}
                isDisabled={messageLoading}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.shiftKey) {
                  // Jangan melakukan apa pun jika tombol Enter ditekan bersamaan dengan tombol Shift
                    return;
                  }
                  if (e.key === 'Enter') {
                    handleMessage();
                  }
                }}
              />
              <InputRightElement>
                {messageLoading ? (
                  <Spinner />
                ) : (
                  <HStack>
                    <IconButton
                      size="sm"
                      variant="ghost"
                      aria-label="Send message"
                      icon={<Icon as={IoSend} fontSize="md" />}
                      onClick={handleMessage}
                    />
                  </HStack>
                )}
              </InputRightElement>
            </InputGroup>
          )}



          <Stack>
            {props?.data?.platform === 'maxchat' && props?.chatConfig?.templateFirst !== undefined && (
              <ChatSendBroadcast globalState={globalState} props={props} />
            )}
          </Stack>
        </HStack>

      </Box>

      <DropboxUploader
        accessTokenDb={props?.accessTokenDb}
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${props?.companyId || 'edrus'}/chat`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />
    </Stack>
  );
}

export default ChatComponentAdmin;
