import React from 'react';
import ContactsPage from '../Pages/Contacts/ContactsPage';
import ContactDetailsPage from '../Pages/Contacts/ContactsDetailPage';
import RetentionPage from '../Pages/Customer/RetentionPage';
import RetentionPipelineViewPage from '../Pages/Customer/RetentionPipelineViewPage';
import PipelineCreatePage from '../Pages/Customer/PipelineCreatePage';
import CustomerHomePage from '../Pages/Customer/CustomerHomePage';
import AcquisitionPipelinePageV2 from '../Pages/Customer/AcquisitionPipelinePageV2';
import AcquisitionPipelineViewPageV3 from '../Pages/Customer/AcquisitionPipelineViewPageV3';
import VoucherSetting from '../Pages/Customer/VoucherSetting';
import VoucherPage from '../Pages/Customer/VoucherPage';
import BulkVoucher from '../Pages/Customer/BulkVoucher';
import BulkContacsPage from '../Pages/Contacts/BulkContactsPage';



const CustomerRelationRouter = [
  {
    path: '/customer',
    element: <CustomerHomePage />,
  },
  {
    path: '/relations/contacts',
    element: <ContactsPage />,
  },
  {
    path: '/relations/contacts/bulk-contacs',
    element: <BulkContacsPage />,
  },
  {
    path: '/relations/contacts/detail/:id',
    element: <ContactDetailsPage />,
  },

  {
    path: '/relations/retention',
    element: <RetentionPage />,
  },
  {
    path: '/relations/retention/pipeline/view/:id',
    element: <RetentionPipelineViewPage />,
  },
  {
    path: '/relations/acquisition',
    element: <AcquisitionPipelinePageV2 />,
  },
  {
    path: '/relations/voucher',
    element: <VoucherPage />,
  },
  {
    path: '/relations/voucher/bulk-voucher',
    element: <BulkVoucher />,
  },
  {
    path: '/relations/voucher/:id',
    element: <VoucherSetting />,
  },
  {
    path: '/pipeline/create',
    element: <PipelineCreatePage />,
  },

  {
    path: '/relations/acquisition/view/:id',
    element: <AcquisitionPipelineViewPageV3 />,
  }
];

export default CustomerRelationRouter;
