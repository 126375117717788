import { Button } from '@chakra-ui/button';
import { Box, HStack, Stack, Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Flex, ModalCloseButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Input } from '@chakra-ui/input';
import { Textarea } from '@chakra-ui/textarea';
import PropTypes from 'prop-types';
import useWebsiteStore from '../../../../Hooks/Zustand/WebsiteStore';
import { FormControl, FormLabel, Image } from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';

const ModalAddContentProduct = ({ modal, handleChangeSectionImage }) => {
  const websiteStore = useWebsiteStore();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
    case 'Enter':
    case 'Tab':
      setValue((prev) => [...prev, createOption(inputValue)]);
      setInputValue('');
      event.preventDefault();
    }
  };

  // console.log(websiteStore, 'ini store');

  const currentProduct = websiteStore?.pageProduct;
  const optionMenu = websiteStore?.pageProduct?.filterConfiguration;

  function mergeArrays(arr) {
    if (!arr || !Array.isArray(arr)) {
      return [];
    }
    return arr.flatMap((option) => option.filterData).flat();
  }

  const mergedArray = mergeArrays(optionMenu);

  const handleAddNewData = () => {
    const defaultProductData = {
      name: '',
      discount: '',
      description: '',
      price: '',
      link: '',
      image: '',
      tags: [],
    };

    const currentProductData = currentProduct?.data;
    currentProductData?.push(defaultProductData);
    websiteStore?.setPageProduct({ ...currentProduct, data: currentProductData });
  };

  const handleDelete = (indexValue) => {
    const currentProductPage = websiteStore?.pageProduct;
    const currentData = websiteStore?.pageProduct?.data;

    currentData?.splice(indexValue, 1);

    currentProductPage.data = currentData;
    websiteStore?.setPageProduct(currentProductPage);
  };

  const handleActivateCustomFilter = () => {
    const defaultFilterData = [
      {
        name: '',
        filterData: [],
      },
    ];

    if (Object?.hasOwn(currentProduct, 'filterConfiguration')) {
      currentProduct?.filterConfiguration?.push(defaultFilterData[0]);
      websiteStore?.setPageProduct(currentProduct);
    } else {
      websiteStore?.setPageProduct({ ...currentProduct, filterConfiguration: defaultFilterData });
    }
  };

  const handleAddFilterData = (index) => {
    const currentFilterData = currentProduct?.filterConfiguration;

    currentFilterData[index].filterData.push('filter custom');
    websiteStore?.setPageProduct({ ...currentProduct, filterConfiguration: currentFilterData });
  };

  const handleChangeFilterData = (value, indexFilter, indexFilterData) => {
    const currentFilterData = currentProduct?.filterConfiguration;
    currentFilterData[indexFilter].filterData[indexFilterData] = value;
    websiteStore?.setPageProduct({ ...currentProduct, filterConfiguration: currentFilterData });
  };

  const handleDeleteFilterData = (indexFilter, indexFilterData) => {
    const currentFilter = currentProduct?.filterConfiguration;
    const currentFilterData = currentFilter[indexFilter].filterData;

    currentFilterData?.splice(indexFilterData, 1);

    currentFilter[indexFilter].filterData = currentFilterData;
    websiteStore?.setPageProduct({ ...currentProduct, filterConfiguration: currentFilter });
  };

  const handleChangeFilterName = (value, indexFilter) => {
    currentProduct.filterConfiguration[indexFilter].name = value;
    websiteStore?.setPageProduct(currentProduct);
  };

  const handleChangeProductData = (value, index, field) => {
    currentProduct.data[index][field] = value;
    websiteStore?.setPageProduct(currentProduct);
  };

  const handleChangeTags = (value, index) => {
    const currentProductData = currentProduct?.data[index];

    if (!Object?.hasOwn(currentProductData, 'tags')) {
      currentProductData.tags = [];
    }

    currentProductData.tags = value.map((tag) => tag.value); // Ambil nilai tag dari objek option

    websiteStore?.setPageProduct(currentProduct); // Set produk dengan data terbaru
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const options = mergedArray.map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <Modal
      onClose={modal.onClose}
      isOpen={modal.isOpen}
      size={'full'}>
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>Product Section</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            w={'100%'}
            gap={5}>
            <Stack
              w={'50%'}
              border={'1px'}
              borderRadius={'md'}
              borderColor={'gray.200'}
              shadow={'base'}
              p={5}>
              <Text
                align={'center'}
                fontSize={18}
                fontWeight={500}>
                Page Information and Features
              </Text>
              <Box my={4}>
                <Stack>
                  <Text>Header</Text>
                  <Input
                    size={'sm'}
                    defaultValue={currentProduct?.header}
                    placeholder='Enter your page header'
                    onChange={(e) => websiteStore?.setPageProduct({ ...currentProduct, header: e.target.value })}
                  />
                </Stack>
                <Stack my={2}>
                  <Text>Description Content</Text>
                  <Textarea
                    size={'sm'}
                    defaultValue={currentProduct?.content}
                    onChange={(e) => websiteStore?.setPageProduct({ ...currentProduct, content: e.target.value })}
                    placeholder='Enter your product page content'
                  />
                </Stack>
              </Box>

              <Box
                border={'1px'}
                borderRadius={'md'}
                borderColor={'gray.200'}
                p={4}>
                {currentProduct && currentProduct.filterConfiguration ? (
                  <SimpleGrid
                    columns={3}
                    spacing={3}>
                    {currentProduct.filterConfiguration.map((x, i) => (
                      <Box
                        key={i}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.100'}
                        p={2}>
                        <FormLabel>Filter Name</FormLabel>
                        <Input
                          onChange={(e) => handleChangeFilterName(e.target.value, i)}
                          size={'sm'}
                          defaultValue={x.name}
                        />
                        {x.filterData.length > 0 ? (
                          <Flex
                            flexWrap={'wrap'}
                            gap={2}
                            py={2}>
                            {x.filterData.map((y, j) => (
                              <HStack key={j}>
                                <Input
                                  px={1}
                                  py={1}
                                  size={'sm'}
                                  borderRadius={'sm'}
                                  fontWeight={'semibold'}
                                  defaultValue={y}
                                  onChange={(e) => handleChangeFilterData(e.target.value, i, j)}
                                />
                                <Box
                                  color='gray.400'
                                  onClick={() => handleDeleteFilterData(i, j)}>
                                  <FaTrash />
                                </Box>
                              </HStack>
                            ))}
                          </Flex>
                        ) : (
                          <Text
                            fontSize={'sm'}
                            align={'center'}
                            py={2}>
                            No Filter Data
                          </Text>
                        )}
                        <Button
                          size={'sm'}
                          borderRadius={'md'}
                          colorScheme='blue'
                          w={'full'}
                          onClick={() => handleAddFilterData(i)}>
                          Add Filter Data
                        </Button>
                      </Box>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Box
                    align={'center'}
                    border={'1px'}
                    py={8}
                    my={4}>
                    <Text>Add Custom Filter To Your Product</Text>
                  </Box>
                )}
                <Box
                  align={'center'}
                  py={3}>
                  <Button
                    size={'sm'}
                    rounded={'sm'}
                    colorScheme='blue'
                    onClick={() => handleActivateCustomFilter()}>
                    Add Custom Filter To Your Product
                  </Button>
                </Box>
              </Box>
            </Stack>

            <Stack
              w={'50%'}
              border={'1px'}
              borderRadius={'md'}
              borderColor={'gray.200'}
              shadow={'base'}
              p={5}>
              <Text
                align={'center'}
                fontSize={18}
                fontWeight={500}>
                Your Product Content
              </Text>
              <Box
                maxH={'70vh'}
                overflowY={'scroll'}>
                {currentProduct?.data?.length > 0 ? (
                  <SimpleGrid
                    columns={2}
                    spacing={3}>
                    {currentProduct?.data?.map((x, i) => (
                      <Stack
                        gap={2}
                        p={3}
                        key={i}>
                        <FormControl>
                          <FormLabel size={'sm'}>Product Name</FormLabel>
                          <Input
                            size='xs'
                            defaultValue={x?.name}
                            onChange={(e) => handleChangeProductData(e.target.value, i, 'name')}
                          />
                        </FormControl>
                        {x?.image1 ? (
                          <HStack justify={'center'}>
                            <Image
                              maxW={100}
                              src={x?.image1}
                              alt={x?.name}
                            />
                            <Image
                              maxW={100}
                              src={x?.image2}
                              alt={x?.name}
                            />
                          </HStack>
                        ) : (
                          <Image
                            src={x?.image}
                            alt={x?.name}
                          />
                        )}
                        <Flex w={'100%'}>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Description
                          </Text>
                          <Textarea
                            w={'70%'}
                            defaultValue={x?.price}
                            size={'xs'}
                            onChange={(e) => handleChangeProductData(e.target.value, i, 'description')}
                          />
                        </Flex>
                        <HStack w={'100%'}>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Price
                          </Text>
                          <Input
                            w={'70%'}
                            defaultValue={x?.price}
                            size={'xs'}
                            onChange={(e) => handleChangeProductData(e.target.value, i, 'price')}
                          />
                        </HStack>
                        <HStack w={'100%'}>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Discount
                          </Text>
                          <Input
                            w={'70%'}
                            defaultValue={x?.discount}
                            size={'xs'}
                            onChange={(e) => handleChangeProductData(e.target.value, i, 'discount')}
                          />
                        </HStack>
                        <HStack w={'100%'}>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Link product
                          </Text>
                          <Input
                            w={'70%'}
                            defaultValue={x?.link}
                            size={'xs'}
                            onChange={(e) => handleChangeProductData(e.target.value, i, 'link')}
                          />
                        </HStack>
                        <HStack w={'100%'}>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Tag product
                          </Text>
                          {/* <CreatableSelect
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            // menuIsOpen={false}
                            onChange={(newValue) => handleChangeTags(newValue, i)}
                            onInputChange={(newValue) => handleChangeTags(newValue, i)}
                            onKeyDown={handleKeyDown}
                            placeholder='Type something and press enter...'
                            value={value}
                          /> */}

                          <CreatableSelect
                            isMulti
                            options={options}
                            inputValue={inputValue}
                            onInputChange={(newValue) => setInputValue(newValue)}
                            onChange={(newValue) => {
                              setValue(newValue);
                              handleChangeTags(newValue, i);
                            }}
                            onKeyDown={handleKeyDown}
                            placeholder='Type something...'
                            defaultValue={x && x.tags ? x.tags.map((tag) => ({ label: tag, value: tag })) : []}
                          />

                          {/* <Input
                            w={'70%'}
                            defaultValue={x?.link}
                            size={'xs'}
                            onChange={(e) => handleChangeProductData(e.target.value, i, 'link')}
                          /> */}
                        </HStack>
                        <HStack>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Image 1
                          </Text>
                          <Input
                            w={'70%'}
                            type='file'
                            size={'xs'}
                            onChange={(e) => handleChangeSectionImage(e, 'product', i, 'image1')}
                          />
                        </HStack>
                        <HStack>
                          <Text
                            fontSize={12}
                            w={'30%'}>
                            Image 2
                          </Text>
                          <Input
                            w={'70%'}
                            type='file'
                            size={'xs'}
                            onChange={(e) => handleChangeSectionImage(e, 'product', i, 'image2')}
                          />
                        </HStack>

                        <Button
                          size={'xs'}
                          colorScheme='red'
                          onClick={() => handleDelete(i)}>
                          Delete
                        </Button>
                      </Stack>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Box
                    p={10}
                    align={'center'}
                    h={'20vh'}>
                    <Text>No Product Data, Add product to see your Product Data</Text>
                  </Box>
                )}
                <Button
                  size={'sm'}
                  colorScheme='blue'
                  onClick={() => handleAddNewData()}>
                  Add Product
                </Button>
              </Box>
            </Stack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              size={'sm'}
              colorScheme='red'
              onClick={modal.onClose}>
              Cancel
            </Button>
            <Button
              size={'sm'}
              colorScheme='green'
              onClick={modal.onClose}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddContentProduct;

ModalAddContentProduct.propTypes = {
  modal: PropTypes.func,
  handleSave: PropTypes.func,
  setNewSectionInput: PropTypes.func,
  newSectionInput: PropTypes.func,
  handleChangeSectionDetail: PropTypes.func,
  addNewData: PropTypes.func,
  handleChangeSectionImage: PropTypes.func,
};
