import {
  Button, Flex, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Table, Tbody, Td, Text, Textarea, Th, Thead, Tr, useDisclosure, AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Badge,
  Center,
  Input,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import { useNavigate, useParams } from 'react-router-dom'
import DynamicTable from '../../../Components/Table/DynamicTable'
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../../Api/firebaseApi'
import moment from 'moment'
import { FaFileInvoice, FaRecycle, FaWhatsapp } from 'react-icons/fa'
import useUserStore from '../../../Hooks/Zustand/Store'
import { AddIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons'
import AnalyticsCard from '../../../Components/Card/AnalyticsCard'
import { MdDone, MdOutlineWatchLater } from 'react-icons/md'
import { TiCancelOutline } from 'react-icons/ti';
import { LuTimer } from 'react-icons/lu';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import { handleRequest } from '../../../Api/Shipping'

function ShippingDetailPage() {
  const param = useParams();
  return (
    <>
      <Stack>
        <HStack spacing={3}>
          <BackButtons />
          <Heading size={'md'} textTransform={'capitalize'}>{param.type}</Heading>
        </HStack>
        <Analytics />
        <TableData />
      </Stack>
    </>
  )

}



function Analytics() {
  const globalState = useUserStore();
  const param = useParams();
  return (
    <>
      <SimpleGrid columns={[1, 2, 3]} gap={5}>
        <AnalyticsCard
          collectionName={'shipping'}
          conditions={[
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
            { field: 'courier', operator: '==', value: param.type },
            { field: 'status', operator: '==', value: 'requested' },
          ]}
          title={'Process Pick Up'}
          icon={<FaRecycle size={'20px'} color='white' />}
        />
        <AnalyticsCard
          collectionName={'shipping'}
          conditions={[
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
            { field: 'courier', operator: '==', value: param.type },
            { field: 'status', operator: '==', value: 'late' },
          ]}
          title={'Late Pickup'}
          icon={<MdOutlineWatchLater size={'20px'} color='white' />}
        />
        <AnalyticsCard
          collectionName={'shipping'}
          conditions={[
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
            { field: 'status', operator: '==', value: 'manifested' },
            { field: 'courier', operator: '==', value: param.type },
          ]}
          title={'Manifested'}
          icon={<MdDone size={'20px'} color='white' />}
        />
      </SimpleGrid>
      <SimpleGrid columns={[1, 2, 3]} gap={5}>
        <AnalyticsCard
          collectionName={'shipping'}
          conditions={[
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
            { field: 'courier', operator: '==', value: param.type },
          ]}
          title={'Total Orders'}
          icon={<FaFileInvoice size={'20px'} color='white' />}
        />
        <AnalyticsCard
          collectionName={'shipping'}
          conditions={[
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
            { field: 'courier', operator: '==', value: param.type },
            { field: 'status', operator: '==', value: 'failed' },
          ]}
          title={'Failed Pick Up'}
          icon={<TiCancelOutline size={'20px'} color='white' />}
        />
        <AnalyticsCard
          collectionName={'shipping'}
          conditions={[
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject },
            { field: 'courier', operator: '==', value: param.type },
            { field: 'status', operator: '==', value: 'pending' },
          ]}
          title={'Pending Pick Up'}
          icon={<LuTimer size={'20px'} color='white' />}
        />
      </SimpleGrid>

    </>

  )
}


function TableData() {
  const param = useParams()


  const itemsPerPage = 5; // Jumlah data per halaman
  const [check, setCheck] = useState([]);
  const [contactList, setCotactList] = useState([]);
  // const [user, setUser] = useState({});
  // const [price, setPrice] = useState([]);
  const [search, setSearch] = useState({ label: '', status: false });
  const [orderData, setOrderData] = useState({});
  const [allItem, setAllItem] = useState([]);
  const globalState = useUserStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [shippingDetails, setShippingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [shippingResponse, setShippingResponse] = useState({});
  const [dataWarehouse, setDataWarehouse] = useState({});
  const { onClose, onOpen, isOpen } = useDisclosure();
  const modalRef = useRef(null);
  const allItemRef = useRef(null);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();
  const navigate = useNavigate();


  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      { field: 'courier', operator: '==', value: param.type },
    ];


    // console.log(conditions, 'conditions')
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;


    try {
      if (globalState?.currentCompany && globalState?.currentProject && param.type) {
        const res = await getCollectionFirebase(
          'shipping',
          conditions,
          sortBy,
          limitValue
        );
        console.log(res, 'ini res')
        setCotactList(res)
      }
      if (search.status) {
        console.log(search, 'search')
        const res = await getCollectionFirebase(
          'shipping',
          [
            { field: 'orderId', operator: '==', value: search.label },
            { field: 'companyId', operator: '==', value: globalState.currentCompany },
            { field: 'projectId', operator: '==', value: globalState.currentProject }
          ]
        );
        console.log(res, 'ini res')
        setCotactList(res)
      }
    } catch (error) {
      console.log(error, 'ini error')
    }
  }
  const handleLoad = () => {
    setCurrentPage((prev) => prev + 1);
  }

  useEffect(() => {
    getData();
  }, [globalState.currentCompany, globalState.currentProject, currentPage, param.type, search]);

  const tableHeader = ['createdAt', 'orderId', 'detail', 'status', 'resi'];

  const tableData = contactList.map((data, index) => {

    const modifiedPhoneNumber = data?.phoneNumber?.startsWith('08')
      ? '628' + data?.phoneNumber.slice(2) // Replace '08' with '628'
      : data?.phoneNumber;


    const name = data.userId || '';
    const phone = `+${modifiedPhoneNumber ? modifiedPhoneNumber : ''}` || '';
    const email = data.email || '';
    const lastActivity = moment(data?.lastUpdated.seconds * 1000).format('LLL')
    data['status'] = <Text fontWeight='bold' color={data['status'] == 'pending' ? 'red' : 'green'}>{data?.status}</Text>
    data['createdAt'] = <Text fontWeight='500' color='gray'>{moment(data?.createdAt).format('LLL')}</Text>
    data['detail'] = <Stack><Text>From : {data?.from}</Text><Text>To : {data?.to}</Text></Stack>
    return {
      data,
      // id: index + 1, // Add 1 to create a unique ID
      // shippingId: data.id,
      // name: name,
      // phone: phone,
      // email: email,
      // 'Tanggal Penyimpanan': lastActivity
    };
  });

  const dataTabel = [];
  tableData.forEach((item) => {
    dataTabel.push(item.data)
  })

  const handleRead = async (row) => {
    onOpen();
    setShippingDetails(row)
    modalRef.current = row;
    const varian = [];
    try {
      const trans = await getSingleDocumentFirebase('orders', row.orderId);
      console.log(trans, 'trans');
      setOrderData(trans)
      // const orderData = await getSingleDocumentFirebase('orders', row.orderId);
      // console.log(shippingDetails, 'detailveiow')
      await Promise.all(row?.items?.map(async (item) => {
        if (item.variantId) {
          const getVariant = await getSingleDocumentFirebase('product_variants', item.variantId);
          varian.push({
            name: getVariant.name,
            weight: parseFloat(getVariant.weight) * 1000 || 1,
            quantity: parseInt(item.quantity),
            price: parseInt(getVariant.price),
            warehouseId: getVariant?.warehouseId
          });
        }
      }));
      setAllItem(varian)
      allItemRef.current = varian;
    } catch (error) {
      console.log('error handleread1: ', error.message)
    }

    try {
      const warehouse = await getSingleDocumentFirebase('warehouses', varian[0]?.warehouseId);
      // console.log(warehouse, 'warehouse')
      setDataWarehouse(warehouse);
    } catch (error) {
      console.log('error handleread2: ', error.message)
    }
  };

  // const handleRequest = async () => {
  //   setLoading(true);
  //   const now = moment().format('YYYY-MM-DD HH:mm');
  //   const lastPickupTime = moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
  //   const isAfterLastPickupDate = moment(now).isAfter(lastPickupTime);
  //   const body = {
  //     'shipping_id': shippingDetails?.id,
  //     'is_production': true,
  //     'shipping_service': shippingDetails?.service,
  //     'is_cod': false,
  //     'is_fragile': false,
  //     'cod_amount': 0,
  //     'goods_value': 30000,
  //     'applied_insurance': false,
  //     'origin_id': parseInt(shippingDetails?.from),
  //     'destination_id': parseInt(shippingDetails?.to),
  //     'dimensions': {
  //       'length': 1,
  //       'width': 1,
  //       'height': 1
  //     },
  //     'items': allItem,
  //     'pickup': {
  //       'date': isAfterLastPickupDate ? moment().add(1, 'day').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
  //       // 'date': '2024-03-19',
  //       'timeslot': {
  //         'start_time': dataWarehouse?.start_pickup || '10:00',
  //         'end_time': dataWarehouse?.end_pickup || '17:00',
  //         'timezone': 'Asia/Jakarta'
  //       },
  //       'name': globalState?.projects?.find((x) => x?.id === globalState.currentProject).name,
  //       'address': dataWarehouse?.address?.street || '',
  //       'phone': dataWarehouse?.phone || '087806848932',
  //       'zip_code': dataWarehouse.address?.postal_code || '15147',
  //     },
  //     'receiver': {
  //       'name': orderData.displayName || 'reinhart',
  //       'address': orderData?.contact_information?.address?.street + ' ' + orderData?.contact_information?.address?.city || '',
  //       'phone': orderData?.phoneNumber || '087806848932',
  //       'zip_code': orderData?.address?.postal_code || '15147'
  //     }, // penerima
  //     'shipper': { //pengirim.
  //       'name': globalState?.projects?.find((x) => x?.id === globalState.currentProject).name,
  //       'address': dataWarehouse?.address?.street,
  //       'phone': dataWarehouse?.phone || '087806848932',
  //     }
  //   }

  //   console.log('body', body)


  //   const config = {
  //     url: `${process.env.REACT_APP_FUNCTIONS_HOST}/shipping-oExpressCreateOrder`,
  //     // url: 'http://127.0.0.1:8081/deoapp-indonesia/asia-southeast2/shipping-oExpressCreateOrder',
  //     method: 'post',
  //     data: body,
  //     // headers: { 'x-api-key': process.env.REACT_APP_OEXPRESS_API_KEY }
  //   }
  //   try {
  //     const requestShipping = await axios(config);
  //     console.log(requestShipping.data, '::requestshipping.data');
  //     const resiNumber = requestShipping?.data?.data?.data?.tracking_number;
  //     if (resiNumber) {
  //       try {
  //         await updateDocumentFirebase('shipping', shippingDetails.id, { status: 'requested', resi: resiNumber });
  //         try {
  //           const obj = orderData?.fulfillment?.find((x) => x?.shippingId == shippingDetails.id);
  //           const x = [...orderData.fulfillment];
  //           const updatedArray = x.filter((x) => x.shippingId != shippingDetails.id);
  //           updatedArray.push({ ...obj, status: 'requested', resi: resiNumber });

  //           await updateDocumentFirebase('orders', orderData?.id, {fulfillment : updatedArray });
  //         } catch (error) {
  //           console.log(error.message, 'error update fulfillment');
  //         }
  //       } catch (error) {
  //         toast({
  //           title: 'Failed',
  //           description: error.message,
  //           isClosable: true,
  //           status: 'error',
  //           duration: 9000
  //         })
  //       }
  //     }
  //     onAlertOpen(true);
  //     setShippingResponse(requestShipping.data);
  //   } catch (error) {
  //     console.log('error request shipping ::::::', error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const handleCustom = (row) => {
    console.log(row)
  }

  const handleColumnClick = (columnName, rowData) => {

    console.log({ columnName, rowData });

    if (columnName === 'name') {
      // Contoh: Jika kolom 'Name' diklik, tampilkan detail nama.
      // Gantilah dengan perilaku yang sesuai untuk menampilkan detail nama.
      console.log(`Detail Name: ${rowData.name}`);
    } else if (columnName === 'email') {
      // Contoh: Jika kolom 'Email' diklik, navigasikan ke halaman tertentu.
      // Anda harus menggantinya dengan navigasi yang sesuai sesuai dengan routing yang Anda gunakan.
      console.log(`/email/${rowData.email}`);
    }
  };

  const handlePrint = () => {
    navigate('/shipping/print-resi');
  };

  const handlePrintChecbox = async () => {
    if (check?.length == 0) {
      return toast({
        title : 'Deoapp',
        description : 'Pilih minimal 1 shipping yang sudah status = "requested"',
        duration : 5000,
        status : 'error',
        isClosable : true
      })
    } else if (check?.some((x) => !x?.resi)) {
      return toast({
        title : 'Deoapp',
        description : 'Semua shipping harus memiliki resi',
        duration : 5000,
        status : 'error',
        isClosable : true
      })
    }
    const point = await Promise.all(check.map(async (x) => {
      const trans = await getSingleDocumentFirebase('orders', x.orderId);
      const varianData = await Promise.all(x?.items?.map(async (item) => {
        if (item.variantId) {
          const getVariant = await getSingleDocumentFirebase('product_variants', item.variantId);
          return {
            name: getVariant.name,
            weight: parseInt(getVariant.weight),
            quantity: parseInt(item.quantity),
            price: parseInt(getVariant.price),
            warehouseId: getVariant?.warehouseId
          };
        }
      }));
      const validVarianData = varianData.find((data) => data !== undefined);
      const warehouse = await getSingleDocumentFirebase('warehouses', validVarianData.warehouseId);
      return {
        // check: x, 
        // trans: trans,
        // varian: varianData,
        // warehouse: warehouse,
        name: trans.contact_information?.name,
        phoneNumber: trans.contact_information?.phoneNumber,
        address: trans?.contact_information?.address?.street + ', ' + trans?.contact_information?.address?.city + ', ' + trans?.contact_information?.address?.state,
        areaCode: x.to,
        senderName: globalState?.projects?.find((x) => x?.id === globalState.currentProject).name,
        senderAddress: warehouse.address.street + ', ' + warehouse.address?.city + ', ' + warehouse.address?.state + ' ' + warehouse.address.postal_code,
        product: varianData.map((x) => x.name),
        quantity: parseInt(trans.items?.reduce((total, item) => total + item.quantity, 0), 10),
        withInsurance: 'No',
        shippingPrice: x.shipping_price,
        orderId: x.orderId,
        printDate: moment().format('DD-MM-YYYY'),
        resi: x.resi
      };
    }));
    // console.log(point, 'blank');
    localStorage.setItem('printData', JSON.stringify(point));

    navigate('/to-print');
  };

  const handleCheckboxChange = (event, rowData) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheck([...check, rowData]);
    } else {
      setCheck(check.filter((row) => row !== rowData));
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>



      {/* <Stack>
        <ShippingAnalyticPage
          totalOrders={shippingAnalytic.totalOrders}
          failedPickup={shippingAnalytic.failedPickup}
          waitingPickup={shippingAnalytic.waitingPickup}
          processPickup={shippingAnalytic.processPickup}
        />
      </Stack> */}

      <Stack>
        <HStack>
          <Spacer />
          {/* <DynamicButton variant={'solid'} size={'sm'} action={'create'} title={'Create Logistic'} /> */}
        </HStack>
        <Stack w={'55%'} direction={'row'} gap={3}>
          <Button
            leftIcon={<AddIcon boxSize={3} />}
            colorScheme='blue'
            onClick={handlePrintChecbox}
            isLoading={loading}
            isDisabled={loading}
            width={'30%'}
          >
            Cetak Label
          </Button>
          <Input placeholder='Search By Order Id' onChange={(e) => setSearch({ ...search, label: e.target.value })} />
          <Button colorScheme='green' onClick={() => setSearch({ ...search, status: !search.status })}>
            <SearchIcon />
          </Button>
        </Stack>

        <DynamicTable
          isCheckbox={true}
          handleCheckboxChange={handleCheckboxChange}
          header={tableHeader}
          data={dataTabel}
          action_color='green'
          action_icon={FaWhatsapp}
          // onCustom={handleCustom}
          onRead={handleRead}
          clickableColumns={['email']}
          onColumnClick={handleColumnClick}
        />
        <Center>
          <Button colorScheme={'blue'} onClick={handleLoad}> Load more</Button>
        </Center>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size={'3xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Detail</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={1}>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'md'}>
                    SHIPPING ID
                  </Text>
                  <Text>{modalRef.current?.id}</Text>
                </HStack>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'md'}>
                    Status Pengiriman
                  </Text>
                  <Badge>{modalRef?.current?.status}</Badge>
                </HStack>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'md'} fontWeight={'extrabold'}>
                    RESI
                  </Text>
                  <Text fontWeight={'extrabold'}>{modalRef?.current?.resi}</Text>
                </HStack>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'md'}>
                    Shipping Price
                  </Text>
                  <Text>
                    {typeof modalRef.current?.shipping_price === 'string' ? `Rp. ${parseInt(modalRef.current?.shipping_price).toLocaleString('id-ID')}` : modalRef.current?.shipping_price?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                  </Text>
                </HStack>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'md'}>
                    Service
                  </Text>
                  <Text>{modalRef.current?.service}</Text>
                </HStack>
                <HStack justifyContent={'space-between'}>
                  <Text fontSize={'md'}>
                    Created At
                  </Text>
                  <Text>{moment.unix(modalRef.current?.createdAt?.seconds).format('dddd, DD MMM YYYY HH:MM')}</Text>
                </HStack>
                <Stack justifyContent={'space-between'}>
                  <Text fontSize={'md'}>
                    Message
                  </Text>
                  {/* <Text>{modalRef.current?.awb}</Text> */}
                  <Textarea>{modalRef.current?.message}</Textarea>
                </Stack>
                <Table variant='striped' colorScheme='teal'>
                  <Thead>
                    <Tr>
                      <Th>Product ID</Th>
                      <Th>Quantity</Th>
                      <Th>Shipping Provider</Th>
                      <Th>Variant ID</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {modalRef.current?.items?.map((item) => (
                      <Tr key={Math.round(Math.random() * 100)}>
                        <Td>{item.productId}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>{item.shipping_provider.description}</Td>
                        <Td>{item.variantId}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>



                {/* <Stack>
                  <Text fontSize={'sm'}>
                    Project <span style={{ color: 'red' }}>*</span>
                  </Text>
                  <Input
                    size={'sm'}
                    placeholder='Project Name'
                    onChange={() => { }}
                    name='project_name'
                  />
                </Stack> */}
              </Stack>
            </ModalBody>
            <ModalFooter>
              {modalRef?.current?.resi ?
                <Flex gap={5}>
                  <Button
                    leftIcon={<AddIcon boxSize={3} />}
                    colorScheme='blue'
                    onClick={handlePrint}
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    Cetak Label2
                  </Button>
                  {/* <Button
                  leftIcon={<AddIcon boxSize={3} />}
                  colorScheme='green'
                  onClick={handleRequest}
                  isLoading={loading}
                  isDisabled={loading}
                >
                  Request Shipping
                </Button> */}
                  {/* {modalRef?.current?.status === 'pending' ?
                  <Button
                    leftIcon={<AddIcon boxSize={3} />}
                    colorScheme='green'
                    onClick={handleRequest}
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    Request Shipping
                  </Button>
                  :
                  // <Button
                  //   leftIcon={<CloseIcon boxSize={3} />}
                  //   colorScheme='red'
                  // // onClick={handleRequest}
                  // // isLoading={loading}
                  // // isDisabled={loading}
                  // >
                  //   Cancel Shipping
                  // </Button>
                  <></>
                } */}

                  <Button
                    leftIcon={<CloseIcon boxSize={3} />}
                    // colorScheme='red'
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </Flex>
                :
                <Flex gap={5}>
                  <Button
                    leftIcon={<AddIcon boxSize={3} />}
                    colorScheme='blue'
                    onClick={handlePrint}
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    Cetak Label1
                  </Button>
                  <Button
                    leftIcon={<AddIcon boxSize={3} />}
                    colorScheme='green'
                    onClick={() => handleRequest({
                      setLoading,
                      shippingDetails,
                      allItem,
                      dataWarehouse,
                      globalState,
                      orderData,
                      setShippingResponse,
                      onAlertOpen,
                      toast
                    })}
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    Request Shipping
                  </Button>
                  {/* {modalRef?.current?.status === 'pending' ?
                  <Button
                    leftIcon={<AddIcon boxSize={3} />}
                    colorScheme='green'
                    onClick={handleRequest}
                    isLoading={loading}
                    isDisabled={loading}
                  >
                    Request Shipping
                  </Button>
                  :
                  // <Button
                  //   leftIcon={<CloseIcon boxSize={3} />}
                  //   colorScheme='red'
                  // // onClick={handleRequest}
                  // // isLoading={loading}
                  // // isDisabled={loading}
                  // >
                  //   Cancel Shipping
                  // </Button>
                  <></>
                } */}
                </Flex>
              }
            </ModalFooter>
          </ModalContent>
        </Modal>

        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Push Shipping {shippingResponse.data?.data?.tracking_number ? 'SUCCESSFUL' : 'FAILED'}
                {shippingResponse.data?.data?.tracking_number ? <FcCheckmark size={'4em'} /> : <FcCancel size={'4em'} />}
              </AlertDialogHeader>

              <AlertDialogBody>
                {shippingResponse.status === true ?
                  ` Shipping id #${shippingDetails.id} is successful with RESI : #${shippingResponse.data?.data?.tracking_number}` :
                  shippingResponse?.data?.message
                }
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => {
                  onAlertClose();
                  onClose();
                  getData();
                }}>
                  Close
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Stack>
    </Stack>
  )
}
export default ShippingDetailPage