/* eslint-disable no-alert */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Image,
  useDisclosure,
  ModalFooter,
  Avatar,
  IconButton,
  Radio,
  RadioGroup
} from '@chakra-ui/react';
import {
  collection,
  query,
  where,
  getCountFromServer
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { addDocumentFirebase, arrayRemoveFirebase, arrayUnionFirebase, deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import { auth, db } from '../../Config/firebase';
import useUserStore from '../../Hooks/Zustand/Store';
import { MdArrowDropDown } from 'react-icons/md';
import CardFile from '../../Components/Accounting/CardFile';
import { AwsUploadFile } from '../../Utils/AwsUploadFile';
import { clientTypessense } from '../../Api/Typesense';
import { DeleteIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import ComponentExportExcel from '../../Utils/ExportExcel';
import * as XLSX from 'xlsx'
import axios from 'axios';

const CategoryPage = () => {
  const toast = useToast();
  const [searchValue, setSearchValue] = useState('');
  const [OpenModalCategory, setOpenModalCategory] = useState('');
  const [OpenModalSubCategory, setOpenModalSubCategory] = useState('');
  const [editData, setEditData] = useState('');
  const globalState = useUserStore();
  const [title, setTitle] = useState('')
  const [type, setType] = useState('physical');
  const [inputType, setInputType] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  // const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataSubCategory, setDataSubCategory] = useState([]);
  const [filterData, setFilterData] = useState({ categoriesId: '' })
  const [titleModalCategory, setTitleModalCategory] = useState('')
  const [titleModalSubCategory, setTitleModalSubCategory] = useState('')
  const [externalId, setExternalId] = useState('')
  const [fileName, setFileName] = useState('');
  const [imageLink, setImageLink] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState();
  const [data, setData] = useState([]);
  const [dataTabel, setDataTabel] = useState([]);
  const [file, setFile] = useState(null)
  const [rows, setRows] = useState([]); 

  const itemsPerPage = 10;

  const projectId = globalState.currentProject;
  // console.log(filterData, 'ini data filter')
  // console.log(type, 'ini typenya')


  const getDataCategory = async () => {
    // Fetch data from API or other source if needed
    try {
      // const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
        { field: 'categoriesId', operator: '==', value: null },
        { field: 'is_parent', operator: '==', value: true },
      ];

      // const sortBy = { field: 'createdAt', direction: 'asc' };
      // const limitValue = startIndex + itemsPerPage;

      const res = await getCollectionFirebase('product_categories', conditions);
      setDataCategory(res);

      const collectionRef = collection(db, 'product_categories');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          // where('projectId', '==', globalState.currentProject),
        ),
      );

      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleEditClick = async (rowData) => {
    setSelectedData(rowData);
    setIsModalOpen(true);
  };
  // console.log(dataTabel,'datatabel')
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDataSubcategory = async () => {
    // Fetch data from API or other source if needed
    try {
      // const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
        { field: 'projectId', operator: '==', value: globalState.currentProject },
        { field: 'is_parent', operator: '==', value: false },
      ];

      if (filterData?.categoriesId)
        conditions.push({
          field: 'categoriesId',
          operator: '==',
          value: filterData?.categoriesId,
        });


      const res = await getCollectionFirebase('product_categories', conditions);
      setDataSubCategory(res);

      const collectionRef = collection(db, 'product_categories');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          // where('projectId', '==', globalState.currentProject),
        ),
      );

      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };


  useEffect(() => {

    getDataCategory();
    getDataSubcategory()

  }, [globalState.currentProject, filterData]);

  const onSubmitModalCategory = async () => {
    try {
      setIsLoading(true)
      const dataSave = {
        categoriesId: null,
        companyId: globalState.currentCompany,
        createdAt: new Date(),
        createdBy: auth.currentUser.uid,
        externalId: externalId,
        is_parent: true,
        images: [imageLink],
        module: ['commerce'],
        projectId: projectId,
        title: title,
        type: type,
      }
      if (editData) {
        await updateDocumentFirebase('product_categories', editData, dataSave);
      } else {
        await addDocumentFirebase('product_categories', dataSave, globalState.currentCompany);
      }

      getDataCategory()
      setIsLoading(false)
      handleCloseModalCategory();
      setImageLink('')
    } catch (error) {
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const onSubmitModalSubCategory = async () => {
    try {
      setIsLoading(true)
      const dataSave = {
        categoriesId: filterData?.categoriesId,
        companyId: globalState.currentCompany,
        createdAt: new Date(),
        createdBy: auth.currentUser.uid,
        externalId: externalId,
        is_parent: false,
        module: ['commerce'],
        images: [imageLink],
        projectId: projectId,
        title: title,
        type: type,
      }
      if (editData) {
        await updateDocumentFirebase('product_categories', editData, dataSave);
      } else {
        await addDocumentFirebase('product_categories', dataSave, globalState.currentCompany);
      }

      getDataSubcategory()
      setIsLoading(false)
      handleCloseModalSubCategory();
      setImageLink('')
    } catch (error) {
      toast({
        title: 'Admin',
        description: `An error occurred while saving data ${totalPages}`,
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)
    const uploadData = {
      file,
      folder: 'folder/v1/xx'
    }
    try {
      const result = await AwsUploadFile(uploadData);

      if (result.status === true) {
        setImageLink(result.data);
      } else {
        toast({
          status: 'error',
          duration: 9000,
          title: 'Upload image failed',
          isClosable: true,
        });
      }

    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      },);
    }
  }

  // const handleSave = async(id) => {
  //   try {
  //     const conditions = [
  //       { field: 'companyId', operator: '==', value: globalState.currentCompany },
  //       { field: 'projectId', operator: '==', value: globalState.currentProject },
  //       { field: 'categoryId', operator: 'array-contains', value: id },
  //     ];
  //     // console.log(conditions,'con')
  //     const get = await getCollectionFirebase('product', conditions)
  //     // console.log(get,'getdata')
  //     const getName = get.map((x) => {
  //       return{
  //         id: x.id,
  //         name: x.name,
  //         categoryId: x.categoryId
  //       }
  //     })
  //     // return console.log(id)
  //     const array = dataTabel.map(async(x) => {
  //       console.log('layer 1')
  //       console.log(x)
  //       const single = await getSingleDocumentFirebase('product', x.id)
  //       if(single.categoryId.length == 0){
  //         const tabel = {
  //           categoryId: [id]
  //         }; 
  //         return  await setDocumentFirebase('product', x.id, tabel) 
  //       } 
  //       setCategoryId(single?.categoryId)
  //       const cat = single?.categoryId?.map(async(y) => {
  //         console.log('layer 2')
  //         console.log(y)
  //         // setCategoryId(y)
  //         console.log(categoryId)
  //         if(y===id){
  //           return       toast({
  //             title: 'Admin',
  //             description: 'Product Already in This Category',
  //             status: 'success',
  //           })
  //         } 
  //         if(categoryId.length == 1){
  //           // return console.log(categoryId, 'nilai x')
  //           const tabel = {
  //             categoryId: [categoryId[0], id]
  //           }; 
  //           console.log(tabel)
  //           const update = await setDocumentFirebase('product', x.id, tabel) 
  //           return toast({
  //             title: 'Admin',
  //             description: 'Success added product',
  //             status: 'success',
  //           })                                   
  //         } 
  //         if(categoryId.length > 1){
  //           console.log('if 3')
  //           // return console.log(categoryId, 'nilai x')
  //           const tabel = {
  //             categoryId: [...categoryId, id]
  //           }; 
  //           const update = await setDocumentFirebase('product', x.id, tabel) 
  //           return toast({
  //             title: 'Admin',
  //             description: 'Success added product',
  //             status: 'success',
  //           })                                   
  //         }
  //       })
  //     })
  //   } catch (error) {
  //     console.log(error, 'error')
  //   }
  // }

  const handleSave = async (id) => {
    try {

      if(inputType === 'search') {
        const array = await Promise.all(dataTabel.map(async (x) => {
          const single = await getSingleDocumentFirebase('product', x.id);
          if (single?.categoryId?.length === 0) {
            const tabel = {
              categoryId: [id]
            };
            await setDocumentFirebase('product', x.id, tabel);
            return           toast({
              title: 'Admin',
              description: 'Success added product',
              status: 'success',
            });
          }

          if (single?.categoryId?.length > 0) {
            const array1 = [];
            const cat = single?.categoryId?.map(async (y) => {
              if (y === id) {
                return toast({
                  title: 'Admin',
                  description: 'Product Already in This Category',
                  status: 'success',
                });
              }
              array1.push(y)
            });
            const tabel = {
              categoryId: [...array1, id]
            };
            const update = await setDocumentFirebase('product', x.id, tabel);
            toast({
              title: 'Admin',
              description: 'Success added product',
              status: 'success',
            });
            return Promise.all(cat);
          }
        }));        
      }

      if(inputType === 'import') {
        console.log('masuk')
        const array = dataTabel.map(async (x) => {
          const single = await getSingleDocumentFirebase('product', x.productId);
          if (single?.categoryId?.length === 0) {
            const tabel = {
              categoryId: [id]
            };
            await setDocumentFirebase('product', x.productId, tabel);
            return           toast({
              title: 'Admin',
              description: 'Success added product',
              status: 'success',
            });
          }

          if (single?.categoryId?.length > 0) {
            console.log('masuk2')

            const array1 = [];
            const cat = single?.categoryId?.map(async (y) => {
              if (y === id) {
                return toast({
                  title: 'Admin',
                  description: 'Product Already in This Category',
                  status: 'success',
                });
              }
              array1.push(y)
            });
            const tabel = {
              categoryId: [...array1, id]
            };
            const update = await setDocumentFirebase('product', x.productId, tabel);
            toast({
              title: 'Admin',
              description: 'Success added product',
              status: 'success',
            });
            return Promise.all(cat);
          }
        });
      }

      if(inputType === 'automation') {
        const conditions = [
          ...rows,
          { field: 'companyId', operator: '==', value: globalState.currentCompany },
          { field: 'projectId', operator: '==', value: globalState.currentProject },
        ];
        console.log(conditions)
        const get = await getCollectionFirebase('product', conditions)
        const array = get.map(async (x) => {
          // const single = await getSingleDocumentFirebase('product', x.productId);
          if (x?.categoryId?.length === 0) {
            const tabel = {
              categoryId: [id]
            };
            await setDocumentFirebase('product', x.id, tabel);
            return           toast({
              title: 'Admin',
              description: 'Success added product',
              status: 'success',
            });
          }

          if (x?.categoryId?.length > 0) {
            const array1 = [];
            const cat = x?.categoryId?.map(async (y) => {
              if (y === id) {
                return toast({
                  title: 'Admin',
                  description: 'Product Already in This Category',
                  status: 'success',
                });
              }
              array1.push(y)
            });
            const tabel = {
              categoryId: [...array1, id]
            };
            const update = await setDocumentFirebase('product', x.id, tabel);
            toast({
              title: 'Admin',
              description: 'Success added product',
              status: 'success',
            });
            return Promise.all(cat);
          }
        });

      }

    }
    catch (error) {
      console.log(error, 'error');
    }
  };


  const deleteData = async (id) => {
    const confirmDelete = window.confirm('If you delete this data you cant Undo');
    if (confirmDelete)

      try {
        await deleteDocumentFirebase('product_categories', id)
        getDataCategory()
        toast({
          title: 'Admin',
          description: 'Success delete banner',
          status: 'success',
        })
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
  }

  const handleBulk = () => {
    navigate('/category/bulk')
  }

  const handleOpenModalCategory = () => {
    setOpenModalCategory(true)
  }
  const handleCloseModalCategory = () => {
    setOpenModalCategory(false)
    setEditData()
  }
  const handleOpenModaSubCategory = () => {
    setOpenModalSubCategory(true);
  }
  const handleCloseModalSubCategory = () => {
    setOpenModalSubCategory(false);
    setEditData()
  }

  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'name',
      filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
      sort_by: '_text_match:desc'
    };
    clientTypessense
      .collections('product')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        setData(newData);
      });
  }
  // console.log(data,'roda')
  const handleDelete = (id) => {
    if(inputType === 'search') {
      setDataTabel(dataTabel.filter((item) => item.id !== id));      
    }
    if(inputType === 'import') {
      setDataTabel(dataTabel.filter((item) => item.productId !== id));      
    }

  }

  const handleAdd = async (x) => {
    const newData = {
      // images: x.images,
      id: x.id,
      name: x.name
    };
    setDataTabel([...dataTabel, newData]);
  }

  const filteredDataCategory = dataCategory.filter((x) =>
    x.title.toLowerCase().includes(searchValue.toLowerCase())
  );

  const downloadTemplate = async () => {
    try {
      const spreadsheetUrl = 'https://docs.google.com/spreadsheets/d/1gRQHLunHRNCogfiWBm4pj8sMx6ZCtpupfqHcRhqompY/export?format=xlsx';
      const response = await axios.get(spreadsheetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template assign product.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading template:', error);
      // Handle error
    }
  };

  const fixedArray = (data) => {
    const dats = []
    data.map((x,index) => {
      const tabsData = {
        productId : x.productId || '',
      }
      dats.push(tabsData)
    })
    console.log(dats)
    setDataTabel(dats)
  }

  const onSelectFiles = (arg) => {
    setFile(arg)
  }

  const onImport = () => {
    if (!file) {
      return toast({
        title: 'Error',
        description: 'Not Have Data',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (file?.target?.files?.[0]?.name?.split('.').pop() !== 'xlsx') {
      return toast({
        title: 'Error',
        description: 'Invalid file type. Please select an XLSX file.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      console.log(worksheet,'works')
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log(jsonData,'json')
      // setTransaction([...jsonData])
      fixedArray(jsonData)
    }

    reader.readAsArrayBuffer(file.target.files[0]);
  }


  const addRow = () => {
    setRows([...rows, { field: '', operator: '', value: ''}],);
  };

  const removeRow = (index) => {
    setRows(rows.filter((row, rowIndex) => rowIndex !== index));
  };

  const handleSelect1Change = (index, value, type) => {
    // console.log(index, field)
    // setSelect1(e.target.value);
    const updatedDetails = [...rows,];
    updatedDetails[index][type] = value;
    setRows(updatedDetails,);
  };
  console.log(rows,'row')

  return (
    <>
      <Box p='5'>
        <HStack mb='5'>
          <BackButtons />
          <Spacer />
          <Heading>Category</Heading>
        </HStack>

        <Box

          borderRadius="md"
          shadow="md"
        // bgColor={'white'}
        >
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Category</Tab>
              <Tab>Sub Category</Tab>

              {/* <Tab>Team</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box p="5" borderRadius="md" shadow="base" mt={5}>
                  <HStack mb='2'>
                    <Spacer />
                    <InputGroup size="md" w='30%'>
                      <Input
                        type="text"
                        placeholder="Search Name"
                        onChange={(event) => setSearchValue(event.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" mr={2}>
                          Search
                        </Button>
                      </InputRightElement>
                    </InputGroup>

                    <DynamicButton
                      size={'md'}
                      action="create"
                      title={'Create New'}
                      onClick={() => { setTitleModalCategory('Create New Category'); handleOpenModalCategory() }}
                    />
                    <DynamicButton
                      size={'md'}
                      action="create"
                      title={'Bulk Category'}
                      onClick={handleBulk}
                    />
                    <ComponentExportExcel fileName='Data Category' excelData={filteredDataCategory} />
                  </HStack>
                  <TableContainer
                    border={'1px'}
                    borderRadius={'sm'}
                    borderColor={'gray.100'}
                    boxShadow={'base'}
                  >
                    <Table variant="striped">
                      <Thead>
                        <Tr>
                          <Th>name </Th>
                          <Th>type </Th>
                          <Th>Document ID</Th>
                          <Th textAlign="center">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredDataCategory.map((x, i) => (
                          <Tr key={i}>
                            <Td>
                              <HStack>
                                <Stack spacing={0}>
                                  <Text
                                    //   textTransform={'capitalize'}
                                    fontWeight={'bold'}
                                    fontSize="sm"
                                  >
                                    {x.title}
                                  </Text>
                                </Stack>
                              </HStack>
                            </Td>
                            <Td>
                              <Text>
                                {x.type}
                              </Text>
                            </Td>
                            <Td>
                              {x.id}
                            </Td>

                            <Td>
                              <HStack spacing={2} justifyContent="center">
                                <DynamicButton
                                  size="sm"
                                  action="create"
                                  // isLoading={loadingStates[i]}
                                  onClick={() => handleEditClick(x.id)}
                                />
                                <DynamicButton
                                  size="sm"
                                  action="update"
                                  onClick={() => { setEditData(x.id); setTitleModalCategory('Update Category'); handleOpenModalCategory() }}
                                />
                                <DynamicButton
                                  size="sm"
                                  action="delete"
                                  // isLoading={loadingStates[i]}
                                  onClick={() => deleteData(x.id, i)}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box p='5' borderRadius="md" shadow="base" mt='5'>
                  <HStack mb='2'>
                    <Spacer />

                    <Select
                      maxW='26%'
                      value={filterData?.categoriesId}
                      placeholder="Filter All Operations"
                      icon={<MdArrowDropDown />}
                      onChange={(e) => setFilterData({ ...filterData, categoriesId: e.target.value })}
                      required
                    >
                      {dataCategory.map((x, i) => (
                        <option key={i} value={x.id}>{x.title}</option>

                      ))}
                    </Select>
                    <DynamicButton
                      size={'md'}
                      action="create"
                      title={'Create New'}
                      onClick={() => { setTitleModalSubCategory('Create Sub Category'); handleOpenModaSubCategory() }}
                    />
                    <DynamicButton
                      size={'md'}
                      action="create"
                      title={'Bulk Sub Category'}
                      onClick={handleBulk}
                    />
                    <ComponentExportExcel fileName='Data Category' excelData={dataSubCategory} />
                  </HStack>

                  <TableContainer
                    border={'1px'}
                    borderRadius={'sm'}
                    borderColor={'gray.100'}
                    boxShadow={'base'}
                  >
                    <Table variant="striped">
                      <Thead>
                        <Tr>
                          <Th>name </Th>
                          <Th>type </Th>
                          <Th>category id </Th>
                          <Th textAlign="center">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {dataSubCategory.map((x, i) => (
                          <Tr key={i}>
                            <Td>
                              <HStack>
                                <Stack spacing={0}>
                                  <Text
                                    //   textTransform={'capitalize'}
                                    fontWeight={'bold'}
                                    fontSize="sm"
                                  >
                                    {x.title}
                                  </Text>
                                </Stack>
                              </HStack>
                            </Td>
                            <Td>
                              <Text>
                                {x.type}
                              </Text>
                            </Td>
                            <Td>
                              {x.categoriesId}
                            </Td>

                            <Td>
                              <HStack spacing={2} justifyContent="center">
                                <DynamicButton
                                  size="sm"
                                  action="create"
                                  // isLoading={loadingStates[i]}
                                  onClick={() => handleEditClick(x.id)}
                                />
                                <DynamicButton
                                  size="sm"
                                  action="update"
                                  onClick={() => { setEditData(x.id); setTitleModalSubCategory('Update Sub Category'); handleOpenModaSubCategory() }}
                                />
                                <DynamicButton
                                  size="sm"
                                  action="delete"
                                  onClick={() => deleteData(x.id, i)}
                                />
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>

                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Modal isOpen={OpenModalCategory} onClose={handleCloseModalCategory} size={'xl'} >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{titleModalCategory}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                w={'100%'}
                gap={5}
                direction={['column', 'row', 'row']}
              >
                <Box
                  p={5}
                  w={['100%']}
                  shadow={'base'}
                >
                  <FormControl mb="4" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormControl>

                  <FormControl mb="4" isRequired>
                    <FormLabel>External ID</FormLabel>
                    <Input
                      type="text"
                      value={externalId}
                      onChange={(e) => setExternalId(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mb="4" isRequired>
                    <FormLabel>Type</FormLabel>
                    <Select
                      icon={<MdArrowDropDown />}
                      onChange={(e) => { setType(e.target.value); }}
                      value={type}
                    >
                      <option>physical</option>
                      <option>digital</option>
                    </Select>
                  </FormControl>

                  <Text>Category image : </Text>
                  <CardFile
                    onChange={onSelectFile}
                    fileName={fileName}
                    setFileName={setFileName}
                  />
                  {imageLink !== '' &&
                    <Stack borderWidth={1} position="relative" alignItems="center" justifyContent={'center'} borderRadius="md" minH={'200px'}>
                      <HStack flexDirection={['column', 'row', 'row',]} position={'absolute'} top={-2} right={-2} spacing={2}>
                        <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(imageLink, '_blank',)} />
                        <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => setImageLink('')} />
                      </HStack>
                      <Stack overflow={'hidden'}>
                        <Image src={imageLink} objectFit="contain" w={'200px'} borderRadius={'md'} shadow="md" />
                      </Stack>
                    </Stack>
                  }
                </Box>
              </Flex>
              <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
                <DynamicButton
                  size={'sm'}
                  action="custom"
                  color={'red'}
                  // icon={IoCloseSharp}
                  title={'Cancel'}
                  onClick={handleCloseModalCategory}
                />
                <DynamicButton
                  color={'green'}
                  isLoading={isLoading}
                  size={'sm'}
                  action="custom"
                  title={'Submit'}
                  onClick={() => onSubmitModalCategory()}
                />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={OpenModalSubCategory} onClose={handleCloseModalSubCategory} size={'xl'} >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{titleModalSubCategory}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                w={'100%'}
                gap={5}
                direction={['column', 'row', 'row']}
              >
                <Box
                  p={5}
                  w={['100%']}
                  shadow={'base'}
                >
                  <FormControl mb="4" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </FormControl>
                  <FormControl mb="4" isRequired>
                    <FormLabel>Type</FormLabel>
                    <Select
                      icon={<MdArrowDropDown />}
                      onChange={(e) => { setType(e.target.value); }}
                    >
                      <option>physical</option>
                      <option>digital</option>
                    </Select>
                  </FormControl>
                  <FormControl mb="4" isRequired>
                    <FormLabel>Category</FormLabel>
                    <Select
                      value={filterData?.categoriesId}
                      icon={<MdArrowDropDown />}
                      onChange={(e) => setFilterData({ ...filterData, categoriesId: e.target.value })}
                    >
                      {dataCategory.map((x, i) => (
                        <option key={i} value={x.id}>{x.title}</option>))}
                    </Select>
                  </FormControl>
                </Box>
              </Flex>
              <Text>Sub-category image : </Text>
              <CardFile
                onChange={onSelectFile}
                fileName={fileName}
                setFileName={setFileName}
              />
              {imageLink !== '' &&
                <Stack borderWidth={1} position="relative" alignItems="center" justifyContent={'center'} borderRadius="md" minH={'200px'}>
                  <HStack flexDirection={['column', 'row', 'row',]} position={'absolute'} top={-2} right={-2} spacing={2}>
                    <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(imageLink, '_blank',)} />
                    <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => setImageLink('')} />
                  </HStack>
                  <Stack overflow={'hidden'}>
                    <Image src={imageLink} objectFit="contain" w={'200px'} borderRadius={'md'} shadow="md" />
                  </Stack>
                </Stack>
              }
              <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
                <DynamicButton
                  size={'sm'}
                  action="custom"
                  color={'red'}
                  // icon={IoCloseSharp}
                  title={'Cancel'}
                  onClick={handleCloseModalSubCategory}
                />
                <DynamicButton
                  color={'green'}
                  // isLoading={isLoading}
                  size={'sm'}
                  action="custom"
                  title={'Submit'}
                  onClick={() => onSubmitModalSubCategory()}
                />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isModalOpen} size={'xl'} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Input Produk</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RadioGroup onChange={setInputType} value={inputType}>
                <Stack direction='row' justifyContent={'space-between'}>
                  <Radio value='search'>Search Manual</Radio>
                  <Radio value='import'>Import Excel</Radio>
                  <Radio value='automation'>Automation</Radio>
                </Stack>
              </RadioGroup>
              {inputType === 'search' ? (
                <><>
                  <Input
                    mt={'5%'}
                    type='text'
                    placeholder='Search Variant'
                    // ref={nameRef}
                    onChange={(e) => {
                      setShowSearchResults(e.target.value);
                      handleTypesenseSearch(e.target.value);
                    } } />
                  {showSearchResults && data?.length > 0 ? (
                    data?.map((x, index) => (
                      <HStack
                        key={index}
                        p='2'
                        zIndex={99}
                        width={'auto'}
                        borderBottom='1px'
                      >
                        <Avatar
                          name={x.name}
                          src={x.images ? x.images[0] : ''} />
                        <Box>
                          <Text>{x.name}</Text>
                          <Text>{x.id}</Text>
                        </Box>
                        <Spacer />
                        <Button
                          colorScheme='green'
                          onClick={() => handleAdd(x)}
                        >
                          +
                        </Button>
                      </HStack>
                    ))
                  ) : (
                    showSearchResults && data?.length === 0 ? (
                      <HStack
                        // key={index}
                        p='2'
                        zIndex={99}
                        width={'auto'}
                        borderBottom='1px'
                      >
                        <Box>
                          <Text>Produk Tidak Ditemukan</Text>
                        </Box>
                      </HStack>
                    ) : null
                  )}
                </><TableContainer p={4}>
                  <Table size='sm'>
                    <Thead>
                      <Tr>
                        {/* <Th>Images</Th> */}
                        <Th>Product Id</Th>
                        <Th>Product Name</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataTabel?.map((item) => (
                        <Tr key={item.id}>
                          {/* <Td>
<Image src={item.images} maxW={'100px'} />
</Td> */}
                          <Td>{item.id}</Td>
                          <Td>{item.name}</Td>
                          <Td>
                            <IconButton
                              colorScheme="red"
                              aria-label="Delete"
                              size="sm"
                              icon={<DeleteIcon />}
                              onClick={() => handleDelete(item.id)} />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer></>                
              ) : null}
              
              {inputType === 'import' ? (
                <Box p="5" borderRadius="md" shadow="base" mt={5} textAlign="center">
                  <CardFile onChange={onSelectFiles} />

                  <HStack mb={5} mt={5} justifyContent="center">
                    <DynamicButton
                      mt={5}
                      mx={3}
                      size={'sm'}
                      color={'blue'}
                      action="custom"
                      title={'Load Document'}
                      onClick={() => onImport()}
                    />
                    <DynamicButton
                      mt={5}
                      mx={3}
                      size={'sm'}
                      color={'blue'}
                      action="custom"
                      title={'Download Template'}
                      onClick={downloadTemplate}
                    />
                  </HStack>

                  <TableContainer
                    border={'1px'}
                    borderRadius={'sm'}
                    borderColor={'gray.100'}
                    boxShadow={'base'}
                  >
                    <Table variant="striped">
                      <Thead>
                        <Tr>
                          <Th>Product ID </Th>
                          <Th>Action </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {dataTabel?.map((x, i) => (
                          <Tr key={i}>
                            <Td> {x?.productId} </Td>
                            <Td>
                              <IconButton
                                colorScheme="red"
                                aria-label="Delete"
                                size="sm"
                                icon={<DeleteIcon />}
                                onClick={() => handleDelete(x.productId)} />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>

                </Box>
              ): null}

              {inputType === 'automation' && (
                <>
                  {rows.map((row, rowIndex) => (
                    <Stack direction={'row'} key={rowIndex} mt={'4%'}>
                      <Select value={row?.field} onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'field')} placeholder='Select Option'>
                        <option value="type">Product Type</option>
                        <option value="tags">Product Tags</option>
                        <option value="price_int">Price</option>
                      </Select>
                      {row?.field === 'price_int' ? (
                        <Select value={row?.operator} onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'operator')} placeholder='Select Option'>
                          <option value={'=='}>Is Equal To</option>
                          <option value={'!='}>Is Not Equal To</option>
                          <option value={'>'}>Is Greater Than</option>
                          <option value={'<'}>Is Less Than</option>
                        </Select>
                      ) : null}
                      {row?.field === 'type' ? (
                        <Select value={row?.operator} onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'operator')} placeholder='Select Option'>
                          <option value={'=='}>Is Equal To</option>
                          <option value={'!='}>Is Not Equal To</option>
                        </Select>
                      ) : null}
                      {row?.field === 'tags' ? (
                        <Select value={row?.operator} onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'operator')} placeholder='Select Option'>
                          <option value={'array-contains'}>Contains</option>
                          {/* <option value={'!='}>Is Not Equal To</option> */}
                        </Select>
                      ) : null}

                      {row?.field === 'price_int' ? (
                        <Input
                          type='number'
                          placeholder='Input Price'
                          onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'value')}
                        />
                      ) : null}
                      {row?.field === 'tags' ? (
                        <Input
                          type='text'
                          placeholder='Input Tags'
                          onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'value')}
                        />
                      ) : null}
                      {row?.field === 'type' ? (
                        <Select value={row?.value} onChange={(e) => handleSelect1Change(rowIndex, e.target.value, 'value')} placeholder='Select Option'>
                          <option value={'digital'}>Digital</option>
                          <option value={'physical'}>Physical</option>
                        </Select>
                      ) : null} 
                      <Button onClick={() => removeRow(rowIndex)}><DeleteIcon /></Button>
                    </Stack>
                  ))}
                  <Button onClick={addRow} mt={'2%'}>Tambah Baris</Button>
                </>
              )}


            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={() => {
                handleCloseModal();
                setDataTabel();
                setShowSearchResults(null);
              }}>
  Close
              </Button>

              <Button onClick={() => handleSave(selectedData)} colorScheme='green'>Save</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box >
    </>
  );
}


export default CategoryPage