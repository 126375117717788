import React from 'react';
import PaymentPage from '../Pages/Payment/PaymentPage';
import CheckoutFrontViewPage from '../Pages/View/CheckoutFrontViewPage';
import CheckoutFrontPayment from '../Pages/View/CheckoutFrontPayment';


const PaymentRouter = [

  {
    path: '/payment',
    element: <PaymentPage />,
  },
  {
    path : '/checkout',
    element : <CheckoutFrontViewPage />
  },
  {
    path : '/checkout/payment',
    element : <CheckoutFrontPayment />
  },
];

export default PaymentRouter;
