import React, { useState } from 'react';

import PropTypes from 'prop-types';

export const Navbar3 = ({ name, bgColor, textColor, navmenu, logo, width }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentParent, setCurrentParent] = useState(null);
  const handleSubCategoryClick = (e) => {
    e.preventDefault();

  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header
      className='bg-white'
      style={{ backgroundColor: bgColor || '#fff' }}>
      <nav
        className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
        aria-label='Global'>
        <div className='flex items-center gap-x-12'>
          <a
            href='#'
            className='-m-1.5 p-1.5'>
            <span className='sr-only'>{name || 'Your Company'}</span>
            <img
              className='h-8 w-auto'
              src={logo || 'https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'}
              alt=''
            />
          </a>
          <div className={width === '400px' ? 'hidden' : 'lg:flex lg:gap-x-12'}>
            {navmenu?.length > 0 ? (
              <>
                {navmenu.map((parentItem, parentIndex) => (
                  <div
                    key={parentIndex}
                    onMouseEnter={() => {
                      setCurrentParent(parentIndex);
                      setShowDropdown(true);
                    }}
                    onMouseLeave={() => setShowDropdown(false)}>
                    <a
                      href={parentItem.link}
                      className='text-sm font-semibold leading-6 text-gray-900'
                      style={{ color: textColor || '#111827' }}>
                      {parentItem.name}
                    </a>
                    {showDropdown && currentParent === parentIndex && parentItem.subCategories && (
                      <div className='absolute bg-white shadow-lg rounded-lg w-48 z-10'>
                        {parentItem.subCategories.map((subItem, subIndex) => (
                          <a
                            key={subIndex}
                            onClick={(e) => handleSubCategoryClick(e, subItem.name)}
                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                            {subItem.name}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                <a
                  href='#'
                  className='text-sm font-semibold leading-6 text-gray-900'
                  style={{ color: textColor || '#111827' }}>
                  Product
                </a>
                <a
                  href='#'
                  className='text-sm font-semibold leading-6 text-gray-900'
                  style={{ color: textColor || '#111827' }}>
                  Features
                </a>
                <a
                  href='#'
                  className='text-sm font-semibold leading-6 text-gray-900'
                  style={{ color: textColor || '#111827' }}>
                  Marketplace
                </a>
                <a
                  href='#'
                  className='text-sm font-semibold leading-6 text-gray-900'
                  style={{ color: textColor || '#111827' }}>
                  Company
                </a>
              </>
            )}
          </div>
        </div>
        <div className={width === '400px' ? 'flex' : 'hidden'}>
          <button
            type='button'
            onClick={handleMobileMenuToggle}
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'>
            <span className='sr-only'>Open main menu</span>
            <svg
              className='h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              aria-hidden='true'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          </button>
        </div>
        <div className={width === '400px' ? 'hidden' : 'lg:flex lg:gap-x-12'}>
          <a
            href='#'
            className='text-sm font-semibold leading-6 text-gray-900'>
            Log in <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </nav>
      {/* <!-- Mobile menu, show/hide based on menu open state. --> */}

      <div
        className={width === '400px' ? 'flex' : 'hidden'}
        role='dialog'
        aria-modal='true'>
        {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
        {showMobileMenu && (
          <>
            <div className='inset-0 z-10'></div>
            <div className='inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
              {/* <div className='flex items-center justify-between'>
                <a
                  href='#'
                  className='-m-1.5 p-1.5'>
                  <span className='sr-only'>{name || 'Your Company'}</span>
                  <img
                    className='h-8 w-auto'
                    src={logo || 'https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'}
                    alt=''
                  />
                </a>
                <button
                  type='button'
                  className='-m-2.5 rounded-md p-2.5 text-gray-700'>
                  <span className='sr-only'>Close menu</span>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div> */}
              <div className='mt-6 flow-root'>
                <div className='-my-6 divide-y divide-gray-500/10'>
                  <div className='space-y-2 py-6'>
                    {navmenu?.length > 0 ? (
                      <>
                        {navmenu?.map((x, i) => (
                          <a
                            key={i}
                            href={x?.link}
                            className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                            style={{ color: textColor || '#111827' }}>
                            {x.name}
                          </a>
                        ))}
                      </>
                    ) : (
                      <>
                        <a
                          href='#'
                          className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                          style={{ color: textColor || '#111827' }}>
                          Product
                        </a>
                        <a
                          href='#'
                          className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                          style={{ color: textColor || '#111827' }}>
                          Features
                        </a>
                        <a
                          href='#'
                          className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                          style={{ color: textColor || '#111827' }}>
                          Marketplace
                        </a>
                        <a
                          href='#'
                          className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                          style={{ color: textColor || '#111827' }}>
                          Company
                        </a>
                      </>
                    )}
                  </div>
                  <div className='py-6'>
                    <a
                      href='#'
                      className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                      style={{ color: textColor || '#111827' }}>
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

Navbar3.propTypes = {
  name: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  navmenu: PropTypes.string,
  logo: PropTypes.string,
  width: PropTypes.string,
};
