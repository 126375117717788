/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState, } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Divider,
  Text,
  IconButton,
  Stack,
  Checkbox,
  useToast,
  Flex,
  Switch,
  Spacer,
  Image,
  SimpleGrid,
  Select,
  Grid,
  Heading,
  GridItem,
  InputRightAddon,
  InputGroup,
  FormHelperText,
  Tooltip,
  useColorModeValue,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure, // Tambahkan import untuk Checkbox
} from '@chakra-ui/react';
import { MdArrowDropDown, MdDelete, } from 'react-icons/md';
import {
  arrayRemoveFirebase,
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice, } from '../../Utils/numberUtil';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  useParams,
} from 'react-router-dom';
import CardFile from '../../Components/Accounting/CardFile';
// import { decryptToken, } from '../../Utils/encrypToken';
// import UploadFileToDropbox from '../../Components/DropBox/UploadFileToDropbox';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { BsFillArrowDownCircleFill, } from 'react-icons/bs';
import { auth, } from '../../Config/firebase';
import ProductVariants from '../../Components/Product/ProductVariants';
import DynamicMultiSelectComponent from '../../Components/Select/DynamicMultiSelectComponent';
import { AwsUploadFile } from '../../Utils/AwsUploadFile';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { CopyIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { FcCheckmark } from 'react-icons/fc';


function ProductDetailPage() {
  const param = useParams()
  const productId = param?.id
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef()
  // Product State
  const [warehouses, setWarehouses] = useState([])
  const [details, setDetails] = useState([]);
  const [modules, setModules] = useState([]); // Tambahkan state untuk checkbox modules
  const globalState = useUserStore();
  const toast = useToast();
  const [dataCategory, setDataCategory] = useState([]);
  // const [pipelines, setPipeline] = useState([]);
  // const [pipelineStatus, setPipelineStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalStocks, setTotalStocks] = useState(0)
  // const [description, setDescription] = useState({})
  const [productData, setProductData] = useState({
    categoryId: [],
    tags: []
  });
  const [inputValidations, setInputValidations] = useState({
    name: true,
    stocks: true,
    sold: true,
    duration: true,
    description: true,
    currency: true,
    price: true,
    price_high: true,
    price_low: true,
    discount_type: true,
    discount_amount: true,
    product_skuId: true,
    type: true,
    label: true,
    length: true,
    width: true,
    height: true,
    weight: true,
  });

  // const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [fileName, setFileName] = useState('Select file to import')
  const [checkoutLink, setCheckoutLink] = useState('');


  const getProject = () => {
    const res = globalState?.projects?.find(
      (e,) => e.id === globalState?.currentProject,
    );
    // setProjectId(res?.id);
    setProjectName(res?.name);
  };
  const getDataCategory = async () => {
    // Fetch data from API or other source if needed
    try {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany, },
        { field: 'projectId', operator: '==', value: globalState.currentProject, },
        // { field: 'categoriesId', operator: '==', value: null},
        { field: 'is_parent', operator: '==', value: false, },
      ];

      const res = await getCollectionFirebase('product_categories', conditions,);
      setDataCategory(res);

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
    }
  };

  const handleDataInput = (key, value) => {
    const obj = { ...productData };
    obj[key] = value;
    console.log(obj, 'updated obj');
    setProductData(obj)
  }
  console.log(productData)


  const getProductData = async () => {
    try {
      const res = await getSingleDocumentFirebase('product', productId);
      
      const object = {
        ...res,
        stocks: totalStocks.toString(),
        stocks_int: totalStocks,
        tags: res?.tags?.length > 0 ? res?.tags : [],
        categoryId: res?.categoryId?.length > 0 ? res?.categoryId : [],
      };

      const update = await updateDocumentFirebase('product', productId, object)

      setDetails(update.details || []);
      const resWarehouses = await getCollectionFirebase('warehouses', [
        { field: 'companyId', operator: '==', value: globalState.currentCompany, },
        { field: 'projectId', operator: '==', value: globalState.currentProject, }]);
      // console.log(resWarehouses, 'resWarehouses')
      setWarehouses(resWarehouses || []);
      setProductData({ ...object });
      setCheckoutLink(`https://deoapp-page-view-qwik.vercel.app/buy/?slug=${res.slug}`)
      if (object?.modules?.length > 0) setModules(object?.modules);
      await getCollectionFirebase('files', [
        { field: 'companyId', operator: '==', value: globalState.currentCompany, },
        { field: 'projectId', operator: '==', value: globalState.currentProject, },
        { field: 'type', operator: '==', value: 'acquisition', }]);
      // setPipeline(resPipelines);
      // setProductData({ ...object, pipelineId: resPipelines[0]?.id });
      localStorage.setItem('product_data_on_edit', JSON.stringify(object));
      // localStorage.setItem('warehouses', JSON.stringify(resWarehouses));
      if (productData?.pipelineId) {
        // setPipelineStatus(true)
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
    }
  };


  useEffect(() => {
    getProject();
    getProductData();
    getDataCategory()

  }, [productId, globalState.currentProject,],);

  const handleAlertOnSubmit = () => {
    if (productData?.is_active) {
      onOpen();
    }
  };


  const onSubmitProduct = async () => {
    setIsLoading(true)

    try {
      const newData = {
        name: productData.name || '',
        description: localStorage.getItem('quillContent') || '',
        type: productData.type || '',
        categoryId: productData.categoryId || [],
        companyId: globalState.currentCompany || '',
        createdBy: auth.currentUser.uid || '',
        currency: productData.currency || 'IDR',
        discount_amount: productData.discount_amount || '',
        discount_type: productData.discount_type || '',
        product_skuId: productData.product_skuId || '',
        external_productId: productData.external_productId || '',
        external_product_skuId: productData.external_product_skuId || '',
        label: productData.label || '',
        launchingDate: productData.launchingDate || '',
        pipelineId: productData.pipelineId || '',
        price: productData.price || '', // string
        price_int: parseInt(productData.price) || 0,
        price_high: productData.price_high || '0',
        price_high_int: parseInt(productData.price_high) || 0,
        price_low: productData.price_low || '0',
        price_low_int: parseInt(productData.price_low) || 0,
        projectId: productData.projectId || '0',
        details: details?.length > 0 ? details?.map((detail) => ({
          key: detail?.key?.toLowerCase(),
          value: detail?.value?.toLowerCase(),
        })) : [],
        sold: productData.sold || '0',
        sold_int: parseInt(productData.sold) || 0,
        stocks: productData.stocks || '0',
        stocks_int: parseInt(productData.stocks) || 0,
        shipping: productData?.shipping || '',
        tags: productData.tags,
        is_active: productData.is_active || false,
        is_promo: productData.is_promo || false,
        warehouseId: productData?.warehouseId || '',
        modules: modules?.length > 0 ? modules?.map((module) => module?.toLowerCase()) : [],
        pixelId: productData?.pixelId || ''
      };
      const requiredFields = [
        'name',
        'stocks',
        'categoryId',
        'product_skuId'
      ];

      const validations = {
        name: !!productData.name,
        stocks: !!productData.stocks,
        sold: !!productData.sold,
        description: !!localStorage.getItem('quillContent'),
        // currency: !!productData.currency,
        // price: !!productData.price,
        // price_high: !!productData.price_high,
        // price_low: !!productData.price_low,
        discount_type: !!productData.discount_type,
        discount_amount: !!productData.discount_amount,
        product_skuId: !!productData.product_skuId,
        type: !!productData.type,
        label: !!productData.label,
      };

      if (productData?.type === 'physical') {
        newData.length = productData?.length || 0;
        newData.width = productData?.width || 0;
        newData.height = productData?.height || 0;
        newData.weight = productData?.weight || 0;

        requiredFields.push('length', 'width', 'height', 'weight');

        validations.length = !!productData?.length;
        validations.width = !!productData?.width;
        validations.height = !!productData?.height;
        validations.weight = !!productData?.weight;

        setInputValidations(validations);
        // console.log(newData, 'newww')
        if (newData.width < 0 || newData.height < 0 || newData.length < 0 || newData.weight < 0) {
          return toast({
            title: 'Deoapp.com',
            description: 'Dimensi Tidak boleh dibawah 0',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          })
        }
      }

      if (productData?.type === 'digital') {
        newData.duration = productData?.length || '';

        validations.length = !!productData?.duration;

        setInputValidations(validations);
      }
      const emptyFields = requiredFields.filter((field) => !productData[field]);
      if (emptyFields.length > 0) {
        const element = document.getElementById(emptyFields[0]);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        throw new Error(`Field ${emptyFields.join(', ')} tidak boleh kosong`);
      }

      await updateDocumentFirebase('product', productId, newData);
      localStorage.setItem('product_data_on_edit', JSON.stringify(newData));
      handleAlertOnSubmit();

      toast({
        title: 'Deoapp.com',
        description: 'success update data',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });


    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
      setIsLoading(false,);
    } finally {
      setIsLoading(false,);
    }
  };
  // Handle Detail Product & Warehouse 
  const handleAddDetail = () => {
    setDetails([...details, { key: '', value: '', }],);
    // console.log(details)
  };
  // const handleAddWarehouse = () => {
  //   setWarehouses([...warehouses, { latitude: '', longitude: '', },],);
  // };

  // const handleRemoveWarehouse = (index,) => {
  //   const updatedWarehouses = [...warehouses,];
  //   updatedWarehouses.splice(index, 1,);
  //   setWarehouses(updatedWarehouses,);
  // };

  const handleGenerateLink = () => {
    try {
      // navigator.clipboard.writeText(`${window.location.host}/checkout?slug=${arg.data.slug}`);
      navigator.clipboard.writeText(checkoutLink);
      toast({
        description: 'Checkout page link copied!',
        status: 'success',
        duration: 2000,
        position: 'top'
      })
    } catch (error) {
      toast({
        title: 'Deoapp',
        description: error?.message,
        status: 'error',
        isClosable: true,
        position: 'top',
      });
    }
  };



  const handleRemoveDetail = (index,) => {
    const updatedDetails = [...details,];
    updatedDetails.splice(index, 1,);
    setDetails(updatedDetails,);
  };

  // const handleWarehouseChange = (index, latitude, longitude,) => {
  //   const updatedWarehouses = [...warehouses,];
  //   updatedWarehouses[index] = { latitude, longitude, };
  //   setWarehouses(updatedWarehouses,);
  // };
  const handleDetailChange = (index, key, value,) => {
    const updatedDetails = [...details,];
    updatedDetails[index] = { key, value, };
    setDetails(updatedDetails,);
  };


  // Handle Modules
  const handleModulesChange = (event,) => {
    const { value, checked, } = event.target;

    if (checked) {
      setModules([...modules, value,],);
    } else {
      const updatedModules = modules?.filter((module,) => module !== value,);
      setModules(updatedModules,);
    }
  };


  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)
    const uploadData = {
      file,
      folder: 'folder/v1/xx'
    }
    try {
      const result = await AwsUploadFile(uploadData);

      if (result.status === true) {
        const collectionName = 'product';
        const docName = param.id;
        const field = 'images';
        const values = [result.data];

        await arrayUnionFirebase(collectionName, docName, field, values,);

        toast({
          status: 'success',
          duration: 9000,
          title: 'Upload your image product success.',
          isClosable: true,
        });
        getProductData();
      } else {
        toast({
          status: 'error',
          duration: 9000,
          title: 'Upload image failed',
          isClosable: true,
        });
      }

    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      },);
    }
  }

  const handleRemoveFeature = (index, key) => {
    let array = productData[key];
    if (array?.length === 1) {
      array = []
    } else {
      array.splice(index, 1);
    }
    setProductData({
      ...productData,
      [key]: array
    })
  };

  const MyEditor = ({ productData }) => {
    const [content, setContent] = useState(productData?.description || '');

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const doc = await getSingleDocumentFirebase('product', productId);
    //       if (doc) {
    //         const savedContent = doc.description;
    //         setContent(savedContent);
    //       }
    //     } catch (error) {
    //       console.error('Error getting document:', error);
    //     }
    //   };

    //   fetchData();
    // }, [productId]);

    const handleChange = (html) => {
      setContent(html);
      localStorage.setItem('quillContent', html);
    };

    const onChange = (html) => {
      handleChange(html);
      // setVariantData((prevState) => ({
      //   ...prevState,
      //   description: html,
      // }));      
    };

    return (
      <div
        style={{
          backgroundColor: useColorModeValue('white'),
          overflow: 'hidden'
        }}>
        <ReactQuill
          style={{
            height: 300,
          }}
          value={content}
          onChange={onChange}
          isInvalid={!inputValidations?.description}
        />
      </div>
    );
  };


  const handleDeleteImage = async (data) => {
    try {
      const resultDelete = await arrayRemoveFirebase('product', productData.id, 'images', [data]);
      toast({
        status: 'success',
        description: resultDelete,
        duration: 3000
      })
      getProductData();
    } catch (error) {
      toast({
        status: 'error',
        duration: 3000,
        description: error.message
      })
    }
  };
  console.log(details,'detail')
  const handleAddValuesToArray = (categoryId, key) => {
    let arr = [];
    if (key === 'categoryId') {
      arr = [...productData?.categoryId]
    } else if (key === 'tags') {
      arr = [...productData?.tags]
    } else {
      arr = productData[key];
    }

    arr.push(categoryId);

    switch (key) {
    case 'categoryId':
      setProductData({
        ...productData,
        categoryId: arr
      });
      break;
    case 'tags':
      setProductData({
        ...productData,
        tags: arr
      });
      break;
    default:
      setProductData({
        ...productData,
        [key]: arr
      });
      break;
    }
  };

  useEffect(() => {
    getProductData()
  }, [totalStocks])

  return (
    <>
      <Stack>
        <BackButtons />
        <Heading size='md'>{productData.name}</Heading>
        <Text color='gray'>{productData.slug}</Text>
      </Stack>
      <Grid
        templateColumns={{ base: '1 fr', md: '1fr', lg: 'repeat(5, 1fr)' }}
        gap={5}>
        <GridItem
          colSpan={3}
          gap={5}
          p={[1, 1, 5,]}
          spacing={5}
          borderRadius="md"
          shadow={'md'}
          bg={useColorModeValue('gray.100', '')}
          mb={2}
          w='100%'
        >

          <VStack spacing={5} align={'left'} w="100%">
            <Stack>
              <Text fontWeight={'bold'}>Product Image: </Text>

              <CardFile
                onChange={onSelectFile}
                fileName={fileName}
                setFileName={setFileName}
              />
              {productData.images?.length > 0 && (

                <SimpleGrid h={'250px'} overflowY="scroll" py={3} spacing={4} columns={[2,]}>
                  {productData.images?.map((x, index,) => {

                    return (
                      <Stack
                        key={index}
                        borderWidth={1}
                        position="relative"
                        alignItems="center"
                        justifyContent={'center'}
                        borderRadius="md"
                        minH={'200px'}
                        bg={useColorModeValue('white', '')}
                      >
                        <HStack flexDirection={['column', 'row', 'row',]} position={'absolute'} top={-2} right={-2} spacing={2}>
                          <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(x, '_blank',)} />
                          <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => handleDeleteImage(x)} />
                        </HStack>
                        <Stack overflow={'hidden'}>
                          <Image src={x} objectFit="contain" w={'200px'} borderRadius={'md'} shadow="md" />
                        </Stack>
                      </Stack>

                    )
                  },)}
                </SimpleGrid>
              )}
              {productData.images?.length > 2 && (
                <Stack alignItems={'center'} justifyContent="center">
                  <BsFillArrowDownCircleFill />
                </Stack>
              )}

            </Stack>

            <Stack>
              <Spacer />
              <Text fontSize={'lg'} fontWeight={500}>Product Data</Text>
              <Divider />

            </Stack>

            <FormControl mt="5" id="Project" isRequired>
              <FormLabel>
                <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
                Project</FormLabel>
              <Input bg={useColorModeValue('white', '')} value={projectName} variant={'unstyled'} disabled />
            </FormControl>


            <Stack w="100%"
              bg={'gray.50'}
              p={5}
              borderRadius={10}
              position='relative'
            >
              <Stack>
                <Spacer />
                <Text fontSize={'lg'} fontWeight={500}>Product Identification</Text>
                <Divider />

              </Stack>
              <HStack>
                <FormControl id="name" isRequired>
                  <FormLabel>
                    <Tooltip label={'Nama Product'}>
                      <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                    </Tooltip>
                    Name:</FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type="text"
                    w="100%"
                    defaultValue={productData.name}
                    value={productData.name}
                    id={'name'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                    isInvalid={!inputValidations.name}
                  />
                </FormControl>

                <FormControl id="stocks" isRequired>
                  <FormLabel>
                    <Tooltip label={'Jumlah ketersediaan product kamu'}>
                      <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                    </Tooltip>
                    Stock Product</FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type="text"
                    defaultValue={productData.stocks}
                    value={productData.stocks}
                    id={'stocks'}
                    isDisabled
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                    isInvalid={!inputValidations.stocks}
                  />
                </FormControl>
              </HStack>
              <HStack>
                <FormControl id="sku" isRequired>
                  <FormLabel>Product ID:</FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type={'text'}
                    defaultValue={productId}
                    value={productId}
                    variant={'unstyled'}
                    disabled
                  // onChange={(e) => setProductId(e.target.value)}
                  />
                </FormControl>

                <FormControl id="sku" >
                  <FormLabel>
                    <Tooltip label={'Opsional. ID produck dari software lain'}>
                      <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                    </Tooltip>
                    External Product ID:</FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type={'text'}
                    id={'external_productId'}
                    defaultValue={productData.external_productId}
                    value={productData.external_productId}
                    // onChange={(e) => setExternalId(e.target.value)}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  />
                </FormControl>
                <FormControl id="sku">
                  <FormLabel>
                    <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                      <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                    </Tooltip>
                    External Product SKU ID:
                  </FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type={'text'}
                    defaultValue={productData.external_product_skuId}
                    value={productData.external_product_skuId}
                    id={'external_product_skuId'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  />
                </FormControl>

              </HStack>

              <HStack>
                <FormControl id="sku" isRequired>
                  <FormLabel>
                    <Tooltip label={'Kode unik untuk setiap produk contoh : DEO-M1B-3'}>
                      <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                    </Tooltip>
                    Product SKU:
                  </FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type={'text'}
                    defaultValue={productData.product_skuId}
                    value={productData.product_skuId}
                    id={'product_skuId'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                    isInvalid={!inputValidations.product_skuId}
                  />
                </FormControl>
              </HStack>


              <HStack>

                <FormControl id="name">
                  <FormLabel>
                    <Tooltip label={'Opsional. Jumlah product terjual'}>
                      <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                    </Tooltip>
                    Amount Sold:</FormLabel>
                  <Input bg={useColorModeValue('white', '')}
                    type="number"
                    w="100%"
                    defaultValue={productData.sold || ''}
                    // value={productData.sold || '0'}
                    id={'sold'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                    isInvalid={!inputValidations.sold}
                  />
                </FormControl>
              </HStack>
            </Stack>


            <DynamicMultiSelectComponent
              title={'Category'}
              listOptions={dataCategory}
              selectedOptions={productData?.categoryId}
              masterData={dataCategory}
              avatar={false}
              handleAddSelection={handleAddValuesToArray}
              hanldeDeleteSelection={handleRemoveFeature}
              select={true}
              stringKey={'categoryId'}
              tooltipLabel={'Kategori produk. Kamu dapat menambahkan kategori dan sub-kategori di menu Product > Master Category product'}
            />
            <DynamicMultiSelectComponent
              title={'Tags'}
              selectedOptions={productData?.tags}
              avatar={false}
              handleAddSelection={handleAddValuesToArray}
              hanldeDeleteSelection={handleRemoveFeature}
              textInput={true}
              stringKey={'tags'}
              tooltipLabel={'Tag produk kamu (cth: 10.10)'}
            />

            <FormControl id="type" isRequired>
              <FormLabel>
                <Tooltip label={'Tipe produk : produk fisik / produk digital'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
                Type
              </FormLabel>
              <Select
                bg={useColorModeValue('white')}
                defaultValue={productData.type}
                value={productData.type}
                placeholder='Product Type'
                icon={<MdArrowDropDown />}
                // onChange={(e,) => setType(e.target.value,)}
                id={'type'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                isInvalid={!inputValidations.type}
              >
                <option value='physical'>Physical</option>
                <option value='digital'>Digital</option>
              </Select>
            </FormControl>
            {productData?.type === 'physical' &&
              <DimensionForms
                productData={productData}
                handleDataInput={handleDataInput}
                inputValidations={inputValidations}
                setInputValidations={setInputValidations} 
                warehouses={warehouses}
              />
            }
            {/* {productData?.type === 'digital' &&
              <DurationForm 
                productData={productData}
                handleDataInput={handleDataInput}
                inputValidations={inputValidations}
                setInputValidations={setInputValidations} />
            } */}

            <FormControl isRequired>
              <FormLabel>
                <Tooltip label={'Opsional.'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
                Label
              </FormLabel>
              <Select
                bg={useColorModeValue('white')}
                defaultValue={productData.label}
                value={productData.label}
                placeholder='Label your product'
                icon={<MdArrowDropDown />}
                // onChange={(e,) => setLabel(e.target.value,)}
                id={'label'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                isInvalid={!inputValidations.label}
              >
                <option value='enable'>Enable</option>
                <option value='disable'>Disable</option>
                <option value='hold'>Hold</option>
              </Select>
            </FormControl>

            <FormControl id="description" isRequired>
              <FormLabel>
                <Tooltip label={'Deskripsikan produkmu di sini (fitur, cara kerja, dll)'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
                Description:</FormLabel>
              <MyEditor productData={productData} />
              {/* <ReactQuill defaultValue={productData.description} onChange={(e) => handleContentChange(e)} /> */}
              {/* <RichTextEditor 
                value={productData.description}
                onChange={handleContentChange} 
                isInvalid={!inputValidations.description} 
                defaultValue={productData.description}
              /> */}
              {/* <Textarea
                minH={500}
                defaultValue={productData.description}
                value={productData.description}
                // onChange={(e,) => setDescription(e.target.value,)}
                id={'description'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                isInvalid={!inputValidations.description}
              /> */}
            </FormControl>

            <Stack w="100%"
              bg={useColorModeValue('gray.50')}
              p={5}
              borderRadius={10}
              position='relative'
            >
              <Stack w={'xl'}>
                {/* <Spacer /> */}
                <HStack>
                  <Text fontSize={'lg'} fontWeight={500}>Product Price (optional)</Text>
                  <Tooltip label={'Opsional. Harga produk dapat diatur di harga variant product di tabel sebelah kanan'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  {/* <Divider /> */}
                </HStack>

              </Stack>

              <FormControl id="currency">
                <FormLabel>
                  <Tooltip label={'Pilih mata uang untuk produk kamu'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Currency:</FormLabel>
                <Select
                  bg={useColorModeValue('white')}
                  defaultValue={productData.currency || 'IDR'}
                  value={productData.currency}
                  icon={<MdArrowDropDown />}
                  // onChange={(e,) => setCurrency(e.target.value,)}
                  id={'currency'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  // isInvalid={!inputValidations.currency}
                  placeholder='Currency your product price'
                >
                  <option value='IDR'>IDR</option>
                  <option value='USD'>USD</option>
                </Select>
              </FormControl>

              <FormControl id="price">
                <FormLabel>
                  <Tooltip label={'Harga produk'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Price</FormLabel>
                <HStack alignItems={'center'} justifyContent="center">
                  <Text>Rp.</Text>
                  <Input bg={useColorModeValue('white', '')}
                    w={'auto'}
                    type="number"
                    size={'sm'}
                    defaultValue={productData.price}
                    value={productData.price}
                    // onChange={(e,) => setPrice(e.target.value,)}
                    id={'price'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  // isInvalid={!inputValidations.price}
                  />
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp.{formatFrice(parseFloat(productData.price || 0,),)}
                  </Text>
                </HStack>
              </FormControl>

              <FormControl id="price">
                <FormLabel>
                  <Tooltip label={'Harga tertinggi dari variant produk anda'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Price High</FormLabel>
                <HStack alignItems={'center'} justifyContent="center">
                  <Text>Rp.</Text>
                  <Input bg={useColorModeValue('white', '')}
                    w={'auto'}
                    type="number"
                    size={'sm'}
                    defaultValue={productData.price_high}
                    value={productData.price_high}
                    // onChange={(e,) => setPriceHigh(e.target.value,)}
                    id={'price_high'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  // isInvalid={!inputValidations.price_high}
                  />
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp.{formatFrice(parseFloat(productData.price_high || 0,),)}
                  </Text>
                </HStack>
              </FormControl>


              <FormControl id="price">
                <FormLabel>
                  <Tooltip label={'Harga terendah dari variant produk anda'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Price Low</FormLabel>
                <HStack>
                  <Text>Rp.</Text>
                  <Input bg={useColorModeValue('white', '')}
                    size={'sm'}
                    w={'auto'}
                    type="number"
                    defaultValue={productData.price_low}
                    value={productData.price_low}
                    // onChange={(e,) => setPriceLow(e.target.value,)}
                    id={'price_low'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  // isInvalid={!inputValidations.price_low}
                  />
                  <Spacer />
                  <Text fontWeight={500}>
                    Rp.{formatFrice(parseFloat(productData.price_low || 0,),)}
                  </Text>
                </HStack>
              </FormControl>
            </Stack>




            <Stack>
              <Spacer />
              <HStack>
                <Text fontSize={'lg'} fontWeight={500}>Product Discount</Text>
                <Tooltip label={'Diskon akan berlaku di semua Variant dalam produk ini'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
              </HStack>
              <Divider />
            </Stack>


            <HStack>
              <FormControl id="weight" isRequired>
                <FormLabel>
                  <Tooltip label={'Kamu dapat mengatur diskon persen maupun jumlah potongan dalam rupiah, aktifkan diskon pada tombol "Activate Promo" di paling bawah'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Discount type (percentage atau value)
                </FormLabel>
                <Select
                  bg={useColorModeValue('white')}
                  defaultValue={productData.discount_type}
                  value={productData.discount_type}
                  icon={<MdArrowDropDown />}
                  // onChange={(e,) => setDiscountType(e.target.value,)}
                  id={'discount_type'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  // isInvalid={!inputValidations.discount_type}
                  placeholder='selected currency'
                >
                  <option value='percentage'>Percentage</option>
                  <option value='value'>Value</option>
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  <Tooltip label={'Jika tipe diskon dalam persen, tulis 10 untuk 10%, jika tipe diskon value, tulis 25000 untuk potongan Ro 25.000,-'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Amount
                </FormLabel>
                <Input bg={useColorModeValue('white', '')}
                  type="number"
                  defaultValue={productData.discount_amount}
                  value={productData.discount_amount}
                  id={'discount_amount'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                // isInvalid={!inputValidations.discount_amount}
                // onChange={(e,) => setDiscountAmount(e.target.value,)}
                />
              </FormControl>
            </HStack>

            <Stack>
              <Spacer />
              <Text fontSize={'lg'} fontWeight={500}>Product Details</Text>
              <Tooltip label={'Jumlah diskon, jika tipe diskon dalam persen, tulis 10 untuk 10%, jika tipe diskon value, tulis 25000 untuk potongan Ro 25.000,-'}>
                <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
              </Tooltip>
              <Divider />
            </Stack>


            <Text fontWeight={500}>Jelaskan detail produk anda :</Text>
            <Text my={-2} color='gray'>Contoh : {'dikirim dari : Jakarta Barat'} || {'material : cotton combed 30s'} || {'produk dikirim : via email'}</Text>

            <Grid gap={3} templateColumns={{ base: 'fr', md: '2fr 1fr' }}>
              <Stack>
                {details?.length > 0 && (
                  details?.map((detail, index) => (
                    <HStack key={index} align={'center'} justify={'center'}>
                      <FormControl id={`detail-key-${index}`}>
                        <FormLabel>
                          <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                            <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                          </Tooltip>
                          Key:</FormLabel>
                        <Input bg={useColorModeValue('white', '')}
                          type="text"
                          placeholder="Garansi Produk"
                          value={detail.key}
                          onChange={(e) =>
                            handleDetailChange(index, e.target.value, detail.value)
                          }
                        />
                      </FormControl>
                      <FormControl id={`detail-value-${index}`}>
                        <FormLabel>
                          <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                            <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                          </Tooltip>
                          Value:</FormLabel>
                        <Input bg={useColorModeValue('white', '')}
                          placeholder="12 bulan"
                          type="text"
                          value={detail.value}
                          onChange={(e) =>
                            handleDetailChange(index, detail.key, e.target.value)
                          }
                        />
                      </FormControl>
                      <Box pt="7">
                        <IconButton
                          icon={<MdDelete />}
                          aria-label="Remove Detail"
                          onClick={() => handleRemoveDetail(index)}
                        />
                      </Box>
                    </HStack>
                  ))
                )}

              </Stack>
            </Grid>
            <Button
              variant={'outline'}
              colorScheme="blue"
              onClick={handleAddDetail}
            >
              Add Detail
            </Button>

            <FormControl isRequired>
              <FormLabel>
                <Tooltip label={'Opsional.'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
                Launching Date
              </FormLabel>
              <Input 
                type='date' 
                bg={useColorModeValue('white', '')} 
                defaultValue={productData.launchingDate}
                value={productData.launchingDate}
                id={'launchingDate'}
                // onChange={(e) => console.log(e.target.id, e.target.value)}/>
                onChange={(e) => handleDataInput(e.target.id, new Date(e.target.value))}/>
            </FormControl>



            {/* <Stack>
              <Spacer />
              <HStack justifyContent="space-between">
                <Text fontSize={'lg'} fontWeight={500}>Set Pipeline</Text>
                <Switch id='email-alerts' onChange={(event) => setPipelineStatus(event.target.checked)} isChecked={productData.pipelineId} />
              </HStack>
              <Divider />
            </Stack> */}
            {/* {productData.pipelineId ?
              <FormControl id="currency">
                <FormLabel>
                  <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Please select pipeline:</FormLabel>
                <Select
                  bg={useColorModeValue('white')} id='pipelineId' onChange={(e) => handleDataInput(e.target.id, e.target.value)}>
                  {pipelines?.map((warehouse, i) =>
                    <option key={i} value={warehouse.id}>{warehouse?.title}</option>
                  )}
                </Select>
              </FormControl>
              : <></>
            } */}

            {/* {warehouses?.map((warehouse, index,) => (
              <HStack key={index} align={'center'} justify={'center'}>
                <FormControl id={`warehouse-latitude-${index}`}>
                  <FormLabel>
                                    <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Latitude:</FormLabel>
                  <Input bg={useColorModeValue('white','')}
                    type="text"
                    placeholder={'Enter detail latitude'}
                    value={warehouse.latitude}
                    defaultValue={warehouse.latitude}
                    onChange={(e,) =>
                      handleWarehouseChange(index, e.target.value, warehouse.longitude,)
                    }
                  />
                </FormControl>
                <FormControl id={`warehouse-longitude-${index}`}>
                  <FormLabel>
                                    <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                    <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                  </Tooltip>
                  Longitude:</FormLabel>
                  <Input bg={useColorModeValue('white','')}
                    type="text"
                    placeholder={'Enter detail longitude'}
                    value={warehouse.longitude}
                    defaultValue={warehouse.longitude}
                    onChange={(e,) =>
                      handleWarehouseChange(index, warehouse.latitude, e.target.value,)
                    }
                  />
                </FormControl>
                <Box pt="7">
                  <IconButton
                    icon={<MdDelete />}
                    aria-label="Remove Detail"
                    onClick={() => handleRemoveWarehouse(index,)}
                  />
                </Box>
              </HStack>
            ),)}

            <Button
              variant={'outline'}
              colorScheme="blue"
              onClick={handleAddWarehouse}
            >
              Add Warehouse
            </Button> */}

            <Flex my>
              <FormControl id="is_active">
                <Switch
                  isChecked={productData.is_active}
                  onChange={(e) => handleDataInput(e.target.id, e.target.checked)}
                >
                  Publish Product
                </Switch>
                <Text color={'gray.400'} fontSize={13} py={3}>
                  The Product will active and appear to your pageview
                </Text>

              </FormControl>

              <FormControl id="is_promo">
                <Switch
                  isChecked={productData.is_promo}
                  onChange={(e) => handleDataInput(e.target.id, e.target.checked)}
                >
                  Activate Promo
                </Switch>
                <Text color={'gray.400'} fontSize={13} py={3}>
                  The product will be discounted as set above{' '}
                </Text>

              </FormControl>
            </Flex>

            <FormControl id="modules">
              <FormLabel>
                <Tooltip label={'Opsional.'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>
                Modules:</FormLabel>
              <Checkbox
                value="commerce"
                onChange={handleModulesChange}
                isChecked={modules?.includes('commerce',)}
              >
                Commerce
              </Checkbox>
              <Checkbox
                value="management"
                onChange={handleModulesChange}
                isChecked={modules?.includes('management',)}
                mx="5"
              >
                Management
              </Checkbox>

              <Checkbox
                value="marketing"
                onChange={handleModulesChange}
                isChecked={modules?.includes('marketing',)}
              >
                Marketing
              </Checkbox>

              <Checkbox
                value="product"
                onChange={handleModulesChange}
                isChecked={modules?.includes('product',)}
                mx="5"
              >
                Product
              </Checkbox>

              <Checkbox
                value="sop"
                onChange={handleModulesChange}
                isChecked={modules?.includes('sop',)}
                mx="5"
              >
                SOP
              </Checkbox>
              <Checkbox
                value="hrga"
                onChange={handleModulesChange}
                isChecked={modules?.includes('hrga',)}
                mx="5"
              >
                HRGA
              </Checkbox>
              <Checkbox
                value="socmed"
                onChange={handleModulesChange}
                isChecked={modules?.includes('socmed',)}
                mx="5"
              >
                Social Media
              </Checkbox>
            </FormControl>

            <Button isLoading={isLoading} colorScheme="blue" onClick={onSubmitProduct}>
              Update Data Product
            </Button>
          </VStack>
        </GridItem>

        <GridItem
          colSpan={2}
          gap={5}
          p={[1, 1, 5,]}
          spacing={5}
          borderRadius="md"
          shadow={'md'}
          mb={2}
          w='100%'
        >
          <ProductVariants productId={productId} totalStocks={(e) =>  {setTotalStocks(e)}} />
        </GridItem>

      </Grid >
      <AlertDialog
        size={'2xl'}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <HStack>
                <Text fontSize='lg' fontWeight='bold'>Your Product is Live!</Text>
                <FcCheckmark size={'1.5em'} />
              </HStack>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Checkout page : </Text>
              <InputGroup>
                <Input
                  defaultValue={checkoutLink}
                  onChange={(e) => setCheckoutLink(e.target.value)}
                />
                <InputRightAddon cursor={'pointer'} onClick={handleGenerateLink}>
                  <CopyIcon />
                </InputRightAddon>
              </InputGroup>
              <Text>Pixel ID : </Text>
              <Input
                defaultValue={productData?.pixelId}
                onChange={(e) => {
                  setProductData((prevState) => ({
                    ...prevState,
                    pixelId: e.target.value
                  }))
                }}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack gap={5}>
                <Button
                  onClick={onSubmitProduct}
                  colorScheme='blue'
                >
                  Save Pixel ID
                </Button>
                <Button ref={cancelRef} onClick={onClose}>
                  Close
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

const DimensionForms = ({ productData, handleDataInput, inputValidations, setInputValidations,warehouses }) => {
  return (
    <>
      <Stack w="100%"
        bg={useColorModeValue('gray.50')}
        p={5}
        borderRadius={10}
        position='relative'
      >
        {/* <FormControl id="type" isRequired>
        <FormLabel>
          <Tooltip label={'Opsional. SKU Produk dari software lain'}>
            <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
          </Tooltip>

          Shipping
        </FormLabel>
        <Select
          bg={useColorModeValue('white')}
          defaultValue={productData.shipping}
          value={productData.shipping}
          placeholder='Select courier : JNE / OExpress'
          icon={<MdArrowDropDown />}
          id={'shipping'}
          onChange={(e) => handleDataInput(e.target.id, e.target.value)}
          required
        >
          <option value='jne'>JNE</option>
          <option value='oExpress'>OExpress</option>
          <option value='rajaOngkir'>Raja Ongkir</option>
        </Select>
      </FormControl> */}
        <FormControl id="type" isRequired>
          <FormLabel>
            <Tooltip label={'Opsional. SKU Produk dari software lain'}>
              <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
            </Tooltip>

          Dimension
          </FormLabel>
        </FormControl>
        <SimpleGrid columns={2}>
          <Image src={require('../../assets/image/box-dimensions.jpeg')} width={300} height={undefined} />
          <Stack>
            <FormControl id="type" isRequired>
              <FormLabel>
                <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>

              Length
              </FormLabel>
              <HStack>
                <Input bg={useColorModeValue('white', '')}
                  defaultValue={productData?.length}
                  id={'length'}
                  onChange={(e) => {
                    handleDataInput(e.target.id, e.target.value);
                    setInputValidations((prevState) => ({
                      ...prevState,
                      length: !!e.target.value,
                    }));
                  }}
                  type={'number'}
                  isInvalid={!inputValidations.length}
                />
                <Text fontWeight={'bold'}>CM</Text>
              </HStack>
            </FormControl>
            <FormControl id="type" isRequired>
              <FormLabel>
                <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>

              Width
              </FormLabel>
              <HStack>
                <Input bg={useColorModeValue('white', '')}
                  defaultValue={productData?.width}
                  id={'width'}
                  onChange={(e) => {
                    handleDataInput(e.target.id, e.target.value);
                    setInputValidations((prevState) => ({
                      ...prevState,
                      width: !!e.target.value,
                    }));
                  }}
                  type={'number'}
                  isInvalid={!inputValidations.width}
                />
                <Text fontWeight={'bold'}>CM</Text>
              </HStack>
            </FormControl>
            <FormControl id="type" isRequired>
              <FormLabel>
                <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>

              Height
              </FormLabel>
              <HStack>
                <Input bg={useColorModeValue('white', '')}
                  defaultValue={productData?.height}
                  id={'height'}
                  onChange={(e) => {
                    handleDataInput(e.target.id, e.target.value);
                    setInputValidations((prevState) => ({
                      ...prevState,
                      height: !!e.target.value,
                    }));
                  }}
                  type={'number'}
                  isInvalid={!inputValidations.height}
                />
                <Text fontWeight={'bold'}>CM</Text>
              </HStack>
            </FormControl>
            <FormControl id="type" isRequired>
              <FormLabel>
                <Tooltip label={'Opsional. SKU Produk dari software lain'}>
                  <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
                </Tooltip>

              Weight
              </FormLabel>
              <HStack>
                <Input bg={useColorModeValue('white', '')}
                  defaultValue={productData?.weight}
                  id={'weight'}
                  onChange={(e) => {
                    handleDataInput(e.target.id, e.target.value);
                    setInputValidations((prevState) => ({
                      ...prevState,
                      weight: !!e.target.value,
                    }));
                  }}
                  type={'number'}
                  isInvalid={!inputValidations.weight}
                />
                <Text fontWeight={'bold'}>KG</Text>
              </HStack>
            </FormControl>
          </Stack>
        </SimpleGrid>

        <Stack>
          <Spacer />
          <Text fontSize={'lg'} fontWeight={500}>Please Select Warehouse</Text>
          <Divider />
        </Stack>

        <FormControl id="currency" isRequired>
          <FormLabel>
            <Tooltip label={'Pilih warehouse di mana produk ini disimpan, konfigurasi warehouse untuk pengiriman buka menu Product > Warehouses'}>
              <InfoOutlineIcon color={'gray'} boxSize={3} mx={3} />
            </Tooltip>
                  Please select warehouse to store the product:</FormLabel>
          <Select
            bg={useColorModeValue('white')} 
            id='warehouseId' 
            onChange={(e) => handleDataInput(e.target.id, e.target.value)} 
            placeholder='Please select warehouse'
            defaultValue={productData?.warehouseId}
          >
            {warehouses?.map((warehouse, i) =>
              <option key={i} value={warehouse.id}>{warehouse?.name}</option>
            )}
          </Select>
          <FormHelperText>Ignore this field if product type is digital</FormHelperText>
        </FormControl>

      </Stack>
    
    </>
  )

}

// const DurationForm = ({ productData, handleDataInput, inputValidations, setInputValidations }) => {
//   return (
//     <>
//       <FormControl id="duration" isRequired>
//         <FormLabel>Duration</FormLabel >
//         <InputGroup>
//           <Input bg={useColorModeValue('white','')}
//             type="number"
//             defaultValue={productData.duration}
//             id={'duration'}
//             onChange={(e) => {
//               handleDataInput(e.target.id, e.target.value);
//               setInputValidations((prevState) => ({
//                 ...prevState,
//                 duration: !!e.target.value,
//               }));
//             }}
//             isInvalid={!inputValidations.duration}
//           />        
//           <InputRightAddon>
//                   Month
//           </InputRightAddon> 
//         </InputGroup>
//       </FormControl> 
//     </>
//   )
// }

DimensionForms.propTypes = {
  productData: PropTypes.object,
  handleDataInput: PropTypes.func,
  inputValidations: PropTypes.object,
  setInputValidations: PropTypes.func
};

export default ProductDetailPage;
