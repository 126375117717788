import { AspectRatio, Box, Container, Heading, HStack, Text, Textarea } from '@chakra-ui/react'
import React from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'

function IntegrationWebChatPage() {
  const script = '<script type="text/javascript">window.$crisp=[];window.CRISP_WEBSITE_ID="216af16a-c418-44ac-951e-11b7f48ee66a";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>'
  return (
    <Box>
      <HStack>
        <BackButtons/>
        <Heading>Whatsapp</Heading>
      </HStack>
      <Container p='2' shadow='base'>
        <AspectRatio maxW='full' ratio={16 / 9}>
          <iframe
            title='naruto'
            src='https://www.youtube.com/embed/QhBnZ6NPOY0'
            allowFullScreen
          />
        </AspectRatio>
        
        <Heading mt='2' fontSize='lg'>1. Copy the following code</Heading>
        <Textarea value={script} disabled />
          
        <Heading mt='2' fontSize='lg'>2. Add the code in your page</Heading>
        <Text>
        Paste the Crisp chatbox code above in your page. You can add it in the HTML head section. If the chatbox is not showing, try to reset your cache. You can also contact our support team.
        </Text>

      </Container>
    </Box>  )
}

export default IntegrationWebChatPage