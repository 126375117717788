import { Center, HStack, Heading, Spinner, Stack, Text, useColorMode } from '@chakra-ui/react'
import React, { memo } from 'react'
import { PropTypes } from 'prop-types'

const AnalyticsCard = ({
  icon,
  title,
  unit,
  loading, 
  number
}) => {
  const { colorMode } = useColorMode();



  return (
    <>
      <Stack bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} shadow={3} p={5} spacing={5} justifyContent='center' borderRadius='xl'>
        {/* <Text>{JSON.stringify(conditions)}</Text> */}
        <HStack>
          <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
            {icon}
          </Stack>
          <Text color={'gray.500'}>{title}</Text>
        </HStack>
        <Stack>
          {loading ? <Center><Spinner size='md' color='green.500' /></Center> :
            <Heading>{isNaN(number) ? 0 : number}{' '}{unit}</Heading>
          }
        </Stack>
        <Stack alignItems={'flex-end'} justifyContent='flex-end'>
          {/* <Text fontSize={'sm'} color='blue.400'>See Section</Text> */}
        </Stack>
      </Stack>
    </>
  )
}

export default memo(AnalyticsCard)


AnalyticsCard.propTypes = {
  collectionName: PropTypes.string,
  unit: PropTypes.string,
  conditions: PropTypes.array,
  title: PropTypes.string,
  icon: PropTypes.func,
  loading: PropTypes.bool,
  number: PropTypes.number
}
