import React from 'react';
import DashboardPage from '../Pages/Dashboard/DashboardPage';




const DashboardRouter = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
  },
];

export default DashboardRouter;
