/* eslint-disable react/no-unknown-property */

import React from 'react';
import PropTypes from 'prop-types'

export const CourseDetail1 = ({ modeBG }) => {

  const dataCourse = [
    {
      title: 'getting started',
      totalTime: '5m',
      couse: [
        {
          name: 'introduction',
          nameTime: '1:05',
          link: '/course/id',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
      ],
    },
    {
      title: 'next js started',
      totalTime: '5m',
      couse: [
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
        {
          name: 'introduction',
          nameTime: '1:05',
        },
      ],
    },
  ];
  return (
    <>
      <div className={modeBG === 'dark' ? 'flex min-h-full flex-col' : 'flex min-h-full flex-col'}>
        <div className='mx-auto flex flex-col-reverse lg:flex-row  w-full max-w-7xl items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8'>
          <aside className='w-72 xl:block flex flex-col'>
            {dataCourse?.map((x, i) => (
              <div key={i} className='mb-3 flex w-full h-auto justify-center  bg-gray-300 px-6 pb-4'>
                <div className='w-full'>
                  <div defaultValue>
                    <h3>
                      <div className='group flex w-full items-center justify-between rounded-t-sm pt-4 pb-2 text-left'>
                        <div className='flex flex-col'>
                          <span className='font-bold text-lg'>
                            {x.title} ({x.totalTime})
                          </span>
                          <span>7/7 complete</span>
                        </div>
                        <span className='pl-2'>
                          <p className='scale-150 group-aria-expanded:rotate-45 group-aria-expanded:transform'>
                            +
                          </p>
                        </span>
                      </div>
                    </h3>
                    <div>
                      {x.couse?.map((x, i) => (
                        <a  key={i} href={x.link} className='cursor-pointer'>
                          <div className='flex space-x-2  bg-gray-200 items-center px-3 py-1 mt-1'>
                            <div className='flex space-x-3 items-center'>
                              <div>
                                <svg
                                  className='-mr-0.5 h-5 w-5'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                  aria-hidden='true'
                                >
                                  <path
                                    fill-rule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                                    clip-rule='evenodd'
                                  />
                                </svg>
                              </div>
                              <div>
                                <div>{i}- {x.name}</div>
                                <div>{x.nameTime}</div>
                              </div>
                            </div>
                            <div>
                              <button
                                type='button'
                                className='bg-transparent rounded-md py-2 px-3 border-gray-400 border-2 text-sm font-bold text-purple-500 shadow-sm hover:bg-indigo-400 hover:border-transparent '
                              >
                                Review
                              </button>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </aside>

          <main className='flex-1 lg:sticky top-8'>
            <div>
              <div>
                <iframe
                  className='w-full aspect-video'
                  src='https://www.youtube.com/embed/mToe6D2fSOA?si=cSYn5PT9pHIW5Wbv'
                />
              </div>
              <div className='h-28'>
                <div className='text-white ps-4 font-bold mt-2 hover:text-purple-700'>
                  Download
                </div>

                <div className='flex justify-center mt-8'>
                  <button
                    type='button'
                    className='h-10 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  >
                    Complete and Continue
                    <svg
                      className='-mr-0.5 h-5 w-5'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                        clip-rule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </main>

          <aside className='w-52 shrink-0 hidden lg:block'>
            <div className='container h-36 flex flex-col justify-center items-center bg-gray-300 font-bold text-center space-y-4'>
              <span className='text-md font-bold '>45% COMPLETE</span>
              <div className='mx-auto w-11/12 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
                <div
                  className='bg-[#8244FF] h-2.5 rounded-full'
                  style={{ width: '45%' }}
                ></div>
              </div>
            </div>
            <div className='mt-6 bg-gray-300 p-6'>
              <div className='flex justify-center items-center space-x-5 pt-2'>
                <span className='relative inline-block'>
                  <img
                    className='h-16 w-16 rounded-full'
                    src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                    alt=''
                  />
                  <span className='absolute bottom-0 right-0 block h-4 w-4 rounded-full bg-green-400 ring-2 ring-white'></span>
                </span>
                <span className=' font-bold'>Mosh Hamedani</span>
              </div>
              <div className='pt-6 text-xs'>
                Hi! My name is Mosh Hamedani. I&apos;m a software engineer with two
                decades of experience. I&apos;ve taught millions of people how to code
                and how to become professional software engineers through my
                online courses and YouTube channel.
              </div>
              <div className='pt-4 text-xs'>
                I believe coding should be fun and accessible to everyone.
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};



CourseDetail1.propTypes = {
  modeBG : PropTypes.string
}