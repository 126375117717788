import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdCancel } from 'react-icons/md';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getCollectionFirebase } from '../../../Api/firebaseApi';
import ReactSelect from 'react-select';
// import { gray } from 'd3-color';

const NavigationComponent = ({ dataSecond, setNavigation, websiteStore }) => {
  const modalNewNavigation = useDisclosure();
  const [subCategories, setSubCategories] = useState([]);
  const [inputNavigation, setInputNavigation] = useState({
    name: '',
    type: '',
    link: '',
    subCategories: [],
  });
  const globalState = useUserStore();
  const toast = useToast();

  const handleModalNavigation = () => {
    modalNewNavigation.onOpen();
  };


  const handleGetSubCategories = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];
    try {
      const res = await getCollectionFirebase('product_categories', conditions);

      const selectedSubCategories = res.map((item) => ({
        value: item.title || '',
        label: item.title || '',
        categoriesId: item.categoriesId || '',
      }));
      setSubCategories(selectedSubCategories);
    } catch (error) {
      toast({
        title: 'Error Sub Categories',
        description: error,
        duration: 3000,
        status: 'error',
      });
      throw Error(error);
    }
  };

  console.log(subCategories, 'subcategories');

  const handleAddNavigation = () => {
    const currentNavigation = websiteStore?.menu || [];
    const newNavigationData = {
      name: inputNavigation.name,
      link: inputNavigation.link,
      type: inputNavigation.type,
      subCategories: inputNavigation.subCategories.map((subCategory) => ({
        name: subCategory.value.label || '', // Nama sub-kategori
        categoriesId: subCategory.value.categoriesId || '', // ID sub-kategori
        parent: subCategory.parent || '',
      })),
    };

    const existingIndex = currentNavigation.findIndex(
      (nav) => nav.name === newNavigationData.name
    );
    if (existingIndex !== -1) {
      currentNavigation[existingIndex].subCategories.push(
        ...newNavigationData.subCategories
      );
    } else {
      currentNavigation.push(newNavigationData);
    }

    websiteStore?.setMenu(currentNavigation);
    modalNewNavigation.onClose();

    setInputNavigation({
      name: '',
      type: '',
      link: '',
      subCategories: [],
    });
  };

  const handleRemoveNavigation = (value) => {
    if (!websiteStore?.menu) {
      toast({
        title: 'Navbar Data invalid',
        description: 'Something Wrong data',
        duration: 3000,
        status: 'error',
      });
      return;
    }

    const currentNavigation = [...websiteStore.menu];
    const indexData = currentNavigation.findIndex((x) => x.name === value.name);

    if (indexData !== -1) {
      currentNavigation.splice(indexData, 1);
      websiteStore.setMenu(currentNavigation);
    }
  };

  const handleAddSubCategory = () => {
    handleGetSubCategories();
    setInputNavigation((prevInput) => ({
      ...prevInput,
      subCategories: [...prevInput.subCategories, { value: '', parent: '' }],
    }));
  };

  const handleRemoveSubCategory = (index) => {
    setInputNavigation((prevInput) => ({
      ...prevInput,
      subCategories: prevInput.subCategories.filter((_, i) => i !== index),
    }));
  };

  const handleSubCategoryChange = (index, newValue) => {
    setInputNavigation((prevInput) => {
      const updatedSubCategories = [...prevInput.subCategories];
      updatedSubCategories[index].value = newValue; // Ubah properti value
      return {
        ...prevInput,
        subCategories: updatedSubCategories,
      };
    });
  };
  const handleSubParentsChange = (index, newValues) => {
    setInputNavigation((prevInput) => {
      const updatedSubCategories = [...prevInput.subCategories];
      updatedSubCategories[index].parent = newValues || '';
      return {
        ...prevInput,
        subCategories: updatedSubCategories,
      };
    });
  };

  return dataSecond.map((x, i) => (
    <Box key={i}>
      <Box p='2' m='1' shadow='base'>
        <Stack align={'center'} pb={2}>
          <Text fontWeight={'semibold'}>Navigation Link</Text>
          <Text fontSize={12} align={'center'}>
            Add or remove the navigation to be placed at navbar
          </Text>
        </Stack>
        <Flex flexWrap={'wrap'} gap={2} py={2}>
          {websiteStore?.menu?.map((x, i) => (
            <Box
              key={i}
              py='1'
              px={2}
              border={'1px'}
              borderRadius={'md'}
              shadow={'base'}
              w={'fit-content'}
              borderColor={'gray.50'}
              cursor={'pointer'}
              pos={'relative'}
            >
              <Box
                pos={'absolute'}
                top={-1}
                right={-1}
                onClick={() => handleRemoveNavigation(x)}
                cursor={'pointer'}
              >
                <MdCancel color={'red'} size={12} />
              </Box>
              <Text>{x.name}</Text>
            </Box>
          ))}
        </Flex>

        <Button
          my={3}
          size='sm'
          width='full'
          colorScheme='green'
          onClick={() => handleModalNavigation()}
        >
          Add Navigation
        </Button>
      </Box>
      <Box p='2' m='1' shadow='base'>
        <Box align={'center'} py={2} fontWeight={'semibold'}>
          <Text>Navigation Bar Templates</Text>
        </Box>
        {x.templates.map((y, j) => (
          <Box
            key={j}
            my='3'
            shadow='base'
            onClick={() => {
              setNavigation(y.file);
              websiteStore?.setNavigation(y?.id);
            }}
            cursor='pointer'
          >
            <Text textAlign='center' fontSize='sm'>
              {y.title}
            </Text>
            <Image src={y.image} />
          </Box>
        ))}
      </Box>

      <Modal
        onClose={modalNewNavigation.onClose}
        isOpen={modalNewNavigation.isOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Navigation</ModalHeader>
          <ModalBody>
            <Stack>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder='Enter navigation name'
                onChange={(e) =>
                  setInputNavigation({
                    ...inputNavigation,
                    name: e.target.value,
                  })
                }
              />
            </Stack>
            <Stack>
              <FormLabel>Type</FormLabel>
              <Select
                placeholder='Select navigation type'
                onChange={(e) =>
                  setInputNavigation({
                    ...inputNavigation,
                    type: e.target.value,
                  })
                }
              >
                <option value='category'>Category Navbar</option>
                <option value='navbar'>Navbar</option>
              </Select>
            </Stack>

            {inputNavigation.type === 'navbar' && (
              <Stack>
                <FormLabel>Link</FormLabel>
                <Input
                  placeholder='Enter navigation link'
                  onChange={(e) =>
                    setInputNavigation({
                      ...inputNavigation,
                      link: e.target.value,
                    })
                  }
                />
              </Stack>
            )}
            {inputNavigation.type === 'category' && (
              <>
                <Stack>
                  <FormLabel>Sub Categories</FormLabel>
                  {inputNavigation.subCategories.map((subCategory, index) => (
                    <HStack key={index} spacing={4} alignItems='center'>
                      <Box width='200px' bg='gray'>
                        <ReactSelect
                          placeholder={`Select Sub Category ${index + 1}`}
                          value={subCategory.name} // Memperbaiki pemanggilan value dari subCategory
                          onChange={(newValue) =>
                            handleSubCategoryChange(index, newValue)
                          }
                          options={subCategories}
                        />
                        <ReactSelect
                          placeholder={`Select parent ${index + 1}`}
                          value={subCategory.parent}
                          onChange={(newValue) =>
                            handleSubParentsChange(index, newValue)
                          }
                          options={subCategories}
                          isMulti // Menjadikan selectable multiple
                          closeMenuOnSelect={false} // Tetap membuka dropdown setelah pemilihan
                        />
                      </Box>
                      <Button onClick={() => handleRemoveSubCategory(index)}>
                        -
                      </Button>
                    </HStack>
                  ))}
                </Stack>
                <Button mt={2} size='sm' onClick={handleAddSubCategory}>
                  Add Sub Category
                </Button>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                colorScheme='red'
                size='sm'
                onClick={() => modalNewNavigation.onClose()}
              >
                Cancel
              </Button>
              <Button
                colorScheme='green'
                size='sm'
                onClick={handleAddNavigation}
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  ));
};

export default NavigationComponent;
