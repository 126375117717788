/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Table, Thead, Tbody, Tr, Th, Td, Image, SimpleGrid
} from '@chakra-ui/react';
import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCollectionFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import { clientTypessense } from '../../Api/Typesense';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import { FaRegCalendar, FaWhatsapp } from 'react-icons/fa';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DynamicTable from '../../Components/Table/DynamicTable';
import DatePicker from '../../Components/DatePicker/DatePicker';
import { collection, doc, getDocs, query, setDoc, where } from '@firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { formatFrice } from '../../Utils/Helper';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import ComponentExportExcel from '../../Utils/ExportExcel';


const ContactsPage = () => {
  const globalState = useUserStore();
  const itemsPerPage = 10; // Jumlah data per halaman

  const [modalContact, setModalContact] = useState(false);
  const [dataNew, setDataNew] = useState();
  const [dataSearchContact, setDataSearchContact] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [detail, setDetail] = useState('');

  const modalFilterDate = useDisclosure();
  const modalDetail = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const [selectedDateRange, setSelectedDateRange] = useState();
  const [contactList, setContactList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const customer = [
    {
      'id': 7248106586324,
      'email': 'dummy11@gmail.com',
      'created_at': '2024-04-25T14:29:58+07:00',
      'updated_at': '2024-04-25T14:31:06+07:00',
      'first_name': 'Fahria',
      'last_name': '',
      'orders_count': 1,
      'state': 'disabled',
      'total_spent': '756250.00',
      'last_order_id': 5557839003860,
      'note': null,
      'verified_email': true,
      'multipass_identifier': null,
      'tax_exempt': false,
      'tags': '',
      'last_order_name': '-398583',
      'currency': 'IDR',
      'phone': '+6281291080559',
      'addresses': [
        {
          'id': 8840174567636,
          'customer_id': 7248106586324,
          'first_name': 'Fahria',
          'last_name': '',
          'company': '',
          'address1': '',
          'address2': '',
          'city': 'Jakarta',
          'province': 'Jakarta',
          'country': 'Indonesia',
          'zip': '',
          'phone': '081291080559',
          'name': 'Fahria',
          'province_code': 'JK',
          'country_code': 'ID',
          'country_name': 'Indonesia',
          'default': true
        }
      ],
      'tax_exemptions': [],
      'email_marketing_consent': {
        'state': 'not_subscribed',
        'opt_in_level': 'single_opt_in',
        'consent_updated_at': null
      },
      'sms_marketing_consent': {
        'state': 'not_subscribed',
        'opt_in_level': 'single_opt_in',
        'consent_updated_at': null,
        'consent_collected_from': 'SHOPIFY'
      },
      'admin_graphql_api_id': 'gid://shopify/Customer/7248106586324',
      'default_address': {
        'id': 8840174567636,
        'customer_id': 7248106586324,
        'first_name': 'Fahria',
        'last_name': '',
        'company': '',
        'address1': '',
        'address2': '',
        'city': 'Jakarta',
        'province': 'Jakarta',
        'country': 'Indonesia',
        'zip': '',
        'phone': '081291080559',
        'name': 'Fahria',
        'province_code': 'JK',
        'country_code': 'ID',
        'country_name': 'Indonesia',
        'default': true
      }
    },
    {
      'id': 7248106324180,
      'email': 'dummy12@gmail.com',
      'created_at': '2024-04-25T14:29:29+07:00',
      'updated_at': '2024-04-25T14:30:17+07:00',
      'first_name': 'Eti ariati',
      'last_name': 'Aks',
      'orders_count': 1,
      'state': 'disabled',
      'total_spent': '890000.00',
      'last_order_id': 5557838479572,
      'note': null,
      'verified_email': true,
      'multipass_identifier': null,
      'tax_exempt': false,
      'tags': '',
      'last_order_name': '-398581',
      'currency': 'IDR',
      'phone': '+6287878465389',
      'addresses': [
        {
          'id': 8840174305492,
          'customer_id': 7248106324180,
          'first_name': 'Eti ariati ',
          'last_name': 'Aks',
          'company': '',
          'address1': '',
          'address2': '',
          'city': 'Lombok',
          'province': 'Nusa Tenggara Barat',
          'country': 'Indonesia',
          'zip': '',
          'phone': '087878465389',
          'name': 'Eti ariati  Aks',
          'province_code': 'NB',
          'country_code': 'ID',
          'country_name': 'Indonesia',
          'default': true
        }
      ],
      'tax_exemptions': [],
      'email_marketing_consent': {
        'state': 'not_subscribed',
        'opt_in_level': 'single_opt_in',
        'consent_updated_at': null
      },
      'sms_marketing_consent': {
        'state': 'not_subscribed',
        'opt_in_level': 'single_opt_in',
        'consent_updated_at': null,
        'consent_collected_from': 'SHOPIFY'
      },
      'admin_graphql_api_id': 'gid://shopify/Customer/7248106324180',
      'default_address': {
        'id': 8840174305492,
        'customer_id': 7248106324180,
        'first_name': 'Eti ariati ',
        'last_name': 'Aks',
        'company': '',
        'address1': '',
        'address2': '',
        'city': 'Lombok',
        'province': 'Nusa Tenggara Barat',
        'country': 'Indonesia',
        'zip': '',
        'phone': '087878465389',
        'name': 'Eti ariati  Aks',
        'province_code': 'NB',
        'country_code': 'ID',
        'country_name': 'Indonesia',
        'default': true
      }
    },
    // {
    //   'id': 7248102195412,
    //   'email': 'dummy5@gmail.com',
    //   'created_at': '2024-04-25T14:20:37+07:00',
    //   'updated_at': '2024-04-25T14:20:37+07:00',
    //   'first_name': null,
    //   'last_name': null,
    //   'orders_count': 0,
    //   'state': 'disabled',
    //   'total_spent': '0.00',
    //   'last_order_id': null,
    //   'note': null,
    //   'verified_email': true,
    //   'multipass_identifier': null,
    //   'tax_exempt': false,
    //   'tags': 'newsletter, prospect',
    //   'last_order_name': null,
    //   'currency': 'IDR',
    //   'phone': null,
    //   'addresses': [
    //     {
    //       'id': 8840170045652,
    //       'customer_id': 7248102195412,
    //       'first_name': null,
    //       'last_name': null,
    //       'company': null,
    //       'address1': null,
    //       'address2': null,
    //       'city': null,
    //       'province': null,
    //       'country': 'Indonesia',
    //       'zip': null,
    //       'phone': null,
    //       'name': '',
    //       'province_code': null,
    //       'country_code': 'ID',
    //       'country_name': 'Indonesia',
    //       'default': true
    //     }
    //   ],
    //   'tax_exemptions': [],
    //   'email_marketing_consent': {
    //     'state': 'subscribed',
    //     'opt_in_level': 'single_opt_in',
    //     'consent_updated_at': '2024-04-25T14:20:37+07:00'
    //   },
    //   'sms_marketing_consent': null,
    //   'admin_graphql_api_id': 'gid://shopify/Customer/7248102195412',
    //   'default_address': {
    //     'id': 8840170045652,
    //     'customer_id': 7248102195412,
    //     'first_name': null,
    //     'last_name': null,
    //     'company': null,
    //     'address1': null,
    //     'address2': null,
    //     'city': null,
    //     'province': null,
    //     'country': 'Indonesia',
    //     'zip': null,
    //     'phone': null,
    //     'name': '',
    //     'province_code': null,
    //     'country_code': 'ID',
    //     'country_name': 'Indonesia',
    //     'default': true
    //   }
    // },
  ]

  const user = () => {
    const fix = [];
    const stats = customer.map(async (x) => {
      const userCredential = await createUserWithEmailAndPassword(auth, x.email, x.email);
      const user = userCredential.user;

      // const docRef = await setDoc(doc(db, 'users', user.uid), {
      //   name: x.first_name + x.last_name,
      //   email: x.email,
      //   companyId: globalState.currentCompany,
      //   lastUpdated: new Date(),
      //   lastUpdatedBy: {
      //     email: globalState.email,
      //     uid: globalState.uid
      //   }
      // });
      const data = {
        name: x.first_name + x.last_name,
        email: x.email,
        companyId: globalState.currentCompany,
        lastUpdated: new Date(),
        lastUpdatedBy: {
          email: globalState.email,
          uid: globalState.uid
        }
      }
      const docRef = await setDocumentFirebase('users', user.uid, data)
      console.log('Sign up success:', user.uid);
      const dats = { ...x, 'uid': user.uid }
      fix.push(dats)
    })
    const userToCustomer = fix.map(async (y) => {
      const data = {
        name: y.first_name + y.last_name,
        email: y.email,
        point: 0,
        tags: '',
        phone_number: y.phone,
        uid: y.uid,
        column: '',
        address: {
          street: y.address?.[0].address1 || '',
          postal_code: y.address?.[0].zip || '',
          city: y.address?.[0].city || '',
          state: y.address?.[0].province || '',
          country: y.address?.[0].country || '',
        },
        average_order_value: 0,
        companyId: globalState.currentCompany,
        createdAt: new Date(),
        last_added_cart_timestamp: '',
        last_added_cart_value: 0,
        last_checkout: '',
        last_checkout_value: 0,
        last_transaction: '',
        last_transaction_amount: 0,
        order_ids: [],
        projectId: globalState.currentProject,
        total_transaction: 0
      }
      console.log(data, 'data')
      // const docRef = await setDoc(doc(db, 'customer', y.uid + '-' + globalState.currentProject), { data });
      const docRef = await setDocumentFirebase('customer', y.uid + '-' + globalState.currentProject, data)
    })
  }

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];

    // const sortBy = { field: 'createdAt', direction: 'desc' };
    const sortBy = null;
    const limitValue = startIndex + itemsPerPage;

    try {
      const customers = await getCollectionFirebase(
        'customer',
        conditions,
        sortBy,
        limitValue
      );
      setContactList(customers);
      console.log(customers, 'customers')
      setTotalPages(Math.ceil(1000 / itemsPerPage));
    } catch (error) {
      console.log(error, 'ini error');
    }
  };
  // const getFilteredData = async () => {
  //   // Parse tanggal menjadi objek Date
  //   const dateObject = moment(selectedDateRange.startDate);
  //   const dateEndObject = moment(selectedDateRange.endDate);

  //   // Ubah tanggal menjadi format yang diinginkan
  //   const formattedStartDate = (dateObject.format('MMMM DD, YYYY [at] h:mm:ss A [UTC]Z')).replace('UTC+07:00', 'UTC+7')
  //   const formattedEndDate = (dateEndObject.format('MMMM DD, YYYY [at] h:mm:ss A [UTC]Z')).replace('UTC+07:00', 'UTC+7')

  //   const startDateObject = moment(formattedStartDate, 'MMMM DD, YYYY [at] h:mm:ss A [UTC]Z');
  //   const endDateObject = moment(formattedEndDate, 'MMMM DD, YYYY [at] h:mm:ss A [UTC]Z');

  //   const startDateTimestamp = startDateObject.unix() * 1000; // Perkalian dengan 1000 untuk mendapatkan waktu dalam milidetik
  //   const endDateTimestamp = endDateObject.unix() * 1000;

  //   console.log(startDateTimestamp); // Timestamp untuk tanggal mulai
  //   console.log(endDateTimestamp); // Timestamp untuk tanggal akhir
  //   // console.log(typeof formattedStartDate, 'datdadetetetet')
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   const conditions = [
  //     { field: 'companyId', operator: '==', value: globalState.currentCompany },
  //     { field: 'projectId', operator: '==', value: globalState.currentProject },
  //     {
  //       field: 'createdAt',
  //       operator: '>=',
  //       value: startDateTimestamp,
  //     },
  //     { field: 'createdAt', operator: '<=', value: endDateTimestamp },
  //   ];

  //   const sortBy = { field: 'createdAt', direction: 'desc' };
  //   const limitValue = startIndex + itemsPerPage;

  //   try {
  //     const contacts = await getCollectionFirebase(
  //       'contacts',
  //       conditions,
  //       sortBy,
  //       limitValue
  //     );
  //     setContactList(contacts);

  //     const totalContacts = await getCollectionFirebase('contacts', conditions);
  //     setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
  //   } catch (error) {
  //     console.log(error, 'ini error');
  //   }
  // };

  const getFilteredData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
      {
        field: 'createdAt',
        operator: '>=',
        value: selectedDateRange.startDate,
      },
      { field: 'createdAt', operator: '<=', value: selectedDateRange.endDate },
    ];

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    try {
      const contacts = await getCollectionFirebase(
        'contacts',
        conditions,
        sortBy,
        limitValue
      );
      setContactList(contacts);

      const totalContacts = await getCollectionFirebase('contacts', conditions);
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      console.log(error, 'ini error');
    }
  };


  const handleTypesenseSearch = (q) => {
    const searchParameters = {
      q: q,
      query_by: 'name, email, phoneNumber',
      filter_by: `projectId: ${globalState.currentProject}`,
      sort_by: '_text_match:desc',
    };

    if (q) {
      clientTypessense
        .collections('customer')
        .documents()
        .search(searchParameters)
        .then((x) => {
          const newData = x.hits.map((y) => {
            return { ...y.document };
          });
          setDataSearchContact(newData);
          console.log(newData, 'ini data');
          setInputSearch(q);
        }).catch((error) => {
          console.log(error, 'errorrr')
        });
    } else {
      setDataSearchContact(contactList);
      setInputSearch(q);
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1); // Pindahkan ke halaman berikutnya saat tombol "Load More" diklik
  };

  useEffect(() => {
    if (!selectedDateRange) {
      getData();
    } else {
      getFilteredData();
    }
  }, [globalState.currentProject, currentPage, selectedDateRange]);

  const handleDetail = (contact) => {
    // navigate(`/relations/contacts/detail/${contact.id}`, { state: contact });
    setDetail(contact);
    modalDetail.onOpen();
  };

  const handleModalOpen = () => {
    setModalContact(true);
  };

  const handleModalClose = () => {
    setModalContact(false);
    setDataNew();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataNew((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeRadioButton = (value) => {
    console.log(value);
    setDataNew({ ...dataNew, contactType: value });
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleSaveContact = async () => {
    const collectionName = 'contacts';
    const docName = `${dataNew.phoneNumber}-${globalState?.currentProject}`;
    const data = {
      ...dataNew,
      projectId: globalState?.currentProject,
      createdAt: new Date(),
      lastUpdated: new Date(),
      companyId: globalState?.currentCompany,
    };

    setIsLoading(true);
    try {
      const result = await setDocumentFirebase(
        collectionName,
        docName,
        data,
        globalState?.currentCompany
      );
      console.log(result); // Pesan toast yang berhasil
      toast({
        title: 'Success',
        description: 'Success add new contact. ',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      handleModalClose();
      getData();
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  const searchProject = globalState.projects.find(
    (x) => x.id === globalState.currentProject
  );

  const searchCompanyName = globalState.companies.find(
    (x) => x.id === globalState.currentCompany
  );

  const tableHeader = [
    'name',
    // 'tags',
    'phone',
    'email',
    'ltv',
    'trx',
    'aov',
    'last activity',
    'last checkout',
    'last checkout value',
    'last added cart',
    'last added cart value',
  ];
  const tableHeader2 = [
    'name',
    // 'tags',
    'phone',
    'email',
    'ltv',
    'trx',
    'aov',
    'last checkout',
    'last transaction',
    'last added cart',
  ];

  let tableData = ''

  if (inputSearch === '') {
    tableData = contactList?.map((data, index) => {

      const modifiedPhoneNumber = data?.phoneNumber?.startsWith('08') || data?.phone?.startsWith('08') || data?.phome_number?.startsWith('08')
        ? '628' + data?.phoneNumber.slice(2) || data?.phone?.slice(2) || data?.phome_number?.slice(2)  // Replace "08" with "628"
        : data?.phoneNumber || data?.phone_number;

      // console.log(data, 'data')
      const name = data.name || '';
      const tags = data?.tags || 'No Tags'
      const phone = `+${modifiedPhoneNumber ? modifiedPhoneNumber : ''}` || '';
      const email = data.email || '';
      const ltv = data?.lifetime_value || 0
      const trx = data?.total_transaction || 0
      const aov = data?.average_order_value || 0
      const lastActivity = moment(data?.lastUpdated.seconds * 1000).format('LLL')

      return {
        data,
        name: name,
        tags: tags,
        phone: phone,
        email: email,
        ltv: ltv,
        trx: trx,
        aov: aov,
        'last activity': lastActivity,
        'last checkout': lastActivity,
        'last checkout value': data?.last_checkout_value || 0,
        'last added cart': data?.last_added_cart || '',
        'last added cart value': data?.last_added_cart_value || 0,
        'last transaction value': data?.last_transaction_value || 0,
        'last transaction': data?.last_transaction_amount || '',
      };
    });

  } else {
    tableData = dataSearchContact?.map((data, index) => {

      const modifiedPhoneNumber = data?.phoneNumber?.startsWith('08') || data?.phone?.startsWith('08') || data?.phome_number?.startsWith('08')
        ? '628' + data?.phoneNumber.slice(2) || data?.phone?.slice(2) || data?.phome_number?.slice(2)  // Replace "08" with "628"
        : data?.phoneNumber || data?.phone_number;
      const name = data.name || '';
      const tags = data?.tags || 'No Tags'
      const phone = `+${modifiedPhoneNumber ? modifiedPhoneNumber : ''}` || '';
      const email = data.email || '';
      const ltv = data?.lifetime_value || 0
      const trx = data?.total_transaction || 0
      const aov = data?.average_order_value || 0
      const lastActivity = moment(data?.lastUpdated.seconds * 1000).format('LLL')

      return {
        data,
        name: name,
        tags: tags,
        phone: phone,
        email: email,
        ltv: ltv,
        trx: trx,
        aov: aov,
        'last activity': lastActivity
      };
    });
  }

  const handleBulk = () => {
    navigate('/relations/contacts/bulk-contacs')
  }


  const handleRead = (row) => {
    // Tambahkan logika hapus sesuai kebutuhan Anda
    handleDetail(row.data)
  };

  const handleCustom = (row) => {

    window.open(`https://wa.me/${row.phone}?text=Halo,%20Saya%20dari%20tim%20${searchCompanyName.name}`)

  }
  console.log(contactList,'tabel')
  const arrayToString = (arr) => {
    if (!Array.isArray(arr)) {
      return '';
    }
    return arr.join(', ');
  };

  const age = (time) => {
    const date = new Date(time?.seconds * 1000); 
    const monthNames = [
      'Januari', 'Februari', 'Maret',
      'April', 'Mei', 'Juni', 'Juli',
      'Agustus', 'September', 'Oktober',
      'November', 'Desember'
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return formattedDate
  }
  const excelData = tableData.map((x) => {
    const string = {
      aov: x.aov || '',
      average_order_value: x?.data?.average_order_value || '',
      column: x?.data?.column || '',
      companyId: x?.data?.companyId || '',
      createdAt: age(x?.data?.createdAt) || '',
      email: x?.data?.email || '',
      first_transaction_order_id: x?.data?.first_transaction_order_id || '',
      id: x?.data?.id || '',
      lastUpdated: age(x?.data?.lastUpdated) || '',
      name: x?.data?.name || '',
      phoneNumber: x?.data?.phoneNumber || '',
      projectId: x?.data?.projectId || '',
      type: x?.data?.type || '',
      emails: x?.email || '',
      'last activity': x?.['last activity']  || '',
      'last added cart': x?.['last added cart'] || '',
      'last added cart value': x?.['last added cart value'] || '',
      'last checkout': x?.['last checkout'] || '',
      'last checkout value': x?.['last checkout value'] || '',
      'last transaction': x?.['last transaction'] || '',
      'last transaction value': x?.['last transaction value'] || '',
      ltv: x?.ltv || '',
      names: x?.name || '',
      phones: x?.phone || '',
      tags: x?.tags || '',
      trx: x?.trx || '',
    }
    return string
  })

  // console.log(tableData, 'tabel')

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={4}>

        <HStack gap={5}>
          <BackButtons />
          {/* <Button onClick={user}>user</Button> */}
          <Heading size={'md'} fontWeight="bold">
            Customers
          </Heading>
          <Spacer />
          <HStack >
            <Input

              w='40%'
              type="text"
              placeholder="Search Contact"
              bgColor="white"
              color="black"
              sx={inputStyles}
              fontSize="sm"
              onChange={(e) => handleTypesenseSearch(e.target.value)}
            />
            <Box>

              <Button
                onClick={modalFilterDate.onOpen}
                bgColor={'transparent'}
                // shadow="md"
                variant="outline"
                borderColor="#F05A28"
                color="#F05A28"
              >
                <HStack>
                  <FaRegCalendar />
                  <Text>Filter date</Text>
                </HStack>
              </Button>
            </Box>
            <Box >
              <DynamicButton title={'Add Contact'} action="create" onClick={handleModalOpen} />
            </Box>
            <Box>
              <DynamicButton title={'Bulk Contact'} action="create" onClick={handleBulk} />            
            </Box>
            <Box>
              <ComponentExportExcel fileName='Data Customers' excelData={excelData} />            
            </Box>
          </HStack>
        </HStack>


        {/* <DynamicTable
          header={tableHeader}
          data={tableData}
          onRead={handleRead}
          onCustom={handleCustom}
          action_color="green"
          action_icon={FaWhatsapp}
          size={'sm'}
          textSize={'xs'}
        /> */}

        <Box
          // bgColor="white"
          spacing={1}
          borderRadius="xl"
          p={3}
          m={[1, 1, 5]}
          shadow="md"
          overflowX={'auto'}
        >
          <Table variant="striped" colorScheme="gray" size={'sm'}>
            <Thead>
              <Tr>
                {tableHeader2?.map((column) => (
                  <Th key={column}>{column}</Th>
                ))}
                <Th>Action</Th>
              </Tr>
            </Thead>
            {tableData && tableData.length > 0 ? (
              <Tbody fontSize="sm" >
                {tableData?.map((row, rowIndex) => (
                  <Tr key={rowIndex}>
                    {/* {tableHeader?.map((column, columnIndex) => (
                      <Td
                        key={columnIndex}
                        sx={{
                          cursor: 'default',
                          color: 'inherit'
                        }}
                      >
                        <Text fontSize={'xs'}>{row[column]}</Text>
                      </Td>
                    ))} */}
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      {row?.name}
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      {row?.phone}
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      {row?.email}
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      {formatFrice(row?.ltv)}
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      {row?.trx}
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      {formatFrice(row?.aov)}
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      <Text>{formatFrice(row['last checkout value'])}</Text>
                      <Text fontSize={10} color='gray'>{row['last checkout'] ? moment(row['last checkout'])?.format('LLL') : 'no data'}</Text>
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      <Text>{formatFrice(row['last transaction value'])}</Text>
                      <Text fontSize={10} color='gray'>{moment(row['last transaction']?.seconds)?.format('LLL')}</Text>
                    </Td>
                    <Td
                      sx={{
                        cursor: 'default',
                        color: 'inherit'
                      }}
                    >
                      <Text>{formatFrice(row['last added cart value'])}</Text>
                      <Text fontSize={10} color='gray'>
                        {row['last added cart'] ? moment(row['last added cart']?.seconds)?.format('LLL') : 'no data'}
                      </Text>
                    </Td>
                    <Td>
                      <Stack flexDirection={'row'} spacing={2}>
                        {handleCustom && (
                          <DynamicButton variant={'solid'} color={'green'} icon={FaWhatsapp} action={'custom'} size="sm" onClick={() => handleCustom(row)} />
                        )}
                        {handleRead && (
                          <DynamicButton variant={'solid'} action={'read'} size="sm" onClick={() => handleRead(row)} />
                        )}
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            ) : (
              <Tbody>
                <Stack minH={'50vh'} alignItems="center" justifyContent={'center'}>
                  <Text fontWeight={500} color="gray.600">No data found</Text>
                </Stack>
              </Tbody>
            )}
          </Table>
        </Box>



        <Stack alignItems={'center'} justifyContent="center">
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Stack>

      </Stack >

      <Modal size={'xl'} isOpen={modalContact} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Grid templateColumns={{ base: '1fr', md: '1fr' }}>
                {/* <Stack py={2}>
                          <Text fontWeight={500}>Opportunity Details</Text>
                      </Stack> */}
                <Stack overflowY="scroll">
                  <Stack py={2}>
                    <Text fontWeight={500}>Contact</Text>
                  </Stack>

                  <Divider />
                  <Stack>
                    <Text>Name</Text>
                    <Input
                      name="name"
                      onChange={handleChange}
                      placeholder="name"
                    />
                  </Stack>

                  <Stack>
                    <Text>Email</Text>
                    <Input
                      name="email"
                      onChange={handleChange}
                      placeholder="email"
                    />
                  </Stack>

                  <Stack>
                    <Text>Phone</Text>
                    <Input
                      name="phoneNumber"
                      type="number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                    />
                  </Stack>

                  <Stack>
                    <Text>Contact Type</Text>
                    <HStack>
                      <RadioGroup
                        name="contactType"
                        onChange={handleChangeRadioButton}
                      >
                        <Stack direction="row" spacing={5}>
                          <Radio value={'leads'}>Lead</Radio>
                          <Radio value={'customer'}>Customer</Radio>
                        </Stack>
                      </RadioGroup>
                      {/* <Select
                        onChange={handleChange}
                        name="contactType"
                        variant="outline"
                        placeholder="Content type"
                        fontWeight="normal"
                        >
                        <option value={"leads"}>Lead</option>
                        <option value={"customer"}>customer</option>
                      </Select> */}
                    </HStack>
                  </Stack>

                  {/* <Stack>
                <Text>Tags</Text>
                <CreatableSelect
                  isClearable={true}
                  value={selectedTagsRef.current}
                  options={data?.category?.map((category) => ({ label: category, value: category })) || []}
                  isMulti
                  onChange={handleTagChange}
                />
              </Stack> */}
                </Stack>
              </Grid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme="blue"
              // variant={"outline"}
              size="sm"
              mr={3}
              onClick={handleSaveContact}
            >
              Save
            </Button>
            <Button
              colorScheme="red"
              // variant={"outline"}
              size="sm"
              mr={3}
              onClick={handleModalClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRangeChange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>Start Date: </Text>
                  <Text>
                    {moment(selectedDateRange?.startDate).format('LLL')}
                  </Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>End Date:</Text>
                  <Text>
                    {moment(selectedDateRange?.endDate).format('LLL')}
                  </Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={'xs'}
                colorScheme="blue"
                onClick={() => setSelectedDateRange()}
              >
                Clear Filter
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalDetail.isOpen}
        onClose={modalDetail.onClose}
        size={'4xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Customer Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={{ base: '1fr', md: '1fr' }}>
              {/* <Stack py={2}>
                        <Text fontWeight={500}>Opportunity Details</Text>
                    </Stack> */}
              <Stack h={'550px'} overflowY="scroll">
                <Stack py={2}>
                  <Text fontWeight={500}>Contact Details</Text>
                </Stack>

                <Divider />
                <SimpleGrid columns={[2]} gap={3} fontSize="sm">
                  <Stack>
                    <Text>Contact Name</Text>
                    <Text>{detail?.name}</Text>
                  </Stack>

                  <Stack>
                    <Text>Email</Text>
                    <Text>{detail?.email}</Text>
                  </Stack>

                  <Stack>
                    <Text>Phone</Text>
                    <Text>{detail?.phone || detail?.phone_number}</Text>
                  </Stack>

                  <Stack>
                    <Text>Uid</Text>
                    <Text>{detail?.uid}</Text>
                  </Stack>
                </SimpleGrid>

                <Divider pt={3} />

                <Stack>
                  <Text fontWeight={500}>Acquisition Retention Details</Text>
                </Stack>

                <Divider />

                <SimpleGrid columns={[2]} gap={3} fontSize="sm">
                  {/* <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Name</Text>
                    <Text color="gray">{detail?.name}</Text>
                  </Stack> */}

                  {/*  <Stack>
                <Text>Status</Text>
                <Select
                  defaultValue={data?.status}
                  variant="outline"
                  fontWeight="normal"
                  placeholder='select status'
                  ref={statusRef}
                >
                  <option value={'open'}>Open</option>
                  <option value={'won'}>Won</option>
                  <option value={'lost'}>Lost</option>
                </Select>
              </Stack> */}
                  {/* <Stack>
                <Text>Source</Text>
                <Input defaultValue={data?.source} ref={sourceRef} />
              </Stack> */}

                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">LTV</Text>
                    <Text color="gray">IDR {formatFrice(detail?.lifetime_value)}</Text>
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">AOV</Text>
                    <Text color="gray">IDR {formatFrice(detail?.average_order_value)}</Text>
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Last Checkout</Text>
                    <Text color="gray">{moment(detail?.last_checkout?.miliseconds)?.format('dddd, DD MMM YYYY HH:mm')}</Text>
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Last Checkout Value</Text>
                    <Text color="gray">IDR {formatFrice(detail?.last_checkout_value)}</Text>
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Total Transaction</Text>
                    <Text color="gray">{detail?.total_transaction || 0}</Text>
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Last Added Cart</Text>
                    <Text color="gray">{detail?.last_added_cart || '-'}</Text>
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Order Ids</Text>
                    <Text color="gray">{detail?.orderIds?.join(', ')}</Text>
                    {/* <Text>{JSON.stringify(detail)}</Text> */}
                  </Stack>
                  <Stack bg="gray.100" p={2} borderRadius={10}>
                    <Text fontWeight="bold">Last Transaction Amount</Text>
                    <Text color="gray">IDR{formatFrice(detail?.last_transaction_amount)}</Text>
                    {/* <Text>{JSON.stringify(data)}</Text> */}
                  </Stack>
                  {/* <Stack bg="gray.100" p={2} borderRadius={10}>
                <Text fontWeight="bold">Order Ids</Text>
                <Text color="gray">{JSON.stringify(data)}</Text>
              </Stack> */}
                </SimpleGrid>
              </Stack>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack >
  );
};

export default ContactsPage;
