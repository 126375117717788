import React,{ useState } from 'react'
import PropTypes from 'prop-types'

const ProductHighlight1 = ({header,data}) => {
  const [hoveredIndex,setHoveredIndex] = useState(null);


  return (
    <div className='mx-auto text-center p-16'>
      <p className='mb-10'>{header || 'What\'s New'}</p>
      <div className='flex justify-center gap-5 flex-wrap'>
        {data?.map((product,index) => (
          <div key={index}>
            <a href={product?.link || 'https://instagram.com'} target='_blank' rel='noreferrer'>
              <div className={'relative bg-cover w-48 h-72 transition-opacity'}
                style={{
                  backgroundImage: `url(${hoveredIndex === index ? product.image2 : product.image1})`,
                  transition: 'background-image 0.3s ease',
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {product?.status === 'soldOut' && (
                  <p className=' absolute bg-amber-800 px-3 py-1 text-white text-xs right-0'>Sold Out</p>
                )}
              </div>
            </a>

            <div className='text-left relative bg-cover w-48 h-72 transition-opacity'>
              <p>{product?.name}</p>
              <p className='text-xs'>IDR {product?.price}</p>
            </div>
          </div>
        ))}
      </div>
      <button className='bg-amber-800 hover:bg-amber-700 px-4 py-1 text-sm text-white mt-8'>View All</button>
    </div>
  )
}

export default ProductHighlight1

ProductHighlight1.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  bgColor: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object)
}