/* eslint-disable react/react-in-jsx-scope */
import AbandonedCartsPage from '../Pages/Orders/AbandonedCartsPage';
import PaymentConfirmations from '../Pages/Orders/OrdersDetail/PaymentConfirmations';
import OrdersHomePage from '../Pages/Orders/OrdersHomePage';
import OrdersPage from '../Pages/Orders/OrdersPage';
import KpiImport from '../Pages/Orders/testPage';
// import ComponentExportExcel from '../Pages/Orders/testPage2';



const OrdersRouter = [
  {
    path: '/order',
    element: <OrdersHomePage />,
  },
  {
    path: '/test',
    element: <KpiImport />,
  },

  {
    path: '/orders',
    element: <OrdersPage />,
  },

  {
    path: '/abandonedCart',
    element: <AbandonedCartsPage />,
  },
  {
    path: '/payment-confirmations',
    element: <PaymentConfirmations />,
  },
];

export default OrdersRouter;
