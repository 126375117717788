import React from 'react';
import PropTypes from 'prop-types';

export const Product4 = ({ data }) => {
  // const [hoveredIndex, setHoveredIndex] = useState();
  // const [filteredData, setFilteredData] = useState(data);

  // const handleFilterChange = (event, selectedFilter) => {
  //   const { checked } = event.target;

  //   if (checked) {
  //     const newData = data.filter((product) => product.tags && product.tags.includes(selectedFilter));
  //     setFilteredData(newData);
  //   } else {
  //     setFilteredData(data);
  //   }
  // };

  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
        {data?.length > 0 ? (
          <>
            <h2 className='text-2xl font-bold tracking-tight text-gray-900'>Customers also purchased</h2>
            <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8'>
              {data.map((product, index) => (
                <div
                  className='group relative'
                  key={index}>
                  <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80'>
                    <img
                      src={product.image1}
                      alt="Front of men's Basic Tee in black."
                      className='h-full w-full object-cover object-center lg:h-full lg:w-full'
                    />
                  </div>
                  <div className='mt-4 flex justify-between'>
                    <div>
                      <h3 className='text-sm text-gray-700'>
                        <a href='#'>
                          <span
                            aria-hidden='true'
                            className='absolute inset-0'></span>
                          {product.name}
                        </a>
                      </h3>
                      <p className='mt-1 text-sm text-gray-500'>{product.description}</p>
                    </div>
                    <p className='text-sm font-medium text-gray-900'>Rp. {product.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <h2 className='text-2xl font-bold tracking-tight text-gray-900'>Customers also purchased</h2>
            <div className='mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8'>
              <div className='group relative'>
                <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80'>
                  <img
                    src='https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg'
                    alt="Front of men's Basic Tee in black."
                    className='h-full w-full object-cover object-center lg:h-full lg:w-full'
                  />
                </div>
                <div className='mt-4 flex justify-between'>
                  <div>
                    <h3 className='text-sm text-gray-700'>
                      <a href='#'>
                        <span
                          aria-hidden='true'
                          className='absolute inset-0'></span>
                        Basic Tee
                      </a>
                    </h3>
                    <p className='mt-1 text-sm text-gray-500'>Black</p>
                  </div>
                  <p className='text-sm font-medium text-gray-900'>$35</p>
                </div>
              </div>
              <div className='group relative'>
                <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80'>
                  <img
                    src='https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg'
                    alt='Front of Woman Tee.'
                    className='h-full w-full object-cover object-center lg:h-full lg:w-full'
                  />
                </div>
                <div className='mt-4 flex justify-between'>
                  <div>
                    <h3 className='text-sm text-gray-700'>
                      <a href='#'>
                        <span
                          aria-hidden='true'
                          className='absolute inset-0'></span>
                        Artwork tee
                      </a>
                    </h3>
                    <p className='mt-1 text-sm text-gray-500'>White</p>
                  </div>
                  <p className='text-sm font-medium text-gray-900'>$45</p>
                </div>
              </div>
              <div className='group relative'>
                <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80'>
                  <img
                    src='https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-02.jpg'
                    alt="Front of men's Basic Tee in black."
                    className='h-full w-full object-cover object-center lg:h-full lg:w-full'
                  />
                </div>
                <div className='mt-4 flex justify-between'>
                  <div>
                    <h3 className='text-sm text-gray-700'>
                      <a href='#'>
                        <span
                          aria-hidden='true'
                          className='absolute inset-0'></span>
                        White Tee
                      </a>
                    </h3>
                    <p className='mt-1 text-sm text-gray-500'>White</p>
                  </div>
                  <p className='text-sm font-medium text-gray-900'>$35</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Product4.propTypes = {
  header: PropTypes.any,
  content: PropTypes.any,
  data: PropTypes.any,
  filter: PropTypes.any,
};
