import {
  Button,
  HStack,
  Input,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  SimpleGrid,
  Spacer,
  Box,
  useToast,
  Grid,
  Heading,
  Center,
  useDisclosure,
  VStack,
  Divider,
  Avatar,
} from '@chakra-ui/react';
import { FiFilter } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import useUserStore from '../../Hooks/Zustand/Store';

import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { auth, db } from '../../Config/firebase';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import CardAvatar from '../../Components/Card/CardAvatar';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import { FcLink } from 'react-icons/fc';
import useKanbanStore from '../../Hooks/Zustand/kanbanStore';
import AcquisitionDefaultPipeline from '../../Components/Pipeline/AcquisitionDefaultPipeline';
import moment from 'moment';
import { DeleteIcon } from '@chakra-ui/icons';



function AcquisitionPipelinePageV2() {


  const [data, setData] = useState([]);
  const [editor, setEditor] = useState([]);
  const [dataDelete, setDataDelete] = useState('');


  const toast = useToast();

  const navigate = useNavigate();

  const [modal, setModal] = useState({ delete: false, user: false });

  const globalState = useUserStore();

  const itemsPerPage = 8;

  const [filterOwner, setFilterOwner] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const { resetTaskData, resetFilterData } = useKanbanStore((state) => state);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteModal = useDisclosure();
  const [pipelineForm, setPipelineForm] = useState({
    title: '',
    stages: [],
    type: 'acquisition',
  });
  const [dataPipeline, setDataPipeline] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleAddNewStage = () => {
    setPipelineForm((prev) => ({
      ...prev,
      stages: [...prev.stages, { stageName: '', filter: true }],
    }));
  };

  const handleDeleteStage = (index) => {
    setPipelineForm((prev) => ({
      ...prev,
      stages: prev.stages.filter((_, i) => i !== index),
    }));
  };

  const handleFilterToggle = (index) => {
    setPipelineForm((prev) => {
      const newStages = prev.stages.map((stage, i) => {
        if (i === index) {
          return {
            ...stage,
            filter: !stage.filter,
          };
        } else if (i === (index - 1 + prev.stages.length) % prev.stages.length) {
          return {
            ...stage,
            filter: prev.stages[index].filter ? true : stage.filter,
          };
        } else {
          return stage;
        }
      });

      return { ...prev, stages: newStages };
    });
  };

  const handleStageNameChange = (index, value) => {
    setPipelineForm((prev) => {
      const newStages = [...prev.stages];
      newStages[index].stageName = value;
      return { ...prev, stages: newStages };
    });
  };



  const getDataAcquisition = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    let conditions = [
      { field: 'type', operator: '==', value: 'acquisition' },
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
      // { field: 'users', operator: 'array-contains', value: globalState.uid },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;

    if (filterOwner === true) {
      conditions = [
        { field: 'type', operator: '==', value: 'kanban' },
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
      ];
    }

    try {
      const res = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue,
      );
      setDataPipeline(res);

      const collectionRef = collection(db, 'files');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      let countQuery = query(collectionRef,
        where('companyId', '==', globalState.currentCompany),
        where('projectId', '==', globalState.currentProject),
        where('type', '==', 'acquisition'),
        where('users', 'array-contains', globalState.uid),
      );
      if (filterOwner === true) {
        countQuery = query(collectionRef,
          where('companyId', '==', globalState.currentCompany),
          // where('type', '==', 'acquisition')
        );
      }

      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;

      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const handleDeletePipeline = async () => {
    setIsLoading(true);
    try {
      const result = await deleteDocumentFirebase('files', dataDelete.id);


      // if (result === 'Dokumen berhasil dihapus.') {
      //   await updateDocumentFirebase('forms', decryptToken(dataDelete.formId[0]), { pipelineId: '' });

      // }

      toast({
        status: 'success',
        title: 'Deoapp CRM',
        description: 'Pipeline deleted',
        duration: 3000,
      });

      getDataAcquisition();

      deleteModal.onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDataAcquisition()

    return () => {
    }
  }, [globalState?.currentProject, currentPage, filterOwner])

  const handleOpenFilter = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: 'Alert!',
        description: 'You dont have access to filter this button / only owner.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };

  const handleNavigate = (x) => {
    navigate(`view/${x.id}`, { state: x });
    resetTaskData();
    resetFilterData();
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleDeleteModal = (value) => {
    deleteModal.onOpen();
    console.log(value,'value')
    setDataDelete(value);
  };

  const createNewKanban = () => {

    if (globalState?.currentCompany === undefined || globalState?.currentProject === undefined) {
      toast({
        title: 'Alert!',
        description: 'You must setup your company and project.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const data = {
      title: pipelineForm.title,
      type: pipelineForm.type,
      companyId: globalState?.currentCompany || globalState?.companies[0].id,
      projectId: globalState?.currentProject || globalState?.projects[0].id,
      lastUpdated: new Date(),
      stages: [
        {
          filter: true,
          stageName: 'leads'
        },
        {
          filter: true,
          stageName: 'fu 1'
        },
        {
          filter: true,
          stageName: 'fu 2'
        },
        {
          filter: true,
          stageName: 'done'
        },
      ],
      // users: [globalState.uid],
      // usersDisplay: [
      //   {
      //     id: auth?.currentUser?.uid,
      //     image: auth.currentUser?.photoURL,
      //     name: auth?.currentUser?.displayName,
      //     email: auth?.currentUser?.email,
      //   },
      // ],
    };

    addDocumentFirebase('files', data, globalState.currentCompany).then((x) => {
      navigate(`view/${x}`);
    });
  };

  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersDisplay?.map((x) => {
      return {
        name: x.name,
        email: x.email,
        id: x.id,
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };
  // console.log(dataPipeline, 'daa')

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={8}>
        <HStack m="2" flexDirection={['column', 'row', 'row']}>
          <BackButtons />
          <Heading size={'md'}>Acquisition</Heading>
          <Spacer />

          <DynamicButton
            title={'See all'}
            action={'read'}
            onClick={handleOpenFilter}
          />

          <DynamicButton
            title={'Add Acquisition'}
            action={'create'}
            onClick={onOpen}
          />
        </HStack>
        {dataPipeline?.length > 0 ? (
          <SimpleGrid columns={[1, 1, 1]} gap="3">
            <AcquisitionDefaultPipeline />
            <SimpleGrid columns={[1, 3, 5]} gap="3">        
              {dataPipeline?.map((x, i) => (
                <Stack key={i} 
                  borderWidth='1px'
                  p={3}
                  shadow={'md'}
                  rounded={5}
                  cursor='pointer'
                  _hover={{
                    bg: 'gray.100',
                    transform: 'scale(1.02)',
                    transition: '0.3s',
                    cursor: 'pointer',
                  }}>
                  <HStack>
                    <Spacer />
                    <DeleteIcon
                      onClick={() => handleDeleteModal(x)}
                      boxSize={3} />
                  </HStack>
                  <CardAvatar
                    data={x}
                    title={x.title}
                    globalState={globalState}
                    onNavigate={() => handleNavigate(x)}
                    userArray={x?.usersDisplay}
                    timeCreated={x?.lastUpdated?.seconds}
                    modalTeam={() => modalTeam(x)}
                    owner={x?.users}
                    // onDelete={() => console.log(x)}
                    Icon={FcLink}
                  />                     
                </Stack>
              ))}
            </SimpleGrid>
          </SimpleGrid>
        ) : (
          <AcquisitionDefaultPipeline />
          // <AlertArrayNull titleData={'Acquisition'} action="Add Acquisition" />
        )}

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Acquisition</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Stack spacing={5}>
              <Text>Pipeline name</Text>
              <Input placeholder='Pipeline Name' type="text" onChange={(e) => setPipelineForm({ ...pipelineForm, title: e.target.value })} />

              {/* {pipelineForm?.stages?.map((stage, index) => (
                <Grid
                  gap={4}
                  templateColumns={{ base: '2fr 1fr', md: '2fr 1fr' }}
                  key={index}>
                  <Stack spacing={2}>
                    <Text>Stage Name</Text>
                    <Input
                      size={'sm'}
                      w='100%'
                      placeholder='Name'
                      value={stage.stageName}
                      onChange={(e) => handleStageNameChange(index, e.target.value.toLowerCase())}
                    />
                  </Stack>

                  <Stack>
                    <Text>Actions</Text>

                    <HStack>
                      <IconButton
                        size='sm'
                        icon={<FiFilter />}
                        color={stage.filter ? 'blue' : 'gray'}
                        onClick={() => handleFilterToggle(index)}
                      />
                      <Button
                        colorScheme={'red'}
                        size='sm'
                        onClick={() => handleDeleteStage(index)}>
                        Delete
                      </Button>
                    </HStack>
                  </Stack>
                </Grid>
              ))} */}
              {/* <Button
                colorScheme={'blue'}
                variant='outline'
                size={'sm'}
                onClick={handleAddNewStage}>
                Add Stage
              </Button> */}
            </Stack>

          </ModalBody>

          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewKanban()}
              title="Save"
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => (
                  <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                    {x?.name === data?.usersDisplay[0]?.name ? (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <VStack align={'flex-start'}>
                            <Text fontWeight={'medium'} fontSize={'sm'}>
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </VStack>
                        </HStack>
                        <Stack>
                          <Box p={4}>
                            <Text fontStyle={'italic'} fontSize={'xs'}>
                              Creator
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <HStack gap={3}>
                          <Avatar size={'md'} name={x?.name} />
                          <Stack align={'flex-start'} spacing={0}>
                            <Text
                              fontWeight={500}
                              textTransform="capitalize"
                              fontSize={'sm'}
                            >
                              {x?.name}
                            </Text>
                            <Text fontSize={'xs'}>{x?.email}</Text>
                          </Stack>
                        </HStack>
                        <Stack>
                          {data?.users?.includes(globalState?.uid) && (
                            <Box p={4} w="15%">
                              <DynamicButton
                                action="delete"
                                size={'sm'}
                              // onClick={() => handleRemoveUser(x, data)}
                              />
                            </Box>
                          )}
                        </Stack>
                      </>
                    )}
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Text fontSize={'md'}>Delete Pipeline</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure to delete {dataDelete.title} Pipeline?</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme='blackAlpha'
              mr={3}
              onClick={deleteModal.onClose}>
              No
            </Button>
            <Button
              isLoading={isLoading}
              size={'sm'}
              colorScheme='twitter'
              mr={3}
              onClick={handleDeletePipeline}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Stack >
  );
}

export default AcquisitionPipelinePageV2;
