/* eslint-disable react/prop-types */
/* eslint-disable no-unsafe-optional-chaining */
import React, { Component, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Divider,
  Text,
  IconButton,
  Stack,
  useToast,
  Container,
  Select,
  Spacer,
  Center,
  Image,
  SimpleGrid,
  InputGroup,
  InputRightAddon,
  RadioGroup,
  Radio,
  FormHelperText,
  useColorModeValue, // Tambahkan import untuk Checkbox
} from '@chakra-ui/react';
import { MdArrowDropDown, MdDelete } from 'react-icons/md';
import {
  addDocumentFirebase,
  arrayRemoveFirebase,
  arrayUnionFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { formatFrice } from '../../Utils/numberUtil';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  useParams,
} from 'react-router-dom';
import CardFile from '../../Components/Accounting/CardFile';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { auth } from '../../Config/firebase';
import { AwsUploadFile } from '../../Utils/AwsUploadFile';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';


function VariantDetailPage() {
  const param = useParams()
  const productVariantId = param?.variantId
  const { id: productId, variantId } = param;
  // Variant State
  const [variantData, setVariantData] = useState({
    currency: 'IDR'
  });
  // const [name, setName] = useState('');
  // const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [fileName, setFileName] = useState('Select Digital File to Upload');
  const [imageName, setImageName] = useState('Select Image to Upload');
  const [files, setFiles] = useState([]); // Initialize with an empty array
  const [details, setDetails] = useState([]);
  const [productData, setProductData] = useState({});
  const [isTypeAlreadySet, setIsTypeAlreadySet] = useState(false);
  const globalState = useUserStore();
  const toast = useToast();
  // const [delivery, setDelivery] = useState({ height: '', length: '', length_unit: 'cm', weight: '', weight_unit: 'gr', width: '' })
  const editData = productVariantId
  const [isLoading, setIsLoading] = useState(false);
  const [inputValidations, setInputValidations] = useState({
    name: true,
    stocks: true,
    // sold: true,
    duration: true,
    description: true,
    currency: true,
    price: true,
    price_high: true,
    price_low: true,
    discount_type: true,
    discount_amount: true,
    variant_skuId: true,
    // externalVariantSKUId: true,
    product_skuId: true,
    type: true,
    // label: true,
    length: true,
    width: true,
    height: true,
    weight: true,
  });
  const [description, setDescription] = useState({})
  const [progress, setProgress] = useState(0)
  const [warehouses, setWarehouses] = useState([]);




  // console.log(details, 'ini detailnya')

  const getProject = () => {
    const res = globalState?.projects?.find(
      (e) => e.id === globalState?.currentProject
    );
    // setProjectId(res?.id);
    setProjectName(res?.name);
  };

  const getProductData = () => {
    const res = localStorage.getItem('product_data_on_edit');
    const parsed = JSON.parse(res)
    if (typeof parsed === 'object') setProductData(parsed);
    if (res.type && res.type !== '' && res.type !== undefined) setIsTypeAlreadySet(true);
  };

  const getVariantData = async () => {
    let resVariant;
    try {
      resVariant = await getSingleDocumentFirebase('product_variants', productVariantId);
      setVariantData(resVariant);
      console.log(resVariant, 'variant data')
      if (resVariant.details) {
        setDetails(resVariant.details);
      }
      if (resVariant.type === 'digital' || productData?.type === 'digital') {
        const resFiles = await getCollectionFirebase(`product_variants/${productVariantId}/files`);
        setFiles(resFiles);
      }
    } catch (error) {
      toast({
        status: 'error',
        description: error.message,
      })
    }

    const resWarehouses = await getCollectionFirebase('warehouses', [
      { field: 'companyId', operator: '==', value: globalState.currentCompany, },
      { field: 'projectId', operator: '==', value: globalState.currentProject, }]);
    console.log(resWarehouses, 'resWarehouses')
    setWarehouses(resWarehouses || []);
    setVariantData({ ...resVariant, warehouseId: resWarehouses[0]?.id || '' })
  };

  useEffect(() => {
    getProject();
    setTimeout(() => getVariantData(), 1000)
    getProductData();
  }, [productId, globalState.currentProject]);


  const handledelete = async (fileId) => {
    try {
      await deleteDocumentFirebase(`product_variants/${productVariantId}/files`, fileId);
      await getVariantData();
      toast({
        status: 'success',
        description: 'Delete success'
      })
    } catch (error) {
      toast({
        status: 'error',
        description: error.message,
        isClosable: true
      })
    }
  }

  const onSubmitVariant = async () => {

    const newData = {
      name: variantData.name || '',
      description: localStorage.getItem('quillContent') || '',

      companyId: globalState.currentCompany,
      createdBy: auth.currentUser.createdBy || '',
      currency: variantData.currency || 'IDR',
      variant_skuId: variantData.variant_skuId || '',
      // duration: productData.duration || '',
      external_productId: variantData.external_productId || '',
      externalVariantSKUId: variantData.externalVariantSKUId || '',
      external_variantId: variantData.external_variantId || '',
      external_variant_skuId: variantData.external_variant_skuId || '',
      price: variantData?.price || '',
      price_int: parseInt(variantData.price) || 0,
      price_low_int: parseInt(variantData.price_low) || 0,
      price_high_int: parseInt(variantData.price_high) || 0,
      price_high: variantData.price_high || '',
      price_low: variantData.price_low || '',
      projectId: variantData.projectId || '',
      productId: productId || '',
      sold : variantData?.sold || '0',
      sold_int : parseInt(variantData?.sold) || 0,
      // details: details?.map((detail) => ({
      //   key: detail.key.toLowerCase(),
      //   value: detail.value.toLowerCase(),
      // })),
      stocks: variantData.stocks || '0',
      stocks_int: parseInt(variantData.stocks) || 0,
      // delivery: delivery,
      type: productData?.type ? productData?.type : (variantData.type || ''),
      warehouseId: variantData?.warehouseId || '',
    };

    const requiredFields = [
      'name',
      'projectId',
      'stocks',
      // 'type',
      // 'currency',
      'price',
    ];

    const validations = {
      name: !!variantData.name,
      stocks: !!variantData.stocks,
      // duration: !!variantData.duration,
      // sold: !!variantData.sold,
      // description: !!localStorage.getItem('quillContent'),
      // currency: !!newData.currency,
      price: !!variantData.price,
      // externalVariantSKUId: !!variantData.externalVariantSKUId,
      // price_high: !!variantData.price_high,
      // price_low: !!variantData.price_low,
      variant_skuId: !!variantData.variant_skuId,
      discount_type: !!variantData.discount_type,
      // external_variantId: !!variantData.external_variantId,
      discount_amount: !!variantData.discount_amount,
      // external_productId: !!variantData.external_productId,
      product_skuId: !!variantData.product_skuId,
      external_product_skuId: !!variantData.external_product_skuId,
      type: !!variantData.type,
      // label: !!variantData.label,
    };

    if (productData?.type === 'physical' || variantData?.type === 'physical') {
      newData.width = variantData?.width || productData?.width || '0'
      newData.height = variantData?.height || productData?.height || '0'
      newData.length = variantData?.length || productData?.length || '0'
      newData.weight = variantData?.weight || productData?.weight || '0'

      requiredFields.push('length', 'width', 'height', 'weight');

      validations.length = !!variantData?.length || !!productData?.length;
      validations.width = !!variantData?.width || !!productData?.width;
      validations.height = !!variantData?.height || !!productData?.height;
      validations.weight = !!variantData?.weight || !!productData?.weight;

      setInputValidations(validations);

      if (newData.width < 0 || newData.height < 0 || newData.length < 0 || newData.weight < 0) {
        return toast({
          title: 'Deoapp.com',
          description: 'Dimensi Tidak boleh dibawah 0',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      }
    }

    if (productData?.type === 'digital' || variantData?.type === 'digital') {
      newData.duration = variantData?.duration || productData?.duration || ''

      requiredFields.push('duration');

      validations.duration = !!variantData?.duration || !!productData?.duration;

      setInputValidations(validations);
    }

    const emptyFields = requiredFields.filter((field) => !variantData[field]);

    if (emptyFields.length > 0) {
      const element = document.getElementById(emptyFields[0]);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      return toast({
        title: 'Deoapp.com',
        description: (`Field ${emptyFields.join(', ')} tidak boleh kosong`),
        status: 'error',
        position: 'top-right',
        isClosable: true,
      },);
      // throw new Error(`Field ${emptyFields.join(', ')} tidak boleh kosong`);
    }


    // return console.log(newData)

    try {
      setIsLoading(true)

      if (editData) {
        await updateDocumentFirebase('product_variants', editData, newData)
      } else {
        await addDocumentFirebase('product_variants', newData, globalState.currentCompany);
      }
      toast({
        status: 'success',
        duration: 3000,
        isClosable: true,
        description: 'Update variant details success'
      })

    } catch (error) {
      toast({
        title: 'Admin',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      setIsLoading(false);
    }
  };

  const handleAddDetail = () => {
    setDetails([...details, { key: '', value: '' }]);
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...details];
    updatedDetails.splice(index, 1);
    setDetails(updatedDetails);
  };

  const handleDetailChange = (index, key, value) => {
    const updatedDetails = [...details];
    updatedDetails[index] = { key, value };
    setDetails(updatedDetails);
  };


  const onSelectFile = async (e) => {
    if (!productData?.type && !variantData.type) return toast({
      status: 'error',
      message: 'Please select product type first, physical or digital!'
    })

    const file = e.target.files[0];
    const uploadData = {
      file,
      folder: 'folder/v1/xx'
    }
    try {
      setIsLoading(true);
      const result = await AwsUploadFile(uploadData);
      if (result.status === true) {
        await addDocumentFirebase(`product_variants/${productVariantId}/files`, {
          fileName: file?.name,
          fileType: file?.name.split('.').pop() || 'unknown_type',
          source: result?.data,
          sourceId: ''
        }, globalState?.currentCompany)
        toast({
          status: 'success',
          duration: 9000,
          title: 'Upload your image product success.',
          isClosable: true,
        });
        getVariantData();
      } else {
        toast({
          status: 'error',
          duration: 9000,
          title: 'Upload image failed',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false)
    }
  };

  const onSelectImage = async (e) => {
    const file = e.target.files[0];
    const uploadData = {
      file,
      folder: 'folder/v1/xx'
    };
    const result = await AwsUploadFile(uploadData);
    try {
      if (result.status === true) {
        await arrayUnionFirebase('product_variants', param.variantId, 'images', [result.data]);
        toast({
          status: 'success',
          duration: 9000,
          title: 'Upload your image product success.',
          isClosable: true,
        });
        getVariantData();
      } else {
        toast({
          status: 'error',
          duration: 9000,
          title: 'Upload image failed',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleDataInput = (key, value) => {
    const obj = { ...variantData };
    obj[key] = value;
    // console.log(obj, 'updated obj');
    setVariantData(obj)
  };

  const handleContentChange = (value) => {
    setDescription(value);
  };

  const MyEditor = ({ variantData }) => {
    const [content, setContent] = useState(variantData?.description || '');

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const doc = await getSingleDocumentFirebase('product_variants', productVariantId);
    //       if (doc) {
    //         const savedContent = doc.description;
    //         setContent(savedContent);
    //       }
    //     } catch (error) {
    //       console.error('Error getting document:', error);
    //     }
    //   };

    //   fetchData();
    // }, [productVariantId]);

    const handleChange = (html) => {
      setContent(html);
      localStorage.setItem('quillContent', html);
    };

    const onChange = (html) => {
      handleChange(html);
      // setVariantData((prevState) => ({
      //   ...prevState,
      //   description: html,
      // }));      
    };

    return (
      <div
        style={{
          backgroundColor: useColorModeValue('white'),
          overflow: 'hidden'
        }}>
        <ReactQuill
          style={{
            height: 300,
          }}
          value={content}
          onChange={onChange}
          isInvalid={!inputValidations?.description}
        />
      </div>
    );
  };


  const handleDeleteImage = async (x) => {
    try {
      const resultDelete = await arrayRemoveFirebase('product_variants', param.variantId, 'images', [x]);
      toast({
        status: 'success',
        description: resultDelete,
        duration: 3000
      })
      getVariantData();
    } catch (error) {
      toast({
        status: 'error',
        duration: 3000,
        description: error.message
      })
    }

  };


  useEffect(() => {
    async function updateVariantType() {
      if (productData?.type !== '' && productData && productData?.type !== variantData?.type) {
        try {
          await updateDocumentFirebase('product_variants', variantId, { type: productData?.type })
        } catch (error) {
          console.error(error.message, ':::error updating variant type')
        }
      }
    }

    updateVariantType();
  }, [productData, variantData])

  return (
    <Stack p={[1, 1, 5]}>
      <Stack>
        <BackButtons />
        <Text fontSize={'xl'} fontWeight={500}>
          Product Variant : <strong>{variantData?.name}</strong>
        </Text>
        <Text color='gray'>{variantData?.slug}</Text>
      </Stack>

      <Container
        gap={5}
        mt={0}
        maxW={'container.lg'}
        minH={'500px'}
        p={[1, 1, 5]}
        spacing={5}
        borderRadius="md"
        shadow={'md'}
        mb={2}
      >

        <VStack spacing={5} align={'left'} w="100%">
          <Stack >


            {productData?.type === 'digital' &&
              <>
                <Text fontWeight={'bold'}>Digital File: </Text>
                <CardFile
                  onChange={onSelectFile}
                  fileName={fileName}
                  setFileName={setFileName}
                />
                {files?.length > 0 && files?.map((file, i) => (
                  <Stack
                    alignItems={'center'}
                    justifyContent="center"
                    p={5}
                    key={i}
                    borderWidth={1}
                    borderColor={'gray'}
                    cursor={'pointer'}
                    _hover={{
                      transform: 'scale(1.1)'
                    }}
                    _active={{
                      transform: 'scale(1.05)'
                    }}
                  >
                    <HStack>
                      <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => handledelete(file?.id)} />
                      <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(file?.source, '_blank')} />
                    </HStack>
                    <Text>{file?.fileName}</Text>
                  </Stack>
                ))}

              </>
            }


            <Text fontWeight={'bold'}>Variant Product Image: </Text>
            <CardFile
              onChange={onSelectImage}
              fileName={imageName}
              setFileName={setImageName}
            />
            {/* {isLoading && <Text color='green'>Uploading....</Text>} */}
            {variantData?.images?.length > 0 && (

              <SimpleGrid h={'250px'} overflowY="scroll" py={3} spacing={4} columns={[2]}>
                {variantData?.images?.map((x, index) => {

                  return (
                    <Stack key={index} borderWidth={1} position="relative" alignItems="center" justifyContent={'center'} borderRadius="md" minH={'120px'}>
                      <HStack flexDirection={['column', 'row', 'row']} position={'absolute'} top={-2} right={-2} spacing={2}>
                        <DynamicButton action={'read'} size="sm" variant={'solid'} onClick={() => window.open(x, '_blank')} />
                        <DynamicButton action={'delete'} size="sm" variant={'solid'} onClick={() => handleDeleteImage(x)} />
                      </HStack>
                      <Stack overflow={'hidden'}>
                        <Image src={x} objectFit="contain" w={'200px'} borderRadius={'md'} shadow="md" />
                      </Stack>
                    </Stack>

                  )
                })}
              </SimpleGrid>
            )}
          </Stack>

          <Stack>
            <Spacer />
            <Text fontSize={'lg'} fontWeight={500}>Product Data</Text>
            <Divider />

          </Stack>

          <FormControl mt="5" id="Project" isRequired>
            <FormLabel>Project</FormLabel>
            <Input value={projectName} variant={'unstyled'} disabled />
          </FormControl>


          <HStack>
            <FormControl id="sku" isRequired>
              <FormLabel>Variant ID:</FormLabel>
              <Input
                type={'text'}
                value={variantId}
                variant={'unstyled'}
                disabled
              />
            </FormControl>
            <FormControl id="sku" isRequired>
              <FormLabel>Variant Name : </FormLabel>
              <Input
                type="text"
                w="100%"
                value={variantData?.name}
                id={'name'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                isInvalid={!inputValidations.name}
              />
            </FormControl>
          </HStack>

          <HStack>
            <FormControl id="sku" isRequired>
              <FormLabel>Variant SKU ID:</FormLabel>
              <Input
                type={'text'}
                value={variantData?.variant_skuId}
                defaultValue={variantData?.variant_skuId}
                id={'variant_skuId'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              // isInvalid={!inputValidations.variant_skuId}
              />
            </FormControl>
          </HStack>

          <HStack>
            <FormControl id="stock" isRequired>
              <FormLabel>Stock Product</FormLabel>
              <Input
                type="text"
                value={variantData?.stocks}
                defaultValue={variantData?.stocks}
                // onChange={(e) => setStocks(e.target.value)}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                id={'stocks'}
                isInvalid={!inputValidations.stocks}
              />
            </FormControl>

            <FormControl id="type" isRequired>
              <FormLabel>
                Type : {productData?.type}
              </FormLabel>
              <Select
                disabled={isTypeAlreadySet ? false : true}
                icon={<MdArrowDropDown />}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                id={'type'}
                isInvalid={!inputValidations.type}
              >
                <option
                  selected={
                    productData.type === 'physical'
                  }
                  value='physical'
                >
                  Physical
                </option>
                <option
                  selected={
                    productData.type === 'digital'
                  }
                  value='digital'
                >
                  Digital
                </option>
              </Select>
            </FormControl>
          </HStack>

          {(productData?.type === 'physical' || variantData?.type === 'physical') &&

            <DimensionForms
              variantData={variantData}
              productData={productData}
              handleDataInput={handleDataInput}
              inputValidations={inputValidations}
              setInputValidations={setInputValidations}
            />
          }
          {(productData?.type === 'digital' || variantData?.type === 'digital') &&

            // (variantData?.duration &&
            <DurationForm
              variantData={variantData}
              productData={productData}
              handleDataInput={handleDataInput}
              inputValidations={inputValidations}
              setInputValidations={setInputValidations}
            />
            // )
          }


          <FormControl id="description" isRequired>
            <FormLabel>Description:</FormLabel>
            {/* <ReactQuill defaultValue={this.state.content}  onChange={(e) => handleContentChange(e)} isInvalid={!inputValidations.description} /> */}
            <MyEditor variantData={variantData} />
          </FormControl>

          <Stack>
            <Spacer />
            <Text fontSize={'lg'} fontWeight={500}>Product Price</Text>
            <Divider />

          </Stack>

          <HStack>
            <FormControl id="currency" isRequired>
              <FormLabel>Currency:</FormLabel>
              <Select
                defaultValue={variantData?.currency}
                value={variantData?.currency}
                icon={<MdArrowDropDown />}
                // onChange={(e) => setCurrency(e.target.value)}
                id={'currency'}
                onChange={(e) => handleDataInput(e.target.id, e.target.value)}
              // isInvalid={!inputValidations.currency}
              >
                <option value=''>Select Currency</option>
                <option value='IDR'>IDR</option>
                <option value='US'>US</option>
              </Select>
            </FormControl>

            <FormControl id="price" isRequired>
              <FormLabel>Price</FormLabel>
              <HStack alignItems={'center'} justifyContent="center">
                <Text>Rp.</Text>
                <Input
                  w={'auto'}
                  type="number"
                  size={'sm'}
                  defaultValue={variantData?.price}
                  value={variantData?.price}
                  // onChange={(e) => setPrice(e.target.value)}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  id={'price'}
                  isInvalid={!inputValidations.price}
                />
                <Spacer />
                <Text fontWeight={500}>
                  Rp.{formatFrice(parseFloat(variantData?.price || 0))}
                </Text>
              </HStack>
            </FormControl>


          </HStack>


          <Stack w="100%"
            bg={'gray.100'}
            p={5}
            borderRadius={10}
            position='relative'>
            <Text color={useColorModeValue('gray', 'lightgray')}>Optional:</Text>
            <HStack
              gap="10"
            >
              <FormControl id="priceLow">
                <FormLabel color={useColorModeValue('gray', 'lightgray')}>Price Low</FormLabel>
                <HStack>
                  <Text color={useColorModeValue('gray', 'lightgray')} >Rp.</Text>
                  <Input
                    bg={useColorModeValue('white')}
                    size={'sm'}
                    w={'auto'}
                    type="number"
                    defaultValue={variantData?.price_low}
                    value={variantData?.price_low}
                    // onChange={(e) => setPriceLow(e.target.value)}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                    id={'price_low'}
                  // isInvalid={!inputValidations.price_low}
                  />
                  <Spacer />
                  <Text fontWeight={500} color={useColorModeValue('gray', 'lightgray')}>
                    Rp.{formatFrice(parseFloat(variantData?.price_low || 0))}
                  </Text>
                </HStack>
              </FormControl>



              <Center height="50px">
                <Divider
                  orientation="vertical"
                  fontWeight={'bold'}
                  color="black"
                />
              </Center>

              <FormControl id="priceHigh">
                <FormLabel color={useColorModeValue('gray', 'lightgray')}>Price High</FormLabel>
                <HStack alignItems={'center'} justifyContent="center">
                  <Text color={useColorModeValue('gray', 'lightgray')}>Rp.</Text>
                  <Input
                    bg={useColorModeValue('white')}
                    w={'auto'}
                    type="number"
                    size={'sm'}
                    defaultValue={variantData?.price_high}
                    value={variantData?.price_high}
                    // onChange={(e) => setPriceHigh(e.target.value)}
                    id={'price_high'}
                    onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  // isInvalid={!inputValidations.price_high}
                  />
                  <Spacer />
                  <Text color={useColorModeValue('gray', 'lightgray')} fontWeight={500}>
                    Rp.{formatFrice(parseFloat(variantData?.price_high || 0))}
                  </Text>
                </HStack>
              </FormControl>

            </HStack>
            <HStack
              gap="10"
            >
              <FormControl id="sku">
                <FormLabel color={useColorModeValue('gray', 'lightgray')} >External Product ID:</FormLabel>
                <Input
                  bg={useColorModeValue('white')}
                  type={'text'}
                  value={variantData?.external_productId}
                  defaultValue={variantData?.external_productId}
                  id={'external_productId'}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                // isInvalid={!inputValidations.external_productId}
                />
              </FormControl>
              <FormControl id="sku">
                <FormLabel color={useColorModeValue('gray', 'lightgray')} >External Product SKU ID:</FormLabel>
                <Input
                  bg={useColorModeValue('white')}
                  type={'text'}
                  id={'external_product_skuId'}
                  value={variantData?.external_product_skuId}
                  defaultValue={variantData?.external_product_skuId}
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                // isInvalid={!inputValidations.external_product_skuId}
                />
              </FormControl>
            </HStack>

          </Stack>


          <Stack>
            <Spacer />
            <Text fontSize={'lg'} fontWeight={500}>Product Details</Text>
            <Divider />
          </Stack>
          <Stack>
            {details?.length > 0 && (
              details?.map((detail, index) => (
                <HStack key={index} align={'center'} justify={'center'}>
                  <FormControl id={`detail-key-${index}`}>
                    <FormLabel>Key:</FormLabel>
                    <Input
                      type="text"
                      defaultValue={detail.key}
                      placeholder='Enter detail name (ex: Author)'
                      value={detail.key}
                      onChange={(e) =>
                        handleDetailChange(index, e.target.value, detail.value)
                      }
                    />
                  </FormControl>
                  <FormControl id={`detail-value-${index}`}>
                    <FormLabel>Value:</FormLabel>
                    <Input
                      type="text"
                      defaultValue={detail.value}
                      placeholder={'Enter detail name value (ex: John Doe)'}
                      value={detail.value}
                      onChange={(e) =>
                        handleDetailChange(index, detail.key, e.target.value)
                      }
                    />
                  </FormControl>
                  <Box pt="7">
                    <IconButton
                      icon={<MdDelete />}
                      aria-label="Remove Detail"
                      onClick={() => handleRemoveDetail(index)}
                    />
                  </Box>
                </HStack>
              ))
            )}
          </Stack>

          {productData?.type === 'physical' ?
            <>
              <Stack>
                <Spacer />
                <Text fontSize={'lg'} fontWeight={500}>Please Select Warehouse</Text>
                <Divider />
              </Stack>

              <FormControl isRequired>
                <FormLabel>Please select warehouse to store the product:</FormLabel>
                <Select
                  id='warehouseId'
                  onChange={(e) => handleDataInput(e.target.id, e.target.value)}
                  defaultValue={variantData?.warehouseId}
                >
                  <option value={''}>Select warehouse / gudang</option>
                  {warehouses?.map((warehouse, i) =>
                    <option key={i} value={warehouse.id}>{warehouse?.name}</option>
                  )}
                </Select>
                <FormHelperText>Ignore this field if product type is digital</FormHelperText>
              </FormControl>
            </> : <></>
          }

          <Button
            variant={'outline'}
            colorScheme="blue"
            onClick={handleAddDetail}
          >
            Add Detail
          </Button>
          <Button colorScheme="blue" onClick={onSubmitVariant}>
            Update Data Product Variant
          </Button>
        </VStack>
      </Container>
    </Stack>
  );
}


const DimensionForms = ({ variantData, productData, handleDataInput, inputValidations, setInputValidations }) => {
  return (
    <>
      <FormControl id="type" isRequired>
        <FormLabel>
          Shipping
        </FormLabel>
        <Select
          defaultValue={productData.shipping}
          value={productData.shipping}
          placeholder='Select courier : JNE / OExpress'
          icon={<MdArrowDropDown />}
          id={'shipping'}
          onChange={(e) => handleDataInput(e.target.id, e.target.value)}
          required
        >
          <option value='jne'>JNE</option>
          <option value='oExpress'>OExpress</option>
          <option value='rajaOngkir'>Raja Ongkir</option>
        </Select>
      </FormControl>
      <FormControl id="type" isRequired>
        <FormLabel>
          Dimension
        </FormLabel>
      </FormControl>
      <SimpleGrid columns={2}>
        <Image src={require('../../assets/image/box-dimensions.jpeg')} width={300} height={undefined} />
        <Stack>
          <FormControl id="type" isRequired>
            <FormLabel>
              Length
            </FormLabel>
            <HStack>
              <Input
                defaultValue={variantData?.length || productData?.length}
                id={'length'}
                onChange={(e) => {
                  handleDataInput(e.target.id, e.target.value);
                  setInputValidations((prevState) => ({
                    ...prevState,
                    length: !!e.target.value,
                  }));
                }}
                type={'number'}
                isInvalid={!inputValidations.length}
              />
              <Text fontWeight={'bold'}>CM</Text>
            </HStack>
          </FormControl>
          <FormControl id="type" isRequired>
            <FormLabel>
              Width
            </FormLabel>
            <HStack>
              <Input
                defaultValue={variantData?.width || productData?.width}
                id={'width'}
                onChange={(e) => {
                  handleDataInput(e.target.id, e.target.value);
                  setInputValidations((prevState) => ({
                    ...prevState,
                    width: !!e.target.value,
                  }));
                }}
                type={'number'}
                isInvalid={!inputValidations.width}
              />
              <Text fontWeight={'bold'}>CM</Text>
            </HStack>
          </FormControl>
          <FormControl id="type" isRequired>
            <FormLabel>
              Height
            </FormLabel>
            <HStack>
              <Input
                defaultValue={variantData?.height || productData?.height}
                id={'height'}
                onChange={(e) => {
                  handleDataInput(e.target.id, e.target.value);
                  setInputValidations((prevState) => ({
                    ...prevState,
                    height: !!e.target.value,
                  }));
                }}
                type={'number'}
                isInvalid={!inputValidations.height}
              />
              <Text fontWeight={'bold'}>CM</Text>
            </HStack>
          </FormControl>
          <FormControl id="type" isRequired>
            <FormLabel>
              Weight
            </FormLabel>
            <HStack>
              <Input
                defaultValue={variantData?.weight || productData?.weight}
                id={'weight'}
                onChange={(e) => {
                  handleDataInput(e.target.id, e.target.value);
                  setInputValidations((prevState) => ({
                    ...prevState,
                    weight: !!e.target.value,
                  }));
                }}
                type={'number'}
                isInvalid={!inputValidations.weight}
              />
              <Text fontWeight={'bold'}>KG</Text>
            </HStack>
          </FormControl>
        </Stack>
      </SimpleGrid>
    </>
  )

}

// class MyEditor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       content: '', // State untuk menyimpan konten dari React Quill
//     };
//     this.handleChange = this.handleChange.bind(this);
//   }

//   componentDidMount() {
//     getSingleDocumentFirebase('product_variants', '4ddbmAw72l6yMRhHaXSs').then((doc) => {
//       console.log(doc,'doccccc')
//       console.log(doc.exists, 'docococococ')
//       if (doc) {
//         console.log('first')
//         const savedContent = doc.description;
//         console.log(savedContent,'savedd') 
//         this.setState({ content: savedContent });
//       }
//     }).catch((error) => {
//       console.error('Error getting document:', error);
//     });
//   }

//   handleChange(html) {
//     // Simpan konten yang diubah ke dalam state dan penyimpanan lokal
//     this.setState({ content: html });
//     localStorage.setItem('quillContent', html);
//   }

//   render() {
//     const onChange = (html) => {
//       this.handleChange(html);
//     };

//     return (
//       <div>
//         <ReactQuill
//           value={this.state.content}
//           onChange={onChange}
//         />
//       </div>
//     );
//   }
// }


const DurationForm = ({ variantData, productData, handleDataInput, inputValidations, setInputValidations }) => {
  // const [value, setValue] = useState(!variantData?.duration ? '' : 'select');
  const [value, setValue] = useState();


  useEffect(() => {
    setTimeout(() => {
      setValue(variantData?.duration == 'unlimited' ? 'unlimited' :
        !isNaN(parseInt(variantData?.duration)) ? 'select' :
          ''
      )
    }, 1000)
  }, [])

  return (
    <>
      <FormControl id="duration" isRequired>
        <Stack direction='row'>
          <FormLabel>Duration</FormLabel>
          <RadioGroup value={value} onChange={setValue}>
            <Stack direction='row'>
              <Radio value='select'>Select Duration</Radio>
              <Radio
                value='unlimited'
                onChange={() => {
                  handleDataInput('duration', 'unlimited');
                  setInputValidations((prevState) => ({
                    ...prevState,
                    duration: true,
                  }));
                }}
              >
                Unlimited
              </Radio>
            </Stack>
          </RadioGroup>
        </Stack>
        {value !== 'unlimited'?
          <InputGroup>
            <Input
              type="number"
              defaultValue={variantData?.duration || productData?.duration}
              id={'duration'}
              onChange={(e) => {
                handleDataInput(e.target.id, e.target.value);
                setInputValidations((prevState) => ({
                  ...prevState,
                  duration: !!e.target.value,
                }));
              }}
              isInvalid={!inputValidations.duration}
            />
            <InputRightAddon>
              Month
            </InputRightAddon>
          </InputGroup>
          :
          <></>
        }

      </FormControl>
    </>
  )
}

// Validasi prop-types untuk DimensionForms
DimensionForms.propTypes = {
  variantData: PropTypes.object,
  productData: PropTypes.object,
  handleDataInput: PropTypes.func,
  inputValidations: PropTypes.object,
  setInputValidations: PropTypes.func,
};

// Validasi prop-types untuk DurationForm
DurationForm.propTypes = {
  variantData: PropTypes.object,
  productData: PropTypes.object,
  handleDataInput: PropTypes.func,
  inputValidations: PropTypes.object,
  setInputValidations: PropTypes.func,
};

DimensionForms.propTypes = {
  variantData: PropTypes.object,
  productData: PropTypes.object,
  handleDataInput: PropTypes.func
};


export default VariantDetailPage;