import { Button, HStack, Heading, Image, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, UnorderedList } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DynamicTable from '../../Components/Table/DynamicTable'
import moment from 'moment'
import BackButtons from '../../Components/Buttons/BackButtons'
import useUserStore from '../../Hooks/Zustand/Store'
import { getCollectionFirebase } from '../../Api/firebaseApi'
import { FaEdit, FaWhatsapp } from 'react-icons/fa'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import { count } from '@firebase/firestore'
import { useDisclosure } from '@chakra-ui/react';

function AbandonedCartsPage() {

  const itemsPerPage = 10; // Jumlah data per halaman
  const AbandonedDetail = useDisclosure();
  const [contactList, setCotactList] = useState([])
  const [modalData, setModalData] = useState();
  const globalState = useUserStore();
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {

    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'projectId', operator: '==', value: globalState.currentProject },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;


    try {
      const res = await getCollectionFirebase(
        'product_carts',
        conditions,
        sortBy,
        limitValue
      );
      console.log(res, 'ini res')
      setCotactList(res)
    } catch (error) {
      console.log(error, 'ini error')
    }
  }

  useEffect(() => {

    getData()

    return () => {

    }
  }, [])

  const tableHeader1 = ['name', 'variant', 'quantity', 'price', 'total price'];
  const tableHeader = ['name', 'quantity', 'value', 'Tanggal Penyimpanan', 'action'];
  console.log(contactList, 'contact')
  const tableData = contactList.map((data, index) => {

    const modifiedPhoneNumber = data?.phoneNumber?.startsWith('08')
      ? '628' + data?.phoneNumber.slice(2) // Replace "08" with "628"
      : data?.phoneNumber;
    // Menentukan total harga
    let totalPrice = 0;

    // Pastikan data adalah array sebelum menggunakan forEach
    if (Array.isArray(data.carts)) {
      data.carts.forEach((cartItem) => {
        const qty = parseInt(cartItem.quantity);
        const count = parseInt(cartItem.productData.price);
        const calculate = qty * count;
        totalPrice += calculate;
        // console.log(totalPrice, 'index');
      });
    }
    let totalQty = 0;

    if (Array.isArray(data.carts)) {
      data.carts.forEach((cartItem) => {
        const qty = parseInt(cartItem.quantity);
        totalQty += qty;
        // console.log(totalPrice, 'index');
      });
    }
    const name = data.userId || '';
    const phone = `+${modifiedPhoneNumber ? modifiedPhoneNumber : ''}` || '';
    const email = data.email || '';
    const lastActivity = moment(data?.lastUpdated.seconds * 1000).format('LLL')

    return {
      data,
      totalQuantity: totalQty,
      totalPrice: totalPrice,
      id: index + 1, // Add 1 to create a unique ID
      name: name,
      phone: phone,
      email: email,
      'Tanggal Penyimpanan': lastActivity
    };
  });

  const handleRead = (row) => {
    // Tambahkan logika hapus sesuai kebutuhan Anda
    console.log(row)
  };

  const handleCustom = (row) => {
    console.log(row)
  }

  const handleColumnClick = (columnName, rowData) => {

    console.log({ columnName, rowData });

    if (columnName === 'name') {
      // Contoh: Jika kolom 'Name' diklik, tampilkan detail nama.
      // Gantilah dengan perilaku yang sesuai untuk menampilkan detail nama.
      console.log(`Detail Name: ${rowData.name}`);
    } else if (columnName === 'email') {
      // Contoh: Jika kolom 'Email' diklik, navigasikan ke halaman tertentu.
      // Anda harus menggantinya dengan navigasi yang sesuai sesuai dengan routing yang Anda gunakan.
      console.log(`/email/${rowData.email}`);
    }
  };

  // let totalPrice = 0;


  // tableData?.forEach((datas) => {
  //   datas.data.carts?.forEach((value) => {
  //     const qty = parseInt(value.quantity);
  //     const count = parseInt(value.productData.price);
  //     const calculate = qty * count;
  //     totalPrice += calculate;
  //     console.log(totalPrice, 'index');
  //   });
  // });

  // console.log('Total harga:', totalPrice);
  const handleEditClick = (row) => {
    console.log('Edit button clicked:', row);
    setModalData(row); // Misalnya, setModalData adalah fungsi untuk menetapkan data ke dalam state modal
    AbandonedDetail.onOpen(); // Buka modal setelah menetapkan data
  };

  console.log(tableData)
  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack spacing={3}>
        <BackButtons />
        <Heading size='md'>Abandoned Cart</Heading>
      </HStack>
      <Table variant="striped" colorScheme="gray" size={'md'}>
        <Thead>
          <Tr>
            {tableHeader?.map((column) => (
              <Th key={column}>{column}</Th>
            ))}
          </Tr>
        </Thead>
        {tableData && tableData.length > 0 ? (
          <Tbody fontSize="sm">
            {tableData.map((x, index) => (
              <Tr key={index}>
                <Td>{x.name}</Td>
                <Td>{x.totalQuantity}</Td>
                <Td>{x.totalPrice.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</Td>
                <Td>{x['Tanggal Penyimpanan']}</Td>
                <Td>
                  <Stack flexDirection={'row'} spacing={2}>
                    <DynamicButton variant={'solid'} color={'green'} icon={FaWhatsapp} action={'custom'} size="sm" onColumnClick={handleColumnClick} />
                    <DynamicButton variant={'solid'} color={'yellow'} icon={FaEdit} action={'custom'} size="sm" onClick={() => handleEditClick(x)} />
                  </Stack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        ) : (
          <Tbody>
            <Tr>
              <Td colSpan={5}>
                <Stack minH={'50vh'} alignItems="center" justifyContent={'center'}>
                  <Text fontWeight={500} color="gray.600">No data found</Text>
                </Stack>
              </Td>
            </Tr>
          </Tbody>
        )}
      </Table>
      {/* <DynamicTable
        header={tableHeader}
        data={tableData}
        action_color="green"
        action_icon={FaWhatsapp}
        onCustom={handleCustom}
        onRead={handleRead}
        clickableColumns={['email']}
        onColumnClick={handleColumnClick}
      /> */}
      <Modal isOpen={AbandonedDetail.isOpen} onClose={AbandonedDetail.onClose} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Abandoned Cart Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="striped" colorScheme="gray" size="md" borderWidth="1px" borderRadius="md" overflow="hidden">
              <Thead>
                <Tr>
                  {tableHeader1?.map((column) => (
                    <Th key={column}>{column}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody fontSize="sm">
                {modalData?.data?.carts?.map((y, index) => (
                  <Tr key={index}>
                    <Td>{y.productData.name}</Td>
                    <Td>{y.variantData.name}</Td>
                    <Td>{y.quantity}</Td>
                    <Td>
                      {new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(y.productData.price)}
                    </Td>
                    <Td>{(y.quantity*y.productData.price).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Stack mt={4} direction="row" justifyContent="space-between" alignItems="center">
              <Text fontSize="lg">Total:</Text>
              <Text fontSize="lg" fontWeight="bold">
                {modalData?.totalPrice.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={AbandonedDetail.onClose}>Close</Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Stack>
  )
}

export default AbandonedCartsPage