/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Badge,
  Button,
  Stack,
  HStack,
  Input,
  Flex,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Heading,
  Image,
  VStack,
} from '@chakra-ui/react';
import { formatFrice } from '../../Utils/numberUtil';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import moment from 'moment';
import DynamicButton from '../Buttons/DynamicButton';
import CardFile from '../../Components/Accounting/CardFile';
import { AwsUploadFile } from '../../Utils/AwsUploadFile';


const ModalDetailOrder = ({ isOpen, onClose, detailOrder, getDataOrders}) => {
  const toast = useToast();
  const [updatedOrder, setUpdatedOrder] = useState({});
  const [fileName, setFileName] = useState('Masukkan bukti order');
  const [imageLink, setImageLink] = useState('');



  const handleSaveUpdate = async () => {
    console.log(updatedOrder);

    try {
      const updatedDetailOrder = {
        ...updatedOrder,
        items: (detailOrder?.items || []).map((item) => ({
          ...item,
          message: item.message || '',
          // shippingStatus: item.shippingStatus || '',
        })),
      };
      if (imageLink) {
        updatedDetailOrder.transferReceipt = imageLink || ''
      }

      console.log(updatedDetailOrder, 'updatedDetailOrder');
      await updateDocumentFirebase(
        'orders',
        detailOrder.id,
        updatedDetailOrder
      );

      toast({
        title: 'Order Updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onClose();
      getDataOrders();
    } catch (error) {
      console.error('Firestore Update Error:', error);
      toast({
        title: 'Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSelectFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file?.name)
    const uploadData = {
      file,
      folder: 'folder/v1/xx'
    }
    try {
      const result = await AwsUploadFile(uploadData);
      setImageLink(result.data);
      // console.log('url bukti transfer:', result.data);
      // if (result.status === true) {
      //   toast({
      //     status: 'success',
      //     duration: 9000,
      //     title: 'Upload your image product success.',
      //     isClosable: true,
      //   });
      // } else {
      //   toast({
      //     status: 'error',
      //     duration: 9000,
      //     title: 'Upload image failed',
      //     isClosable: true,
      //   });
      // }

    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      },);
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack>
            <Heading size='md'>Detail Order</Heading>
            <Text fontSize={12} fontWeight={500} color={'gray.500'}> #{detailOrder.id}</Text>
          </Stack>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={1}>
            <Flex justifyContent='flex-end'>
              <Text color='gray.500'>
                {moment(detailOrder?.createdAt?.seconds * 1000).format('LLL')}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'} >
              <Text color='gray.500'>User Id:</Text>
              <Text fontWeight={'semibold'}>{detailOrder?.userId}</Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'} >
              <Text color='gray.500'>Name:</Text>
              <Text
                size={'md'}
                textTransform={'capitalize'}
                fontWeight={'semibold'}
              >
                {detailOrder?.contact_information?.name}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Email:</Text>
              <Text textTransform={'lowercase'} fontWeight={'semibold'}>
                {detailOrder?.contact_information?.email}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Phone Number:</Text>
              <Text fontWeight={'semibold'}>
                {detailOrder?.contact_information?.phone_number}
              </Text>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text color='gray.500'>Address:</Text>
              <Text fontWeight='semibold' ml={'60px'}>
                {detailOrder?.contact_information?.address?.city} -{' '}
                {detailOrder?.contact_information?.address?.state}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Last Updated:</Text>
              <Text fontWeight={'semibold'}>
                {moment(detailOrder?.lastUpdated?.seconds * 1000).format('LLL')}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Payment Status:</Text>
              <Badge
                colorScheme={
                  detailOrder?.paymentStatus === 'PAID' ? 'green' : 'orange'
                }
              >
                {detailOrder?.paymentStatus
                  ? detailOrder?.paymentStatus
                  : 'No Data'}
              </Badge>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Payment Method:</Text>
              <Badge colorScheme={'green'}>
                {' '}
                {detailOrder?.payment_information?.payment_type}
              </Badge>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500' fontWeight={'bold'}>MARK AS PAID:</Text>
              <Select
                maxW={300}
                onChange={(e) => {
                  setUpdatedOrder({
                    ...updatedOrder,
                    paymentStatus: e.target.value,
                  });
                }}
              >
                <option value={''}>Select paid / unpaid</option>
                <option value={'PAID'}>MARK AS PAID</option>
                <option value={'UNPAID'}>MARK AS UNPAID</option>
              </Select>
            </Flex>
            {/* <Fileup */}

            {updatedOrder?.paymentStatus === 'PAID' &&
            (!imageLink ? 
              <CardFile
                onChange={onSelectFile}
                fileName={fileName}
                setFileName={setFileName}
              /> : 
              <VStack>
                <Image alt={'bukti bayar'} src={imageLink} w={200} />
              </VStack>
            )
            }
          </Stack>

          {/* <Divider my={4} /> */}
          <Stack my={4} />

          <Stack my={4} />
          <Text fontWeight={'semibold'}>Items in order</Text>
          <OrderWithShipping shipping_ids={detailOrder?.shipping_ids || []} detailOrder={detailOrder} />
          <Stack spacing={1}>

            <Flex justifyContent='space-between' my={4}>
              <Text fontWeight={'semibold'}>Total</Text>
              <Text fontWeight={'semibold'}>Rp. {formatFrice(detailOrder?.amount)}</Text>
            </Flex>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={onClose} colorScheme='red' size={'sm'}>
              Close
            </Button>
            <DynamicButton
              action={'create'}
              title={'Save'}
              size={'sm'}
              variant={'solid'}
              onClick={handleSaveUpdate}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDetailOrder;



function OrderWithShipping(props) {
  const [shippingsData, setShippingsData] = useState([]);
  const getData = async () => {
    try {
      const promises = await Promise.all(props?.shipping_ids?.map((shpId) => {
        const shpData = getSingleDocumentFirebase('shipping', shpId);
        return shpData;
      }));
      if (promises?.length > 0) setShippingsData(promises);
    } catch (error) {
      console.error(error.message + '::error getting shipping detail')
    }
  };
  useEffect(() => {
    getData()
  }, []);
  return (<>
    <Table overflowX={'auto'}>
      <Thead>
        <Tr textAlign={'center'}>
          <Th>Image</Th>
          <Th>Product Name</Th>
          <Th>Quantity</Th>
          <Th>Price</Th>
          {props?.detailOrder?.items?.length > 0 && props?.detailOrder?.items[0]?.type === 'physial' ?
            <Th>Shipping</Th>
            :
            <></>
          }
        </Tr>
      </Thead>

      <Tbody>
        {props?.detailOrder && props?.detailOrder?.items && props?.detailOrder?.items.map((product, index) => {
          const shippingDataHasItem = shippingsData.find((shipping) =>
            shipping?.items?.some((item) => item?.variantId === product?.variantId)
          )
          return (
            <Tr key={index}>
              <Td>
                <Image src={product?.images[0]} maxW={100} />
              </Td>
              <Td>
                <Text >{product?.name}</Text>
                <Text color={'gray'} fontSize={'sm'}>Note:</Text>
                <Input
                  defaultValue={product?.message}
                />
              </Td>
              <Td>
                <Text fontSize={'sm'} textAlign={'center'}>
                  {product?.quantity || ''}
                </Text>
              </Td>
              <Td>
                <Text fontSize={'sm'}>
                  Rp. {formatFrice(parseInt(product?.price))}
                </Text>
              </Td>
              {/* {detailOrder.shipping_ids ?
                      <Td>
                        <Select
                          placeholder='Select Status'
                          defaultValue={
                            product?.shippingStatus || ''
                          }
                          onChange={(e) => {
                            const newItems = [...updatedOrder.items];
                            newItems[index] = {
                              ...newItems[index],
                              shippingStatus: e.target.value,
                            };
                            setUpdatedOrder({
                              ...updatedOrder,
                              items: newItems,
                            });
                          }}
                        >
                          <option value='packed'>Packed</option>
                          <option value='shipped'>Shipped</option>
                          <option value='done'>Done</option>
                        </Select>
                      </Td> :
                      <></>} */}
              {props?.detailOrder?.items?.length > 0 && props?.detailOrder?.items[0]?.type === 'physial' ?
                <Td>
                  <Text>
                    Resi :{shippingDataHasItem?.resi || ' - '}
                  </Text>
                  <Text>Service :{shippingDataHasItem?.service} </Text>
                  <Text>Status :</Text>
                  <Badge
                    color={
                      shippingDataHasItem?.status === 'pending' ?
                        'red' :
                        'teal'
                    }
                  >
                    {shippingDataHasItem?.status}
                  </Badge>
                </Td>
                :
                <></>
              }
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  </>)
} 
