import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  SimpleGrid,
  Spacer,
  useDisclosure,
  Stack,
  useToast,
  Divider,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import CardMyKanban from '../../../Components/Card/CardMyKanban';
import { useNavigate } from 'react-router-dom';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../../Api/firebaseApi';
import useUserStore from '../../../Hooks/Zustand/Store';
import { useEffect } from 'react';
import { clientTypessense } from '../../../Api/Typesense';
import { FcTodoList } from 'react-icons/fc';
import AlertArrayNull from '../../../Components/AlertDialog/AlertArrayNull';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import ChatConfigDetailPage from './ChatConfigDetailPage';

function ChatConfigPage() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newTitle, setNewTitle] = useState();
  const [data, setData] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const globalState = useUserStore();
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;


  const handleSave = async () => {
    if (!newTitle) return;
    setLoading(true);
    try {
      const res = await addDocumentFirebase(
        'chat_data',
        {
          title: newTitle,
          projectId: globalState.currentProject,
          isTemplate: false,
        },
        globalState.currentCompany
      );
      navigate('/chat-config/' + res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    globalState.setIsLoading(true);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const x = await getCollectionFirebase(
        'chat_data',
        conditions,
        sortBy,
        limitValue
      );
      setData(x);
      setDataOrigin(x)
      setTotalPages(Math.ceil(x.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const handleTypesenseSearch = async (q) => {
    try {
      const searchParameters = {
        q: q ? q : '*',
        query_by: 'title',
        filter_by: `companyId: ${globalState.currentCompany} && projectId: ${globalState.currentProject}`,
        sort_by: '_text_match:desc',
        per_page: q ? 8 : itemsPerPage,
        page: q ? 1 : currentPage,
      };

      const x = await clientTypessense
        .collections('chat_data')
        .documents()
        .search(searchParameters);

      const newData = x?.hits?.map((y) => {
        return { ...y.document };
      });
      if (q) {
        setData([...newData]);
      } else {
        setData(dataOrigin);
      }
      setTotalPages(Math.ceil(x.found / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [globalState.currentCompany, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };


  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <Stack>
        <ChatConfigDetailPage/>
      </Stack>
      <Divider />
      <Stack>
        <HStack>
          <Heading size={'md'}>Chat Learning</Heading>
          <Spacer />
          <Input
            placeholder="Search Chat Config"
            type="text"
            w="-moz-min-content"
            onChange={(e) => handleTypesenseSearch(e.target.value)}
          />
          <DynamicButton
            title={'Add Config'}
            action={'create'}
            variant={'solid'}
            onClick={onOpen}
          />

        </HStack>

        {data?.length > 0 ? (
          <SimpleGrid columns={[1, 1, 4]} mt="2" gap="2">
            {data?.map((x, i) => (
              <CardMyKanban
                key={i}
                Icon={FcTodoList}
                onNavigate={() => navigate(`/chat-config/${x.id}`)}
                title={x.title}
                userArray={x.userArray}
              />
            ))}
          </SimpleGrid>
        ) : (
          <AlertArrayNull titleData={'Chat'} action="Add Chat" />
        )}

        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage <= totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Chat Config</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder="Title"
              onChange={(e) => setNewTitle(e.target.value)}
            />
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              isLoading={loading}
              mr={3}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Stack>
  );
}

export default ChatConfigPage;
