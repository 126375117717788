import React from 'react'
import PropTypes from 'prop-types'

const Hero6 = ({header,content,data}) => {
  return (
    <div className="flex justify-center items-center text-center text-white h-[40vw] bg-no-repeat bg-cover bg-fixed" style={{ backgroundImage: `url(${data[0]?.image})` }}>
      <div>
        <p className='text-5xl'>{header || 'Klamby\'s Journal'}</p>
        <p className='text-sm my-5'>{content || 'The Beginning Your Day Reading'}</p>
        <button className='px-4 py-2 border border-slate-100 text-white text-xs'>Explore Now</button>
      </div>
    </div>
  )
}

export default Hero6

Hero6.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.string,
  content: PropTypes.string
}