import {
  Box, Button, Heading, HStack, Spacer, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, Skeleton, Image, InputGroup, Input, InputRightElement,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx'
import axios from 'axios';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import CardFile from '../../Components/Accounting/CardFile';
import DynamicButton from '../../Components/Buttons/DynamicButton';

function BulkContacsPage() {
  const globalState = useUserStore();
  const [file, setFile] = useState(null)
  const [transaction, setTransaction] = useState([])
  const [data, setData] = useState([]);
  const toast = useToast()

  const onSelectFile = (arg) => {
    setFile(arg)
  }

  const fixedArray = (data) => {
    const dats = []
    const varDatas = []
    data.map((x,index) => {
      const array = x.order_ids?.split(',')
      const array2 = x.voucher_used?.split(',')
      const array3 = x.variantId?.split(',')
      const tabsData = {
        average_order_value : x.average_order_value || '',
        last_transaction: x.last_transaction || '',
        column: x.column || '',
        email: x.email || '',
        last_checkout_value: x.last_checkout_value || '',
        id: x.id || '',
        name: x.name || '',
        phone_number: x.phone_number || '',
        last_transaction_amount: x.last_transaction_amount || '',
        last_transaction_order_id: x.last_transaction_order_id || '',
        lifetime_value: x.lifetime_value || '',
        order_ids: array|| '',
        total_paid_transaction: x.total_paid_transaction || '',
        total_transaction: x.total_transaction || '',
        uid: x.uid || '',
        voucher_used: array2 || '',
        address: {
          street : x?.address_street || '',
          postal_coda: x?.address_postal_code || '',
          subdistrict: x?. address_subdistrict || '',
          city: x?.address_city || '',
          state: x?.address_state || '',
          country: x?.address_country || ''
        },
        firstName: x.firstName || '',
        lastName: x.lastName || '',
        company_name: x.company_name || '',
        companyId: globalState.currentCompany,
        projectId: globalState.currentProject,
      }
      dats.push(tabsData)
    })
    console.log(dats)
    setTransaction(dats)
  }
  const onImport = () => {
    if (!file) {
      return toast({
        title: 'Error',
        description: 'Not Have Data',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (file?.target?.files?.[0]?.name?.split('.').pop() !== 'xlsx') {
      return toast({
        title: 'Error',
        description: 'Invalid file type. Please select an XLSX file.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      console.log(worksheet,'works')
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log(jsonData,'json')
      // setTransaction([...jsonData])
      fixedArray(jsonData)
    }

    reader.readAsArrayBuffer(file.target.files[0]);
  }

  const saveDocument = async () => {
    try {
      if (!transaction || transaction.length === 0) {
        return toast({
          title: 'Error',
          description: 'Not Have Data, plese input file  first.',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      const push = transaction.map(async(x) => {
        // const idProduct = await addDocumentFirebase('customer', x, globalState.currentCompany) 
        await setDocumentFirebase('customer', x.uid, x)
      })
      toast({
        title: 'Data saved to Firebase',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
      toast({
        title: 'Error saving data to Firebase',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const downloadTemplate = async () => {
    try {
      const spreadsheetUrl = 'https://docs.google.com/spreadsheets/d/102B1_OlDyFr8DWinNnx0RW0UQTS9s-PwpSn_sd2tjvg/export?format=xlsx';
      const response = await axios.get(spreadsheetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template customer.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading template:', error);
      // Handle error
    }
  };
  // console.log(transaction)
  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Bulk Contact</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5} textAlign="center">
          <CardFile onChange={onSelectFile} />

          <HStack mb={5} mt={5} justifyContent="center">
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Load Document'}
              onClick={() => onImport()}
            />
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Download Template'}
              onClick={downloadTemplate}
            />
          </HStack>

          <Stack alignItems={'end'} justifyContent="end" my={3}>
            <DynamicButton
              size={'sm'}
              action="create"
              title={'Save Document'}
              onClick={() => saveDocument()}
            />
          </Stack>

          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Phone Number</Th>
                  <Th>ID</Th>
                  <Th>Column</Th>
                  <Th>Email</Th>
                  <Th>Average Order Value </Th>
                  <Th>Last Checkout Value</Th>
                  <Th>Last Transaction Amount</Th>
                  <Th>Last Transaction Order Id </Th>
                  <Th>Lifetime Value</Th>
                  <Th>Order Id</Th>
                  <Th>Total Paid Transaction</Th>
                  <Th>Total Transaction</Th>
                  <Th>Uid</Th>
                  <Th>Voucher Used</Th>
                  <Th>Address</Th>
                  <Th>Company Name</Th>
                  <Th>First Name</Th>
                  <Th>Last Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transaction?.map((x, i) => (
                  <Tr key={i}>
                    <Td> {x?.name} </Td>
                    <Td> {x?.phone_number} </Td>
                    <Td> {x?.id} </Td>
                    <Td> {x?.column} </Td>
                    <Td> {x?.email} </Td>
                    <Td> {x?.average_order_value} </Td>
                    <Td> {x?.last_checkout_value} </Td>
                    <Td> {x?.last_transaction_amount} </Td>
                    <Td> {x?.last_transaction_order_id} </Td>
                    <Td> {x?.lifetime_value} </Td>
                    <Td> 
                      <UnorderedList>
                        {x?.order_ids?.map((y) => (
                          <ListItem key={y}>{y}</ListItem>
                        ))}
                      </UnorderedList>
                    </Td> 
                    <Td> {x?.total_paid_transaction} </Td>
                    <Td> {x?.total_transaction} </Td>
                    <Td> {x?.uid} </Td>
                    <Td> 
                      <UnorderedList>
                        {x?.voucher_used?.map((y) => (
                          <ListItem key={y}>{y}</ListItem>
                        ))}
                      </UnorderedList>
                    </Td>                    
                    <Td> {x?.address?.street}, {x?.address?.subdistrict}, {x?.address?.postal}, {x?.address?.city}, {x?.address?.state}, {x?.address?.country} </Td>
                    <Td> {x?.firstName} </Td>
                    <Td> {x?.lastName} </Td>
                    <Td> {x?.company_name} </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

        </Box>
      </Box>
    </>
  );
}

export default BulkContacsPage;