/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import { Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  useDisclosure,
  Avatar,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDropdownCircle,
  IoMdSettings,
} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../Config/firebase';

import { data } from './DataMenu';
import useUserStore from '../../Hooks/Zustand/Store';
import { signOut } from 'firebase/auth';
import { useEffect, useState } from 'react';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../Api/firebaseApi';
import themeConfig from '../../Config/themeConfig';
import { logoutUserWithIp } from '../../Hooks/Middleware/sessionMiddleWare';
import { encryptToken } from '../../Utils/encrypToken';
import { AiOutlineDesktop, AiOutlineMobile } from 'react-icons/ai';
import { HiBell } from 'react-icons/hi';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import SelectCompanyProject from '../Select/SelectCompanyProject';


// import { anggaranApi } from '../../Api/Anggaran';
// import { ApiAccountingToken } from '../../Api/ApiAccountingToken';

// ** Theme Configuration

function SidebarComponentV3({ layout }) {
  const [menu, setMenu] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
  const [desktopShow, setDesktopShow] = useState(true);
  const [hidden, setHidden] = useState(!isOpen);
  const [countNotif, setCountNotif] = useState(1)
  const { colorMode, toggleColorMode } = useColorMode()


  const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });


  const globalState = useUserStore();

  const uid = globalState?.uid;

  const encryptUid = encryptToken(uid);
  const encryptFix = encodeURIComponent(encryptUid);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevIsVisible) => !prevIsVisible);
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);


  const user = auth.currentUser;

  const navigate = useNavigate();

  const toast = useToast();

  const handleSelectMenu = (value) => {
    setMenu(value);

    if (value.status) {
      null;
    } else {
      if (value?.link?.includes('https')) {
        window.open(value?.link, '_blank');
      } else {
        navigate(value.link);
      }
    }
  };

  const logout = async () => {
    const pathLink = 'business';

    await logoutUserWithIp(
      window.location.hostname,
      globalState?.email,
      pathLink
    );

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: 'success',
          description: 'Logged out success',
          duration: 2000,
        });
        navigate('/');
        localStorage.clear();
        globalState.setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {

        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  // const handleTokenAccounting = async (value) => {
  //   try {
  //     const res = await ApiAccountingToken(globalState, value);
  //     globalState.setAccountingData(res.data);
  //   } catch (error) {

  //     toast({
  //       title: 'Deoapp.com',
  //       description: error.message,
  //       status: 'error',
  //       position: 'top-right',
  //       isClosable: true,
  //     });
  //   }
  // };



  const getRole = async () => {
    const conditions = [
      { field: 'users', operator: 'array-contains', value: globalState?.uid },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 10;
    const startAfterData = null;

    try {
      const res = await getCollectionFirebase(
        `companies/${globalState?.currentCompany}/roles`,
        conditions,
        sortBy,
        limitValue,
        startAfterData
      );

      if (res[0]?.id) {
        const result = await getSingleDocumentFirebase('files', res[0]?.id);

        globalState.setRole(result);
      }
    } catch (error) {

      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
      globalState.setRole({});
    }
  };

  const getCountNotification = async () => {
    const collectionRef = collection(db, 'notification');
    const snapshot = await getCountFromServer(
      query(
        collectionRef,
        where('userId', 'array-contains', globalState?.uid),
        where('companyId', '==', globalState?.currentCompany),
        where('onSee', '==', false),
      ));

    const assesmentLength = snapshot.data().count;
    setCountNotif(assesmentLength)
  }

  useEffect(() => {
    if (globalState?.currentCompany) {
      getRole();
      getCountNotification()
    }

    return () => { };
  }, [globalState?.currentCompany]);

  const handleNavigate = (value) => {
    if (value?.link?.includes('https')) {
      window.open(`${value.link}?id=${encryptFix}`, '_blank');
    } else {
      navigate(value.link);
    }
  };

  const handleClick = () => {
    setDesktopShow(!desktopShow);
  };

  const height = window?.innerHeight;

  if (layout.type === 'vertical' || layout.type === 'vertical-horizontal')
    return (
      <HStack spacing={'-1'}>
        <Box
          sx={{
            '&::-webkit-scrollbar': {
              w: '2',
              h: '3',
            },
            '&::-webkit-scrollbar-track': {
              w: '6',
              h: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.200',
            },
          }}
          width={desktopShow ? '100px' : '15px'}
          transition={'0.2s ease-in-out'}
          shadow={'xl'}
          roundedBottomRight={'lg'}
          roundedTopRight={'lg'}
          minH={height}
        // backgroundColor={themeConfig.color.colorFirst}
        >
          <Box position="sticky">
            <Stack
              position={'absolute'}
              right={-4}
              cursor={'pointer'}
              onClick={handleClick}
              alignItems="flex-end"
              justifyContent={'flex-end'}
              p={2}
              borderRadius="full"
              bgColor="blue.200"
              zIndex={500}
            >
              {desktopShow ? (
                <AiOutlineMobile size={17} />
              ) : (
                <AiOutlineDesktop size={17} />
              )}
            </Stack>

            <Stack
              {...getButtonProps()}
              position={'absolute'}
              right={-5}
              top={300}
              py={[5, null, null]}
              bg={'blue.300'}
              minH={50}
              overflowY="scroll"
              borderRadius={'md'}
              zIndex={-1}
              cursor={'pointer'}
              p={1}
              alignItems={'center'}
              justify={'center'}
            >
              {isOpen ? (
                <IoIosArrowBack size={18} color="white" />
              ) : (
                <IoIosArrowForward size={18} color="white" />
              )}
            </Stack>

            <Flex as="section">
              <Stack display={desktopShow ? 'flex' : 'none'}>
                <Box onClick={() => navigate('/')} cursor={'pointer'}>
                  <Image src={themeConfig.logokotak} borderRadius="full" />
                </Box>

                <Box px={2}>
                  <Divider />
                </Box>

                <SelectCompanyProject globalStateProps={globalState} moduleProps={'karyawan'} />


                <Stack
                  height={['40vh', '50vh', '50vh']}
                  overflowY="scroll"
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}
                >
                  {data.map((menu, i) => (
                    <Stack
                      key={i}
                      pt={2}
                      spacing={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                      onClick={() => handleSelectMenu(menu)}
                      cursor={'pointer'}
                      position="relative"
                    >
                      <Icon as={menu.icon} boxSize={6} />
                      <Text fontSize={10}>{menu.name}</Text>
                      {menu.status ? (
                        <Stack
                          size={'xs'}
                          position="absolute"
                          bgColor={'red'}
                          borderRadius="md"
                          visibility={isVisible ? 'visible' : 'hidden'}
                          top={2}
                        >
                          <Text
                            color={'white'}
                            fontSize={'xx-small'}
                            fontWeight={500}
                            py={'0.5'}
                            px={1}
                          >
                            Coming Soon
                          </Text>
                        </Stack>
                      ) : null}
                    </Stack>
                  ))}
                </Stack>
                {data.lenght > 7 && (
                  <Stack alignItems={'center'} justifyContent="center">
                    <IoIosArrowDropdownCircle />
                  </Stack>
                )}

                <Spacer />

                <Stack spacing={3}>
                  <Stack
                    align={'center'}
                    onClick={() => navigate('/notification')}
                    cursor={'pointer'}
                    position='relative'
                  >

                    <Icon as={HiBell} boxSize={6} color="subtle" />
                    <Stack bottom={5} right={5} p={1} position='absolute' alignItems={'center'} justifyContent={'center'} w={'25px'} h='25px' borderRadius="full" bgColor={'red'} color={'white'}>
                      <Text fontSize={'xs'} fontWeight={'bold'}>{countNotif}</Text>
                    </Stack>
                  </Stack>

                  <Stack
                    align={'center'}
                    onClick={() => navigate('/settings')}
                    cursor={'pointer'}
                  >
                    <Icon as={IoMdSettings} boxSize={6} color="subtle" />
                  </Stack>
                  {/* </Button> */}
                </Stack>

                <Box py={3}>
                  <Center mt='2'>
                    <Switch onChange={toggleColorMode} />
                  </Center>
                  <Center py={2}>
                    <Avatar
                      boxSize={10}
                      name={user?.displayName || `https://api.dicebear.com/7.x/adventurer/svg?seed=${user.email}`}
                      src={user?.photoURL === null || user?.photoURL?.includes('example.com') ? `https://api.dicebear.com/7.x/adventurer/svg?seed=${user.email}` : user?.photoURL}
                    />
                  </Center>

                  <Box align={'center'}>
                    <Button size={'xs'} colorScheme="red" onClick={logout}>
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Flex>
          </Box>
        </Box>

        <motion.div
          {...getDisclosureProps()}
          hidden={hidden}
          initial={false}
          onAnimationStart={menu?.submenu ? () => setHidden(false) : null}
          onAnimationComplete={menu?.submenu ? () => setHidden(!isOpen) : null}
          animate={menu?.submenu ? { width: isOpen ? 200 : 0 } : 0}
          style={{
            borderStartEndRadius: 20,
            borderEndEndRadius: 20,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // position: "absolute",
            // left: "0",
            height: '100vh',
            // top: "0",
          }}
        >
          <Stack p={3} h={'100vh'}>
            <Text
              color={'gray.600'}
              my={3}
              fontWeight={'semibold'}
              align={'center'}
              textTransform="uppercase"
            >
              Business Deoapp
            </Text>
            <Box>
              <Divider />
            </Box>
            <Stack spacing={3} pt={3}>
              <Accordion allowToggle>
                {menu.submenu?.map((sub, i) => (
                  <AccordionItem
                    key={i}
                  // isDisabled={x.name === "Social Media" ? true : false}
                  >
                    <h2>
                      <AccordionButton w={'100%'}>
                        <HStack spacing={2} w={'100%'}>
                          <Icon as={sub.icon} boxSize={isDesktop ? 5 : 7} />
                          <Text
                            fontWeight={500}
                            fontSize={isDesktop ? 'sm' : 'xs'}
                            noOfLines={1}
                          >
                            {sub.name}
                          </Text>
                          <Spacer />
                          <AccordionIcon />
                        </HStack>
                      </AccordionButton>
                    </h2>
                    {sub.submenu ? (
                      <>
                        <AccordionPanel>
                          <Stack>
                            {sub.submenu?.map((subitem, i) => {
                              return (
                                <Box
                                  cursor={'pointer'}
                                  onClick={() => handleNavigate(subitem)}
                                  key={i}
                                >
                                  <HStack spacing="3">
                                    <Icon as={subitem.icon} boxSize={5} />
                                    <Text
                                      pl={3}
                                      fontWeight={300}
                                      fontSize={isDesktop ? 'sm' : 'xs'}
                                      noOfLines={1}
                                    >
                                      {subitem.name}
                                    </Text>
                                  </HStack>
                                  <Divider py={1} />
                                </Box>
                              );
                            })}
                          </Stack>
                        </AccordionPanel>
                      </>
                    ) : (
                      <>{null}</>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Stack>
            <Spacer />
            <Stack spacing={0} align={'center'} color={'gray.500'}>
              <Text fontSize={'xs'}>{globalState?.name}</Text>
              <Text fontSize={'xs'}>{globalState?.email}</Text>
            </Stack>
            <Divider />
            <Text
              color={'gray.500'}
              align={'center'}
              fontSize={12}
              fontWeight={'semibold'}
            >
              Business Deoapp
            </Text>
          </Stack>
        </motion.div>

      </HStack>
    );

  return <></>;
}

export default SidebarComponentV3;
