import React, { useEffect, useState } from 'react';
import { Avatar, AvatarGroup, Box, HStack, Heading, SimpleGrid, Spacer, Stack, Text, VStack, useColorMode } from '@chakra-ui/react';
import { FcApproval, FcFactory } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import { countDocumentsFirebase, sumDocumentsFieldFirebase } from '../../Api/firebaseApi';
import moment from 'moment';

const RetentionDefaultPipeline = () => {
  const globalState = useUserStore();
  const findProject = globalState?.projects?.find(
    (x) => x?.id === globalState?.currentProject
  );
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [counts, setCounts] = useState({});
  const [value, setValue] = useState({})
  const [counts1, setCounts1] = useState({});
  const [value1, setValue1] = useState({})

  const handleNavigate = (transaction) => {
    navigate(`pipeline/view/${transaction}`, { state: transaction });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const today = moment();
        // const today1 = moment().subtract(1, 'days');
        // const time1 = moment().subtract(7, 'days');
        // const time2 = moment().subtract(14, 'days');
        // const time3 = moment().subtract(30, 'days');
        // const time4 = moment().subtract(90, 'days');

        const today = moment().startOf('day').toDate();
        const dates = [
          new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)),
          new Date(today.getTime() - (14 * 24 * 60 * 60 * 1000)),
          new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000)),
          new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000))
        ];
        // return console.log(dates)
        const todayConditions = [
          { field: 'last_transaction', operator: '>=', value: today },
          { field: 'projectId', operator: '==', value: globalState?.currentProject },
          { field: 'companyId', operator: '==', value: globalState?.currentCompany },
          { field: 'total_paid_transaction', operator: '>=', value: 1 },
        ];

        const conditions = dates.map((x) => ([
          { field: 'last_transaction', operator: '<=', value: today },
          { field: 'last_transaction', operator: '>=', value: x },
          { field: 'projectId', operator: '==', value: globalState?.currentProject },
          { field: 'companyId', operator: '==', value: globalState?.currentCompany },
          { field: 'total_paid_transaction', operator: '>=', value: 1 },
        ]));

        const conditionsInactive = dates.map((x) => ([
          { field: 'lastUpdated', operator: '<=', value: today },
          { field: 'lastUpdated', operator: '>=', value: x },
          { field: 'projectId', operator: '==', value: globalState?.currentProject },
          { field: 'companyId', operator: '==', value: globalState?.currentCompany }
        ]));

        // return console.log(conditions)
        const countsPromises = conditions.map(async (item) => {
          const count = await countDocumentsFirebase('customer', item);
          return count;
        });
        const counts = await Promise.all(countsPromises);

        const countsInactivePromises = conditionsInactive.map(async (item) => {
          const count = await countDocumentsFirebase('customer', item);
          return count;
        });
        const countsInactive = await Promise.all(countsInactivePromises);
        // console.log(countsInactive)
        const valuesPromises = conditions.map(async (x) => {
          const point = await sumDocumentsFieldFirebase('customer', 'last_checkout_value', x);
          return point;
        });
        const values = await Promise.all(valuesPromises);

        const valuesInactivePromises = conditionsInactive.map(async (x) => {
          const point = await sumDocumentsFieldFirebase('customer', 'last_checkout_value', x);
          return point;
        });
        const valuesInactive = await Promise.all(valuesInactivePromises);
        // console.log(valuesInactive)
        const todayCountPromise = countDocumentsFirebase('customer', todayConditions);
        const todayValuePromise = sumDocumentsFieldFirebase('customer', 'last_checkout_value', todayConditions);

        const [todayCount, todayValue] = await Promise.all([todayCountPromise, todayValuePromise]);

        setCounts([
          todayCount, ...counts, ...countsInactive
        ]);

        setValue([
          todayValue, ...values, ...valuesInactive
        ]);

        setCounts1([
          ...countsInactive
        ]);

        setValue1([
          ...valuesInactive
        ]);

      } catch (error) {
        console.log('error', error)
      }
    };

    fetchData();
  }, [globalState.currentCompany, globalState.currentProject]);

  const title = [
    { name: 'Today Transactions', url: 'today-transaction' },
    { name: 'Last 7 Days Transactions', url: 'last-7-days-transaction' },
    { name: 'Last 14 Days Transactions', url: 'last-14-days-transaction' },
    { name: 'Last 30 Days Transactions', url: 'last-30-days-transaction' },
    { name: 'Last 90 Days Transactions', url: 'last-90-days-transaction' },
  ];

  const title1 = [
    { name: 'Inactive Period 7 Day', url: 'inactive-period-7-day' },
    { name: 'Inactive Period 14 Day', url: 'inactive-period-14-day' },
    { name: 'Inactive Period 30 Day', url: 'inactive-period-30-day' },
    { name: 'Inactive Period 90 Day', url: 'inactive-period-90-day' }
  ];

  return (
    <>
      <Stack mb={'2%'}>
        <Heading fontSize={'120%'}>Last Transactions</Heading>

        <Box overflowX={'auto'} sx={{
          '&::-webkit-scrollbar': {
            w: '2',
            h: '3',
          },
          '&::-webkit-scrollbar-track': {
            w: '6',
            h: '5',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10',
            bg: 'gray.200',
          },
        }}>
          <HStack spacing={5} p={4}>
            {title?.map((title, index) => (
              <Box key={index} minWidth="350px" borderWidth="1px" p={4} rounded="md" cursor="pointer" _hover={{ bg: 'gray.100' }} onClick={() => handleNavigate(title.url)}>
                <Stack columns={1} borderWidth='1px'
                  p={3}
                  shadow={'md'}
                  rounded={5}
                  cursor='pointer'
                  _hover={{
                    bg: 'gray.100',
                    transform: 'scale(1.02)',
                    transition: '0.3s',
                    cursor: 'pointer',
                  }}>
                  <VStack borderRadius={'md'} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} align="flex-start" w="full" h="full" justify="space-between">
                    <HStack spacing={3} px={4} pt={4} w="100%">
                      <Stack cursor="pointer" onClick={() => handleNavigate(title)}>
                        <FcFactory size={50} />
                      </Stack>
                      <Stack ml={'5%'}>
                        <Text
                          key={index}
                          textTransform="capitalize"
                          cursor="pointer"
                          noOfLines={1}
                          fontSize="sm"
                          fontWeight="medium"
                          onClick={() => handleNavigate(title.url)}
                        >
                          {title.name}
                        </Text>
                        <Text spacing={3} fontSize="2xs">
                          Jumlah: Rp. {value[`${index}`]?.toLocaleString('id-ID')}
                        </Text>
                        <Text spacing={3} fontSize="2xs">
                          Count: {counts[`${index}`]}
                        </Text>
                      </Stack>
                    </HStack>

                    <HStack
                      alignItems="center"
                      justifyContent="space-around"
                      w="full"
                      p={3}
                    // mt={1}
                    >
                      <Stack spacing={0} ml={['10%', '40%', '70%']}>
                        <Text spacing={3} fontSize="2xs" >
                          Project: {findProject?.name}
                        </Text>
                      </Stack>
                      <Spacer />
                      {/* Tombol aksi tambahan jika diperlukan */}
                    </HStack>
                  </VStack>
                </Stack>
              </Box>
            ))}
          </HStack>
        </Box>
        <Heading fontSize={'120%'} mt={'3%'}>Inactive Period</Heading>

        <Box overflowX={'auto'}
          sx={{
            '&::-webkit-scrollbar': {
              w: '2',
              h: '3',
            },
            '&::-webkit-scrollbar-track': {
              w: '6',
              h: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.200',
            },
          }}>
          <HStack spacing={5} p={4}>
            {title1?.map((title, index) => (
              <Box key={index} minWidth="350px" borderWidth="1px" p={4} rounded="md" cursor="pointer" _hover={{ bg: 'gray.100' }} onClick={() => handleNavigate(title.url)}>
                <Stack columns={1} borderWidth='1px'
                  p={3}
                  shadow={'md'}
                  rounded={5}
                  cursor='pointer'
                  _hover={{
                    bg: 'gray.100',
                    transform: 'scale(1.02)',
                    transition: '0.3s',
                    cursor: 'pointer',
                  }}>
                  <VStack borderRadius={'md'} bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'} align="flex-start" w="full" h="full" justify="space-between">
                    <HStack spacing={3} px={4} pt={4} w="100%">
                      <Stack cursor="pointer" onClick={() => handleNavigate(title)}>
                        <FcFactory size={50} />
                      </Stack>
                      <Stack ml={'5%'}>
                        <Text
                          key={index}
                          textTransform="capitalize"
                          cursor="pointer"
                          noOfLines={1}
                          fontSize="sm"
                          fontWeight="medium"
                          onClick={() => handleNavigate(title.url)}
                        >
                          {title.name}
                        </Text>
                        <Text spacing={3} fontSize="2xs">
                          Jumlah: Rp. {value1[`${index}`]?.toLocaleString('id-ID')}
                        </Text>
                        <Text spacing={3} fontSize="2xs">
                          Count: {counts1[`${index}`]}
                        </Text>
                      </Stack>
                    </HStack>

                    <HStack
                      alignItems="center"
                      justifyContent="space-around"
                      w="full"
                      p={3}
                    // mt={1}
                    >
                      <Stack spacing={0} ml={['10%', '40%', '70%']}>
                        <Text spacing={3} fontSize="2xs" >
                          Project: {findProject?.name}
                        </Text>
                      </Stack>
                      <Spacer />
                      {/* Tombol aksi tambahan jika diperlukan */}
                    </HStack>
                  </VStack>
                </Stack>
              </Box>
            ))}
          </HStack>
        </Box>
      </Stack>
    </>
  );
};

export default RetentionDefaultPipeline