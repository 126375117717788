import PrintLabelPage from '../Pages/Shipping/PrintLabelPage';
import ShippingDetailPage from '../Pages/Shipping/ShippingDetail/ShippingDetailPage';
import ShippingPage from '../Pages/Shipping/ShippingPage';
import React from 'react'

const ShippingRouter = [
  {
    path: '/shipping',
    element: <ShippingPage />,
  },
  {
    path: '/shipping/:type',
    element: <ShippingDetailPage />,
  },
  {
    path: '/shipping/print-resi',
    element: <PrintLabelPage />,
  },
]


export default ShippingRouter;