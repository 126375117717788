/* eslint-disable react/prop-types */
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  HStack,
  FormControl,
  FormErrorMessage,
  Input,
  useColorMode as mode,
  Divider,
  Textarea,
  Select,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import ShippingOExpress from '../../Components/Shipment/ShippingOExpress';
import axios from 'axios';
import { formatFrice } from '../../Utils/Helper';
import { Helmet } from 'react-helmet'

const banks = [
  { name: 'bni', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png' },
  { name: 'bca', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png' },
  { name: 'mandiri', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png' },
  { name: 'permata', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png' },
  { name: 'bri', uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png' },
  { name: 'cimb', uri: 'https://career.amikom.ac.id/images/company/cover/1637497490.jpeg' },
]

const CheckoutFrontViewPage = () => {
  const { setColorMode } = mode();
  const [searchParams] = useSearchParams();
  const [productData, setProductData] = useState({});
  const [warehouseData, setWarehouseData] = useState({});
  const [shippingServices, setShippingServices] = useState([]);
  const [formData, setFormData] = useState({ quantity: 1 });
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingCosts, setFetchingCosts] = useState(false);
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [selectedVariant, setSelectedVariant] = useState({});
  const [isError, setIsError] = useState({
    name: false
  });
  const [cityCode, setCityCode] = useState({
    oExpress: 0
  });
  // https://deoapp.com/management-app?utm_source=fbads&utm_medium={{adset.name}}&utm_campaign={{campaign.name}}&utm_content={{ad.name}}
  const slug = searchParams.get('slug');
  const utm_medium = searchParams.get('utm_medium') || '';
  const utm_campaign = searchParams.get('utm_campaign') || '';
  const utm_content = searchParams.get('utm_content') || '';
  const selectedVariant = searchParams.get('variantId') || '';
  const navigate = useNavigate();
  const toast = useToast();



  // useEffect(() => {
  //   setTotalPrice(formatFrice((parseFloat(formData?.variant?.price) * parseInt(formData?.quantity))
  //     + parseInt(formData?.shippingCost)))
  // }, [
  //   formData?.variant?.price,
  //   formData?.quantity,
  //   formData?.shippingCost
  // ])



  const getProductDetail = async () => {
    let x;
    try {
      const result = await getCollectionFirebase('product', [{ field: 'slug', operator: '==', value: slug }]);
      x = result[0];
      // console.log(x, 'productdata')
      setProductData(x);
    } catch (error) {
      console.log(error.message, 'error getting product data')
    }


    if (x.type === 'physical') {
      try {
        const warehouse = await getSingleDocumentFirebase('warehouses', x?.warehouseId);
        warehouse && setWarehouseData(warehouse);
        console.log(warehouse, 'warehouse')
      } catch (error) {
        console.log(error.message, 'error getting warehouseData')
      }
    }

    try {
      const condition = [{ field: 'productId', operator: '==', value: x?.id }];
      const variants = await getCollectionFirebase('product_variants', condition);
      setVariants(variants);
      setFormData((prev) => ({
        ...prev,
        variant: variants[0],
        variantId: variants[0]?.id
      }))
    } catch (error) {
      console.log(error.message, 'error getting variant data')
    }
  };

  const handleData = (e) => {
    const { id: key, value } = e.target;
    const existingData = { ...formData };
    existingData[key] = value;
    setFormData(existingData);
  };

  const getShippingPrice = async () => {
    try {
      setFetchingCosts(true);
      const body = {
        'origin_id': warehouseData?.oExpress,
        'destination_id': cityCode?.oExpress,
        'total_weight': parseInt(productData?.weight) * 1000 || 1000,
        'force_oexpress ': false,
        'is_production': true
      };
      // console.log(body, 'body to get shipping price')
      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_FUNCTIONS_HOST}/shipping-oExpressShippingCost`,
        data: body,
        headers: {
          'Authorization': process.env.REACT_APP_PAYMENT_KEY,
          // 'x-api-key': process.env.REACT_APP_OEXPRESS_API_KEY_PRODUCTION
        }
      };

      const shipping = await axios(config);
      const { data } = shipping;
      // console.log(data, 'data')
      if (data.status === true) {
        // console.log(data?.data?.costs, 'data?.data?.costs')
        setShippingServices(data?.data?.costs);
        setFormData({
          ...formData,
          selectedService: data?.data?.costs[0]?.service,
          shippingCost: data?.data?.costs[0]?.cost[0]?.value
        })
      } else {
        // console.log(data, 'data, status != true')
      }
    } catch (error) {
      console.log(error.message, 'error shipping cost')
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    } finally {
      setFetchingCosts(false);
    }
  }

  // const itemPrice = formatFrice((parseFloat(formData?.variant?.price) * parseInt(formData?.quantity))
  //   + parseInt(formData?.shippingCost)
  // );
  const calculateDiscount = productData?.is_promo && (formData?.variant?.discount_type === 'percentage' ?
    parseFloat(formData?.variant?.price) * (parseFloat(formData?.variant?.discount_amount) / 100) :
    parseFloat(formData?.variant?.discount_amount))
  const discount = productData?.is_promo ? formData?.variant?.is_promo === false ? 0 : calculateDiscount : 0;
  let itemPrice = formData?.variant?.price * formData?.quantity
  let totalPrice = parseInt(formData?.variant?.price * formData?.quantity) + parseInt(formData?.shippingCost || 0)



  const fetchPixel = async () => {
    try {
      const res = await fetch('https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/lpage.id/testing-11/gtm.js');
      const pixel = await res.text();
      // console.log(pixel, 'pixel')
      const fromWindow = window.document
      // console.log(fromWindow, 'fromWindow')
    } catch (error) {
      console.log(error, 'error fetch pixel')
    }
  }

  const handleCreateOrder = async () => {
    setLoading(true)
    const items = [
      {
        'variantId': formData?.variantId, // warehouse denpasar (warehouse id = lQzFqM4JYgDMh879EklR)
        'productId': productData?.id, // cari warehouse where projectId === productId
        'quantity': formData?.quantity,
        'message': 'halo world',
        'shipping_provider': { // if variant type == physical, shipping_provider required!!!
          'provider': 'oExpress', // oExpress || jne ======= required!!!
          'service': formData?.selectedService, // HORE,SERBU,KISS,SATSET,SULTAN,GOCAP (oExpress) || REG, OKE, JTR, YES, ======= required!!!
          'description': '-',
          'note': '',
          'from': warehouseData?.oExpress, //required!!!
          'to': cityCode?.oExpress // example: 4569 (oExpress),  CLG10000, PDG10000 (jne) required!!!
        }
      },
    ];

    if (productData?.type === 'digital') delete items[0].shipping_provider;


    const payload = {
      'userId': '',
      'userType': 'customer', // customer || user
      'companyId': productData?.companyId,
      'projectId': productData?.projectId,
      'paymentGateway': 'midtrans', // xendit || midtrans || ipaymu || finpay || stripe
      'paymentService': formData?.bank === 'qr' || formData?.bank === 'gopay' ? 'gopay' : 'bank_transfer', // va || qr || gopay 
      'bank': formData?.bank === 'qr' ? '' : formData?.bank, // bca || bni || bri || permata || 
      'module': 'commerce', // commerce || management || business || ...
      'is_production': true, // true or false for xendit create payment
      'items': items,
      'contact_information': {
        'email': formData?.email,
        'name': formData?.name,
        'phone_number': formData?.phone,
        'address': {
          'street': formData?.street,
          'city': formData?.city,
          'state': formData?.state,
          'postal_code': formData?.zip,
          'country': 'indonesia',
          'fullAddress': formData?.address
        },
      },
      'utm_medium': utm_medium || '',
      'utm_campaign': utm_campaign || '',
      'utm_content': utm_content || '',
    };
    const config = {
      url: 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/order-createOrder',
      method: 'post',
      data: payload,
    }
    console.log(payload, 'payload');
    try {
      const res = await axios(config);
      console.log(res.data, 'res.data')
      if (res.data?.status === true && Object.keys(res.data?.data?.payment_information)?.length > 0) {
        navigate('payment', { state: { ...res.data, bank: formData?.bank } });
      } else if (res.data?.status === false) {
        toast({
          title: 'Oops!',
          description: res?.data.error?.message || 'Order tidak berhasil',
          status: 'warning',
          isClosable: true,
          duration: 9000
        })
      } else {
        toast({
          title: 'Oops!',
          description: res?.data?.message || 'Order tidak berhasil',
          status: 'warning',
          isClosable: true,
          duration: 9000
        })
      }
    } catch (error) {
      console.log(error.message, 'error order');
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        isClosable: true,
        duration: 9000
      })
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchPixel();
    getProductDetail();
    setColorMode('light');
    if (selectedVariant) {
      setFormData({
        ...formData,
        variantId: selectedVariant,
        variant: variants?.find((x) => x?.id === selectedVariant)
      })
      itemPrice = variants?.find((x) => x?.id === selectedVariant)?.price
      totalPrice = variants?.find((x) => x?.id === selectedVariant)?.price
    }
  }, []);


  useEffect(() => {
    getShippingPrice();
  }, [cityCode,])

  return (
    <>
      {productData?.pixelId && 
          <Helmet>
            <script type='text/javascript'>
              {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${productData?.pixelId}');
          fbq('track', 'PageView');
        `}
            </script>
            <noscript>
              {`
           <img height='1' width='1' style='display:none'
            src='https://www.facebook.com/tr?id=${productData?.pixelId}&ev=PageView&noscript=1' />
        `}
            </noscript>
          </Helmet>
      }

      <Container maxW={'4xl'}>
        <SimpleGrid columns={[1, 1, 2]} my={10} p={5} align={'center'}>
          <VStack px={'5%'} bg='#f2f2f2' py={10} position='relative'>
            <Image src={productData?.images ? productData?.images[0] : ''} width={'10em'} />
            <Heading size='md'>{productData?.name}</Heading>
            <Divider my={5} />
            <div dangerouslySetInnerHTML={{ __html: productData?.description }} />
            <Divider px={-10} my={5} />
            <Heading size='sm' color='red'>Silakan Pilih Varian:</Heading>

            <Box wrap={'wrap'} >
              {variants?.map((variant, i) => (
                <Box
                  width={'10em'}
                  bg={'white'}
                  onClick={() => {
                    setFormData({ ...formData, variantId: variant.id, variant })
                  }}
                  cursor={'pointer'}
                  _hover={{ transform: 'scale(1.05)', transition: 'ease-in 0.1s' }}
                  m={1}
                  p={3}
                  borderWidth={formData?.variantId === variant.id ? 2 : 1}
                  borderColor={formData?.variantId === variant.id ? 'orange' : 'lightgray'}
                  key={i}
                >
                  <Text>{variant?.name}</Text>
                  <Text fontSize={10} color='gray'>Stock : {variant?.stocks}</Text>
                </Box>))}
            </Box>
            <HStack>
              <Text>Quantity: </Text>
              <Input bg={'white'} defaultValue={1} maxW={'5em'} type={'number'} id='quantity' onChange={handleData} />
            </HStack>
            <Text fontWeight='medium'>IDR {formatFrice(formData?.variant?.price)}</Text>
            <Stack w='100%' bg='white' mb={100} p={'5%'}>


              <Flex justify='space-between'>
                <Text>
                  {productData?.name}
                </Text>
                <Text fontWeight='semibold'>
                  IDR {formatFrice(itemPrice)}
                </Text>
              </Flex>
              {formData?.shippingCost &&
                <Flex justify='space-between'>
                  <Text>
                    Biaya Pengiriman
                  </Text>
                  <Text fontWeight='semibold'>
                    IDR {formatFrice(formData?.shippingCost)}
                  </Text>
                </Flex>
              }
              {discount != 0 &&
                <Flex justify='space-between'>
                  <Text>
                    Discount
                  </Text>
                  <Text fontWeight='semibold'>
                    IDR {formatFrice(discount)}
                  </Text>
                </Flex>
              }

              <Flex justify='space-between'>
                <Text fontWeight='semibold'  onClick={() => console.log(formData)}>
                  Total
                </Text>
                <Text fontWeight='extrabold'>
                  IDR {formatFrice(totalPrice)}
                </Text>
              </Flex>
            </Stack>

          </VStack >
          <Stack px={'5%'}>
            <Heading size='md'>Data Penerima</Heading>

            <FormControl isInvalid={isError.name}>
              <Input type='text' id='name' onChange={handleData} placeholder={'Nama Anda'} />
              {!isError ? (
                <></>
              ) : (
                <FormErrorMessage>Name is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isError.name}>

              <Input type='number' id='phone' onChange={handleData} placeholder={'No. Whatsapp Anda'} />
              {!isError ? (
                <></>
              ) : (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isError.name}>

              <Input type='email' id='email' onChange={handleData} placeholder={'Email Anda'} />
              {!isError ? (
                <></>
              ) : (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={isError.name}>
              <Input type='text' id='company' onChange={handleData} placeholder={'Company Anda'} />
              {!isError ? (
                <></>
              ) : (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
            </FormControl>

            {productData?.type === 'physical' ?
              <>
                <ShippingOExpress cityCode={cityCode} setCityCode={setCityCode} />
                {/* street, city, state, postal_code */}

                {/* <FormControl isInvalid={isError.name}>
                  <Input type='text' id='street' onChange={handleData} placeholder={'Jalan'} />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isError.name}>
                  <Input type='text' id='city' onChange={handleData} placeholder={'Kota'} />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isError.name}>
                  <Input type='text' id='state' onChange={handleData} placeholder={'Provinsi'} />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isError.name}>
                  <Input type='text' id='zip' onChange={handleData} placeholder={'Kode Pos'} />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl> */}
                <FormControl isInvalid={isError.name}>
                  <Textarea type='text' id='address' onChange={handleData} placeholder={'Alamat Lengkap'} />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
              </>

              : <></>}


            {productData?.type === 'physical' ?
              <>
                <HStack mt={10}>
                  <Heading size='md'>Metode Pengiriman</Heading>
                  {fetchingCosts && <Spinner color={'gray.400'} mx={2} />}
                </HStack>
                <Select
                  onChange={(e) => {
                    console.log(e.target.value, 'value shipping services')
                    setFormData(
                      {
                        ...formData,
                        selectedService: e.target.value,
                        shippingCost: shippingServices?.find((x) => x?.service === e.target.value)?.cost[0]?.value
                      }
                    )
                  }
                  }
                  // placeholder='Pilih Kurir'
                  defaultValue={shippingServices[0]?.service}
                >
                  {shippingServices?.map((x, i) => (
                    <option key={i} value={x.service}>{x?.service}  ({x?.cost[0]?.etd}) --Rp{formatFrice(x?.cost[0]?.value)}</option>
                  ))}
                </Select>
              </>
              : <></>}

            <Heading mt={10} size='md'>Metode Pembayaran</Heading>
            <SimpleGrid columns={2} mb={20}>
              <Box
                cursor={'pointer'}
                onClick={() => setFormData({ ...formData, bank: 'qr' })}
                _hover={{ transform: 'scale(1.05)', transition: 'ease-in 0.1s' }}
                p={3}
                m={1}
                borderWidth={formData?.bank === 'qr' ? 2 : 1}
                borderColor={formData?.bank === 'qr' ? 'orange' : 'lightgray'}
              >
                <Image src='https://seeklogo.com/images/Q/quick-response-code-indonesia-standard-qris-logo-F300D5EB32-seeklogo.com.png' width={'5em'} alt='qris' />
              </Box>
              <Box
                cursor={'pointer'}
                onClick={() => setFormData({ ...formData, bank: 'gopay', paymentService: 'gopay' })}
                _hover={{ transform: 'scale(1.05)', transition: 'ease-in 0.1s' }}
                p={3}
                m={1}
                borderWidth={formData?.bank === 'gopay' ? 2 : 1}
                borderColor={formData?.bank === 'gopay' ? 'orange' : 'lightgray'}
              >
                <Image src='https://antinomi.org/wp-content/uploads/2022/03/logo-gopay-vector.png' width={'5em'} alt='gopay' />
              </Box>
              {banks.map((bank, i) => (
                <Box
                  onClick={() => setFormData({ ...formData, bank: bank.name })}
                  cursor={'pointer'}
                  _hover={{ transform: 'scale(1.05)', transition: 'ease-in 0.1s' }}
                  m={1}
                  p={3}
                  borderWidth={formData?.bank === bank.name ? 2 : 1}
                  borderColor={formData?.bank === bank.name ? 'orange' : 'lightgray'}
                  key={i}
                >
                  <Image src={bank.uri} width={'5em'} />
                </Box>
              ))}
            </SimpleGrid>

            <Button
              bg={'black'}
              color={'white'}
              borderWidth={1}
              borderRadius={0}
              fontWeight={500}
              onClick={handleCreateOrder}
              // pos={'absolute'}
              // bottom={5}
              // right={2}
              // left={2}
              mb={5}
              h={'3.5em'}
              isLoading={loading}
              disabled={loading}
            >
              Buat Pesanan
            </Button>
          </Stack>
        </SimpleGrid >
      </Container >

    </>
  )
}

export default CheckoutFrontViewPage

