/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  useDisclosure,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcDatabase, FcHome } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import {
  addDocumentFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { db } from '../../Config/firebase';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import CardAvatar from '../../Components/Card/CardAvatar';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
  
function ProductivityKanbanPage() {
  const navigate = useNavigate();
  const toast = useToast()
  const [dataKanban, setDataKanban] = useState([]);
  const [name, setName] = useState('');
  const globalState = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterOwner, setFilterOwner] = useState(false)
  
  
  const itemsPerPage = 8;
  
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
  
    let conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState.currentProject,
      },
    ];
    const sortBy = { field: 'lastUpdated', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
  
    if (filterOwner === true) {
      conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany },
      ];
    }
  
    try {
      const res = await getCollectionFirebase(
        'warehouses',
        conditions,
        sortBy,
        limitValue,
      );
      setDataKanban(res);
  
      const collectionRef = collection(db, 'warehouses');
  
      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      let countQuery = query(collectionRef, 
        where('companyId', '==', globalState.currentCompany), 
        where('projectId', '==', globalState.currentProject), 
      );
  
      if (filterOwner === true) {
        countQuery = query(collectionRef, 
          where('companyId', '==', globalState.currentCompany),
        );
      }
      
      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;
      
      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
  
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };
  
  const handleOpenFilter = () => {
    if (globalState.roleCompany !== 'owner') {
      toast({
        title: 'Alert!',
        description: 'You dont have access to filter this button / only owner.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  
    setFilterOwner((prevFilterOwner) => !prevFilterOwner);
  };
  
  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };
  
  
  
  useEffect(() => {
    getData();
  
  }, [globalState?.currentProject, filterOwner, currentPage]);
  
  
  const createNewKanban = () => {
  
    if (globalState?.currentCompany === undefined || globalState?.currentProject === undefined) {
      toast({
        title: 'Alert!',
        description: 'You must setup your company and project.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  
  
    const data = {
      name: name,
      companyId: globalState?.currentCompany || globalState?.companies[0].id,
      projectId: globalState?.currentProject || globalState?.projects[0].id,
      lastUpdated: new Date(),
    };
    addDocumentFirebase('warehouses', data, globalState.currentCompany).then((x) => {
      navigate(`/warehouse/${x}`);
    });
  };
  const handleNavigate = (x) => {
    navigate(`/warehouse/${x.id}`);
  };
  
  
  return (
    <>
      <Stack spacing={8}>
        <HStack m="2" flexDirection={['column', 'row', 'row']}>
          <BackButtons />
  
          <Heading size={'md'}>Warehouses</Heading>
          <Spacer />
  
          <DynamicButton
            title={'See all'}
            action={'read'}
            onClick={handleOpenFilter}
          />
  
          <DynamicButton
            title={'Add Warehouse'}
            action={'create'}
            onClick={onOpen}
          />
        </HStack>
        {/* {dataKanban?.length > 0 ? ( */}
        <SimpleGrid columns={[1, 2, 4]} gap="3">
          {dataKanban?.map((x, i) => (
            <Stack key={i}>
              <CardAvatar
                data={x}
                title={x.name}
                globalState={globalState}
                onNavigate={() => handleNavigate(x)}
                timeCreated={x?.lastUpdated?.seconds}
                Icon={FcHome}
              />
            </Stack>
          ))}
        </SimpleGrid>
        {/* ) : (
            <AlertArrayNull titleData={'kanban'} action="Add Kanban" />
          )} */}
  
        <Stack alignItems={'center'} justifyContent="center" py={5}>
          <Box>
            {currentPage < totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Warehouse</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type="text" onChange={(e) => setName(e.target.value)} />
          </ModalBody>
  
          <ModalFooter>
            <DynamicButton
              action={'create'}
              onClick={() => createNewKanban()}
              title="Save"
              variant={'solid'}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
  
export default ProductivityKanbanPage;
  