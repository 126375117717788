/* eslint-disable no-undef */
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL_VERCEL_API
export const deleteDomainCustom = async(data) => {
  const url = `${baseURL}/delete/${data?.domain}`
  const configTest = {
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.REACT_APP_README_APIKEY,
    },
  }

  return axios.delete(url, configTest,)
    .then((x,) => x,)
    .catch((err,) => (err),)
}

export const createDomainCustom = async(data) => {
  const url = `${baseURL}/create`
  const configTest = {
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': process.env.REACT_APP_README_APIKEY,
    },
  }

  const newData = {
    'name': data?.domain_name,
  }
  return axios.post(url, newData, configTest,)
    .then((x,) => x,)
    .catch((err,) => (err),)
}

export const checkDomainCustom = async(domainName) => {
  const url = `${baseURL}/check/${domainName}`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'X-API-KEY': process.env.REACT_APP_README_APIKEY}}

  return axios.get(url, configtest)
    .then((x,) => x.data,)
    .catch((err,) => (err),)
}