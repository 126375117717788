/* eslint-disable react/react-in-jsx-scope */
import React, { Suspense, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, fetchToken } from './Config/firebase';
import {
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from './Api/firebaseApi';
import useUserStore from './Hooks/Zustand/Store';
import { decryptToken } from './Utils/encrypToken';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import LoadingOverlay from './Components/Loader/LoadingOverlay';
import MainRouter from './Router/MainRouter';
import AuthRouter from './Router/AuthRouter';
import EmailVerification from './Pages/Auth/EmailVerification';

function App() {
  const globalState = useUserStore();

  const handleUsersDisplay = async (companies, currentCompany) => {
    try {
      const findCompany = companies.find((x) => x.id === currentCompany);

      if (!findCompany) {
        return;
      }

      globalState.setKaryawanRole(findCompany?.karyawanAi || []);

      const dataUsers = findCompany.users;
      const dataUsersDisplay = [];

      await Promise.all(
        dataUsers.map(async (userId) => {
          try {
            const result = await getSingleDocumentFirebase('users', userId);

            const user = {
              id: userId || '',
              name: result.name || result.displayName,
              email: result.email || '',
              phoneNumber: result.phoneNumber || result.phone,
              image: result.image || '',
            };

            dataUsersDisplay.push(user);
          } catch (error) {
            throw new Error(error.message, 'Failed to send  error message');
          }
        })
      );

      globalState.setUsers(dataUsersDisplay);
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
  };

  const fetchProjectsAndCompanies = async (uid) => {
    let fetchCompanyId = localStorage.getItem('currentCompany');
    let fetchProjectId = localStorage.getItem('currentProject');

    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: uid,
      },
    ];

    try {
      const [companies, projects] = await Promise.all([
        getCollectionFirebase('companies', conditions),
        getCollectionFirebase('projects', conditions),
      ]);

      if (!fetchCompanyId) {
        fetchCompanyId = companies[0]?.id; // Set the fetched ID to the variable
        const userRoleInCompany = getUserRole(
          companies,
          uid,
          companies[0]?.id,
          'company'
        );

        localStorage.setItem('currentCompany', fetchCompanyId);
        globalState.setExpired(
          companies[0]?.expired_at?.seconds >= moment().unix() ? false : true
        );
        globalState.setCompanies(companies);
        globalState.setCurrentCompany(fetchCompanyId);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);
        await handleUsersDisplay(companies, fetchCompanyId);
      } else {
        globalState.setExpired(
          companies[0]?.expired_at?.seconds >= moment().unix() ? false : true
        );
        globalState.setCurrentCompany(fetchCompanyId);

        const userRoleInCompany = getUserRole(
          companies,
          uid,
          fetchCompanyId,
          'company'
        );
        globalState.setCompanies(companies);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);

        await handleUsersDisplay(companies, fetchCompanyId);
      }

      if (!fetchProjectId) {
        if (companies[0]?.id) {
          fetchProjectId = projects[0]?.id;
          const userRoleInProject = getUserRole(
            projects,
            uid,
            fetchProjectId,
            'project'
          );
          localStorage.setItem('currentProject', fetchProjectId);
          globalState.setProjects(projects);
          globalState.setCurrentProject(fetchProjectId);
          globalState.setRoleProject(userRoleInProject);
        }
      } else {
        const userRoleInProject = getUserRole(
          projects,
          uid,
          fetchProjectId,
          'project'
        );

        globalState.setProjects(projects);
        globalState.setCurrentProject(fetchProjectId);
        globalState.setRoleProject(userRoleInProject);
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
  };

  const getUserRole = (data, uid, dataId) => {
    let findData = {};
    findData = data.find((x) => x.id === dataId);

    if (findData && findData.owner?.includes(uid)) {
      return 'owner';
    } else if (findData && findData?.managers?.includes(uid)) {
      return 'managers';
    } else {
      return 'user';
    }
  };

  const uploadTokenToFirebase = async (token, user) => {
    if (token !== '') {
      const collectionName = 'users';
      const docName = user.uid;
      const field = 'tokenId';
      const values = [token];

      try {
        await arrayUnionFirebase(collectionName, docName, field, values);
      } catch (error) {
        return 'Error occured:', error;
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const result = await getSingleDocumentFirebase('token', 'dropbox');
      const resultData = decryptToken(result?.access_token);
      globalState.setAccessToken(resultData);
    } catch (error) {
      return error;
    }
  };

  const refreshTokenHourly = async () => {
    const user = auth.currentUser;
    if (user) {
      const tokenFirebase = await user.getIdToken(true);
      globalState.setAuthFirebaseToken(tokenFirebase);
      localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await fetchToken();
        if (token) {
          await uploadTokenToFirebase(token, user);
        }

        await getAccessToken();
        const tokenFirebase = await user.getIdToken(true);

        globalState.setAuthFirebaseToken(tokenFirebase);
        localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));

        fetchProjectsAndCompanies(user?.uid);

        // setDocumentFirebase('customer', `${user.uid}-${projectId}`, {lastUpdated : new Date()})
        globalState.setIsLoggedIn(true);
        globalState.setUid(user.uid);
        globalState.setName(user.displayName);
        globalState.setEmail(user.email);
        // }
      } else {
        globalState.setIsLoggedIn(false);
      }
    });

    const intervalId = setInterval(refreshTokenHourly, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    // <Suspense fallback={<LoadingOverlay />}>
    <Suspense fallback={<LoadingOverlay />}>
      <Stack position={'relative'} overflow="hidden">
        {globalState?.isLoggedIn ? (
          auth?.currentUser?.emailVerified ? (
            <Stack position={'relative'}>
              <Alert
                status="warning"
                h={['30px', '30px', '40px']}
                overflowX="auto"
              >
                <AlertIcon />
                <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
                  Perhatian! 📢
                </AlertTitle>
                <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
                  <Text>
                    Beta Version. Kritik, saran, dan bantuan harap hubungi Customer Service kami <n />
                    {/* <Link
                    fontStyle={'italic'}
                    color="blue.600"
                    href={'https://hr.deoapp.com'}
                    fontWeight={'bold'}
                    cursor="pointer"
                  >
                    Kunjungi Website Business
                  </Link> */}
                  </Text>
                </AlertDescription>
              </Alert>
              <LoadingOverlay />
              <MainRouter />
            </Stack>
          ) : (
            <EmailVerification />
          )
        ) : (
          <AuthRouter />
        )}
      </Stack>
    </Suspense>

  );
}

export default App;
