import { Box, Button, Center, Flex, FormControl, FormLabel, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Stack, Text, Textarea } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import useWebsiteStore from '../../../../Hooks/Zustand/WebsiteStore'

const ModalAddCategory = ({modal, handleChangeSectionImage}) => {
  const websiteStore = useWebsiteStore()

  const currentCategory = websiteStore?.pageCategory

  const handleAddCategory = () => {
    const currentCategoryData = currentCategory?.data

    const dataCategory = {
      name: '',
      link: '',
      image: '',
    }

    currentCategoryData?.push(dataCategory)

    websiteStore?.setPageCategory({...currentCategory, data: currentCategoryData})
  }

  const handleDelete = (indexValue) => {
    const currentProductPage = websiteStore?.pageProduct;
    const currentData = websiteStore?.pageProduct?.data;

    currentData?.splice(indexValue, 1);

    currentProductPage.data = currentData;
    websiteStore?.setPageProduct(currentProductPage);
  };

  const handleChangeCategoryData = (value, index, field) => {
    currentCategory.data[index][field] = value;
    websiteStore?.setPageProduct(currentCategory);
  };

  // console.log(currentCategory)

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Add Category</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody align={'center'}>
          <Box my={3}>
            <Stack>
              <Text>Header</Text>
              <Input
                size={'sm'}
                defaultValue={currentCategory?.header}
                placeholder='Enter your page header'
                onChange={(e) => websiteStore?.setPageCategory({ ...currentCategory, header: e.target.value })}
              />
            </Stack>
            <Stack my={2}>
              <Text>Description Content</Text>
              <Textarea
                size={'sm'}
                defaultValue={currentCategory?.content}
                onChange={(e) => websiteStore?.setPageCategory({ ...currentCategory, content: e.target.value })}
                placeholder='Enter your product page content'
              />
            </Stack>
          </Box>
          <Text>Your Category</Text>
          <Flex>
            {currentCategory?.data?.length > 0 ? (
              <SimpleGrid
                columns={2}
                spacing={3}>
                {currentCategory?.data?.map((x, i) => (
                  <Stack
                    gap={2}
                    p={3}
                    key={i}>
                    <FormControl>
                      <FormLabel size={'sm'}>Category Name</FormLabel>
                      <Input
                        size='xs'
                        defaultValue={x?.name}
                        onChange={(e) => handleChangeCategoryData(e.target.value, i, 'name')}
                      />
                    </FormControl>
                    {x?.image1 ? (
                      <HStack justify={'center'}>
                        <Image
                          maxW={100}
                          src={x?.image1}
                          alt={x?.name}
                        />
                        <Image
                          maxW={100}
                          src={x?.image2}
                          alt={x?.name}
                        />
                      </HStack>
                    ) : (
                      <Image
                        src={x?.image}
                        alt={x?.name}
                      />
                    )}
                    <HStack w={'100%'}>
                      <Text
                        fontSize={12}
                        w={'30%'}>
                            Link category
                      </Text>
                      <Input
                        w={'70%'}
                        defaultValue={x?.link}
                        size={'xs'}
                        onChange={(e) => handleChangeCategoryData(e.target.value, i, 'link')}
                      />
                    </HStack>
                    
                    <HStack>
                      <Text
                        fontSize={12}
                        w={'30%'}>
                            Image
                      </Text>
                      <Input
                        w={'70%'}
                        type='file'
                        size={'xs'}
                        onChange={(e) => handleChangeSectionImage(e, 'category', i, 'image')}
                      />
                    </HStack>
                        

                    <Button
                      size={'xs'}
                      colorScheme='red'
                      onClick={() => handleDelete(i)}
                    >
                          Delete
                    </Button>
                  </Stack>
                ))}
              </SimpleGrid>
            ):(
              <Center w={'full'} h={'20vh'}>
                No Category Data
              </Center>
            )}

          </Flex>
          <Box align={'center'}>
            <Button size={'sm'} colorScheme='blue' onClick={() => handleAddCategory()}>Add Category</Button>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button colorScheme='red' size={'sm'} onClick={() => modal.onClose()}>Cancel</Button>
            <Button colorScheme='green' size={'sm'} onClick={() => modal.onClose()}>Save</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalAddCategory

ModalAddCategory.propTypes = {
  modal: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  handleChangeSectionImage: PropTypes.func
}